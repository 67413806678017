import {
  stateInitial,
  stateLoading,
  stateLoaded,
  stateError,
} from "./loadingStates";
import apiService from "@/services/automation";

export default {
  namespaced: true,
  state: {
    templates: { ...stateInitial, result: [] },
    topics: { ...stateInitial, result: [] },
    rules: { ...stateInitial, result: [] },
    rulesQuery: null,
    rulesPage: {},
  },
  mutations: {
    templatesLoading(state) {
      state.templates = { ...stateLoading, result: state.templates.result };
    },
    templatesLoaded(state, result) {
      state.templates = { ...stateLoaded, result };
    },
    templatesError(state) {
      state.templates = { ...stateError, result: state.templates.result };
    },

    topicsLoading(state) {
      state.topics = { ...stateLoading, result: state.topics.result };
    },
    topicsLoaded(state, result) {
      state.topics = { ...stateLoaded, result };
    },
    topicsError(state) {
      state.topics = { ...stateError, result: state.topics.result };
    },

    rulesLoading(state, query) {
      state.rules = { ...stateLoading, result: state.rules.result };
      state.rulesQuery = query;
    },
    rulesLoaded(state, { result, page }) {
      state.rules = { ...stateLoaded, result };
      state.rulesPage = page;
    },
    rulesError(state) {
      state.rules = { ...stateError, result: state.rules.result };
    },
  },
  actions: {
    async loadTemplates({ commit }) {
      commit("templatesLoading");
      const response = await apiService.getTemplates().catch((error) => {
        commit("templatesError");
        throw error;
      });
      const result = response.data.result;
      commit("templatesLoaded", result);
      return result;
    },
    async loadTopics({ commit }) {
      commit("topicsLoading");
      const response = await apiService.getTopics().catch((error) => {
        commit("topicsError");
        throw error;
      });
      const result = response.data.result;
      commit("topicsLoaded", result);
      return result;
    },
    async loadRules({ commit }, query) {
      commit("rulesLoading", query);
      const response = await apiService.getRules(query).catch((error) => {
        commit("rulesError");
        throw error;
      });
      const result = response.data.result;
      commit("rulesLoaded", {
        result,
        page: {
          total: response.data.total,
          pagesTotal: response.data.pagesTotal,
        },
      });
      return result;
    },
    async addRule({ commit, dispatch, state }, rule) {
      commit("rulesLoading", state.rulesQuery);
      const response = await apiService.postRule(rule).catch((error) => {
        commit("rulesError");
        throw error;
      });
      const result = response.data.result;
      dispatch("loadRules", state.rulesQuery);
      return result;
    },
    async editRule({ commit, dispatch, state }, rule) {
      commit("rulesLoading", state.rulesQuery);
      const response = await apiService.patchRule(rule).catch((error) => {
        commit("rulesError");
        throw error;
      });
      const result = response.data.result;
      dispatch("loadRules", state.rulesQuery);
      return result;
    },
    async deleteRule({ commit, dispatch, state }, rule) {
      commit("rulesLoading", state.rulesQuery);
      const response = await apiService.deleteRule(rule).catch((error) => {
        commit("rulesError", error);
        throw error;
      });
      dispatch("loadRules", state.rulesQuery);
      return response;
    },
  },
  getters: {
    templates: (state) => state.templates.result,
    templatesLoadingState: (state) => state.templates,
    topics: (state) => state.topics.result,
    topicsLoadingState: (state) => state.topics,
    rules: (state) => state.rules.result,
    rulesLoadingState: (state) => state.rules,
    rulesPage: (state) => state.rulesPage,
  },
};
