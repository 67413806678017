<template lang="pug">
.flex.flex-justify-center
  el-button.medium-width.confirm-button.clickable(
    v-if="!completed",
    type="primary",
    @click="sendConfirmation()"
  ) {{ $t("confirm") }}
  el-button.medium-width.confirm-button(v-else, type="primary", icon="Check") {{ $t("confirmed") }}
</template>

<script>
export default {
  props: {
    completed: Boolean,
  },
  methods: {
    sendConfirmation() {
      this.$emit("confirmed");
    },
  },
};
</script>

<style lang="stylus">
.confirm-button {
}
</style>
