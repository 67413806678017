<template>
  <img class="header-logo" :src="headerImage" alt @click="redirect" />
</template>
<script>
export default {
  name: "HeaderLogo",
  props: {
    image: {
      type: String,
    },
  },
  computed: {
    headerImage() {
      const propsImage = this.image;
      if (propsImage) {
        return propsImage;
      }
      return this.$getSetting("headerlogo");
    },
  },
  methods: {
    redirect() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="stylus">
.header-logo {
  width: 120px;
  max-height: 45px;
  padding-bottom: 0px;
}
</style>
