// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import store from "./store";
import messages from "./localization";
import VueObserveVisibility from "vue-observe-visibility";
import Modal from "@/components/elements/Modal";
import { createI18n } from "vue-i18n";
import ElementPlus, { dayjs } from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "element-plus/dist/index.css";
import "./element-overrides.css";
import "quill/dist/quill.snow.css";

import "flag-icons/css/flag-icons.css";
import axios from "axios";

import * as Sentry from "@sentry/vue";
import { AuthErrorInterceptor, AuthInterceptor } from "@/services/interceptors";
import CustomDatePicker from "@/components/elements/DatePicker.vue";

import { title } from "./settings";
import { getSetting } from "./getSettings";
import {
  HardwareRestrictionsErrorInterceptor,
  HardwareRestrictionsInterceptor,
} from "./services/hardwareRestrictionInterceptor";
import VueClickAway from "vue3-click-away";
import duration from "dayjs/plugin/duration";

import { createPinia } from "pinia";

axios.interceptors.request.use(HardwareRestrictionsInterceptor, () => {});

axios.interceptors.request.use(AuthInterceptor, () => {});

axios.interceptors.response.use((response) => {
  return response;
}, AuthErrorInterceptor);

axios.interceptors.response.use((response) => {
  return response;
}, HardwareRestrictionsErrorInterceptor);

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: "de",
  messages,
});

const pinia = createPinia();

export const app = createApp(App);

dayjs.extend(duration);

app.use(i18n);
app.use(store);
app.use(router);
app.use(VueClickAway);
app.use(VueObserveVisibility);
app.use(pinia);

app.component(CustomDatePicker.name, CustomDatePicker);

app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.component("Modal", Modal);

// Vue.config.productionTip = false;

// Global settings mixin
app.mixin({
  methods: {
    $getSetting: getSetting,
  },
});

// TODO: vue3 Check for production environment
const isDevEnvironment = process.env.NODE_ENV == "development";
// const isProductionEnvironment = false;

if (!isDevEnvironment) {
  Sentry.init({
    app,
    dsn: "https://e09d7cf43eca437ca7159a3a8d5b542e@o394164.ingest.sentry.io/5243990",
    release: `elearning@${title}`,
    integration: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
} else {
  console.log("isDev, sentry disabled", isDevEnvironment);
}

// NOTE: we start regular token updates due to  https://github.com/inctec/elearning-portal/issues/1114
store.dispatch("setTokenRefreshTimer");

app.mount("#app");
