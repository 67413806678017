import axios from "axios";
import { mapResponse, printError } from "../utils/mapResponse";
import API from "./config";

export default {
  LIFECYCLE(options) {
    return axios
      .post(API.baseUrlV2 + "lifecycle", {
        /** complete | start | failed */
        state: options.state,
        course: options.course,

        /*
      score and duration only for `complete`
      */
        score: options.score,
        duration: options.duration, // optional
      })
      .then(mapResponse)
      .catch(printError);
  },
};
