import getToken from "@/utils/getToken";
import axios from "axios";
import API from "./config";

/**
 *  @module Capabilities service
 */

/**
 * @typedef {Object} Role
 * @property {number} id -role id
 * @property {string} name - role name
 * @property {Array<string>} capabilities - array of capabilities
 */

export default {
  /**
   * API call to get all capabilities
   * @returns {Promise<Response>} Fetch response
   */
  INIT() {
    return axios.get(API.roleCapsURL);
  },
};
