<template>
  <div class="notifications-container">
    <transition-group name="list" tag="span">
      <notification
        v-for="notify in notifications"
        :id="notify.id"
        :key="notify.id"
        class="list-item"
        :timeout="notify.timeout"
        :is-hiding="notify.isHiding"
        :notification-type="notify.notificationType"
        :text="notify.text"
        :title="notify.title"
        :text-as-is="notify.textAsIs"
      />
    </transition-group>
  </div>
</template>

<script>
import Notification from "./Notification";

export default {
  name: "NotificationsContainer",
  components: {
    Notification,
  },
  data() {
    return {};
  },
  computed: {
    notifications() {
      return this.$store.getters.getNotifications;
    },
  },
};
</script>
<style lang="stylus">
.notifications-container {
  position: fixed;
  z-index: 999999999;
  right: 10px;
  bottom: 50px;
  display: flex;
  flex-flow: column nowrap;

  .list-enter-active, .list-leave-active {
    transition: all 1s;
  }

  .list-enter, .list-leave-to {
    opacity: 0;
    transform: translateY(100px);
  }
}
</style>
