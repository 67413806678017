import services from "@/services/logger";
import {
  stateLoading,
  stateInitial,
  stateError,
  stateLoaded,
} from "./loadingStates";

export default {
  state: {
    // DEPRECATED
    messages: [],
    // DEPRECATED
    loadingState: stateInitial,

    logsLoadingState: { ...stateInitial, result: [] },
    logsOptions: {},
    logsPage: null,

    filtersLoadingState: { ...stateInitial },

    logSettingsLoadingState: { ...stateInitial, result: [] },
    logGlobalSettingsLoadingState: { ...stateInitial },
  },
  mutations: {
    loading(state, payload) {
      state.loadingState = stateLoading;
    },
    loaded(state, payload) {
      state.loadingState = stateLoaded;
    },
    error(state, payload) {
      state.loadingState = { ...stateError, errorMessage: payload };
    },
    initSystemMessages(state, payload) {
      if (payload && payload.length) {
        state.messages = payload;
      }
    },
    addSystemMessage(state, payload) {
      if (payload) {
        state.messages.push(payload);
      }
    },

    logsLoading(state, options) {
      state.logsLoadingState = {
        ...stateLoading,
        result: state.logsLoadingState.result,
      };
      state.logsOptions = options;
    },
    logsLoaded(state, response) {
      state.logsLoadingState = { ...stateLoaded, result: response.result };
      state.logsPage = {
        total: response.total,
        pagesTotal: response.pagesTotal,
      };
    },
    logsError(state) {
      state.logsLoadingState = stateError;
    },

    logSettingsLoading(state) {
      state.logSettingsLoadingState = {
        ...stateLoading,
        result: state.logSettingsLoadingState.result,
      };
    },
    logSettingsLoaded(state, response) {
      state.logSettingsLoadingState = {
        ...stateLoaded,
        result: response.result,
      };
    },
    logSettingsError(state) {
      state.logSettingsLoadingState = { ...stateError, result: [] };
    },
    logSettingUpdated(state, setting) {
      state.logSettingsLoadingState = {
        ...stateLoaded,
        result: state.logSettingsLoadingState.result.map((item) =>
          item.id === setting.id ? { ...item, ...setting } : item,
        ),
      };
    },

    logGlobalSettingsLoading(state) {
      state.logGlobalSettingsLoadingState = {
        ...stateLoading,
        result: state.logGlobalSettingsLoadingState.result,
      };
    },
    logGlobalSettingsLoaded(state, response) {
      state.logGlobalSettingsLoadingState = {
        ...stateLoaded,
        result: response,
      };
    },
    logGlobalSettingsError(state) {
      state.logGlobalSettingsLoadingState = { ...stateError, result: [] };
    },

    filtersLoading(state) {
      state.filtersLoadingState = { ...stateLoading };
    },
    filtersLoaded(state, result) {
      state.filtersLoadingState = { ...stateLoaded, result };
    },
    filtersError(state) {
      state.filtersLoadingState = { ...stateError };
    },
  },
  actions: {
    // DEPRECATED
    // TODO: remove, use initLogs instead
    initSystemMessages({ commit }, options) {
      commit("loading");
      services.LOGS_GET(options).then(
        (data) => {
          commit("initSystemMessages", data);
          commit("loaded");
        },
        (error) => commit("error", error),
      );
    },
    async loadLogs({ commit }, options) {
      commit("logsLoading", options);
      const response = await services.getLogs(options).then(
        (response) => commit("logsLoaded", response),
        (error) => commit("logsError", error),
      );
      return response;
    },
    async loadLogFilters({ commit }) {
      commit("filtersLoading");
      const response = await services.getLogsFilter().then(
        (response) => commit("filtersLoaded", response),
        (error) => {
          commit("filtersError");
          throw error;
        },
      );
      return response;
    },
    async loadLogSettings({ commit }) {
      commit("logSettingsLoading");
      const response = await services.getLogSettings().then(
        (response) => commit("logSettingsLoaded", response.data),
        (error) => {
          commit("logSettingsError");
          throw error;
        },
      );
      return response;
    },
    async loadLogGlobalSettings({ commit }) {
      commit("logGlobalSettingsLoading");
      const response = await services.getlogGlobalSettings().then(
        (response) => {
          commit("logGlobalSettingsLoaded", response.data);
        },
        (error) => {
          commit("logGlobalSettingsError");
          throw error;
        },
      );
      return response;
    },
    async patchLogSetting({ commit }, settings) {
      commit("logSettingsLoading");
      const response = await services
        .patchLogSettings({
          settings: [settings],
        })
        .then(
          () => {
            commit("logSettingUpdated", settings);
          },
          (error) => {
            commit("logGlobalSettingsError");
            throw error;
          },
        );
      return response;
    },
    // addSystemMessage({ commit, getters }, payload) {
    //   const currentAccountId = getters.getCurrentUserAccountId;
    //   const text = payload.text;
    //   const result = {
    //     courseId: payload.courseId,
    //     action: payload.action,
    //     actionResultKpi: text,
    //     actionResult: text,
    //     actionResultShort: text,
    //     accountId: currentAccountId,
    //     type: 'COURSE',
    //   };
    //   services.POST_INFO(result).then((data) => {
    //     commit('addSystemMessage', data);
    //   });
    // },
    addInfoSystemMessage({ getters }, payload) {
      const { courseId, text, kpi, short } = payload;
      const currentAccountId = getters.getCurrentUserAccountId;
      const result = {
        action: "info",
        actionResultKpi: kpi,
        actionResult: text,
        actionResultShort: short,
        accountId: currentAccountId,
        courseId,
        type: "course",
      };
      services.POST_INFO(result);
    },
    addErrorSystemMessage({ getters }, payload) {
      const { courseId, text, kpi, short } = payload;
      const currentAccountId = getters.getCurrentUserAccountId;
      const result = {
        action: "error",
        actionResultKpi: kpi,
        actionResult: text,
        actionResultShort: short,
        accountId: currentAccountId,
        courseId,
        type: "course",
      };
      services.POST_INFO(result);
    },
  },
  getters: {
    // Deprecated: remove
    getLogMessages: (state) => state.messages,
    getLogsLoadingState: (state) => state.logsLoadingState,
    getLogsOptions: (state) => state.logsOptions,
    getLogsPage: (state) => state.logsPage,
    getLogFiltersLoadingState: (state) => state.filtersLoadingState,
    getLogSettingsLoadingState: (state) => state.logSettingsLoadingState,
    getLogGlobalSettingsLoadingState: (state) =>
      state.logGlobalSettingsLoadingState,
  },
};
