<template lang="pug">
div
  EditPreview.mb-2(:text="text", :edit-on-start="false", @editText="editText")
  .video-upload-container(v-if="!url", v-loading="uploadLoading")
    .video-placeholder.mb-3 {{ $t("uploadVideoFile") }}
    input.file-upload(type="file", @change="uploadFile($event.target.files)")
  .video-container(v-else, v-loading="uploadLoading")
    video.mb-3(
      :key="url",
      controls="true",
      oncontextmenu="return false;",
      preload="metadata",
      width="400",
      height="200"
    ) Your browser does not support HTML5 video.
      source(:src="url")
</template>

<script>
import editStepMixin from "./mixins/editStepMixin";
import EditPreview from "./components/EditPreview.vue";

export default {
  components: {
    EditPreview,
  },
  mixins: [editStepMixin],
  props: {
    step: { type: Object, required: true },
  },
  data: function () {
    return {
      uploadLoading: false,
      isEditingText: false,
    };
  },
  computed: {
    text() {
      return this.step.text;
    },
    url() {
      return this.step.url;
    },
  },
  methods: {
    editText(text) {
      this.patchStep({ text, type: "multimedia" });
    },
    uploadFile(files) {
      this.uploadLoading = true;
      // NOTE: videos might be large and there is no support for chunked upload.
      // On the one hand, we have to check for timeouts here,
      // On the other hand, the token might be invalidated during upload, to prevent this we refresh the token before upload and hope the upload will be done before expiration
      // https://github.com/inctec/elearning-portal/issues/1114
      this.$store.dispatch("refreshToken").then(() => {
        this.$store.dispatch("uploadVideo", { file: files[0] }).then((_) => {
          this.uploadLoading = false;
        });
      });
    },
    startEditText() {
      this.isEditingText = true;
    },
    stopEditText() {
      this.isEditingText = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.video-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 200px;
  border: 1px solid black;
  margin: auto;
}

.video-upload-container {
  position: relative;
  width: 400px;
  height: 200px;
  margin: auto;
}

.video-container {
  width: 400px;
  height: 200px;
  margin: auto;
}

.video-upload-container > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  width: 400px;
  height: 200px;
}

.video-upload-container input {
  opacity: 0;
}
</style>
