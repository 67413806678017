import {
  stateError,
  stateInitial,
  stateLoaded,
  stateLoading,
} from "./loadingStates";
import eventService from "@/services/events";
import { eventsApi } from "@/services/shogun";
import _ from "lodash";

export default {
  state: {
    events: { ...stateInitial, result: [] },
    eventDates: { ...stateInitial, result: [] },
    preregistrations: { ...stateInitial, result: [] },
    translations: {
      loadingState: stateInitial,
      translations: [],
      // patches are of type {id, type, key, lang, text}
      patches: [],
      defaultLanguage: null,
    },
    // NOTE: exam results keeps results from different examDates.
    // type is results: Array<{
    //  eventDateId: string,
    //  users: Array<UserExamResult>
    // }>
    examResults: { ...stateInitial, result: [] },
    // array of {eventDateId, patches: Array<{
    //   userId: string,
    //   patch: Object
    // }>}
    // patches are merged locally for viewing and can then be sent to the server
    examPatches: [],
    questionnaire: {
      questionnaires: { ...stateInitial, result: [] },
      questions: { ...stateInitial, result: [] },
      sections: { ...stateInitial, result: [] },
      questionPatches: [],
    },
  },
  mutations: {
    setPatches(state, { eventDateId, patches }) {
      // find the patches for eventDateId
      const index = state.examPatches.findIndex(
        (item) => (item.eventDateId = eventDateId),
      );
      if (index < 0) {
        state.examPatches.push({ eventDateId, patches });
      } else {
        state.examPatches.splice(index, 1, { eventDateId, patches });
      }
    },
    clearExamPatches(state) {
      state.examPatches = [];
    },
    eventsLoading(state) {
      state.events = { ...stateLoading, result: state.events.result };
    },
    eventsLoaded(state, result) {
      state.events = { ...stateLoaded, result };
    },
    eventsError(state) {
      state.events = { ...stateError, result: state.events.result };
    },
    eventDatesLoading(state) {
      state.eventDates = { ...stateLoading, result: state.eventDates.result };
    },
    eventDatesLoaded(state, result) {
      state.eventDates = { ...stateLoaded, result };
    },
    eventDatesError(state) {
      state.eventDates = { ...stateError, result: state.eventDates.result };
    },
    examResultsLoading(state) {
      state.examResults = { ...stateLoading, result: state.examResults.result };
    },
    examResultsLoaded(state, result) {
      state.examResults = { ...stateLoaded, result };
    },
    examResultsError(state) {
      state.examResults = { ...stateError, result: state.examResults.result };
    },
    preregistrationLoading(state) {
      state.preregistrations = {
        ...stateLoading,
        result: state.preregistrations.result,
      };
    },
    preregistrationLoaded(state, result) {
      state.preregistrations = { ...stateLoaded, result };
    },
    preregistrationError(state) {
      state.preregistrations = { stateError, result: [] };
    },
    eventDateAdded(state, eventDate) {
      const result = state.eventDates.result;
      result.push(eventDate);
      state.eventDates = { ...stateLoaded, result };
    },
    eventDateEdited(state, eventDate) {
      state.eventDates.result.splice(
        state.eventDates.result.findIndex((item) => item.id === eventDate.id),
        1,
        eventDate,
      );
      state.eventDates = { ...stateLoaded, result: state.eventDates.result };
    },
    preregistrationUpdated(state, preRegistration) {
      const index = state.eventDates.result.findIndex(
        (item) => item.id === preRegistration.id,
      );
      const oldItem = state.eventDates.result[index];
      //  NOTE if preregistrations have changed, we also have to update preregistrationsTotal
      const oldPreregistrations = oldItem.preRegistrationsAccount;
      const preregistrationDelta =
        oldPreregistrations - preRegistration.preregistrations;
      state.eventDates.result.splice(index, 1, {
        ...oldItem,
        preRegistrationsAccount: preRegistration.preregistrations,
        preRegistrationsTotal:
          oldItem.preRegistrationsTotal - preregistrationDelta,
      });
    },
    loadTranslations(state) {
      state.translations.loadingState = stateLoading;
    },
    translationsLoaded(state, { courseId, response }) {
      state.id = courseId;
      state.translations.translations = response.result;
      state.translations.defaultLanguage = response.defaultLanguage;
      state.translations.loadingState = stateLoaded;
    },
    translationsSaved(state, updatedTranslations) {
      state.translations.translations = updatedTranslations;
      state.translations.patches = [];
      state.translations.loadingState = stateLoaded;
    },
    translationsError(state) {
      state.translations.loadingState = stateError;
    },
    // NOTE: patch here is expected to be {id, type, key, lang, text},
    addTranslationPatch(state, patch) {
      const existingPatchIndex = state.translations.patches.findIndex(
        (item) =>
          item.type === patch.type &&
          item.id === patch.id &&
          (item.key === patch.key) & (item.lang === patch.lang),
      );
      if (existingPatchIndex > -1) {
        state.translations.patches.splice(existingPatchIndex, 1, patch);
      } else {
        state.translations.patches.push(patch);
      }
    },
    // directly patch the backend translation state
    patchTranslation(state, { stepId, patch }) {
      // NOTE: direct patches to translation only happen for file uploads,
      // i.e. we can safely assume here that the key for the patch is 'url'
      const stepItemIndex = state.translations.translations.findIndex(
        (item) => item.id === stepId,
      );
      if (stepItemIndex === -1) {
        return;
      }
      const translationItemIndex = state.translations.translations[
        stepItemIndex
      ].translations.findIndex((item) => item.key === patch.key);
      const translationItem =
        state.translations.translations[stepItemIndex].translations[
          translationItemIndex
        ];
      state.translations.translations[stepItemIndex].translations,
        translationItemIndex,
        _.merge({}, translationItem, patch);
    },
    questionnairesLoading(state) {
      state.questionnaire.questionnaires = {
        ...stateLoading,
        result: state.questionnaire.questionnaires.result,
      };
    },
    questionnairesLoaded(state, result) {
      state.questionnaire.questionnaires = { ...stateLoaded, result };
    },
    questionnairesError(state) {
      state.questionnaire.questionnaires = {
        ...stateError,
        result: state.questionnaire.questionnaires.result,
      };
    },
    questionnaireQuestionsLoading(state) {
      state.questionnaire.questions = {
        ...stateLoading,
        result: state.questionnaire.questions.result,
      };
    },
    questionnaireQuestionsLoaded(state, result) {
      state.questionnaire.questions = { ...stateLoaded, result };
    },
    questionnaireQuestionsError(state) {
      state.questionnaire.questions = {
        ...stateError,
        result: state.questionnaire.questions.result,
      };
    },
    addQuestionPatch(state, patch) {
      const existingPatchIndex = state.questionnaire.questionPatches.findIndex(
        item.id === patch.id,
      );
      if (existingPatchIndex > -1) {
        state.questionnaire.questionPatches.splice(existingPatchIndex, 1, {
          ...state.questionnaire.questionPatches,
          ...patch,
        });
      } else {
        state.questionnaire.questionPatches.push(patch);
      }
    },
    clearQuestionPatches(state) {
      state.questionnaire.questionPatches = [];
    },
    discardExamPatchById(state, { eventDateId, userId }) {
      const eventDatePatch = state.examPatches.find(
        (item) => item.eventDateId === eventDateId,
      );
      if (!eventDatePatch) {
        return;
      }
      eventDatePatch.patches = eventDatePatch.patches.filter(
        (item) => item.id !== userId,
      );
    },
    questionnaireSectionsLoading(state) {
      state.questionnaire.sections = { ...stateLoading, result: [] };
    },
    questionnaireSectionsLoaded(state, result) {
      state.questionnaire.sections = { ...stateLoaded, result };
    },
    questionnaireSectionsError(state) {
      state.questionnaire.sections = {
        ...stateError,
        result: state.questionnaire.sections.result,
      };
    },
  },
  actions: {
    addExamPatch({ commit, state, getters }, { eventDateId, userId, patch }) {
      // get or create the event patch
      let eventDatePatch = state.examPatches.find(
        (item) => item.eventDateId === eventDateId,
      );
      if (!eventDatePatch) {
        eventDatePatch = { eventDateId, patches: [] };
        state.examPatches.push(eventDatePatch);
      }
      // update or create userPatch
      const userPatchIndex = eventDatePatch.patches.findIndex(
        (item) => item.id === userId,
      );
      if (userPatchIndex === -1) {
        eventDatePatch.patches.push({ ...patch, id: userId });
      } else {
        eventDatePatch.patches.splice(
          userPatchIndex,
          1,
          _.merge(eventDatePatch.patches[userPatchIndex], patch),
        );
      }
    },
    async saveExamPatches({ commit, state, dispatch }) {
      commit("examResultsLoading");
      const objectNotEmpty = function (o) {
        return Object.keys(o).length > 0;
      };
      const responses = await Promise.all(
        state.examPatches.map((item) =>
          eventService.patchExamResults(
            item.eventDateId,
            item.patches.filter((item) => objectNotEmpty(item)),
          ),
        ),
      )
        .then(
          (responses) => {
            commit("addNewNotification", {
              title: "success",
              text: "success",
              notificationType: "success",
            });
            // remove all patches
            commit("clearExamPatches");
          },
          (error) => {
            commit("addNewNotification", {
              title: "error",
              text: "genericError",
              notificationType: "success",
            });
            throw error;
          },
        )
        .finally(() => {
          // Reload the exam patches
          dispatch("loadExamResults", {
            eventDateIds: state.examResults.result.map(
              (item) => item.eventDateId,
            ),
          });
        });
    },
    // Apply a single patch
    async saveExamPatchById(
      { state, commit, dispatch },
      { eventDateId, userId },
    ) {
      const eventDatePatch = state.examPatches.find(
        (item) => item.eventDateId === eventDateId,
      );
      if (!eventDatePatch) {
        return;
      }
      const userPatch = eventDatePatch.patches.find(
        (item) => item.id === userId,
      );
      if (!userPatch) {
        return;
      }
      await eventService.patchExamResults(eventDateId, [userPatch]).then(
        (responses) => {
          commit("addNewNotification", {
            title: "success",
            text: "success",
            notificationType: "success",
          });
          // Reload the exam patches
          eventDatePatch.patches = eventDatePatch.patches.filter(
            (item) => item.id !== userId,
          );
          dispatch("loadExamResults", {
            eventDateIds: state.examResults.result.map(
              (item) => item.eventDateId,
            ),
          });
        },
        (error) => {
          commit("addNewNotification", {
            title: "error",
            text: "genericError",
            notificationType: "success",
          });
          throw error;
        },
      );
      // remove the patch
    },
    loadEvents(context, { keepCache }) {
      if (
        keepCache &&
        (context.state.events.loading || context.state.events.loaded)
      ) {
        return;
      }
      context.commit("eventsLoading");
      eventService.getAllEvents().then(
        (response) => {
          context.commit("eventsLoaded", response.result);
        },
        (error) => {
          context.commit("eventsError");
          throw error;
        },
      );
    },
    loadEventDates(context, params) {
      context.commit("eventDatesLoading");
      eventService.getAllDates(params).then(
        (result) => {
          context.commit("eventDatesLoaded", result.dates);
        },
        (error) => {
          context.commit("eventDatesError");
          throw error;
        },
      );
    },
    // eventDateIds: String[]
    async loadExamResults(context, { eventDateIds }) {
      context.commit("examResultsLoading");
      try {
        const results = await Promise.all(
          eventDateIds.map((item) => eventService.getExamResults(item)),
        );
        context.commit(
          "examResultsLoaded",
          eventDateIds.map((eventDateId, index) => ({
            eventDateId: eventDateId,
            users: results[index].users,
          })),
        );
      } catch (error) {
        context.commit("examResultsError");
        throw error;
      }
    },
    async addEventDate(context, date) {
      context.commit("eventDatesLoading");
      await eventService.createEventDate(date).then(
        (result) => {
          context.commit("eventDateAdded", result);
        },
        (error) => {
          context.commit("eventDatesError");
          throw error;
        },
      );
    },
    async editEventDate(context, data) {
      context.commit("eventDatesLoading");
      await eventService.editEventDate(data.eventDate).then(
        (result) => {
          context.commit("eventDateEdited", result);
        },
        (error) => {
          context.commit("eventDatesError");
          throw error;
        },
      );
    },
    async editPreregistration(context, data) {
      context.commit("preregistrationLoading");
      await eventService.editPreregistrations(data).then(
        (result) => {
          context.commit("preregistrationUpdated", data);
        },
        (error) => {
          context.commit("preregistrationError");
          throw error;
        },
      );
    },
    async loadEventTranslations(context, courseId) {
      context.commit("loadTranslations");
      const response = await eventService
        .getTranslations(courseId)
        .catch((error) => {
          context.commit("backgroundLoaded");
          context.commit("backgroundError");
          throw error;
        });
      context.commit("translationsLoaded", { response: response, courseId });
    },
    async saveEventTranslations(context, courseId) {
      context.commit("loadTranslations");
      const patches = context.state.translations.patches.reduce((agg, curr) => {
        const item = agg.find(
          (item) => item.type === curr.type && item.id === curr.id,
        );
        if (item) {
          const existingTranslation = item.translations.find(
            (item) => item.key === curr.key,
          );
          if (existingTranslation) {
            existingTranslation.values[curr.lang] = curr.text;
          } else {
            const values = {};
            values[curr.lang] = curr.text;
            item.translations.push({ key: curr.key, values });
          }
        } else {
          const values = {};
          values[curr.lang] = curr.text;
          agg.push({
            type: curr.type,
            id: curr.id,
            translations: [
              {
                key: curr.key,
                values,
              },
            ],
          });
        }
        return agg;
      }, []);
      await eventService
        .patchTranslations({ courseId, patches })
        .catch((error) => context.commit("translationsError"));
      context.commit("translationsSaved", context.getters.mergedTranslations);
    },
    loadQuestionnaires(context) {
      context.commit("questionnairesLoading");
      eventService.getQuestionnaires().then(
        (result) => {
          context.commit("questionnairesLoaded", result.result);
        },
        (error) => {
          context.commit("questionnairesError");
        },
      );
    },
    createQuestionnaire(context, { name }) {
      context.commit("questionnairesLoading");
      return eventService.createQuestionnaire({ name }).then(
        (response) => {
          const questionnaires =
            context.state.questionnaire.questionnaires.result;
          questionnaires.push(response);
          context.commit("questionnairesLoaded", questionnaires);
          return response;
        },
        (error) => {
          context.commit("questionnairesError");
        },
      );
    },
    patchQuestionnaire(context, { id, patch }) {
      context.commit("questionnairesLoading");
      return eventService.patchQuestionnaire(id, patch).then(
        (response) => {
          const questionnaires =
            context.state.questionnaire.questionnaires.result;
          const index = questionnaires.findIndex((item) => item.id === id);
          questionnaires[index] = response;
          context.commit("questionnairesLoaded", questionnaires);
          return response;
        },
        (error) => {
          context.commit("questionnairesError");
        },
      );
    },

    deleteQuestionnaire(context, questionnaireId) {
      context.commit("questionnairesLoading");
      eventService.deleteQuestionnaire(questionnaireId).then(
        (result) => {
          const questionnaires =
            context.state.questionnaire.questionnaires.result;
          const index = questionnaires.findIndex(
            (item) => item.id === questionnaireId,
          );
          questionnaires.splice(index, 1);
          context.commit("questionnairesLoaded", questionnaires);
        },
        (error) => {
          context.commit("questionnairesError");
        },
      );
    },
    loadQuestionnaireQuestions(context, questionnaireId) {
      context.commit("questionnaireQuestionsLoading");
      eventService.getQuestionnaireQuestions(questionnaireId).then(
        (result) => {
          context.commit("questionnaireQuestionsLoaded", result.result);
        },
        (error) => {
          context.commit("questionnaireQuestionsError");
        },
      );
    },
    createQuestionnaireQuestion(context, { questionnaireId, question }) {
      context.commit("questionnaireQuestionsLoading");
      eventService.createQuestionnaireQuestion(questionnaireId, question).then(
        (result) => {
          const questionnaireQuestions =
            context.state.questionnaire.questions.result;
          questionnaireQuestions.push(result);
          context.commit(
            "questionnaireQuestionsLoaded",
            questionnaireQuestions,
          );
        },
        (error) => {
          context.commit("questionnaireQuestionsError");
        },
      );
    },
    patchQuestionnaireQuestion(context, { questionnaireId, patch }) {
      context.commit("questionnaireQuestionsLoading");
      return eventService
        .patchQuestionnaireQuestion(questionnaireId, patch.id, patch)
        .then(
          (response) => {
            const questionnaireQuestions =
              context.state.questionnaire.questions.result;
            const index = questionnaireQuestions.findIndex(
              (item) => item.id === patch.id,
            );
            questionnaireQuestions.splice(index, 1, response);
            context.commit(
              "questionnaireQuestionsLoaded",
              questionnaireQuestions,
            );
            return response;
          },
          (error) => {
            context.commit("questionnaireQuestionsError");
          },
        );
    },
    deleteQuestionnaireQuestion(
      context,
      { questionnaireId, questionnaireQuestionId },
    ) {
      context.commit("questionnaireQuestionsLoading");
      eventService
        .deleteQuestionnaireQuestion(questionnaireId, questionnaireQuestionId)
        .then(
          (result) => {
            const questionnaireQuestions =
              context.state.questionnaire.questions.result;
            const index = questionnaireQuestions.findIndex(
              (item) => item.id === questionnaireQuestionId,
            );
            questionnaireQuestions.splice(index, 1);
            context.commit(
              "questionnaireQuestionsLoaded",
              questionnaireQuestions,
            );
          },
          (error) => {
            context.commit("questionnaireQuestionsError");
          },
        );
    },
    async loadQuestionnaireSections(context, questionnaireId) {
      context.commit("questionnaireSectionsLoading");
      const response = await eventService
        .getQuestionnaireSections(questionnaireId)
        .then(
          (response) => {
            context.commit("questionnaireSectionsLoaded", response.result);
          },
          (error) => {
            context.commit("questionnaireSectionsError");
          },
        );
      return response;
    },
    async createQuestionnaireSection(
      context,
      { questionnaireId, name, order },
    ) {
      // const response = await eventsApi.questionnaire.createQuestionnaireSection({questionnaireId, section})
      const response = await eventService
        .postQuestionnaireSection(questionnaireId, name, order)
        .catch((e) => {
          context.actions.loadQuestionnaireSections(questionnaireId);
          throw e;
        });
      console.log("adding", response);
      context.state.questionnaire.sections.result.push(response);
      return response;
    },
    async updateQuestionnaireSection(context, { questionnaireId, section }) {
      context.commit("questionnaireSectionsLoading");
      const response = await eventService
        .patchQuestionnaireSection(questionnaireId, section.id, section)
        .catch((e) => {
          context.actions.loadQuestionnaireSections(questionnaireId);
          throw e;
        });
      context.state.questionnaire.sections.result.splice(
        context.state.questionnaire.sections.result.findIndex(
          (item) => item.id === section.id,
        ),
        1,
        response,
      );
      context.commit(
        "questionnaireSectionsLoaded",
        context.state.questionnaire.sections.result,
      );
      return response;
    },
    async deleteQuestionnaireSection(context, { questionnaireId, sectionId }) {
      const response = await eventService
        .deleteQuestionnaireSection(questionnaireId, sectionId)
        .catch((e) => {
          context.actions.loadQuestionnaireSections(questionnaireId);
          throw e;
        });
      // Remove the section
      context.state.questionnaire.sections.result.splice(
        context.state.questionnaire.sections.result.findIndex(
          (item) => item.id === sectionId,
        ),
        1,
      );
      // removing section behavior is on delete null for questions
      context.state.questionnaire.questions.result =
        context.state.questionnaire.questions.result.map((item) =>
          item.section === sectionId ? { ...item, section: null } : item,
        );
      return response;
    },
  },
  getters: {
    getEventDatesLoadingState: (state) => state.eventDates,
    getEvents: (state) => state.events.result,
    getEventsLoadingState: (state) => state.events,
    getEventDatesPreregistrations: (state) => ({
      loading: state.eventDates.loading || state.preregistrations.loading,
      loaded: state.eventDates.loaded || state.preregistrations.loaded,
      error: state.eventDates.error || state.preregistrations.error,
      result: state.eventDates.result
        .filter((item) => item.hasPreregistration)
        .map((date) => {
          const preregistrationItem = state.preregistrations.result.find(
            (item) => item.dateId === date.id,
          );
          return {
            ...date,
            preregistrations: preregistrationItem
              ? preregistrationItem.preregistrations
              : 0,
          };
        }),
    }),
    mergedExamResults: (state) => {
      return state.examResults.result.map((examResult) => {
        const datePatches = state.examPatches.find(
          (item) => item.eventDateId === examResult.eventDateId,
        );
        return {
          ...examResult,
          users: examResult.users.map((user) => {
            const userPatch = datePatches
              ? datePatches.patches.find((item) => item.id === user.id)
              : {};
            return {
              ...user,
              ...(userPatch ? userPatch : {}),
            };
          }),
        };
      });
    },
    examResultsLoadingState: (state) => state.examResults,
    examPatchesForDate: (state) => (dateId) => {
      const patches = state.examPatches.find(
        (item) => item.eventDateId === dateId,
      );
      if (patches) {
        return patches.patches;
      } else {
        return [];
      }
    },
    hasExamPatches: (state) => state.examPatches.length > 0,
    eventTranslationsLoadingState: (state) => state.translations.loadingState,
    eventTranslations: (state) => state.translations.translations,
    eventTranslationsDefaultLang: (state) => state.translations.defaultLanguage,
    mergedEventTranslations: (state) => {
      return state.translations.translations.map((item) => ({
        ...item,
        translations: item.translations.map((translation) => {
          const copy = { ...translation };
          state.translations.patches
            .filter(
              (patch) =>
                item.type === patch.type &&
                item.id === patch.id &&
                copy.key === patch.key,
            )
            .forEach((patch) => (copy.values[patch.lang] = patch.text));
          return copy;
        }),
      }));
    },
    eventTranslationHasChanges: (state) =>
      state.translations.patches.length > 0,
    questionnaires: (state) => state.questionnaire.questionnaires,
    questionnaireQuestions: (state) => state.questionnaire.questions,
    mergedQuestionnaireQuestions: (state) => {
      return state.questionnaire.questions.result.map((item) => {
        const patch = state.questionnaire.questionPatches.find(
          (patch) => patch.id === item.id,
        );
        return { ...item, ...patch };
      });
    },
    questionnaireSections: (state) =>
      state.questionnaire.sections.result.toSorted((a, b) => a.order - b.order),
    questionnaireSectionsLoadState: (state) => state.questionnaire.sections,
  },
};
