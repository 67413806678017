import {
  stateInitial,
  stateLoading,
  stateLoaded,
  stateError,
} from "./loadingStates";
import userService from "@/services/users";
import { reports } from "@/services/shogun";

export default {
  namespaced: true,
  state: {
    userStateLoadingState: stateInitial,
    userSettingsLoadingState: stateInitial,
  },
  mutations: {
    userStateLoading(state) {
      state.userStateLoadingState = {
        ...stateLoading,
        result: state.userStateLoadingState.result,
      };
    },
    userStateLoaded(state, result) {
      state.userStateLoadingState = { ...stateLoaded, result };
    },
    userStateError(state) {
      state.userStateLoadingState = stateError;
    },
    userSettingsStateLoaded(state, result) {
      state.userSettingsLoadingState = { ...stateLoaded, result };
    },
  },
  actions: {
    loadUserState({ commit, state }, data) {
      if (data.keepCache && state.userStateLoadingState.loaded) {
        return;
      }
      commit("userStateLoading");
      reports.userStates
        .searchUserstates({
          page: 1,
          pageSize: 1,
          requestBody: {
            users: [data.id],
            extras: true,
          },
        })
        .then(
          (response) => {
            commit("userStateLoaded", response.result[0]);
          },
          (error) => {
            commit("userStateError");
          },
        );
    },

    loadUserSettings({ commit, state }, data) {
      return userService.GET_USER_SETTINGS().then(
        (response) => {
          commit("userSettingsStateLoaded", response.data);
        },
        (error) => {
          // Set defaults
          commit("userSettingsStateLoaded", {
            hideCourseDisclaimer: false,
          });
        },
      );
    },

    async updateUserSettings({ dispatch, commit, state }, patch) {
      const settings = state.userSettingsLoadingState.result;
      state.userSettingsLoadingState = { ...stateLoading, result: settings };
      return userService.PUT_USER_SETTINGS({ ...settings, ...patch }).then(
        (response) => {
          commit("userSettingsStateLoaded", response);
        },
        (error) => {
          // Set setting for this session
          commit("userSettingsStateLoaded", { ...settings, ...patch });
        },
      );
    },
  },
  getters: {
    userStateLoadingState: (state) => state.userStateLoadingState,
    userState: (state) => state.userStateLoadingState.result,
    userSettingsLoadingState: (state) => state.userSettingsLoadingState,
  },
};
