<template lang="pug">
el-select.lang-select(v-model="language", filterable, :disabled="disabled")
  template(#prefix)
    .fi.prefix(:class="`fi-${flagFromKey(language)}`")
  el-option(
    v-for="item in languages",
    :key="item.key",
    :value="item.key",
    :label="item.value"
  )
    .flex.flex-center
      .fi.mr-1(:class="`fi-${item.flag}`")
      span {{ item.value }}
</template>

<script>
import { defineComponent, nextTick } from "vue";
import { languages } from "@/localization";
import { getSetting } from "@/getSettings";

export default defineComponent({
  props: {
    modelValue: String,
    disabled: { required: false, default: false },
  },
  data() {
    return {
      languages: languages,
      language: getSetting("defaultLanguage") || "de",
    };
  },
  watch: {
    language(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(v) {
      this.language = v;
    },
  },
  mounted() {
    if (this.modelValue === "") {
      nextTick(() => {
        this.$emit("update:modelValue", this.language);
      });
    } else {
      this.language = this.modelValue;
    }
  },
  methods: {
    flagFromKey(key) {
      const lang = this.languages.find((l) => l.key === key);
      return lang ? lang.flag : "de";
    },
  },
});
</script>

<style lang="stylus">
.fi {
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.12);
}

.lang-select .prefix {
  margin-left: 5px;
}
</style>
