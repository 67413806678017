<template lang="pug">
.step-settings
  label {{ $t("requiresCompletion") }}
  CheckboxInput(
    :model-value="requiresCompletion",
    @update:modelValue="patchStep({ requiresCompletion: $event })"
  )

  label(v-if="showRequiresInteraction") {{ $t("requiresInteraction") }}
  CheckboxInput(
    v-if="showRequiresInteraction",
    :model-value="requiresInteraction",
    @update:modelValue="patchStep({ requiresInteraction: $event })"
  )

  label {{ $t("isEditable") }}
  CheckboxInput(
    :model-value="isEditable",
    @update:modelValue="patchStep({ isEditable: $event })"
  )

  label {{ $t("points") }}
  el-input-number(
    :model-value="points",
    :min="0",
    @update:modelValue="patchStep({ points: $event })"
  )
</template>

<script>
import CheckboxInput from "@/components/elements/CheckboxInput";
import { mapGetters } from "vuex";
import editStepMixin from "./mixins/editStepMixin";

export default {
  components: {
    CheckboxInput,
  },
  mixins: [editStepMixin],
  props: {
    showRequiresInteraction: { type: Boolean, required: false, default: false },
  },
  computed: {
    requiresCompletion() {
      return this.step.requiresCompletion;
    },
    requiresInteraction() {
      return this.step.requiresInteraction;
    },
    isEditable() {
      return this.step.isEditable;
    },
    points() {
      return this.step.points || 0;
    },
  },
};
</script>
