<template lang="pug">
div
RichTextEditor.mb-3(
  v-if="isEditingText",
  ref="editor",
  v-click-away="stopEditText",
  :model-value="text",
  :allow-images="true",
  @update:modelValue="editText"
)
.text-container.ql-editor(
  v-else,
  :class="{ 'txt-inactive': !text }",
  @click="startEditText",
  v-html="text ? text : $t('clickToEdit')"
)
</template>

<script>
import { nextTick } from "vue";
import RichTextEditor from "@/components/elements/RichTextEditor.vue";

export default {
  components: {
    RichTextEditor,
  },
  mixins: [],
  props: {
    text: String,
    editOnStart: { type: Boolean, required: false, default: true },
  },
  emits: ["editText"],
  data: function () {
    return {
      isEditingText: false,
      editorSettings: {
        modules: {
          imageResize: {},
        },
      },
    };
  },
  mounted() {
    this.isEditingText = this.editOnStart;
  },
  methods: {
    focus() {
      this.$refs.editor.focus();
    },
    startEditText() {
      this.isEditingText = true;
      nextTick(() => {
        this.focus();
      });
    },
    stopEditText() {
      this.isEditingText = false;
    },
    editText(event) {
      this.$emit("editText", event);
    },
  },
};
</script>

<style></style>
