import { createStore } from "vuex";
import auth from "./auth";
import courses from "./courses";
import scorm from "./scorm";
import myProgram from "./myProgram";
import admin from "./admin";
import notifications from "./notifications";
import logger from "./logger";
import accounts from "./accounts";
import reports from "./reports";
import capabilites from "./capabilites";
import quizzes from "./quizzes";
import reportingService from "./reportingService";
import flexStore from "./flex";
import flexEditor from "./flex-editor";
import covid from "./covid";
import event from "./event";
import tags from "./tags";
import testcenter from "./testcenter";
import user from "./user";
import automation from "./automation";
import hardwareRestrictions from "./hardwareRestrictions";
import courseLifecycle from "./courseLifecycle";
import loginRedirects from "./loginRedirects";
import userState from "./userState";

export default createStore({
  state: {},
  mutations: {},
  modules: {
    auth,
    admin,
    event,
    logger,
    reports,
    courses,
    scorm,
    accounts,
    myProgram,
    capabilites,
    notifications,
    quizzes,
    reportingService,
    flexStore,
    flexEditor,
    covid,
    tags,
    testcenter,
    user,
    automation,
    hardwareRestrictions,
    courseLifecycle,
    loginRedirects,
    userState,
  },
});
