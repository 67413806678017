<template lang="pug">
.password-input
  el-popover(
    v-model:visible="showHelp",
    trigger="manual",
    :visible="showHelp",
    placement="top",
    :width="400"
  )
    template(#reference)
      el-input(
        v-model="val",
        type="password",
        :placeholder="placeholder",
        show-password,
        @focus="onFocus",
        @blur="onHide",
        @keyup.enter="enter"
      )
    ul.help-message
      li.require.mb-1
        i.icon.icon--success.material-icons(v-if="correctLength") done
        i.icon.material-icons(v-else) clear
        span {{ $t("minimumLength") }}
      li.require.mb-1
        i.icon.icon--success.material-icons(v-if="capitalLetter") done
        i.icon.material-icons(v-else) clear
        span {{ $t("atLeastOneBigAndSmall") }}
      li.require
        i.icon.icon--success.material-icons(v-if="!isBlocked") done
        i.icon.material-icons(v-else) clear
        span {{ $t("passwordNotEqualUsernameAndEmail") }}
</template>
<script>
export default {
  name: "PasswordInput",
  props: {
    modelValue: {
      type: String,
    },
    blocked: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "placeholder",
    },
  },
  data() {
    return {
      showHelp: false,
      val: this.modelValue || "",
      errors: [],
    };
  },
  computed: {
    isValid() {
      return this.correctLength && this.capitalLetter && !this.isBlocked;
    },
    correctLength() {
      return this.val.length >= 8;
    },
    capitalLetter() {
      const oldString = this.val;
      const lowerCase = oldString.toLowerCase();
      const upperCase = oldString.toUpperCase();
      return oldString !== lowerCase && oldString !== upperCase;
    },
    isBlocked() {
      const blocked = this.blocked;
      const value = this.val;
      return blocked.indexOf(value) !== -1;
    },
  },
  watch: {
    val(vl) {
      const value = vl.trim();
      if (value.length === 0) {
        this.$emit("update:modelValue", "");
      } else if (this.isValid) {
        this.$emit("update:modelValue", value);
      }
    },
    correctLength() {
      if (this.isValid) {
        this.$emit("update:modelValue", this.val);
      } else {
        this.$emit("update:modelValue", "");
      }
    },
    capitalLetter() {
      if (this.isValid) {
        this.$emit("update:modelValue", this.val);
      } else {
        this.$emit("update:modelValue", "");
      }
    },
    isBlocked() {
      if (this.isValid) {
        this.$emit("update:modelValue", this.val);
      } else {
        this.$emit("update:modelValue", "");
      }
    },
  },
  methods: {
    onFocus() {
      this.showHelp = true;
    },
    onHide() {
      this.showHelp = false;
    },
    enter() {
      this.$emit("enter");
    },
  },
};
</script>
<style lang="stylus" scoped>
@import "../../settings.styl";


  .require {
    display: flex;
    align-items: center;
    // margin: 5px 0;
  }

  .indicator {
    height: 4px;
  }

  .help-message {
    margin-bottom: 15px;
    padding-left: 8px;

    .icon {
      font-size: 16px;
      color: $incorrect;

      &--success {
        color: $correct;
      }
    }
  }
</style>
