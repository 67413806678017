import services from "@/services/users";
import apiService from "@/services/systemConfiguration";
import flexService from "@/services/flex";
import { reports } from "@/services/shogun";

import {
  stateInitial,
  stateLoading,
  stateLoaded,
  stateError,
} from "./loadingStates";

export default {
  state: {
    users: [],
    usersLoadingState: stateInitial,
    userStatePage: 0,
    userStatePages: 0,
    userStateTotal: 0,
    userStates: [],
    userStatesLoadingState: stateInitial,
    userStateDetail: null,
    userStateDetailLoadingState: stateInitial,
    // This are lists of user ids where a request to edit access respective hasCard is currently active
    userAccessLoading: [],
    userhasCardLoading: [],
    loggingConfig: {
      all: true,
    },
    apisLoadingState: stateInitial,
    apiKeysLoadingState: stateInitial,
    sessionsByUser: [],
    sessionsByUserLoadingState: stateInitial,
    currentUserStateUserId: null,
    sessionsByCourse: [],
    sessionsByCourseLoadingState: stateInitial,
    currentCourseEditCourseId: null,
  },
  mutations: {
    setCurrentUserStateUserId(state, payload) {
      state.currentUserStateUserId = payload;
    },
    setCurrentCourseEditCourseId(state, payload) {
      state.currentCourseEditCourseId = payload;
    },
    loadingUsers(state, payload) {
      state.usersLoadingState = stateLoading;
    },
    errorLoadingUsers(state, payload) {
      state.usersLoadingState = { ...stateError, errorMessage: payload };
    },
    loadedUsers(state, payload) {
      state.usersLoadingState = stateLoaded;
    },

    apisLoading(state) {
      state.apisLoadingState = stateLoading;
    },
    apisLoaded(state, result) {
      state.apisLoadingState = { ...stateLoaded, result };
    },
    apisError(state) {
      state.apisLoadingState = stateError;
    },
    apiKeysLoading(state) {
      state.apiKeysLoadingState = stateLoading;
    },
    apiKeysLoaded(state, result) {
      state.apiKeysLoadingState = { ...stateLoaded, result };
    },
    apiKeysError(state) {
      state.apiKeysLoadingState = stateError;
    },

    apiKeysRemove(state, id) {
      const index = state.apiKeysLoadingState.result.findIndex(
        (item) => id === item.id,
      );
      state.apiKeysLoadingState.result.splice(index, 1);
    },

    apiKeyCreated(state, key) {
      state.apiKeysLoadingState.result.push(key);
    },
    apiKeyUpdated(state, key) {
      const index = state.apiKeysLoadingState.result.findIndex(
        (item) => key.id === item.id,
      );
      state.apiKeysLoadingState.result.splice(index, 1, key);
    },

    loadingUserStates(state, payload) {
      state.userStatesLoadingState = stateLoading;
    },
    errorLoadingUserStates(state, payload) {
      state.userStatesLoadingState = { ...stateError, errorMessage: payload };
    },
    loadedUserStates(state, payload) {
      state.userStatesLoadingState = stateLoaded;
    },

    initUserStates(state, payload) {
      state.userStates = payload.result;
      state.userStatePages = payload.pagesTotal;
      state.userStatePage = payload.page;
      state.userStateTotal = payload.total;
      state.userStatesLoadingState = stateLoaded;
    },

    loadingUserStateDetail(state, payload) {
      state.userStateDetailLoadingState = stateLoading;
    },
    errorLoadingUserStateDetail(state, payload) {
      state.userStateDetailLoadingState = {
        ...stateError,
        errorMessage: payload,
      };
    },
    loadedUserStateDetail(state, payload) {
      state.userStateDetailLoadingState = stateLoaded;
    },

    initUserStateDetail(state, payload) {
      state.userStateDetail = payload;
      state.userStateDetailLoadingState = stateLoaded;
    },

    accessLoading(state, user) {
      state.userAccessLoading.push(user.id);
    },

    hasCardLoading(state, user) {
      state.userhasCardLoading.push(user.id);
    },

    covidRulesSet(state, user) {
      const userState = state.userStates.find((us) => us.id === user.id);
      if (userState) {
        userState.fulfillsCovidRules = true;
      }
    },
    userhasCardSet(state, { user, hasCard }) {
      const userState = state.userStates.find((us) => us.id === user.id);
      if (userState) {
        userState.hasCard = hasCard;
      }
      if (
        state.userStateDetail !== null &&
        state.userStateDetail.id === user.id
      ) {
        state.userStateDetail.hasCard = hasCard;
      }
      state.userhasCardLoading.splice(
        state.userhasCardLoading.findIndex((id) => id === user.id),
        1,
      );
    },

    userAccessSet(state, { user, access }) {
      const userState = state.userStates.find((us) => us.id === user.id);
      if (userState) {
        userState.access = access;
      }
      if (
        state.userStateDetail !== null &&
        state.userStateDetail.id === user.id
      ) {
        state.userStateDetail.access = access;
      }
      state.userAccessLoading.splice(
        state.userAccessLoading.findIndex((id) => id === user.id),
        1,
      );
    },

    initUsers(state, payload) {
      state.usersLoadingState = stateLoaded;
      state.users = [];
      if (payload && payload.length) {
        state.users = payload;
      }
    },
    addUser(state, payload) {
      if (payload) {
        state.users.push(payload);
      }
    },
    initLoggingConfig(state, payload) {
      if (payload) {
        state.loggingConfig = payload;
      }
    },
    deleteUserById(state, payload) {
      const list = state.users;
      const foundItemIndex = list.findIndex((item) => item.id === +payload);
      if (foundItemIndex >= 0) {
        list.splice(foundItemIndex, 1);
      }
    },
    updateUser(state, payload) {
      const list = state.users;
      const foundItem = list.find((item) => item.id === +payload.id);
      if (foundItem) {
        Object.keys(payload).forEach((key) => {
          foundItem[key] = payload[key];
        });
      }
    },
    removeAllUserAccounts(state, payload) {
      const userId = payload.userId;
      if (userId) {
        const user = state.users.find((item) => item.id === userId);
        if (user) {
          user.units = [];
        }
      }
    },
    addUserAccount(state, payload) {
      const user = state.users.find((item) => item.id === payload.userId);
      if (user) {
        if (!user.units) {
          user.units = [];
        }
        user.units.push({
          id: payload.accountId,
          roleId: payload.roleId,
        });
      }
    },
    loadSessionsByUser(state, payload) {
      state.sessionsByUser = payload;
      state.sessionsByUserLoadingState = stateLoaded;
    },
    loadSessionsByCourse(state, payload) {
      state.sessionsByCourse = payload;
      state.sessionsByCourseLoadingState = stateLoaded;
    },
  },
  actions: {
    deleteUserAccountRole({ dispatch, commit }, payload) {
      commit("loadingUsers");
      services.DELETE_USER_ACCOUNT_ROLE(payload).then((data) => {
        const { accountId } = payload;
        dispatch("initUsers", accountId);
      });
    },
    addUserAccountRole({ commit, dispatch }, payload) {
      commit("loadingUsers");
      return services.ADD_USER_ACCOUNT_ROLE(payload).then(
        (response) => {
          dispatch("initUsers");
          return true;
        },
        (errorResponse) => {
          commit("errorLoadingUsers");
          return false;
        },
      );
    },
    initLoggingConfig({ commit }) {
      const savedConfig = localStorage.getItem("loggingConfig");
      if (savedConfig) {
        const parsedConfig = JSON.parse(savedConfig);
        commit("initLoggingConfig", parsedConfig);
      }
    },
    saveLoggingConfig({ commit }, payload) {
      const strigified = JSON.stringify(payload);
      localStorage.setItem("loggingConfig", strigified);
      commit("initLoggingConfig", payload);
      commit("addNewNotification", {
        title: "success",
        text: "loggingConfigSaved",
        notificationType: "success",
      });
    },
    initUsers({ commit, state }, options) {
      if (options && options.keepCache && state.usersLoadingState.loaded) {
        return;
      }
      commit("loadingUsers");
      services.GET_USERS().then(
        (data) => {
          if (data) {
            commit("initUsers", data);
          }
        },
        (error) => {
          commit("errorLoadingUsers", error);
        },
      );
    },
    setAccess({ commit }, { user, access, accessFrom, accessTo, qGate }) {
      commit("accessLoading", user);
      return services
        .SET_USER_ACCESS(user.id, { access, accessFrom, accessTo, qGate })
        .then(
          (response) => {
            // TODO: Currently qGate, from/to is not being used in the state list.
            // If this is to change, the data has to be stored as well.
            commit("userAccessSet", { user, access });
          },
          (error) => {
            commit("errorLoadingUserStateDetail");
            commit("errorLoadingUserStates");
          },
        );
    },
    setHasCard({ commit }, { user, hasCard }) {
      commit("hasCardLoading", user);
      services.SET_USER_ACCESS_CARD(user.id, hasCard).then(
        (response) => {
          commit("userhasCardSet", { user, hasCard });
        },
        (error) => {
          commit("errorLoadingUserStateDetail");
          commit("errorLoadingUserStates");
        },
      );
    },

    //
    initUserStates({ commit }, { page, pageSize, ...requestBody }) {
      commit("loadingUserStates");
      reports.userStates
        .searchUserstates({
          page,
          pageSize,
          requestBody,
        })
        .then(
          (response) => {
            commit("initUserStates", { ...response, page });
          },
          (error) => {
            commit("errorLoadingUserStates");
          },
        );
    },
    initUserStateDetail({ commit }, id) {
      commit("loadingUserStateDetail");
      reports.userStates
        .searchUserstates({
          page: 1,
          pageSize: 1,
          requestBody: {
            users: [id],
            extras: true,
          },
        })
        .then(
          (response) => {
            commit("initUserStateDetail", response.result[0]);
          },
          (error) => {
            commit("errorLoadingUserStateDetail");
          },
        );
    },
    reloadUserStateDetail({ state, dispatch }) {
      if (state.userStateDetail !== null) {
        dispatch("initUserStateDetail", state.userStateDetail.id);
      }
    },

    deleteUserById({ commit }, payload) {
      commit("loadingUsers");
      services
        .DELETE_USER(payload)
        .then((data) => {
          commit("deleteUserById", payload);
          commit("loadedUsers");
          commit("addNewNotification", {
            title: "warning",
            text: "userDeleted",
            notificationType: "warning",
          });
        })
        .catch((err) => {
          commit("errorLoadingUsers", err);
          commit("addNewNotification", {
            title: "error",
            text: err,
            notificationType: "error",
          });
        });
    },
    addUser({ commit, dispatch }, payload) {
      commit("loadingUsers");
      return services.POST_USER(payload).then(
        (data) => {
          dispatch("initUsers");
          return data;
        },
        (error) => {
          commit("errorLoadingUsers", error);
          return error;
        },
      );
    },
    batchAddUsers({ commit }, payload) {
      return services.BATCH_ADD_USERS(payload);
    },
    updateUser({ commit }, payload) {
      return services.UPDATE_USER(payload).then((data) => {
        if (!data.error) {
          commit("updateUser", data);
          commit("addNewNotification", {
            title: "success",
            text: "userUpdated",
            notificationType: "success",
          });
        } else {
          commit("addNewNotification", {
            title: "error",
            text: data.error,
            textAsIs: true,
            notificationType: "error",
          });
        }
      });
    },
    removeAllUserAccounts({ commit }, payload) {
      return services.REMOVE_ALL_USER_ACCOUNTS(payload).then(() => {
        commit("removeAllUserAccounts", payload);
      });
    },
    resetPasswordById({ commit }, payload) {
      return services.RESET_PASSWORD(payload).then((data) => {
        if (data) {
          commit("addNewNotification", {
            title: "success",
            text: "passwordChanged",
            notificationType: "success",
          });
        }
      });
    },
    async loadApiKeys({ commit }) {
      commit("apiKeysLoading");
      await apiService.getApiKeys().then(
        (response) => {
          commit("apiKeysLoaded", response.data.result);
        },
        (error) => {
          commit("apiKeysError");
        },
      );
    },
    async loadApis({ commit, getters }) {
      if (getters.getApis.loaded) {
        return;
      }
      commit("apisLoading");
      await apiService.getAvailableApis().then(
        (response) => {
          commit("apisLoaded", response.data.result);
        },
        (error) => {
          commit("apisError");
        },
      );
    },
    async removeApiKey({ commit, dispatch }, id) {
      await apiService.deleteApiKey(id).then(
        () => {
          commit("apiKeysRemove", id);
        },
        (error) => {
          commit("apiKeysError");
          dispatch("loadApiKeys");
          this.$store.commit("addNewNotification", {
            title: "error",
            text: "genericError",
            notificationType: "error",
          });
        },
      );
    },

    async createApiKey({ commit, dispatch }, key) {
      return await apiService.createApiKey(key).then(
        (response) => {
          dispatch("loadApiKeys");
          return response;
        },
        (error) => {
          commit("apiKeyError");
        },
      );
    },
    async updateApiKey({ commit }, key) {
      return await apiService.updateApiKey(key.id, key).then(
        (response) => {
          commit("apiKeyUpdated", key);
          return response;
        },
        (error) => {
          commit("apiKeyError");
        },
      );
    },
    loadSessionsByUser({ commit }, userId) {
      commit("setCurrentUserStateUserId", userId);
      flexService.getSessionByUserId({ userId }).then(
        (response) => {
          commit("loadSessionsByUser", response.data);
        },
        (error) => {
          this.sessionsByUserLoadingState = { ...stateError, error };
        },
      );
    },
    loadSessionsByCourse({ commit }, courseId) {
      commit("setCurrentCourseEditCourseId", courseId);
      flexService.getSessionByCourseId({ courseId }).then(
        (response) => {
          commit("loadSessionsByCourse", response.data);
        },
        (error) => {
          this.sessionsByCourseLoadingState = { ...stateError, error };
        },
      );
    },
    async deleteCourseSessionUpdateUserList(
      { dispatch, state, commit },
      payload,
    ) {
      await flexService.deleteUserSession(payload).then(
        (response) => {
          commit("addNewNotification", {
            title: "success",
            text: "courseSessionDeleted",
            notificationType: "success",
          });

          if (state.currentUserStateUserId !== null) {
            dispatch("loadSessionsByUser", state.currentUserStateUserId);
          }
          if (state.currentCourseEditCourseId !== null) {
            dispatch("loadSessionsByCourse", state.currentCourseEditCourseId);
          }
        },
        (error) => {
          commit("addNewNotification", {
            title: "error",
            text: error,
            notificationType: "error",
          });
        },
      );
    },
  },
  getters: {
    getLoggingConfig: (state) => state.loggingConfig,

    getUsers: (state) => state.users,
    getUsersLoadingState: (state) => state.usersLoadingState,

    getUserStates: (state) => state.userStates,
    getUserStatesLoadingState: (state) => state.userStatesLoadingState,

    getUserStateDetail: (state) => state.userStateDetail,
    getUserStateDetailLoadingState: (state) =>
      state.userStateDetailLoadingState,

    getUserAccessLoading: (state) => state.userAccessLoading,
    getUserHasCardLoading: (state) => state.userhasCardLoading,

    getUserStatePages: (state) => state.userStatePages,
    getUserStateTotal: (state) => state.userStateTotal,

    getApiKeys: (state) => state.apiKeysLoadingState,
    getApis: (state) => state.apisLoadingState,

    getContentManagers: (state, getters) => {
      const ids = getters.getContentManagersIds;
      const users = state.users;
      return users.filter((item) => {
        if (!item.units || !item.units.length) {
          return false;
        }
        if (item.units.some((itm) => ids.indexOf(itm.roleId) !== -1)) {
          return item;
        }
        return false;
      });
    },

    getSessionsByUser: (state) => state.sessionsByUser,
    getSessionsByUserLoadingState: (state) => state.sessionsByUserLoadingState,
    getSessionsByCourse: (state) => state.sessionsByCourse,
    getSessionsByCourseLoadingState: (state) =>
      state.sessionsByCourseLoadingState,
  },
};
