import { reports } from "@/services/shogun";
import {
  stateError,
  stateInitial,
  stateLoading,
  stateLoaded,
} from "./loadingStates";

import umapService from "@/services/tags";

export default {
  state: {
    userStateViewsLoadingState: { ...stateInitial, result: [] },
    userStateTagsLoadingState: { ...stateInitial, result: [] },
  },
  mutations: {
    userStateViewsLoading(state, payload) {
      state.userStateViewsLoadingState = {
        ...stateLoading,
        result: state.userStateViewsLoadingState.result,
      };
    },
    userStateViewsLoaded(state, payload) {
      state.userStateViewsLoadingState = { ...stateLoaded, result: payload };
    },
    userStateViewsError(state, payload) {
      state.userStateViewsLoadingState = { ...stateError, result: [] };
    },
    removeUserStateView(state, id) {
      state.userStateViewsLoadingState.result.splice(
        state.userStateViewsLoadingState.result.findIndex(
          (item) => item.id === id,
        ),
        1,
      );
    },
  },
  actions: {
    async initUserStateViews({ state, dispatch, commit }, options) {
      if (
        options?.keepCache &&
        (state.userStateViewsLoadingState.loaded ||
          state.userStateViewsLoadingState.loaded)
      ) {
        return;
      }
      commit("userStateViewsLoading");
      const response = await reports.userStatesView
        .searchUserstatesView()
        .catch((e) => {
          commit("userStateViewsError");
          throw e;
        });
      commit("userStateViewsLoaded", response.result);
    },
    async initUserStateTags({ commit, state }, options) {
      if (
        options?.keepCache &&
        (state.userStateTagsLoadingState.loaded ||
          state.userStateTagsLoadingState.loading)
      ) {
        return;
      }
      state.userStateTagsLoadingState = { ...stateLoading, result: [] };

      // NOTE: This is quite a complicated system: see umap for details on the api
      // at first we have to load purposes and find the purpose for `user state`, then we can filter tags by this purpose
      const purposes = await umapService.getPurposes().catch((e) => {
        state.userStateTagsLoadingState = { ...stateError, result: [] };
        throw e;
      });

      const purpose = purposes.result.find(
        (item) => item.name === "user state view",
      );

      if (!purpose) {
        state.userStateTagsLoadingState = { ...stateError, result: [] };
        throw new Error("System misconfiguired");
      }

      const tagValues = await umapService
        .getTagValues({ purposes: [purpose] })
        .catch((e) => {
          state.userStateTagsLoadingState = { ...stateError, result: [] };
          throw e;
        });

      state.userStateTagsLoadingState = { ...stateLoaded, result: tagValues };
    },
  },
  getters: {
    userStateViewsLoadingState: (state) => state.userStateViewsLoadingState,
    userStateViews: (state) => state.userStateViewsLoadingState.result,
    userStateTagsLoadingState: (state) => state.userStateTagsLoadingState,
  },
};
