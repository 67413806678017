import {
  stateError,
  stateInitial,
  stateLoaded,
  stateLoading,
} from "./loadingStates";
import loginRedirectsService from "@/services/loginRedirects";
import LoginRedirectsDb from "./db/loginRedirectsDb";

export default {
  namespaced: true,
  state: {
    loginRedirects: { ...stateInitial, result: [] },
    localLoginRedirects: [],
    localLoginRedirectCapabilititesLoadingState: {
      ...stateInitial,
      result: [],
    },
  },
  mutations: {
    loginRedirectsLoading(state) {
      state.loginRedirects = {
        ...stateLoading,
        result: state.loginRedirects.result,
      };
    },
    loginRedirectsLoaded(state, result) {
      state.loginRedirects = { ...stateLoaded, result: result };
    },
    loginRedirectsError(state) {
      state.loginRedirects = {
        ...stateError,
        result: state.loginRedirects.result,
      };
    },
    updateLocalLoginRedirects(state, loginRedirects) {
      state.localLoginRedirects = loginRedirects;
    },
    removeLocalLoginRedirect(state, id) {
      state.localLoginRedirects = state.localLoginRedirects.filter(
        (k) => k.id !== id,
      );
    },
  },
  actions: {
    async loadLoginRedirects({ commit }) {
      commit("loginRedirectsLoading");
      const response = await loginRedirectsService
        .postLoginRedirectSearch()
        .catch((error) => {
          commit("loginRedirectsError");
          throw error;
        });
      commit("loginRedirectsLoaded", response.data.result);
      return response;
    },
    async addLoginRedirect({ commit, dispatch }, loginRedirect) {
      commit("loginRedirectsLoading");
      const response = await loginRedirectsService
        .putLoginRedirect(loginRedirect)
        .catch((error) => {
          commit("loginRedirectsError");
          throw error;
        });
      dispatch("loadLoginRedirects");
    },
    async patchLoginRedirect({ commit, dispatch }, loginRedirect) {
      commit("loginRedirectsLoading");
      const response = await loginRedirectsService
        .patchLoginRedirect(loginRedirect)
        .catch((error) => {
          commit("loginRedirectsError");
          throw error;
        });
      await dispatch("loadLoginRedirects");
    },
    async removeLoginRedirect({ commit, dispatch }, id) {
      commit("loginRedirectsLoading");
      const response = await loginRedirectsService
        .deleteLoginRedirect(id)
        .catch((error) => {
          commit("loginRedirectsError");
          throw error;
        });
      await dispatch("loadLoginRedirects");
    },
    async initLoginRedirects({ commit, dispatch }) {
      const store = new LoginRedirectsDb();
      await store.openDB();
      const loginRedirects = await store.getAll();

      commit("updateLocalLoginRedirects", loginRedirects);
    },
    async storeLoginRedirectLocally({ state, commit }, loginRedirect) {
      const store = new LoginRedirectsDb();
      await store.openDB();
      await store.insert(loginRedirect);
      // NOTE: currently only one redirect is allowed
      const updatedLoginRedirects = [loginRedirect];
      commit("updateLocalLoginRedirects", updatedLoginRedirects);
    },
    async updateLocalRedirects() {},
    async removeLoginRedirectLocally({ commit, state }, id) {
      const store = new LoginRedirectsDb();
      await store.openDB();
      await store.remove(id);
      commit("removeLocalLoginRedirect", id);
    },
  },
  getters: {
    loginRedirectsLoadingState: (state) => state.loginRedirects,
    localLoginRedirects: (state) => state.localLoginRedirects,
    localLoginRedirectCapabilities: (state) =>
      state.localLoginRedirectCapabilititesLoadingState.result,
    localLoginRedirectCapabilititesLoadingState: (state) =>
      state.localLoginRedirectCapabilititesLoadingState,
  },
};
