import enLocale from "element-plus/dist/locale/en";

export default {
  ...enLocale,
  termsAccept: "Nõustun {0}.",
  tos: "Üldised müügitingimused",
  privacyAccept: "Nõustun {0}.",
  privacyPolicy: "Privaatsuspoliitika",
  resetPassword: "Parooli lähtestamine",
  usernamePasswordRequired: "Palun sisestage kasutajanimi ja parool.",
  passwordTooShort: "Parool peab olema vähemalt 8 tähemärki pikk.",
  wrongCredentials: "Esitatud andmetega ei leitud ühtegi kontot.",
  accountStructureItem: "Osakond",
  addRole: "Rolli lisamine",
  allUserRelatedDeleteText:
    "Sellga kustutatakse kõik kasutajaga seotud andmed pöördumatult.",
  assignRoleModals: "Rolli määramine",
  canBeDownloadedText: "saab alla laadida lõpetatute alla",
  changePassword: "Parooli muutmine",
  changingPasswordFor: "Kasutaja parooli muutmine",
  confirmDeleteText: "Palun sisestage kinnitamiseks kasutajanimi:",
  confirmDeteleBtnText: "Sain aru, kustutage kasutaja",
  confirmPassword: "Parooli kordamine",
  createAccount: "Konto loomine",
  createUser: "Kasutaja loomine",
  currentPassword: "Praegune parool",
  customId: "Kasutaja määratud ID",
  dataPrivacyHeader: "Isikuandmed",
  dataPrivacyText: "Jaotise tekst (sisu järgneb)",
  dataPrivacyTitle: "Jaotise pealkiri",
  dataRelatedToMe: "Minu kohta salvestatud andmete vaatamine",
  dataRelatedToMeTitle: "Salvestatud andmed",
  deleteText: "Kas soovite kindlasti seda kustutada",
  downloadDataRelatedToMe: "Salvestatud andmete allalaadimine",
  fileWithUserInformation: "Fail kasutaja andmetega",
  forgotPassword: "Unustasite parooli?",
  forgotPasswordText:
    "Palun sisestage oma e-posti aadress, et saada link oma parooli lähtestamiseks.",
  importUsers: "Kasutaja importimine",
  newHere: "Olete siin uus?",
  newPassowrd: "Uus parool",
  newPasswordShort: "Uus parool liiga lühike",
  password: "Parool",
  passwordCantBeEmpty: "Parool ei tohi olla tühi",
  passwordsNotMatch: "Paroolid ei kattu",
  pickUser: "Valige kasutaja",
  pleaseFillUpAllFields: "Palun täitke kõik väljad",
  pleaseTypeNewPassword: "Palun sisestage uus parool",
  policyAcceptance: "Tingimustega nõustumine",
  policyAcceptanceSave: "Nõustu",
  acceptPolicies: "Palun nõustuge tingimustega",
  profile: "Profiil",
  role: "Roll",
  roleExpiresOn: "Roll aegub",
  saveDataAboutMe: "minu kohta salvestatud andmed",
  signIn: "Registreeru",
  termsConditionsHeader: "Üldised müügitingimused",
  termsConditionsText: "Jaotise tekst (sisu järgneb)",
  termsConditionsTitle: "Jaotise pealkiri",
  typeInOne: "Sisestage üks kõigi kohta",
  username: "Kasutajanimi",
  firstname: "Eesnimi",
  lastname: "Perekonnanimi",
  usernameMail: "Kasutajanimi või e-posti aadress",
  userNotes: "Märkmed",
  userProfile: "Kasutaja profiil",
  userStatus: "Kasutaja olek",
  wrongCurrentPassword: "Vale kehtiv parool",
  fillAllFields: "Palun täitke kõik nõutavad väljad",
  wrongUsernameOrPassword: "Kasutajanimi või parool on vale",
  minimumLength: "Minimaalne pikkus 8",
  atLeastOneBigAndSmall: "vähemalt 1 väike- ja 1 suurtäht",
  passwordNotEqualUsernameAndEmail:
    "Parool ei kattu kasutajanime ja e-posti aadressiga",
  registrationKey: "Registreerumiskood",
  registration: "Registreerumine",
  notAnEmailAdress: "Palun sisestage kehtiv e-posti aadress.",
  company: "Ettevõte",
  birthday: "Sünnipäev",
  birthdayHint: "Sünnipäev",
  birthyear: "Sünniaasta",
  accountName: "Konto nimi",
  accountType: "Konto liik",
  accountAddress: "Konto omaniku aadress",
  logo: "Logo",
  address: "Aadress",
  contry: "Riik",
  zipCode: "Sihtnumber",
  lessThan: "alla 1 mb",
  defaultLanguage: "Standardkeel",
  accountStatus: "Konto olek",
  accountNotes: "Konto märkmed",
  createAccount: "Konto loomine",
  updateAccount: "Konto värskendamine",
  fileToImport: "Konto märkused",
  importStructure: "Struktuuri importimine",
  addRootAccount: "Lisa Basis Level Account",
  usersPerMonth: "Igakuised kasutajad",
  usersAdded: "Lisatud kasutajad",
  usersDeleted: "Kustutatud kasutajad",
  usersTotal: "Kasutajaid kokku",
  city: "Linn",
  users: "Kasutaja",
  coursesAvailable: "Saadaolevad kursused",
  started: "Alustatud",
  completed: "Lõpetatud",
  openMandatoryAssignments: "Lahtised kohustuslikud määramised",
  mandatoryAssignments: "Kohustuslikud määramised",
  dueMandatoryCompletions: "Kohustuslike kursuste läbimise tähtajad",
  of: "/",
  accountOverview: "Konto ülevaade",
  selectAccount: "Vali konto",
  noAccountsAvailable: "Ühtki kontot pole saadaval",
  completionRate: "Lõpetamise määr",
  averageResult: "Keskmine tulemus",
  courseOverview: "Kursuste ülevaade",
  reportCoursesSearchPlaceholder: "Otsi kursust",
  completedLastWeeks: "Lõpetatud kursused viimase {number} nädala jooksul",
  completedLastMonths: "Lõpetatud kursused viimase {number} kuu jooksul",
  executionTime: "Jagunemine täitmisaja alusel (min)",
  completionLevel: "Jagunemine lõpetamise punktide alusel",
  knowledge: "TEADMISED",
  toolbox: "TÖÖRIISTAKAST",
  lessonCompleted: "Õppetükk lõpetatud",
  lessonFailed: "Õppetükki ei ole läbitud",
  repeatLesson: "Palun korrake õppetükki, et jätkata",
  congratulations: "Palju õnne!",
  courseCompleted: "Kursus lõpetatud",
  percentCompleted: "Vastasite $successRate% küsimusele õigesti.",
  downloadCertificate:
    "Oma sertifikaadi saate igal ajal alla laadida oma profiili jaotisest „Sertifikaadid“.",
  trueFalse: "Õige või vale?",
  seconds: "s",
  noChoiceText: "Kahjuks liiga hilja.",
  right: "Õige!",
  wrong: "Vale",
  edenemine: "$current / $total",
  addCourseContent: "Kursuse sisu lisamine",
  startCourseEditor: "Käivita kursuse redigeerija",
  addSemicolon: "Lisage pealkirjad, mis on eraldatud semikooloniga",
  addToProgram: "Programmile lisamine",
  allAccounts: "Kõik kontod",
  certificate: "Sertifikaat",
  certificates: "Sertifikaadid",
  completed: "Lõpetatud",
  contentIsLoaded: "Sisu on laaditud",
  contentManager: "Sisuhaldur",
  courseCompleted: "Kursus lõpetatud",
  courseContent: "Kursuse sisu",
  courseFormat: "Kursuse formaat",
  coverPhoto: "Kaanepilt",
  editCoverPhoto: "Kaanepildi muutmine",
  addCoverPhoto: "Kaanepildi lisamine",
  additionalDocuments: "Täiendavad dokumendid",
  editAdditionalDocuments: "Täiendavate dokumentide muutmine",
  addAdditionalDocuments: "Täiendavate dokumentide lisamine",
  documents: "Dokumendid",
  courseLables: "Kursuse etiketid",
  courseLanguage: "Kursuse keel",
  courseNotes: "Kursuse märkmed",
  courseStructure: "Struktuur",
  courseTags: "Kursuse sildid",
  courseTitle: "Kursuse nimi",
  createCourse: "Kursuse loomine",
  curator: "KURAATOR",
  description: "Kirjeldus",
  details: "Andmed",
  dueDate: "Kehtib kuni",
  duration: "Kestus",
  exportStatistics: "Statistika eksportimine",
  learningStandard: "Õppestandard",
  licenses: "Litsentsid",
  mandatory: "Kohustuslik kursus",
  noAssessment: "Hetkel pole ühtegi hinnangut saadaval.",
  noCoursesAssigned:
    "Teie kontole pole ühtegi kursust omistatud. Palun külastage ",
  noCoursesAvailable: "Praegu pole ühtegi kursust saadaval.",
  noDueDate: "Aegumiskuupäev puudub",
  overview: "Ülevaade",
  lk: "",
  pickAnAccount: "Vali konto",
  printCertificate: "Prindi sertifikaat",
  publishCourse: "Kursuse avaldamine",
  publishedToAccount: "Avaldatud kontol",
  relatedCourses: "Sarnased kursused",
  remove: "Eemalda",
  removeFromProgram: "Eemalda programmist",
  singleAccount: "Üksik konto",
  startCourse: "Alusta",
  successMessageOne: "Palju õnne, teil on kursus ",
  successMessageThree:
    "Nüüd saate alla laadida või välja printida läbimise tunnistuse. ",
  successMessageTwo: " edukalt läbitud!",
  tags: "Märksõnad",
  unpublishCourse: "Tühista avaldamine",
  updateCourseContent: "Kursuse sisu üleslaadimine",
  updateCourse: "Kursuse värskendamine",
  youDidntfinishCourse: "Te pole veel ühtegi kursust lõpetanud.",
  courseVisibleFor: "Kursus on nähtav",
  accountStructureItem: "Osakond",
  courseAssigmentOptions: "Määramise suvandid",
  assignCourse: "Kursuse määramine",
  pickACourse: "Valige kursus",
  noDocumentsAvailable: "Dokumendid puuduvad",
  nothingRelated: "Sarnased kursused puuduvad",
  nothingWritten: "midagi pole üles märgitud",
  noTagsAvailable: "Märksõnad puuduvad",
  alreadyGlobal: "Kursus on juba üle maailma avaldatud.",
  questionsanswered: "Küsimused",
  completedOn: "Lõpetatud",
  locale: "de-DE",
  quizAuthorizationContent: "Testi autoriseerimine",
  courseNotPublished: "Kursus pole hetkel avaldatud",
  courseNotPublishedForAccount:
    "Kursus pole hetkel avaldatud konto $account jaoks",
  clickToPublishCourse: "Kursuse avaldamine",
  visible: "Nähtav",
  userIsNotContentManager:
    "Te ei ole sisuhaldur ja seepärast ei saa te kursuse sisu muuta.",
  assignmentOpen: "Avatud",
  assignmentStarted: "Alustatud",
  assignmentCompleted: "Lõpetatud",
  dueDatePassed: "Aegumiskuupäev ületatud",
  exitCourse: "Katkesta kursus",
  markAsFinished: "Kursus lõpetatud",
  assignToAllUsers: "Määra kursus kõikidele konto kasutajatele",

  learningPoints: "Õppepunktid",
  learningPointsAbbreviation: "LP",
  back: "Tagasi",
  next: "Edasi",
  responsesMissing: "Enne jätkamist lõpetage kõik punktid",

  configureLogging: "Protokollimise konfigureerimine",
  createAccount: "Konto loomine",
  changeAccount: "Konto muutmine",
  deleteAccount: "Konto kustutamine",
  importAccountStructure: "Konto struktuuri importimine",
  createUser: "Kasutaja loomine",
  importUser: "Kasutaja importimine",
  changeUser: "Kasutaja muutmine",
  deleteUser: "Kasutaja kustutamine",
  assignUserRole: "Rolli määramine",
  resetPassword: "Parooli lähtestamine",
  createCourse: "Kursuse loomine",
  changeCourse: "Kursuse muutmine",
  addQuizContent: "Testi sisu lisamine",
  changeQuizContent: "Testi sisu muutmine",
  addContent: "Kursuse sisu lisamine",
  changeContent: "Kursuse sisu muutmine",
  deleteCourse: "Kursuse kustutamine",
  deleteContent: "Kursuse sisu kustutamine",
  publishCourse: "Kursuse avaldamine",
  unpublishCourse: "Tühista avaldamine",
  assignCourse: "Kursuse määramine",
  unassignCourse: "Tühista kursuse määramine",
  systemMessages: "Süsteemi teated",
  learningManagement: "Õppe haldamine",
  courseManagement: "Kursuse haldamine",
  userManagement: "Kasutaja haldamine",
  organizationStructure: "Organisatsiooni struktuur",
  accountManagement: "Konto haldamine",
  system: "Süsteem",
  learningManagementDetail: "Õppeplaanid ja kursuse määramine",
  courseManagementDetail: "Kursuse sisu haldamine",
  userManagementDetail: "Kasutaja haldamine ja paroolide lähtestamine",
  organizationStructureDetail: "Organisatsiooni struktuuri määramine",
  accountManagementDetail: "Kontode haldamine",
  logsDetail: "Süsteemi logid ja seadistused",
  userCreateSuccess: "Kasutaja loomine õnnestus",
  createOtherUser: "Lisa veel üks kasutaja",
  backToUserOverview: "Tagasi kasutaja ülevaate juurde",
  importMoreUsers: "Muude kasutajate importimine",
  currentRoles: "Praegused rollid",
  numberOfRoles: "Rollide arv",
  numberOfUsers: "Kasutaja",
  deleteAccountMessage:
    "Palun kinnitage, et soovite konto '$account' kustutada.",
  showStatistics: "Statistika kuvamine",
  appendAccount: "Seo konto kontoga $account.",
  courseAssignments: "Määratud",
  courseAssignmentsForCourse: "Kursuse $course määramised",
  mandatoryAssignments: "Kohustusliku kursuse määramised",
  dueDateAssignments: "Tähtajaga",
  dueDatePassed: "Aegub",
  userOrAccount: "Kasutaja või osakond",
  expiryDate: "Aegumiskuupäev",
  deletePublication: "Kustuta avaldamine",
  deletePublicationMessage:
    "Kas olete kindel, et soovite kontol $account avaldatu kustutada?",
  orgStructure: "Osakond",
  type: "Tüüp",
  downloadTemplate: "Malli allalaadimine",
  published: "Avaldatud",
  scorm: "SCORM",
  quiz: "Test",
  staticFile: "PDF",
  editPublication: "Avaldatud materjali töötlemine",
  pleaseDefineCourseContent:
    "Palun määratlege kursuse sisu enne selle avaldamist.",
  usernameMissing: "Kasutajanimi puudub",
  usernameError:
    "Kasutajanimi tohib koosneda ainult tähtnumbrilistest märkidest",
  emailMissing: "E-posti aadress puudub",
  emailError: "Puudub kehtiv e-posti aadress",
  passwordMissing: "Parool puudub",
  passwordError:
    "Parool peab olema vähemalt 8 tähemärki pikk ning sisaldama ühte suur- ja ühte väiketähte.",
  registrationKeyManagement: "Registreerumiskood",
  registrationKeyManagementDetail: "Registreerumiskoodide haldamine",
  addRegistrationKey: "Registreerumiskoodi lisamine",
  registrationLink: "Registreerumislink",
  createKey: "Lisa",
  user_upload_maximum: "Kuni 1000 kasutajat üleslaadimise kohta",
  userState: "Kasutaja olek",
  userStateDetail: "Zugangsberechtigung und Pflichtkurse.",
  testsPassed: "Test lõpetatud",
  access: "Juurdepääs",
  downloadReport: "Aruande allalaadimine",
  success: "Salvestamine õnnestus!",
  warning: "Ettevaatust!",
  error: "Viga!",
  removedFromProgram: "Kursus on programmist eemaldatud",
  addedToProgram: "Kursus on teie programmile lisatud",
  courseCreated: "Kursus on loodud",
  courseNotCreated: "Kursus pole loodud",
  fillUpFields: "Täitke kõik kohustuslikud väljad",
  cookieNotAvailable: "Teie sessioon on aegunud, palun logige uuesti sisse",
  courseNotFinished: "Kursuse läbimise info puudub",
  courseAborted: "Kursus on katkestatud",
  correctAnswer: "Õige vastus",
  wrongAnswer: "Vale vastus",
  courseStarted: "Kursus on alustatud",
  testFailed: "Test pole läbitud",
  courseCompleted: "Kursus on edukalt läbitud",
  imageTooLarge: "Fail ei tohi suurem kui 2 MB",
  wrongFormat: "Vale formaat",
  passwordReset:
    "Sisestatud e-posti aadressile saadeti parooli lähtestamise link",
  passwordChanged: "Parool on läbitud",
  accountAdded: "Konto on lisatud",
  accountUpdated: "Konto on uuendatud",
  accountDeleted: "Konto on kustutatud",
  userImported: "Kasutajad imporditi uuesti",
  userNotImported: "Kasutajaid ei imporditud",
  loggingConfigSaved: "Logi konfiguratsioon on salvestatud",
  userDeleted: "Kasutaja on kustutatud",
  userAdded: "Kasutaja on loodud",
  userUpdated: "Kasutaja on uuendatud",
  GDPRLoaded: "Isikuandmete kaitse üldmäärus on laaditud",
  cookiesDisabled: "Olete küpsised inaktiveerinud",
  wrongCredentials: "Valed sisselogimise andmed",
  fileUploaded: "Teie fail on üles laaditud",
  courseContentUpdated: "Kursuse sisu on uuendatud",
  coursePublished: "Kursus on avaldatud",
  courseUnPublished: "Kursuse avaldamine on tühistatud.",
  serverUnreachable: "Server pole kättesaadav",
  courseContentAdded: "Kursuse sisu lisamine õnnestus.",
  user_upload_exceeded:
    "Liiga palju kasutajaid. Ühe üleslaadimise kohta toetatakse maksimaalselt 1000 kasutajat.",
  selectAccounts: "Valige konto",
  selectAccountToContinue: "Jätkamiseks valige konto",
  accountOverview: "Konto ülevaade",
  users: "Kasutaja",
  courses: "Kursused",
  coursesCompleted: "Lõpetatud kursused",
  completedCoursesPerUser: "Lõpetatud kursused kasutaja kohta",
  learningHours: "Õppetunnid",
  learningHoursPerUser: "Õppetunnid kasutaja kohta",
  courseOverview: "Kursuse ülevaade",
  completed: "Lõpetatud",
  assigned: "Määratud",
  courseDetails: "Andmed",
  selectCourse: "Kursuse valimine",
  details: "Vali",
  type: "Tüüp",
  dueDate: "Kuni",
  mandatory: "Kohustuslik kursus",
  completionRate: "Lõpetamise määr",
  averageScore: "Testi tulemus",
  duration: "Kestus",
  averageDuration: "Keskmine kestus",
  account: "Konto",
  accountName: "Konto nimi",
  accounts: "Kontod",
  active: "Aktiveeritud",
  admin: "Admin",
  all: "Kõik",
  not_found: "Lehekülge ei leitud",
  assessmentStatistics: "Hindamise statistika",
  assignedUsersOnly: "Ainult määratud kasutajad",
  autoIndividual: "Automaatne, individuaalne",
  cancel: "Katkesta",
  cardImage: "Kaardipilt",
  close: "Sulge",
  completed: "Lõpetatud",
  confirm: "Kinnita",
  country: "Riik",
  course: "Kursused",
  courseDescription: "Kursuse kirjeldus",
  courseDocuments: "Kursuse dokumendid",
  courses: "Teek",
  courseStructure: "Kursuse struktuur",
  dataProtection: "Andmekaitse",
  defaultLanguage: "Standardkeel",
  deleteText: "Kas soovite kindlasti seda kustutada",
  delete: "Kustuta",
  details: "Andmed",
  dragAndDrop: "Drag & Drop / Klõpsake lisamiseks",
  dueDate: "Aegumiskuupäev",
  email: "E-post",
  empty: "Tühi",
  exit: "Logi välja",
  expiresOn: "Aegub kuupäeval",
  fileImport: "Imporditav fail",
  image: "Pilt",
  inactive: "Inaktiivne",
  language: "Keel",
  logo: "Logo",
  mandatory: "Kohustuslik kursus",
  monthsLabels: [
    "Jaanuar",
    "Veebruar",
    "Märts",
    "Aprill",
    "Mai",
    "Juuni",
    "Juuli",
    "August",
    "September",
    "Oktoober",
    "November",
    "Detsember",
  ],
  myAccount: "Minu konto",
  myProfile: "Minu profiil",
  myProgram: "Minu programm",
  myRoles: "Minu rollid",
  never: "Mitte kunagi",
  no: "Ei",
  none: "Mitte ükski",
  notFound: "Ei leitud",
  phoneNumber: "Telefoninumber",
  playImage: "Pildi esitamine",
  publishingExpiresOn: "Avaldamine aegub kuupäeval",
  publishTo: "Avaldamine",
  reports: "Aruanne",
  save: "Salvesta",
  search: "Otsi...",
  status: "Olek",
  structureItem: "Osakond",
  termsAndConditions: "Tingimused",
  unlimited: "Piiramatu",
  update: "Värskenda",
  user: "Kasutaja",
  username: "Kasutajanimi",
  users: "Kasutaja",
  visibility: "Konto nähtavus",
  yes: "Jah",
  zipCode: "Sihtnumber",
  info: "Info",
  error: "Viga",
  alert: "Alarm",
  criticalAlert: "Kriitiline alarm",
  navigation: "Navigeerimine",
  note: "Märkus",
  userProfile: "Kasutaja profiil",
  userId: "Kasutaja ID",
  customId: "ID",
  organizationalStructure: "Organisatsiooni struktuur",
  created: "Loomise kuupäev",
  new: "Uus",
  noResults: "Tulemused puuduvad",
  noData: "Andmed puuduvad",
  uploadFile: "Faili üleslaadimine",
  id: "ID",
  name: "Nimi",
  append: "Lisa",
  edit: "Redigeeri",
  format: "Vorming",
  years: "Aastad",
  months: "Kuud",
  weeks: "Nädalad",
  days: "Päevad",
  seconds: "Sekundid",
  minutes: "Minutid",
  hours: "Tunnid",
  date: "Kuupäev",
  message: "Teade",
  fax: "Faks",
  genericError:
    "Kui tekib viga, proovige laadida uus lehekülg. Kui viga ei kao, võtke ühendust süsteemi administraatoriga.",
  action: "Toiming",

  // TODO
  roles: "Rollid",
  properties: "Omadused",
  property: "Omadus",
  assignUserProperty: "Omaduse määramine",
  userProperties: "Kasutaja omadused",
  addTagToUser: "Omaduse määramine",
  showMoreFilters: "Kuva rohkem filtreid",
  assigned: "Määratud",
  unassigned: "Pole määratud",
  assignedOrUnassigned: "Määratud / pole määratud",
  emailAboutChange: "Saada teade e-posti teel",
  showLessFilters: "Peida filter",
  clearFilters: "Eemalda filtrid",
  no_changes_assignment_button: "Muudatused puuduvad",
  assignment: "Määramised",

  upload_picture: "Laadi üles",
  take_picture: "Kinnita",
  access_card: "Juurdepääsukaart",

  assign_course: "Kursuse määramine",
  add_assignment_to_users: "määra kasutajatele $users",
  remove_assignments_from_users: "eemalda kasutajatelt $users määrang",
  update_assignments_for_users: "värskenda kasutajatele $users määramine",
  create_property: 'Omaduse "$property" loomine',
  non_mandatory: "Pole kohustuslik kursus",
  department: "Osakond",
  user_details: "Kasutaja andmed",
  download_file: "Faili allalaadimine",
  your_browser_not_support_this_content:
    "Teie brauser ei toeta seda failiformaati.",
  browser_not_support_pdf:
    "Your browser can't display pdf files. Scroll down to download the pdf instead.",
  upload_file: "Faili üleslaadimine",
  deleteDocument: "Dokumendi kustutamine",
  deleteDocumentConfirmation:
    "Kas olete kindel, et soovite $document kustutada?",
  video_unsupported: "See brauser ei toeta HTML5 videot.",
  confirm: "Kinnita",
  confirmed: "Kinnitatud",
  multiselect: "Võimalik mitme omaduse valimine",
  profile_image: "Profilbild",
  property_type: "Eigenschaften Typ",
  account_details: "Account details",
  remove_assigned_properties: "Zugewiesene Eigenschaften entfernen",
  mandatory_user_properties: "Obligatorische Benutzereigenschaften",

  select_property_first: "Jätkamiseks valige üks omadus.",
  selected_property_already_mandatory: "Valitud omadus on juba kohustuslik.",
  confirm_remove_assigned_properties:
    "Bitte bestätigen sie dass sie alle zugewiesenen Eigenschaften des typs $type entfernen wollen, dieser Schritt kann nicht rückgangig gemacht werden.  .",
  tags_removed_from_users:
    "Alle zugewiesenen Eigenschaften des Typs $type wurden entfernt.",
  add_mandatory_user_property:
    "Bitte wählen sie folgende Merkmale für ihre Tätigkeit aus:",

  upload_certificate: "Zertifikat hochladen",
  delete_certificate: "Zertifikat löschen",
  confirm_delete_certificate:
    "Sind sie sicher, dass sie das Zertifikat $certificateName löschen wollen?",

  certificateName: "Sertifikaadi nimi",
  issuedFrom: "Väljaandja",
  ectsPoints: "ECTS-punktid",
  ects: "ECTS",

  completedInPercent: "Täidetud protsentides",

  issuedAt: "Väljaandmise kuupäev",
  receivedAt: "Kättesaamise kuupäev",
  validUntil: "Kehtib kuni",
  unlimitedValidity: "Piiramatu kehtivus",

  uploadedCertificates: "Üleslaaditud sertifikaadid",

  drag_drop_steps: "Kasutage lohistamist, et lisada elemente vasakust veerust.",
  add_lesson: "Õppetunni lisamine",

  minCompletion: "Punktide protsendiarv õppetüki läbimiseks",
  randomStepOrder: "Üksikute etappide juhuslik järjestus.",
  fixedViewExecutionOrder: "Üksikute ekraanikuvade juhuslik järjestus.",
  allowBackNavigation: "Luba tagasi navigeerimine",
  maxTimeForCompletions: "Maksimaalne aeg õppetüki lõpetamiseks minutites",
  maxRetries: "Maksimaalne korduskatsete arv",
  maxNumberOfCompletions:
    "Maksimaalne korduskatsete arv punktiarvu parandamiseks",

  quiz: "Test",
  text: "Tekst",
  multimedia: "Video",
  pdf: "Pdf",
  inputText: "Teksti sisestamine",
  inputDate: "Kuupäeva sisestamine",

  quiz_description:
    "Esitage kasutajale küsimus ja laske tal mitme vastusevõimaluse seast valida",
  text_description:
    "Näidake kasutajale teksti ja laske tal valikuliselt teksti sisu kinnitada.",
  multimedia_description:
    "Näidake kasutajale videot ja laske tal valikuliselt video sisu kinnitada.",
  pdf_description:
    "Näidake kasutajale PDF-i ja laske tal valikuliselt sisu kinnitada.",
  inputText_description: "Laske kasutajal sisestada tekst.",
  inputDate_description: "Laske kasutajal sisestada kuupäev.",

  lessons: "Õppetunnid",
  new_lesson: "Uus õppetund",

  settings: "Seadistused",
  requiresCompletion: "See etapp tuleb ära teha kursuse jätkamiseks",
  requiresInteraction: "Kasutaja peab selle etapi kinnitama",
  isEditable: "Kasutaja saab seda sammu hiljem uuesti muuta",
  points: "Punktid",
  clickToEdit: "(klõpsa redigeerimiseks)",
  choiceSettings: "Vastuse seadistused",
  choice: "Antwort",
  choiceText: "Vastuse $index tekst",
  choiceExplanation: "Vastuse $index selgitus",
  choiceIsCorrect: "Õige vastus",
  addChoice: "Vastuse lisamine",
  deleteChoice: "Vastuse kustutamine",
  uploadVideoFile: "Videofaili üleslaadimine",
  discardChanges: "Muudatuste tagasilükkamine",
  addInputLabel: "(sildi lisamine)",
  inputType: "Sisestuse liik",
  week: "Nädal",
  month: "Kuu",
  year: "Aasta",
  label: "Silt",
  minDate: "Minimaalne kuupäev",
  maxDate: "Maksimaalne kuupäev",
  oneLine: "Üks rida",
  rows: "Read",
  multipleLine: "Mitu rida",
  minLength: "Minimaalne pikkus",
  maxLength: "Maksimaalne pikkus",
  showWordLimit: "Kuva maksimaalne tähemärkide arv",

  lessonFailedNoMoreRetries: "Te ei läbinud kursust $retries katse järel.",
  lessonFailedMoreRetries:
    "Teil on veel $retries katset selle õppetüki läbimiseks.",
  lessonFailedOneMoreRetries:
    "Teil on veel üks katse selle õppetüki läbimiseks.",
  registrationKeyAlphanumeric:
    "Registreerumiskood (tähtnumbriline, 3 tähemärki)",
  registrationKeyMinimumLength:
    "Registreerumiskood peab olema vähemalt {length} tähemärki pikk.",
  chooseAnAccountToContinue: "Jätkamiseks valige konto.",

  help: "Abi",
  alreadyRegistered: "Juba registreeritud",
  additionalDetails: "Täiendavad andmed",
  additionalDetailsSave: "Kinnita",
  upload: "Üleslaadimine",
  uploadVideo: "Video üleslaadimine",
  uploadPdf: "Pdf-faili üleslaadimine",
  step: "Etapp",
  flexView: "Vaade",
  flexViews: "Vaated",
  lesson: "Õppetunnid",
  dragInThisAreaToDelete:
    "Elementide kustutamiseks lohistage need sellele alale",
  editor: "Redigeerija",
  baseLanguage: "Lähtekeel",
  translation: "Tõlge",
  editCourse: "Kursuse redigeerimine",
  editTranslation: "Tõlke redigeerimine",

  pointTracking: "Punktide kuvamine",
  repeatable: "Korratav",
  completionShowScore: "Punktide koguarvu kuvamine",
  dragAndDropCertificate:
    "Lisamiseks lohistage PDF-faili Drag & Drop funktsiooni abil või klõpsake seda",
  fileName: "Faili nimi",
  fileSize: "Faili suurus",
  userProfileV2: "Kasutaja profiil v2",
  certification: "Sertifikaadid",
  logs: "Logid",
  issuedBy: "Väljaandja",
  issuedOn: "Väljaandmise kuupäev",
  fulfilledInPercent: "Täidetud protsentides",
  plusNewTag: "+ uus päev",
  orSeparator: "Või",
  contentManagerPicker: "Sisuhaldur",
  saveChangesTitle: "Muudatuste salvestamine",
  saveChangesBody:
    "Teil on salvestamata muudatusi.\nKas soovite need salvestada või hüljata?",
  flexLessonLocked: "Kasutaja peab jätkamiseks selle õppetüki lõpetama.",
  flexSubset: "Selle õppetüki ekraanikuvade arv, mida kasutaja peab töötlema.",

  curatorName: "Kuraatori nimi",
  curatorCompany: "Kuraatori ettevõte",
  curatorDescription: "Kuraatori kirjeldus",
  courseCompletedTitle: "Lõpetatud kursuse pealkiri",
  courseCompletedSubtitle: "Lõpetatud kursuse alapealkiri",
  courseCompletedNote: "Lõpetatud kursuse tekst",
  completionShowScore: "Punktide kuvamine pärast kursuse lõpetamist",
  failedCourseInformation: "Tekstikuva kursuse läbimise ebaõnnestumise korral",
  pinPage: "Kuva vaade alati õppetüki alguses või lõpus",
  start: "Algus",
  end: "Lõpp",
  courseDuration: "Kursuse kestus minutites",
  randomizeAnswers: "Vastuste läbisegamine",
  closeEditor: "Redigeerija sulgemine",
  courseNotEditable:
    "See kursus on juba kasutusel ja seetõttu ei saa seda muuta.",
  flex: "Flex",
  copied: "Kopeeritud vahemälusse",
  covidCertificates: "Vaktsineerimistõendid",
  covidCertificatesDetail: "Vaktsineerimis- ja testimistõendite haldamine",
  covidInstructions: "COVID 19-ga seotud juhised",
  covid: "COVID-19",
  addCertificate: "Zertifikat hinzufügen",
  scanQrCode: "QR-koodi skannimine",
  scanQrCodeDescription:
    "Järgmiseid QR-koode saab sisse skannida või üles laadida: vaktsineerimistõendid, PCR- ja antigeeni testi tõendid, tervenemise tõendid",
  openFile: "Datei öffnen",
  openImage: "Bilddatei öffnen",
  validFrom: "Gueltig seit $date",
  validFor: "Gueltig am $date",
  vaccinationCertificatesValid:
    "Teie sertifikaadid vastavad etteantud reeglitele",
  vaccinationCertificatesInvalid:
    "Teie sertifikaadid ei vasta etteantud reeglitele",
  certificateInvalid: "See sertifikaat on kehtetu",
  certificateExpired: "See sertifikaat on aegunud",
  updatedOn: "Värskendatud: $date",
  uploadinCertificate: "Sertifikaadi üleslaadimine",
  certificateUploadError: "Viga sertifikaadi üleslaadimisel",
  certificateNameMismatch: "Sertifikaat on väljastatud teise nime all.",
  certificateBirthdayMismatch:
    "Sertifikaat on väljastatud teise sünnikuupäevaga.",
  certificateExists: "Sertifikaat on juba olemas.",
  certificateUploaded: "Sertifikaadi üleslaadimine õnnestus",
  instructions: "Juhised",
  accessRules: "Juurdepääsu reeglid",
  selectAccessRule: "Juurdepääsu reeglite valimine",
  qrCode: "QR-kood",
  certificateDeleted: "Sertifikaat eemaldatud",
  deleteCertificateConfirmation:
    "Kas olete kindel, et soovite sertifikaadi eemaldada?",
  event: "Sündmus",

  instructions: "Juhised",
  isOnlineEvent: "Veebisündmus",
  eventLink: "Sündmuse link",
  eventLocation: "Asukoht",
  selfRegistration: "Ise registreerumine",
  attendanceConfirmation: "Kohalolek",
  passedConfirmation: "Läbitud",
  mark: "Hinnatud",
  trainer: "Koolitaja",
  attendanceAndGrades: "Kohalolek ja hindamine",
  onlyIfMandatoryCourse: "Kohustuse määramise korral",
  allUsersInAccount: "Kõik konto kasutajad",
  dateManagement: "Tähtaegade haldamine",
  dateManagementDetail: "Tähtaegade ja osalemise tulemuste haldamine",
  courseFilter: "Kursuse filter",

  fromTo: "Alates – kuni",
  capacity: "Mahtuvus",
  unlimitedCapacity: "Piiramatu maht",
  invalidatePreregistration: "Eelregistreerumiste tühistamine",
  allowPreregistration: "Eelregistreerumiste lubamine",
  newDate: "Uus tähtaeg",
  editDate: "Redigeeri tähtaega",
  preenrollments: "Eelregistreerumised",
  enrollments: "Registreerumised",
  preregistrationDate: "Eelregistreerumine $date",
  registered: "Registreeritud",
  notRegistered: "Pole registreeritud",
  registeredAndNotRegistered: "Registreeritud / registreerimata",
  selectCourseToContinue: "Jätkamiseks valige kursus",
  changesSavedSuccessfully: "Muudatuste salvestamine õnnestus",
  cancelRegistrationTitle: "Registreerumise tühistamine",
  cancelRegistrationBody:
    "Kas soovite tõepoolest $name alljärgneva registreerumise tühistada?",
  date: "Tähtaeg",
  dates: "Tähtajad",
  unregister: "Logi välja",
  changeRegistrationTitle: "Registreerumise tühistamine",
  currentRegistration: "$name on juba sellele tähtajale registreeritud",
  changeRegistrationBody:
    "Kas soovite olemasoleva registreerumise tühistada ja luua uue järgmise tähtajaga?",
  preregistrationAvailableRegistrations: "Eelregistreerumised / vabad kohad",
  seatsLeft: "Vabad kohad",
  registrationTitle: "Registreerumine",
  registrationBody:
    "Järgmisele tähtajale registreerimisel määratakse kursus $course kasutajale kohustusliku kursusena.",
  register: "Registreeru",
  myDates: "Minu tähtajad",
  dateDetails: "Tähtaja andmed",
  attendees: "Osalejad",
  fractionOfTotal: "$fraction / $total",
  registrations: "Registreerumised",
  link: "Link",
  notPassed: "Pole läbitud",
  passed: "Läbitud",
  attended: "Osalenud",
  notAttended: "Pole osalenud",
  youAreRegisteredFor: "Olete registreeritud järgmiseks tähtajaks:",
  unlimitedSeats: "Ruumipiirang puudub",
  otherDates: "Muud tähtajad",
  managerWillAssignDate: "Teie haldur registreerib teid tähtajaks.",
  covidInstructionsLabel: "Juhised süsteemi keeles $lang.",
  cameraNotAllowedError:
    "Kaamera kasutamisest keelduti. Laadige lehekülg uuesti või muutke kaamera juurdepääsu sätteid brauseris.",
  cameraNotFoundError:
    "Kaamerat ei leitud. Palun kasutage selle asemel üleslaadimist.",
  cameraNotReadableError:
    "Juurdepääs kaamerale ebaõnnestus. Kontrollige, et mõni teine programm ei kasuta kaamerat.",
  cameraNotAvailable:
    "Teie brauser ei toeta juurdepääsu kaamerale. Palun kasutage selle asemel üleslaadimist.",
  qrCodeUploadError:
    "QR-koodi lugemine ebaõnnestus. Kontrollige, et pildil oleks ainult QR-kood ja vajaduse korral suurendage eraldusvõimet.",
  confirmCovidRulesManuallyTitle: "COVID-19 – visuaalne olekukontroll",
  confirmCovidRulesBody:
    "Käesolevaga kinnitan, et kasutaja poolt $user näidatud tõendid vastavad kehtivale COVID-19 määrusele ja kehtivad tänase päeva kohta.",

  activateApis: "API piirkonna aktiveerimine",
  allowApiManagement: "API haldamise lubamine",
  allowedApis: "Aktiveeritud API-d",
  volume: "Volume in req/day",
  throttle: "Throttle in req/min",
  apis: "API-d",
  apisDetail: "API-võtmete haldus",

  keys: "Kood",
  key: "Kood",
  keyName: "Nimi",
  keyPurpose: "Kasutusotstarve",
  createApiKey: "Koodi lisamine",
  keyCopiedToClipboard: "Võti on kopeeritud vahemälusse.",
  deleteKey: "Koodi kustutamine",
  deleteKeyMessage:
    "Kas olete kindel, et soovite koodi '$name' '$purpose' jaoks kustutada?",
  changeKey: "Schlüssel bearbeiten",
  keyCreatedSuccessfully: "API võti on lisatud",
  keyUpdatedSuccessfully: "Muudatuste salvestamine õnnestus",
  api: "Api",
  addApi: "Api lisamine",
  editApi: "Api redigeerimine",
  example: "Näide",
  apiDetails: "Api andmed",
  flex: "Flex",
  notAvailable: "Pole saadaval",
  method: "Meetod",
  requestBody: "Päring",
  responses: "Vastused",
  parameters: "Parameetrid",
  manualCheck: "Tõendi kontroll",
  isOnline: "Online",
  logout: "Logi välja",
  continue: "Jätka",
  courseFinishedDoYouWantToLogout:
    "Kursus on lõppenud. Kas soovite välja logida või jätkata? Logitakse automaatselt välja {seconds} sekundi pärast.",
  stateless: "kodakondsuseta",
};
