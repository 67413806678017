import getToken from "@/utils/getToken";
import axios from "axios";
import { mapResponse, printError } from "../utils/mapResponse";
import API from "./config";

/**
 *  @module SCORM value service
 */

export default {
  /**
   * API call to get the value for the given key
   * @property {number} userId - user id
   * @property {number} accountId - account id
   * @property {number} courseId - course id
   * @property {string} key - scorm key
   * @returns {string} scorm value
   */
  GET_ALL_VALUES({ userId, accountId, courseId }) {
    return axios
      .get(`${API.scormURL}scorm/accounts/${accountId}/courses/${courseId}`)
      .then(mapResponse)
      .catch(printError)
      .then((valueArray) => {
        return Array.isArray(valueArray) ? arrayToObject(valueArray) : {};
      });
  },
  // /**
  //  * API call to get the value for the given key
  //  * @property {number} userId - user id
  //  * @property {number} accountId - account id
  //  * @property {number} courseId - course id
  //  * @property {string} key - scorm key
  //  * @returns {string} scorm value
  //  */
  // GET({userId, accountId, courseId, key}) {
  //   return fetch(`${API.scormURL}${userId}/accounts/${accountId}/courses/${courseId}/scorm/${key}`, {
  //     headers: {
  //       Authorization: getToken(),
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //     .then(response => response.text());
  // },
  // /**
  //  * API call to get the number of records for a collection
  //  * @property {number} userId - user id
  //  * @property {number} accountId - account id
  //  * @property {number} courseId - course id
  //  * @property {string} key - scorm key
  //  * @returns {integer} number of records for the collection corresponding to the key
  //  */
  // GET_COUNT({userId, accountId, courseId, key}) {
  //   return fetch(`${API.scormURL}${userId}/accounts/${accountId}/courses/${courseId}/scorm/${key}/count`, {
  //     headers: {
  //       Authorization: getToken(),
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //     .then(response => response.text());
  // },
  /**
   * API call to post info log
   * @param {object} paylaod - payload object
   * @typedef {Object} ScormValue
   * @property {number} payload.userId - user id
   * @property {number} payload.accountId - account id
   * @property {number} payload.courseId - course id
   * @property {string} payload.key - scorm key
   * @property {string} payload.value - scorm value
   * @returns {string} added scorm value
   */
  POST({ userId, accountId, courseId, key, value }) {
    const payload = {
      userId,
      accountId,
      courseId,
      key,
      value,
    };
    return axios
      .post(`${API.scormURL}scorm`, payload)
      .then(mapResponse)
      .catch(printError);
  },
};

/**** Helper functions ****/
const arrayToObject = (array) =>
  array.reduce((obj, item) => {
    obj[item.key] = item.value;
    return obj;
  }, {});
