import enLocale from "element-plus/dist/locale/en";

export default {
  ...enLocale,
  termsAccept: "Slažem se sa {0} .",
  tos: "OUP",
  privacyAccept: "Slažem se sa {0} .",
  privacyPolicy: "Izjava o zaštiti podataka",
  resetPassword: "Resetiranje lozinke",
  usernamePasswordRequired:
    "Bitte geben Sie einen Nutzernamen und ein Passwort ein.",
  passwordTooShort: "Lozinka mora imati najmanje 8 znakova.",
  wrongCredentials: "Nije pronađen nijedan nalog s navedenim podacima.",
  accountStructureItem: "Odjeljenje",
  addRole: "Dodati ulogu",
  allUserRelatedDeleteText:
    "Hiermit werden alle nutzerrelevanten Daten von unwiderruflich gelöscht.",
  assignRoleModals: "Dodijeliti ulogu",
  canBeDownloadedText: 'može se preuzeti pod "završen"',
  changePassword: "Promijeniti lozinku",
  changingPasswordFor: "Promijeniti lozinku za korisnika",
  confirmDeleteText: "Za potvrđivanje, navedite ime korisnika:",
  confirmDeteleBtnText: "Razumio/la sam, izbrišite korisnika",
  confirmPassword: "Ponoviti lozinku",
  createAccount: "Kreirati nalog",
  createUser: "Kreirati korisnika",
  currentPassword: "Aktuelna lozinka",
  customId: "Broj ID-a koju definira korisnik",
  dataPrivacyHeader: "Privatnost podataka",
  dataPrivacyText: "Tekst odsječka (sadržaj će uslijediti)",
  dataPrivacyTitle: "Naslov odsječka",
  dataRelatedToMe: "Uvid u sačuvane podatke o meni",
  dataRelatedToMeTitle: "Sačuvani podaci",
  deleteText: "Jeste li sigurni da želite to izbrisati?",
  downloadDataRelatedToMe: "Preuzimanje sačuvanih podataka",
  fileWithUserInformation: "Datoteka s informacijama o korisniku",
  forgotPassword: "Zaboravili ste lozinku?",
  forgotPasswordText:
    "Bitte geben Sie ihre E-Mail-Adresse ein, um einen Link zum zurücksetzen ihres Passwortes zu erhalten.",
  importUsers: "Uvesti korisnika",
  newHere: "Novi ste ovdje?",
  newPassowrd: "Nova lozinka",
  newPasswordShort: "Nova lozinka je prekratka",
  password: "Lozinka",
  passwordCantBeEmpty: "Lozinka ne smije biti prazna",
  passwordsNotMatch: "Lozinke se ne poklapaju",
  pickUser: "Izaberite korisnika",
  pleaseFillUpAllFields: "Popunite sva polja",
  pleaseTypeNewPassword: "Unesite svoju novu lozinku",
  policyAcceptance: "Prihvatanje smjernica",
  policyAcceptanceSave: "Prihvaćanje",
  acceptPolicies: "Prihvatite smjernice",
  profile: "Profil",
  role: "Uloga",
  roleExpiresOn: "Uloga prestaje da vrijedi na dan",
  saveDataAboutMe: "sačuvani podaci o meni",
  signIn: "Prijaviti",
  termsConditionsHeader: "Opći uslovi poslovanja (OUP)",
  termsConditionsText: "Tekst odsječka (sadržaj će uslijediti)",
  termsConditionsTitle: "Naslov odsječka",
  typeInOne: "Navedite jedan za sve",
  username: "Korisničko ime",
  firstname: "Ime",
  lastname: "Prezime",
  usernameMail: "Korisničko ime ili e-mail adresa",
  userNotes: "Zabilješke",
  userProfile: "Korisnički profil",
  userStatus: "Status korisnika",
  wrongCurrentPassword: "Pogrešna aktuelna lozinka",
  fillAllFields: "Popunite sva potrebna polja",
  wrongUsernameOrPassword: "Pogrešno korisničko ime ili lozinka",
  minimumLength: "Najmanja dužina 8",
  atLeastOneBigAndSmall: "najmanje 1 malo i 1 veliko slovo",
  passwordNotEqualUsernameAndEmail:
    "Passwort ist nicht identisch mit dem Nutzernamen und der E-Mail-Adresse",
  registrationKey: "Ključ za registraciju",
  registration: "Registracija",
  notAnEmailAdress: "Navedite jednu važeću e-mail adresu.",
  company: "Firma",
  birthday: "Datum rođenja",
  birthdayHint: "Datum rođenja",
  birthyear: "Godina rođenja",
  accountName: "Naziv naloga",
  accountType: "Vrsta naloga",
  accountAddress: "Adresa vlasnika naloga",
  logo: "Logotip",
  address: "Adresa",
  contry: "Zemlja",
  zipCode: "Poštanski broj",
  lessThan: "manje od 1 mb",
  defaultLanguage: "Standardni jezik",
  accountStatus: "Status naloga",
  accountNotes: "Zabilješke o nalogu",
  createAccount: "Kreirati nalog",
  updateAccount: "Ažurirati nalog",
  fileToImport: "Account notes",
  importStructure: "Uvoz strukture",
  addRootAccount: "Dodati Basis Level Account",
  usersPerMonth: "Mjesečni korisnici",
  usersAdded: "Dodani korisnici",
  usersDeleted: "Izbrisani korisnici",
  usersTotal: "Korisnici Ukupno",
  city: "Grad",
  users: "Korisnik/ci",
  coursesAvailable: "Raspoloživi kursevi",
  started: "Pokrenut",
  completed: "Završen",
  openMandatoryAssignments: "Neizvršene dodjele obaveza",
  mandatoryAssignments: "Dodjele obaveza",
  dueMandatoryCompletions: "Dospjele obaveze treba završiti do",
  of: "od",
  accountOverview: "Pregled naloga",
  selectAccount: "Izaberi nalig",
  noAccountsAvailable: "Nema raspoloživih naloga",
  completionRate: "Stopa završavanja",
  averageResult: "Prosječan rezultat",
  courseOverview: "Pregled kurseva",
  reportCoursesSearchPlaceholder: "Pretraži kurs",
  completedLastWeeks: "Završavanja kursa zadnjih {number} sedmica",
  completedLastMonths: "Završavanja kursa zadnjih {number} mjeseci",
  executionTime: "Raspodjela prema vremenu provođenja (min)",
  completionLevel: "Raspodjela prema završnim bodovima",
  knowledge: "ZNANJE",
  toolbox: "TOOLBOX",
  lessonCompleted: "Lekcija je završena",
  lessonFailed: "Lekcija nije položena",
  repeatLesson: "Za nastavak, ponovite lekciju",
  congratulations: "Čestitamo vam!",
  courseCompleted: "Kurs je završen",
  percentCompleted: "Ispravno ste odgovorili na $successRate% pitanja.",
  downloadCertificate:
    "Sie können jederzeit Ihr Zertifikat unter „Zertifikate“ im Profil herunterladen.",
  trueFalse: "Ispravno ili pogrešno?",
  seconds: "Sek.",
  noChoiceText: "Šteta, prekasno.",
  right: "Ispravno!",
  wrong: "Pogrešno",
  progress: "$current od $total",
  addCourseContent: "Dodati sadržaje kursa",
  startCourseEditor: "Pokrenuti editor kursa",
  addSemicolon: "Dodajte natpise, odvajajući ih semikolonom",
  addToProgram: "Dodati programu",
  allAccounts: "Svi nalozi",
  certificate: "Certifikat",
  certificates: "Certifikati",
  completed: "Završeno",
  contentIsLoaded: "Sadržaj je učitan",
  contentManager: "Upravljač sadržaja",
  courseCompleted: "Kurs je završen",
  courseContent: "Sadržaj kursa",
  courseFormat: "Format kursa",
  coverPhoto: "Naslovna slika",
  editCoverPhoto: "Promijeniti naslovnu sliku",
  addCoverPhoto: "Dodati naslovnu sliku",
  additionalDocuments: "Dodatna dokumentacija",
  editAdditionalDocuments: "Promijeniti dodatnu dokumentaciju",
  addAdditionalDocuments: "Dodati dodatnu dokumentaciju",
  documents: "Dokumentacija",
  courseLables: "Etikete kursa",
  courseLanguage: "Jezik kursa",
  courseNotes: "Zabilješke kursa",
  courseStructure: "Struktura",
  courseTags: "Tag-ovi kursa",
  courseTitle: "Naziv kursa",
  createCourse: "Izrada kursa",
  curator: "KURATOR",
  description: "Opis",
  details: "Detalji",
  dueDate: "Treba provesti do",
  duration: "Trajanje",
  exportStatistics: "Izvoz statistika",
  learningStandard: "Standard učenja",
  licenses: "Licencije",
  mandatory: "Obavezni kurs",
  noAssessment: "Momentalno nema raspoloživih ocjena.",
  noCoursesAssigned:
    "Ihrem Account sind keine Kurse zugeordnet. Bitte besuchen Sie die ",
  noCoursesAvailable: "Momentalno nema raspoloživih kurseva.",
  noDueDate: "Nema datuma za potrebu provođenja",
  overview: "Pregled",
  page: "",
  pickAnAccount: "Izaberi nalog",
  printCertificate: "Štampanje certifikata",
  publishCourse: "Objaviti kurs",
  publishedToAccount: "Objavljeno u nalogu",
  relatedCourses: "Slični kursevi",
  remove: "Ukloniti",
  removeFromProgram: "Ukloniti iz programa",
  singleAccount: "Pojedinačni račun",
  startCourse: "Pokrenuti",
  successMessageOne: "Čestitamo, uspješno ste ",
  successMessageThree:
    "Jetzt können Sie Ihr Abschlusszertifikat herunterladen oder ausdrucken. ",
  successMessageTwo: " završili ovaj kurs!",
  tags: "Natuknice",
  unpublishCourse: "Otkazivanje objave",
  updateCourseContent: "Učitavanje sadržaja kursa",
  updateCourse: "Ažuriranje kursa",
  youDidntfinishCourse: "Niste završili još nijedan kurs.",
  courseVisibleFor: "Kurs je vidljiv za",
  accountStructureItem: "Odjeljenje",
  courseAssigmentOptions: "Opcije za dodjelu",
  assignCourse: "Dodijeliti kurs",
  pickACourse: "Izaberi kurs",
  noDocumentsAvailable: "Nema dokumentacije",
  nothingRelated: "Nema sličnih kurseva",
  nothingWritten: "ništa nije uneseno",
  noTagsAvailable: "Nema natuknica",
  alreadyGlobal: "Kurs je već objavljen diljem svijeta.",
  questionsanswered: "Pitanja",
  completedOn: "Završeno dana",
  locale: "bs-BS",
  quizAuthorizationContent: "Autorizacija kviza",
  courseNotPublished: "Kurs u ovom času nije objavljen",
  courseNotPublishedForAccount:
    "Kurs u ovom času nije objavljen za nalog $account",
  clickToPublishCourse: "Objaviti kurs",
  visible: "Vidljivo",
  userIsNotContentManager:
    "Niste upravitelj sadržaja (content menager) i zato ne možete obrađivati sadržaj kursa.",
  assignmentOpen: "Otvoreno",
  assignmentStarted: "Pokrenut",
  assignmentCompleted: "Završeno",
  dueDatePassed: "Rok je prekoračen",
  exitCourse: "Prekinuti kurs",
  markAsFinished: "Kurs je završen",
  assignToAllUsers: "Dodijeliti kurs na nalog svim korisnicima",

  learningPoints: "Tačke za učenje",
  learningPointsAbbreviation: "TU",
  back: "Nazad",
  next: "Dalje",
  responsesMissing: "Zatvorite sve tačke prije nego što nastavite",

  configureLogging: "Konfiguracija protokoliranja",
  createAccount: "Kreiranje naloga",
  changeAccount: "Izmjena naloga",
  deleteAccount: "Brisanje naloga",
  importAccountStructure: "Uvoz strukture naloga",
  createUser: "Kreirati korisnika",
  importUser: "Uvesti korisnika",
  changeUser: "Izmijeniti korisnika",
  deleteUser: "Izbrisati korisnika",
  assignUserRole: "Dodijeliti ulogu",
  resetPassword: "Resetiranje lozinke",
  createCourse: "Izrada kursa",
  changeCourse: "Izmjena kursa",
  addQuizContent: "Dodavanje sadržaja kviza",
  changeQuizContent: "Izmjena sadržaja kviza",
  addContent: "Dodavanje sadržaja kursa",
  changeContent: "Izmjena sadržaja kursa",
  deleteCourse: "Brisanje kursa",
  deleteContent: "Brisanje sadržaja kursa",
  publishCourse: "Objaviti kurs",
  unpublishCourse: "Otkazivanje objave",
  assignCourse: "Dodijeliti kurs",
  unassignCourse: "Otkazivanje dodjele kursa",
  systemMessages: "Sistemske obavijesti",
  learningManagement: "Upravljanje učenjem",
  courseManagement: "Upravljanje kursevima",
  userManagement: "Upravljanje korisnicima",
  organizationStructure: "Organizacijska struktura",
  accountManagement: "Upravljanje nalogom",
  system: "Sistem",
  learningManagementDetail: "Planovi učenja i dodjela kursa",
  courseManagementDetail: "Upravljanje sadržajima kursa",
  userManagementDetail: "Upravljanje korisnicima i resetiranje lozinki",
  organizationStructureDetail: "Definiranje organizacijske strukture",
  accountManagementDetail: "Upravljanje nalozima",
  logsDetail: "Prijave na sistem (system-logs) i postavke",
  userCreateSuccess: "Korisnik je uspješno kreiran",
  createOtherUser: "Dodati još jednog korisnika",
  backToUserOverview: "Nazad na pregled korisnika",
  importMoreUsers: "Uvoz daljnjih korisnika",
  currentRoles: "Aktuelne uloge",
  numberOfRoles: "Broj uloga",
  numberOfUsers: "Korisnik/ci",
  deleteAccountMessage:
    "Molimo vas da potvrdite da ste nalog '$account' željeli izbrisati.",
  showStatistics: "Prikaz statistika",
  appendAccount: "Nalog staviti u prilog na $account .",
  courseAssignments: "Raspodijeljeno",
  courseAssignmentsForCourse: "Dodjele kursa za $course",
  mandatoryAssignments: "Dodjele obaveznog kursa",
  dueDateAssignments: "S rokom",
  dueDatePassed: "Treba provesti do",
  userOrAccount: "Korisnik ili odjeljenje",
  expiryDate: "Datum isteka",
  deletePublication: "Brisanje objave",
  deletePublicationMessage:
    "Sigurni ste da želite izbrisati objavu za nalog $account ?",
  orgStructure: "Odjeljenje",
  type: "Tip",
  downloadTemplate: "Preuzimanje predloška",
  published: "Objavljeno",
  scorm: "SCORM",
  quiz: "Kviz",
  staticFile: "PDF",
  editPublication: "Obrada objave",
  pleaseDefineCourseContent:
    "Molimo vas da definirate sadržaj kursa prije nego ga objavite.",
  usernameMissing: "Nedostaje korisničko ime",
  usernameError:
    "Korisničko ime se može sastojati samo od alfanumeričkih znakova",
  emailMissing: "Nedostaje e-mail adresa",
  emailError: "E-mail adresa nije važeća",
  passwordMissing: "Nedostaje lozinka",
  passwordError:
    "Lozinka mora imati najmanje 8 znakova, te sadržavati velika i mala slova.",
  registrationKeyManagement: "Ključ za registraciju",
  registrationKeyManagementDetail: "Upravljanje ključevima za registraciju",
  addRegistrationKey: "Dodati ključ za registraciju",
  registrationLink: "Link za registraciju",
  createKey: "Dodati",
  user_upload_maximum: "Do 1000 korisnika po učitavanju (upload)",
  userState: "Status korisnika",
  userStateDetail: "Zugangsberechtigung und Pflichtkurse.",
  testsPassed: "Test je završen",
  access: "Pristup",
  downloadReport: "Preuzimanje izvještaja",
  success: "Uspješno je sačuvano!",
  warning: "Oprez!",
  error: "Greška!",
  removedFromProgram: "Kurs je uklonjen iz vašeg programa",
  addedToProgram: "Kurs je dodan vašem programu",
  courseCreated: "Kurs je postavljen",
  courseNotCreated: "Kurs nije postavljen",
  fillUpFields: "Molimo da ispunite sva obavezna polja",
  cookieNotAvailable:
    "Ihre Sitzung ist nicht mehr gültig, bitte melden Sie sich erneut an",
  courseNotFinished: "Nema informacije o završavanju za kurs",
  courseAborted: "Kurs je prekinut",
  correctAnswer: "Ispravan odgovor",
  wrongAnswer: "Pogrešan odgovor",
  courseStarted: "Kurs je započet",
  testFailed: "Test nije položen",
  courseCompleted: "Kurs je uspješno završen",
  imageTooLarge: "Datoteka bi trebala biti manja od 2 mb",
  wrongFormat: "Pogrešan format",
  passwordReset:
    "Ein Link, um das Passwort zurückzusetzen, wurde an die angegebene E-Mail-Adresse gesendet",
  passwordChanged: "Lozinka je promijenjena",
  accountAdded: "Nalog je dodan",
  accountUpdated: "Nalog je ažuriran",
  accountDeleted: "Nalog je izbrisan",
  userImported: "Korisnici su uvezeni",
  userNotImported: "Korisnici nisu uvezeni",
  loggingConfigSaved: "Konfiguracija prijave je spremljena",
  userDeleted: "Korisnik je izbrisan",
  userAdded: "Korisnik je postavljen",
  userUpdated: "Korisnik je ažuriran",
  GDPRLoaded: "GDPR je učitana",
  cookiesDisabled: "Deaktivirali ste kolačiće",
  wrongCredentials: "Pogrešni podaci prijave",
  fileUploaded: "Vaša datoteka je učitana",
  courseContentUpdated: "Sadržaj kursa je ažuriran",
  coursePublished: "Kurs je objavljen",
  courseUnPublished: "Objava kursa je obustavljena.",
  serverUnreachable: "Server nije dostupan",
  courseContentAdded: "Kurs je uspješno dodan.",
  user_upload_exceeded:
    "Previše je korisnika. Podržava se maksimalno 1000 korisnika po jednom učitavanju (upload).",
  selectAccounts: "Izbor naloga",
  selectAccountToContinue: "Za nastavak izabrati nalog",
  accountOverview: "Pregled naloga",
  users: "Korisnik/ci",
  courses: "Kursevi",
  coursesCompleted: "Završeni kursevi",
  completedCoursesPerUser: "Završeni kursevi po korisniku",
  learningHours: "Sati učenja",
  learningHoursPerUser: "Sati učenja po korisniku",
  courseOverview: "Pregled kurs(ev)a",
  completed: "Završeno",
  assigned: "Dodijeljeno",
  courseDetails: "Detalji",
  selectCourse: "Izabrati kurs",
  details: "Izabrati",
  type: "Tip",
  dueDate: "Do",
  mandatory: "Obavezni kurs",
  completionRate: "Stopa završavanja",
  averageScore: "Rezultat testa",
  duration: "Trajanje",
  averageDuration: "Prosječno trajanje",
  account: "Nalog",
  accountName: "Naziv naloga",
  accounts: "Nalozi",
  active: "Aktivno",
  admin: "Administrator",
  all: "Svi",
  not_found: "Stranica ne postoji",
  assessmentStatistics: "Statistike ocjenjivanja",
  assignedUsersOnly: "Samo dodijeljeni korisnici",
  autoIndividual: "Automatski, individualno",
  cancel: "Prekinuti",
  cardImage: "Slika karte",
  close: "Završiti/zatvoriti",
  completed: "Završeno",
  confirm: "Potvrditi",
  country: "Zemlja",
  course: "Kursevi",
  courseDescription: "Opis kursa",
  courseDocuments: "Dokumentacija kursa",
  courses: "Biblioteka",
  courseStructure: "Struktura kursa",
  dataProtection: "Zaštita podataka",
  defaultLanguage: "Standardni jezik",
  deleteText: "Jeste li sigurni da želite to izbrisati?",
  delete: "Izbrisati",
  details: "Detalji",
  dragAndDrop: "Drag & Drop / Kliknuti da biste dodali",
  dueDate: "Datum do kad treba provesti",
  email: "E-mail",
  empty: "Prazno",
  exit: "Odjaviti",
  expiresOn: "Ne vrijedi na dan",
  fileImport: "Datoteka za uvoz",
  image: "Slika",
  inactive: "Neaktivno",
  language: "Jezik",
  logo: "Logotip",
  mandatory: "Obavezni kurs",
  monthsLabels: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ],
  myAccount: "Moj nalog",
  myProfile: "Moj profil",
  myProgram: "Moj program",
  myRoles: "Moje uloge",
  never: "Nikada",
  no: "Ne",
  none: "Nema",
  notFound: "Nije pronađeno",
  phoneNumber: "Telefonski broj",
  playImage: "Reproduktovati sliku",
  publishingExpiresOn: "Datum isteka objave",
  publishTo: "Objaviti u",
  reports: "Izvještaj",
  save: "Sačuvati",
  search: "Pretraži...",
  status: "Status",
  structureItem: "Odjeljenje",
  termsAndConditions: "Uslovi poslovanja",
  unlimited: "Neograničeno",
  update: "Ažuriranje",
  user: "Korisnik/ci",
  username: "Korisničko ime",
  users: "Korisnik/ci",
  visibility: "Vidljivost naloga",
  yes: "Da",
  zipCode: "Poštanski broj",
  info: "Informacija",
  error: "Greška",
  alert: "Alarm",
  criticalAlert: "Kritični alarm",
  navigation: "Navigacija",
  note: "Napomena",
  userProfile: "Korisnički profil",
  userId: "ID korisnika",
  customId: "ID",
  organizationalStructure: "Organizacijska struktura",
  created: "Kreirano dana",
  new: "Novo",
  noResults: "Nema rezultata",
  noData: "Nema podataka",
  uploadFile: "Učitavanje/slanje datoteke",
  id: "ID",
  name: "Naziv",
  append: "Dodati",
  edit: "Obraditi",
  format: "Format",
  years: "Godine",
  months: "Mjeseci",
  weeks: "Sedmice",
  days: "Dani",
  seconds: "Sekunde",
  minutes: "Minute",
  hours: "Sati",
  date: "Datum",
  message: "Obavijest",
  fax: "Faks",
  genericError:
    "Došlo je do greške, pokušajte ponovo učitati stranicu. Ako se greška i dalje pojavljuje, kontaktirajte administratora sistema.",
  action: "Akcija",

  // TODO
  roles: "Uloge",
  properties: "Karakteristike",
  property: "Karakteristika",
  assignUserProperty: "Dodijeliti karakteristiku",
  userProperties: "Karakteristike korisnika",
  addTagToUser: "Dodijeliti karakteristiku",
  showMoreFilters: "Prikazati više filtera",
  assigned: "Dodijeljeno",
  unassigned: "Nije dodijeljeno",
  assignedOrUnassigned: "Dodijeljeno/Nije dodijeljeno",
  emailAboutChange: "Obavijest poslati preko e-maila",
  showLessFilters: "Isključiti filter",
  clearFilters: "Ukloniti filter",
  no_changes_assignment_button: "Nema promjena",
  assignment: "Dodjele",

  upload_picture: "Učitati",
  take_picture: "Prihvatiti",
  access_card: "Pristupna kartica",

  assign_course: "Dodijeliti kurs",
  add_assignment_to_users: "na $users dodijeliti",
  remove_assignments_from_users: "od $users ukloniti dodjelu",
  update_assignments_for_users: "za $users ažurirati dodjelu",
  create_property: 'Karakteristiku "$property" kreirati',
  non_mandatory: "Nije obavezni kurs",
  department: "Odjeljenje",
  user_details: "Podaci korisnika",
  download_file: "Preuzimanje datoteke",
  your_browser_not_support_this_content:
    "Tvoj preglednik ne podržava ovaj format datoteke.",
  browser_not_support_pdf:
    "Your browser can't display pdf files. Scroll down to download the pdf instead.",
  upload_file: "Učitavanje/slanje datoteke",
  deleteDocument: "Brisanje dokumenta",
  deleteDocumentConfirmation: "Sigurni ste da želite izbrisati $document?",
  video_unsupported: "Ovaj preglednik ne podržava HTML5 video.",
  confirm: "Potvrditi",
  confirmed: "Potvrđeno",
  multiselect: "Dopustiti izbor više karakteristika",
  profile_image: "Profilbild",
  property_type: "Eigenschaften Typ",
  account_details: "Account details",
  remove_assigned_properties: "Zugewiesene Eigenschaften entfernen",
  mandatory_user_properties: "Obligatorische Benutzereigenschaften",

  select_property_first: "Izaberite neku karakteristiku radi nastavka.",
  selected_property_already_mandatory:
    "Izabrana karakteristika je već obligatorna.",
  confirm_remove_assigned_properties:
    "Bitte bestätigen sie dass sie alle zugewiesenen Eigenschaften des typs $type entfernen wollen, dieser Schritt kann nicht rückgangig gemacht werden.  .",
  tags_removed_from_users:
    "Alle zugewiesenen Eigenschaften des Typs $type wurden entfernt.",
  add_mandatory_user_property:
    "Bitte wählen sie folgende Merkmale für ihre Tätigkeit aus:",

  upload_certificate: "Zertifikat hochladen",
  delete_certificate: "Zertifikat löschen",
  confirm_delete_certificate:
    "Sind sie sicher, dass sie das Zertifikat $certificateName löschen wollen?",

  certificateName: "Naziv certifikata",
  issuedFrom: "Izdan od strane",
  ectsPoints: "ECTS bodova",
  ects: "ECTS",

  completedInPercent: "Ispunjeno u procentima",

  issuedAt: "Izdano dana",
  receivedAt: "Primljeno dana",
  validUntil: "Važi do",
  unlimitedValidity: "Neograničeno važenje",

  uploadedCertificates: "Učitani/poslani certifikati",

  drag_drop_steps:
    'Koristite "Drag and Drop" kako biste umetnuli elemente iz lijevog stupca.',
  add_lesson: "Dodati lekciju",

  minCompletion: "Broj bodova u procentima za postizanje prolaska lekcije",
  randomStepOrder: "Nasumični raspored pojedinih koraka.",
  fixedViewExecutionOrder: "Nasumični raspored pojedinih prikaza.",
  allowBackNavigation: "Dopustiti navigaciju unazad",
  maxTimeForCompletions: "Maksimalno vrijeme za završavanje lekcije u minutama",
  maxRetries: "Maksimalni broj ponavljanja",
  maxNumberOfCompletions:
    "Maksimalni broj ponavljanja za poboljšanje broja bodova",

  quiz: "Kviz",
  text: "Tekst",
  multimedia: "Video",
  pdf: "Pdf",
  inputText: "Unos teksta",
  inputDate: "Unos datuma",

  quiz_description:
    "Postavite korisniku pitanje i dopustite mu da izabere jedan od nekoliko mogućih odgovora.",
  text_description:
    "Prikažite korisniku tekst i dopustite mu da, ako želi, potvrdi sadržaj teksta.",
  multimedia_description:
    "Prikažite korisniku video i dopustite mu da, ako želi, potvrdi sadržaj videa.",
  pdf_description:
    "Prikažite korisniku PDF i dopustite mu da, ako želi, potvrdi sadržaj.",
  inputText_description: "Dopustite korisniku da unese tekst.",
  inputDate_description: "Dopustite korisniku da unese datum.",

  lessons: "Lekcije",
  new_lesson: "Nova lekcija",

  settings: "Postavke",
  requiresCompletion:
    "Korak mora biti izveden, kako bi se kurs mogao dalje obrađivati",
  requiresInteraction: "Korisnik mora potvrditi ovaj korak",
  isEditable: "Korisnik može ovaj korak naknadno obraditi",
  points: "Bodovi",
  clickToEdit: "(kliknuti za obradu)",
  choiceSettings: "Postavke odgovora",
  choice: "Antwort",
  choiceText: "Tekst $index odgovora",
  choiceExplanation: "Objašnjenje $index odgovora",
  choiceIsCorrect: "Ispravan odgovor",
  addChoice: "Dodati odgovor",
  deleteChoice: "Izbrisati odgovor",
  uploadVideoFile: "Učitati/poslati video",
  discardChanges: "Odbaciti izmjene",
  addInputLabel: "(dodati label)",
  inputType: "Vrsta unosa",
  week: "Sedmica",
  month: "Mjesec",
  year: "Godina",
  label: "Label",
  minDate: "Minimalni datum",
  maxDate: "Maksimalni datum",
  oneLine: "od jednog retka",
  rows: "Retci",
  multipleLine: "od više redaka",
  minLength: "Minimalna dužina",
  maxLength: "Maksimalna dužina",
  showWordLimit: "Prikazati maksimalni broj znakova",

  lessonFailedNoMoreRetries: "Nakon $retries pokušaja niste položili kurs.",
  lessonFailedMoreRetries:
    "Imate pravo na još $retries pokušaja, kako biste položili lekciju i kurs.",
  lessonFailedOneMoreRetries:
    "Imate pravo na još jedan pokušaj, kako biste položili lekciju i kurs.",
  registrationKeyAlphanumeric:
    "Ključ za registraciju (alfanumerički, min. 3 znakova)",
  registrationKeyMinimumLength:
    "Ključ za registraciju mora imati najmanje {length} znakova.",
  chooseAnAccountToContinue: "Za nastavak, izaberite račun.",

  help: "Pomoć",
  alreadyRegistered: "Već registriran",
  additionalDetails: "Dodatni navodi",
  additionalDetailsSave: "Potvrditi",
  upload: "Učitati (upload)",
  uploadVideo: "Učitati video",
  uploadPdf: "Učitati pdf",
  step: "Korak",
  flexView: "Prikaz",
  flexViews: "Prikazi",
  lesson: "Lekcije",
  dragInThisAreaToDelete:
    "Povucite elemente u ovo područje, kako biste ih izbrisali",
  editor: "Editor/uređivač",
  baseLanguage: "Izvorni jezik",
  translation: "Prijevod",
  editCourse: "Obraditi kurs",
  editTranslation: "Obraditi prijevod",

  pointTracking: "Prikazati bodove",
  repeatable: "Može se ponoviti",
  completionShowScore: "Prikazati cjelokupan broj bodova",
  dragAndDropCertificate:
    'Povucite PDF datoteku metodom "Drag & Drop" ili kliknite za dodavanje',
  fileName: "Naziv datoteke",
  fileSize: "Veličina datoteke",
  userProfileV2: "Korisnički profil v2",
  certification: "Certifikati",
  logs: "Podaci za prijavu (logs)",
  issuedBy: "Izdan od strane",
  issuedOn: "Izdano dana",
  fulfilledInPercent: "Ispunjeno u procentima",
  plusNewTag: "+ Novi dan",
  orSeparator: "Ili",
  contentManagerPicker: "Upravljač sadržaja",
  saveChangesTitle: "Sačuvati izmjene",
  saveChangesBody:
    "Imate nesačuvane izmjene.\nDa ih se sačuva ili da ih se odbaci?",
  flexLessonLocked: "Za nastavak, korisnik mora završiti ovu lekciji.",
  flexSubset: "Broj prikaza iz ove lekcije koje korisnik mora obraditi.",

  curatorName: "Ime i prezime kuratora",
  curatorCompany: "Preduzeće kuratora",
  curatorDescription: "Opis kuratora",
  courseCompletedTitle: "Kurs je završen, naslov",
  courseCompletedSubtitle: "Kurs je završen, podnaslov",
  courseCompletedNote: "Kurs je završen, tekst",
  completionShowScore: "Prikaz bodova nakon završenog kursa",
  failedCourseInformation: "Prikaz teksta kod nepoloženog kursa",
  pinPage: "Pregled uvijek pokazati na početku ili na kraju lekcije",
  start: "Početak",
  end: "Kraj",
  courseDuration: "Trajanje kursa u minutama",
  randomizeAnswers: "Izmiješati odgovore",
  closeEditor: "Zatvoriti editor",
  courseNotEditable:
    "Ovaj kurs se već koristi i zato ga se ne može editirati/uređivati.",
  flex: "Fleks",
  copied: "Kopirano u privremeni spremnik",
  covidCertificates: "Certifikati o vakcini",
  covidCertificatesDetail: "Upravljanje certifikatima vakcinisanja i testova",
  covidInstructions: "Upute relevantne za COVID-19",
  covid: "COVID-19",
  addCertificate: "Zertifikat hinzufügen",
  scanQrCode: "Uskenirati QR kod",
  scanQrCodeDescription:
    "Sljedeće QR kodove može se uskenirati ili učitati/poslati: Certifikati o vakcini, PCR-ima i antigenskim testovima, certifikati o preboljenju kovida",
  openFile: "Datei öffnen",
  openImage: "Bilddatei öffnen",
  validFrom: "Gueltig seit $date",
  validFor: "Gueltig am $date",
  vaccinationCertificatesValid:
    "Vaši certifikati odgovaraju propisanim pravilima",
  vaccinationCertificatesInvalid:
    "Vaši certifikati ne odgovaraju propisanim pravilima",
  certificateInvalid: "Ovaj certifikat nije važeći",
  certificateExpired: "Ovaj certifikat je istekao",
  updatedOn: "Ažurirano dana $date",
  uploadinCertificate: "Učitati certifikat",
  certificateUploadError: "Greška prilikom učitavanja certifikata",
  certificateNameMismatch: "Certifikat je izdan na drugo ime.",
  certificateBirthdayMismatch:
    "Certifikat je izdan na drugačiji datum rođenja.",
  certificateExists: "Certifikat već postoji.",
  certificateUploaded: "Certifikat je uspješno učitan",
  instructions: "Instrukcije",
  accessRules: "Pravila za pristup",
  selectAccessRule: "Izabrati pravila za pristup",
  qrCode: "QR kod",
  certificateDeleted: "Certifikat je uklonjen",
  deleteCertificateConfirmation:
    "Jeste sigurni da želite ukloniti ovaj certifikat?",
  event: "Događaj",

  instructions: "Instrukcije",
  isOnlineEvent: "Online događaj",
  eventLink: "Link na događaj",
  eventLocation: "Mjesto",
  selfRegistration: "Samostalna registracija",
  attendanceConfirmation: "Prisustvo",
  passedConfirmation: "Položen",
  mark: "Ocijenjen",
  trainer: "Trener",
  attendanceAndGrades: "Prisustvo i ocjena",
  onlyIfMandatoryCourse: "Kod dodjele obaveze",
  allUsersInAccount: "Svi korisnici naloga",
  dateManagement: "Upravljanje terminima",
  dateManagementDetail: "Upravljanje terminima i rezultati učestvovanja",
  courseFilter: "Filter kursa",

  fromTo: "Od - do",
  capacity: "Kapacitet",
  unlimitedCapacity: "Neograničeni kapacitet",
  invalidatePreregistration: "Propadanje prednajava",
  allowPreregistration: "Dopustiti prednajave",
  newDate: "Novi termin",
  editDate: "Obraditi termin",
  preenrollments: "Prednajave",
  enrollments: "Najave",
  preregistrationDate: "Prednajava $date",
  registered: "Prijavljen/a",
  notRegistered: "Nije prijavljen/a",
  registeredAndNotRegistered: "Prijavljen(a) / Nije prijavljen(a)",
  selectCourseToContinue: "Za nastavak, izabrati kurs",
  changesSavedSuccessfully: "Izmjene su uspješno sačuvane",
  cancelRegistrationTitle: "Odjava",
  cancelRegistrationBody:
    "Zaista želite da se $name odjavite sa sljedećeg termina?",
  date: "Termin",
  dates: "Termini",
  unregister: "Odjaviti",
  changeRegistrationTitle: "Izmjena prijave",
  currentRegistration: "$name je već prijavljen(a) za sljedeći termin",
  changeRegistrationBody:
    "Dali želite otkazati postojeću i kreirati novu prijavu za sljedeći termin?",
  preregistrationAvailableRegistrations: "Prednajave / Slobodna mjesta",
  seatsLeft: "Slobodna mjesta",
  registrationTitle: "Prijava",
  registrationBody:
    "Prijavom na sljedeći termin, kurs $course će biti dodijeljen korisniku kao obavezni kurs.",
  register: "Prijaviti",
  myDates: "Moji termini",
  dateDetails: "Detalji termina",
  attendees: "Učesnika",
  fractionOfTotal: "$fraction od $total",
  registrations: "Registracije",
  link: "Link",
  notPassed: "Nije položen",
  passed: "Položen",
  attended: "Učestvovalo",
  notAttended: "Nije učestvovalo",
  youAreRegisteredFor: "Vi ste prijavljeni za sljedeći termin:",
  unlimitedSeats: "Nema ograničenja za mjesta",
  otherDates: "Ostali termini",
  managerWillAssignDate: "Vas će prijaviti vaš menadžer za određeni termin.",
  covidInstructionsLabel: "Instrukcije u sistemskom jeziku $lang.",
  cameraNotAllowedError:
    "Korištenje kamere je odbijeno. Ponovo učitajte stranicu ili promijenite pristup kameri u postavkama preglednika.",
  cameraNotFoundError:
    "Kamera nije pronađena. Molimo vas da umjesto toga koristite upload.",
  cameraNotReadableError:
    "Nije bilo moguće pristupiti kameri. Pobrinite se da nijedan drugi program ne koristi kameru.",
  cameraNotAvailable:
    "Vaš preglednik ne podržava pristup kameri. Molimo vas da umjesto toga koristite upload.",
  qrCodeUploadError:
    "QR kod nije bilo moguće očitati. Pobrinite se da postoji samo jedan QR kod na slici i povećajte rezoluciju ako je potrebno.",
  confirmCovidRulesManuallyTitle: "COVID-19 – Vizualna kontrola statusa",
  confirmCovidRulesBody:
    "Ovime potvrđujem da dokazi koje prikazuje $user odgovaraju aktuelnoj regulativi o COVID-19 i da su važeći za današnji dan.",

  activateApis: "Aktivirati područje API-ja",
  allowApiManagement: "Dopustiti upravljanje API-ja",
  allowedApis: "Odobreni API-ji",
  volume: "Volume in req/day",
  throttle: "Throttle in req/min",
  apis: "APIs",
  apisDetail: "Upravljanje API-ključem",

  keys: "Ključ(evi)",
  key: "Ključ(evi)",
  keyName: "Naziv",
  keyPurpose: "Svrha upotrebe",
  createApiKey: "Dodati ključ",
  keyCopiedToClipboard: "Ključ je kopiran u privremeni spremnik.",
  deleteKey: "Izbrisati ključ",
  deleteKeyMessage:
    "Sind sie sicher, dass sie den Schlüssel '$name' für '$purpose' löschen wollen?",
  changeKey: "Schlüssel bearbeiten",
  keyCreatedSuccessfully: "Api ključ je dodan",
  keyUpdatedSuccessfully: "Izmjene su uspješno spremljene",
  api: "Api",
  addApi: "Dodati Api",
  editApi: "Obraditi Api",
  example: "Primjer",
  apiDetails: "Api detalji",
  flex: "Fleks",
  notAvailable: "Nije raspoloživo",
  method: "Metoda",
  requestBody: "Upit",
  responses: "Odgovoriti",
  parameters: "Parametar",
  manualCheck: "Kontrola dokaza",
  isOnline: "Online",
  logout: "Odjava",
  continue: "Nastavi",
  courseFinishedDoYouWantToLogout:
    "Kurs je završen. Da li želite da se odjavite ili nastavite? Bićete automatski odjavljeni za {seconds}.",
  stateless: "bez državljanstva",
};
