import skLocale from "element-plus/dist/locale/sk";

export default {
  ...skLocale,

  termsAccept: "Súhlasím s {0}.",
  tos: "VOP",
  privacyAccept: "Súhlasím s {0}.",
  privacyPolicy: "Vyhlásenie o ochrane osobných údajov",
  resetPassword: "Resetovať heslo",
  usernamePasswordRequired: "Zadajte užívateľské meno a heslo.",
  passwordTooShort: "Heslo musí mať najmenej 8 znakov.",
  wrongCredentials: "Nenašiel sa žiadny účet so zadanými údajmi.",
  accountStructureItem: "Oddelenie",
  addRole: "Pridať úlohu",
  allUserRelatedDeleteText:
    "Týmto sa nenávratne vymažú všetky údaje relevantné pre používateľa.",
  assignRoleModals: "Priradiť úlohu",
  canBeDownloadedText: "možno stiahnuť v dokončených",
  changePassword: "Zmeniť heslo",
  changingPasswordFor: "Zmeniť heslo pre používateľa",
  confirmDeleteText: "Pre potvrdenie zadajte užívateľské meno:",
  confirmDeteleBtnText: "Porozumel/a som, vymazať používateľa",
  confirmPassword: "Zopakovať heslo",
  createAccount: "Vytvoriť účet",
  createUser: "Vytvoriť užívateľa",
  currentPassword: "Aktuálne heslo",
  customId: "Používateľom definované ID",
  dataPrivacyHeader: "Ochrana osobných údajov",
  dataPrivacyText: "Text odseku (obsah bude nasledovať)",
  dataPrivacyTitle: "Nadpis odseku",
  dataRelatedToMe: "Zobraziť o mne uchovávané údaje",
  dataRelatedToMeTitle: "Uchovávané údaje",
  deleteText: "Naozaj ste si istí vymazaním",
  downloadDataRelatedToMe: "Stiahnuť uchovávané údaje",
  fileWithUserInformation: "Súbor s informáciami o používateľovi",
  forgotPassword: "Zabudli ste heslo?",
  forgotPasswordText:
    "Zadajte vašu e-mailovú adresu, aby ste dostali odkaz na resetovanie hesla.",
  importUsers: "Importovať užívateľa",
  newHere: "Ste tu noví?",
  newPassowrd: "Nové heslo",
  newPasswordShort: "Nové heslo príliš krátke",
  password: "Heslo",
  passwordCantBeEmpty: "Heslo nemôže byť prázdne",
  passwordsNotMatch: "Heslá sa nezhodujú",
  pickUser: "Vyberte používateľa",
  pleaseFillUpAllFields: "Vyplňte všetky polia",
  pleaseTypeNewPassword: "Zadajte vaše nové heslo",
  policyAcceptance: "Prijatie zásad",
  policyAcceptanceSave: "Akceptovať",
  acceptPolicies: "Akceptujte zásady",
  profile: "Profil",
  role: "Úloha",
  roleExpiresOn: "Úloha vyprší dňa",
  saveDataAboutMe: "o mne uchovávané údaje",
  signIn: "Prihlásiť sa",
  termsConditionsHeader: "Všeobecné obchodné podmienky",
  termsConditionsText: "Text odseku (obsah bude nasledovať)",
  termsConditionsTitle: "Nadpis odseku",
  typeInOne: "Zadajte jedno pre všetkých",
  username: "Používateľské meno",
  firstname: "Krstné meno",
  lastname: "Priezvisko",
  usernameMail: "Používateľské meno alebo e-mail",
  userNotes: "Poznámky",
  userProfile: "Používateľský profil",
  userStatus: "Stav užívateľa",
  wrongCurrentPassword: "Nesprávne aktuálne heslo",
  fillAllFields: "Vyplňte všetky požadované polia",
  wrongUsernameOrPassword: "Používateľské meno alebo e-mail sú nesprávne",
  minimumLength: "Minimálna dĺžka 8",
  atLeastOneBigAndSmall: "minimálne 1 malé a 1 veľké písmeno",
  passwordNotEqualUsernameAndEmail:
    "Heslo nie je identické s používateľským menom a e-mailom",
  registrationKey: "Registračný kľúč",
  registration: "Registrácia",
  notAnEmailAdress: "Zadajte platný e-mail.",
  company: "Firma",
  companyHint: "Firma",
  birthday: "Dátum narodenia",
  birthdayHint: "Dátum narodenia",
  birthyear: "Rok narodenia",
  accountName: "Názov účtu",
  accountType: "Typ účtu",
  accountAddress: "Adresa majiteľa účtu",
  logo: "Logo",
  address: "Adresa",
  contry: "Krajina",
  zipCode: "PSČ",
  lessThan: "menej ako 1 MB",
  defaultLanguage: "Štandardný jazyk",
  accountStatus: "Stav účtu",
  accountNotes: "Poznámky k účtu",
  createAccount: "Vytvoriť účet",
  updateAccount: "Aktualizovať účet",
  fileToImport: "Account notes",
  importStructure: "Importovať štruktúru",
  addRootAccount: "Pridať Basis level account",
  usersPerMonth: "Používateľov za mesiac",
  usersAdded: "Pridaní používatelia",
  usersDeleted: "Vymazaní používatelia",
  usersTotal: "Užívatelia celkovo",
  city: "Mesto",
  users: "Užívatelia",
  coursesAvailable: "Dostupné kurzy",
  started: "Spustené",
  completed: "Dokončené",
  openMandatoryAssignments: "Otvorené povinné priradenia",
  mandatoryAssignments: "Povinné priradenia",
  dueMandatoryCompletions:
    "Povinné absolvovania s aktuálnou potrebou dokončenia",
  of: "z",
  accountOverview: "Prehľad účtu",
  selectAccount: "Vyberte účet",
  noAccountsAvailable: "Nie sú k dispozícii žiadne účty",
  completionRate: "Miera dokončenia",
  averageResult: "Priemerný výsledok",
  courseOverview: "Prehľad kurzov",
  reportCoursesSearchPlaceholder: "Vyhľadať kurz",
  completedLastWeeks:
    "Absolvované kurzy za posledné {number} týždne/posledných {number} týždňov",
  completedLastMonths:
    "Absolvované kurzy za posledné {number} mesiace/posledných {number} mesiacov",
  executionTime: "Rozdelenie podľa času realizácie (min.)",
  completionLevel: "Rozdelenie podľa počtu dosiahnutých bodov",
  knowledge: "VEDOMOSTI",
  toolbox: "TOOLBOX",
  lessonCompleted: "Lekcia dokončená",
  lessonFailed: "Lekcia nedokončená",
  repeatLesson: "Ak chcete pokračovať, opakujte lekciu",
  congratulations: "Srdečne blahoželáme!",
  courseCompleted: "Kurz dokončený",
  percentCompleted: "Správne ste odpovedali na $successRate% otázky/otázok.",
  downloadCertificate:
    "Certifikát si môžete kedykoľvek stiahnuť v časti „Certifikáty“ vášho profilu.",
  trueFalse: "Správne alebo nesprávne?",
  seconds: "Sek.",
  noChoiceText: "Bohužiaľ, príliš neskoro.",
  right: "Správne!",
  wrong: "Nesprávne",
  progress: "$current z $total",

  repeat: "Opakovať",
  // todo translate ->
  confirmRead: "I confirm that I have read the text.",
  confirmedRead: "You have confirmed that you have read the text.",
  confirmVideo: "I confirm that I have watched the video.",
  confirmedVideo: "You have confirmed that you have watched the video.",
  confirmPdf: "I confirm that I read the pdf document.",
  confirmedPdf: "You have confirmed that you have read the pdf document.",
  responsesMissing: "Please complete all items before you continue",
  // <- tod translate
  confirmed: "Potvrdené",

  addCourseContent: "Pridať obsahy kurzov",
  addSemicolon: "Pridajte popisky oddelené bodkočiarkou",
  addToProgram: "Pridať do programu",
  allAccounts: "Všetky účty",
  certificate: "Certifikát",
  certificates: "Certifikáty",
  completed: "Dokončené",
  contentIsLoaded: "Obsah je načítaný",
  contentManager: "Správca obsahu",
  courseCompleted: "Kurz dokončený",
  courseContent: "Obsah kurzu",
  courseDocuments: "Podklady",
  courseLables: "Etikety kurzov",
  courseLanguage: "Jazyk kurzu",
  courseNotes: "Poznámky pre kurz",
  courseStructure: "Štruktúra",
  courseTags: "Tagy kurzov",
  courseTitle: "Názov kurzu",
  createCourse: "Vytvoriť kurz",
  curator: "KURÁTOR",
  description: "Opis",
  details: "Detaily",
  dueDate: "Termín pre dokončenie",
  duration: "Trvanie",
  exportStatistics: "Exportovať štatistiky",
  learningStandard: "Študijný štandard",
  licenses: "Licencie",
  mandatory: "Povinný kurz",
  noAssessment: "Momentálne neexistuje žiadne hodnotenie na preskúmanie.",
  noCoursesAssigned:
    "K vášmu účtu nie sú priradené žiadne kurzy. Navštívte prosím ",
  noCoursesAvailable: "Momentálne nie sú k dispozícii žiadne kurzy.",
  noDueDate: "Žiadny termín pre dokončenie",
  overview: "PREHĽAD",
  page: "page",
  pickAnAccount: "Vybrať si účet",
  printCertificate: "Tlačiť certifikát",
  publishCourse: "Zverejniť kurz",
  publishedToAccount: "Zverejnené v účte",
  relatedCourses: "Podobné kurzy",
  remove: "Odstránenie",
  removeFromProgram: "Odstrániť z programu",
  singleAccount: "Jeden účet",
  startCourse: "Štart",
  successMessageOne: "Blahoželáme, kurz ",
  successMessageThree:
    "Teraz si môžete stiahnuť alebo vytlačiť certifikát o absolvovaní. ",
  successMessageTwo: " ste úspešne absolvovali!",
  tags: "Kľúčové slová",
  unpublishCourse: "Zrušiť zverejnenie",
  updateCourseContent: "Nahrať obsahy kurzov",
  updateCourse: "Aktualizovať kurz",
  youDidntfinishCourse: "Ešte ste neabsolvovali žiadny kurz.",
  courseVisibleFor: "Kurz je viditeľný pre",
  accountStructureItem: "Oddelenie",
  courseAssigmentOptions: "Možnosti priradenia",
  assignCourse: "Priradiť kurz",
  pickACourse: "Vyberte kurz",
  noDocumentsAvailable: "Nie sú k dispozícii žiadne podklady",
  nothingRelated: "Nie sú k dispozícii žiadne podobné kurzy",
  nothingWritten: "nič nie je zapísané",
  noTagsAvailable: "Nie sú k dispozícii žiadne kľúčové slová",
  alreadyGlobal: "Kurz je už zverejnený po celom svete.",
  questionsanswered: "Otázky",
  completedOn: "Dokončené dňa",
  locale: "sk-SK",
  quizAuthorizationContent: "Autorizácia kvízu",
  courseNotPublished: "Kurz momentálne nie je zverejnený",
  courseNotPublishedForAccount:
    "Kurz momentálne nie je zverejnený pre účet $account",
  clickToPublishCourse: "Zverejniť kurz",
  visible: "Viditeľné",
  userIsNotContentManager:
    "Nie ste správcom obsahu, a preto nemôžete upravovať obsah kurzu.",
  assignmentOpen: "Otvorené",
  assignmentStarted: "Spustené",
  assignmentCompleted: "Dokončené",
  dueDatePassed: "Termín pre dokončenie bol prekročený",
  exitCourse: "Ukončiť kurz",
  markAsFinished: "Označiť ako spracované",
  assignToAllUsers: "Priradiť kurz všetkým používateľom v účte",

  learningPoints: "Učebné body",
  learningPointsAbbreviation: "UB",
  back: "Späť",
  next: "Ďalšie",
  courseOverview: "Prehľad kurzu",
  responsesMissing: "Pred pokračovaním vyplňte všetky body",

  configureLogging: "Nakonfigurovať protokolovanie",
  createAccount: "Založiť účet",
  changeAccount: "Zmeniť účet",
  deleteAccount: "Vymazať účet",
  importAccountStructure: "Importovať štruktúru účtu",
  createUser: "Vytvoriť používateľa",
  importUser: "Importovať používateľa",
  changeUser: "Zmeniť používateľa",
  deleteUser: "Vymazať používateľa",
  assignUserRole: "Priradiť úlohu",
  resetPassword: "Resetovať heslo",
  createCourse: "Vytvoriť kurz",
  changeCourse: "Zmeniť kurz",
  addQuizContent: "Pridať obsah kvízu",
  changeQuizContent: "Zmeniť obsah kvízu",
  addContent: "Pridať obsah kurzu",
  changeContent: "Zmeniť obsah kurzu",
  deleteCourse: "Vymazať kurz",
  deleteContent: "Vymazať obsah kurzu",
  publishCourse: "Zverejniť kurz",
  unpublishCourse: "Zrušiť zverejnenie",
  assignCourse: "Priradiť kurz",
  unassignCourse: "Zrušiť priradenie kurzu",
  systemMessages: "Systémové správy",
  learningManagement: "Správa štúdia",
  courseManagement: "Správa kurzu",
  userManagement: "Správa používateľa",
  organizationStructure: "Organizačná štruktúra",
  accountManagement: "Správa účtu",
  system: "Systém",

  learningManagementDetail: "Študijné plány a priradenie kurzu",
  courseManagementDetail: "Spravovať obsah kurzov",
  userManagementDetail: "Spravovať používateľa a resetovať heslá",
  organizationStructureDetail: "Definovať organizačnú štruktúru",
  accountManagementDetail: "Spravovať účty",
  logsDetail: "Systémové denníky a nastavenia",
  // todo ->
  noResults: "No Results",
  noData: "No Data Available",
  // <- todo

  userCreateSuccess: "Používateľ úspešne vytvorený",
  createOtherUser: "Pridať ďalšieho používateľa",
  backToUserOverview: "Späť na prehľad používateľov",
  importMoreUsers: "Importovať ďalších používateľov",
  currentRoles: "Aktuálne úlohy",
  numberOfRoles: "Počet úloh",
  numberOfUsers: "Používatelia",
  deleteAccountMessage: "Potvrďte, že chcete vymazať účet '$account'.",
  showStatistics: "Zobraziť štatistiky",
  appendAccount: "Účet pripojiť k $account.",
  courseAssignments: "Pridelené",
  courseAssignmentsForCourse: "Priradenia kurzov pre $course",
  mandatoryAssignments: "Povinné priradenia kurzov",
  dueDateAssignments: "S lehotou",
  dueDatePassed: "Termín pre dokončenie",
  userOrAccount: "Používateľ alebo oddelenie",
  expiryDate: "Dátum uplynutia",
  deletePublication: "Vymazať zverejnenie",
  deletePublicationMessage:
    "Naozaj chcete vymazať zverejnenie pre účet $account?",
  orgStructure: "Oddelenie",
  type: "Typ",
  downloadTemplate: "Stiahnuť predlohu",
  published: "Zverejnené",
  scorm: "SCORM",
  quiz: "Kvíz",
  staticFile: "Pdf",
  editPublication: "Upraviť zverejnenie",
  pleaseDefineCourseContent:
    "please define the course content before publishing it.",

  usernameMissing: "Chýba používateľské meno",
  usernameError:
    "Používateľské meno môže pozostávať iba z alfanumerických znakov",
  emailMissing: "Chýba e-mail",
  emailError: "Žiadna platná e-mailová adresa",
  passwordMissing: "Chýba heslo",
  passwordError:
    "Heslo musí mať najmenej 8 znakov a obsahovať jedno veľké a jedno malé písmeno.",
  registrationKeyManagement: "Registračný kľúč",
  registrationKeyManagementDetail: "Správa registračných kľúčov",

  addRegistrationKey: "Pridať registračný kľúč",
  registrationLink: "Odkaz na registráciu",
  createKey: "Pridať",
  user_upload_maximum: "Až 1000 používateľov na jedno nahranie",
  userState: "Stav používateľa",
  userStateDetail: "Prístupové oprávnenie a povinné kurzy.",
  testsPassed: "Testy absolvované",
  access: "Prístup",
  downloadReport: "Stiahnuť správu",
  success: "Úspešne uložené!",
  warning: "Pozor!",
  error: "Chyba!",
  removedFromProgram: "Kurz bol odstránený z vášho programu",
  addedToProgram: "Kurz bol pridaný do vášho programu",
  courseCreated: "Kurz bol vytvorený",
  courseNotCreated: "Kurz nebol vytvorený",
  fillUpFields: "Vyplňte všetky povinné polia",
  cookieNotAvailable: "Vaša relácia už nie je platná, prihláste sa znova",
  courseNotFinished: "Nie sú k dispozícii žiadne informácie o ukončení kurzu",
  courseAborted: "Kurz bol zrušený",
  correctAnswer: "Správna odpoveď",
  wrongAnswer: "Nesprávna odpoveď",
  courseStarted: "Kurz bol spustený",
  testsPassed: "Test absolvovaný",
  testFailed: "Test nebol absolvovaný",
  courseCompleted: "Kurz bol úspešne dokončený",
  imageTooLarge: "Veľkosť súboru by mala byť menej ako 2 MB",
  wrongFormat: "Nesprávny formát",
  passwordReset: "Na zadaný e-mail bol odoslaný odkaz na resetovanie hesla",
  passwordChanged: "Heslo bolo zmenené",
  accountAdded: "Účet bol pridaný",
  accountUpdated: "Účet bol aktualizovaný",
  accountDeleted: "Účet bol vymazaný",
  userImported: "Používatelia boli importovaní",
  userNotImported: "Používatelia neboli importovaní",
  loggingConfigSaved: "Konfigurácia denníka bola uložená",
  userDeleted: "Používateľ bol vymazaný",
  userAdded: "Používateľ bol vytvorený",
  userUpdated: "Používateľ bol aktualizovaný",
  GDPRLoaded: "GDPR je načítané",
  cookiesDisabled: "Deaktivovali ste cookies",
  wrongCredentials: "Nesprávne prihlasovacie údaje",
  fileUploaded: "Váš súbor bol nahraný",
  courseContentUpdated: "Obsah kurzu bol aktualizovaný",
  coursePublished: "Kurz bol zverejnený",
  courseUnPublished: "Zverejnenie kurzu bolo zrušené.",
  serverUnreachable: "Server je nedostupný",
  courseContentAdded: "Obsah kurzu bol úspešne pridaný.",
  user_upload_exceeded:
    "Príliš veľa používateľov. Na jedno nahranie je podporovaných maximálne 1 000 používateľov.",
  selectAccounts: "Vybrať účet",
  selectAccountToContinue: "Pre pokračovanie vyberte účet",
  accountOverview: "Prehľad účtu",
  users: "Užívatelia",
  courses: "Kurzy",
  coursesCompleted: "Dokončené kurzy",
  completedCoursesPerUser: "Dokončené kurzy na užívateľa",
  learningHours: "Študijné hodiny",
  learningHoursPerUser: "Študijné hodiny na užívateľa",
  courseOverview: "Prehľad kurzu",
  completed: "Dokončené",
  assigned: "Priradené",
  courseDetails: "Detaily",
  selectCourse: "Vybrať kurz",
  details: "Vybrať",
  type: "Typ",
  dueDate: "Do",
  mandatory: "Povinný kurz",
  completionRate: "Miera dokončenia",
  averageScore: "Výsledok testu",
  duration: "Trvanie",
  averageDuration: "Priemerná doba trvania",
  account: "Účet",
  accountName: "Názov účtu",
  accounts: "Účty",
  active: "Aktívne",
  admin: "Admin",
  all: "Všetci",
  not_found: "Stránka neexistuje",
  assessmentStatistics: "Štatistiky hodnotenia",
  assignedUsersOnly: "Iba pridelení používatelia",
  autoIndividual: "Automaticky, individuálne",
  cancel: "Zrušiť",
  cardImage: "Zobrazenie karty",
  close: "Zatvoriť",
  completed: "Dokončené",
  confirm: "Potvrdiť",
  country: "Krajina",
  course: "Kurzy",
  courseDescription: "Opis kurzu",
  courseDocuments: "Podklady pre kurz",
  courses: "Knižnica",
  courseStructure: "Štruktúra kurzu",
  dataProtection: "Ochrana údajov",
  defaultLanguage: "Štandardný jazyk",
  deleteText: "Naozaj ste si istí vymazaním",
  delete: "Vymazať",
  details: "Detaily",
  dragAndDrop: "Potiahni a pusti/kliknúť pre pridanie",
  dueDate: "Termín pre dokončenie dňa",
  email: "E-mail",
  empty: "Prázdne",
  exit: "Odhlásiť sa",
  expiresOn: "Vyprší dňa",
  fileImport: "Súbor na importovanie",
  image: "Obrázok",
  inactive: "Aktívne",
  language: "Jazyk",
  logo: "Logo",
  mandatory: "Povinný kurz",
  monthsLabels: [
    "Január",
    "Február",
    "Marec",
    "Apríl",
    "Máj",
    "Jún",
    "Júl",
    "August",
    "September",
    "Október",
    "November",
    "December",
  ],
  myAccount: "Môj účet",
  myProfile: "Môj profil",
  myProgram: "Môj program",
  myRoles: "Moje úlohy",
  never: "Nikdy",
  no: "Nie",
  none: "Žiadne",
  notFound: "Nenájdené",
  phoneNumber: "Telefónne číslo",
  playImage: "Zobraziť obrázok",
  publishingExpiresOn: "Zverejnenie vyprší dňa",
  publishTo: "Zverejniť o",
  reports: "Správa",
  save: "Uložiť",
  search: "Hľadať...",
  status: "Stav",
  structureItem: "Oddelenie",
  termsAndConditions: "Obchodné podmienky",
  unlimited: "Neobmedzené",
  update: "Aktualizovať",
  user: "Používatelia",
  username: "Používateľské meno",
  users: "Používatelia",
  visibility: "Viditeľnosť účtu",
  yes: "Áno",
  zipCode: "PSČ",
  info: "Informácie",
  error: "Chyba",
  alert: "Výstraha",
  criticalAlert: "Kritická výstraha",
  navigation: "Navigácia",
  system: "Systém",
  note: "Upozornenie",
  userProfile: "Používateľský profil",
  userId: "ID používateľa",
  customId: "ID",
  organizationalStructure: "Organizačná štruktúra",
  created: "Vytvorené dňa",
  new: "Nové",
  noResults: "Žiadne výsledky",
  noData: "Žiadne údaje",
  uploadFile: "Nahrať súbor",
  id: "ID",
  name: "Názov",
  append: "Pridať",
  edit: "Upraviť",
  format: "Formát",
  minutes: "Minúty",
  hours: "Hodiny",
  date: "Dátum",
  message: "Správa",
  fax: "Fax",
  genericError:
    "Vyskytla sa chyba, skúste stránku načítať znova. Ak chyba pretrváva, obráťte sa na správcu systému.",
  action: "Akcia",

  uploadedCertificates: "Nahrané certifikáty",

  // TODO ->
  roles: "Roles",
  properties: "Properties",
  property: "Property",
  assignUserProperty: "Assign property to user",
  userProperties: "User properties",
  addTagToUser: "Add property to user",
  showMoreFilters: "Show more filters",

  assigned: "Assigned",
  unassigned: "Unassigned",
  assignedOrUnassigned: "Assigned/Unassigned",
  emailAboutChange: "Send users a notification E-Mail",
  showLessFilters: "Show less filters",
  clearFilters: "Clear filters",
  no_changes_assignment_button: "No Changes",
  assignment: "Assignment",

  assign_course: "Assign Course",
  add_assignment_to_users: "assign to $users",
  remove_assignments_from_users: "remove assignment from $users",
  update_assignments_for_users: "update $users assignments",
  create_property: 'Create new property "$property"',
  non_mandatory: "Non-mandatory",
  department: "Department",
  user_details: "User Details",
  download_file: "Stiahnuť súbor",
  your_browser_not_support_this_content:
    "Your browser does not support viewing this content.",
  upload_file: "Upload File",
  deleteDocument: "Delete Document",
  deleteDocumentConfirmation: "Are you sure you want to delete $document?",

  add_mandatory_user_property:
    "Vyberte pre svoju prácu nasledujúce charakteristiky",
  help: "Pomoc",
  alreadyRegistered: "Už zaregistrované",
  additionalDetails: "Doplňujúce informácie",
  additionalDetailsSave: "Na potvrdenie",
  dragAndDropCertificate:
    "Potiahnite sem súbor PDF alebo kliknite na tlačidlo pre pridanie",
  coverPhoto: "Cover Photo",
  EditCoverPhoto: "Edit Cover Photo",
  AddCoverPhoto: "Add Cover Photo",
  additionalDocuments: "Additional Documents",
  editAdditionalDocuments: "Edit Additional Documents",
  addAdditionalDocuments: "Add Additional Documents",
  fileName: "File name",
  fileSize: "File size",
  systemLogs: "System Logs",
  systemConfigurationDetail: "Configure system settings",
  systemConfiguration: "System configuration",
  courseFormat: "Course formats",
  views: "Views",
  apis: "APIs",
  viewsDescription: "Visibility of the element in the respective view",
  courseFormatDescription: "Available course formats for defining the course",
  accessCard: "Access card",
  downloadCertification: "Download Certification",
  userProfileV2: "User profile V2",
  certification: "Certification",
  logs: "Logs",
  issuedBy: "Issued by",
  issuedOn: "Issued on",
  fulfilledInPercent: "Fulfilled in percent",

  logout: "Odhlásiť sa",
  continue: "Pokračovať",
  courseFinishedDoYouWantToLogout:
    "Kurz bol dokončený. Chcete sa odhlásiť alebo pokračovať? Automaticky sa odhlásite o {seconds}.",
  stateless: "Bez štátnej príslušnosti",
};
