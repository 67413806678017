import { mapResponse, printError } from "../utils/mapResponse";
import { requestSettings, deleteSettings, postSettings } from "./util";
import api from "./config";

import store from "@/store/";
import axios from "axios";

export default {
  _getLanguage() {
    const userData = store.getters.getUserData;
    const attrs = userData.attributes || {};
    if (attrs.langCode) {
      const lang = attrs.langCode.toLowerCase();
      return lang;
    }
    return "de";
  },

  loadTagTypes() {
    const language = this._getLanguage();
    return axios
      .get(
        `${api.tagServices}tag-types?${new URLSearchParams({ language })}`,
        requestSettings,
      )
      .then(mapResponse)
      .catch(printError);
  },
  getAvailableTags() {
    const language = this._getLanguage();
    return axios
      .post(`${api.tagServices}tag-values/search`, { language })
      .then(mapResponse)
      .catch(printError);
  },
  addTagType(tag) {
    // tag is an object {name: string, description: string, language: string}
    return axios
      .post(`${api.tagServices}tag-types`, tag)
      .then(mapResponse)
      .catch(printError);
  },
  patchTagType(tag) {
    return axios
      .patch(`${api.tagServices}tag-types/${tag.uuid}`, tag)
      .then(mapResponse)
      .catch(printError);
  },
  deleteTagType(id) {
    return axios
      .delete(`${api.tagServices}tag-types/${id}`)
      .then(mapResponse)
      .catch(printError);
  },
  addTagTypeValue(id, tag) {
    return axios
      .post(`${api.tagServices}tag-types/${id}/values`, { tagType: id, ...tag })
      .then(mapResponse)
      .catch(printError);
  },
  putTagTypeValue(id, tag) {
    return axios
      .put(`${api.tagServices}tag-types/${id}/values/${tag.uuid}`, {
        tagType: id,
        ...tag,
      })
      .then(mapResponse)
      .catch(printError);
  },
  deleteTagTypeValue(typeId, valueId) {
    return axios
      .delete(`${api.tagServices}tag-types/${typeId}/values/${valueId}`)
      .then(mapResponse)
      .catch(printError);
  },
  getTagsForUser(id) {
    return axios
      .get(
        `${api.tagServices}tags?${new URLSearchParams({ entityType: "user", entities: JSON.stringify([id.toString()]) })}`,
      )
      .then(mapResponse)
      .catch(printError);
  },
  addTagForUser(userId, tagType, tagValue) {
    return axios
      .post(`${api.tagServices}tags`, {
        tagType,
        value: tagValue,
        entityType: "user",
        entity: userId,
      })
      .then(mapResponse)
      .catch(printError);
  },
  removeTagFromUser(userId, tagType, tagValue) {
    return axios
      .delete(
        `${api.tagServices}tags/${tagValue}?${new URLSearchParams({ entityType: "user", entity: userId })}`,
      )
      .then(mapResponse)
      .catch(printError);
  },
  getTagValues(request) {
    return axios
      .post(`${api.tagServices}tag-values/search`, request)
      .then(mapResponse)
      .catch(printError);
  },
  // Mandatory tags per account
  getMandatoryTags(entityType, entityId) {
    return axios
      .get(
        `${api.tagServices}requirements?${new URLSearchParams({ entityType, entity: entityId })}`,
        requestSettings,
      )
      .then(mapResponse)
      .catch(printError);
  },
  addMandatoryTag(tagRequirement) {
    return axios
      .post(`${api.tagServices}requirements`, tagRequirement)
      .then(mapResponse)
      .catch(printError);
  },
  removeMandatoryTag(tagId) {
    return axios
      .delete(`${api.tagServices}requirements/${tagId}`)
      .then(mapResponse)
      .catch(printError);
  },
  removeAllTags(requirementId) {
    return axios
      .delete(
        `${api.tagServices}requirements/${requirementId}/assignedProperties`,
      )
      .then(mapResponse)
      .catch(printError);
  },

  // Purposes
  getPurposes() {
    return axios
      .get(`${api.tagServices}purposes`)
      .then(mapResponse)
      .catch(printError);
  },
};
