// NOTE: customers might require certain features of the app to be customized that others do not.
// In order to not scatter previous default values all over the code,
// we use this function to have a centralized set of default values that are being applied if no value is set in the customization.
// Note that keys are by default overridden, if a key should be merged additional code has to be added

import * as settings from "./settings.ts";

export function getSetting(name) {
  const defaults = {
    title: "lilli e-learning",

    termsAndConditionsLink: "https://www.incontext.technology/impressum.html",
    privacyPolicyLink: "https://www.incontext.technology/datenschutz.html",
    headerlogo: "/img/logo.svg",

    showDetailsForQuiz: false,
    showDetailsForSurvey: false,
    showSeal: false,
    showCovidView: false,
    showCovidCertificatesToAdmin: false,
    showTestCenterView: false,
    showHardwareRestrictions: false,

    registrationRequiresKey: true,

    registrationRequiredDetails: {
      email: { request: true, mandatory: true },
      username: { request: true, mandatory: true },
      firstname: { request: false, mandatory: false },
      lastname: { request: false, mandatory: false },
      phone: { request: false, mandatory: false },
      address: { request: false, mandatory: false },
      birthday: { request: false, mandatory: false },
      birthyear: { request: false, mandatory: false },
      company: { request: false, mandatory: false },
      emergencyContact: { request: false, mandatory: false },
    },

    customPublicRegistrations: {},

    qGateFields: [
      { id: "birthdate", type: "date", label: "birthday", mandatory: true },
      {
        id: "birthplace",
        type: "string",
        label: "birthplace",
        mandatory: true,
      },
      // NOTE: nationalityWithNonEuVisa also adds the additional, optional fields socialSecurity and workPermit (if the user is not from the EU)
      {
        id: "nationality",
        type: "nationalityWithNonEuVisa",
        label: "nationality",
        mandatory: true,
      },
      { id: "address", type: "string", label: "address", mandatory: true },
      { id: "city", type: "string", label: "city", mandatory: true },
      { id: "zipCode", type: "string", label: "zipCode", mandatory: true },
      { id: "country", type: "country", label: "country", mandatory: true },
      {
        id: "car",
        type: "switchString",
        label: "car",
        mandatory: false,
        settings: { subLabel: "carPlate", subId: "carPlate" },
      },
    ],

    emailAsUsername: true,
    supportedLanguages: [
      "de",
      "en",
      "bg",
      "cs",
      "el",
      "hr",
      "hu",
      "it",
      "lv",
      "pl",
      "pt",
      "ru",
      "ro",
      "es",
      "tr",
      "sr",
      "et",
      "nl",
      "ar",
    ],
    defaultLanguage: "de",

    languageOverrides: {},
  };

  const mergedSettings = { ...defaults, ...settings };
  mergedSettings.registrationRequiredDetails = {
    ...defaults.registrationRequiredDetails,
    ...settings.registrationRequiredDetails,
  };

  if (!mergedSettings[name]) {
    console.warn(`Request for unknown setting: ${name}`);
  }

  return mergedSettings[name];
}
