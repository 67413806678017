import axios from "axios";
import { mapResponse, printError } from "../utils/mapResponse";
import urls from "./config";

export default {
  putLoginRedirect(loginRedirect) {
    return axios.put(`${urls.baseUrlV2}login-redirects`, loginRedirect);
  },
  getLoginRedirect(id) {
    return axios.get(`${urls.baseUrlV2}login-redirects/${id}`);
  },
  patchLoginRedirect(loginRedirectPatch) {
    return axios.patch(
      `${urls.baseUrlV2}login-redirects/${loginRedirectPatch.id}`,
      loginRedirectPatch,
    );
  },
  deleteLoginRedirect(id) {
    return axios.delete(`${urls.baseUrlV2}login-redirects/${id}`);
  },
  postLoginRedirectSearch(page, pageSize) {
    return axios.post(`${urls.baseUrlV2}login-redirects/search`);
  },
};
