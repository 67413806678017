<template lang="pug">
.quiz-step-container.mb-3
  .quiz-content
    EditPreview.mb-2(
      ref="questionEditor",
      :text="step.question",
      @editText="patchStep({ question: $event })"
    )
  .quiz-step-answers
    .quiz-step-answer.clickable(
      v-for="(choice, index) in step.choices",
      :key="index",
      :class="{ 'quiz-answer-correct': choice.isCorrect, 'quiz-answer-incorrect': !choice.isCorrect }"
    ) 
      //- Go through the alphabet
      span.txt-accent.txt-large.mr-2 {{ String.fromCharCode(65 + index) }}

      EditPreview(
        :text="choice.text",
        :edit-on-start="false",
        @editText="editChoiceText(index, $event)"
      )

      span
</template>

<script>
import editStepMixin from "./mixins/editStepMixin";
import EditPreview from "./components/EditPreview.vue";
import { nextTick } from "vue";

export default {
  components: {
    EditPreview,
  },
  mixins: [editStepMixin],
  data: function () {
    return {
      editingChoice: null,
      titleEditing: false,
    };
  },
  mounted() {
    nextTick(() => {
      if (this.$refs.questionEditor !== undefined) {
        this.$refs.questionEditor.focus();
      }
    });
  },
  methods: {
    editChoiceText(index, text) {
      // NOTE: when editing choices, we have to edit all choices
      const choices = this.step.choices.map((item) => item);
      choices[index].text = text;
      this.patchStep({ choices });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../../settings.styl';

.quiz-answer-correct {
  border: 3px solid $correct;
}

.quiz-answer-incorrect {
  border: 3px solid $incorrect;
}
</style>
