export default function () {
  let token = null;
  try {
    token = localStorage.getItem("token");
  } catch (err) {
    /* eslint-disable */
    console.log("Error ", err);
    /* eslint-enable */
  }
  if (token) {
    return `Bearer ${token}`;
  }
  return false;
}
