<template lang="pug">
div
  h3 {{ $t("settings") }}
  .step-settings.mb-1
    label {{ $t("upload") }}
    .upload-container
      el-button(v-loading="uploadLoading", type="primary", icon="Upload") {{ $t("uploadPdf") }}
      input.file-upload(type="file", @change="uploadFile($event.target.files)")
  CommonSettings(:step="step", show-requires-interaction)
</template>

<script>
import { mapGetters } from "vuex";
import CommonSettings from "./CommonSettings";
import editStepMixin from "./mixins/editStepMixin";

export default {
  components: {
    CommonSettings,
  },
  mixins: [editStepMixin],
  data: () => ({
    uploadLoading: false,
  }),
  methods: {
    uploadFile(files) {
      this.uploadLoading = true;
      this.$store.dispatch("uploadPdf", { file: files[0] }).then((_) => {
        this.uploadLoading = false;
      });
    },
  },
};
</script>

<style scoped>
.upload-container {
  position: relative;
  width: 300px;
  height: 40px;
  min-width: 300px;
}

.upload-container > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  width: 300px;
}

.file-upload {
  opacity: 0;
}
</style>
