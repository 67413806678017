<template>
  <div class="user-menu">
    <user-profile @close="$emit('closeModal')" />
  </div>
</template>

<script>
import UserProfile from "./UserProfile";

export default {
  name: "UserMenu",
  components: {
    UserProfile,
  },
  data() {
    return {};
  },
};
</script>
<style lang="stylus">
.user-menu {
  display: flex;
  margin-left: auto;
  position: relative;
  display: inline-block;

  &--showed {
    display: block;
  }

  // &:hover &__content {
  // display: block;
  // }
  &__fullname {
    // font-size: 21px;
    font-size: 16px;
    height: 22px;
    width: 180px;
    text-align: right;
  }

  &__userpicture {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    overflow: hidden;
    // TODO: theme
    background-image: linear-gradient(to bottom right, #8b8b91, #535358);
  }

  &__icon {
    // TODO: theme
    color: #bebebe;
    font-size: 30px;
    margin: 0 5px;

    &--drop {
      margin-bottom: 2px;
    }
  }

  &__title {
    display: flex;
    font-weight: 500;
    font-size: 21px;
    align-items: flex-end;
    margin-right: -14px;
    cursor: pointer;
  }

  &__content {
    display: none;
    position: absolute;
    // TODO: theme
    border: 1px solid #c0c0c0;
    border-radius: 4px;
    // TODO: theme
    background: #fff;
    right: 0;
    top: 100%;
    width: 240px;

    &--showed {
      display: block;
    }
  }
}
</style>
