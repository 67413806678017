<template lang="pug">
div
  h2 {{ $t("miscellaneous") }}
  .link-wrapper.mt-3
    a(:href="configApi.getGDPRURL + '?download=true'", @click="saveGDPRtxt") {{ $t("downloadDataRelatedToMe") }}
    router-link.navbar__item(v-if="isCovidAvailable", to="/vaccination/") 
      a(@click="close") {{ $t("covid") }}
    router-link.navbar__item(
      v-if="isTestcenterAvailable",
      to="/test-center/",
      @click="close"
    ) 
      a(@click="close") {{ $t("tests") }}
    router-link.navbar__item(
      v-if="isCompletedAvailable",
      to="/completed/",
      @click="close"
    )
      a(@click="close") {{ $t("certificates") }}

    a.navbar__item(:href="termsAndConditionsLink", target="_blank") {{ $t("tos") }}
    a.navbar__item(:href="privacyPolicyLink", target="_blank") {{ $t("privacyPolicy") }}
</template>

<script>
import configApi from "@/services/config";
import RolesScopesMixin from "../admin/admin-action/mixins/RolesScopesMixin";
import roles from "@/utils/roles";

export default {
  name: "MiscellaneousUserActions",
  mixins: [RolesScopesMixin],
  data() {
    return {
      configApi,
    };
  },
  computed: {
    privacyPolicyLink() {
      return this.$getSetting("privacyPolicyLink").replace(
        "$langcode",
        this.$i18n.locale,
      );
    },
    termsAndConditionsLink() {
      return this.$getSetting("termsAndConditionsLink").replace(
        "$langcode",
        this.$i18n.locale,
      );
    },
    isCovidAvailable() {
      return this.$getSetting("showCovidView");
    },
    isTestcenterAvailable() {
      return this.$getSetting("showTestCenterView");
    },
    isCompletedAvailable() {
      return this.hasScope("users_get_courses_completed");
    },
  },
  methods: {
    close() {
      console.log("close");
      this.$emit("close");
    },
    saveGDPRtxt() {
      this.$store.dispatch("getGDPR", true);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import "../../settings.styl"
.link-wrapper > * {
  color: $hyperlink;
  margin-left: 0;
  margin-top: 2rem;
  }
</style>
