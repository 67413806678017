import {
  stateError,
  stateInitial,
  stateLoaded,
  stateLoading,
} from "./loadingStates";
import hardwareRestrictionService from "@/services/hardwareRestrictions";
import HardwareRestrictionDb from "./db/hardwareRestrictionsDb";

export default {
  namespaced: true,
  state: {
    keys: { ...stateInitial, result: [] },
    localKeys: [],
    localKeyCapabilititesLoadingState: { ...stateInitial, result: [] },
  },
  mutations: {
    keysLoading(state) {
      state.keys = { ...stateLoading, result: state.keys.result };
    },
    keysLoaded(state, result) {
      state.keys = { ...stateLoaded, result: result };
    },
    keysError(state) {
      state.keys = { ...stateError, result: state.keys.result };
    },
    updateLocalKeys(state, keys) {
      state.localKeys = keys;
    },
    removeLocalKey(state, key) {
      state.localKeys = state.localKeys.filter((k) => k.id !== key.id);
      state.localKeyCapabilititesLoadingState.result =
        state.localKeyCapabilititesLoadingState.result.filter(
          (k) => k.id !== key.id,
        );
    },
    localKeyCapabilitiesLoading(state) {
      state.localKeyCapabilititesLoadingState = {
        ...stateLoading,
        result: state.localKeyCapabilititesLoadingState.result,
      };
    },
    localKeyCapabilitiesLoaded(state, capabilities) {
      state.localKeyCapabilititesLoadingState = {
        ...stateLoaded,
        result: capabilities,
      };
    },
    localKeyCapabilitiesError(state) {
      state.localKeyCapabilititesLoadingState = { ...stateError };
    },
  },
  actions: {
    async loadKeys({ commit }) {
      commit("keysLoading");
      const response = await hardwareRestrictionService
        .getRestrictionKeys()
        .catch((error) => {
          commit("keysError");
          throw error;
        });
      commit("keysLoaded", response.data.result);
      return response;
    },
    async addKey({ commit, dispatch }, key) {
      commit("keysLoading");
      const response = await hardwareRestrictionService
        .postRestrictionKey(key)
        .catch((error) => {
          commit("keysError");
          throw error;
        });
      dispatch("loadKeys");
    },
    async patchKey({ commit, dispatch }, key) {
      commit("keysLoading");
      const response = await hardwareRestrictionService
        .patchRestrictionKey(key)
        .catch((error) => {
          commit("keysError");
          throw error;
        });
      dispatch("loadKeys");
    },
    async removeKey({ commit, dispatch }, key) {
      commit("keysLoading");
      const response = await hardwareRestrictionService
        .deleteRestrictionKey(key)
        .catch((error) => {
          commit("keysError");
          throw error;
        });
      dispatch("loadKeys");
    },
    async initHardwareRestrictionKeys({ commit, dispatch }) {
      // const keys = JSON.parse(localStorage.getItem('hardwareKeys')) || [];
      const store = new HardwareRestrictionDb();
      await store.openDB();
      const keys = await store.getAll();
      commit("updateLocalKeys", keys);
      if (keys.length > 0) {
        // Resolve key capabilities
        // TODO: handle error case
        await dispatch("updateLocalKeyCapabilitites");
      } else {
        commit("localKeyCapabilitiesLoaded", []);
      }
    },
    async updateLocalKeyCapabilitites({ commit, state, dispatch }) {
      commit("localKeyCapabilitiesLoading");
      const responses = await Promise.all(
        state.localKeys.map((key) =>
          hardwareRestrictionService
            .getRestrictionKeyCapabilities(key.id)
            .catch((error) => {
              if (error && error.response && error.response.status === 404) {
                // The key is unkown to the backend, thus it was deleted and we should also remove it locally
                dispatch("removeKeyLocally", key);
              }
            }),
        ),
      );
      commit(
        "localKeyCapabilitiesLoaded",
        state.localKeys.map((key, index) => ({
          key,
          ...(responses[index] !== undefined ? responses[index].data : []),
        })),
      );
    },
    async storeKeyLocally({ state, commit }, key) {
      const store = new HardwareRestrictionDb();
      await store.openDB();
      await store.insert(key);
      const updatedKeys = [...new Set([...state.localKeys, key])];
      commit("updateLocalKeys", updatedKeys);
    },
    async removeKeyLocally({ commit, state }, key) {
      const store = new HardwareRestrictionDb();
      await store.openDB();
      await store.remove(key.id);
      commit("removeLocalKey", key);
    },
  },
  getters: {
    hardwareKeysLoadingState: (state) => state.keys,
    localKeys: (state) => state.localKeys,
    localKeyCapabilities: (state) =>
      state.localKeyCapabilititesLoadingState.result,
    localKeyCapabilititesLoadingState: (state) =>
      state.localKeyCapabilititesLoadingState,
  },
};
