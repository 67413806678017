import _ from "lodash";
import {
  stateError,
  stateInitial,
  stateLoaded,
  stateLoading,
} from "./loadingStates";
import covidService from "@/services/covid";

export default {
  state: {
    settings: {
      result: null,
      loadingState: stateInitial,
    },
    translations: {
      loadingState: stateInitial,
      translations: [],
      // patches are of type {id, type, key, lang, text}
      patches: [],
      defaultLanguage: null,
    },
    fulfillsCovidRulesLoading: [],
  },
  mutations: {
    covidRulesForUserLoading(state, user) {
      state.fulfillsCovidRulesLoading.push(user.id);
    },
    covidRulesForUserLoaded(state, user) {
      state.fulfillsCovidRulesLoading.splice(
        state.fulfillsCovidRulesLoading.findIndex((id) => id === user.id),
        1,
      );
    },
    covidSettingsLoading(state) {
      state.settings.loadingState = stateLoading;
    },
    covidSettingsLoaded(state, response) {
      state.settings.loadingState = stateLoaded;
      state.settings.result = response;
    },
    covidSettingsError(state) {
      state.settings.loadingState = stateError;
    },
    loadCovidTranslations(state) {
      state.translations.loadingState = stateLoading;
    },
    covidTranslationsLoaded(state, response) {
      state.translations.translations = response.result;
      state.translations.defaultLanguage = response.defaultLanguage;
      state.translations.loadingState = stateLoaded;
    },
    covidTranslationsSaved(state, updatedTranslations) {
      state.translations.translations = updatedTranslations;
      state.translations.patches = [];
      state.translations.loadingState = stateLoaded;
    },
    covidTranslationsError(state) {
      state.translations.loadingState = stateError;
    },
    // NOTE: patch here is expected to be {id, type, key, lang, text},
    addCovidTranslationPatch(state, patch) {
      const existingPatchIndex = state.translations.patches.findIndex(
        (item) =>
          item.type === patch.type &&
          item.id === patch.id &&
          (item.key === patch.key) & (item.lang === patch.lang),
      );
      if (existingPatchIndex > -1) {
        state.translations.patches.splice(existingPatchIndex, 1, patch);
      } else {
        state.translations.patches.push(patch);
      }
    },
    // directly patch the backend translation state
    patchCovidTranslation(state, { stepId, patch }) {
      // NOTE: direct patches to translation only happen for file uploads,
      // i.e. we can safely assume here that the key for the patch is 'url'
      const stepItemIndex = state.translations.translations.findIndex(
        (item) => item.id === stepId,
      );
      if (stepItemIndex === -1) {
        return;
      }
      const translationItemIndex = state.translations.translations[
        stepItemIndex
      ].translations.findIndex((item) => item.key === patch.key);
      const translationItem =
        state.translations.translations[stepItemIndex].translations[
          translationItemIndex
        ];
      state.translations.translations[stepItemIndex].translations,
        translationItemIndex,
        _.merge({}, translationItem, patch);
    },
  },
  actions: {
    setCovidRulesChecked({ commit }, { user }) {
      commit("covidRulesForUserLoading", user);
      const response = covidService.setCovidRulesChecked(user.id).then(
        () => {
          commit("covidRulesForUserLoaded", user);
          commit("covidRulesSet", user);
        },
        (error) => {
          commit("addNewNotification", {
            text: "genericError",
            title: "error",
            notificationType: "error",
          });
          commit("covidRulesForUserLoaded", user);
        },
      );
    },
    async loadCovidSettings({ commit }) {
      commit("covidSettingsLoading");
      try {
        const response = await covidService.getVaccinationSettings();
        commit("covidSettingsLoaded", response.data);
      } catch (error) {
        commit("covidSettingsError");
      }
    },
    async loadCovidTranslations(context) {
      context.commit("loadCovidTranslations");
      const response = await covidService.getTranslations().catch((error) => {
        context.commit("covidTranslationsError");
        throw error;
      });
      context.commit("covidTranslationsLoaded", response.data);
    },
    async saveCovidTranslations(context, courseId) {
      context.commit("loadCovidTranslations");
      const patches = context.state.translations.patches.reduce((agg, curr) => {
        const item = agg.find(
          (item) => item.type === curr.type && item.id === curr.id,
        );
        if (item) {
          const existingTranslation = item.translations.find(
            (item) => item.key === curr.key,
          );
          if (existingTranslation) {
            existingTranslation.values[curr.lang] = curr.text;
          } else {
            const values = {};
            values[curr.lang] = curr.text;
            item.translations.push({ key: curr.key, values });
          }
        } else {
          const values = {};
          values[curr.lang] = curr.text;
          agg.push({
            type: curr.type,
            id: curr.id,
            translations: [
              {
                key: curr.key,
                values,
              },
            ],
          });
        }
        return agg;
      }, []);
      await covidService.patchTranslations(patches).catch((error) => {
        context.commit("addNewNotification", {
          text: "genericError",
          title: "error",
          notificationType: "error",
        });
        context.commit("covidTranslationsError");
      });
      context.commit(
        "covidTranslationsSaved",
        context.getters.mergedCovidTranslations,
      );
    },
  },
  getters: {
    covidSettings: (state) => state.settings.result,
    covidSettingsLoadingState: (state) => state.settings.loadingState,
    covidSettingsDisplay: () => [
      {
        label: "2G",
        value: "2g",
      },
      {
        label: "2G Plus",
        value: "2gp",
      },
      {
        label: "3G",
        value: "3g",
      },
      {
        label: "3G Plus",
        value: "3gp",
      },
      {
        label: "-",
        value: null,
      },
    ],
    covidTranslationsLoadingState: (state) => state.translations.loadingState,
    covidTranslations: (state) => state.translations.translations,
    covidTranslationsDefaultLang: (state) => state.translations.defaultLanguage,
    covidRulesLoading: (state) => state.fulfillsCovidRulesLoading,
    mergedCovidTranslations: (state) => {
      return state.translations.translations.map((item) => ({
        ...item,
        translations: item.translations.map((translation) => {
          const copy = { ...translation };
          state.translations.patches
            .filter(
              (patch) =>
                item.type === patch.type &&
                item.id === patch.id &&
                copy.key === patch.key,
            )
            .forEach((patch) => (copy.values[patch.lang] = patch.text));
          return copy;
        }),
      }));
    },
    covidTranslationHasChanges: (state) =>
      state.translations.patches.length > 0,
  },
};
