export default {
  termsAccept: "Αποδέχομαι τους {0}.",
  tos: "ΓΟΣ",
  privacyAccept: "Αποδέχομαι τη {0}.",
  privacyPolicy: "Δήλωση προστασίας δεδομένων",
  resetPassword: "Επαναφορά κωδικού πρόσβασης",
  usernamePasswordRequired:
    "Εισαγάγετε ένα όνομα χρήστη και έναν κωδικό πρόσβασης.",
  passwordTooShort:
    "Ο κωδικός πρόσβασης πρέπει να έχει μήκος τουλάχιστον 8 χαρακτήρων.",
  wrongCredentials:
    "Δεν βρέθηκε κανένας λογαριασμός που να αντιστοιχεί στα καταχωρισμένα στοιχεία.",
  accountStructureItem: "Τμήμα",
  addRole: "Προσθήκη ρόλου",
  allUserRelatedDeleteText:
    "Με αυτό, όλα τα δεδομένα που αφορούν στον χρήστη θα διαγραφούν οριστικά.",
  assignRoleModals: "Ανάθεση ρόλου",
  canBeDownloadedText: "μπορεί να γίνει λήψη τους στο ολοκληρώθηκε",
  changePassword: "Αλλαγή κωδικού πρόσβασης",
  changingPasswordFor: "Αλλαγή κωδικού πρόσβασης για τον χρήστη",
  confirmDeleteText: "Πληκτρολογήστε το όνομα χρήστη για επιβεβαίωση:",
  confirmDeteleBtnText: "Κατάλαβα, διάγραψε τον χρήστη",
  confirmPassword: "Επανάληψη κωδικού πρόσβασης",
  createAccount: "Δημιουργία λογαριασμού",
  createUser: "Δημιουργία χρήστη",
  currentPassword: "Τρέχων κωδικός πρόσβασης",
  customId: "ID ορισμένο από τον χρήστη",
  dataPrivacyHeader: "Ιδιωτικότητα δεδομένων",
  dataPrivacyText: "Κείμενο ενότητας (ακολουθεί περιεχόμενο)",
  dataPrivacyTitle: "Τίτλος ενότητας",
  dataRelatedToMe: "Προβολή αποθηκευμένων δεδομένων σχετικά με εμένα",
  dataRelatedToMeTitle: "Αποθηκευμένα δεδομένα",
  deleteText: "Σίγουρα θέλετε να διαγραφεί αυτό",
  downloadDataRelatedToMe: "Λήψη αποθηκευμένων δεδομένων",
  fileWithUserInformation: "Αρχείο με στοιχεία χρήστη",
  forgotPassword: "Ξεχάσατε τον κωδικό πρόσβασης;",
  forgotPasswordText:
    "Εισαγάγετε τη διεύθυνση του email σας για να λάβετε έναν σύνδεσμο για την επαναφορά του κωδικού σας πρόσβασης.",
  importUsers: "Εισαγωγή χρήστη",
  newHere: "Πρώτη φορά εδώ;",
  newPassowrd: "Νέος κωδικός πρόσβασης",
  newPasswordShort: "Νέος κωδικός πρόσβασης υπερβολικά μικρός",
  password: "Κωδικός πρόσβασης",
  passwordCantBeEmpty: "Ο κωδικός πρόσβασης δεν μπορεί να είναι κενός",
  passwordsNotMatch: "Οι κωδικοί πρόσβασης διαφέρουν",
  pickUser: "Επιλέξτε έναν χρήστη",
  pleaseFillUpAllFields: "Συμπληρώστε όλα τα πεδία",
  pleaseTypeNewPassword: "Εισαγάγετε τον νέο σας κωδικό πρόσβασης",
  policyAcceptance: "Αποδοχή των κατευθυντήριων γραμμών",
  policyAcceptanceSave: "Αποδοχή",
  acceptPolicies: "Αποδεχτείτε τις κατευθυντήριες γραμμές",
  profile: "Προφίλ",
  role: "Ρόλος",
  roleExpiresOn: "Ο ρόλος λήγει στις",
  saveDataAboutMe: "αποθηκευμένα δεδομένα σχετικά με εμένα",
  signIn: "Σύνδεση",
  termsConditionsHeader: "Γενικοί Όροι Συναλλαγών",
  termsConditionsText: "Κείμενο ενότητας (ακολουθεί περιεχόμενο)",
  termsConditionsTitle: "Τίτλος ενότητας",
  typeInOne: "Εισαγάγετε έναν για όλους",
  username: "Όνομα χρήστη",
  firstname: "Όνομα",
  lastname: "Επώνυμο",
  usernameMail: "Όνομα χρήστη ή email",
  userNotes: "Σημειώσεις",
  userProfile: "Προφίλ χρήστη",
  userStatus: "Κατάσταση χρήστη",
  wrongCurrentPassword: "Λάθος τρέχων κωδικός πρόσβασης",
  fillAllFields: "Συμπληρώστε όλα τα υποχρεωτικά πεδία",
  wrongUsernameOrPassword: "Λάθος όνομα χρήστη ή κωδικός πρόσβασης",
  minimumLength: "ελάχιστο μήκος 8 χαρακτήρες",
  atLeastOneBigAndSmall:
    "τουλάχιστον 1 πεζός και 1 κεφαλαίος αλφαβητικός χαρακτήρας",
  passwordNotEqualUsernameAndEmail:
    "ο κωδικός πρόσβασης δεν αντιστοιχεί στο όνομα χρήστη και τη διεύθυνση email",
  registrationKey: "Κωδικοί εγγραφής",
  registration: "Εγγραφή",
  notAnEmailAdress: "Εισαγάγετε μια έγκυρη διεύθυνση email.",
  company: "Εταιρία",
  companyHint: "Εταιρία",
  birthday: "Γενέθλια",
  birthdayHint: "Γενέθλια",
  accountName: "Όνομα λογαριασμού",
  accountType: "Είδος λογαριασμού",
  accountAddress: "Διεύθυνση δικαιούχου λογαριασμού",
  logo: "Λογότυπο",
  address: "Διεύθυνση",
  contry: "Χώρα",
  zipCode: "Ταχυδρομικός κώδικας",
  lessThan: "λιγότερο από 1mb",
  defaultLanguage: "Προεπιλεγμένη γλώσσα",
  accountStatus: "Κατάσταση λογαριασμού",
  accountNotes: "Σημειώσεις λογαριασμού",
  createAccount: "Δημιουργία λογαριασμού",
  updateAccount: "Ενημέρωση λογαριασμού",
  fileToImport: "Σημειώσεις λογαριασμού",
  importStructure: "Εισαγωγή δομής",
  addRootAccount: "Προσθήκη λογαριασμού βασικού επιπέδου",
  usersPerMonth: "Μηνιαίοι χρήστες",
  usersAdded: "Προστιθέμενοι χρήστες",
  usersDeleted: "Διαγραμμένοι χρήστες",
  usersTotal: "Χρήστες σύνολο",
  city: "Πόλη",
  knowledge: "ΓΝΩΣΗ",
  toolbox: "ΕΡΓΑΛΕΙΑ",
  lessonCompleted: "Το μάθημα ολοκληρώθηκε",
  lessonFailed: "Το μάθημα δεν ολοκληρώθηκε",
  repeatLesson: "Επαναλάβατε το μάθημα για να συνεχίσετε",
  congratulations: "Συγχαρητήρια!",
  courseCompleted: "Μάθημα ολοκληρώθηκε",
  percentCompleted: "Απαντήσατε σωστά στο $successRate% των ερωτήσεων.",
  downloadCertificate:
    "Μπορείτε να κατεβάσετε το πιστοποιητικό σας οποτεδήποτε από το «Πιστοποιητικά» στο προφίλ σας και να το ζητήσετε μέσω email.",
  trueFalse: "Σωστό ή λάθος;",
  seconds: "Δευτερόλεπτα",
  noChoiceText: "Δυστυχώς, είναι πολύ αργά.",
  right: "Σωστά!",
  wrong: "Δεν είναι σωστό",
  progress: "$current από $total",

  addCourseContent: "Προσθήκη περιεχομένων μαθήματος",
  addSemicolon: "Προσθέστε ετικέτες χωρισμένες με ερωτηματικό",
  addToProgram: "Προσθήκη στο πρόγραμμα",
  allAccounts: "Όλοι οι λογαριασμοί",
  certificate: "Πιστοποιητικό",
  certificates: "Πιστοποιητικά",
  completed: "Ολοκληρώθηκε",
  contentIsLoaded: "Το περιεχόμενο φορτώθηκε",
  contentManager: "Διαχειριστής περιεχομένων",
  courseCompleted: "Μάθημα ολοκληρώθηκε",
  courseContent: "Περιεχόμενο μαθήματος",
  courseDocuments: "Έγγραφα",
  courseLables: "Ετικέτες μαθήματος",
  courseLanguage: "Γλώσσα μαθήματος",
  courseNotes: "Σημειώσεις μαθήματος",
  courseStructure: "Δομή",
  courseTags: "Tags μαθήματος",
  courseTitle: "Ονομασία μαθήματος",
  createCourse: "Δημιουργία μαθήματος",
  curator: "ΕΠΟΠΤΗΣ",
  description: "Περιγραφή",
  details: "Λεπτομέρειες",
  dueDate: "Λήξη έως",
  duration: "Διάρκεια",
  exportStatistics: "Εξαγωγή στατιστικών",
  learningStandard: "Μαθησιακό πρότυπο",
  licenses: "Άδειες χρήσης",
  mandatory: "Υποχρεωτικό μάθημα",
  noAssessment: "Αυτή τη στιγμή δεν υπάρχει καμία αξιολόγηση προς εξέταση.",
  noCoursesAssigned: "Δεν υπάρχουν μαθήματα στον λογαριασμό σας. Επισκεφθείτε",
  noCoursesAvailable: "Αυτή τη στιγμή δεν υπάρχουν διαθέσιμα μαθήματα.",
  noDueDate: "Καμία ημερομηνία λήξης",
  overview: "ΕΠΙΣΚΟΠΗΣΗ",
  page: "",
  pickAnAccount: "Επίλεξε έναν λογαριασμό",
  printCertificate: "Εκτύπωση πιστοποιητικού",
  publishCourse: "Δημοσίευση μαθήματος",
  publishedToAccount: "Δημοσιεύτηκε στον λογαριασμό",
  relatedCourses: "Παρόμοια μαθήματα",
  remove: "Αφαίρεση",
  removeFromProgram: "Αφαίρεση από το πρόγραμμα",
  singleAccount: "Μεμονωμένος λογαριασμός",
  startCourse: "Έναρξη",
  successMessageOne: "Συγχαρητήρια, ολοκληρώσατε το μάθημα ",
  successMessageThree:
    "Τώρα μπορείτε να κατεβάσετε ή να εκτυπώσετε το πιστοποιητικό σας ολοκλήρωσης. ",
  successMessageTwo: " με επιτυχία!",
  tags: "Λέξεις αναφοράς",
  unpublishCourse: "Κατάργηση δημοσίευσης",
  updateCourseContent: "Ανέβασμα περιεχομένων μαθήματος",
  updateCourse: "Ενημέρωση μαθήματος",
  youDidntfinishCourse: "Ακόμα δεν έχεις ολοκληρώσει κανένα μάθημα.",
  courseVisibleFor: "Μάθημα ορατό για",
  accountStructureItem: "Τμήμα",
  courseAssigmentOptions: "Επιλογές ανάθεσης",
  assignCourse: "Ανάθεση μαθήματος",
  pickACourse: "Επίλεξε ένα μάθημα",

  noDocumentsAvailable: "δεν υπάρχουν έγγραφα",
  nothingRelated: "δεν υπάρχει τίποτα παρόμοιο",
  nothingWritten: "δεν έχει καταχωρισθεί τίποτα",
  noTagsAvailable: "δεν υπάρχουν λέξεις αναφοράς",
  alreadyGlobal: "Το μάθημα έχει ήδη δημοσιευτεί παγκοσμίως.",
  questionsanswered: "Ερωτήσεις",
  completedOn: "Ολοκληρώθηκε στις",
  locale: "el-GR",
  quizAuthorizationContent: "Εξουσιοδότηση κουίζ",

  courseNotPublished: "Προς το παρόν το μάθημα δεν έχει δημοσιευτεί",
  courseNotPublishedForAccount:
    "Προς το παρόν το μάθημα δεν έχει δημοσιευτεί για τον λογαριασμό $account",
  clickToPublishCourse: "Δημοσίευση μαθήματος",
  visible: "Ορατό",
  userIsNotContentManager:
    "Δεν είστε Content Manager και συνεπώς δεν μπορείτε να επεξεργαστείτε το περιεχόμενο του μαθήματος.",
  assignmentOpen: "Εκκρεμεί",
  assignmentStarted: "Ξεκίνησε",
  assignmentCompleted: "Ολοκληρώθηκε",
  dueDatePassed: "Υπέρβαση ημερομηνίας λήξης",

  exitCourse: "Τερματισμός μαθήματος",
  markAsFinished: "Επισήμανση ως επεξεργασμένο",

  system: "Σύστημα",
  configureLogging: "Διαμόρφωση πρωτοκόλλου",
  createAccount: "Δημιουργία λογαριασμού",
  changeAccount: "Αλλαγή λογαριασμού",
  deleteAccount: "Διαγραφή λογαριασμού",
  importAccountStructure: "Εισαγωγή δομής λογαριασμού",
  createUser: "Δημιουργία χρήστη",
  importUser: "Εισαγωγή χρήστη",
  changeUser: "Αλλαγή χρήστη",
  deleteUser: "Διαγραφή χρήστη",
  assignUserRole: "Ανάθεση ρόλου",
  resetPassword: "Επαναφορά κωδικού πρόσβασης",

  createCourse: "Δημιουργία μαθήματος",
  changeCourse: "Αλλαγή μαθήματος",
  addQuizContent: "Προσθήκη περιεχομένου κουίζ",
  changeQuizContent: "Αλλαγή περιεχομένου κουίζ",
  addContent: "Προσθήκη περιεχομένου μαθήματος",
  changeContent: "Αλλαγή περιεχομένου μαθήματος",
  deleteCourse: "Διαγραφή μαθήματος",
  deleteContent: "Διαγραφή περιεχομένου μαθήματος",
  publishCourse: "Δημοσίευση μαθήματος",
  unpublishCourse: "Η δημοσίευση του μαθήματος ακυρώθηκε",
  assignCourse: "Ανάθεση μαθήματος",
  unassignCourse: "Κατάργηση ανάθεσης μαθήματος",

  systemMessages: "Μηνύματα συστήματος",

  learningManagement: "Διαχείριση μάθησης",
  courseManagement: "Διαχείριση μαθημάτων",
  userManagement: "Διαχείριση χρηστών",
  organizationStructure: "Δομή οργάνωσης",
  accountManagement: "Διαχείριση λογαριασμών",
  system: "Σύστημα",

  learningManagementDetail: "Προγράμματα εκμάθησης και ανάθεση μαθήματος",
  courseManagementDetail: "Διαχείριση περιεχομένων μαθήματος",
  userManagementDetail: "Διαχείριση χρηστών και επαναφορά κωδικών",
  organizationStructureDetail: "Ορισμός δομής οργάνωσης",
  accountManagementDetail: "Διαχείριση λογαριασμών",
  logsDetail: "Αρχεία log συστήματος και ρυθμίσεις",

  userCreateSuccess: "Επιτυχής δημιουργία χρήστη",
  createOtherUser: "Προσθήκη άλλου χρήστη",
  backToUserOverview: "Επιστροφή στην επισκόπηση χρηστών",
  importMoreUsers: "Εισαγωγή άλλων χρηστών",
  currentRoles: "Τρέχοντες ρόλοι",
  numberOfRoles: "Πλήθος ρόλων",
  numberOfUsers: "Χρήστες",
  deleteAccountMessage:
    "Επιβεβαιώστε πως θέλετε να διαγραφεί ο λογαριασμός '$account'.",
  showStatistics: "Προβολή στατιστικών",
  appendAccount: "Επισύναψη λογαριασμού σε $account.",

  courseAssignments: "Ανατεθειμένα",
  courseAssignmentsForCourse: "Αναθέσεις μαθημάτων για $course",
  mandatoryAssignments: "Αναθέσεις υποχρεωτικών μαθημάτων",
  dueDateAssignments: "Με προθεσμία",
  dueDatePassed: "Λήξη",
  userOrAccount: "Χρήστης ή τμήμα",
  expiryDate: "Ημερομηνία λήξης",
  deletePublication: "Διαγραφή δημοσίευσης",
  deletePublicationMessage:
    "Σίγουρα θέλετε να διαγραφεί η δημοσίευση για τον λογαριασμό $account;",
  orgStructure: "Τμήμα",
  type: "Τύπος",
  downloadTemplate: "Λήψη προτύπου",
  published: "Δημοσιευμένα",
  scorm: "SCORM",
  quiz: "Κουίζ",
  staticFile: "Pdf",
  editPublication: "Επεξεργασία δημοσίευσης",
  pleaseDefineCourseContent:
    "please define the course content before publishing it.",

  usernameMissing: "Λείπει το όνομα χρήστη",
  usernameError:
    "Το όνομα χρήστη μπορεί να αποτελείται μόνο από αλφαριθμητικούς χαρακτήρες",
  emailMissing: "Λείπει το email",
  emailError: "Μη έγκυρη διεύθυνση email",
  passwordMissing: "Λείπει ο κωδικός πρόσβασης",
  passwordError:
    "Ο κωδικός πρόσβασης πρέπει να έχει μήκος τουλάχιστον 8 χαρακτήρων και να περιέχει έναν πεζό και έναν κεφαλαίο αλφαβητικό χαρακτήρα.",

  registrationKeyManagement: "Κωδικοί εγγραφής",
  registrationKeyManagementDetail: "Διαχείριση κωδικών εγγραφής",
  addRegistrationKey: "Προσθήκη κωδικών εγγραφής",
  registrationLink: "Σύνδεσμος εγγραφής",
  createKey: "Προσθήκη",

  userState: "Κατάσταση χρήστη",
  userStateDetail: "Κατάσταση άδειας πρόσβασης και υποχρεωτικών μαθημάτων.",
  testsPassed: "Οι δοκιμές πέρασαν",
  access: "Πρόσβαση",
  downloadReport: "Λήψη αναφοράς",
  success: "Αποθηκεύτηκε επιτυχώς!",
  warning: "Προσοχή!",
  error: "Σφάλμα!",
  removedFromProgram: "Αυτό το μάθημα αφαιρέθηκε από το πρόγραμμά σας",
  addedToProgram: "Αυτό το μάθημα προστέθηκε στο πρόγραμμά σας",
  courseCreated: "Το μάθημα δημιουργήθηκε",
  courseNotCreated: "Το μάθημα δεν δημιουργήθηκε",
  fillUpFields: "Συμπληρώστε όλα τα υποχρεωτικά πεδία",
  cookieNotAvailable:
    "Τα στοιχεία σύνδεσης δεν υπάρχουν ή είναι απαρχαιωμένα, συνδεθείτε ξανά",
  courseNotFinished:
    "Δεν υπάρχουν πληροφορίες σχετικά με την ολοκλήρωση του μαθήματος",
  courseAborted: "Το μάθημα διακόπηκε",
  correctAnswer: "Σωστή απάντηση",
  wrongAnswer: "Λάθος απάντηση",
  courseStarted: "Το μάθημα ξεκίνησε",
  testsPassed: "Περάσατε το τεστ",
  testFailed: "Δεν περάσατε το τεστ",
  courseCompleted: "Το μάθημα ολοκληρώθηκε με επιτυχία",
  imageTooLarge: "Το μέγεθος αρχείου δεν μπορεί να υπερβαίνει τα 2mb",
  wrongFormat: "Λάθος μορφή αρχείου",
  passwordReset:
    "Ένας σύνδεσμος για την επαναφορά του κωδικού πρόσβασης στάλθηκε στην αναφερόμενη διεύθυνση email",
  passwordChanged: "Ο κωδικός πρόσβασης άλλαξε",
  accountAdded: "Ο λογαριασμός προστέθηκε",
  accountUpdated: "Ο λογαριασμός ενημερώθηκε",
  accountDeleted: "Ο λογαριασμός διαγράφηκε",
  userImported: "Προστέθηκαν χρήστες",
  userNotImported: "Δεν έγινε εισαγωγή χρηστών",
  loggingConfigSaved: "Η διαμόρφωση σύνδεσης αποθηκεύτηκε",
  userDeleted: "Ο χρήστης διαγράφηκε",
  userAdded: "Ο χρήστης δημιουργήθηκε",
  userUpdated: "Ο χρήστης ενημερώθηκε",
  GDPRLoaded: "Ο ΓΚΠΔ φορτώθηκε",
  cookiesDisabled: "Έχετε απενεργοποιήσει τα Cookies",
  wrongCredentials: "Λάθος στοιχεία σύνδεσης",
  fileUploaded: "Το αρχείο σας φορτώθηκε",
  courseContentUpdated: "Το περιεχόμενο του μαθήματος ενημερώθηκε",
  coursePublished: "Το μάθημα δημοσιεύτηκε",
  courseUnPublished: "Η δημοσίευση του μαθήματος καταργήθηκε.",
  serverUnreachable: "Ο διακομιστής δεν είναι προσβάσιμος",
  selectAccounts: "Επιλογή λογαριασμού",
  selectAccountToContinue: "Επιλέξτε λογαριασμό για να συνεχίσετε",
  accountOverview: "Επισκόπηση λογαριασμού",
  users: "Χρήστες",
  courses: "Μαθήματα",
  coursesCompleted: "Ολοκληρωμένα μαθήματα",
  completedCoursesPerUser: "Ολοκληρωμένα μαθήματα ανά χρήστη",
  learningHours: "Ώρες εκμάθησης",
  learningHoursPerUser: "Ώρες εκμάθησης ανά χρήστη",
  courseOverview: "Επισκόπηση μαθήματος",
  completed: "Ολοκληρώθηκε",
  assigned: "Ανατεθειμένα",
  courseDetails: "Λεπτομέρειες",
  selectCourse: "Επιλογή μαθήματος",
  details: "Επιλογή",
  type: "Τύπος",
  dueDate: "Έως",
  mandatory: "Υποχρεωτικό μάθημα",
  completionRate: "Ποσοστό επιτυχούς ολοκλήρωσης",
  averageScore: "Αποτέλεσμα τεστ",
  duration: "Διάρκεια",
  averageDuration: "Μέση διάρκεια",
  account: "Λογαριασμός",
  accountName: "Όνομα λογαριασμού",
  accounts: "Λογαριασμοί",
  active: "Ενεργοί",
  admin: "Διαχειριστής",
  all: "Όλοι",
  not_found: "Η σελίδα δεν υπάρχει",
  assessmentStatistics: "Στατιστικά αξιολόγησης",
  assignedUsersOnly: "Μόνο χρήστες με αναθέσεις",
  autoIndividual: "Αυτόματα, εξατομικευμένα",
  cancel: "Ακύρωση",
  cardImage: "Εικόνα χάρτη",
  close: "Κλείσιμο",
  completed: "Ολοκληρώθηκε",
  confirm: "Επιβεβαίωση",
  country: "Χώρα",
  course: "Μαθήματα",
  courseDescription: "Περιγραφή μαθήματος",
  courseDocuments: "Έγγραφα μαθήματος",
  courses: "Βιβλιοθήκη",
  courseStructure: "Δομή μαθήματος",
  dataProtection: "Προστασία δεδομένων",
  defaultLanguage: "Προεπιλεγμένη γλώσσα",
  deleteText: "Σίγουρα θέλετε να διαγραφεί αυτό",
  delete: "Διαγραφή",
  details: "Λεπτομέρειες",
  dragAndDrop: "Drag & Drop / Κάντε κλικ για προσθήκη",
  dueDate: "Λήγει στις",
  email: "email",
  empty: "Κενό",
  exit: "Αποσύνδεση",
  expiresOn: "Λήγει στις",
  fileImport: "Αρχείο προς εισαγωγή",
  image: "Εικόνα",
  inactive: "Ανενεργοί",
  language: "Γλώσσα",
  logo: "Λογότυπο",
  mandatory: "Υποχρεωτικό μάθημα",
  monthsLabels: [
    "Ιανουάριος",
    "Φεβρουάριος",
    "Μάρτιος",
    "Απρίλιος",
    "Μάιος",
    "Ιούνιος",
    "Ιούλιος",
    "Αύγουστος",
    "Σεπτέμβριος",
    "Οκτώβριος",
    "Νοέμβριος",
    "Δεκέμβριος",
  ],
  myAccount: "Ο λογαριασμός μου",
  myProfile: "Το προφίλ μου",
  myProgram: "Το πρόγραμμά μου",
  myRoles: "Οι ρόλοι μου",
  never: "Ποτέ",
  no: "Όχι",
  none: "Κανένας",
  notFound: "Δεν βρέθηκε",
  phoneNumber: "Αριθμός τηλεφώνου",
  playImage: "Αναπαραγωγή εικόνας",
  publishingExpiresOn: "Η δημοσίευση λήγει στις",
  publishTo: "Δημοσίευση σε",
  reports: "Έκθεση",
  save: "Αποθήκευση",
  search: "Αναζήτηση…",
  status: "Κατάσταση",
  structureItem: "Τμήμα",
  termsAndConditions: "Όροι συναλλαγών",
  unlimited: "Απεριόριστα",
  update: "Ενημέρωση",
  user: "Χρήστες",
  username: "Όνομα χρήστη",
  users: "Χρήστες",
  yes: "Ναι",
  zipCode: "Ταχυδρομικός κώδικας",

  info: "Πληροφορίες",
  error: "Σφάλμα",
  alert: "Ειδοποίηση",
  criticalAlert: "Κρίσιμη ειδοποίηση",
  navigation: "Πλοήγηση",
  system: "Σύστημα",
  note: "Σημείωση",
  userProfile: "Προφίλ χρήστη",

  userId: "ID χρήστη",
  customId: "",
  organizationalStructure: "Δομή οργάνωσης",
  created: "Δημιουργήθηκε στις",
  new: "Νέο",
  noResults: "Κανένα αποτέλεσμα",
  noData: "Κανένα δεδομένο",
  uploadFile: "Ανέβασμα αρχείου",
  id: "ID",
  name: "Όνομα",
  append: "Προσθήκη",
  edit: "Επεξεργασία",
  format: "Μορφή αρχείου",
  minutes: "Λεπτά",
  hours: "Ώρες",
  date: "Ημερομηνία",
  message: "Μήνυμα",
  fax: "Φαξ",

  genericError:
    "Παρουσιάστηκε κάποιο σφάλμα, προσπαθήστε να φορτώσετε ξανά τη σελίδα. Εάν το σφάλμα επιμένει, επικοινωνήστε με τον διαχειριστή του συστήματος.",
  action: "Ενέργεια",

  uploadedCertificate: "Μεταφορτωμένα πιστοποιητικά",

  add_mandatory_user_property:
    "Επιλέξτε τα ακόλουθα χαρακτηριστικά για την εργασία σας",
  help: "Βοήθεια",
  alreadyRegistered: "Εχουν ήδη καταχωρηθεί",
  additionalDetails: "Επιπλέον πληροφορίες",
  additionalDetailsSave: "Για να επιβεβαιώσετε",
  dragAndDropCertificate:
    "Σύρετε & αφήστε το αρχείο PDF εδώ ή κάντε κλικ για να το προσθέσετε",
  coverPhoto: "Cover Photo",
  EditCoverPhoto: "Edit Cover Photo",
  AddCoverPhoto: "Add Cover Photo",
  additionalDocuments: "Additional Documents",
  editAdditionalDocuments: "Edit Additional Documents",
  addAdditionalDocuments: "Add Additional Documents",
  fileName: "File name",
  fileSize: "File size",
  userProfileV2: "User profile V2",
  certification: "Certification",
  issuedBy: "Issued by",
  issuedOn: "Issued on",
  fulfilledInPercent: "Fulfilled in percent",
  systemLogs: "System Logs",
  systemConfigurationDetail: "Configure system settings",
  systemConfiguration: "System configuration",
  courseFormat: "Course formats",
  views: "Views",
  apis: "APIs",
  logs: "Logs",
  viewsDescription: "Visibility of the element in the respective view",
  courseFormatDescription: "Available course formats for defining the course",
  accessCard: "Access card",
  downloadCertification: "Download Certification",
  logout: "Αποσύνδεση",
  continue: "Συνέχεια",
  courseFinishedDoYouWantToLogout:
    "Το μάθημα έχει ολοκληρωθεί. Θέλετε να αποσυνδεθείτε ή να συνεχίσετε; Θα αποσυνδεθείτε αυτόματα σε {seconds}.",
  stateless: "ανιθαγενής",
};
