<template>
  <span class="footer-item" @click="click">
    <slot />
  </span>
</template>

<script>
export default {
  name: "FooterItem",
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
<style lang="stylus">
.footer-item {
  margin: 0 0 0 15px;
  cursor: pointer;

  &:hover {
    color: #000;
  }
}
</style>
