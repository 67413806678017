import API from "./config";
import axios from "axios";
import { mapResponse, printError } from "../utils/mapResponse";

/**
 *  @module Logger service
 */

export default {
  /**
   * @typedef {Object} Log
   * @property {number} accountId - account id
   * @property {string} action - log type
   * @property {string} actionResult - action text
   * @property {string} actionResult_kpi - action kpi
   * @property {number} courseId - course id
   * @property {object} details - additional info
   * @property {number} id - id
   * @property {string} type - action type
   * @property {number} userId - user id
   */

  /**
   * API call to get all logs
   * DEPRECATED: use the logs service endpoint instead
   * @returns {Array<Log>} array of logs
   */
  LOGS_GET(options) {
    return axios
      .get(API.coursesLoggingURL + "?" + new URLSearchParams(options))
      .then(mapResponse);
  },

  // TODO: remove deprecated endpoint, use this endpoint instead
  getLogs(options) {
    return axios
      .get(`${API.baseUrlV2}businesslogs/logs?${new URLSearchParams(options)}`)
      .then(mapResponse)
      .catch(printError);
  },

  getLogSettings() {
    return axios
      .get(`${API.baseUrlV2}businesslogs/configuration`)
      .catch(printError);
  },

  patchLogSettings(patch) {
    return axios
      .patch(`${API.baseUrlV2}businesslogs/configuration`, patch)
      .catch(printError);
  },

  getlogGlobalSettings() {
    return axios
      .get(`${API.baseUrlV2}businesslogs/systemconfiguration`)
      .catch(printError);
  },

  getLogsFilter() {
    return axios.get(`${API.baseUrlV2}businesslogs/filter`).then(mapResponse);
  },

  /**
   * API call to post info log
   * @param {object} paylaod - payload object
   * @param {string} payload.action - action type, now restricted by 'info'
   * @param {string} payload.actionResultKpi - action kpi number
   * @param {string} payload.actionResult - action text
   * @param {string} payload.actionResultShort - action cutted text
   * @param {number} payload.accountId - account id
   * @param {number} payload.courseId - course id
   * @param {string} payload.type - type, now restricted by 'course' module
   * @returns {Log} added log
   */
  POST_INFO(payload) {
    return axios.post(`${API.coursesLoggingURL}`, payload);
  },
};
