export default {
  termsAccept: "Souhlasím s {0}.",
  tos: "VOP",
  privacyAccept: "Souhlasím s {0}.",
  privacyPolicy: "Prohlášení o ochraně osobních údajů",
  resetPassword: "Resetovat heslo",
  usernamePasswordRequired: "Zadejte prosím jméno uživatele a heslo.",
  passwordTooShort: "Heslo musí být minimálně 8 znaků dlouhé.",
  wrongCredentials: "Nebyl nalezen žádný účet s udanými údaji.",
  accountStructureItem: "Oddělení",
  addRole: "Připojit roli",
  allUserRelatedDeleteText:
    "Tím se všechny, pro uživatele relevantní údaje nenávratně vymažou.",
  assignRoleModals: "Přiřadit roli",
  canBeDownloadedText: "mohou být staženy pod ukončenými",
  changePassword: "Změnit heslo",
  changingPasswordFor: "Změnit heslo pro uživatele",
  confirmDeleteText: "Zadejte prosím jméno uživatele pro potvrzení:",
  confirmDeteleBtnText: "Rozumím, vymazávám uživatele",
  confirmPassword: "Opakovat heslo",
  createAccount: "Zřídit účet",
  createUser: "Zřídit uživatele",
  currentPassword: "Aktuální heslo",
  customId: "Uživatelem definované ID",
  dataPrivacyHeader: "Privátní sféra údajů",
  dataPrivacyText: "Dílčí text (obsah následuje)",
  dataPrivacyTitle: "Dílčí nadpis",
  dataRelatedToMe: "Nahlédnout na mnou uložené údaje",
  dataRelatedToMeTitle: "Uložené údaje",
  deleteText: "Jste si jisti, že to chcete vymazat",
  downloadDataRelatedToMe: "Stáhnout uložené údaje",
  fileWithUserInformation: "Soubor s uživatelskými informacemi",
  forgotPassword: "Zapomenuté heslo?",
  forgotPasswordText:
    "Zadejte prosím svou e-mailovou adresu, abyste obdrželi odkaz k resetu vašeho hesla.",
  importUsers: "Importovat uživatele",
  newHere: "Zde nový?",
  newPassowrd: "Nové heslo",
  newPasswordShort: "Nové heslo je příliš krátké",
  password: "Heslo",
  passwordCantBeEmpty: "Heslo nesmí být prázdné",
  passwordsNotMatch: "Hesla vzájemně nesouhlasí",
  pickUser: "Vyberte uživatele",
  pleaseFillUpAllFields: "Vyplňte prosím všechna pole",
  pleaseTypeNewPassword: "Zadejte prosím své nové heslo",
  policyAcceptance: "Schválení směrnic",
  policyAcceptanceSave: "Akceptovat",
  acceptPolicies: "Akceptujte prosím směrnice",
  profile: "Profil",
  role: "Role",
  roleExpiresOn: "Role zaniká dne",
  saveDataAboutMe: "mnou uložené údaje",
  signIn: "Přihlásit",
  termsConditionsHeader: "Všeobecné obchodní podmínky",
  termsConditionsText: "Dílčí text (obsah následuje)",
  termsConditionsTitle: "Dílčí nadpis",
  typeInOne: "Zadejte jeden pro všechny",
  username: "Jméno uživatele",
  firstname: "Jméno",
  lastname: "Příjmení",
  usernameMail: "Jméno uživatele nebo e-mail",
  userNotes: "Poznámky",
  userProfile: "Profil uživatele",
  userStatus: "Stav uživatele",
  wrongCurrentPassword: "Chybné aktuální heslo",
  fillAllFields: "Vyplňte prosím všechna nutná pole",
  wrongUsernameOrPassword: "Jméno uživatele nebo heslo je chybné",
  minimumLength: "minimální délka 8",
  atLeastOneBigAndSmall: "minimálně 1 malé a 1 velké písmeno",
  passwordNotEqualUsernameAndEmail:
    "heslo není identické se jménem uživatele a e-mailovou adresou",
  registrationKey: "Registrační klíč",
  registration: "Registrace",
  notAnEmailAdress: "Zadejte prosím platnou e-mailovou adresu.",
  company: "Firma",
  companyHint: "Firma",
  birthday: "Narozeniny",
  birthdayHint: "Narozeniny",
  accountName: "Název účtu",
  accountType: "Druh účtu",
  accountAddress: "Adresa majitele účtu",
  logo: "Logo",
  address: "Adresa",
  contry: "Země",
  zipCode: "PSČ",
  lessThan: "méně než 1mb",
  defaultLanguage: "Základní jazyk",
  accountStatus: "Stav účtu",
  accountNotes: "Poznámky účtu",
  createAccount: "Zřídit účet",
  updateAccount: "Aktualizovat účet",
  fileToImport: "Account notes",
  importStructure: "Importovat strukturu",
  addRootAccount: "Připojit základní účet",
  usersPerMonth: "Měsíční uživatelé",
  usersAdded: "Připojení uživatelé",
  usersDeleted: "Vymazaní uživatelé",
  usersTotal: "Uživatelé celkem",
  city: "Město",

  knowledge: "VĚDOMOSTI",
  toolbox: "TOOLBOX",
  lessonCompleted: "Lekce ukončena",
  lessonFailed: "Lekce neukončena",
  repeatLesson: "Abyste mohli pokračovat, zopakujte prosím lekci",
  congratulations: "Srdečně gratulujeme!",
  courseCompleted: "Kurz ukončen",
  percentCompleted: "Zodpověděli jste správně $successRate% otázek.",
  downloadCertificate:
    "Svůj certifikát můžete kdykoliv stáhnout v profilu pod „Certifikáty“ a vyžádat pomocí e-mailu.",
  trueFalse: "Správně nebo chybně?",
  seconds: "Vteřiny",
  noChoiceText: "Škoda, příliš pozdě.",
  right: "Správně!",
  wrong: "Nesprávné",
  progress: "$current z $total",
  addCourseContent: "Připojit obsahy kurzů",
  addSemicolon: "Připojte popisy oddělené středníky",
  addToProgram: "Připojit k programu",
  allAccounts: "Všechny účty",
  certificate: "Certifikát",
  certificates: "Certifikáty",
  completed: "Ukončeno",
  contentIsLoaded: "Obsah je nahrán",
  contentManager: "Manager obsahu",
  courseCompleted: "Kurz ukončen",
  courseContent: "Obsah kurzu",
  courseDocuments: "Podklady",
  courseLables: "Etikety kurzu",
  courseLanguage: "Jazyk kurzu",
  courseNotes: "Poznámky kurzu",
  courseStructure: "Struktura",
  courseTags: "Značky kurzu",
  courseTitle: "Název kurzu",
  createCourse: "Vytvořit kurz",
  curator: "KURÁTOR",
  description: "Popis",
  details: "Detaily",
  dueDate: "Splatnost do",
  duration: "Doba trvání",
  exportStatistics: "Exportovat statistiky",
  learningStandard: "Výukový standard",
  licenses: "Licence",
  mandatory: "Povinný kurz",
  noAssessment: "Momentálně není k prozkoumání žádné hodnocení.",
  noCoursesAssigned:
    "Vašemu účtu nejsou přiřazeny žádné kurzy. Navštivte prosím",
  noCoursesAvailable: "Momentálně nejsou k dispozici žádné kurzy.",
  noDueDate: "Žádné datum splatnosti",
  overview: "PŘEHLED",
  page: "",
  pickAnAccount: "Vyber účet",
  printCertificate: "Vytisknout certifikát",
  publishCourse: "Zveřejnit kurz",
  publishedToAccount: "Zveřejněno v účtu",
  relatedCourses: "Podobné kurzy",
  remove: "Odstranit",
  removeFromProgram: "Odstranit z programu",
  singleAccount: "Jednotlivý účet",
  startCourse: "Spustit",
  successMessageOne: "Srdečně gratulujeme, že jste úspěšně ",
  successMessageThree:
    "Nyní můžete stáhnout a vytisknout závěrečný certifikát. ",
  successMessageTwo: " ukončili kurz!",
  tags: "Hesla",
  unpublishCourse: "Zrušit zveřejnění",
  updateCourseContent: "Nahrát obsahy kurzů",
  updateCourse: "Aktualizovat kurz",
  youDidntfinishCourse: "Ještě jsi neukončil žádný kurz.",
  courseVisibleFor: "Kurz viditelný pro",
  accountStructureItem: "Oddělení",
  courseAssigmentOptions: "Možnosti přiřazení",
  assignCourse: "Přiřadit kurz",
  pickACourse: "Vyberte kurz",

  noDocumentsAvailable: "žádné podklady k dispozici",
  nothingRelated: "nic podobného k dispozici",
  nothingWritten: "nic nezaneseno",
  noTagsAvailable: "žádná hesla k dispozici",
  alreadyGlobal: "Kurz je již celosvětově zveřejněn.",
  questionsanswered: "Dotazy",
  completedOn: "Ukončeno dne",
  locale: "cz-CZ",
  quizAuthorizationContent: "Autorizace kvízu",

  courseNotPublished: "Kurz v současnosti není zveřejněn",
  courseNotPublishedForAccount:
    "Kurz v současnosti není pro účet $account zveřejněn",
  clickToPublishCourse: "Zveřejnit kurz",
  visible: "Viditelné",
  userIsNotContentManager:
    "Nejste Content Manager a nemůžete proto obsah kurzu zpracovávat.",
  assignmentOpen: "Otevřeno",
  assignmentStarted: "Spuštěno",
  assignmentCompleted: "Ukončeno",
  dueDatePassed: "Přepsat datum splatnosti",

  exitCourse: "Ukončit kurz",
  markAsFinished: "Označit jako zpracované",

  system: "Systém",
  configureLogging: "Konfigurovat protokolování",
  createAccount: "Založit účet",
  changeAccount: "Změnit účet",
  deleteAccount: "Vymazat účet",
  importAccountStructure: "Importovat strukturu účtu",
  createUser: "Zřídit uživatele",
  importUser: "Importovat uživatele",
  changeUser: "Změnit uživatele",
  deleteUser: "Vymazat uživatele",
  assignUserRole: "Přiřadit roli",
  resetPassword: "Resetovat heslo",

  createCourse: "Vytvořit kurz",
  changeCourse: "Změnit kurz",
  addQuizContent: "Připojit obsah kvízu",
  changeQuizContent: "Změnit obsah kvízu",
  addContent: "Připojit obsah kurzu",
  changeContent: "Změnit obsah kurzu",
  deleteCourse: "Vymazat kurz",
  deleteContent: "Vymazat obsah kurzu",
  publishCourse: "Zveřejnit kurz",
  unpublishCourse: "Zveřejnit kurz",
  assignCourse: "Přiřadit kurz",
  unassignCourse: "Zrušit přiřazení kurzu",

  systemMessages: "Systémové zprávy",

  learningManagement: "Výukový management",
  courseManagement: "Management kurzu",
  userManagement: "Management uživatelů",
  organizationStructure: "Organizační struktura",
  accountManagement: "Management účtů",
  system: "Systém",

  learningManagementDetail: "Výukové plány a přiřazení kurzů",
  courseManagementDetail: "Spravovat obsahy kurzu",
  userManagementDetail: "Spravovat uživatele a resetovat hesla",
  organizationStructureDetail: "Definovat organizační struktury",
  accountManagementDetail: "Spravovat účty",
  logsDetail: "Systémové přihlášení a nastavení",

  userCreateSuccess: "Uživatel úspěšně zřízen",
  createOtherUser: "Připojit dalšího uživatele",
  backToUserOverview: "Zpět k přehledu uživatelů",
  importMoreUsers: "Importovat další uživatele",
  currentRoles: "Aktuální role",
  numberOfRoles: "Počet rolí",
  numberOfUsers: "Uživatel",
  deleteAccountMessage: "Potvrďte prosím, že chcete účet '$account' vymazat.",
  showStatistics: "Zobrazit statistiky",
  appendAccount: "Připojit účet k $account.",

  courseAssignments: "Přiřazeno",
  courseAssignmentsForCourse: "Přiřazení kurzu pro $course",
  mandatoryAssignments: "Povinná přiřazení kurzů",
  dueDateAssignments: "Se lhůtou",
  dueDatePassed: "Splatný",
  userOrAccount: "Uživatel nebo oddělení",
  expiryDate: "Datum platnosti",
  deletePublication: "Vymazat zveřejnění",
  deletePublicationMessage:
    "Jste si jisti, že chcete zveřejnění pro účet $account vymazat?",
  orgStructure: "Oddělení",
  type: "Typ",
  downloadTemplate: "Stáhnout předlohu",
  published: "Zveřejněno",
  scorm: "SCORM",
  quiz: "Kvíz",
  staticFile: "Pdf",
  editPublication: "Zpracovat zveřejnění",
  pleaseDefineCourseContent:
    "please define the course content before publishing it.",

  usernameMissing: "Chybí jméno uživatele",
  usernameError:
    "Jméno uživatele se může skládat pouze z alfanumerických znaků",
  emailMissing: "Chybí e-mail",
  emailError: "Žádná platná e-mailová adresa",
  passwordMissing: "Chybí heslo",
  passwordError:
    "Heslo musí být minimálně 8 znaků dlouhé a obsahovat jedno velké a jedno malé písmeno.",

  registrationKeyManagement: "Registrační klíč",
  registrationKeyManagementDetail: "Správa registračních klíčů",
  addRegistrationKey: "Připojit registrační klíč",
  registrationLink: "Registrační odkaz",
  createKey: "Připojit",

  userState: "Stav uživatele",
  userStateDetail: "Stav povolení přístupu a povinné kurzy.",
  testsPassed: "Testy prošly",
  access: "Přístup",
  downloadReport: "Stáhnout zprávu",
  success: "Úspěšně uloženo!",
  warning: "Opatrně!",
  error: "Chyba!",
  removedFromProgram: "Tento kurz byl z vašeho programu odstraněn",
  addedToProgram: "Tento kurz byl do vašeho programu připojen",
  courseCreated: "Kurz byl založen",
  courseNotCreated: "Kurz nebyl založen",
  fillUpFields: "Vyplňte prosím všechna povinná pole",
  cookieNotAvailable:
    "Vaše login informace není k dispozici nebo je příliš stará, přihlaste se prosím znovu",
  courseNotFinished: "Pro kurz není k dispozici žádná závěrečná informace",
  courseAborted: "Kurz byl přerušen",
  correctAnswer: "Správná odpověď",
  wrongAnswer: "Nesprávná odpověď",
  courseStarted: "Kurz byl spuštěn",
  testsPassed: "Test složen",
  testFailed: "Test nesložen",
  courseCompleted: "Kurz byl úspěšně ukončen",
  imageTooLarge: "Soubor musí být velký méně než 2mb",
  wrongFormat: "Nesprávný formát",
  passwordReset:
    "Odkaz pro reset hesla byl odeslán na udanou e-mailovou adresu",
  passwordChanged: "Heslo bylo změněno",
  accountAdded: "Účet byl připojen",
  accountUpdated: "Účet byl aktualizován",
  accountDeleted: "Účet byl vymazán",
  userImported: "Uživatelé byli importováni",
  userNotImported: "Uživatelé nebyli importováni",
  loggingConfigSaved: "Záznam konfigurace byl uložen",
  userDeleted: "Uživatel byl vymazán",
  userAdded: "Uživatel byl založen",
  userUpdated: "Uživatel byl aktualizován",
  GDPRLoaded: "GDPR je nahráno",
  cookiesDisabled: "Deaktivovali jste cookies",
  wrongCredentials: "Nesprávné přihlašovací údaje",
  fileUploaded: "Váš soubor byl nahrán",
  courseContentUpdated: "Obsah kurzu byl aktualizován",
  coursePublished: "Kurz byl zveřejněn",
  courseUnPublished: "Zveřejnění kurzu bylo zrušeno.",
  serverUnreachable: "Server není dosažitelný",
  selectAccounts: "Vybrat účet",
  selectAccountToContinue: "Vybrat účet pro pokračování",
  accountOverview: "Přehled účtu",
  users: "Uživatel",
  courses: "Kurzy",
  coursesCompleted: "Ukončené kurzy",
  completedCoursesPerUser: "Ukončené kurzy na uživatele",
  learningHours: "Výukové hodiny",
  learningHoursPerUser: "Výukové hodiny na uživatele",
  courseOverview: "Stručný přehled",
  completed: "Ukončeno",
  assigned: "Přiřazeno",
  courseDetails: "Detaily",
  selectCourse: "Vybrat kurz",
  details: "Vybrat",
  type: "Typ",
  dueDate: "Do",
  mandatory: "Povinný kurz",
  completionRate: "Kvóta ukončení",
  averageScore: "Výsledek testu",
  duration: "Doba trvání",
  averageDuration: "Průměrná doba",
  account: "Účet",
  accountName: "Název účtu",
  accounts: "Účty",
  active: "Aktivní",
  admin: "Admin",
  all: "Všechny",
  not_found: "Stránka neexistuje",
  assessmentStatistics: "Vyhodnocovací statistiky",
  assignedUsersOnly: "Pouze přiřazení uživatelé",
  autoIndividual: "Automaticky, individuálně",
  cancel: "Přerušit",
  cardImage: "Zobrazení karty",
  close: "Zavřít",
  completed: "Ukončeno",
  confirm: "Potvrdit",
  country: "Země",
  course: "Kurzy",
  courseDescription: "Stručný popis",
  courseDocuments: "Podklady kurzu",
  courses: "Knihovna",
  courseStructure: "Struktura kurzu",
  dataProtection: "Ochrana údajů",
  defaultLanguage: "Standardní jazyk",
  deleteText: "Jste si jisti, že to chcete vymazat",
  delete: "Vymazat",
  details: "Detaily",
  dragAndDrop: "Drag & Drop / Kliknout pro připojení",
  dueDate: "Splatnost dne",
  email: "E-mail",
  empty: "Prázdný",
  exit: "Odhlásit",
  expiresOn: "Propadá dne",
  fileImport: "Importovaný soubor",
  image: "Obrazovka",
  inactive: "Neaktivní",
  language: "Jazyk",
  logo: "Logo",
  mandatory: "Povinný kurz",
  monthsLabels: [
    "Leden",
    "Únor",
    "Březen",
    "Duben",
    "Květen",
    "Červen",
    "Červenec",
    "Srpen",
    "Září",
    "Říjen",
    "Listopad",
    "Prosinec",
  ],
  myAccount: "Můj účet",
  myProfile: "Můj profil",
  myProgram: "Můj program",
  myRoles: "Moje role",
  never: "Nikdy",
  no: "Ne",
  none: "Žádný",
  notFound: "Nenalezeno",
  phoneNumber: "Telefonní číslo",
  playImage: "Zobrazit obrazovku",
  publishingExpiresOn: "Zveřejnění zaniká dne",
  publishTo: "Zveřejnění v",
  reports: "Zpráva",
  save: "Uložit",
  search: "Hledat…",
  status: "Stav",
  structureItem: "Oddělení",
  termsAndConditions: "Obchodní podmínky",
  unlimited: "Neomezeno",
  update: "Aktualizovat",
  user: "Uživatel",
  username: "uživatelské jméno",
  users: "uživatelé",
  yes: "Ano",
  zipCode: "PSČ",

  info: "Info",
  error: "Chyba",
  alert: "Alarm",
  criticalAlert: "Kritický alarm",
  navigation: "Navigace",
  system: "Systém",
  note: "Odkaz",
  userProfile: "Profil uživatele",

  userId: "ID uživatele",
  customId: "",
  organizationalStructure: "Organizační struktura",
  created: "Vyhotoveno dne",
  new: "Nový",
  noResults: "Žádné výsledky",
  noData: "Žádné údaje",
  uploadFile: "Nahrát soubor",
  id: "ID",
  name: "Jméno",
  append: "Připojit",
  edit: "Zpracovat",
  format: "Formát",
  minutes: "Minuty",
  hours: "Hodiny",
  date: "Datum",
  message: "Zpráva",
  fax: "Fax",

  genericError:
    "Došlo k chybě, pokuste se stránku znovu stáhnout, pokud chyba potrvá, kontaktujte administrátora systému.",
  action: "Akce",

  uploadedCertificates: "Nahrané certifikáty",

  add_mandatory_user_property:
    "Vyberte pro svou práci následující charakteristiky",
  help: "Pomoc",
  alreadyRegistered: "Již zaregistrované",
  additionalDetails: "Dodatečné informace",
  additionalDetailsSave: "Potvrdit",
  dragAndDropCertificate: "Přetáhněte sem soubor PDF nebo klikněte pro přidání",
  coverPhoto: "Cover Photo",
  EditCoverPhoto: "Edit Cover Photo",
  AddCoverPhoto: "Add Cover Photo",
  additionalDocuments: "Additional Documents",
  editAdditionalDocuments: "Edit Additional Documents",
  addAdditionalDocuments: "Add Additional Documents",
  fileName: "File name",
  fileSize: "File size",
  userProfileV2: "User profile V2",
  certification: "Certification",
  issuedBy: "Issued by",
  issuedOn: "Issued on",
  fulfilledInPercent: "Fulfilled in percent",
  systemLogs: "System Logs",
  systemConfigurationDetail: "Configure system settings",
  systemConfiguration: "System configuration",
  courseFormat: "Course formats",
  views: "Views",
  apis: "APIs",
  logs: "Logs",
  viewsDescription: "Visibility of the element in the respective view",
  courseFormatDescription: "Available course formats for defining the course",
  accessCard: "Access card",
  downloadCertification: "Download Certification",
  logout: "Odhlásit se",
  continue: "Pokračovat",
  courseFinishedDoYouWantToLogout:
    "Kurz byl dokončen. Chcete se odhlásit nebo pokračovat? Budete automaticky odhlášeni za {seconds}.",
  stateless: "bez státní příslušnosti",
};
