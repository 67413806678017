export default {
  termsAccept: "Dajem svoju suglasnost za: {0}.",
  tos: "Opći uvjeti poslovanja",
  privacyAccept: "Dajem svoju suglasnost za: {0}.",
  privacyPolicy: "Izjava o zaštiti podataka",
  resetPassword: "Resetiraj lozinku",
  usernamePasswordRequired: "Unesite korisničko ime i lozinku.",
  passwordTooShort: "Lozinka mora sadržavati najmanje 8 znakova.",
  wrongCredentials: "Nije pronađen račun s navedenim podatcima.",
  accountStructureItem: "Odjel",
  addRole: "Dodaj ulogu",
  allUserRelatedDeleteText:
    "Ovime se neopozivo brišu svi podatci važni za korisnike.",
  assignRoleModals: "Dodijeli ulogu",
  canBeDownloadedText: "mogu se preuzeti iz dovršenih",
  changePassword: "Promijeni lozinku",
  changingPasswordFor: "Promijeni lozinku za korisnika",
  confirmDeleteText: "Unesite korisničko ime za potvrdu:",
  confirmDeteleBtnText: "Shvatio/la sam, izbriši korisnika",
  confirmPassword: "Ponovite lozinku",
  createAccount: "Kreiraj račun",
  createUser: "Kreiraj korisnika",
  currentPassword: "Aktualna lozinka",
  customId: "ID koji je kreirao korisnik",
  dataPrivacyHeader: "Područje privatnosti podataka",
  dataPrivacyText: "Tekst odlomka (sadržaj slijedi)",
  dataPrivacyTitle: "Naslov odlomka",
  dataRelatedToMe: "Prikaži podatke spremljene o meni",
  dataRelatedToMeTitle: "Spremljeni podatci",
  deleteText: "Jeste li sigurni da to želite izbrisati",
  downloadDataRelatedToMe: "Preuzmi spremljene podatke",
  fileWithUserInformation: "Datoteka s podatcima o korisniku",
  forgotPassword: "Zaboravljena lozinka?",
  forgotPasswordText:
    "Unesite svoju adresu e-pošte da vam pošaljemo poveznicu za resetiranje lozinke.",
  importUsers: "Uvezi korisnika",
  newHere: "Prvi ste put ovdje?",
  newPassowrd: "Nova lozinka",
  newPasswordShort: "Nova je lozinka prekratka",
  password: "Lozinka",
  passwordCantBeEmpty: "Lozinka ne smije biti prazna",
  passwordsNotMatch: "Lozinke se ne podudaraju",
  pickUser: "Odaberite korisnika",
  pleaseFillUpAllFields: "Popunite sva polja",
  pleaseTypeNewPassword: "Unesite novu lozinku",
  policyAcceptance: "Prihvaćanje pravila",
  policyAcceptanceSave: "Prihvati",
  acceptPolicies: "Prihvatite pravila",
  profile: "Profil",
  role: "Uloga",
  roleExpiresOn: "Uloga istječe",
  saveDataAboutMe: "podatci spremljeni o meni",
  signIn: "Prijava",
  termsConditionsHeader: "Opći uvjeti poslovanja",
  termsConditionsText: "Tekst odlomka (sadržaj slijedi)",
  termsConditionsTitle: "Naslov odlomka",
  typeInOne: "Unesite jedan za sve",
  username: "Korisničko ime",
  firstname: "Ime",
  lastname: "Prezime",
  usernameMail: "Korisničko ime ili adresa e-pošte",
  userNotes: "Bilješke",
  userProfile: "Profil korisnika",
  userStatus: "Status korisnika",
  wrongCurrentPassword: "Pogrešna aktualna lozinka",
  fillAllFields: "Popunite sva obvezna polja",
  wrongUsernameOrPassword: "Pogrešno korisničko ime ili lozinka",
  minimumLength: "najmanja duljina 8 znakova",
  atLeastOneBigAndSmall: "najmanje 1 malo i 1 veliko slovo",
  passwordNotEqualUsernameAndEmail:
    "lozinka se ne podudara s korisničkim imenom i adresom e-pošte",
  registrationKey: "Šifra za registraciju",
  registration: "Registracija",
  notAnEmailAdress: "Unesite valjanu adresu e-pošte.",
  company: "Društvo",
  companyHint: "Društvo",
  birthday: "Rođendan",
  birthdayHint: "Rođendan",

  accountName: "Naziv računa",
  accountType: "Vrsta računa",
  accountAddress: "Adresa vlasnika računa",
  logo: "Logotip",
  address: "Adresa",
  contry: "Država",
  zipCode: "Poštanski broj",
  lessThan: "manje od 1 MB",
  defaultLanguage: "Zadani jezik",
  accountStatus: "Status računa",
  accountNotes: "Bilješke o računu",
  createAccount: "Kreiraj račun",
  updateAccount: "Ažuriraj račun",
  fileToImport: "Bilješke o računu",
  importStructure: "Uvezi strukturu",
  addRootAccount: "Dodaj račun osnovne razine",
  usersPerMonth: "Mjesečni broj korisnika",
  usersAdded: "Dodani korisnici",
  usersDeleted: "Izbrisani korisnici",
  usersTotal: "Ukupno korisnika",
  city: "Grad",

  knowledge: "ZNANJE",
  toolbox: "KUTIJA ALATA",
  lessonCompleted: "Lekcija je dovršena",
  lessonFailed: "Lekcija nije dovršena",
  repeatLesson: "Ponovite lekciju za nastavak",
  congratulations: "Čestitamo!",
  courseCompleted: "Tečaj je dovršen",
  percentCompleted: "Točno ste odgovorili na $successRate % pitanja.",
  downloadCertificate:
    "Potvrdu o dovršenom tečaju u svakom trenutku možete preuzeti u odjeljku „Potvrde” i zatražiti da vam se pošalje putem e-pošte.",
  trueFalse: "Točno ili pogrešno?",
  seconds: "Sekunde",
  noChoiceText: "Nažalost, prekasno.",
  right: "Točno!",
  wrong: "Netočno",
  progress: "$current od $total",

  addCourseContent: "Dodaj sadržaje tečajeva",
  addSemicolon: "Dodajte oznake odvojene točkom sa zarezom",
  addToProgram: "Dodaj u program",
  allAccounts: "Svi računi",
  certificate: "Potvrda",
  certificates: "Potvrde",
  completed: "Dovršeno",
  contentIsLoaded: "Sadržaj je učitan",
  contentManager: "Upravitelj sadržaja",
  courseCompleted: "Tečaj je dovršen",
  courseContent: "Sadržaj tečaja",
  courseDocuments: "Materijali",
  courseLables: "Oznake tečaja",
  courseLanguage: "Jezik tečaja",
  courseNotes: "Bilješke o tečaju",
  courseStructure: "Struktura",
  courseTags: "Oznake tečaja",
  courseTitle: "Naziv tečaja",
  createCourse: "Kreiraj tečaj",
  curator: "KURATOR",
  description: "Opis",
  details: "Pojedinosti",
  dueDate: "Rok do",
  duration: "Trajanje",
  exportStatistics: "Izvezi statistike",
  learningStandard: "Standard učenja",
  licenses: "Licence",
  mandatory: "Obvezan tečaj",
  noAssessment: "Trenutačno nema ocjene za analizu.",
  noCoursesAssigned: "Vašem računu nije dodijeljen nijedan tečaj. Idite na",
  noCoursesAvailable: "Trenutačno nema dostupnih tečajeva.",
  noDueDate: "Nema datuma roka",
  overview: "PREGLED",
  page: "",
  pickAnAccount: "Odaberite račun",
  printCertificate: "Ispiši potvrdu",
  publishCourse: "Objavi tečaj",
  publishedToAccount: "Objavljeno na računu",
  relatedCourses: "Slični tečajevi",
  remove: "Ukloni",
  removeFromProgram: "Ukloni iz programa",
  singleAccount: "Pojedinačni račun",
  startCourse: "Kreni",
  successMessageOne: "Čestitamo, uspješno ste ",
  successMessageThree:
    "Sada možete preuzeti ili ispisati potvrdu o dovršenom tečaju. ",
  successMessageTwo: " dovršili tečaj!",
  tags: "Ključne riječi",
  unpublishCourse: "Poništi objavu",
  updateCourseContent: "Prenesi sadržaje tečajeva",
  updateCourse: "Ažuriraj tečaj",
  youDidntfinishCourse: "Još niste dovršili nijedan tečaj.",
  courseVisibleFor: "Tečaj je vidljiv za",
  accountStructureItem: "Odjel",
  courseAssigmentOptions: "Opcije dodjele",
  assignCourse: "Dodijeli tečaj",
  pickACourse: "Odaberite tečaj",

  noDocumentsAvailable: "nema materijala",
  nothingRelated: "nema ništa slično",
  nothingWritten: "ništa nije uneseno",
  noTagsAvailable: "nema ključnih riječi",
  alreadyGlobal: "Tečaj je već objavljen diljem svijeta.",
  questionsanswered: "Pitanja",
  completedOn: "Dovršeno dana",
  locale: "hr-HR",
  quizAuthorizationContent: "Autorizacija provjere znanja",

  courseNotPublished: "Tečaj trenutačno nije objavljen",
  courseNotPublishedForAccount:
    "Tečaj trenutačno nije objavljen za račun $account",
  clickToPublishCourse: "Objavi tečaj",
  visible: "Vidljivo",
  userIsNotContentManager:
    "Vi niste upravitelj sadržaja i stoga ne možete uređivati sadržaj tečaja.",
  assignmentOpen: "Otvoreno",
  assignmentStarted: "Započeto",
  assignmentCompleted: "Dovršeno",
  dueDatePassed: "Prekoračen rok",

  exitCourse: "Završi tečaj",
  markAsFinished: "Označi kao uređeno",

  system: "Sustav",
  configureLogging: "Konfiguriraj zapisivanje",
  createAccount: "Kreiraj račun",
  changeAccount: "Izmijeni račun",
  deleteAccount: "Izbriši račun",
  importAccountStructure: "Uvezi strukturu računa",
  createUser: "Kreiraj korisnika",
  importUser: "Uvezi korisnika",
  changeUser: "Promijeni korisnika",
  deleteUser: "Izbriši korisnika",
  assignUserRole: "Dodijeli ulogu",
  resetPassword: "Resetiraj lozinku",

  createCourse: "Kreiraj tečaj",
  changeCourse: "Izmijeni tečaj",
  addQuizContent: "Dodaj sadržaj provjere znanja",
  changeQuizContent: "Promijeni sadržaj provjere znanja",
  addContent: "Dodaj sadržaj tečaja",
  changeContent: "Promijeni sadržaj tečaja",
  deleteCourse: "Izbriši tečaj",
  deleteContent: "Izbriši sadržaj tečaja",
  publishCourse: "Objavi tečaj",
  unpublishCourse: "Opozovi objavu",
  assignCourse: "Dodijeli tečaj",
  unassignCourse: "Poništi dodjelu tečaja",

  systemMessages: "Poruke sustava",

  learningManagement: "Upravljanje učenjem",
  courseManagement: "Upravljanje tečajem",
  userManagement: "Upravljanje korisnicima",
  organizationStructure: "Organizacijska struktura",
  accountManagement: "Upravljanje računima",
  system: "Sustav",

  learningManagementDetail: "Planovi učenja i dodjela tečajeva",
  courseManagementDetail: "Upravljanje sadržajem tečajeva",
  userManagementDetail: "Upravljanje korisnicima i resetiranje lozinki",
  organizationStructureDetail: "Definiranje organizacijske strukture",
  accountManagementDetail: "Upravljanje računima",
  logsDetail: "Zapisnici sustava i postavke",

  userCreateSuccess: "Korisnik je uspješno kreiran",
  createOtherUser: "Dodaj još jednog korisnika",
  backToUserOverview: "Povratak na pregled korisnika",
  importMoreUsers: "Uvezi još korisnika",
  currentRoles: "Aktualne uloge",
  numberOfRoles: "Broj uloga",
  numberOfUsers: "Korisnik",
  deleteAccountMessage: "Potvrdite da želite izbrisati račun '$account'.",
  showStatistics: "Prikaži statistike",
  appendAccount: "Račun dodaj računu $account.",

  courseAssignments: "Dodijeljeno",
  courseAssignmentsForCourse: "Dodjele tečaja za $course",
  mandatoryAssignments: "Dodjele obveznih tečaja",
  dueDateAssignments: "S rokom",
  dueDatePassed: "Rok",
  userOrAccount: "Korisnik ili odjel",
  expiryDate: "Datum isteka",
  deletePublication: "Izbriši objavu",
  deletePublicationMessage:
    "Jeste li sigurni da želite izbrisati objavu za račun $account?",
  orgStructure: "Odjel",
  type: "Tip",
  downloadTemplate: "Preuzmi predložak",
  published: "Objavljeno",
  scorm: "SCORM",
  quiz: "Provjera znanja",
  staticFile: "Pdf",
  editPublication: "Uredi objavu",
  pleaseDefineCourseContent:
    "please define the course content before publishing it.",

  usernameMissing: "Nedostaje korisničko ime",
  usernameError:
    "Korisničko ime može se sastojati samo od alfanumeričkih znakova",
  emailMissing: "Nedostaje adresa e-pošte",
  emailError: "Adresa e-pošte nije valjana",
  passwordMissing: "Nedostaje lozinka",
  passwordError:
    "Lozinka se mora sastojati od najmanje 8 znakova te sadržavati najmanje jedno veliko i malo slovo.",

  registrationKeyManagement: "Šifra za registraciju",
  registrationKeyManagementDetail: "Upravljanje šiframa za registraciju",
  addRegistrationKey: "Dodaj šifru za registraciju",
  registrationLink: "Poveznica za registraciju",
  createKey: "Dodaj",

  userState: "Država korisnika",
  userStateDetail: "Državna dozvola i obvezni tečajevi.",
  testsPassed: "Prošli testovi",
  access: "Pristup",
  downloadReport: "Preuzimanje izvješća",
  success: "Uspješno spremljeno!",
  warning: "Pažnja!",
  error: "Pogreška!",
  removedFromProgram: "Ovaj je tečaj uklonjen iz vašeg programa",
  addedToProgram: "Ovaj je tečaj dodan u vaš program",
  courseCreated: "Tečaj je kreiran",
  courseNotCreated: "Tečaj nije kreiran",
  fillUpFields: "Popunite sva obvezna polja",
  cookieNotAvailable:
    "Nema podataka za prijavu ili su zastarjeli, prijavite se ponovno",
  courseNotFinished: "Za tečaj nema podataka o dovršavanju",
  courseAborted: "Tečaj je prekinut",
  correctAnswer: "Točan odgovor",
  wrongAnswer: "Pogrešan odgovor",
  courseStarted: "Tečaj je započet",
  testsPassed: "Ispit je položen",
  testFailed: "Ispit nije položen",
  courseCompleted: "Tečaj je uspješno dovršen",
  imageTooLarge: "Datoteka mora biti manja od 2 MB",
  wrongFormat: "Pogrešan format",
  passwordReset:
    "Na navedenu adresu e-pošte poslana vam je poveznica za promjenu lozinke",
  passwordChanged: "Lozinka je promijenjena",
  accountAdded: "Račun je dodan",
  accountUpdated: "Račun je ažuriran",
  accountDeleted: "Račun je izbrisan",
  userImported: "Korisnici su uvezeni",
  userNotImported: "Korisnici nisu uvezeni",
  loggingConfigSaved: "Konfiguracija zapisivanja je spremljena",
  userDeleted: "Korisnik je izbrisan",
  userAdded: "Korisnik je kreiran",
  userUpdated: "Korisnik je ažuriran",
  GDPRLoaded: "OUZP je učitan",
  cookiesDisabled: "Deaktivirali ste kolačiće",
  wrongCredentials: "Pogrešni podatci za prijavu",
  fileUploaded: "Vaša je datoteka prenesena",
  courseContentUpdated: "Sadržaj tečaja je ažuriran",
  coursePublished: "Tečaj je objavljen",
  courseUnPublished: "Objavljivanje tečaja je poništeno.",
  serverUnreachable: "Poslužitelj nije dostupan",
  selectAccounts: "Odabir računa",
  selectAccountToContinue: "Za nastavak odaberite račun",
  accountOverview: "Pregled računa",
  users: "Korisnik",
  courses: "Tečajevi",
  coursesCompleted: "Dovršeni tečajevi",
  completedCoursesPerUser: "Dovršeni tečajevi po korisniku",
  learningHours: "Sati učenja",
  learningHoursPerUser: "Sati učenja po korisniku",
  courseOverview: "Pregled tečaja",
  completed: "Dovršeno",
  assigned: "Dodijeljeno",
  courseDetails: "Pojedinosti",
  selectCourse: "Odaberi tečaj",
  details: "Odaberi",
  type: "Tip",
  dueDate: "Do",
  mandatory: "Obvezan tečaj",
  completionRate: "Stopa dovršavanja",
  averageScore: "Rezultat ispita",
  duration: "Trajanje",
  averageDuration: "Prosječno trajanje",
  account: "Račun",
  accountName: "Naziv računa",
  accounts: "Računi",
  active: "Aktivno",
  admin: "Administrator",
  all: "Svi",
  not_found: "Ova stranica ne postoji",
  assessmentStatistics: "Statistike vrednovanja",
  assignedUsersOnly: "Samo autorizirani korisnici",
  autoIndividual: "Automatski, individualno",
  cancel: "Prekid",
  cardImage: "Prikaz karte",
  close: "Zatvori",
  completed: "Dovršeno",
  confirm: "Potvrdi",
  country: "Država",
  course: "Tečajevi",
  courseDescription: "Opis tečaja",
  courseDocuments: "Materijali za tečaj",
  courses: "Knjižnica",
  courseStructure: "Struktura tečaja",
  dataProtection: "Zaštita podataka",
  defaultLanguage: "Zadani jezik",
  deleteText: "Jeste li sigurni da to želite izbrisati",
  delete: "Izbriši",
  details: "Pojedinosti",
  dragAndDrop: "Povlačenje i ispuštanje / Klikni za dodavanje",
  dueDate: "Rok do",
  email: "E-pošta",
  empty: "Prazno",
  exit: "Odjava",
  expiresOn: "Istječe dana",
  fileImport: "Datoteka za uvoz",
  image: "Slika",
  inactive: "Neaktivno",
  language: "Jezik",
  logo: "Logotip",
  mandatory: "Obvezan tečaj",
  monthsLabels: [
    "Siječanvj",
    "Veljača",
    "Ožujak",
    "Travanj",
    "Svibanj",
    "Lipanj",
    "Srpanj",
    "Kolovoz",
    "Rujan",
    "Listopad",
    "Studeni",
    "Prosinac",
  ],
  myAccount: "Moj račun",
  myProfile: "Moj profil",
  myProgram: "Moj program",
  myRoles: "Moje uloge",
  never: "Nikada",
  no: "Ne",
  none: "Nema",
  notFound: "Nije pronađeno",
  phoneNumber: "Telefonski broj",
  playImage: "Prikaži sliku",
  publishingExpiresOn: "Objava istječe dana",
  publishTo: "Objavi u",
  reports: "Izvješće",
  save: "Spremi",
  search: "Pretraživanje…",
  status: "Status",
  structureItem: "Odjel",
  termsAndConditions: "Uvjeti poslovanja",
  unlimited: "Neograničeno",
  update: "Ažuriraj",
  user: "Korisnik",
  username: "Korisničko ime",
  users: "Korisnici",
  yes: "Da",
  zipCode: "Poštanski broj",

  info: "Informacije",
  error: "Pogreška",
  alert: "Upozorenje",
  criticalAlert: "Kritično upozorenje",
  navigation: "Navigacija",
  system: "Sustav",
  note: "Napomena",
  userProfile: "Profil korisnika",

  userId: "ID korisnika",
  customId: "",
  organizationalStructure: "Organizacijska struktura",
  created: "Kreirano dana",
  new: "Novo",
  noResults: "Nema rezultata",
  noData: "Nema podataka",
  uploadFile: "Prenesi datoteku",
  id: "ID",
  name: "Naziv",
  append: "Dodaj",
  edit: "Uredi",
  format: "Format",
  minutes: "Minuta",
  hours: "Sati",
  date: "Datum",
  message: "Poruka",
  fax: "Faks",

  genericError:
    "Došlo je do pogreške, pokušajte ponovno učitati stranicu. Ako se pogreška nastavi pojavljivati, obratite se administratoru sustava.",
  action: "Akcija",

  uploadedCertificates: "Učitani certifikati",

  add_mandatory_user_property:
    "Molimo odaberite sljedeće karakteristike za svoj posao",
  help: "Pomozite",
  alreadyRegistered: "Već registrirani",
  additionalDetails: "Dodatne informacije",
  additionalDetailsSave: "Potvrditi",
  dragAndDropCertificate:
    "Povucite i ispustite PDF datoteku ovdje ili kliknite za dodavanje",
  coverPhoto: "Cover Photo",
  EditCoverPhoto: "Edit Cover Photo",
  AddCoverPhoto: "Add Cover Photo",
  additionalDocuments: "Additional Documents",
  editAdditionalDocuments: "Edit Additional Documents",
  addAdditionalDocuments: "Add Additional Documents",
  fileName: "File name",
  fileSize: "File size",
  userProfileV2: "User profile V2",
  certification: "Certification",
  issuedBy: "Issued by",
  issuedOn: "Issued on",
  fulfilledInPercent: "Fulfilled in percent",
  systemLogs: "System Logs",
  systemConfigurationDetail: "Configure system settings",
  systemConfiguration: "System configuration",
  courseFormat: "Course formats",
  views: "Views",
  apis: "APIs",
  logs: "Logs",
  viewsDescription: "Visibility of the element in the respective view",
  courseFormatDescription: "Available course formats for defining the course",
  accessCard: "Access card",
  downloadCertification: "Download Certification",

  logout: "Odjava",
  continue: "Nastavi",
  courseFinishedDoYouWantToLogout:
    "Tečaj je završen. Želite li se odjaviti ili nastaviti? Automatski ćete biti odjavljeni za {seconds}.",
  stateless: "bez državljanstva",
};
