export default class LoginRedirectsDb {
  constructor() {
    this.version = 1;
    this.dbName = "elearning-login-redirects";
    this.storeName = "LoginRedirects";
  }

  async openDB() {
    return new Promise((resolve, reject) => {
      if (!window.indexedDB) {
        reject({ message: "Unsupported indexedDB" });
      }

      let request = window.indexedDB.open(this.dbName, this.version);

      request.onsuccess = () => {
        this.db = request.result;
        resolve();
      };

      request.onerror = (error) => reject(error);

      request.onupgradeneeded = (e) => {
        this.db = e.target.result;
        this.db.onabort = (error) => reject(error);
        this.db.error = (error) => reject(error);

        this.db.createObjectStore(this.storeName, { keyPath: "id" });
      };
    });
  }

  async insert(data) {
    return new Promise((resolve, reject) => {
      if (this.db && data) {
        let transaction = this.db.transaction([this.storeName], "readwrite");

        transaction.onabort = (e) => reject(e);
        transaction.onerror = (e) => reject(e);

        let request = transaction.objectStore(this.storeName).clear();

        request = transaction.objectStore(this.storeName).put(data);

        request.onerror = (e) => reject(e);
        request.onsuccess = (e) => resolve(e.target.result);
      } else {
        if (!this.db) {
          reject(new Error("Open database first"));
        }
      }
    });
  }

  async getAll() {
    return new Promise((resolve, reject) => {
      if (this.db) {
        let request = this.db
          .transaction(this.storeName)
          .objectStore(this.storeName)
          .openCursor(null, IDBCursor.NEXT);
        let results = [];
        request.onsuccess = (e) => {
          let cursor = e.target.result;
          if (cursor) {
            results.push(cursor.value);
            cursor.continue();
          } else {
            resolve(results);
          }
        };
        request.onerror = (e) => reject(e.target.error);
      }
    });
  }

  async remove(key) {
    return new Promise((resolve, reject) => {
      if (this.db) {
        let request = this.db
          .transaction([this.storeName], "readwrite")
          .objectStore(this.storeName)
          .delete(key);
        request.onerror = (e) => reject(e);
        request.onsuccess = (e) => resolve(e);
      } else {
        reject(new Error("Open database first"));
      }
    });
  }
}
