import en from "./en";
import de from "./de";
import ru from "./ru";
import bg from "./bg";
import cs from "./cs";
import el from "./el";
import hr from "./hr";
import hu from "./hu";
import it from "./it";
import lv from "./lv";
import pl from "./pl";
import ro from "./ro";
import es from "./es";
import tr from "./tr";
import pt from "./pt";
import sk from "./sk";
import fr from "./fr";
import sl from "./sl";
import bs from "./bs";
import sr from "./sr";
import nl from "./nl";
import et from "./et";
import ar from "./ar";

import { getSetting } from "../getSettings";

export default mergeLanguages(
  {
    en,
    de,
    ru,
    sk,
    fr,
    sl,
    sr,
    bg,
    cs,
    el,
    hr,
    hu,
    it,
    lv,
    pl,
    pt,
    ro,
    es,
    tr,
    bs,
    et,
    nl,
    ar,
  },
  getSetting("languageOverrides"),
);

export function mergeLanguages(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeLanguages(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeLanguages(target, ...sources);
}

export function isObject(item) {
  return item && typeof item === "object";
}

/**
 * NOTE: FLAG and KEY might differ for languages.
 * FLAG is https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 country code
 * KEY is https://en.wikipedia.org/wiki/ISO_639-1 language code
 * when adding a language, properly check both codes
 */
const availableLanguages = [
  {
    key: "de",
    flag: "de",
    value: "Deutsch",
  },
  {
    key: "en",
    flag: "gb",
    value: "English",
  },
  {
    key: "ru",
    flag: "ru",
    value: "русский",
  },
  {
    key: "bg",
    flag: "bg",
    value: "български",
  },
  {
    key: "cs",
    flag: "cz",
    value: "čeština",
  },
  {
    key: "el",
    flag: "gr",
    value: "Ελληνικά",
  },
  {
    key: "es",
    flag: "es",
    value: "Español",
  },
  {
    key: "hr",
    flag: "hr",
    value: "hrvatski",
  },
  {
    key: "hu",
    flag: "hu",
    value: "magyar",
  },
  {
    key: "it",
    flag: "it",
    value: "italiano",
  },
  {
    key: "lv",
    flag: "lv",
    value: "latviešu valoda",
  },
  {
    key: "sl",
    flag: "si",
    value: "Slovenščina",
  },
  {
    key: "pl",
    flag: "pl",
    value: "język plski",
  },
  {
    key: "pt",
    flag: "pt",
    value: "Português",
  },
  {
    key: "ro",
    flag: "ro",
    value: "Română",
  },
  {
    key: "tr",
    flag: "tr",
    value: "Türkçe",
  },
  {
    key: "fr",
    flag: "fr",
    value: "Français",
  },
  {
    key: "sk",
    flag: "sk",
    value: "Slovenčina",
  },
  {
    key: "bs",
    flag: "ba",
    value: "Bosanski",
  },
  {
    key: "sr",
    flag: "rs",
    value: "Srpski",
  },
  {
    key: "ba",
    flag: "ru",
    value: "Bashkir",
  },
  {
    key: "et",
    flag: "ee",
    value: "Eesti",
  },
  {
    key: "nl",
    flag: "nl",
    value: "Nederlands",
  },
  {
    key: "ar",
    flag: "sa",
    value: "Arabe",
  },
];

export const languages =
  getSetting("supportedLanguages") === undefined
    ? availableLanguages
    : getSetting("supportedLanguages").map((key) =>
        availableLanguages.find((l) => l.key === key),
      );
