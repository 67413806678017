import services from "../services/reportingServices";
import {
  stateInitial,
  stateLoading,
  stateLoaded,
  stateError,
} from "./loadingStates";
import { mapResponse, printError } from "../utils/mapResponse";
import dayjs from "dayjs";

const arrToObj = (results) =>
  results.reduce(
    (comb, { courseId, data }) => ({
      ...comb,
      [`${courseId}`]: data,
    }),
    {},
  );

export default {
  namespaced: true,

  state: {
    stats: {},
    result: {},
    executionTimes: {},
    completionLevels: {},
    completedWeeks: {},
    completedMonths: {},

    // pickedAccountId: null,
    // pickedCourse: null,

    loadingState: stateInitial,
  },

  mutations: {
    setStats(state, payload) {
      state.stats = payload;
    },
    setExecutionTimes(state, payload) {
      state.executionTimes = arrToObj(payload.results);
    },
    setCompletionLevels(state, payload) {
      state.completionLevels = arrToObj(payload.results);
    },
    setCompletedWeeks(state, payload) {
      state.completedWeeks = arrToObj(payload.results);
    },
    setCompletedMonths(state, payload) {
      state.completedMonths = arrToObj(payload.results);
    },

    combineResult(state) {
      state.result = {
        ...state.stats,
        publishedCourses: state.stats.publishedCourses.map((c) => ({
          ...c,
          charts: {
            completedLastWeeks: state.completedWeeks[c.courseId],
            completedLastMonths: state.completedMonths[c.courseId],
            executionTime: state.executionTimes[c.courseId],
            completionLevel: state.completionLevels[c.courseId],
          },
        })),
      };
    },
  },

  actions: {
    loadAccountReport({ commit }, id) {
      const endDate = new Date();
      const endDateBoth = dayjs(endDate).format("YYYY-MM-DD");
      // results in a 6 weeks difference
      const startDateWeeks = dayjs(endDate)
        .subtract(5, "weeks")
        .format("YYYY-MM-DD");
      // results in a 6 months difference
      const startDateMonths = dayjs(endDate)
        .subtract(5, "months")
        .format("YYYY-MM-DD");

      Promise.all([
        services.GET_STATS(id).then((data) => commit("setStats", data)),
        services
          .GET_EXECUTION_TIME({ accountId: id })
          .then((data) => commit("setExecutionTimes", data)),
        services
          .GET_COMPLETION_LEVEL({ accountId: id })
          .then((data) => commit("setCompletionLevels", data)),
        services
          .GET_COMPLETED_COURSES({
            accountId: id,
            startDate: startDateWeeks,
            endDate: endDateBoth,
            binSize: "week",
          })
          .then((data) => commit("setCompletedWeeks", data)),
        services
          .GET_COMPLETED_COURSES({
            accountId: id,
            startDate: startDateMonths,
            endDate: endDateBoth,
            binSize: "month",
          })
          .then((data) => commit("setCompletedMonths", data)),
      ]).then(() => commit("combineResult"));
    },
  },

  getters: {
    getResult: (state) => state.result,
  },
};
