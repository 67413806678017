// Create step objects, these object consist of:
// view: the vue component to be displayed
// requiresCompletion: indicates whether the item requires some interaction or if the user can click next without interacting
import QuizStep from "./QuizStep";
import QuizEditStep from "./QuizEditStep.vue";
import QuizSettings from "./QuizSettings.vue";

import TextStep from "./TextStep.vue";
import TextEditStep from "./TextEditStep.vue";
import TextSettings from "./TextSettings.vue";

import InputStep from "./InputStep.vue";
import InputEditStep from "./InputEditStep.vue";
import InputSettings from "./InputSettings.vue";

import DateStep from "./InputDateStep.vue";
import InputDateEditStep from "./InputDateEditStep.vue";
import InputDateSettings from "./InputDateSettings.vue";

import VideoStep from "./VideoStep";
import VideoEditStep from "./VideoEditStep.vue";
import VideoSettings from "./VideoSettings.vue";

import PdfStep from "./PdfStep";
import PdfEditStep from "./PdfEditStep";
import PdfSettings from "./PdfSettings";

/**
 * Step {
 *  id: String          // The id of the step,
 *  data: Object        // The content of the step,
 *  view: Object        // The view that will be created for the step
 *  editView: Object    // The edit view
 *  settingsView: Object    // View for editing settings
 *  requiresCompletion: boolean  // whether the step has to be set to completed before the user can click on next
 *  sendOnNext: boolean // If set, the answer of the user is cached and only sent to the server when the user clicks on next
 * }
 *
 */

export function createStep(step) {
  switch (step.type) {
    case "quiz":
      return {
        id: step.id,
        data: step,
        view: QuizStep,
        editView: QuizEditStep,
        settingsView: QuizSettings,
        preview: "/img/logo.png",
        requiresCompletion: step.requiresCompletion,
      };
    case "text":
      return {
        id: step.id,
        data: step,
        editView: TextEditStep,
        settingsView: TextSettings,
        preview: "/img/logo.png",
        view: TextStep,
        requiresCompletion: step.requiresCompletion,
      };
    case "multimedia":
      return {
        id: step.id,
        data: step,
        editView: VideoEditStep,
        settingsView: VideoSettings,
        preview: "/img/logo.png",
        view: VideoStep,
        requiresCompletion: step.requiresCompletion,
      };
    case "pdf":
      return {
        id: step.id,
        data: step,
        editView: PdfEditStep,
        settingsView: PdfSettings,
        preview: "/img/logo.png",
        view: PdfStep,
        requiresCompletion: step.requiresCompletion,
      };
    case "inputText":
      return {
        id: step.id,
        data: step,
        view: InputStep,
        editView: InputEditStep,
        preview: "/img/logo.png",
        settingsView: InputSettings,
        requiresCompletion: step.requiresCompletion,
        sendOnNext: true,
      };
    case "inputDate":
      return {
        id: step.id,
        data: step,
        view: DateStep,
        editView: InputDateEditStep,
        settingsView: InputDateSettings,
        preview: "/img/logo.png",
        requiresCompletion: step.requiresCompletion,
        sendOnNext: true,
      };
    default: {
      console.error("Unsupported Step item: " + step.type);
    }
  }
}

export const steps = [
  "quiz",
  "text",
  "multimedia",
  "pdf",
  "inputText",
  "inputDate",
];

const generalOptions = {
  requiresCompletion: false,
  requiresInteraction: false,
  isEditable: true,
};

/**
 * default setting are being used in the editor whenever the user creates a new step
 */
export const defaultSettings = {
  quiz: {
    type: "quiz",
    question: "",
    choices: [
      {
        text: "",
        description: "",
        isCorrect: true,
      },
      {
        text: "",
        description: "",
        isCorrect: false,
      },
      {
        text: "",
        description: "",
        isCorrect: false,
      },
      {
        text: "",
        description: "",
        isCorrect: false,
      },
    ],
    ...generalOptions,
  },
  text: {
    type: "text",
    text: "",
    ...generalOptions,
  },
  multimedia: {
    type: "multimedia",
    url: "",
    text: "",
    ...generalOptions,
  },
  pdf: {
    type: "pdf",
    url: "",
    text: "",
    ...generalOptions,
  },
  inputText: {
    type: "inputText",
    text: "",
    label: "",
    inputType: "text",
    showWordLimit: false,
    rows: 1,
    maxLength: 512,
    minLength: 0,
    ...generalOptions,
  },
  inputDate: {
    type: "inputDate",
    text: "",
    label: "",
    inputType: "date",
    maxDate: null,
    minDate: null,
    ...generalOptions,
  },
};
