export default {
  accountStructureItem: "Структура аккаунта",
  addRole: "Добавить роль",
  allUserRelatedDeleteText:
    "Это безвозвратно удаляет все относящиеся к пользователю данные.",
  assignRoleModals: "Назначить роль",
  canBeDownloadedText: "может быть загружен в разделе завершенные",
  changePassword: "Изменить пароль",
  changingPasswordFor: "Изменить пароль для пользователя",
  confirmDeleteText: "Пожалуйста, подтвердите, введя имя пользователя:",
  confirmDeteleBtnText: "Подтверждаю, удалить пользователя.",
  confirmPassword: "Подтвердите пароль",
  createAccount: "Создать аккаунт",
  createUser: "Создать пользователя",
  currentPassword: "Текущий пароль",
  customId: "Дополнительный идентификатор",
  dataPrivacyHeader: "Конфиденциальность данных",
  dataPrivacyText: "Раздел текста (Содержание следует)",
  dataPrivacyTitle: "Название раздела",
  dataRelatedToMe: "Просмотр данных обо мне",
  dataRelatedToMeTitle: "Данные обо мне",
  deleteText: "Вы уверены, что хотите удалить это",
  downloadDataRelatedToMe: "Скачать данные обо мне как .txt",
  fileWithUserInformation: "Файл с информацией о пользователе",
  forgotPassword: "Вы забыли свой пароль?",
  forgotPasswordText:
    "Пожалуйста, введите ваш адрес электронной почты, чтобы мы могли отправить вам инструкции по сбросу пароля.",
  importUsers: "Импорт пользователей",
  newHere: "Здесь впервые?",
  newPassowrd: "Новый пароль",
  newPasswordShort: "Новый пароль слишком короткий",
  password: "Пароль",
  passwordCantBeEmpty: "Пароль не может быть пустым",
  passwordsNotMatch: "Введенные пароли не совпадают",
  pickUser: "Выберите пользователя",
  pleaseFillUpAllFields: "Пожалуйста, заполните все поля",
  pleaseTypeNewPassword: "Пожалуйста, введите ваш новый пароль",
  policyAcceptance: "Пользовательское соглашение",
  profile: "Профиль",
  role: "Роль",
  roleExpiresOn: "Роль действительна до",
  saveDataAboutMe: "данные обо мне",
  signIn: "Войти в систему",
  termsConditionsHeader: "Условия использования",
  termsConditionsText: "Раздел текста (Содержание следует)",
  termsConditionsTitle: "Название раздела",
  typeInOne: "Введите один для всех",
  username: "Имя пользователя",
  usernameMail: "Имя пользователя или oder адрес электронной почты",
  userNotes: "Заметки о пользователе",
  userProfile: "Профиль пользователя",
  userStatus: "Статус пользователя",
  wrongCurrentPassword: "Неверный текущий пароль",

  minimumLength: "Минимальная длина 8",
  atLeastOneBigAndSmall: "как минимум 1 маленькая и 1 большая буква",
  passwordNotEqualUsernameAndEmail:
    "пароль не совпадает с именем пользователя и адресом электронной почты",

  userCreateSuccess: "Пользователь успешно создан",
  createOtherUser: "Создать другого пользователя",
  backToUserOverview: "Назад к обзору пользователя",

  registrationKey: "Регистрационный ключ",
  registration: "Регистрация",
  notAnEmailAdress: "Пожалуйста, введите действующий адрес электронной почты.",
  company: "Компания",
  companyHint: "Компания",
  birthday: "День рождения",
  birthdayHint: "День рождения",

  accountName: "Название аккаунта",
  accountType: "Тип аккаунта",
  accountAddress: "Адрес аккаунта",
  logo: "Логотип",
  address: "Адрес",
  contry: "Страна",
  lessThan: "менее 1 МБ",
  defaultLanguage: "Язык по умолчанию",
  accountStatus: "Статус аккаунта",
  accountNotes: "Примечания к аккаунту",
  createAccount: "Создать аккаунт",
  fileToImport: "Импорт файла",
  importStructure: "Импорт структуры аккаунта",
  updateAccount: "Обновить аккаунт",
  addRootAccount: "Добавить учетную запись базового уровня",
  usersPerMonth: "Пользователей в месяц",
  usersAdded: "Пользователи добавлены",
  usersDeleted: "Пользователи удалены",
  usersTotal: "Всего пользователей",
  city: "Город",

  knowledge: "ОБЗОР",
  toolbox: "TOOLBOX",
  lessonCompleted: "Урок завершен",
  lessonFailed: "Урок не окончен",
  repeatLesson: "Пожалуйста, повторите урок, чтобы продолжить",
  congratulations: "Поздравляем",
  courseCompleted: "Курс окончен",
  downloadCertificate:
    'Вы можете загрузить сертификат в разделе "Сертификаты" в своем профиле.',
  trueFalse: "Правда или ложь?",
  seconds: "сек.",
  noChoiceText: "Слишком поздно, как жаль!",
  right: "Верно!",
  wrong: "Неверно",
  percentCompleted: "Вы правильно ответили на $successRate% всех вопросов.",
  progress: "$current из $total",

  addCourseContent: "Добавить содержание курса",
  addSemicolon: "Добавьте теги через точку с запятой",
  addToProgram: "Добавить в программу",
  allAccounts: "Все аккаунты",
  certificate: "Сертификат",
  certificates: "Сертификаты",
  completed: "Закончен",
  contentIsLoaded: "Контент загружен",
  contentManager: "Менеджер контента",
  courseCompleted: "Курс завершен",
  courseContent: "Содержание курса",
  courseDocuments: "Документация",
  courseLables: "Kurs-Etiketten",
  courseLanguage: "Язык курса",
  courseNotes: "Примечания к курсу",
  courseStructure: "Структура",
  courseTags: "Теги курса",
  courseTitle: "Название курса",
  createCourse: "Создать курс",
  description: "Описание",
  details: "Детали",
  dueDate: "Завершить до",
  duration: "Продолжительность",
  learningStandard: "Стандарт обучения",
  licenses: "Лицензии",
  mandatory: "Обязательный курс",
  noCoursesAssigned: "Нет курсов, связанных с вашей программой.",
  noCoursesAvailable: "В настоящее время доступных курсов нет.",
  noDueDate: "без срока завершения",
  page: "",
  pickAnAccount: "Выберите аккаунт",
  printCertificate: "Распечатать сертификат",
  publishCourse: "Опубликовать курс",
  publishedToAccount: "Опубликован в аккаунте",
  relatedCourses: "Подобные курсы",
  remove: "Удалить",
  singleAccount: "Один аккаунт",
  startCourse: "Начать курс",
  successMessageOne: "Поздравляем, Ваш курс ",
  successMessageThree:
    "Теперь вы можете скачать или распечатать свой сертификат об окончании курса. ",
  successMessageTwo: " успешно завершено!",
  tags: "Теги",
  unpublishCourse: "Отменить публикацию",
  updateCourseContent: "Загрузить содержание курса",
  updateCourse: "Обновить курс",
  youDidntfinishCourse: "Вы еще не закончили курс.",
  courseVisibleFor: "Курс виден для",
  accountStructureItem: "Структура аккаунта",
  courseAssigmentOptions: "Варианты назначения курса",
  assignCourse: "Назначить курс",
  pickACourse: "Выберите курс",

  noDocumentsAvailable: "Документация отсутствует",
  nothingRelated: "ничего подобного не найденно",
  nothingWritten: "ничего не занесено",
  noTagsAvailable: "Теги отсутствуют",
  alreadyGlobal: "Курс уже опубликован во всех аккаунтах.",

  courseNotPublished: "Курс не опубликован",
  courseNotPublishedForAccount: "Курс не опубликован для аккаунта $account",
  clickToPublishCourse: "Опубликовать курс",
  visible: "Видимый",
  userIsNotContentManager:
    "Вы не зарегистрированы в качестве контент-менеджера и, следовательно, не можете редактировать содержание этого курса.",

  assignmentOpen: "Открыт",
  assignmentStarted: "Начат",
  assignmentCompleted: "Закончен",
  dueDatePassed: "Срок истек",
  exitCourse: "Выход из курса",
  markAsFinished: "Отметить как закончен",

  system: "Система",
  configureLogging: "Настройка журнала",
  createAccount: "Создать аккаунт",
  changeAccount: "Изменить аккаунт",
  deleteAccount: "Удалить аккаунт",
  importAccountStructure: "Импорт структуры аккаунта",
  createUser: "Создать пользователя",
  importUser: "Импорт пользователя",
  changeUser: "Изменить пользователя",
  deleteUser: "Удалить пользователя",
  assignUserRole: "Назначить роль",
  resetPassword: "Изменить пароль",

  createCourse: "Создать курс",
  changeCourse: "Изменить курс",
  addContent: "Добавить содержание курса",
  changeContent: "Изменить содержание курса",
  deleteCourse: "Удалить курс",
  deleteContent: "Удалить содержание курса",
  publishCourse: "Опубликовать курс",
  unpublishCourse: "Отменить публикацию",
  assignCourse: "Назначить курс",
  unassignCourse: "Отменить назначенного курса",
  systemMessages: "Системные сообщения",

  learningManagement: "Управление обучением",
  courseManagement: "Управление курсами",
  userManagement: "Управление пользователями",
  organizationStructure: "Структура организации",
  accountManagement: "Управление аккаунтами",
  system: "Система",

  learningManagementDetail: "Учебные планы и назначения курсов",
  courseManagementDetail: "Управление содержанием курсов",
  userManagementDetail: "Администрирование пользователей и сброс пароля",
  organizationStructureDetail: "Структура организации",
  accountManagementDetail: "Администрирование аккаунтов",
  logsDetail: "Системные журналы и настройки",
  importMoreUsers: "Импорт следующих пользователей",
  currentRoles: "Роли",

  numberOfRoles: "Количество ролей",
  numberOfUsers: "Пользователи",
  deleteAccountMessage:
    "Пожалуйста, подтвердите, что вы хотите удалить аккаунт '$account'.",
  showStatistics: "Показать статистику",
  appendAccount: "Добавить аккаунт к $account.",

  courseAssignments: "Назначения",
  courseAssignmentsForCourse: "Назначения для $course",
  mandatoryAssignments: "Обязательные назначения",
  dueDateAssignments: "Со сроком исполнения",
  dueDatePassed: "Срок истек",

  userOrAccount: "Пользователь или аккаунт",
  expiryDate: "Срок годности",

  deletePublication: "Удаление публикации курсов",
  deletePublicationMessage:
    "Уверены ли вы, что хотите удалить публикацию для аккаунта $account?",
  orgStructure: "Отдел",
  type: "Тип",
  downloadTemplate: "Скачать шаблон",
  published: "Опубликован",
  scorm: "SCORM",
  quiz: "Quiz",
  staticFile: "Pdf",
  editPublication: "Редакция публикации",
  pleaseDefineCourseContent:
    "please define the course content before publishing it.",

  usernameMissing: "Имя пользователя отсутствует",
  usernameError:
    "Имя пользователя может содержать только буквенно-цифровые символы.",
  emailMissing: "Электронная почта отсутствует",
  emailError: "Не действующий адрес электронной почты",
  passwordMissing: "Пароль отсутствует",
  passwordError:
    "Пароль должен содержать не менее 8 символов, заглавную и строчную букву",

  registrationKeyManagement: "Ключи регистрации",
  registrationKeyManagementDetail: "Управление ключом регистрации",

  addRegistrationKey: "Добавить ключ регистрации",
  registrationLink: "Ссылка для регистрации",
  createKey: "Создать ключ",
  user_upload_maximum: "До 1000 пользователей за одну загрузку",

  userState: "Состояние пользователя",
  userStateDetail: "Состояние доступа и обязательные курсы.",
  access: "Доступ",
  downloadReport: "Скачать отчет",

  success: "Успешно сохранено!",
  warning: "Внимание!",
  error: "Ошибка!",
  removedFromProgram: "Курс был удален из вашей программы",
  addedToProgram: "Курс был добавлен в вашу программу",
  courseCreated: "Курс создан",
  courseNotCreated: "Курс не был создан",
  fillUpFields: "Пожалуйста, заполните необходимые поля",
  cookieNotAvailable:
    "Ваша логин информация не доступна или устарела, пожалуйста, войдите в систему еще раз.",
  courseNotFinished: "Курс не предоставил никакой информации об окончании",
  courseAborted: "Курс отменён",
  correctAnswer: "Правильный ответ",
  wrongAnswer: "Неправильный ответ",
  courseStarted: "Курс начат",
  testsPassed: "Тест пройден",
  testFailed: "Тест не пройден",
  courseCompleted: "Курс успешно пройден",
  imageTooLarge: "Изображение должно быть меньше 2мб",
  wrongFormat: "Неправильный формат",
  passwordReset: "На электронный адрес отправлена ссылка для сброса пароля.",
  passwordChanged: "Пароль изменен",
  accountAdded: "Аккаунт добавлен",
  accountUpdated: "Аккаунт был обновлен",
  accountDeleted: "Аккаунт удален",
  userImported: "Пользователи импортированы",
  userNotImported: "Пользователи не были импортированы",
  loggingConfigSaved: "Конфигурация журнала сохранена",
  userDeleted: "Пользователь удален",
  userAdded: "Пользователь добавлен",
  userUpdated: "Пользователь обновлен",
  GDPRLoaded: "ВВПР загружен",
  cookiesDisabled: "У вас отключены cookie-файлы",
  wrongCredentials: "Неверные данные",
  fileUploaded: "Ваш файл загружен",
  courseContentUpdated: "Содержание курса обновлено",
  coursePublished: "Курс опубликован",
  courseUnPublished: "Публикация курса отменена",
  serverUnreachable: "Сервер недоступен",
  courseContentAdded: "Содержание курса добавлено",
  user_upload_exceeded:
    "Слишком много пользователей: поддерживается до 1000 пользователей за одну загрузку.",

  selectAccounts: "Выбрать аккаунт",
  accountOverview: "Обзор аккаунта",
  users: "Пользователи",
  courses: "Курсы",
  coursesCompleted: "Завершенные курсы",
  completedCoursesPerUser: "Завершенные курсы на пользователя",
  learningHours: "Часы обучения",
  learningHoursPerUser: "Часы обучения на пользователя",
  courseOverview: "Обзор курса",
  completed: "Завершен",
  assigned: "Назначен",
  courseDetails: "Детали",
  selectCourse: "Выбрать курс",
  details: "Подробно",
  type: "Тип",
  dueDate: "До",
  mandatory: "Обязательный курс",
  completionRate: "Показатель завершения",
  averageScore: "Результат теста",
  duration: "Продолжительность",
  averageDuration: "Средняя продолжительность",

  account: "Аккаунт",
  accountName: "Название аккаунта",
  accounts: "Аккаунты",
  active: "Действующий",
  admin: "Администратор",
  all: "Все",
  assignedUsersOnly: "Только назначенные пользователи",
  autoIndividual: "Автоматический, индивидуальный",
  cancel: "Отменить",
  cardImage: "Изображение карты",
  close: "Закрыть",
  completed: "Завершен",
  confirm: "Подтвердить",
  country: "Страна",
  course: "Курсы",
  courseDescription: "Описание курса",
  courseDocuments: "Дополнительные материалы",
  courses: "Курсы",
  courseStructure: "Структура курса",
  dataProtection: "Политика конфиденциальности",
  defaultLanguage: "Стандартный язык",
  deleteText: "Вы уверены, что хотите удалить это",
  details: "Подробности",
  dragAndDrop: "Перетащите / Нажмите, чтобы добавить",
  dueDate: "выполнить до",
  email: "Электронная почта",
  empty: "Отсутствует",
  exit: "Выйти",
  expiresOn: "Истекает",
  fileImport: "Импорт файла",
  image: "Изображение",
  inactive: "Неактивный",
  language: "Язык",
  logo: "Логотип",
  mandatory: "Обязательный",
  monthsLabels: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Аавгуст",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ],
  myAccount: "Мой аккаунт",
  myProfile: "Мой профиль",
  myProgram: "Моя программа",
  myRoles: "Мои роли",
  never: "Никогда",
  no: "Нет",
  none: "Никто",
  notFound: "Не найден",
  phoneNumber: "Номер телефона",
  playImage: "Воспроизвести картинку",
  publishingExpiresOn: "Срок публикации истекает",
  publishTo: "Опубликовать в",
  save: "Сохранить",
  search: "Поиск",
  status: "Статус",
  structureItem: "Структурный элемент",
  termsAndConditions: "Постановления и условия",
  unlimited: "Неограниченный",
  update: "Обновить",
  user: "Пользователь",
  username: "Имя пользователя",
  users: "Пользователи",
  visibility: "Видимость аккаунта",
  yes: "Да",
  zipCode: "Почтовый индекс",

  info: "Информация",
  error: "Ошибка",
  alert: "Предупреждение",
  criticalAlert: "Критическое предупреждение",
  navigation: "Навигация",
  system: "Система",
  note: "Примечание",
  userProfile: "Профиль пользователя",

  userId: "ID пользователя",
  customId: "Индивидуальный код",
  organizationalStructure: "Оргструктура",
  created: "Создано",
  new: "Новый",
  noData: "Данные отсутствуют",
  uploadFile: "Загрузить файл",

  id: "ID",
  name: "Имя",
  delete: "Удалить",
  append: "Добавить",
  edit: "Правка",
  format: "Формат",
  minutes: "Минуты",
  hours: "Часы",

  date: "Дата",
  message: "Сообщение",
  fax: "Факс",
  genericError:
    "Произошла ошибка, попробуйте перезагрузить страницу. Если ошибка не устранена, обратитесь к своему системному администратору.",
  action: "Действие",

  uploadedCertificates: "Загруженные сертификаты",

  add_mandatory_user_property:
    "Пожалуйста, выберите следующие характеристики для вашей работы",
  help: "Помощь",
  alreadyRegistered: "Уже зарегистрирован",
  additionalDetails: "Дополнительная информация",
  additionalDetailsSave: "Подтвердить",
  dragAndDropCertificate:
    "Переместите PDF-файл сюда или нажмите для добавления",
  coverPhoto: "Cover Photo",
  EditCoverPhoto: "Edit Cover Photo",
  AddCoverPhoto: "Add Cover Photo",
  additionalDocuments: "Additional Documents",
  editAdditionalDocuments: "Edit Additional Documents",
  addAdditionalDocuments: "Add Additional Documents",
  fileName: "File name",
  fileSize: "File size",
  systemLogs: "System Logs",
  systemConfigurationDetail: "Configure system settings",
  systemConfiguration: "System configuration",
  courseFormat: "Course formats",
  views: "Views",
  apis: "APIs",
  viewsDescription: "Visibility of the element in the respective view",
  courseFormatDescription: "Available course formats for defining the course",
  accessCard: "Access card",
  downloadCertification: "Download Certification",
  userProfileV2: "User profile V2",
  certification: "Certification",
  logs: "Logs",
  issuedBy: "Issued by",
  issuedOn: "Issued on",
  fulfilledInPercent: "Fulfilled in percent",

  logout: "Выйти",
  continue: "Продолжить",
  courseFinishedDoYouWantToLogout:
    "Курс завершен. Вы хотите выйти или продолжить? Вы будете автоматически выведены через {seconds}.",
  stateless: "лицо без гражданства",
};
