<template lang="pug">
.text-step
  .ql-editor.text-container.mb-2(v-html="data.text")
  .center(v-loading="data.loadingState.loading")
    ConfirmationButton(
      v-if="data.requiresInteraction",
      :completed="data.completed",
      @confirmed="sendConfirmation"
    )
</template>

<script>
import stepMixin from "./stepMixin";
import ConfirmationButton from "./components/ConfirmButton.vue";

export default {
  components: { ConfirmationButton },
  mixins: [stepMixin],
  methods: {
    sendConfirmation() {
      this.sendAnswer({ confirmation: true });
    },
  },
};
</script>

<style scoped lang="stylus">
@import '../../../settings.styl';

.text-container :deep(h1) {
  text-align: center;
}
</style>
