<template lang="pug">
div
  h3 {{ $t("settings") }}
  h4 {{ $t("choiceSettings") }}
  transition-group(name="fade", tag="span")
    template(
      v-for="(choice, index) in step.choices",
      :key="'step-setting-' + index"
    )
      .step-settings.clickable.mt-2.mb-1(@click="hideChoiceSetting(index)")
        .flex-row.flex-center
          el-icon.mr-2(v-if="isHidden(index)")
            ArrowDown
          el-icon.mr-2(v-else)
            ArrowRight
          h4.mt-0.mb-0.mr-2 {{ `${$t("choice")} ${String.fromCharCode(65 + index)}` }}
          el-icon
            Delete(@click="deleteChoice(index)")
      .step-settings(
        v-if="!isHidden(index)",
        :key="'step-setting-content' + index"
      )
        label.settings-label-top {{ $t("choiceText").replace("$index", String.fromCharCode(65 + index)) }}
        RichTextEditor(
          :model-value="choice.text",
          @update:modelValue="editChoiceText(index, $event)"
        )

        label.settings-label-top {{ $t("choiceExplanation").replace("$index", String.fromCharCode(65 + index)) }}
        RichTextEditor(
          :model-value="choice.description",
          @update:modelValue="editChoiceDescription(index, $event)"
        )

        label {{ $t("choiceIsCorrect") }}
        CheckboxInput(
          :model-value="choice.isCorrect",
          @update:modelValue="editChoiceIsCorrect(index, $event)"
        )

    el-button.fill-width.mb-3.mt-1(type="primary", @click="addChoice") {{ $t("addChoice") }}
    .step-settings
      label {{ $t("randomizeAnswers") }}
      CheckboxInput(v-model="randomizeAnswers")

  CommonSettings(:step="step")
</template>

<script>
import { mapGetters } from "vuex";
import CommonSettings from "./CommonSettings";
import RichTextEditor from "@/components/elements/RichTextEditor.vue";
import CheckboxInput from "@/components/elements/CheckboxInput";
import editStepMixin from "./mixins/editStepMixin";

export default {
  components: {
    CommonSettings,
    RichTextEditor,
    CheckboxInput,
  },
  mixins: [editStepMixin],
  data: function () {
    return {
      hiddenChoices: [],
    };
  },
  computed: {
    randomizeAnswers: {
      get() {
        return this.step.randomizeChoices;
      },
      set(randomizeChoices) {
        this.patchStep({ randomizeChoices });
      },
    },
  },
  methods: {
    hideChoiceSetting(index) {
      const isHiddenIndex = this.hiddenChoices.findIndex(
        (item) => item === index,
      );
      if (isHiddenIndex > -1) {
        this.hiddenChoices.splice(isHiddenIndex, 1);
      } else {
        this.hiddenChoices.push(index);
      }
    },
    isHidden(index) {
      return this.hiddenChoices.includes(index);
    },
    editChoiceText(index, text) {
      // NOTE: when editing choices, we have to edit all choices
      const choices = this.step.choices.map((item) => item);
      if (choices[index].text !== text) {
        choices[index].text = text;
        this.patchStep({ choices });
      }
    },
    editChoiceDescription(index, text) {
      const choices = this.step.choices.map((item) => item);
      if (choices[index].description !== text) {
        choices[index].description = text;
        this.patchStep({ choices });
      }
    },
    editChoiceIsCorrect(index, isCorrect) {
      const choices = this.step.choices.map((item) => item);
      choices[index].isCorrect = isCorrect;
      this.patchStep({ choices });
    },
    addChoice() {
      const choices =
        this.step.choices !== undefined
          ? this.step.choices.map((item) => item)
          : [];
      choices.push({
        text: this.$t("newChoice"),
        description: "",
        isCorrect: false,
      });
      this.patchStep({ choices });
    },
    deleteChoice(index) {
      const choices = this.step.choices.map((item) => item);
      choices.splice(index, 1);
      this.patchStep({ choices });
    },
  },
};
</script>
