import {
  stateInitial,
  stateLoading,
  stateLoaded,
  stateError,
} from "./loadingStates";
import flexService from "@/services/flex";
import { createStep } from "@/components/flex/steps/stepFactory";
import _, { find } from "lodash";

const requestQueue = {
  queue: [],
  isRunning: false,
};

function runQueue() {
  if (requestQueue.queue.length > 0) {
    const next = requestQueue.queue.shift();
    next().then(runQueue);
  } else {
    requestQueue.isRunning = false;
  }
}

const addToQueue = (func) =>
  new Promise((resolve, reject) => {
    if (requestQueue.isRunning) {
      requestQueue.queue.push(async function () {
        const result = await func();
        resolve(result);
      });
    } else {
      requestQueue.isRunning = true;
      func().then((result) => {
        runQueue();
        resolve(result);
      });
    }
  });

export default {
  state: {
    id: null,
    backendState: {
      loadingState: stateInitial,
      course: null,
    },
    // NOTE: when editing a course we follow an optimistic approach,
    // we change the local data and hope that the request will work,
    // the user still sees a loading hint and an error if the request fails
    // backgroundLoading and error are meant as universal loading error indicators,
    // showing that some kind of process is running in the background or failed.
    // backgroundLoading is being incremented and decremented to allow multiple processes indicate a loading state at the same time
    backgroundLoading: 0,
    backgroundError: false,
    // These are changes that are currently only held locally.
    // These changes are merged with the backendState to show the user what the current editing state is.
    // Whenever the user clicks on save, these changes are submitted to the backend and then merged into backendState.
    // This is also
    unsavedChanges: {},

    coursePatch: {},
    lessonPatches: [],
    viewPatches: [],
    stepPatches: [],

    savingChanges: false,
    errorSavingChanges: false,
    // This is defining what type we are currently editing, this can be 'step', 'view', 'lesson' or 'course'
    editType: null,
    editId: null,
    translations: {
      loadingState: stateInitial,
      translations: [],
      // patches are of type {id, type, key, lang, text}
      patches: [],
      defaultLanguage: null,
    },
  },
  mutations: {
    courseLoading(state, id) {
      state.id = id;
      state.backendState.course = null;
      state.backendState.loadingState = stateLoading;
    },
    courseLoaded(state, { data, id }) {
      state.backendState.course = data;
      state.backendState.id = id;
      state.backendState.loadingState = stateLoaded;
    },
    courseError(state, error) {
      state.backendState.course = null;
      state.backendState.loadingState = stateError;
      state.backendState.loadingState.error = error;
    },
    backgroundLoading(state) {
      state.backgroundLoading = state.backgroundLoading + 1;
    },
    backgroundError(state) {
      state.backgroundError = true;
      state.backgroundLoading = 0;
    },
    backgroundLoaded(state) {
      state.backgroundLoading = state.backgroundLoading - 1;
    },
    addLesson(state, lesson) {
      state.backendState.course.lessons.push(lesson);
    },
    addView(state, { lessonId, view, index }) {
      // find the referenced lesson
      const lesson = state.backendState.course.lessons.find(
        (item) => item.id === lessonId,
      );
      lesson.views.splice(index, 0, view);
    },
    addStep(state, { viewId, step, index }) {
      // find the referenced view
      const view = state.backendState.course.lessons
        .flatMap((lesson) => lesson.views)
        .find((item) => item.id === viewId);
      view.steps.splice(index, 0, step);
    },
    orderStepsInView(state, { viewId, oldIndex, newIndex }) {
      const view = state.backendState.course.lessons
        .flatMap((lesson) => lesson.views)
        .find((item) => item.id === viewId);
      const items = view.steps.splice(oldIndex, 1);
      view.steps.splice(newIndex, 0, items[0]);
    },
    moveStepBetweenViews(state, { stepId, oldViewId, newViewId, newIndex }) {
      const oldView = state.backendState.course.lessons
        .flatMap((lesson) => lesson.views)
        .find((item) => item.id === oldViewId);
      const oldIndex = oldView.steps.findIndex((item) => item.id === stepId);
      const step = oldView.steps.splice(oldIndex, 1)[0];

      const newView = state.backendState.course.lessons
        .flatMap((lesson) => lesson.views)
        .find((item) => item.id === newViewId);
      newView.steps.splice(newIndex, 0, step);
    },
    removeViews(state, views) {
      state.editType = "course";
      views.forEach((view) => {
        const lesson = state.backendState.course.lessons.find(
          (lesson) =>
            lesson.views.find((item) => item.id === view.id) !== undefined,
        );
        const viewIndex = lesson.views.findIndex((item) => item.id === view.id);
        lesson.views.splice(viewIndex, 1);
      });
    },
    orderViewsInLesson(state, { lessonId, oldIndex, newIndex }) {
      const lesson = state.backendState.course.lessons.find(
        (item) => item.id === lessonId,
      );
      const items = lesson.views.splice(oldIndex, 1);
      lesson.views.splice(newIndex, 0, items[0]);
    },
    moveViewBetweenLessons(
      state,
      { viewId, newLessonId, oldLessonId, newIndex },
    ) {
      const oldLesson = state.backendState.course.lessons.find(
        (item) => item.id === oldLessonId,
      );
      const oldIndex = oldLesson.views.findIndex((item) => item.id === viewId);
      const view = oldLesson.views.splice(oldIndex, 1)[0];

      const newLesson = state.backendState.course.lessons.find(
        (item) => item.id === newLessonId,
      );
      newLesson.views.splice(newIndex, 0, view);
    },
    sortLessons(state, { oldIndex, newIndex }) {
      const lessons = state.backendState.course.lessons;
      const lesson = lessons.splice(oldIndex, 1)[0];
      lessons.splice(newIndex, 0, lesson);
    },
    setEditStep(state, step) {
      state.unsavedChanges = {};
      state.editType = "step";
      state.editId = step.id;
    },
    setEditView(state, view) {
      state.unsavedChanges = {};
      state.editType = "view";
      state.editId = view.id;
    },
    setEditLesson(state, lesson) {
      state.unsavedChanges = {};
      state.editType = "lesson";
      state.editId = lesson.id;
    },
    setEditCourse(state) {
      state.unsavedChanges = {};
      state.editType = "course";
    },
    addStepPatch(state, patch) {
      const index = state.stepPatches.findIndex((item) => patch.id === item.id);
      if (index > -1) {
        state.stepPatches.splice(
          index,
          1,
          _.mergeWith({}, state.stepPatches[index], patch, _mergeWithoutArray),
        );
      } else {
        state.stepPatches.push(patch);
      }
    },
    /**
     * searches an existing step patch, merges the @patch argument, then merges the patch into the course and removes the patch
     * This is used to merge server side information (i.e. quiz response ids) into the course, mutates patch
     */
    mergeStepPatch(state, patch) {
      const stepPatchIndex = state.stepPatches.findIndex(
        (item) => patch.id === item.id,
      );
      if (stepPatchIndex > -1) {
        _.merge(patch, state.stepPatches[stepPatchIndex]);
        state.stepPatches.splice(stepPatchIndex, 1);
      }
      const step = state.backendState.course.lessons
        .flatMap((lesson) => lesson.views)
        .flatMap((view) => view.steps)
        .find((step) => step.id === patch.id);
      _.merge(step, patch);
    },
    addViewPatch(state, patch) {
      const index = state.viewPatches.findIndex((item) => patch.id === item.id);
      if (index > -1) {
        state.viewPatches.splice(
          index,
          1,
          _.mergeWith({}, state.viewPatches[index], patch, _mergeWithoutArray),
        );
      } else {
        state.viewPatches.push(patch);
      }
    },
    addCoursePatch(state, patch) {
      state.coursePatch = _.mergeWith(
        {},
        state.coursePatch,
        patch,
        _mergeWithoutArray,
      );
    },
    addLessonPatch(state, patch) {
      const index = state.lessonPatches.findIndex(
        (item) => patch.id === item.id,
      );
      if (index > -1) {
        state.lessonPatches.splice(
          index,
          1,
          _.mergeWith(
            {},
            state.lessonPatches[index],
            patch,
            _mergeWithoutArray,
          ),
        );
      } else {
        state.lessonPatches.push(patch);
      }
    },
    savingChanges(state) {
      state.errorSavingChanges = false;
      state.savingChanges = true;
    },
    errorSavingChanges(state) {
      state.errorSavingChanges = true;
    },
    discardChanges(state) {
      state.lessonPatches = [];
      state.viewPatches = [];
      state.stepPatches = [];
      state.editType = null;
      state.editId = null;
    },
    changesSaved(state, updatedCourse) {
      state.backendState.course = updatedCourse;
      state.coursePatch = {};
      state.lessonPatches = [];
      state.viewPatches = [];
      state.stepPatches = [];
      state.savingChanges = false;
    },
    patchView(state, { patch }) {
      const view = state.backendState.course.lessons
        .flatMap((lesson) => lesson.views)
        .find((view) => view.id === state.editId);
      _.mergeWith(view, patch, _mergeWithoutArray);
    },
    patchStep(state, { patch }) {
      if (state.backendState.course) {
        const step = state.backendState.course.lessons
          .flatMap((lesson) => lesson.views)
          .flatMap((view) => view.steps)
          .find((step) => step.id === state.editId);
        if (step) {
          _.mergeWith(step, patch, _mergeWithoutArray);
        }
      }
    },
    removeStep(state, stepId) {
      state.editType = "course";
      if (state.editId === stepId) {
        state.editId = null;
        state.editType = "";
      }
      const view = state.backendState.course.lessons
        .flatMap((lesson) => lesson.views)
        .find((view) => view.steps.find((step) => step.id === stepId));
      const index = view.steps.findIndex((item) => item.id === stepId);
      view.steps.splice(index, 1);
    },
    removeLesson(state, lessonId) {
      state.editType = "course";
      const index = state.backendState.course.lessons.findIndex(
        (lesson) => lesson.id === lessonId,
      );
      state.backendState.course.lessons.splice(index, 1);
      if (state.editType === "lesson" && state.editId === lessonId) {
        state.editType = null;
        state.editId = null;
      }
    },
    removeView(state, viewId) {
      state.editType = "course";
      const lesson = state.backendState.course.lessons.find(
        (lesson) =>
          lesson.views.find((item) => item.id === viewId) !== undefined,
      );
      const viewIndex = lesson.views.findIndex((item) => item.id === viewId);
      lesson.views.splice(viewIndex, 1);
    },
    loadTranslations(state) {
      state.translations.loadingState = stateLoading;
    },
    translationsLoaded(state, { courseId, response }) {
      state.id = courseId;
      state.translations.translations = response.result;
      state.translations.defaultLanguage = response.defaultLanguage;
      state.translations.loadingState = stateLoaded;
    },
    translationsSaved(state, updatedTranslations) {
      state.translations.translations = updatedTranslations;
      state.translations.patches = [];
      state.translations.loadingState = stateLoaded;
    },
    translationsError(state) {
      state.translations.loadingState = stateError;
    },
    // NOTE: patch here is expected to be {id, type, key, lang, text},
    addTranslationPatch(state, patch) {
      const existingPatchIndex = state.translations.patches.findIndex(
        (item) =>
          item.type === patch.type &&
          item.id === patch.id &&
          (item.key === patch.key) & (item.lang === patch.lang),
      );
      if (existingPatchIndex > -1) {
        state.translations.patches.splice(existingPatchIndex, 1, patch);
      } else {
        state.translations.patches.push(patch);
      }
    },
    // directly patch the backend translation state
    patchBackendTranslation(state, { stepId, patch }) {
      // NOTE: direct patches to translation only happen for file uploads,
      // i.e. we can safely assume here that the key for the patch is 'url'
      const stepItemIndex = state.translations.translations.findIndex(
        (item) => item.id === stepId,
      );
      if (stepItemIndex === -1) {
        return;
      }
      const translationItemIndex = state.translations.translations[
        stepItemIndex
      ].translations.findIndex((item) => item.key === patch.key);
      const translationItem =
        state.translations.translations[stepItemIndex].translations[
          translationItemIndex
        ];
      state.translations.translations[stepItemIndex].translations[
        translationItemIndex
      ] = _.merge({}, translationItem, patch);
    },
  },
  actions: {
    handleFlexEditorError(context) {
      context.commit("addNewNotification", {
        title: "error",
        text: "genericError",
        notificationType: "error",
      });
      context.dispatch("reloadCourse");
    },
    loadCourse(context, { id }) {
      context.commit("courseLoading", id);
      flexService.loadFullCourse(id).then(
        (data) => {
          context.commit("courseLoaded", { data, id });
        },
        (error) => {
          context.commit("courseError", error);
        },
      );
    },
    async reloadCourse(context) {
      const id = context.state.id;
      context.commit("courseLoading", id);
      await flexService.loadFullCourse(id).then(
        (data) => {
          context.commit("courseLoaded", { data, id });
        },
        (error) => {
          context.commit("courseError", error);
        },
      );
    },
    async addLesson(context, lesson) {
      context.commit("backgroundLoading");
      // NOTE: when creating things, we have to wait for an id, thus we render after the request
      const response = await addToQueue(() =>
        flexService
          .addLesson(context.getters.courseId, lesson)
          .catch((error) => {
            context.commit("backgroundLoaded");
            context.commit("backgroundError");
            context.dispatch("handleFlexEditorError");
            throw error;
          }),
      );

      lesson = {
        id: response.id,
        views: [],
        ...lesson,
      };
      context.commit("addLesson", lesson);
      context.commit("backgroundLoaded");
      return lesson;
    },
    // Adds a new view, returns the response of the view creation
    async addView(context, { lessonId, view, index }) {
      context.commit("backgroundLoading");
      const viewResponse = await addToQueue(async function () {
        const viewResponse = await flexService
          .addView(context.getters.courseId, lessonId, view)
          .catch((error) => {
            context.commit("backgroundLoaded");
            context.commit("backgroundError");
            context.dispatch("handleFlexEditorError");
            throw error;
          });
        context.commit("addView", {
          lessonId,
          index,
          view: {
            id: viewResponse.data.id,
            steps: [],
            ...view,
          },
        });
        // NOTE: after creating a view, we have to set the ordering correctly
        const lesson = context.getters.lessons.find(
          (lesson) => lesson.id === lessonId,
        );
        const ordering = lesson.views.map((view) => view.id);
        await flexService
          .orderViews(context.getters.courseId, lessonId, { ordering })
          .catch((error) => {
            context.commit("backgroundLoaded");
            context.commit("backgroundError");
            context.dispatch("handleFlexEditorError");
            throw error;
          });
        context.commit("backgroundLoaded");
        return viewResponse.data;
      });
      return viewResponse;
    },
    async addStep(context, { viewId, step, index }) {
      context.commit("backgroundLoading");
      const response = await flexService
        .addStep(context.getters.courseId, viewId, step)
        .catch((error) => {
          context.commit("backgroundLoaded");
          context.commit("backgroundError");
          context.dispatch("handleFlexEditorError");
          throw error;
        });

      // TODO: fix API inconsistency
      const stepWithId = {
        id: response.data.id,
      };
      _.merge(stepWithId, step, response.data);

      context.commit("addStep", {
        viewId,
        index,
        step: stepWithId,
      });
      // NOTE: after adding a step we have to set the ordering correctly
      const view = context.getters.views.find((item) => item.id === viewId);
      const ordering = view.steps.map((item) => item.id);
      await flexService
        .orderSteps(context.getters.courseId, viewId, { ordering })
        .catch((error) => {
          context.commit("backgroundLoaded");
          context.commit("backgroundError");
          context.dispatch("handleFlexEditorError");
          throw error;
        });
      context.commit("backgroundLoaded");
      // After adding a new element we switch directly into the edit mode for that element
      context.commit("setEditStep", stepWithId);
      return response;
    },
    async moveStepInView(context, { viewId, oldIndex, newIndex }) {
      context.commit("orderStepsInView", { viewId, oldIndex, newIndex });
      context.commit("backgroundLoading");
      // after ordering locally we make the request to the backend
      const view = context.getters.views.find((item) => item.id === viewId);
      const ordering = view.steps.map((item) => item.id);
      await addToQueue(() =>
        flexService
          .orderSteps(context.getters.courseId, viewId, { ordering })
          .catch((error) => {
            context.commit("backgroundLoaded");
            context.commit("backgroundError");
            context.dispatch("handleFlexEditorError");
            throw error;
          }),
      );

      context.commit("backgroundLoaded");
    },
    async switchStepBetweenViews(
      context,
      { stepId, oldViewId, newViewId, newIndex },
    ) {
      if (
        !stepId === undefined ||
        !oldViewId === undefined ||
        newViewId === undefined ||
        !newIndex === undefined
      ) {
        return;
      }
      context.commit("moveStepBetweenViews", {
        stepId,
        oldViewId,
        newViewId,
        newIndex,
      });

      // First we have to move the step to another view, then we have to sort the new view
      context.commit("backgroundLoading");
      await addToQueue(async function () {
        await flexService
          .patchStep(context.getters.courseId, stepId, { view: newViewId })
          .catch((error) => {
            context.commit("backgroundLoaded");
            context.commit("backgroundError");
            context.dispatch("handleFlexEditorError");
            throw error;
          });
        const view = context.getters.views.find(
          (item) => item.id === newViewId,
        );
        const ordering = view.steps.map((item) => item.id);
        if (ordering.length > 1) {
          await flexService
            .orderSteps(context.getters.courseId, newViewId, { ordering })
            .catch((error) => {
              context.commit("backgroundLoaded");
              context.commit("backgroundError");
              context.dispatch("handleFlexEditorError");
              throw error;
            });
        }
      });

      context.dispatch("removeEmptyViews");
      context.commit("backgroundLoaded");
    },
    /**
     * Remove any views that have become empty, this should run after any move step or delete step action,
     * since we don't want to have any empty views
     */
    async removeEmptyViews(context) {
      // find empty views
      const emptyViews = context.getters.views.filter(
        (item) => item.steps.length === 0,
      );
      context.commit("removeViews", emptyViews);
      if (emptyViews.length > 0) {
        context.commit("backgroundLoading");
        await addToQueue(() =>
          Promise.all(
            emptyViews.map((view) =>
              flexService.deleteView(context.getters.courseId, view.id),
            ),
          ).catch((error) => {
            context.commit("backgroundLoaded");
            context.commit("backgroundError");
            context.dispatch("handleFlexEditorError");
            throw error;
          }),
        );

        context.commit("backgroundLoaded");
      }
    },
    async moveViewInLesson(context, { lessonId, oldIndex, newIndex }) {
      context.commit("orderViewsInLesson", { lessonId, oldIndex, newIndex });
      context.commit("backgroundLoading");
      // after ordering locally we make the request to the backend
      const lesson = context.getters.lessons.find(
        (item) => item.id === lessonId,
      );
      const ordering = lesson.views.map((item) => item.id);
      await addToQueue(() =>
        flexService
          .orderViews(context.getters.courseId, lessonId, { ordering })
          .catch((error) => {
            context.commit("backgroundLoaded");
            context.commit("backgroundError");
            context.dispatch("handleFlexEditorError");
            throw error;
          }),
      );

      context.commit("backgroundLoaded");
    },
    async moveViewBetweenLessons(
      context,
      { viewId, oldLessonId, newLessonId, newIndex },
    ) {
      context.commit("moveViewBetweenLessons", {
        viewId,
        oldLessonId,
        newLessonId,
        newIndex,
      });

      // First we tell the backend to move the lesson, then we reorder the views in the lesson
      context.commit("backgroundLoading");
      addToQueue(async function () {
        await flexService
          .patchView(context.getters.courseId, viewId, {
            lessonId: newLessonId,
          })
          .catch((error) => {
            context.commit("backgroundLoaded");
            context.commit("backgroundError");
            context.dispatch("handleFlexEditorError");
            throw error;
          });

        const lesson = context.getters.lessons.find(
          (item) => item.id === newLessonId,
        );
        const ordering = lesson.views.map((item) => item.id);
        await flexService
          .orderViews(context.getters.courseId, newLessonId, { ordering })
          .catch((error) => {
            context.commit("backgroundLoaded");
            context.commit("backgroundError");
            context.dispatch("handleFlexEditorError");
            throw error;
          });
      });
      context.commit("backgroundLoaded");
    },
    async sortLessons(context, { oldIndex, newIndex }) {
      context.commit("sortLessons", { oldIndex, newIndex });
      context.commit("backgroundLoading");
      // after ordering locally we make the request to the backend
      const ordering = context.getters.lessons.map((item) => item.id);
      await addToQueue(() =>
        flexService
          .orderLessons(context.getters.courseId, { ordering })
          .catch((error) => {
            context.commit("backgroundLoaded");
            context.commit("backgroundError");
            context.dispatch("handleFlexEditorError");
            throw error;
          }),
      );
      context.commit("backgroundLoaded");
    },
    async saveChanges(context) {
      context.commit("savingChanges");
      await Promise.all(
        // TODO: remove { ...response.data, id: response.data.id } when backend id is consistent
        context.state.stepPatches.map((patch) =>
          flexService
            .patchStep(context.getters.courseId, patch.id, patch)
            .then((response) => {
              context.commit("mergeStepPatch", {
                ...response.data,
                id: response.data.id,
              });
            })
            .catch((error) => {
              context.commit("errorSavingChanges");
              throw error;
            }),
        ),
        context.state.lessonPatches.map((patch) =>
          flexService
            .patchLesson(context.getters.courseId, patch.id, patch)
            .catch((error) => {
              context.commit("errorSavingChanges");
              throw error;
            }),
        ),
        context.state.viewPatches.map((patch) =>
          flexService
            .patchView(context.getters.courseId, patch.id, patch)
            .catch((error) => {
              context.commit("errorSavingChanges");
              throw error;
            }),
        ),
        Object.keys(context.state.coursePatch).length > 0
          ? flexService.patchCourse(
              context.getters.courseId,
              context.state.coursePatch,
            )
          : undefined,
      );
      context.commit("changesSaved", context.getters.mergedEditorState);
    },
    async uploadVideo(context, { file, stepId, langCode }) {
      const formData = new FormData();
      formData.append("file", file, file.name);
      if (langCode) {
        formData.append("language", langCode);
      }
      const response = await flexService.uploadFlexVideo({
        courseId: context.getters.courseId,
        stepId: stepId ? stepId : context.state.editId,
        formData,
      });
      const url = response.url;
      context.commit("patchStep", { patch: { url } });

      const values = {};
      values[langCode] = url;
      const filename = {};
      filename[langCode] = file.name;
      context.commit("patchBackendTranslation", {
        stepId,
        patch: { key: "url", values, filename },
      });
    },
    async uploadPdf(context, { file, stepId, langCode }) {
      const formData = new FormData();
      formData.append("file", file, file.name);
      if (langCode) {
        formData.append("language", langCode);
      }
      const response = await flexService.uploadFlexPdf({
        courseId: context.getters.courseId,
        stepId: stepId ? stepId : context.state.editId,
        formData,
      });
      const url = response.url;
      context.commit("patchStep", { patch: { url } });

      const values = {};
      values[langCode] = url;
      const filename = {};
      filename[langCode] = file.name;
      context.commit("patchBackendTranslation", {
        stepId,
        patch: { key: "url", values, filename },
      });
    },
    async deleteStep(context, stepId) {
      context.commit("removeStep", stepId);
      context.commit("backgroundLoading");
      await addToQueue(() =>
        flexService
          .deleteStep(context.getters.courseId, stepId)
          .catch((error) => {
            context.commit("backgroundLoaded");
            context.commit("backgroundError");
            context.dispatch("handleFlexEditorError");
            throw error;
          }),
      );

      context.dispatch("removeEmptyViews");
      context.commit("backgroundLoaded");
    },
    async deleteView(context, viewId) {
      context.commit("removeView", viewId);
      context.commit("backgroundLoading");
      await addToQueue(() =>
        flexService
          .deleteView(context.getters.courseId, viewId)
          .catch((error) => {
            context.commit("backgroundLoaded");
            context.commit("backgroundError");
            context.dispatch("handleFlexEditorError");
            throw error;
          }),
      );

      context.commit("backgroundLoaded");
    },
    async deleteLesson(context, lessonId) {
      context.commit("removeLesson", lessonId);
      context.commit("backgroundLoading");
      await addToQueue(() =>
        flexService
          .deleteLesson(context.getters.courseId, lessonId)
          .catch((error) => {
            context.commit("backgroundLoaded");
            context.commit("backgroundError");
            context.dispatch("handleFlexEditorError");
            throw error;
          }),
      );

      context.commit("backgroundLoaded");
    },
    async loadTranslations(context, courseId) {
      context.commit("loadTranslations");
      const response = await flexService
        .getTranslations(courseId)
        .catch((error) => {
          context.commit("backgroundLoaded");
          context.commit("backgroundError");
          context.dispatch("handleFlexEditorError");
          throw error;
        });
      context.commit("translationsLoaded", {
        response: response.data,
        courseId,
      });
    },
    async saveTranslations(context, courseId) {
      context.commit("loadTranslations");
      const patches = context.state.translations.patches.reduce((agg, curr) => {
        const item = agg.find(
          (item) => item.type === curr.type && item.id === curr.id,
        );
        if (item) {
          const existingTranslation = item.translations.find(
            (item) => item.key === curr.key,
          );
          if (existingTranslation) {
            existingTranslation.values[curr.lang] = curr.text;
          } else {
            const values = {};
            values[curr.lang] = curr.text;
            item.translations.push({ key: curr.key, values });
          }
        } else {
          const values = {};
          values[curr.lang] = curr.text;
          agg.push({
            type: curr.type,
            stepType: curr.stepType,
            subType: curr.subType,
            id: curr.id,
            translations: [
              {
                key: curr.key,
                values,
              },
            ],
          });
        }
        return agg;
      }, []);
      await flexService
        .patchTranslations({ courseId, patches })
        .catch((error) => context.commit("translationsError"));
      context.commit("translationsSaved", context.getters.mergedTranslations);
    },
  },
  getters: {
    courseLoadingState: (state) => state.backendState.loadingState,
    course: (state) => state.backendState.course,
    courseId: (state) => state.id,
    lessons: (state) => state.backendState.course.lessons,
    views: (state) =>
      state.backendState.course.lessons.flatMap((lesson) => lesson.views),
    steps: (state) =>
      state.backendState.course.lessons
        .flatMap((lesson) => lesson.views)
        .flatMap((view) => view.steps),
    flexBackgroundLoading: (state) => state.backgroundLoading > 0,
    flexBackgroundError: (state) => state.backgroundError,
    editingType: (state) => state.editType,
    editingId: (state) => state.editId,
    editingStepData: (state, getters) =>
      getters.mergedEditorState.lessons
        .flatMap((lesson) => lesson.views)
        .flatMap((view) => view.steps)
        .find((step) => step.id === state.editId),
    editingStep: (state, getters) =>
      createStep(
        getters.mergedEditorState.lessons
          .flatMap((lesson) => lesson.views)
          .flatMap((view) => view.steps)
          .find((step) => step.id === state.editId),
      ),
    editingView: (state, getters) =>
      getters.mergedEditorState.lessons
        .flatMap((lesson) => lesson.views)
        .find((view) => view.id === state.editId),
    editingLesson: (state, getters) =>
      getters.mergedEditorState.lessons.find(
        (lesson) => lesson.id === state.editId,
      ),
    mergedEditorState: (state) => {
      return {
        ...state.backendState.course,
        ...state.coursePatch,
        lessons: state.backendState.course.lessons.map((lesson) => {
          // merge lesson patches
          const lessonPatch = state.lessonPatches.find(
            (item) => item.id === lesson.id,
          );
          return {
            ...lesson,
            ...lessonPatch,
            // merge view patches
            views: lesson.views.map((view) => {
              const viewPatch = state.viewPatches.find(
                (item) => item.id === view.id,
              );
              return {
                ...view,
                ...viewPatch,
                // deep merge step patches
                steps: view.steps.map((step) => {
                  const stepPatch = state.stepPatches.find(
                    (item) => item.id === step.id,
                  );
                  return _.mergeWith({}, step, stepPatch, _mergeWithoutArray);
                }),
              };
            }),
          };
        }),
      };
    },
    hasChanges: (state) =>
      state.lessonPatches.length > 0 ||
      state.viewPatches.length > 0 ||
      state.stepPatches.length > 0 ||
      Object.keys(state.coursePatch).length > 0,

    translationsLoadingState: (state) => state.translations.loadingState,
    translations: (state) => state.translations.translations,
    translationsDefaultLang: (state) => state.translations.defaultLanguage,
    editorSaveError: (state) => state.errorSavingChanges,
    mergedTranslations: (state) => {
      return state.translations.translations.map((item) => ({
        ...item,
        translations: item.translations.map((translation) => {
          const copy = { ...translation };
          state.translations.patches
            .filter(
              (patch) =>
                item.type === patch.type &&
                item.id === patch.id &&
                copy.key === patch.key,
            )
            .forEach((patch) => (copy.values[patch.lang] = patch.text));
          return copy;
        }),
      }));
    },
    translationHasChanges: (state) => state.translations.patches.length > 0,
  },
};

function _mergeWithoutArray(objValue, srcValue) {
  if (Array.isArray(objValue)) {
    return srcValue;
  }
}
