import getToken from "@/utils/getToken";
import axios from "axios";
import { mapResponse, printError } from "../utils/mapResponse";
import API from "./config";

export default {
  getDocumentUrl(documentId) {
    return `${API.quizzesURL}documents/${documentId}`;
  },
  getUserQuizById(quizId) {
    return axios
      .get(API.quizzesURL + quizId)
      .then(mapResponse)
      .catch(printError);
  },
  getUserLessonById({
    lessonId,
    stepId = undefined,
    fiftyFiftyJoker = false,
    audienceJoker = false,
  }) {
    let path = stepId
      ? `lessons/${lessonId}/steps/${stepId}`
      : `lessons/${lessonId}`;
    path = stepId && fiftyFiftyJoker ? `${path}/fifty-fifty-joker` : path;
    path = stepId && audienceJoker ? `${path}/audience-joker` : path;

    return axios
      .get(`${API.quizzesURL}${path}`)
      .then(mapResponse)
      .catch(printError);
  },
  saveSelection(lessonId, stepId, action) {
    return axios
      .post(
        `${API.quizzesURL}lessons/${lessonId}/steps/${stepId}/selection`,
        action,
      )
      .then(mapResponse)
      .catch(printError);
  },

  // POST_DEFINITION(payload) {
  //   return fetch(`${API.quizzesURL}/definition`, {
  //     method: 'POST',
  //     headers: {
  //       Authorization: getToken(),
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(payload),
  //   })
  //     .then(response => response.json());
  // },
};
