<template lang="pug">
div
  h2 {{ $t("properties") }}
  el-alert(
    v-if="error",
    type="warning",
    :title="$t('genericError')",
    :closable="false"
  )
  el-table(
    v-else,
    v-loading="userTagsLoadingState.loading",
    :data="userTags",
    style="width: 100%"
  )
    el-table-column(:label="$t('property')", prop="valueText")
    el-table-column(:label="$t('type')", prop="tagType")
      template(#default="scope")
        span {{ getTagType(scope.row) }}
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "UserProfileProperties",
  props: {
    modelValue: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["userTagsLoadingState", "tagTypesState"]),
    userId() {
      return this.$store.getters.getUserData.id;
    },
    userTags() {
      return this.userTagsLoadingState.result;
    },
    error() {
      return this.userTagsLoadingState.error || this.tagTypesState.error;
    },
  },
  mounted() {
    this.$store.dispatch("loadTagsForUser", { userId: this.userId });
    this.$store.dispatch("loadTagTypes");
  },
  methods: {
    getTagType(row) {
      const tagType = this.tagTypesState.result.find(
        (item) => item.uuid === row.tagType,
      );
      return tagType ? tagType.name : "";
    },
  },
};
</script>

<style scoped></style>
