import decodeJWT from "@/utils/decodeJWT";
import service from "@/services/auth";
import userService from "@/services/users";
import router from "@/router";
import { saveAs } from "file-saver";
import { mapResponse } from "../utils/mapResponse";
import { i18n } from "../main";
import { unref } from "vue";
import { useRouter } from "vue-router";
import dayjs from "dayjs";

export default {
  state: {
    isAuthenticated: false,
    isInitialPassword: false,
    isWrongCredentials: false,
    // User interaction required is set if the user has to complete an action before being able to continue,
    // This can be accepting new T&C or adding a tag
    userInteractionRequired: false,
    // If userinteraction is being returned from registration or login, the user has to be logged in after successfully completing an action,
    // thus the username and password is cached here in order to be reused for the the interaction/login step
    userInteractionCache: {
      email: "",
      password: "",
      requiredActions: [],
    },
    userData: {},
    usersAccounts: {},
    // NOTE: this is a random id changing whenever the profile picture changes
    // This is used to update any instances of the profile picture
    profilePictureId: 0,
    token: null,
  },
  mutations: {
    initUserAccounts(state, payload) {
      const list = payload.capabilities || [];
      state.usersAccounts = {};
      const usersAccounts = state.usersAccounts;

      list.forEach((item) => {
        const roleId = item.roleId;
        item.ids.forEach((accountId) => {
          if (usersAccounts[accountId] === undefined) {
            usersAccounts[accountId] = [];
          }
          usersAccounts[accountId].push(roleId);
        });
      });
    },
    setToken(state, token) {
      state.token = token;
    },
    setInitialPassowrd(state) {
      state.isInitialPassword = true;
    },
    unsetInitialPassword(state) {
      state.isInitialPassword = false;
    },
    setUserInformation(state, payload) {
      const userData = payload || {};
      state.userData = userData;
      if (userData.id) {
        state.userData.id = userData.id;
      }
    },
    isLogin(state) {
      state.isAuthenticated = true;
    },
    isLogout(state) {
      state.isAuthenticated = false;
      state.userData = {};
    },
    setWrongCredentials(state) {
      state.isWrongCredentials = true;
    },
    unsetWrongCredentials(state) {
      state.isWrongCredentials = false;
    },
    reloadProfilePicture(state) {
      state.profilePictureId = Math.random();
    },
    setUserInteractionRequired(state, payload) {
      state.userInteractionCache = payload;
      state.userInteractionRequired = true;
    },
    cancelUserInteractionRequired(state) {
      state.userInteractionCache = {};
      state.userInteractionRequired = false;
    },
  },
  actions: {
    register({ commit, dispatch }, payload) {
      return service
        .REGISTER(payload)
        .then((data) => {
          try {
            dispatch("updateToken", data);
            dispatch("setUserDataFromToken", data.token);
            return data;
          } catch (err) {
            commit("addNewNotification", {
              title: "error",
              text: "cookiesDisabled",
              notificationType: "error",
            });
            throw err;
          }
        })
        .catch((error) => {
          // NOTE: a 451 Error in this case is actually a SUCCESSFUL response,
          // the user has been registered, but user interaction is required (i.e. adding required tags)
          if (error.response.status === 451) {
            commit("setUserInteractionRequired", {
              email: payload.email,
              password: payload.password,
              requiredActions: error.response.data.actionsRequired,
            });
          } else {
            const message = error.response?.data?.error;
            commit("addNewNotification", {
              title: "error",
              text: message
                ? message
                : i18n.global.availableLocales.t("genericError"),
              textAsIs: true,
              notificationType: "error",
            });
            throw error;
          }
        });
    },
    initUserData({ commit, dispatch }) {
      return new Promise((res, rej) => {
        let tokens = null;
        try {
          const tokens = JSON.parse(localStorage.getItem("tokens"));
          if (!tokens) {
            dispatch("logout");
            return;
          }
          commit("setToken", tokens);
          dispatch("setUserDataFromToken", tokens.token);
          document.cookie = `token=${tokens.token};path=/`;
          localStorage.removeItem("roleId");

          // NOTE: to ensure that the token has a minimum validity time left, we refresh on startup
          dispatch("refreshToken");
        } catch (err) {
          commit("addNewNotification", {
            title: "error",
            text: "cookiesDisabled",
            notificationType: "error",
          });
          console.log("Error ", err);
          dispatch("logout");
          return;
        }
      });
    },
    // NOTE: we refreh the token periodically, see https://github.com/inctec/elearning-portal/issues/1114
    setTokenRefreshTimer({ state, commit, dispatch }) {
      if (state.token) {
        const decodedToken = decodeJWT(state.token.token);
        const remainingSeconds = decodedToken.exp - dayjs().valueOf() / 1000;
        setTimeout(
          () => dispatch("refreshToken"),
          remainingSeconds * 1000 * 0.9,
        );
      }
    },
    updateToken({ commit, dispatch }, tokens) {
      localStorage.setItem("tokens", JSON.stringify(tokens));
      document.cookie = `token=${tokens.token};path=/`;
      commit("setToken", tokens);
      dispatch("setUserDataFromToken", tokens.token);
      dispatch("setTokenRefreshTimer");
    },
    clearLocalStorage() {
      const hrAutomatedLogout = localStorage.getItem("hrAutomatedLogout");
      localStorage.clear();
      if (hrAutomatedLogout !== null) {
        localStorage.setItem("hrAutomatedLogout", hrAutomatedLogout);
      }
    },
    removeToken({ commit, dispatch, state }) {
      dispatch("clearLocalStorage");
      state.token = null;
      // Clear all cookies
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
    },
    forgotPassword({ commit }, payload) {
      return service.FORGOT_PASSWORD(payload).then(
        (data) => {
          commit("addNewNotification", {
            title: "success",
            text: "passwordReset",
            textAsIs: false,
            notificationType: "success",
          });
          return true;
        },
        (error) => {
          if (error.response && error.response.data) {
            if (error.response.data.error.includes("email")) {
              // TODO: await for input on https://github.com/inctec/elearning-services/issues/221
              commit("addNewNotification", {
                title: "error",
                text: error.response.data.error,
                textAsIs: true,
                notificationType: "error",
              });
            } else {
              commit("addNewNotification", {
                title: "error",
                text: error.response.data.error,
                textAsIs: true,
                notificationType: "error",
              });
            }
          } else {
            commit("addNewNotification", {
              title: "error",
              text: "genericError",
              textAsIs: false,
              notificationType: "error",
            });
          }
          return false;
        },
      );
    },
    setNewPassword({ commit }, payload) {
      return service.SET_PASSWORD(payload).then((data) => {
        if (data.error) {
          commit("addNewNotification", {
            title: "error",
            text: data.error,
            textAsIs: true,
            notificationType: "error",
          });
          return false;
        }

        commit("addNewNotification", {
          title: "success",
          text: "passwordChanged",
          notificationType: "success",
        });
        return true;
      });
    },
    setUserDataFromToken({ commit, dispatch }, accessToken) {
      if (!accessToken) {
        return;
      }
      const tempResult = decodeJWT(accessToken);
      // Set the language accordingly
      const langCode = tempResult.attributes.langCode;
      if (langCode) {
        i18n.global.locale.value = langCode;
        document.documentElement.setAttribute("lang", langCode);
      }
      commit("setUserInformation", tempResult);
      dispatch("initUsersRoles", tempResult);
      commit("initUserAccounts", tempResult);
      dispatch("initCapabilities");
      commit("isLogin");
    },
    login({ commit, dispatch }, payload) {
      commit("unsetWrongCredentials");
      return service
        .POST(payload)
        .then((response) => {
          try {
            const tokens = response.data;
            dispatch("updateToken", tokens);
            dispatch("setUserDataFromToken", tokens.token);
            dispatch("hardwareRestrictions/updateLocalKeyCapabilitites");
          } catch (err) {
            console.log(err);
            commit("addNewNotification", {
              title: "error",
              text: "cookiesDisabled",
              notificationType: "error",
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            const response = error.response;
            if (response.status === 451) {
              console.log(error);
              // the user has been registered, but user interaction is required (i.e. adding required tags)
              commit("setUserInteractionRequired", {
                email: payload.email,
                password: payload.password,
                requiredActions: error.response.data.actionsRequired,
              });
            } else if (response.data && response.data.error) {
              commit("setWrongCredentials");
              commit("isLogout");
            } else {
              commit("addNewNotification", {
                title: "error",
                text: "genericError",
                notificationType: "error",
              });
              commit("isLogout");
            }
          }
          throw error;
        });
    },

    updateProfile({ commit, dispatch, getters }, payload) {
      const result = {
        ...payload,
        id: getters.getCurrentUserId,
      };
      return service.PATCH(result).then((data) => {
        commit("setUserInformation", data);
        dispatch("initUsersRoles", data);
        commit("initUserAccounts", data);
      });
    },
    async refreshToken({ getters, dispatch, commit }) {
      if (getters.token === null) {
        // User not logged in
        dispatch("logout");
      }
      const response = await service
        .refreshToken(getters.token.refresh)
        .catch((error) => {
          dispatch("logout");
          console.error("logout", error);
          throw error;
        });
      dispatch("updateToken", response.data);
      return response;
    },
    updateUserInformation({ commit, dispatch, getters }, payload) {
      const result = {
        ...payload,
        id: getters.getCurrentUserId,
      };
      return service
        .PATCH_ME(result)
        .then((response) => {
          dispatch("refreshToken");
        })
        .catch((error) => {
          commit("addNewNotification", {
            title: "error",
            text: error,
            notificationType: "error",
          });
          throw error;
        });
    },
    async deleteProfilePicture({ state, commit }) {
      return userService
        .DELETE_PROFILE_PICTURE(state.userData.id)
        .then(() => commit("reloadProfilePicture"));
    },
    logout({ commit, dispatch }) {
      dispatch("flushAllCourses");
      dispatch("removeToken");
      dispatch("clearLocalStorage");
      commit("isLogout");
      router.isReady().then(async () => {
        if (!unref(router.currentRoute).meta?.public) {
          const routeResult = await router.push({ name: "Login" }).then(() => {
            // NOTE: we force a hard reload here to make sure that any data in the store is wiped
            // re-routing might fail even though there is no error thrown. To prevent a reload loop, we only reload on public endpoints
            if (unref(router.currentRoute.meta?.public)) {
              window.location.reload();
            }
          });
        }
      });
    },
    updatePassword({ getters }, payload) {
      const userId = getters.getCurrentUserId;
      const result = {
        ...payload,
        userId,
      };
      return service.UPDATE_PASSWORD(result);
    },
  },
  getters: {
    getUserData: (state) => state.userData || {},
    getUserLangCode: (state) => {
      const userData = state.userData || {};
      const attrs = userData.attributes || {};
      if (attrs.langCode) {
        const lang = attrs.langCode.toLowerCase();
        return lang;
      }
      return "DE";
    },
    getCurrentUserAccountId: (state) => {
      const userData = state.userData || {};
      const capabilities = userData.capabilities;
      // getting the account where the user has the role User
      // if no User role found, return 0
      if (capabilities && capabilities.length > 0) {
        const userCaps = capabilities.filter((c) => c.roleId === 4);
        // there is only one entry per role in the capabilities
        if (
          userCaps.length > 0 &&
          userCaps[0].ids &&
          userCaps[0].ids.length > 0
        ) {
          // there must be one single account where the user has the role User
          return userCaps[0].ids[0];
        }
      }
      return 0;
    },
    isAuthenticated: (state) => state.isAuthenticated,
    getCapabilites: (state) => state.usersAccounts,
    getCurrentUserId: (state) => state.userData.id,
    getCurrentUsername: (state) => {
      let username = "__";
      const userdata = state.userData;
      if (userdata && userdata.username) {
        username = userdata.username;
      } else if (userdata && userdata.email) {
        username = userdata.email;
      }
      return username;
    },
    getUserAccounts: (state) => {
      const ids = Object.keys(state.usersAccounts);
      return ids.map((item) => +item);
    },
    token: (state) => state.token,
    userInteractionRequired: (state) => state.userInteractionRequired,
    userInteractionCache: (state) => state.userInteractionCache,
    getCurrentUserData: (state) => state.userData,
    profilePictureId: (state) => state.profilePictureId,
  },
};
