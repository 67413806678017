import getToken from "@/utils/getToken";
import API from "./config";
import { mapResponse, printError } from "../utils/mapResponse";
import axios from "axios";

export default {
  GET_STATS: (accountId) =>
    axios
      .get(`${API.reportingServices}/${accountId}/stats`)
      .then(mapResponse)
      .catch(printError),

  GET_COMPLETED_COURSES: ({ accountId, startDate, endDate, binSize }) => {
    // TODO: accountId 0
    // if (!accountId || !startDate || !endDate || !binSize) {
    //  throw Error();
    //}

    return axios
      .get(
        `${API.reportingServices}/courses/completed?${new URLSearchParams({ accountId, startDate, endDate, binSize })}`,
      )
      .then(mapResponse)
      .catch(printError);
  },

  GET_EXECUTION_TIME: ({ accountId }) => {
    return axios
      .get(
        `${API.reportingServices}/courses/executionTime?${new URLSearchParams({ accountId })}`,
      )
      .then(mapResponse)
      .catch(printError);
  },

  GET_COMPLETION_LEVEL: ({ accountId }) => {
    return axios
      .get(
        `${API.reportingServices}/courses/completionLevel?${new URLSearchParams({ accountId })}`,
      )
      .then(mapResponse)
      .catch(printError);
  },
};
