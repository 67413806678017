import axios from "axios";
import api from "./config";

export default {
  getRestrictionKeys() {
    return axios.get(`${api.baseUrlV2}hardware-restrictions/keys`);
  },
  postRestrictionKey(key) {
    return axios.post(`${api.baseUrlV2}hardware-restrictions/keys`, key);
  },
  deleteRestrictionKey(id) {
    return axios.delete(`${api.baseUrlV2}hardware-restrictions/keys/${id}`);
  },
  patchRestrictionKey(key) {
    return axios.patch(
      `${api.baseUrlV2}hardware-restrictions/keys/${key.id}`,
      key,
    );
  },
  getRestrictionKeyCapabilities(id) {
    return axios.get(
      `${api.baseUrlV2}hardware-restrictions/keys/${id}/capabilities`,
    );
  },
};
