<template lang="pug">
.user-action-list
  .user-action-list__item(@click="openProfile") {{ $t("userProfile") }}
  .user-action-list__item(@click="logout") {{ $t("exit") }}
</template>

<script>
export default {
  name: "UserActionList",
  components: {},
  methods: {
    openProfile() {
      this.$emit("openProfile");
      return true;
    },
    openProfileV2() {
      this.$emit("openProfileV2");
      return true;
    },
    pickLang(lang = "en") {
      this.$i18n.locale = lang;
    },
    logout() {
      this.$store.dispatch("logout");
      return true;
    },
  },
};
</script>
<style lang="stylus">
@import '../../settings.styl';

.user-action-list {
  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    height: 30px;
    font-size: 14px;

    &:hover {
      background: $backgroundInactive;
    }
  }
}
</style>
