<template>
  <div class="notification" :class="notificationType">
    <i v-if="notificationType == 'warning'" class="material-icons pre-icon"
      >error</i
    >
    <i v-else-if="notificationType == 'error'" class="material-icons pre-icon"
      >do_not_disturb</i
    >
    <i v-else-if="notificationType == 'info'" class="material-icons pre-icon"
      >info_outline</i
    >
    <i v-else-if="notificationType == 'success'" class="material-icons pre-icon"
      >check</i
    >
    <i v-else class="pre-icon" />
    <i class="material-icons close" @click="deleteNotification">close</i>
    <div class="content" :class="{ cutted: isLongText }">
      <h5 class="title">
        {{ $t(title) }}
      </h5>
      <p v-if="textAsIs" class="text">
        {{ text }}
      </p>
      <p v-else class="text">
        {{ $t(text) }}
      </p>
      <div v-if="isLongText" class="dots" />
    </div>
  </div>
</template>
<script>
export default {
  name: "NotificationComponent",
  props: {
    id: {
      type: Number,
      required: true,
    },
    notificationType: {
      type: String,
      default: "info",
    },
    title: {
      type: String,
    },
    text: {
      type: String,
      required: true,
    },
    textAsIs: {
      type: Boolean,
      required: false,
    },
    timeout: {
      type: Number,
      default: 5000,
    },
    isHiding: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isModalShowed: false,
    };
  },
  computed: {
    isLongText() {
      return this.text.length > 160;
    },
  },
  created() {
    if (this.isHiding) {
      this.hideNotification();
    }
  },
  methods: {
    hideModal() {
      this.isModalShowed = false;
    },
    showModal() {
      this.isModalShowed = true;
    },
    hideNotification() {
      setTimeout(() => {
        this.deleteNotification();
      }, this.timeout);
    },
    deleteNotification() {
      this.$store.commit("deleteNotification", this.id);
    },
  },
};
</script>
<style lang="stylus">
@import '../../../settings.styl';

.notification {
  position: relative;
  display: flex;
  width: 300px;
  padding: 10px 15px 25px 5px;
  margin: 10px 5px;
  border-radius: 6px;
  background-color: $background;
  border: 1px solid $border;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
  border-left: 5px solid $border;

  .title {
    margin: 0 0 6px 0;
  }

  .pre-icon {
    flex: 0 0;
    width: 40px;
    font-size: 28px;
    color: $txt;
    margin: 0 5px;
  }

  .content {
    flex: 1 0;
    overflow: auto;

    .more {
      display: block;
      margin-top: 14px;
      font-size: $txt-small;
      text-align: right;
      text-transform: uppercase;
      transition: all 0.4s;
      cursor: pointer;

      &:hover {
        color: black;
      }
    }

    &.cutted {
      overflow: hidden;
    }
  }

  &.error {
    border-left: 5px solid #f25750;

    .pre-icon {
      color: #f25750;
    }
  }

  &.warning {
    border-left: 5px solid #fea500;

    .pre-icon {
      color: #fea500;
    }
  }

  &.success {
    border-left: 5px solid #7fca15;

    .pre-icon {
      color: #7fca15;
    }
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: black;
    opacity: 0.5;
    cursor: pointer;
    transition: all 0.4s;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
