export default {
  termsAccept: "{0} tarafımdan kabul edilmiştir.",
  tos: "Genel işlem şartları",
  privacyAccept: "{0} tarafımdan kabul edilmiştir.",
  privacyPolicy: "Veri koruma beyanı",
  resetPassword: "Parolayı sıfırla",
  usernamePasswordRequired: "Lütfen bir kullanıcı adı ve parola girin.",
  passwordTooShort: "Parola en az 8 karakter uzunluğunda olmalıdır.",
  wrongCredentials: "Girilen verilere sahip bir hesap bulunamadı.",
  accountStructureItem: "Bölüm",
  addRole: "Rol ekle",
  allUserRelatedDeleteText:
    "Bunu yaptığınızda kullanıcıyla ilgili tüm veriler geri alınamayacak şekilde silinir.",
  assignRoleModals: "Rol tayin et",
  canBeDownloadedText: "Tamamlandı altında indirilebilir",
  changePassword: "Parolayı değiştir",
  changingPasswordFor: "Kullanıcı için parolayı değiştir",
  confirmDeleteText: "Lütfen onaylamak için kullanıcı adını girin:",
  confirmDeteleBtnText: "Anladım, kullanıcıyı sil",
  confirmPassword: "Parolayı tekrarla",
  createAccount: "Hesap oluştur",
  createUser: "Kullanıcı oluştur",
  currentPassword: "Güncel parola",
  customId: "Kullanıcı tanımlı kimlik",
  dataPrivacyHeader: "Veri mahremiyeti",
  dataPrivacyText: "Bölüm metni (içerik takip eder)",
  dataPrivacyTitle: "Bölüm başlığı",
  dataRelatedToMe: "Benim hakkımda kayıtlı verilere bak",
  dataRelatedToMeTitle: "Kayıtlı veriler",
  deleteText: "Bunu silmek istediğinizden emin misiniz",
  downloadDataRelatedToMe: "Kayıtlı verileri indir",
  fileWithUserInformation: "Kullanıcı bilgilerini içeren dosya",
  forgotPassword: "Parolayı mı unuttunuz?",
  forgotPasswordText:
    "Parolanız sıfırlama linki almak için e-posta adresinizi girin.",
  importUsers: "Kullanıcıyı içe aktar",
  newHere: "Burada yeni misiniz?",
  newPassowrd: "Yeni parola",
  newPasswordShort: "Yeni parola çok kısa",
  password: "Parola",
  passwordCantBeEmpty: "Parola boş olamaz",
  passwordsNotMatch: "Parolalar birbiriyle uyuşmuyor",
  pickUser: "Bir kullanıcı seçin",
  pleaseFillUpAllFields: "Lütfen tüm alanları doldurun",
  pleaseTypeNewPassword: "Lütfen yeni parolanızı girin",
  policyAcceptance: "Yönergelerin kabulü",
  policyAcceptanceSave: "Kabul et",
  acceptPolicies: "Lütfen yönergeleri kabul edin",
  profile: "Profil",
  role: "Rol",
  roleExpiresOn: "Rol süresinin dolma tarihi",
  saveDataAboutMe: "Benim hakkımda kayıtlı veriler",
  signIn: "Oturum aç",
  termsConditionsHeader: "Genel ticaret koşulları",
  termsConditionsText: "Bölüm metni (içerik takip eder)",
  termsConditionsTitle: "Bölüm başlığı",
  typeInOne: "Hepsi için bir tane girin",
  username: "Kullanıcı adı",
  firstname: "Ad",
  lastname: "Soyadı",
  usernameMail: "Kullanıcı adı veya e-posta",
  userNotes: "Notlar",
  userProfile: "Kullanıcı profili",
  userStatus: "Kullanıcı durumu",
  wrongCurrentPassword: "Yanlış güncel parola",
  fillAllFields: "Lütfen zorunlu tüm alanları doldurun",
  wrongUsernameOrPassword: "Kullanıcı adı veya parola yanlış",
  minimumLength: "En az uzunluk 8",
  atLeastOneBigAndSmall: "En az 1 küçük ve 1 büyük harf",
  passwordNotEqualUsernameAndEmail:
    "Parola kullanıcı adı ve e-posta adresiyle uyuşmuyor",
  registrationKey: "Kayıt anahtarı",
  registration: "Kayıt",
  notAnEmailAdress: "Lütfen geçerli e-posta adresi girin.",
  company: "şirket",
  companyHint: "şirket",
  birthday: "Doğum günü",
  birthdayHint: "Doğum günü",
  accountName: "Hesap adı",
  accountType: "Hesap türü",
  accountAddress: "Hesap sahibinin adresi",
  logo: "Logo",
  address: "Adres",
  contry: "Ülke",
  zipCode: "Posta kodu",
  lessThan: "1 MB den küçük",
  defaultLanguage: "varsayılan dil",
  accountStatus: "Hesap durumu",
  accountNotes: "Hesap notları",
  createAccount: "Hesap oluştur",
  updateAccount: "Hesabı güncelle",
  fileToImport: "Hesap notları",
  importStructure: "Yapıyı içe aktar",
  addRootAccount: "Temel düzeyde hesap ekle",
  usersPerMonth: "Aylık kullanıcı",
  usersAdded: "Eklenen kullanıcı",
  usersDeleted: "Silinen kullanıcı",
  usersTotal: "Toplum kullanıcı",
  city: "Kent",

  knowledge: "BİLGİ",
  toolbox: "ALET KUTUSU",
  lessonCompleted: "Ünite tamamlandı",
  lessonFailed: "Ünite tamamlanmadı",
  repeatLesson: "Devam etmek için lütfen üniteyi tamamlayın",
  congratulations: "Tebrikler!",
  courseCompleted: "Kurs tamamlandı",
  percentCompleted: "Soruları %$successRate oranında doğru cevapladınız.",
  downloadCertificate:
    'Sertifikanızı dilediğiniz zaman profilinizdeki "Sertifikalar" bölümünden indirebilir ve e-posta aracılığıyla talep edebilirsiniz.',
  trueFalse: "Doğru mu yanlış mı?",
  seconds: "Saniye",
  noChoiceText: "Maalesef çok geç.",
  right: "Doğru!",
  wrong: "Doğru değil",
  progress: "$current/$total",

  addCourseContent: "Kurs içerikleri ekle",
  addSemicolon: "Noktalı virgülle ayırarak etiketler girin",
  addToProgram: "Programa ekle",
  allAccounts: "Tüm hesaplar",
  certificate: "Sertifika",
  certificates: "Sertifikalar",
  completed: "Tamamlandı",
  contentIsLoaded: "İçerik yüklendi",
  contentManager: "İçerik yöneticisi",
  courseCompleted: "Kurs tamamlandı",
  courseContent: "Kurs içeriği",
  courseDocuments: "Evraklar",
  courseLables: "Kurs etiketleri",
  courseLanguage: "Kurs dili",
  courseNotes: "Kurs notları",
  courseStructure: "Yapı",
  courseTags: "Kurs etiketleri",
  courseTitle: "Kurs adı",
  createCourse: "Kurs oluştur",
  curator: "SORUMLU",
  description: "Tanım",
  details: "Ayrıntılar",
  dueDate: "Sona erme tarihi",
  duration: "Süre",
  exportStatistics: "İstatistikleri dışarı aktar",
  learningStandard: "Öğrenim standardı",
  licenses: "Lisanslar",
  mandatory: "Zorunlu kurs",
  noAssessment: "Halihazırda incelenecek değerlendirme yok.",
  noCoursesAssigned: "Hesabınıza tayin edilmiş kurs yok. Lütfen ziyaret edin",
  noCoursesAvailable: "Halihazırda mevcut kurs yok.",
  noDueDate: "Sona erme tarihi yok",
  overview: "GENEL BAKIŞ",
  page: "",
  pickAnAccount: "Bir hesap seç",
  printCertificate: "Sertifikayı yazdır",
  publishCourse: "Kursu yayınla",
  publishedToAccount: "Hesapta yayınlandı",
  relatedCourses: "Benzer kurslar",
  remove: "Kaldır",
  removeFromProgram: "Programdan kaldır",
  singleAccount: "Münferit hesap",
  startCourse: "Başlat",
  successMessageOne: "Tebrikler, kursu ",
  successMessageThree:
    "Artık kurs bitirme sertifikanızı indirebilir ya da yazdırabilirsiniz. ",
  successMessageTwo: " başarıyla tamamladınız!",
  tags: "Anahtar kelimeler",
  unpublishCourse: "Yayını kaldır",
  updateCourseContent: "Kurs içerikleri yükle",
  updateCourse: "Kursu güncelle",
  youDidntfinishCourse: "Henüz bir kurs tamamlamadınız.",
  courseVisibleFor: "Kurs şunun için görülebilir",
  accountStructureItem: "Bölüm",
  courseAssigmentOptions: "Tayin seçenekleri",
  assignCourse: "Kurs tayin et",
  pickACourse: "Bir kurs seç",

  noDocumentsAvailable: "Evrak mevcut değil",
  nothingRelated: "Benzer bir şey bulunamadı",
  nothingWritten: "Hiçbir şey girilmedi",
  noTagsAvailable: "Anahtar kelimeler mevcut değil",
  alreadyGlobal: "Kurs önceden dünya genelinde yayınlandı.",
  questionsanswered: "Sorular",
  completedOn: "Tamamlanma tarihi",
  locale: "tr-TR",
  quizAuthorizationContent: "Test yetkilendirmesi",

  courseNotPublished: "Kurs halihazırda yayınlanmadı",
  courseNotPublishedForAccount:
    "Kurs halihazırda $account hesabı için yayınlanmadı",
  clickToPublishCourse: "Kursu yayınla",
  visible: "Görünür",
  userIsNotContentManager:
    "İçerik yöneticisi değilsiniz ve bu yüzden kurs içeriğini düzenleyemezsiniz.",
  assignmentOpen: "Açık",
  assignmentStarted: "Başlatıldı",
  assignmentCompleted: "Tamamlandı",
  dueDatePassed: "Sona erme tarihi aşıldı",

  exitCourse: "Kursu tamamla",
  markAsFinished: "Düzenlendi olarak işaretle",

  system: "Sistem",
  configureLogging: "Günlüğü yapılandır",
  createAccount: "Hesap oluştur",
  changeAccount: "Hesabı değiştir",
  deleteAccount: "Hesabı sil",
  importAccountStructure: "Hesap yapısını içe aktar",
  createUser: "Kullanıcı oluştur",
  importUser: "Kullanıcıyı içe aktar",
  changeUser: "Kullanıcıyı değiştir",
  deleteUser: "Kullanıcıyı sil",
  assignUserRole: "Rol tayin et",
  resetPassword: "Parolayı sıfırla",

  createCourse: "Kurs oluştur",
  changeCourse: "Kursu değiştir",
  addQuizContent: "Test içeriği ekle",
  changeQuizContent: "Test içeriğini değiştir",
  addContent: "Kurs içeriği ekle",
  changeContent: "Kurs içeriğini değiştir",
  deleteCourse: "Kursu sil",
  deleteContent: "Kurs içeriğini sil",
  publishCourse: "Kursu yayınla",
  unpublishCourse: "Yayından Kaldır",
  assignCourse: "Kurs tayin et",
  unassignCourse: "Kurs tayinini kaldır",

  systemMessages: "Sistem mesajları",

  learningManagement: "Öğrenim yönetimi",
  courseManagement: "Kurs yönetimi",
  userManagement: "Kullanıcı yönetimi",
  organizationStructure: "Organizasyon yapısı",
  accountManagement: "Hesap yönetimi",
  system: "Sistem",

  learningManagementDetail: "Öğrenim planları ve kurs tayini",
  courseManagementDetail: "Kurs içeriklerini yönet",
  userManagementDetail: "Kullanıcıları yönet ve parolaları sıfırla",
  organizationStructureDetail: "Organizasyon yapısı tanımla",
  accountManagementDetail: "Hesapları yönet",
  logsDetail: "Sistem günlükleri ve ayarlar",

  userCreateSuccess: "Kullanıcı başarıyla oluşturuldu",
  createOtherUser: "Başka bir kullanıcı ekle",
  backToUserOverview: "Kullanıcıya genel bakışa dön",
  importMoreUsers: "Başka kullanıcı içe aktar",
  currentRoles: "Güncel roller",
  numberOfRoles: "Rol sayısı",
  numberOfUsers: "Kullanıcı",
  deleteAccountMessage:
    "Lütfen '$account' hesabını silmek istediğinizi onaylayın.",
  showStatistics: "İstatistikleri görüntüle",
  appendAccount: "Hesaba bir $account ekle.",

  courseAssignments: "Dağıtıldı",
  courseAssignmentsForCourse: "$course için kurs tayinleri",
  mandatoryAssignments: "Zorunlu kurs tayinleri",
  dueDateAssignments: "Süreli",
  dueDatePassed: "Süresi dolmuş",
  userOrAccount: "Kullanıcı veya bölüm",
  expiryDate: "Son tarih",
  deletePublication: "Yayını sil",
  deletePublicationMessage:
    "$account hesabı için yayını silmek istediğinizden emin misiniz?",
  orgStructure: "Bölüm",
  type: "Tip",
  downloadTemplate: "Şablon indir",
  published: "Yayınlandı",
  scorm: "SCORM",
  quiz: "Test",
  staticFile: "Pdf",
  editPublication: "Yayını düzenle",
  pleaseDefineCourseContent:
    "please define the course content before publishing it.",

  usernameMissing: "Kullanıcı adı eksik",
  usernameError: "Kullanıcı adı sadece alfanümerik karakterlerden oluşabilir",
  emailMissing: "E-posta eksik",
  emailError: "Geçerli e-posta adresi yok",
  passwordMissing: "Parola eksik",
  passwordError:
    "Parola en az 8 karakter uzunluğunda olmalı ve büyük ve küçük harf içermelidir.",

  registrationKeyManagement: "Kayıt anahtarı",
  registrationKeyManagementDetail: "Kayıt anahtarları yönetimi",
  addRegistrationKey: "Kayıt anahtarı ekle",
  registrationLink: "Kayıt bağlantısı",
  createKey: "Ekle",

  userState: "Kullanıcı Durumu",
  userStateDetail: "Erişim izni ve zorunlu derslerin durumu.",
  testsPassed: "Testler geçti",
  access: "Giriş",
  downloadReport: "Raporu İndir",
  success: "Başarıyla kaydedildi!",
  warning: "Dikkat!",
  error: "Hata!",
  removedFromProgram: "Bu kurs programınızdan çıkarıldı",
  addedToProgram: "Bu kurs programınıza eklendi",
  courseCreated: "Kurs oluşturuldu",
  courseNotCreated: "Kurs oluşturulmadı",
  fillUpFields: "Lütfen tüm zorunlu alanları dolduruldu",
  cookieNotAvailable:
    "Oturum açma bilgileriniz mevcut değil veya çok eski, lütfen yeniden oturum açın",
  courseNotFinished: "Kurs için tamamlama bilgileri mevcut değil",
  courseAborted: "Kurs iptal edildi",
  correctAnswer: "Doğru cevap",
  wrongAnswer: "Yanlış cevap",
  courseStarted: "Kurs başlatıldı",
  testsPassed: "Test başarılı oldu",
  testFailed: "Test başarılı olmadı",
  courseCompleted: "Kurs başarıyla tamamlandı",
  imageTooLarge: "Dosya 2 MB den küçük olmalı",
  wrongFormat: "Yanlış format",
  passwordReset:
    "Parola sıfırlama bağlantısı belirtilen e-posta adresine gönderildi",
  passwordChanged: "Parola değiştirildi",
  accountAdded: "Hesap eklendi",
  accountUpdated: "Hesap güncellendi",
  accountDeleted: "Hesap silindi",
  userImported: "Kullanıcılar içe aktarıldı",
  userNotImported: "Kullanıcılar içe aktarılmadı",
  loggingConfigSaved: "Günlük yapılandırması kaydedildi",
  userDeleted: "Kullanıcı silindi",
  userAdded: "Kullanıcı oluşturuldu",
  userUpdated: "Kullanıcı güncellendi",
  GDPRLoaded: "GVKY yüklendi",
  cookiesDisabled: "Çerezleri devre dışı bıraktınız",
  wrongCredentials: "Yanlış oturum açma bilgileri",
  fileUploaded: "Dosyanız yüklendi",
  courseContentUpdated: "Kurs içeriği güncellendi",
  coursePublished: "Kurs yayınlandı",
  courseUnPublished: "Kursun yayını kaldırıldı.",
  serverUnreachable: "Sunucuya ulaşılamıyor",
  selectAccounts: "Hesap seç",
  selectAccountToContinue: "Devam etmek için hesap seç",
  accountOverview: "Hesaba genel bakış",
  users: "Kullanıcı",
  courses: "Kurslar",
  coursesCompleted: "Tamamlanmış kurslar",
  completedCoursesPerUser: "Her kullanıcı için tamamlanmış kurslar",
  learningHours: "Öğrenim saatleri",
  learningHoursPerUser: "Her kullanıcı için öğrenim saatleri",
  courseOverview: "Kursa genel bakış",
  completed: "Tamamlandı",
  assigned: "Tayin edildi",
  courseDetails: "Ayrıntılar",
  selectCourse: "Kurs seç",
  details: "Seç",
  type: "Tip",
  dueDate: "Bitiş",
  mandatory: "Zorunlu kurs",
  completionRate: "Tamamlama oranı",
  averageScore: "Test sonucu",
  duration: "Süre",
  averageDuration: "Ortalama süre",
  account: "Hesap",
  accountName: "Hesap adı",
  accounts: "Hesaplar",
  active: "Etkin",
  admin: "Yönetici",
  all: "Tüm",
  not_found: "Sayfa mevcut değil",
  assessmentStatistics: "Değerlendirme istatistikleri",
  assignedUsersOnly: "Sadece tayin edilmiş kullanıcılar",
  autoIndividual: "Otomatik, bireysel",
  cancel: "İptal et",
  cardImage: "Harita görüntüsü",
  close: "Kapat",
  completed: "Tamamlandı",
  confirm: "Onayla",
  country: "Ülke",
  course: "Kurslar",
  courseDescription: "Kurs tanımı",
  courseDocuments: "Kurs evrakları",
  courses: "Kütüphane",
  courseStructure: "Kurs yapısı",
  dataProtection: "Veri gizliliği",
  defaultLanguage: "Standart dil",
  deleteText: "Bunu silmek istediğinizden emin misiniz",
  delete: "Sil",
  details: "Ayrıntılar",
  dragAndDrop: "Sürükle ve bırak / Eklemek için tıkla",
  dueDate: "Süre dolma tarihi",
  email: "E-posta",
  empty: "Boş",
  exit: "Oturumu kapat",
  expiresOn: "Süre dolma tarihi",
  fileImport: "İçe aktarılacak dosya",
  image: "Fotoğraf",
  inactive: "Etkin değil",
  language: "Dil",
  logo: "Logo",
  mandatory: "Zorunlu kurs",
  monthsLabels: [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ],
  myAccount: "Hesabım",
  myProfile: "Profilim",
  myProgram: "Programım",
  myRoles: "Rollerim",
  never: "Asla",
  no: "Hayır",
  none: "Yok",
  notFound: "Bulunamadı",
  phoneNumber: "Telefon numarası",
  playImage: "Fotoğrafı göster",
  publishingExpiresOn: "Yayın süresinin dolma tarihi",
  publishTo: "Şurada yayınla",
  reports: "Rapor",
  save: "Kaydet",
  search: "Ara...",
  status: "Durum",
  structureItem: "Bölüm",
  termsAndConditions: "İşlem şartları",
  unlimited: "Sınırsız",
  update: "Güncelle",
  user: "Kullanıcı",
  username: "Kullanıcı adı",
  users: "kullanıcılar",
  yes: "Evet",
  zipCode: "Posta kodu",

  info: "Bilgi",
  error: "Hata",
  alert: "Alarm",
  criticalAlert: "Kritik alarm",
  navigation: "Gezinme",
  system: "Sistem",
  note: "Not",
  userProfile: "Kullanıcı profili",

  userId: "Kullanıcı kimliği",
  customId: "",
  organizationalStructure: "Organizasyon yapısı",
  created: "Oluşturulma tarihi",
  new: "Yeni",
  noResults: "Sonuç yok",
  noData: "Veri yok",
  uploadFile: "Dosya yükle",
  id: "Kimlik",
  name: "Ad",
  append: "Ekle",
  edit: "Düzenle",
  format: "Format",
  minutes: "Dakika",
  hours: "Saat",
  date: "Tarih",
  message: "Mesaj",
  fax: "Faks",

  genericError:
    "Bir hata oluştu, sayfayı yeniden yüklemeyi deneyin. Hata devam ederse, sistem yöneticisine başvurun.",
  action: "Eylem",

  uploadedCertificates: "Yüklenen Sertifikalar",

  add_mandatory_user_property: "Lütfen işiniz için aşağıdaki özellikleri seçin",
  help: "Yardım",
  alreadyRegistered: "Zaten kayıtlı",
  additionalDetails: "Ek Bilgiler",
  additionalDetailsSave: "Onaylamak",
  dragAndDropCertificate:
    "PDF dosyasını buraya sürükleyip bırakın veya eklemek için tıklayın",
  coverPhoto: "Cover Photo",
  EditCoverPhoto: "Edit Cover Photo",
  AddCoverPhoto: "Add Cover Photo",
  additionalDocuments: "Additional Documents",
  editAdditionalDocuments: "Edit Additional Documents",
  addAdditionalDocuments: "Add Additional Documents",
  fileName: "File name",
  fileSize: "File size",
  systemLogs: "System Logs",
  systemConfigurationDetail: "Configure system settings",
  systemConfiguration: "System configuration",
  courseFormat: "Course formats",
  views: "Views",
  apis: "APIs",
  viewsDescription: "Visibility of the element in the respective view",
  courseFormatDescription: "Available course formats for defining the course",
  accessCard: "Access card",
  downloadCertification: "Download Certification",
  userProfileV2: "User profile V2",
  certification: "Certification",
  logs: "Logs",

  issuedBy: "Issued by",
  issuedOn: "Issued on",
  fulfilledInPercent: "Fulfilled in percent",

  logout: "Çıkış yap",
  continue: "Devam et",
  courseFinishedDoYouWantToLogout:
    "Kurs tamamlandı. Çıkış yapmak mı yoksa devam etmek mi istiyorsunuz? {seconds} saniye içinde otomatik olarak çıkış yapılacaktır.",
  stateless: "Vatansız",
};
