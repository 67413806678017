<template lang="pug">
.input-date-step
  .ql-editor.text-container.mb-1(v-if="data.text", v-html="data.text")
  .flex-form-item
    label.ql-editor.flex-form-label(:for="step.id", v-html="data.label") 
    .flex-form-input
      el-date-picker(
        :id="step.id",
        v-model="date",
        :type="dateType",
        :disabled-date="disabledDate"
      )
</template>

<script>
import stepMixin from "./stepMixin";
import dayjs from "dayjs";

export default {
  components: {},
  mixins: [stepMixin],
  data: function () {
    return {
      date: "",
    };
  },
  computed: {
    dateType() {
      return this.data.inputType ? this.data.inputType : "date";
    },
  },
  watch: {
    date(date) {
      if (this.date !== "") {
        this.sendAnswer({ date });
      }
    },
  },
  mounted() {
    if (this.data.feedback && this.data.feedback.length > 0) {
      this.date = this.data.feedback[this.data.feedback.length - 1].date;
    }
  },
  methods: {
    disabledDate(date) {
      let isDisabled = false;
      if (this.data.minDate !== null) {
        isDisabled = !dayjs(this.data.minDate).isBefore(dayjs(date));
      }
      if (this.data.maxDate !== null) {
        isDisabled =
          isDisabled || !dayjs(this.data.maxDate).isAfter(dayjs(date));
      }
      return isDisabled;
    },
  },
};
</script>

<style></style>
