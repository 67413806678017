import roles from "@/utils/roles";

export default {
  methods: {
    hasScope(name) {
      return this.$store.getters.checkCapability(name);
    },
    hasRole(role) {
      return this.$store.getters.getCurrentUsersRolesIds.find(
        (r) => r === role,
      );
    },
  },
  computed: {
    isAnyAdmin() {
      return (
        this.$store.getters.getCurrentUsersRolesIds.find(
          (role) => role === roles.admin || role === roles.global_admin,
        ) || false
      );
    },
    isAdmin() {
      return (
        this.$store.getters.getCurrentUsersRolesIds.find(
          (role) => role === roles.admin,
        ) || false
      );
    },
    isGlobalAdmin() {
      return (
        this.$store.getters.getCurrentUsersRolesIds.find(
          (role) => role === roles.global_admin,
        ) || false
      );
    },
    userAccounts() {
      const caps = this.$store.getters.getCapabilites || {};
      return this.$store.getters
        .getAccountByCapabilities(caps)
        .filter((account) => account.id !== 0);
    },
  },
  created() {
    // NOTE: users can't currently request the accounts endpoint. But this functionality is not necessarily required for this mixin
    // this.$store.dispatch('initAccounts', {keepCache: true})
  },
};
