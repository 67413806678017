<template lang="pug">
div
  h3 {{ $t("settings") }}
  CommonSettings(:step="step", show-requires-interaction)
    .step-settings
</template>

<script>
import { mapGetters } from "vuex";
import CommonSettings from "./CommonSettings";
import editStepMixin from "./mixins/editStepMixin";

export default {
  components: {
    CommonSettings,
  },
  mixins: [editStepMixin],
};
</script>
