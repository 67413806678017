import store from "@/store";
import { getSetting } from "@/getSettings";

export function getUserLanguage() {
  const userData = store.getters.getUserData;
  const attrs = userData.attributes || {};
  return attrs.langCode
    ? attrs.langCode.toLowerCase()
    : getSetting("defaultLanguage");
}
