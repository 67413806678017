import axios from "axios";
import api from "./config";

export default {
  getTestTypes() {
    return axios.get(`${api.testCenterUrl}/types`);
  },

  postTestType(testType) {
    return axios.post(`${api.testCenterUrl}/types`, testType);
  },

  patchTestType(testType) {
    return axios.patch(`${api.testCenterUrl}/types/${testType.id}`, testType);
  },

  deleteTestType(testTypeId) {
    return axios.delete(`${api.testCenterUrl}/types/${testTypeId}`);
  },

  getTests(query) {
    return axios.get(
      `${api.testCenterUrl}/tests?${new URLSearchParams(query)}`,
    );
  },

  postTest(test) {
    return axios.post(`${api.testCenterUrl}/tests`, test);
  },

  patchTest(test) {
    return axios.patch(`${api.testCenterUrl}/tests/${test.id}`, test);
  },

  deleteTest(testId) {
    return axios.delete(`${api.testCenterUrl}/tests/${testId}`);
  },

  putTestResult(testResult) {
    return axios.put(
      `${api.testCenterUrl}/test-results/${testResult.id}`,
      testResult,
    );
  },

  getTranslations() {
    return axios.get(`${api.testCenterUrl}/translations`);
  },

  patchTranslations() {
    return axios.patch(`${api.testCenterUrl}/translations`);
  },
};
