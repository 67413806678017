export default {
  termsAccept: "Concordo com {0}.",
  tos: "TCV",
  privacyAccept: "Concordo com {0}.",
  privacyPolicy: "Política de Privacidade",
  resetPassword: "Repor palavra-passe",
  usernamePasswordRequired:
    "Indique um nome de utilizador e uma palavra-passe.",
  passwordTooShort: "A palavra-passe tem de ter no mínimo 8 dígitos.",
  wrongCredentials: "Não foi encontrada nenhuma conta com os dados indicados.",
  accountStructureItem: "Departamento",
  addRole: "Adicionar função",
  allUserRelatedDeleteText:
    "Ao fazê-lo, todos os dados relevantes do utilizador serão apagados sem possibilidade de recuperação.",
  assignRoleModals: "Atribuir função",
  canBeDownloadedText: "podem ser descarregados em Concluído",
  changePassword: "Alterar palavra-passe",
  changingPasswordFor: "Alterar palavra-passe para o utilizador",
  confirmDeleteText: "Para confirmar, digite o nome de utilizador:",
  confirmDeteleBtnText: "Compreendi, apagar o utilizador",
  confirmPassword: "Repetir palavra-passe",
  createAccount: "Criar conta",
  createUser: "Criar consumidor",
  currentPassword: "Palavra-passe atual",
  customId: "ID definida pelo utilizador",
  dataPrivacyHeader: "Dados privados",
  dataPrivacyText: "Texto da secção (seguido de conteúdo)",
  dataPrivacyTitle: "Título da secção",
  dataRelatedToMe: "Ver os dados guardados sobre mim",
  dataRelatedToMeTitle: "Dados guardados",
  deleteText: "Apagar?",
  downloadDataRelatedToMe: "Descarregar os dados guardados",
  fileWithUserInformation: "Ficheiro com Informações de consumidor",
  forgotPassword: "Esqueceu palavra-passe?",
  forgotPasswordText:
    "Indique o seu endereço de e-mail, para receber uma ligação para repor a palavra-passe.",
  importUsers: "Importar consumidor",
  newHere: "Novo por cá?",
  newPassowrd: "Palavra-passe nova",
  newPasswordShort: "Palavra-passe muito curta",
  password: "Palavra-passe",
  passwordCantBeEmpty: "A palavra-passe não pode estar vazia",
  passwordsNotMatch: "As palavras-passe não coincidem",
  pickUser: "Selecione um utilizador",
  pleaseFillUpAllFields: "Preencha todos os campos",
  pleaseTypeNewPassword: "Indique uma nova palavra-passe",
  policyAcceptance: "Adoção de diretivas",
  policyAcceptanceSave: "Aceitar",
  acceptPolicies: "Aceitar diretivas",
  profile: "Perfil",
  role: "Função",
  roleExpiresOn: "A função expira em",
  saveDataAboutMe: "dados guardados sobre mim",
  signIn: "Iniciar sessão",
  termsConditionsHeader: "Termos e Condições Gerais",
  termsConditionsText: "Texto da secção (seguido de conteúdo)",
  termsConditionsTitle: "Título da secção",
  typeInOne: "Indique um para todos",
  username: "Nome de utilizador",
  firstname: "Nome",
  lastname: "Apelido",
  usernameMail: "Nome de utilizador ou palavra-passe",
  userNotes: "Notas",
  userProfile: "Perfil de utilizador",
  userStatus: "Estado do consumidor",
  wrongCurrentPassword: "Palavra-passe atual incorreta",
  fillAllFields: "Preencha todos os campos necessários",
  wrongUsernameOrPassword: "Nome de utilizador ou palavra-passe incorreta",
  minimumLength: "no mínimo 8",
  atLeastOneBigAndSmall: "pelo menos 1 letra minúscula e 1 letra maiúscula",
  passwordNotEqualUsernameAndEmail:
    "a palavra-passe não corresponde ao nome de utilizador e endereço de e-mail",
  registrationKey: "Chave de registo",
  registration: "Registo",
  notAnEmailAdress: "Indique um endereço de e-mail válido.",
  company: "Firma",
  companyHint: "Firma",
  birthday: "Aniversário",
  birthdayHint: "Aniversário",

  accountName: "Nome da conta",
  accountType: "Tipo de conta",
  accountAddress: "Endereço do titular da conta",
  logo: "Logótipo",
  address: "Endereço",
  contry: "País",
  zipCode: "Código postal",
  lessThan: "menos do que 1mb",
  defaultLanguage: "Idioma padrão",
  accountStatus: "Estado da conta",
  accountNotes: "Notas da conta",
  createAccount: "Criar conta",
  updateAccount: "Atualizar conta",
  fileToImport: "Notas sobre a conta",
  importStructure: "Importar estrutura",
  addRootAccount: "Adicionar conta de nível básico",
  usersPerMonth: "Utilizador mensal",
  usersAdded: "Adicionar utilizador",
  usersDeleted: "Utilizador apagado",
  usersTotal: "Total Consumidores",
  city: "Cidade",

  knowledge: "CONHECIMENTOS",
  toolbox: "CAIXA DE FERRAMENTAS",
  lessonCompleted: "Lição Concluída",
  lessonFailed: "Lição não concluída",
  repeatLesson: "Para continuar, repita a lição",
  congratulations: "Boa sorte!",
  courseCompleted: "Curso concluído",
  percentCompleted: "Respondeu corretamente a $successRate% da pergunta.",
  downloadCertificate:
    'Pode carregar o seu certificado em qualquer altura, no separador "Certificados", em Perfil, e solicitá-lo via e-mail.',
  trueFalse: "Certo ou errado?",
  seconds: "Segundos",
  noChoiceText: "Lamentamos, já não foi a tempo.",
  right: "Certo!",
  wrong: "Incorreto",
  progress: "$current de $total",

  addCourseContent: "Adicionar conteúdos do curso",
  addSemicolon: "Use o ponto e vírgula, para adicionar rótulos separados",
  addToProgram: "Adicionar ao programa",
  allAccounts: "Todas as contas",
  certificate: "Certificado",
  certificates: "Certificados",
  completed: "Concluído",
  contentIsLoaded: "Conteúdo carregado",
  contentManager: "Gestor de conteúdo",
  courseCompleted: "Curso concluído",
  courseContent: "Conteúdo do curso",
  courseDocuments: "Documentos",
  courseLables: "Etiquetas curso",
  courseLanguage: "Idioma de curso",
  courseNotes: "Notas de curso",
  courseStructure: "Estrutura",
  courseTags: "Etiquetas do curso",
  courseTitle: "Nome de curso",
  createCourse: "Criar curso",
  curator: "CURADOR",
  description: "Descrição",
  details: "Detalhes",
  dueDate: "Pagável até",
  duration: "Duração",
  exportStatistics: "Exportar estatísticas",
  learningStandard: "Padrão de aprendizagem",
  licenses: "Licenças",
  mandatory: "Curso obrigatório",
  noAssessment: "Não existem atualmente avaliações para serem analisadas.",
  noCoursesAssigned: "Não foi atribuído nenhum curso à sua conta. Consulte",
  noCoursesAvailable: "Não existem atualmente cursos lançados.",
  noDueDate: "Sem Data de validade",
  overview: "VISTA GERAL",
  page: "",
  pickAnAccount: "Selecione uma conta",
  printCertificate: "Imprimir certificado",
  publishCourse: "Lançar curso",
  publishedToAccount: "Lançamento na conta",
  relatedCourses: "Cursos similares",
  remove: "Remover",
  removeFromProgram: "Remover do programa",
  singleAccount: "Conta individual",
  startCourse: "Iniciar",
  successMessageOne: "Boa-sorte no curso ",
  successMessageThree:
    "Pode agora descarregar ou imprimir o seu certificado de conclusão. ",
  successMessageTwo: " concluído com êxito!",
  tags: "Palavras-chave",
  unpublishCourse: "Suspender lançamento",
  updateCourseContent: "Carregar conteúdo do curso",
  updateCourse: "Atualizar curso",
  youDidntfinishCourse: "Ainda não terminaste nenhum curso.",
  courseVisibleFor: "Curso visível para",
  accountStructureItem: "Departamento",
  courseAssigmentOptions: "Opções de atribuição",
  assignCourse: "Atribuir curso",
  pickACourse: "Selecione um curso",

  noDocumentsAvailable: "Nenhuma documentação disponível",
  nothingRelated: "não existe similar",
  nothingWritten: "não introduzido",
  noTagsAvailable: "Não existem palavras-chave disponíveis",
  alreadyGlobal: "O curso já foi lançado mundialmente.",
  questionsanswered: "Questões",
  completedOn: "Concluído em",
  locale: "pt-PT",
  quizAuthorizationContent: "Autorização questionário",

  courseNotPublished: "O curso atualmente não é público",
  courseNotPublishedForAccount:
    "Neste momento, o curso não se encontra lançado para a conta $account",
  clickToPublishCourse: "Lançar curso",
  visible: "Visível",
  userIsNotContentManager:
    "Como não é Gestor de Conteúdo não pode editar o conteúdo do curso.",
  assignmentOpen: "Abrir",
  assignmentStarted: "Iniciado",
  assignmentCompleted: "Concluído",
  dueDatePassed: "Data de validade excedida",

  exitCourse: "Terminar curso",
  markAsFinished: "Marcar como editado",

  system: "Sistema",
  configureLogging: "Configurar registo",
  createAccount: "Criar conta",
  changeAccount: "Alterar conta",
  deleteAccount: "Apagar conta",
  importAccountStructure: "Importar estrutura de conta",
  createUser: "Criar utilizador",
  importUser: "Importar utilizador",
  changeUser: "Alterar utilizador",
  deleteUser: "Apagar utilizador",
  assignUserRole: "Atribuir função",
  resetPassword: "Repor palavra-passe",

  createCourse: "Criar curso",
  changeCourse: "Alterar curso",
  addQuizContent: "Adicionar conteúdo do questionário",
  changeQuizContent: "Alterar conteúdo do questionário",
  addContent: "Adicionar conteúdo do curso",
  changeContent: "Alterar conteúdo do curso",
  deleteCourse: "Apagar curso",
  deleteContent: "Apagar conteúdo do curso",
  publishCourse: "Lançar curso",
  unpublishCourse: "cancelar publicação de curso",
  assignCourse: "Atribuir curso",
  unassignCourse: "Suspender atribuição de cursos",

  systemMessages: "Mensagens do sistema",

  learningManagement: "Gestão do programa",
  courseManagement: "Gestão de curso",
  userManagement: "Gestão utilizador",
  organizationStructure: "Estrutura organizativa",
  accountManagement: "Gestão contas",
  system: "Sistema",

  learningManagementDetail: "Conteúdos programáticos e atribuição de cursos",
  courseManagementDetail: "Gerir conteúdos do curso",
  userManagementDetail: "Gerir utilizador e repor palavras-passe",
  organizationStructureDetail: "Definir estrutura organizativa",
  accountManagementDetail: "Gerir contas",
  logsDetail: "Registos e definições do sistema",

  userCreateSuccess: "Utilizador criado com êxito",
  createOtherUser: "Adicionar um outro utilizador",
  backToUserOverview: "Voltar à vista geral de utilizador",
  importMoreUsers: "Importar mais utilizadores",
  currentRoles: "Funções atuais",
  numberOfRoles: "Número de funções",
  numberOfUsers: "Utilizador",
  deleteAccountMessage: "Confirme que deseja apagar a conta '$account'.",
  showStatistics: "Mostrar estatísticas",
  appendAccount: "Anexar conta a $account.",

  courseAssignments: "Atribuído",
  courseAssignmentsForCourse: "Atribuição de curso para $course",
  mandatoryAssignments: "Atribuições obrigatórias de curso",
  dueDateAssignments: "Com prazo",
  dueDatePassed: "Aguarda pagamento",
  userOrAccount: "Utilizador ou Departamento",
  expiryDate: "Data de validade",
  deletePublication: "Apagar lançamento",
  deletePublicationMessage: "Apagar o lançamento da conta $account?",
  orgStructure: "Departamento",
  type: "Tipo",
  downloadTemplate: "Descarregar modelo",
  published: "Lançado",
  scorm: "SCORM",
  quiz: "Questionário",
  staticFile: "Pdf",
  editPublication: "Editar lançamento",
  pleaseDefineCourseContent:
    "please define the course content before publishing it.",

  usernameMissing: "Falta o nome de utilizador",
  usernameError:
    "O nome de utilizador só pode ser composto de caracteres alfanuméricos",
  emailMissing: "Falta o e-mail",
  emailError: "Nenhum endereço de e-mail válido",
  passwordMissing: "Falta palavra-passe",
  passwordError:
    "A palavra-passe tem de ter no mínimo 8 dígitos e conter letras maiúsculas e minúsculas.",

  registrationKeyManagement: "Chave de registo",
  registrationKeyManagementDetail: "Gestão de chaves de registo",
  addRegistrationKey: "Adicionar chave de registo",
  registrationLink: "Ligação para registo",
  createKey: "Adicionar",

  userState: "Estado do usuário.",
  userStateDetail: "Estado de permissão de acesso e cursos obrigatórios.",
  testsPassed: "Testes aprovados",
  access: "Acesso",
  downloadReport: "Download do Relatório",
  success: "Guardado com êxito!",
  warning: "Cuidado!",
  error: "Erro!",
  removedFromProgram: "Este curso foi removido do seu programa",
  addedToProgram: "Este curso foi adicionado ao seu programa",
  courseCreated: "O curso foi criado",
  courseNotCreated: "O curso não foi criado",
  fillUpFields: "Preencher todos os campos obrigatórios",
  cookieNotAvailable:
    "A sua informação de início de sessão não está disponível ou está desatualizada, inicie sessão novamente",
  courseNotFinished: "Não existe informação de conclusão para o curso",
  courseAborted: "O curso foi cancelado",
  correctAnswer: "Resposta certa",
  wrongAnswer: "Resposta errada",
  courseStarted: "O curso foi iniciado",
  testsPassed: "Aprovado",
  testFailed: "Reprovado",
  courseCompleted: "Curso concluído com êxito",
  imageTooLarge: "O ficheiro tem de ser inferior a 2mb",
  wrongFormat: "Formato incorreto",
  passwordReset:
    "Foi enviada uma ligação para repor a palavra-passe para o endereço de e-mail indicado",
  passwordChanged: "A palavra-passe foi alterada",
  accountAdded: "A conta foi adicionada",
  accountUpdated: "A conta foi atualizada",
  accountDeleted: "A conta foi apagada",
  userImported: "Utilizadores foram importados",
  userNotImported: "Utilizadores não foram importados",
  loggingConfigSaved: "A configuração de início de sessão foi gravada",
  userDeleted: "O utilizador foi apagado",
  userAdded: "O utilizador foi criado",
  userUpdated: "O utilizador foi atualizado",
  GDPRLoaded: "RGPD carregado",
  cookiesDisabled: "Os cookies foram desativados por si",
  wrongCredentials: "Dados de acesso incorretos",
  fileUploaded: "Os seus dados foram carregados",
  courseContentUpdated: "O conteúdo do curso foi atualizado",
  coursePublished: "O curso foi lançado",
  courseUnPublished: "O lançamento do curso foi anulado.",
  serverUnreachable: "Não é possível aceder ao servidor",
  selectAccounts: "Selecionar conta",
  selectAccountToContinue: "Para continuar, selecionar conta",
  accountOverview: "Vista geral da conta",
  users: "Consumidor",
  courses: "Cursos",
  coursesCompleted: "Cursos concluídos",
  completedCoursesPerUser: "Cursos concluídos por consumidor",
  learningHours: "Horas de estudo",
  learningHoursPerUser: "Horas de estudo por consumidor",
  courseOverview: "Vista geral do curso",
  completed: "Concluído",
  assigned: "Atribuído",
  courseDetails: "Detalhes",
  selectCourse: "Selecionar curso",
  details: "Selecionar",
  type: "Tipo",
  dueDate: "Até",
  mandatory: "Curso obrigatório",
  completionRate: "Taxa de conclusão dos estudos",
  averageScore: "Resultado do teste",
  duration: "Duração",
  averageDuration: "Duração média",
  account: "Conta",
  accountName: "Nome da conta",
  accounts: "Contas",
  active: "Ativo",
  admin: "Admin",
  all: "Todos",
  not_found: "A página não existe",
  assessmentStatistics: "Estatísticas de análise",
  assignedUsersOnly: "Nenhum utilizador atribuído",
  autoIndividual: "Automático, individual",
  cancel: "Cancelar",
  cardImage: "Imagem do mapa",
  close: "Fechar",
  completed: "Concluído",
  confirm: "Confirmar",
  country: "País",
  course: "Cursos",
  courseDescription: "Descrição resumida",
  courseDocuments: "Documentos de curso",
  courses: "Biblioteca",
  courseStructure: "Estrutura de curso",
  dataProtection: "Proteção de dados",
  defaultLanguage: "Idioma padrão",
  deleteText: "Apagar?",
  delete: "Apagar",
  details: "Detalhes",
  dragAndDrop: "Arrastar e Largar / Clique para adicionar",
  dueDate: "Vence em",
  email: "E-mail",
  empty: "Vazio",
  exit: "Terminar sessão",
  expiresOn: "Vence no dia",
  fileImport: "Ficheiro para importar",
  image: "Imagem",
  inactive: "Inativo",
  language: "Idioma",
  logo: "Logótipo",
  mandatory: "Curso obrigatório",
  monthsLabels: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  myAccount: "A minha conta",
  myProfile: "O meu perfil",
  myProgram: "O meu programa",
  myRoles: "As minhas funções",
  never: "Nunca",
  no: "Não",
  none: "Nenhum",
  notFound: "Não encontrado",
  phoneNumber: "Número de telefone",
  playImage: "Reproduzir imagem",
  publishingExpiresOn: "O lançamento expira em",
  publishTo: "Lançado em",
  reports: "Relatório",
  save: "Guardar",
  search: "Pesquisar...",
  status: "Estado",
  structureItem: "Departamento",
  termsAndConditions: "Condições Gerais",
  unlimited: "Ilimitado",
  update: "Atualizar",
  user: "Utilizador",
  username: "Utilizador",
  users: "usuários",
  yes: "Sim",
  zipCode: "Código postal",

  info: "Informação",
  error: "Erro",
  alert: "Alarme",
  criticalAlert: "Alarme crítico",
  navigation: "Navegação",
  system: "Sistema",
  note: "Nota",
  userProfile: "Perfil de utilizador",

  userId: "ID do utilizador",
  customId: "",
  organizationalStructure: "Estrutura organizativa",
  created: "Criado em",
  new: "Novo",
  noResults: "Sem resultados",
  noData: "Sem dados",
  uploadFile: "Carregar ficheiro",
  id: "ID",
  name: "Nome",
  append: "Adicionar",
  edit: "Editar",
  format: "Formato",
  minutes: "Minutos",
  hours: "Horas",
  date: "Data",
  message: "Mensagem",
  fax: "Fax",

  genericError:
    "Ocorreu um erro, tente recarregar a página. Se o erro persistir, contacte o administrador de sistema.",
  action: "Ação",

  UploadedCertificates: "Uploaded Certificates",

  add_mandatory_user_property:
    "Selecione as seguintes características para o seu trabalho",
  help: "Ajuda",
  alreadyRegistered: "Já registrado",
  additionalDetails: "Informação adicional",
  additionalDetailsSave: "Confirmar",
  dragAndDropCertificate:
    "Arrastar e largar ficheiro PDF aqui ou clicar para adicionar",
  coverPhoto: "Cover Photo",
  EditCoverPhoto: "Edit Cover Photo",
  AddCoverPhoto: "Add Cover Photo",
  additionalDocuments: "Additional Documents",
  editAdditionalDocuments: "Edit Additional Documents",
  addAdditionalDocuments: "Add Additional Documents",
  fileName: "File name",
  fileSize: "File size",
  userProfileV2: "User profile V2",
  certification: "Certification",
  issuedBy: "Issued by",
  issuedOn: "Issued on",
  fulfilledInPercent: "Fulfilled in percent",
  systemLogs: "System Logs",
  systemConfigurationDetail: "Configure system settings",
  systemConfiguration: "System configuration",
  courseFormat: "Course formats",
  views: "Views",
  apis: "APIs",
  logs: "Logs",
  viewsDescription: "Visibility of the element in the respective view",
  courseFormatDescription: "Available course formats for defining the course",
  accessCard: "Access card",
  downloadCertification: "Download Certification",

  logout: "Sair",
  continue: "Continuar",
  courseFinishedDoYouWantToLogout:
    "O curso foi concluído. Você quer sair ou continuar? Você será desconectado automaticamente em {seconds}.",
  stateless: "Apátrida",
};
