import store from "@/store/";
import * as Sentry from "@sentry/browser";
import { i18n } from "../main";
/**
 * Map a fetch response to it's json content.
 * Returns a promise that either resolves with the response body if the request has been successful,
 * or rejects with the response body if the request was unsuccessful
 * @param {*} response a fetch response
 */
export function mapResponse(response) {
  return response.data;
}

export function printError(error) {
  let isMessageShown = false;

  if (error.response) {
    // Log server side errors to Sentry
    if (error.response.status && error.response.status > 500) {
      const errorMessage = `Error on request: ${JSON.stringify(error.request)}, error: ${JSON.stringify(error.response)}}`;
      Sentry.captureMessage(errorMessage);
    }
    // If an error message exists show it
    // TODO: this is currently not translated
    if (error.response.data && error.response.data.error) {
      const message = error.response.data.error;
      store.commit("addNewNotification", {
        title: "error",
        text: message,
        textAsIs: true,
        notificationType: "error",
      });
      isMessageShown = true;
    }
  }

  if (!isMessageShown) {
    store.commit("addNewNotification", {
      title: "error",
      text: i18n.global.t("genericError"),
      textAsIs: true,
      notificationType: "error",
    });
  }

  throw error;
}
