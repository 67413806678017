import getToken from "@/utils/getToken";
import API from "./config";
import { mapResponse, printError } from "../utils/mapResponse";
import axios from "axios";
/**
 *  @module Reports service
 */

export default {
  /**
   * @typedef {Object} AccountOverview
   * @property {number} completedCoursesPerUser - completed courses per user
   * @property {number} courses - number of courses
   * @property {number} coursesCompleted - number of completed courses
   * @property {(string|null)} learningHours - common time of all courses
   * @property {(string|null)} learningHoursPerUser - average time per user
   * @property {number} users - count of the users
   */
  /**
   * @typedef {Object} CourseDetails
   * @property {(string|null)} averageDuration - average duration
   * @property {string} averageScore - average score
   * @property {string} completionRate - completion rate
   * @property {number} dueDate - due date
   * @property {number} duration - duration
   * @property {string} type - course type
   */
  /**
   * @typedef {Object} CourseOverview
   * @property {array} assigned - array of counts of assigned courses
   * @property {array} completed - array of counts of completed courses
   * @property {Array<number>} dates - array of dates
   * @property {string} precission - one of 'day' ,'month', 'year'
   */

  /**
   * API call to get account overview by account id
   * @param {number} id - account id
   * @returns {AccountOverview} account overview object
   */
  GET_ACCOUNT_OVERVIEW(id) {
    return axios
      .get(API.reportsAccountURL + id)
      .then(mapResponse)
      .catch(printError);
  },
  /**
   * API call to get course overview
   * @param {object} payload - payload object
   * @param {number} payload.courseId - course id
   * @param {number} payload.id - account id
   * @returns {CourseOverview} course overview object
   */
  GET_COURSE_OVERVIEW(payload) {
    const { courseId, id } = payload;
    return axios
      .get(`${API.reportsAccountURL}${id}/courses/${courseId}`)
      .then(mapResponse)
      .catch(printError);
  },
  /**
   * API call to get course details
   * @param {object} payload - payload object
   * @param {number} payload.courseId - course id
   * @param {number} payload.id - account id
   * @returns {CourseDetails} course details object
   */
  GET_COURSE_DETAILS(payload) {
    const { courseId, id } = payload;
    return axios
      .get(`${API.reportsAccountURL}${id}/courses/${courseId}/details`)
      .then(mapResponse)
      .catch(printError);
  },

  /**
   * @typedef {Object} AssignmentReport
   * @property {number} courseId
   * @property {number} assignmentsTotal
   * @property {number} assignmentsOpen
   * @property {number} assignmentsStarted
   * @property {number} assignmentsCompleted
   * @property {number} assignmentsWithDueDate
   * @property {number} assignmentsDueDatePassed
   */
  /**
   * Get details on course assignments
   * @param {number} id
   * @returns {Array<AssignmentReport>}
   */
  GET_ASSIGNMENT_REPORT(id) {
    return axios
      .get(`${API.courseAssigmentsURL}${id}/assignments/summary`)
      .then(mapResponse)
      .catch(printError);
  },
};
