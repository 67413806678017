import axios from "axios";
import api from "./config";

export default {
  getTemplates() {
    return axios.get(`${api.automationUrl}/templates`);
  },
  getTopics() {
    return axios.get(`${api.automationUrl}/topics`);
  },
  getRules(query) {
    return axios.get(
      `${api.automationUrl}/rules?${new URLSearchParams(query)}`,
    );
  },
  postRule(rule) {
    return axios.post(`${api.automationUrl}/rules`, rule);
  },
  patchRule(rule) {
    return axios.patch(`${api.automationUrl}/rules/${rule.id}`, rule);
  },
  deleteRule(rule) {
    return axios.delete(`${api.automationUrl}/rules/${rule.id}`);
  },
};
