import store from "@/store";
import * as Sentry from "@sentry/browser";
import axios from "axios";

// If a user refreshes on a flex page, it might happen that the flex request happens before key capabilities have been received.
// In this case we store all flex requests, until the hardware restriction request hast resolved.
let flexRequestQueue = [];
let isAwaitingCapabilityInitialization = false;

function _checkAndAttachCapabilitites(config) {
  // We check for all requests made to flex endpoints,
  // if they contain one of the course ids we have a key for, we attach that key to the header
  const localKeyCapabilities =
    store.getters["hardwareRestrictions/localKeyCapabilities"];
  if (!localKeyCapabilities) {
    return config;
  }
  const relevantKeys = localKeyCapabilities.filter((key) =>
    key.access.some((access) => config.url.includes(access)),
  );
  if (relevantKeys.length > 0) {
    config.headers["hardware-key"] = relevantKeys[0].key.key;
  }
  return config;
}

export const HardwareRestrictionsInterceptor = (config) => {
  if (!config.url.startsWith("/services/api/v2/flex")) {
    return config;
  }
  return _checkAndAttachCapabilitites(config);
};

export const HardwareRestrictionsErrorInterceptor = (error) => {
  // We dont want any errors to propagte which would cancel all requests
  const config = error.config;
  if (!config) {
    return Promise.reject(error);
  }

  if (!config.url.startsWith("/services/api/v2/flex")) {
    return Promise.reject(error);
  }

  if (
    error.response.status &&
    error.response.status === 403 &&
    error.response.data.error === "key_required" &&
    !config._retry
  ) {
    // Don't fall into infinite retry loop in case of a backend failure
    config._retry = true;

    if (isAwaitingCapabilityInitialization) {
      return new Promise((resolve, reject) => {
        flexRequestQueue.push({ resolve, reject });
      }).then(
        () => {
          resolve(axios(_checkAndAttachCapabilitites(config)));
        },
        (error) => {
          reject(error);
        },
      );
    }

    // Start listening to store changes
    isAwaitingCapabilityInitialization = true;

    return new Promise(function (resolve, reject) {
      store.dispatch("hardwareRestrictions/initHardwareRestrictionKeys").then(
        () => {
          isAwaitingCapabilityInitialization = false;
          processQueue();
          resolve(axios(_checkAndAttachCapabilitites(config)));
        },
        (error) => {
          processQueue();
          reject(error);
        },
      );
    });
  }
  return Promise.reject(error);
};

export const hardwareRestrictionErrorInterceptor = (error) => {};

function processQueue() {
  flexRequestQueue.forEach((promise) => promise.resolve());
  flexRequestQueue = [];
}
