export default {
  termsAccept: "Wyrażam zgodę na {0}.",
  tos: "OWH",
  privacyAccept: "Wyrażam zgodę na {0}.",
  privacyPolicy: "Polityka prywatności",
  resetPassword: "Resetuj hasło",
  usernamePasswordRequired: "Proszę wpisać nazwę użytkownika i hasło.",
  passwordTooShort: "Hasło musi się składać przynajmniej z 8 znaków.",
  wrongCredentials: "Nie znaleziono konta pasującego dla podanych danych.",
  accountStructureItem: "Dział",
  addRole: "Dodaj rolę",
  allUserRelatedDeleteText:
    "Wszystkie dane związane z użytkownikiem zostaną nieodwracalnie usunięte.",
  assignRoleModals: "Przydziel rolę",
  canBeDownloadedText: "mogą być pobrane jako zamknięte",
  changePassword: "Zmień hasło",
  changingPasswordFor: "Zmień hasło dla użytkownika",
  confirmDeleteText: "Proszę wpisać nazwy użytkownika do zatwierdzenia:",
  confirmDeteleBtnText: "Zrozumiałem, usuwam użytkownika",
  confirmPassword: "Powtórz hasło",
  createAccount: "Utwórz konto",
  createUser: "Utwórz użytkownika",
  currentPassword: "Aktualne hasło",
  customId: "Identyfikator zdefiniowany dla użytkownika",
  dataPrivacyHeader: "Strefa danych prywatnych",
  dataPrivacyText: "Tekst sekcji (treść wkrótce)",
  dataPrivacyTitle: "Tytuł sekcji",
  dataRelatedToMe: "Zobacz zapisane dane o mnie",
  dataRelatedToMeTitle: "Zapisane dane",
  deleteText: "Jesteś pewny/-a, że chcesz to usunąć?",
  downloadDataRelatedToMe: "Pobierz zapisane dane",
  fileWithUserInformation: "Plik z informacjami o użytkowniku",
  forgotPassword: "Zapomniałeś hasła?",
  forgotPasswordText:
    "Proszę wpisać adres e-mail, aby otrzymać link umożliwiający przywrócenie Twojego hasła.",
  importUsers: "Importuj użytkowników",
  newHere: "Jesteś tu nowy /-a?",
  newPassowrd: "Nowe hasło",
  newPasswordShort: "Nowe hasło jest za krótkie",
  password: "Hasło",
  passwordCantBeEmpty: "Hasło nie może być puste",
  passwordsNotMatch: "Hasła nie są takie samie",
  pickUser: "Wybierz użytkownika",
  pleaseFillUpAllFields: "Proszę wypełnić wszystkie pola",
  pleaseTypeNewPassword: "Proszę nadać nowe hasło",
  policyAcceptance: "Akceptacja warunków",
  policyAcceptanceSave: "Akceptuj",
  acceptPolicies: "Proszę zaakceptować warunki",
  profile: "Profil",
  role: "Rola",
  roleExpiresOn: "Rola ważna do",
  saveDataAboutMe: "zapisane dane o mnie",
  signIn: "Loguj",
  termsConditionsHeader: "Ogólne warunki handlowe",
  termsConditionsText: "Tekst sekcji (treść wkrótce)",
  termsConditionsTitle: "Tytuł sekcji",
  typeInOne: "Proszę wprowadzić jeden dla wszystkich",
  username: "Nazwa użytkownika",
  firstname: "Imię",
  lastname: "Nazwisko",
  usernameMail: "Nazwa użytkownika lub e-mail",
  userNotes: "Notatki",
  userProfile: "Profil użytkownika",
  userStatus: "Status użytkownika",
  wrongCurrentPassword: "Błędne aktualne hasło",
  fillAllFields: "Proszę wypełnić wszystkie wymagane pola",
  wrongUsernameOrPassword: "Błędna nazwa użytkownika lub hasło",
  minimumLength: "minimalna długość 8 znaków",
  atLeastOneBigAndSmall: "przynajmniej 1 mała i 1 wielka litera",
  passwordNotEqualUsernameAndEmail:
    "hasło nie jest identyczne z nazwą użytkownika i adresem e-mail",
  registrationKey: "Klucz rejestracji",
  registration: "Rejestracja",
  notAnEmailAdress: "Proszę wpisać ważny adres e-mail.",
  company: "Urodziny",
  companyHint: "Urodziny",
  birthday: "Dzimšanas diena",
  birthdayHint: "Dzimšanas diena",

  accountName: "Nazwa konta",
  accountType: "Rodzaj konta",
  accountAddress: "Adres posiadacza konta",
  logo: "Logo",
  address: "Adres",
  contry: "Kraj",
  zipCode: "Kod",
  lessThan: "mniej niż 1 MB",
  defaultLanguage: "Domyślny język",
  accountStatus: "Status konta",
  accountNotes: "Notatki do konta",
  createAccount: "Załóż konto",
  updateAccount: "Aktualizuj konto",
  fileToImport: "Uwagi do konta",
  importStructure: "Importuj strukturę",
  addRootAccount: "Dodaj basis level account",
  usersPerMonth: "Miesięczni użytkownicy",
  usersAdded: "Dodany użytkownik",
  usersDeleted: "Usunięty użytkownik",
  usersTotal: "Użytkownicy Łącznie",
  city: "Miasto",

  knowledge: "WIEDZA",
  toolbox: "TOOLBOX",
  lessonCompleted: "Lekcja zakończona",
  lessonFailed: "Lekcja niezakończona",
  repeatLesson: "Proszę powtórzyć lekcję, aby kontynuować",
  congratulations: "Gratulacje!",
  courseCompleted: "Szkolenie zakończone",
  percentCompleted: "Odpowiedziałeś /-aś na $successRate% pytań poprawnie.",
  downloadCertificate:
    "Możesz w każdym momencie pobrać swój certyfikat z zakładki „Certyfikaty” w profilu i zażądać wysłanie go e-mailem.",
  trueFalse: "Dobrze czy źle?",
  seconds: "Sekund",
  noChoiceText: "Szkoda, za późno.",
  right: "Dobrze!",
  wrong: "Niepoprawne",
  progress: "$current z $total",

  addCourseContent: "Dodaj treści szkolenia",
  addSemicolon: "Dodaj opis, oddzielając go średnikiem",
  addToProgram: "Dodaj do programu",
  allAccounts: "Wszystkie konta",
  certificate: "Certyfikat",
  certificates: "Certyfikaty",
  completed: "Zamknięte",
  contentIsLoaded: "Załadowano treść",
  contentManager: "Menedżer treści",
  courseCompleted: "Szkolenie zakończone",
  courseContent: "Treść szkolenia",
  courseDocuments: "Dokumenty",
  courseLables: "Etykiety do szkolenia",
  courseLanguage: "Język szkolenia",
  courseNotes: "Notatki do szkolenia",
  courseStructure: "Struktura",
  courseTags: "Dni szkolenia",
  courseTitle: "Nazwa szkolenia",
  createCourse: "Utwórz szkolenie",
  curator: "OPIEKUN",
  description: "Opis",
  details: "Szczegóły",
  dueDate: "Płatne do",
  duration: "Czas trwania",
  exportStatistics: "Eksportuj statystyki",
  learningStandard: "Standard nauczania",
  licenses: "Licencje",
  mandatory: "Szkolenie obowiązkowe",
  noAssessment: "Obecnie nie ma komentarza do sprawdzenia.",
  noCoursesAssigned:
    "Do Twojego konta nie przypisano żadnego szkolenia. Proszę odwiedzić",
  noCoursesAvailable: "Obecnie brak dostępnych szkoleń.",
  noDueDate: "Brak daty ważności",
  overview: "PRZEGLĄD",
  page: "",
  pickAnAccount: "Wybierz konto",
  printCertificate: "Drukuj certyfikat",
  publishCourse: "Udostępnij szkolenie",
  publishedToAccount: "Opublikowane na koncie",
  relatedCourses: "Podobne szkolenia",
  remove: "Usuń",
  removeFromProgram: "Usuń z programu",
  singleAccount: "Pojedyncze konto",
  startCourse: "Rozpocznij",
  successMessageOne: "Gratulacje, ukończyłeś /-aś szkolenie ",
  successMessageThree:
    "Teraz możesz pobrać lub wydrukować swój certyfikat ukończenia szkolenia. ",
  successMessageTwo: " zakończone pomyślnie!",
  tags: "Hasła",
  unpublishCourse: "Anuluj udostępnienie",
  updateCourseContent: "Prześlij treści szkolenia",
  updateCourse: "Aktualizuj szkolenie",
  youDidntfinishCourse: "Jeszcze nie ukończyłeś /-aś żadnego szkolenia.",
  courseVisibleFor: "Szkolenie przewidywane dla",
  accountStructureItem: "Dział",
  courseAssigmentOptions: "Opcje przypisywania",
  assignCourse: "Przypisz szkolenie",
  pickACourse: "Wybierz szkolenie",

  noDocumentsAvailable: "Brak dokumentów",
  nothingRelated: "brak podobnych",
  nothingWritten: "brak wpisu",
  noTagsAvailable: "brak haseł",
  alreadyGlobal: "Szkolenie jest już udostępnione na całym świecie.",
  questionsanswered: "Pytania",
  completedOn: "Zakończono w dniu",
  locale: "pl-PL",
  quizAuthorizationContent: "Autoryzacja quizu",

  courseNotPublished: "Szkolenie nie jest obecnie udostępnione",
  courseNotPublishedForAccount:
    "Szkolenie nie jest obecnie udostępnione dla konta $account",
  clickToPublishCourse: "Udostępnij szkolenie",
  visible: "Przewidywane",
  userIsNotContentManager:
    "Nie jesteś Content Managerem, dlatego nie możesz edytować treści szkolenia.",
  assignmentOpen: "Otwarte",
  assignmentStarted: "Rozpoczęte",
  assignmentCompleted: "Zakończone",
  dueDatePassed: "Przekroczona data ważności",

  exitCourse: "Zakończ szkolenie",
  markAsFinished: "Zaznacz jako opracowane",

  system: "System",
  configureLogging: "Konfiguruj protokołowanie",
  createAccount: "Załóż konto",
  changeAccount: "Zmień kontoc",
  deleteAccount: "Usuń konto",
  importAccountStructure: "Importuj strukturę konta",
  createUser: "Utwórz użytkowników",
  importUser: "Importuj użytkowników",
  changeUser: "Zmień użytkowników",
  deleteUser: "Usuń użytkowników",
  assignUserRole: "Przydziel rolę",
  resetPassword: "Resetuj hasło",

  createCourse: "Utwórz szkolenie",
  changeCourse: "Zmień szkolenie",
  addQuizContent: "Dodaj treść quizu",
  changeQuizContent: "Zmień treść quizu",
  addContent: "Dodaj treść szkolenia",
  changeContent: "Zmień treść szkolenia",
  deleteCourse: "Usuń szkolenie",
  deleteContent: "Usuń treść szkolenia",
  publishCourse: "Udostępnij szkolenie",
  unpublishCourse: "Udostępnij szkolenie",
  assignCourse: "Przypisz szkolenie",
  unassignCourse: "Anuluj przypisanie szkolenia",

  systemMessages: "Wiadomości systemowe",

  learningManagement: "Zarządzanie nauczaniem",
  courseManagement: "Zarządzanie szkoleniami",
  userManagement: "Zarządzanie użytkownikami",
  organizationStructure: "Struktura organizacji",
  accountManagement: "Zarządzanie kontami",
  system: "System",

  learningManagementDetail: "Plany nauczania i przypisywanie szkolenia",
  courseManagementDetail: "Zarządzaj treścią szkolenia",
  userManagementDetail: "Zarządzaj użytkownikami i resetuj hasła",
  organizationStructureDetail: "Zdefiniuj strukturę organizacji",
  accountManagementDetail: "Zarządzaj kontami",
  logsDetail: "Dzienniki systemowe i ustawienia",

  userCreateSuccess: "Utworzono pomyślnie użytkownika",
  createOtherUser: "Dodaj kolejnego użytkownika",
  backToUserOverview: "Powrót do listy użytkowników",
  importMoreUsers: "Importuj kolejnvych użytkowników",
  currentRoles: "Bieżące role",
  numberOfRoles: "Liczba ról",
  numberOfUsers: "Użytkownik",
  deleteAccountMessage:
    "Proszę potwierdzić, że chcesz usunąć konto '$account».",
  showStatistics: "Wyświetl statystyki",
  appendAccount: "Dodaj konto do $account.",

  courseAssignments: "Przydzielono",
  courseAssignmentsForCourse: "Przydzielenie szkolenia dla $course",
  mandatoryAssignments: "Przydzielenia szkolenia obowiązkowego",
  dueDateAssignments: "Z terminem",
  dueDatePassed: "Ważne do",
  userOrAccount: "Użytkownik lub dział",
  expiryDate: "Data ważności",
  deletePublication: "Usuń udostępnienie",
  deletePublicationMessage:
    "Jesteś pewny, że chcesz usunąć udostępnienie dla konta $account?",
  orgStructure: "Dział",
  type: "Typ",
  downloadTemplate: "Pobierz wzór",
  published: "Udostępnienie",
  scorm: "SCORM",
  quiz: "Kartkówka",
  staticFile: "Pdf",
  editPublication: "Edytuj udostępnienie",
  pleaseDefineCourseContent:
    "please define the course content before publishing it.",

  usernameMissing: "Brak nazwy użytkownika",
  usernameError:
    "Nazwa użytkownika może składać się wyłącznie ze znaków alfanumerycznych",
  emailMissing: "Brak adresu e-mail",
  emailError: "Brak ważnego adresu e-mail",
  passwordMissing: "Brak hasła",
  passwordError:
    "Hasło musi się składać przynajmniej z 8 znaków i zawierać przynajmniej jedną wielką i jedną małą literę.",

  registrationKeyManagement: "Klucz rejestracji",
  registrationKeyManagementDetail: "Zarządzanie kluczami rejestracji",
  addRegistrationKey: "Dodaj klucz rejestracji",
  registrationLink: "Link do rejestracji",
  createKey: "Dodaj",

  userState: "Stan użytkownika",
  userStateDetail: "Stan pozwolenia na dostęp i obowiązkowe kursy.",
  testsPassed: "Testy zdały",
  access: "Dostęp",
  downloadReport: "Pobierz raport",
  success: "Pomyślnie zapisano!",
  warning: "Uwaga!",
  error: "Błąd!",
  removedFromProgram: "To szkolenie zostało usunięte z Twojego programu",
  addedToProgram: "To szkolenie zostało dodane do Twojego programu",
  courseCreated: "Utworzono szkolenie",
  courseNotCreated: "Nie utworzono szkolenia",
  fillUpFields: "Proszę wypełnić wszystkie pola obowiązkowe",
  cookieNotAvailable:
    "Twoje dane logowania są niedostępne lub zbyt stare, proszę zalogować się ponownie",
  courseNotFinished: "Brak informacji o zakończenie dla szkolenia",
  courseAborted: "Szkolenie zostało przerwane",
  correctAnswer: "Poprawna odpowiedź",
  wrongAnswer: "Błędna odpowiedź",
  courseStarted: "Rozpoczęto szkolenie",
  testsPassed: "Test zdany pomyślnie",
  testFailed: "Test niezdany",
  courseCompleted: "Szkolenie zakończono pomyślnie",
  imageTooLarge: "Plik nie może przekroczyć 2 MB",
  wrongFormat: "Błędny format",
  passwordReset: "Na podany adres e-mail wysłano link do przywrócenia hasła",
  passwordChanged: "Hasło zostało zmienione",
  accountAdded: "Dodano konto",
  accountUpdated: "Zaktualizowano konto",
  accountDeleted: "Usunięto konto",
  userImported: "Zaimportowano użytkowników",
  userNotImported: "Nie zaimportowano użytkowników",
  loggingConfigSaved: "Zapisano konfiguracje logowania",
  userDeleted: "Usunięto użytkownika",
  userAdded: "Dodano użytkownika",
  userUpdated: "Zaktualizowano użytkownika",
  GDPRLoaded: "Załadowano RODO",
  cookiesDisabled: "Wyłączyłeś /-aś pliki cookies",
  wrongCredentials: "Błędne dane logowania",
  fileUploaded: "Twój plik został załadowany",
  courseContentUpdated: "Zaktualizowano treść szkolenia",
  coursePublished: "Udostępniono szkolenie",
  courseUnPublished: "Zawieszono udostępnianie szkolenia.",
  serverUnreachable: "Brak dostępu do serwera",
  selectAccounts: "Wybierz konto",
  selectAccountToContinue: "Wybierz konto, aby kontynuować",
  accountOverview: "Lista kont",
  users: "Użytkownik",
  courses: "Szkolenia",
  coursesCompleted: "Zakończone szkolenia",
  completedCoursesPerUser: "Zakończone szkolenia na użytkownika",
  learningHours: "Ilość godzin lekcyjnych",
  learningHoursPerUser: "Ilość godzin lekcyjnych na użytkownika",
  courseOverview: "Lista szkoleń",
  completed: "Zamknięte",
  assigned: "Przydzielone",
  courseDetails: "Szczegóły",
  selectCourse: "Wybierz szkolenie",
  details: "Wybierz",
  type: "Typ",
  dueDate: "Do",
  mandatory: "Szkolenie obowiązkowe",
  completionRate: "Wskaźnik ukończenia",
  averageScore: "Wynik testu",
  duration: "Czas trwania",
  averageDuration: "Średni czas trwania",
  account: "Konto",
  accountName: "Nazwa konta",
  accounts: "Konta",
  active: "Aktywne",
  admin: "Admin",
  all: "Wszystkie",
  not_found: "Ta strona nie istnieje",
  assessmentStatistics: "Statystyki ocen",
  assignedUsersOnly: "Tylko przydzieleni użytkownicy",
  autoIndividual: "Automatycznie, indywidualnie",
  cancel: "Przerwij",
  cardImage: "Obraz mapy",
  close: "Zamknij",
  completed: "Zamknięte",
  confirm: "Potwierdź",
  country: "Kraj",
  course: "Szkolenia",
  courseDescription: "Opis szkolenia",
  courseDocuments: "Dokumenty do szkolenia",
  courses: "Biblioteka",
  courseStructure: "Struktura szkolenia",
  dataProtection: "Ochrona danych",
  defaultLanguage: "Język standardowy",
  deleteText: "Jesteś pewny/-a, że chcesz to usunąć?",
  delete: "Usuń",
  details: "Szczegóły",
  dragAndDrop: "Drag & Drop / Przeciągnij i upuść",
  dueDate: "Płatne",
  email: "E-mail",
  empty: "Pusty",
  exit: "Wyloguj",
  expiresOn: "Termin ważności",
  fileImport: "Plik do zaimportowania",
  image: "Obraz",
  inactive: "Nieaktywny",
  language: "Język",
  logo: "Logo",
  mandatory: "Szkolenie obowiązkowe",
  monthsLabels: [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Październik",
    "Listopad",
    "Grudzień",
  ],
  myAccount: "Moje konto",
  myProfile: "Mój profil",
  myProgram: "Moj program",
  myRoles: "Moje role",
  never: "Nigdy",
  no: "Nie",
  none: "Żaden",
  notFound: "Nie znaleziono",
  phoneNumber: "Numer telefonu",
  playImage: "Odtwórz obraz",
  publishingExpiresOn: "Udostępnienie ważne do",
  publishTo: "Udostępnij w",
  reports: "Raport",
  save: "Zapisywanie",
  search: "Wyszukiwanie...",
  status: "Status",
  structureItem: "Dział",
  termsAndConditions: "Warunki handlowe",
  unlimited: "Nieograniczone",
  update: "Aktualizacja",
  user: "Użytkownik",
  username: "Nazwa Użytkownika",
  users: "Użytkownicy",
  yes: "Tak",
  zipCode: "Kod",

  info: "Informacja",
  error: "Error",
  alert: "Alarm",
  criticalAlert: "Alarm krytyczny",
  navigation: "Nawigacja",
  system: "System",
  note: "Wskazówka",
  userProfile: "Profil użytkownika",

  userId: "ID użytkownika",
  customId: "",
  organizationalStructure: "Struktura organizacji",
  created: "Sporządzono w dniu",
  new: "Nowość",
  noResults: "Brak wyników",
  noData: "Brak danych",
  uploadFile: "Prześlij plik",
  id: "ID",
  name: "Nazwa",
  append: "Dodaj",
  edit: "Edytuj",
  format: "Format",
  minutes: "Minuty",
  hours: "Godziny",
  date: "Data",
  message: "Wiadomość",
  fax: "Faks",

  genericError:
    "Wystąpił błąd, spróbuj ponownie załadować stronę. Jeżeli błąd będzie występować nadal, skontaktuj się z administratorem systemu.",
  action: "Akcja",

  uploadedCertificates: "Przesłane certyfikaty",

  add_mandatory_user_property: "Wybierz następujące cechy dla swojej pracy",
  help: "Wsparcie",
  alreadyRegistered: "Już zarejestrowany",
  additionalDetails: "Dodatkowe informacje",
  additionalDetailsSave: "Potwierdzać",
  dragAndDropCertificate:
    "Przeciągnij i upuść plik PDF tutaj lub kliknij, aby dodać",
  coverPhoto: "Cover Photo",
  EditCoverPhoto: "Edit Cover Photo",
  AddCoverPhoto: "Add Cover Photo",
  additionalDocuments: "Additional Documents",
  editAdditionalDocuments: "Edit Additional Documents",
  addAdditionalDocuments: "Add Additional Documents",
  fileName: "File name",
  fileSize: "File size",
  userProfileV2: "User profile V2",
  certification: "Certification",
  issuedBy: "Issued by",
  issuedOn: "Issued on",
  fulfilledInPercent: "Fulfilled in percent",
  systemLogs: "System Logs",
  systemConfigurationDetail: "Configure system settings",
  systemConfiguration: "System configuration",
  courseFormat: "Course formats",
  views: "Views",
  apis: "APIs",
  logs: "Logs",
  viewsDescription: "Visibility of the element in the respective view",
  courseFormatDescription: "Available course formats for defining the course",
  accessCard: "Access card",
  downloadCertification: "Download Certification",

  logout: "Wyloguj się",
  continue: "Kontynuuj",
  courseFinishedDoYouWantToLogout:
    "Kurs został zakończony. Czy chcesz się wylogować czy kontynuować? Zostaniesz automatycznie wylogowany za {seconds}.",
  stateless: "Bezpaństwowiec",
};
