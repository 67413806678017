import getToken from "@/utils/getToken";
import API from "./config";
import { mapResponse, printError } from "../utils/mapResponse";
import axios from "axios";

export type Account = {
  id: number;
  name: string;
};

export default {
  /**
   * API call to get all available accounts
   * @returns {Array<FullAccount>} fetch response which contains accounts array
   */
  GET() {
    return axios
      .get(API.baseUrl + "accounts")
      .then(mapResponse)
      .catch(printError);
  },
  /**
   * API call to get an account information by id
   * @param {(number|string)} id - id of an account
   * @returns {FullAccount} Fetch response
   */
  GET_BY_ID(id) {
    return axios
      .get(`${API.baseUrl}accounts/${id}`)
      .then(mapResponse)
      .catch(printError);
  },

  /**
   * API call to get an account's statistics information by id
   * @param {object} data - pagination params and accountId to select
   * @returns {statistics} Fetch response
   */
  GET_ACCOUNT_STATISTICS(data) {
    const params = new URLSearchParams({
      ...data,
      accountIds: JSON.stringify(data.accountIds),
    });
    return axios
      .get(`${API.reportingServices}accounts/stats?${params.toString()}`)
      .then(mapResponse);
  },

  /**
   * API call to get an global account statistics
   * @param {object} data - pagination params
   * @returns {statistics} Fetch response
   */
  GET_GLOBAL_ACCOUNT_STATISTICS(data) {
    const params = new URLSearchParams(data);
    return axios
      .get(`${API.reportingServices}accounts/globalStats?${params.toString()}`)
      .then(mapResponse);
  },

  /**
   * API call to add a new account
   * @param {object} payload - payload object
   * @param {string} payload.name - an account name
   * @param {(number | null)} payload.parent - id of a parent account
   * @param {string} payload.expiration - (ISO) date string
   * @param {object} payload.attributes - additional info
   * @returns {Account} added account
   */
  POST(payload) {
    return axios
      .post(API.baseUrl + "accounts", payload)
      .then(mapResponse)
      .catch(printError);
  },

  BATCH_CREATE_ACCOUNTS(payload) {
    return axios
      .post(
        `${API.baseUrl}accounts/${payload.account}/structure`,
        payload.imports,
      )
      .then(mapResponse)
      .catch(printError);
  },

  GET_DEPARTMENTS(id) {
    return axios
      .get(`${API.baseUrl}accounts/${id}/structure`)
      .then(mapResponse)
      .catch(printError);
  },

  DELETE_DEPARTMENT(accountId, elementId) {
    return axios
      .delete(`${API.baseUrl}accounts/${accountId}/structure/${elementId}`)
      .then(mapResponse)
      .catch(printError);
  },

  /**
   * API call to update account information
   * @param {object} payload - payload object
   * @param {string} payload.name - an account name
   * @param {(number | null)} payload.parent - id of a parent account
   * @param {string} payload.expiration - (ISO) date string
   * @param {object} payload.attributes - additional info
   * @returns {Account} updated account object
   */
  PATCH(payload) {
    return axios
      .patch(API.baseUrl + "accounts/" + payload.id, payload)
      .then(mapResponse);
  },
  /**
   * API call to delete an account by id
   * @param {id} payload - payload object
   * @returns {Promise<Response>} Fetch response, status 200 if success
   */
  DELETE(id) {
    return axios
      .delete(API.baseUrl + "accounts/" + id)
      .then(mapResponse)
      .catch(printError);
  },

  getSubcontractors(accountId) {
    return axios
      .get(
        `${API.subcontractorUrl}?${new URLSearchParams({ account: accountId })}`,
      )
      .then(mapResponse)
      .catch(printError);
  },

  addSubcontractor(subcontractor) {
    return axios
      .post(API.subcontractorUrl, subcontractor)
      .then(mapResponse)
      .catch(printError);
  },

  deleteSubcontractor(id) {
    return axios
      .delete(API.subcontractorUrl + id)
      .then(mapResponse)
      .catch(printError);
  },

  patchSubcontractor(subcontractor) {
    return axios
      .patch(API.subcontractorUrl + subcontractor.id, subcontractor)
      .then(mapResponse)
      .catch(printError);
  },
};
