export default {
  termsAccept: "أنا أقبل {0}",
  tos: "الشروط والأحكام",
  privacyAccept: "أنا أقبل {0}",
  privacyPolicy: "سياسة الخصوصية",
  resetPassword: "إعادة تعيين كلمة المرور",
  usernamePasswordRequired: "الرجاء إدخال اسم مستخدم وكلمة مرور",
  passwordTooShort: "يجب أن تتكون كلمة المرور من 8 أحرف على الأقل",
  wrongCredentials: "بيانات الاعتماد المقدمة لا تتطابق مع أي حساب",
  accountStructureItem: "القسم",
  addRole: "إضافة دور",
  allUserRelatedDeleteText:
    "سيتم حذف جميع البيانات المتعلقة بالمستخدم بموجب هذا بشكل نهائي",
  assignRoleModals: "تعيين نماذج الدور",
  canBeDownloadedText: "يمكن تنزيله تحت مكتمل",
  changePassword: "تغيير كلمة المرور",
  changingPasswordFor: "تغيير كلمة المرور للمستخدم",
  confirmDeleteText: "يرجى التأكيد عن طريق كتابة اسم المستخدم",
  confirmDeteleBtnText: "أفهم  احذف هذا المستخدم",
  confirmPassword: "تأكيد كلمة المرور",
  createAccount: "تسجيل المستخدم",
  createUser: "إنشاء مستخدم",
  currentPassword: "كلمة المرور الحالية",
  customId: "معرف مخصص",
  dataPrivacyHeader: "خصوصية البيانات",
  dataPrivacyText: "نص القسم (المحتوى سيتبع)",
  dataPrivacyTitle: "عنوان القسم",
  dataRelatedToMe: "البيانات المتعلقة بي",
  dataRelatedToMeTitle: "البيانات المتعلقة بي",
  deleteText: "هل أنت متأكد من أنك تريد حذف",
  downloadDataRelatedToMe: "تنزيل البيانات المتعلقة بي باسم txt",
  fileWithUserInformation: "ملف يحتوي على معلومات المستخدم",
  forgotPassword: "هل نسيت كلمة المرور؟",
  forgotPasswordText:
    "من فضلك ، أدخل بريدك الإلكتروني وسنرسل إليك تعليمات بالبريد الإلكتروني حول كيفية إعادة تعيين كلمة المرور الخاصة بك",
  importUsers: "استيراد المستخدمين",
  newHere: "جديد هنا؟",
  newPassowrd: "كلمة مرور جديدة",
  newPasswordShort: "كلمة مرور الجديدة قصيرة جدا",
  password: "كلمة المرور",
  passwordCantBeEmpty: "لا يمكن أن تكون كلمة المرور فارغة",
  passwordsNotMatch: "كلمات المرور غير متطابقة",
  pickUser: "يرجى ملء جميع الحقول",
  pleaseFillUpAllFields: "يرجى كتابة كلمة المرور الجديدة",
  pleaseTypeNewPassword: "يرجى كتابة كلمة المرور الجديدة",
  policyAcceptance: "اعتماد السياسة",
  policyAcceptanceSave: "قبول",
  acceptPolicies: "من فضلكم اقبلوا السياسات",
  profile: "الملف الشخصي",
  role: "الدور",
  roleExpiresOn: "ينتهي الدور في",
  saveDataAboutMe: "البيانات المحفوظة عني",
  signIn: "تسجيل الدخول",
  termsConditionsHeader: "الشروط والأحكام",
  termsConditionsText: "نص القسم (المحتوى سيتبع)",
  termsConditionsTitle: "عنوان القسم",
  typeInOne: "اكتب واحدا للجميع",
  firstname: "الاسم ",
  lastname: "اللقب",
  username: "اسم المستخدم",
  usernameMail: "اسم المستخدم أو عنوان البريد الإلكتروني",
  userNotes: "ملاحظات",
  userProfile: "ملف تعريف المستخدم",
  userStatus: "حالة المستخدم",
  wrongCurrentPassword: "كلمة المرور الحالية خاطئة",
  minimumLength: "الحد الأدنى للطول 8",
  atLeastOneBigAndSmall: "على الأقل 1 حرف صغير و 1 حرف كبير",
  passwordNotEqualUsernameAndEmail:
    "كلمة المرور لا تساوي اسم المستخدم وعنوان البريد الإلكتروني",
  registrationKey: "مفتاح التسجيل",
  registration: "التسجيل",
  notAnEmailAdress: "الرجاء إدخال عنوان بريد إلكتروني صالح",
  company: "شركة",
  companyHint: "شركة",
  birthday: "تاريخ الميلاد",
  birthdayHint: "تاريخ الميلاد",
  birthyear: "سنة الميلاد",
  accountName: "اسم الحساب",
  accountType: "نوع الحساب",
  accountAddress: "عنوان الحساب",
  logo: "الشعار",
  address: "العنوان",
  contry: "البلد",
  lessThan: "أقل من 1 MB",
  defaultLanguage: "اللغة الافتراضية",
  accountStatus: "حالة الحساب",
  accountNotes: "ملاحظات الحساب",
  fileToImport: "ملاحظات الحساب",
  importStructure: "هيكل الاستيراد",
  updateAccount: "تحديث الحساب",
  addRootAccount: "إضافة حساب على مستوى الجذر",
  usersPerMonth: "المستخدمون شهريا",
  usersAdded: "تمت إضافة المستخدمين",
  usersDeleted: "المستخدمون محذوفون",
  usersTotal: "إجمالي المستخدمين",
  city: "المدينة",
  users: "المستخدمون",
  coursesAvailable: "الدورات المتاحة",
  started: "بدأت",
  openMandatoryAssignments: "فتح المهام الإلزامية",
  mandatoryAssignments: "المهام الإلزامية",
  dueMandatoryCompletions: "الإكمال الإلزامي الواجب",
  of: "من",
  accountOverview: "نظرة عامة على الحساب",
  selectAccount: "اختر حسابا",
  noAccountsAvailable: "لا توجد حسابات متاحة",
  completionRate: "معدل الإنجاز",
  averageResult: "نتيجة متوسطة",
  courseOverview: "نظرة عامة على الدورة التدريبية",
  reportCoursesSearchPlaceholder: "البحث عن دورة تدريبية",
  completedLastWeeks: "إكمال الدورة في آخر {عدد} أسابيع",
  completedLastMonths: "إكمال الدورة في آخر {عدد} أشهر",
  executionTime: "التوزيع حسب وقت التنفيذ (دقيقة)",
  completionLevel: "التوزيع حسب نقاط الإنجاز",
  knowledge: "نظرة عامة",
  toolbox: "صندوق الأدوات",
  lessonCompleted: "اكتمل الدرس",
  lessonFailed: "فشل الدرس",
  repeatLesson: "يرجى تكرار الدرس من أجل المتابعة",
  congratulations: "تهانينا",
  courseCompleted: "الدورة المكتملة",
  downloadCertificate:
    'يمكنك تنزيل شهادتك ضمن "الشهادات" في ملف التعريف الخاص بك ، أو طلب شهادتك عبر البريد الإلكتروني',
  trueFalse: "صواب أم خطأ؟",
  noChoiceText: "بعد فوات الأوان  يا للأسف!",
  right: "صحيح!",
  wrong: "خطأ",
  percentCompleted: "لقد أجبت على $successRate٪ من جميع الأسئلة بشكل صحيح",
  progress: "$current of $total",

  repeat: "كرر",
  responsesMissing: "يرجى إكمال جميع العناصر قبل المتابعة",
  addCourseContent: "إضافة محتوى الدورة التدريبية",
  startCourseEditor: "بدء محرر الدورة التدريبية",
  copyCourse: "نسخ الدورة",
  courseCopyStarted: "تم بدء إجراء النسخ",
  courseCopySuccess: "تم نسخ الدورة التدريبية بنجاح",
  transferCourse: "دورة التحويل",
  systemUrl: "عنوان URL للنظام",

  addSemicolon: "إضافة تصنيفات مفصولة بفاصلة منقوطة",
  addToProgram: "إضافة إلى البرنامج",
  allAccounts: "جميع الحسابات",
  certificate: "شهادة",
  certificates: "الشهادات",
  completed: "مكتمل",
  contentIsLoaded: "تم تحميل المحتوى",
  contentManager: "مدير المحتوى",
  courseContent: "محتوى الدورة التدريبية",
  courseFormat: "تنسيق الدورة التدريبية",
  coverPhoto: "صورة الغلاف",
  EditCoverPhoto: "تحرير صورة الغلاف",
  AddCoverPhoto: "إضافة صورة الغلاف",
  additionalDocuments: "وثائق إضافية",
  editAdditionalDocuments: "تحرير مستندات إضافية",
  addAdditionalDocuments: "إضافة مستندات إضافية",
  documents: "الوثائق",
  courseLables: "تسميات الدورة التدريبية",
  courseLanguage: "لغة الدورة",
  courseNotes: "ملاحظات الدورة التدريبية",
  courseStructure: "هيكل الدورة",
  courseTags: "علامات الدورة",
  courseTitle: "عنوان الدورة",
  createCourse: "إنشاء دورة تدريبية",
  curator: "أمين",
  description: "الوصف",
  details: "التفاصيل",
  dueDate: "تاريخ الاستحقاق",
  duration: "المدة",
  exportStatistics: "إحصاءات التصدير",
  learningStandard: "معيار التعلم",
  licenses: "التراخيص",
  mandatory: "إلزامي",
  noAssessment: "لا توجد تقييمات للتقييم",
  noCoursesAssigned: "لا توجد دورات مخصصة لبرنامجك يرجى زيارة",
  noCoursesAvailable: "لا توجد دورات متاحة حاليا",
  noDueDate: "لا يوجد تاريخ استحقاق",
  overview: "نظرة عامة",
  page: "صفحة",
  pickAnAccount: "اختر حسابا",
  printCertificate: "طباعة",
  publishCourse: "نشر الدورة التدريبية",
  publishedToAccount: "تم النشر في الحساب",
  relatedCourses: "دورات ذات صلة",
  remove: "إزالة",
  removeFromProgram: "إزالة من البرنامج",
  singleAccount: "حساب واحد",
  startCourse: "ابدأ",
  successMessageOne: "تهانينا  لقد أكملت الدورة بنجاح",
  successMessageThree: "الآن يمكنك تنزيل أو طباعة شهادة إتمام الدورة",
  successMessageTwo: " !",
  tags: "العلامات",
  unpublishCourse: "دورة إلغاء النشر",
  updateCourseContent: "تحميل محتوى الدورة التدريبية",
  updateCourse: "تحديث الدورة",
  youDidntfinishCourse: "أنت لم تنته من أي دورة",
  courseVisibleFor: "الدورة مرئية ل",
  courseAssigmentOptions: "خيارات مهام المقرر الدراسي",
  assignCourse: "تعيين دورة تدريبية",
  pickACourse: "اختر دورة",
  noDocumentsAvailable: "لا توجد وثائق متاحة",
  nothingRelated: "لا شيء ذي صلة",
  nothingWritten: "لا شيء مكتوب",
  noTagsAvailable: "لا توجد علامات متاحة",
  alreadyGlobal: "تم نشر الدورة بالفعل على مستوى العالم",
  questionsanswered: "أسئلة",
  completedOn: "اكتمل في",
  locale: "en-EN",
  quizAuthorizationContent: "تراخيص الاختبار",

  courseNotPublished: "الدورة التدريبية غير منشورة حاليا",
  courseNotPublishedForAccount:
    "لم يتم نشر الدورة التدريبية حاليا للحساب $account",
  clickToPublishCourse: "نشر الدورة التدريبية",
  visible: "مرئية",
  userIsNotContentManager:
    "أنت غير مدرج كمدير محتوى وبالتالي لا يمكنك تحرير محتوى هذه الدورة التدريبية",

  assignmentOpen: "مفتوح",
  assignmentStarted: "بدأت",
  assignmentCompleted: "مكتمل",
  exitCourse: "الخروج من الدورة",
  markAsFinished: "وضع علامة على أنه منتهي",

  assignToAllUsers: "تعيين دورة لجميع المستخدمين في الحساب",
  learningPoints: "نقاط التعلم",
  back: "العودة",
  next: " التالي",

  system: " النظام ",
  configureLogging: " تكوين التسجيل ",
  changeAccount: " تغيير الحساب",
  deleteAccount: " حذف الحساب",
  importAccountStructure: " بنية حساب الاستيراد",
  importUser: " استيراد المستخدمين",
  changeUser: " تغيير المستخدم",
  deleteUser: " حذف المستخدم",
  assignUserRole: " تعيين دور المستخدم ",
  changeCourse: " تحرير الدورة ",
  addContent: " إضافة محتوى",
  changeContent: " تعديل المحتوى",
  addQuizContent: " إضافة محتوى اختبار ",
  changeQuizContent: "تغيير محتوى الاختبار",
  deleteCourse: "حذف المقرر الدراسي",
  deleteContent: "حذف المحتوى",
  unassignCourse: "إلغاء تعيين المقرر الدراسي",
  systemMessages: "رسائل النظام",

  learningManagement: "إدارة التعلم",
  courseManagement: "إدارة الدورة",
  userManagement: "إدارة المستخدمين",
  organizationStructure: "الهيكل التنظيمي",
  accountManagement: "إدارة الحساب",

  learningManagementDetail: "خطط التعلم ومهام الدورة",
  courseManagementDetail: "إدارة محتوى الدورة التدريبية",
  userManagementDetail: "إدارة المستخدم وإعادة تعيين كلمة المرور",
  organizationStructureDetail: "تعريف الهيكل التنظيمي",
  accountManagementDetail: "إدارة الحسابات",
  logsDetail: "سجلات النظام والإعدادات",
  noResults: "لا توجد نتائج",
  noData: "لا توجد بيانات متاحة",

  userCreateSuccess: "تم إنشاء المستخدم بنجاح",
  createOtherUser: "إنشاء مستخدم آخر",
  backToUserOverview: "العودة إلى نظرة عامة على المستخدم",
  currentRoles: "الأدوار الحالية",

  numberOfRoles: "عدد الأدوار",
  numberOfUsers: "المستخدمون",
  deleteAccountMessage: "يرجى تأكيد رغبتك في حذف الحساب '$account'",
  showStatistics: "الإحصاءات",
  appendAccount: "إلحاق الحساب $account",

  courseAssignments: "المهام",
  courseAssignmentsForCourse: "مهام الدورة التدريبية $course",
  dueDateAssignments: "مع تاريخ الاستحقاق",
  dueDatePassed: "مر تاريخ الاستحقاق",

  userOrAccount: "المستخدم أو الحساب",
  expiryDate: "تاريخ انتهاء الصلاحية",

  deletePublication: "دورة إلغاء النشر",
  deletePublicationMessage:
    "هل أنت متأكد من رغبتك في إلغاء نشر الدورة التدريبية لمدة $account؟",
  orgStructure: "القسم",
  type: "النوع",
  downloadTemplate: "تنزيل القالب",
  published: "نشرت",
  scorm: "SCORM",
  quiz: "اختبار",
  staticFile: "ملف PDF",
  editPublication: "تحرير المنشور",
  pleaseDefineCourseContent: "يرجى تحديد محتوى الدورة التدريبية قبل نشرها",
  usernameMissing: "اسم المستخدم مفقود",
  usernameError: "يمكن أن يحتوي اسم المستخدم على أحرف أبجدية رقمية فقط",
  emailMissing: "البريد الإلكتروني مفقود",
  emailError: "عنوان بريد إلكتروني غير صالح",
  passwordMissing: "كلمة المرور مفقودة",
  passwordError:
    "يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل  حرف كبير وحرف صغير",

  registrationKeyManagement: "مفاتيح التسجيل",
  registrationKeyManagementDetail: "إدارة مفتاح التسجيل",

  addRegistrationKey: "إدارة مفتاح التسجيل",
  registrationLink: "رابط التسجيل",
  createKey: "إنشاء مفتاح",
  user_upload_maximum: "ما يصل إلى 1000 مستخدم لكل تحميل",

  userState: "حالة المستخدم",
  userStateDetail: "تصريح حالة الوصول والدورات الإلزامية",
  testsPassed: "اجتاز الاختبار",
  access: "الوصول",
  downloadReport: "تقرير",
  success: "تم الحفظ بنجاح!",
  warning: "تحذير!",
  error: "خطأ!",
  removedFromProgram: "تمت إزالة الدورة التدريبية من برنامجك",
  addedToProgram: "تمت إضافة الدورة التدريبية إلى برنامجك",
  courseCreated: "تم إنشاء الدورة",
  courseNotCreated: "لم يتم إنشاء الدورة التدريبية",
  fillUpFields: "يرجى ملء الحقول المطلوبة",
  cookieNotAvailable:
    "معلومات تسجيل الدخول الخاصة بك غير متوفرة أو قديمة جدًا ، يرجى تسجيل الدخول مرة أخرى",
  courseNotFinished: "لم تقدم الدورة أي معلومات عن الإكمال",
  courseAborted: "تم إجهاض الدورة",
  correctAnswer: "الإجابة الصحيحة",
  wrongAnswer: "إجابة خاطئة",
  courseStarted: "لقد بدأت الدورة",
  testFailed: "فشل الاختبار",
  imageTooLarge: "يجب أن تكون الصورة أقل من 2 ميغابايت",
  wrongFormat: "تنسيق خاطئ",
  passwordReset:
    "تم إرسال ارتباط لإعادة تعيين كلمة المرور إلى عنوان البريد الإلكتروني",
  passwordChanged: "تم تغيير كلمة المرور",
  accountAdded: "تمت إضافة الحساب",
  accountUpdated: "تم تحديث الحساب",
  accountDeleted: "تم حذف الحساب",
  userImported: "تم استيراد المستخدمين",
  userNotImported: "لم يتم استيراد المستخدمين",
  loggingConfigSaved: "تم حفظ تكوين التسجيل",
  userDeleted: "تم حذف المستخدم",
  userAdded: "تمت إضافة المستخدم",
  userUpdated: "تم تحديث المستخدم",
  GDPRLoaded: "تم تحميل اللائحة العامة لحماية البيانات",
  cookiesDisabled: "لقد قمت بتعطيل ملفات تعريف الارتباط",
  fileUploaded: "تم تحميل ملفك",
  courseContentUpdated: "تم تحديث محتوى الدورة التدريبية",
  coursePublished: "تم نشر الدورة",
  courseUnPublished: "الدورة التدريبية التي لم يتم نشرها بنجاح",
  serverUnreachable: "لا يمكن الوصول إلى الخادم",
  courseContentAdded: "تمت إضا+فة محتوى الدورة التدريبية",
  user_upload_exceeded: "عدد كبير جدا من المستخدمين",

  selectAccounts: "اختر حسابا",
  selectAccountToContinue: "حدد حسابا للمتابعة",
  courses: "الدورات",
  coursesCompleted: "الدورات المكتملة",
  completedCoursesPerUser: "الدورات التدريبية المكتملة لكل مستخدم",
  learningHours: "ساعات التعلم",
  learningHoursPerUser: "ساعات التعلم لكل مستخدم",
  assigned: "معين",
  courseDetails: "تفاصيل الدورة",
  selectCourse: "اختر الدورة",
  averageScore: "متوسط الدرجات",
  averageDuration: "متوسط المدة",
  account: "الحساب",
  AccountName: "اسم الحساب",
  accounts: "الحسابات",
  active: "نشط",
  admin: "المشرف",
  all: "الكل",
  not_found: "لم يتم العثور على الصفحة",
  assessmentStatistics: "إحصاءات التقييم",
  assignedUsersOnly: "المستخدمون المعينون فقط",
  autoIndividual: "تلقائي  فردي",
  cancel: "إلغاء",
  cardImage: "صورة البطاقة",
  close: "إغلاق",
  reset: "إعادة تعيين",
  confirm: "تأكيد",
  country: "البلد",
  course: "الدورة",
  courseDescription: "وصف الدورة",
  courseDocuments: "وثائق الدورة",
  dataProtection: "حماية البيانات",
  dragAndDrop: "السحب والإفلات أو النقر للإضافة",
  email: "البريد الإلكتروني",
  empty: "فارغة",
  exit: "الخروج",
  expiresOn: "تنتهي صلاحيته",
  fileImport: "ملف للاستيراد",
  image: "صورة",
  inactive: "غير نشط",
  language: "اللغة",

  monthsLabels: [
    "جانفي",
    "فبراير",
    "مارس",
    "أفريل",
    "ماي",
    "جوان",
    "جويلية",
    "أوت",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ],
  myAccount: "حسابي",
  myProfile: "ملفي الشخصي",
  myProgram: "برنامجي",
  myRoles: "أدواري",
  never: "أبدا",
  no: "لا",
  none: "لا شيء",
  notFound: "غير موجود",
  phoneNumber: "رقم الهاتف",
  playImage: "تشغيل الصورة",
  publishingExpiresOn: "تنتهي صلاحية النشر في",
  publishTo: "نشر إلى",
  reports: "التقارير",
  save: "حفظ",
  search: "بحث",
  status: "الحالة",
  structureItem: "عنصر الهيكل",
  termsAndConditions: "الشروط والأحكام",
  unlimited: "غير محدود",
  update: "تحديث",
  user: "المستخدم",
  visibility: "رؤية الحساب",
  yes: "نعم",
  zipCode: "الرمز البريدي",
  navigation: "التنقل",
  info: "معلومات",
  alert: "تنبيه",
  criticalAlert: "تنبيه حرج",
  note: "Note",
  userId: "معرف المستخدم",
  organizationalStructure: "الهيكل التنظيمي",
  created: "تم الإنشاء",
  new: "جديد",
  uploadFile: "تحميل ملف",
  id: "المعرف",
  name: "الاسم",
  delete: "حذف",
  append: " إلحاق",
  edit: " تحرير",
  format: "الشكل",

  years: "سنوات",
  months: "أشهر",
  weeks: "أسابيع",
  days: "أيام",
  seconds: "ثواني",
  minutes: "دقائق",
  hours: "ساعات",
  date: "التاريخ",
  message: "رسالة",
  fax: "فاكس",

  genericError:
    "حدث خطأ  حاول إعادة تحميل الصفحة إذا استمر الخطأ  فاتصل بمسؤول النظام",
  action: "العمل",
  // TODO
  roles: "الأدوار",
  properties: "الخصائص",
  property: "الملكية",
  assignUserProperty: "تعيين خاصية للمستخدم",
  userProperties: "تعيين خاصية للمستخدم",
  addTagToUser: "إضافة خاصية إلى المستخدم",
  showMoreFilters: "إظهار المزيد من الفلاتر",
  unassigned: "غير معين",
  assignedOrUnassigned: "معين/غير معين",
  emailAboutChange: "إرسال إشعار بالبريد الإلكتروني للمستخدمين",
  showLessFilters: "إظهار فلاتر أقل",
  clearFilters: "مسح الفلاتر",

  no_changes_assignment_button: "لا تغييرات",
  assignment: "التنازل",

  assign_course: "تعيين دورة تدريبية",
  add_assignment_to_users: "تعيين إلى $users",
  remove_assignments_from_users: "إزالة المهمة من $users",
  update_assignments_for_users: "تحديث $users الواجبات",
  create_property: "إنشاء خاصية جديدة '$property'",
  non_mandatory: "غير إلزامي",
  department: "القسم",
  user_details: "تفاصيل المستخدم",
  download_file: "تنزيل الملف",
  your_browser_not_support_this_content: "متصفحك لا يدعم عرض هذا المحتوى",
  browser_not_support_pdf:
    "يتعذر على متصفحك عرض ملفات pdf\n قم بالتمرير لأسفل لتنزيل ملف pdf بدلا من ذلك",
  upload_file: "تحميل ملف",
  deleteDocument: "حذف المستند",
  deleteDocumentConfirmation: "هل أنت متأكد من أنك تريد حذف $document؟",
  video_unsupported: "متصفحك لا يدعم فيديو HTML5",
  confirme: "تأكيد",
  confirmed: "مؤكد",
  multiselect: "السماح باختيار أماكن إقامة متعددة",
  profile_image: "صورة الملف الشخصي",
  account_details: "تفاصيل الحساب",
  mandatory_user_properties: "خصائص المستخدم الإلزامية",
  published_courses_for_account: "الدورات المنشورة",
  select_property_first: "الرجاء اختيار عقار",
  selected_property_already_mandatory: "الخاصية المحددة إلزامية بالفعل",
  remove_assigned_properties: "إزالة الخصائص المعينة",
  property_type: "نوع العقار",
  confirm_remove_assigned_properties:
    "يرجى تأكيد رغبتك في إزالة جميع الخصائص المعينة حاليا من النوع $type لا يمكن التراجع عن هذا الإجراء",
  tags_removed_from_users: "تمت إزالة جميع الخصائص المخصصة من النوع $type",
  add_mandatory_user_property: "إضافة خاصية مستخدم إلزامية",
  upload_certificate: "تحميل الشهادة",

  uploadCertificates: "Certificats téléchargés",
  delete_certificate: "حذف الشهادة",
  confirm_delete_certificate:
    "هل أنت متأكد من أنك تريد حذف الشهادة التي تم تحميلها $certificateName؟",
  certificateName: "اسم الشهادة",
  issuedFrom: "صدر من",
  ectsPoints: "نقاط ECTS",
  ects: "ECTS",
  issuedAt: "صدر في",
  receivedAt: "تم الاستلام في",
  validUntil: "صالح حتى",
  unlimitedValidity: "صلاحية غير محدودة",

  completedInPercent: "مكتمل بالنسبة المئوية",
  uploadedCertificates: "الشهادات التي تم تحميلها",
  drag_drop_steps: "اسحب الخطوات وأفلتها من اليسار لإضافتها إلى درس",
  add_lesson: "أضف درسا",
  minCompletion: "الحد الأدنى للنسبة المئوية للنقاط لإكمال الدرس",
  randomStepOrder: "ترتيب الخطوات عشوائيا",
  fixedViewExecutionOrder: "ترتيب الصفحات عشوائيا",
  allowBackNavigation: "السماح بالتنقل مرة أخرى",
  maxTimeForCompletions: "الحد الأقصى لوقت الانتهاء بالدقائق",
  maxRetries: "الحد الأقصى لعدد مرات المحاولة",
  maxNumberOfCompletions: "الحد الأقصى لعدد الأشواط لتحسين النتيجة",
  text: "النص",
  multimedia: "ملتيميديا",
  pdf: "ملف pdf",
  help: "مساعدة",
  inputText: "إدخال النص",
  inputDate: "إدخال التاريخ",
  quiz_description:
    "اطرح سؤالا واسمح للمستخدم بالاختيار من بين خيارات إجابة متعددة",
  text_description: "اعرض نصا للمستخدم ودعه يؤكد اختياريا على محتواه",
  multimedia_description:
    "عرض فيديو للمستخدم والسماح له اختياريا بتأكيد أنه شاهد المحتوى",
  pdf_description:
    "عرض فيديو للمستخدم والسماح له اختياريا بتأكيد أنه شاهد المحتوى",
  inputText_description: "السماح للمستخدم بإدخال بعض النصوص",
  inputDate_description: "السماح للمستخدم بإدخال تاريخ",
  lessons: "الدروس",
  new_lesson: "درس جديد",
  settings: "الإعدادات",
  requiresCompletion: "يجب إكمال هذه الخطوة للمتابعة",
  requiresInteraction: "يجب على المستخدم تأكيد أنه شاهد هذه الخطوة",
  isEditable: "يمكن للمستخدم تعديل هذه الخطوة",
  points: "نقاط ",
  clickToEdit: "(انقر للتحرير)",
  choiceSettings: "إعدادات الاختيار",
  choice: "الاختيار",
  choiceText: "اختيار $index النص",
  choiceIsCorrect: "الاختيار الصحيح",
  addChoice: "إضافة خيار",
  deleteChoice: "حذف الاختيار",
  uploadVideoFile: "تحميل ملف فيديو",
  discardChanges: "تجاهل التغييرات",
  addInputLabel: "(إضافة تسمية)",
  inputType: "نوع الإدخال",
  week: "أسبوع",
  month: "الشهر",
  year: "السنة",
  label: "التسمية",
  minDate: "الحد الأدنى للتاريخ",
  maxDate: "الحد الأقصى للتاريخ",
  oneLine: "خط واحد",
  multipleLine: "خطوط متعددة",
  rows: "خطوط متعددة",
  minLength: "الحد الأدنى للطول",
  maxLength: "الحد الأقصى للطول",
  showWordLimit: "إظهار عدد الأحرف المسموح به",
  lessonFailedNoMoreRetries: "لقد فشلت في الدرس بعد $retries المحاولات",
  lessonFailedMoreRetries: "لديك $retries المزيد من المحاولات لإنهاء الدرس",
  lessonFailedOneMoreRetries: "لديك محاولة أخرى لإنهاء الدرس",
  registrationKeyAlphanumeric:
    "مفتاح التسجيل (أبجدي رقمي الحد الأدنى من أحرف {الطول})",
  registrationKeyMinimumLength:
    "يجب أن يحتوي مفتاح التسجيل على أحرف {طول} على الأقل",
  chooseAnAccountToContinue: "حدد حسابا للمتابعة",

  alreadyRegistered: "مسجل بالفعل",
  additionalDetails: "تفاصيل إضافية",
  additionalDetailsSave: "تأكيد",
  upload: "تحميل",
  uploadVideo: "تحميل الفيديو",
  uploadPdf: "تحميل ملف pdf",
  step: "خطوة",
  steps: "خطوات",
  flexViews: "الصفحات",
  flexView: "الصفحة",
  lesson: "الدرس",
  dragInThisAreaToDelete: "سحب العناصر وإفلاتها في هذه المنطقة لحذفها",
  editor: "محرر",
  baseLanguage: "اللغة الأساسية",
  translation: "الترجمة",
  editCourse: "تحرير الدورة",
  editTranslation: "تحرير الترجمات",
  dragAndDropCertificate: "سحب وإسقاط ملف PDF هنا أو انقر للإضافة",
  userProfileV2: "ملف تعريف المستخدم V2",
  issuedBy: "صادر عن",
  issuedOn: "صدر في",
  fulfilledInPercent: "تم الوفاء بها في المئة",

  fileName: "اسم الملف",
  fileSize: "حجم الملف",
  certification: "شهادة",
  systemLogs: "سجلات النظام",
  systemConfigurationDetail: "تكوين إعدادات النظام",
  systemConfiguration: "تكوين النظام",

  views: "تكوين النظام",
  apis: "واجهات برمجة التطبيقات",
  apisDetail: "إدارة مفاتيح واجهة برمجة التطبيقات",
  logs: "السجلات",
  viewsDescription: "رؤية العنصر في العرض المعني",
  courseFormatDescription:
    "تنسيقات الدورة التدريبية المتاحة لتحديد الدورة التدريبية",
  accessCard: "بطاقة الدخول",
  downloadCertification: "تنزيل الشهادة",
  pointTracking: "نقاط العرض",
  repeatable: "قابل للتكرار",
  plusNewTag: "+ علامة جديدة",
  orSeparator: "أو",

  contentManagerPicker: "مدير المحتوى",
  saveChangesTitle: "حفظ التغييرات",
  saveChangesBody:
    "لديك بعض التغييرات غير المحفوظة\nهل يجب حفظ هذه التغييرات أو تجاهلها؟",
  flexLessonLocked: "يجب إنهاء الدرس قبل أن يتمكن المستخدم من المتابعة",
  flexSubset: "عدد الصفحات التي يتم اختيارها عشوائيا من جميع الصفحات في الدرس",
  retry: "إعادة المحاولة",
  editLesson: "إعادة المحاولة",
  templates: "قوالب",

  curatorName: "اسم الأمين",
  curatorCompany: "شركة أمين",
  curatorDescription: "وصف القيم",
  courseCompletedTitle: "عنوان الدورة المكتملة",
  courseCompletedSubtitle: "العنوان الفرعي للدورة المكتملة",
  courseCompletedNote: "الهيئة المكتملة للدورة",
  completionShowScore: "إظهار النتيجة في عرض إكمال المقرر الدراسي",
  failedCourseInformation: "نص لإظهاره عندما يفشل المستخدم في الدورة التدريبية",
  pinPage: "اعرض هذه الصفحة دائما في بداية الدرس أو نهايته",
  start: "ابدأ",
  end: "النهاية",
  courseDuration: "مدة الدورة بالدقائق",
  randomizeAnswers: "إجابات عشوائية",
  closeEditor: "إغلاق المحرر",
  courseNotEditable:
    "هذه الدورة قيد الاستخدام بالفعل وبالتالي يمكن تحرير الترجمات فقط",
  flex: "فليكس",
  event: "الحدث",

  copied: "تم نسخ الرابط إلى الحافظة",
  covidCertificates: "شهادات التطعيم",
  covidCertificatesDetail: "إدارة شهادات التطعيم والاختبار",
  covidInstructions: "تعليمات COVID-19",
  covid: "كوفيد-19",
  addCertificate: "إضافة شهادة",
  scanQrCode: "مسح رمز الاستجابة السريعة",
  scanQrCodeDescription: "يمكن مسح رموز QR الخاصة ب folowing أو تحميلها",
  openFile: "فتح ملف",
  openImage: "فتح ملف الصورة",
  validFrom: "صالح في $date",
  validFor: "Gueltig am $date",
  vaccinationCertificatesValid: "شهاداتك متوافقة مع القواعد الحالية",
  vaccinationCertificatesInvalid: "شهاداتك غير متوافقة مع القواعد الحالية",
  certificateInvalid: "هذه الشهادة غير صالحة",
  certificateExpired: "انتهت صلاحية هذه الشهادة",
  updatedOn: "تم التحديث في $date",
  uploadinCertificate: "تحميل الشهادة",
  certificateUploadError: "حدث خطأ أثناء تحميل الشهادة",
  certificateNameMismatch: "اسم الشهادة غير متطابق",
  certificateBirthdayMismatch: "عيد ميلاد الشهادة غير متطابق",
  certificateExists: "هذه الشهادة موجودة بالفعل",
  certificateUploaded: "تم تحميل الشهادة بنجاح",
  instructions: "تعليمات",
  accessRules: "قواعد الوصول",
  selectAccessRule: "رمز الاستجابة السريعة",
  qrCode: "رمز الاستجابة السريعة",
  certificateDeleted: "تم حذف الشهادة",
  deleteCertificateConfirmation: "هل أنت متأكد من أنك تريد حذف هذه الشهادة؟",
  isOnlineEvent: "حدث عبر الإنترنت",
  eventLink: "رابط الحدث",
  eventLocation: "الموقع",
  selfRegistration: "التسجيل الذاتي",
  attendanceConfirmation: "الحضور",
  passedConfirmation: "تمرير",
  mark: "مارك",
  trainer: "المدرب",
  attendanceAndGrades: "الحضور والتقييم",
  onlyIfMandatoryCourse: "إذا تم تعيين الدورة التدريبية",
  allUsersInAccount: "جميع المستخدمين في الحساب",
  dateManagement: "إدارة التمور",
  dateManagementDetail: "إدارة تواريخ الأحداث ونتائجها",
  courseFilter: "مرشح الدورة التدريبية",

  fromTo: "من - إلى",
  capacity: "القدرة",
  unlimitedCapacity: "سعة غير محدودة",
  invalidatePreregistration:
    "إبطال التسجيلات المسبقة في الساعات التي تسبق الحدث",
  allowPreregistration: "السماح بالتسجيل المسبق",
  newDate: "تاريخ جديد",
  editDate: "تعديل التاريخ",
  preenrollments: "التسجيل المسبق",
  enrollments: "التسجيل",
  preregistrationDate: "$date التسجيل المسبق",
  registered: "مسجل",
  notRegistered: "غير مسجل",
  registeredAndNotRegistered: "مسجل / غير مسجل",
  selectCourseToContinue: "حدد دورة للمتابعة",
  changesSavedSuccessfully: "تم حفظ التغييرات بنجاح",
  cancelRegistrationTitle: "إلغاء التسجيل",
  cancelRegistrationBody:
    "هل أنت متأكد من أنك تريد إزالة تسجيل $name للتاريخ التالي؟",
  dates: "التواريخ",
  unregister: "إلغاء التسجيل",
  changeRegistrationTitle: "تغيير التسجيل",
  currentRegistration: "$name مسجل بالفعل للتاريخ التالي",
  changeRegistrationBody:
    "هل تريد إلغاء هذا التسجيل وإنشاء تسجيل للتاريخ التالي بدلا من ذلك؟",
  preregistrationAvailableRegistrations: "التسجيل المسبق / المقاعد المتاحة",
  seatsLeft: "المقاعد المتاحة",
  registrationTitle: "التسجيل",
  registrationBody:
    "من خلال تسجيل المستخدم في $course سيتم إنشاء مهمة إلزامية لتلك الدورة التدريبية للمستخدم",
  register: "التسجيل",
  myDates: "مواعيدي",
  dateDetails: "تفاصيل التاريخ",
  attendees: "الحضور",
  fractionOfTotal: "$fraction $total",
  registrations: "التسجيلات",
  link: "الرابط",
  notPassed: "لم يتم تمريره",
  passed: "مرت",
  attended: "حضر",
  notAttended: "لم يحضر",
  youAreRegisteredFor: "أنت مسجل في التاريخ التالي",
  unlimitedSeats: "سعة غير محدودة",
  otherDates: "تواريخ أخرى",
  managerWillAssignDate: "سيحدد مديرك موعدا لك",

  courseCompletionsPerUser: "إكمال الدورة التدريبية لكل مستخدم",
  accountStatistics: "إحصاءات الحساب",
  reportExcelDownloadBtn: "تنزيل تقرير Excel",
  reportDisplayBtn: "العرض",
  covidReport: "تقرير كوفيد",
  reportExcelCourseCompletion: "واجب الدورة وتقرير إتمامها",
  reportExcelPracticalTests: "تقرير الأحداث",
  listOfAccomplishedCourses:
    "قائمة إنجازات الدورة الإلزامية والاختيارية حسب المستخدم",
  reportUserState: "قائمة حالة وصول المستخدم وحالة الدورات الإلزامية المعينة",
  descriptionCovidReport: "قائمة شهادات التطعيم ضد COVID",
  descriptionCourseAssignmentsAndCompletion:
    "قائمة مهام الدورة التدريبية وحالة إكمال الدورة التدريبية الحالية",
  descriptionEventReport: "قائمة تسجيل الحدث والحضور",
  descriptionAccountStatistics:
    "مؤشرات الأداء الرئيسية الخاصة بالحساب وإحصائيات الدورات المنشورة للحساب المحدد",
  userRoleAssignments: "تعيينات دور المستخدم",
  listOfRolesPerUser: "قائمة الأدوار المعينة للمستخدم حسب الحساب",
  covidInstructionsLabel: "تعليمات في $lang اللغة الافتراضية للنظام",
  cameraNotAllowedError:
    "تم رفض الوصول إلى الكاميرا أعد تحميل الصفحة للسماح بالوصول أو السماح بالوصول في إعدادات المتصفح ",
  cameraNotFoundError:
    "تعذر الوصول إلى الكاميرا - يرجى التأكد من عدم استخدام أي تطبيق آخر للكاميرا",
  cameraNotReadableError:
    "متصفحك لا يدعم الوصول إلى الكاميرا يرجى استخدام وظيفة التحميل بدلا من ذلك",
  cameraNotAvailable:
    "متصفحك لا يدعم الوصول إلى الكاميرا يرجى استخدام وظيفة التحميل بدلا من ذلك",
  qrCodeUploadError:
    "تعذر قراءة رمز الاستجابة السريعة  يرجى التحقق من أن الصورة تحتوي على رمز QR واحد فقط  وأن رمز الاستجابة السريعة قابل للقراءة إذا استمرت المشكلة  فجرب صورة عالية الدقة",
  confirmCovidRulesManuallyTitle: "COVID-19 - التحقق من الحالة البصرية",
  confirmCovidRulesBody:
    "أؤكد أن المستندات المقدمة من $user تفي بأحكام COVID-19 الحالية وصالحة لليوم الحالي",
  activateApis: "تنشيط واجهات برمجة التطبيقات",
  allowApiManagement: "السماح بإدارة واجهة برمجة التطبيقات",
  allowedApis: "واجهات برمجة التطبيقات المسموح بها",
  volume: "الحجم في req / يوم",
  throttle: "خنق في req / دقيقة",

  keys: "مفاتيح",
  key: "مفتاح",
  keyName: "الاسم",
  keyPurpose: "الغرض",
  createApiKey: "إضافة مفتاح API",
  keyCopiedToClipboard: "تم نسخ المفتاح بنجاح إلى الحافظة",
  deleteKey: " حذف المفتاح",
  deleteKeyMessage:
    "هل أنت متأكد من أنك تريد حذف المفتاح' $name 'ل' $purpose '؟' ",
  changeKey: "تحرير المفتاح",
  keyCreatedSuccessfully: "تم إنشاء مفتاح api بنجاح",
  api: "واجهة برمجة التطبيقات",
  addApi: "إضافة واجهة برمجة التطبيقات",
  editApi: "تحرير واجهة برمجة التطبيقات",
  example: "مثال",
  apiDetails: "تفاصيل واجهة برمجة التطبيقات",
  notAvailable: "غير متوفر",
  method: "الطريقة",
  requestBody: "طلب الهيئة",
  responses: "الردود",
  parameters: "المعلمات",
  manualCheck: "الفحص اليدوي",
  qGateTitle: "فحص جودة بيانات المستخدم",
  qGateDescription:
    "إذا تم تنشيط Q-Gate  يطلب التحكم في البوابة للتحقق من جودة بيانات المستخدم وقد يضيف أيضا تفاصيل إضافية إلى بيانات المستخدم",
  subcontractorTitle: "إظهار علامة تبويب المقاول من الباطن",
  subcontractorDescription:
    "يسمح بإضافة مقاولين من الباطن إلى حساب بالإضافة إلى تعيين مستخدمين لمقاولين من الباطن",
  subcontractor: "مقاول من الباطن",
  subcontractors: "المقاولون من الباطن",
  addSubcontractor: "إضافة  المقاول من الباطن",
  editSubcontractor: "Edit  المقاول من الباطن",
  street: "الشارع",
  deleteSubcontractor: "حذف المقاول من الباطن",
  deleteSubcontractorBody: "الشركة",
  deleteSubcontractorHasReference:
    "المقاول من الباطن لديه بالفعل مستخدمون معينون له  وبالتالي لا يمكن حذفه",
  accessFrom: "الوصول من",
  accessTo: "النهاية المحتملة للوصول",
  birthplace: "مكان الميلاد",
  nationality: "الجنسية",
  car: "سيارة",
  carPlate: "لوحة الأرقام",
  socialSecurity: "رقم الضمان الاجتماعي",
  workPermit: "تصريح العمل حتى",
  confirmData: "أؤكد اكتمال وصحة البيانات المدخلة",
  accessRegistration: "تسجيل الوصول",
  registeredOn: "مسجل في",
  registeredBy: "مسجل بواسطة",
  isOnline: "عبر الإنترنت",
  coursePreRegistrationUsedFrom:
    "إجمالي عدد التسجيلات المسبقة ل '{courseName}' - {preRegTotal} على وشك الإلغاء",
  notEnoughCapacityReload:
    "لا توجد سعة مجانية كافية يتم إعادة تحميل القدرات الحالية",
  editUserData: "تعديل بيانات المستخدم",
  dateSpecificInstructions: "تعليمات خاصة بالحدث",
  preRegistrationClosed: "انتهت صلاحية التسجيل المسبق لهذا التاريخ",
  preRegistrationNotificationsTitle:
    "سيتم إبلاغ صاحب الحساب بالتسجيلات الحالية والتسجيلات المسبقة التي تنتهي خلال الأيام القادمة",
  preRegistrationNotificationsDescription:
    "سيتم إبلاغ مالك الحساب بالتسجيلات الحالية والتسجيلات المسبقة التي تنتهي صلاحيتها خلال الأيام القادمة",
  automaticAssignments: "الأتمتة",
  infoOnly: "معلومات فقط",
  automated: "آلي",
  assignAutomaticallyTo: "تعيين تلقائيا إلى",
  allUsers: "جميع المستخدمين",
  usersWithAssignedProperty: "المستخدمون الذين لديهم خاصية معينة",
  assignmentType: "نوع المهمة",
  optionally: "اختياريا",
  exactDate: "التاريخ المحدد",
  relativeDateOffsetDays: "نسبي (أيام الإزاحة)",
  emailNotification: "إشعار البريد الإلكتروني",
  selectPropertyMandatory: "اختر العقار",
  setAllSelected: "تعيين كل المحدد",
  nSelected: "{العدد} المحدد",
  publishedUnpublished: "منشورة/غير منشورة",
  unpublished: "غير منشورة",
  noneInfoAutomated: "لا شيء / معلومات / آلي",
  scope: "النطاق",
  tagged: "الموسومة",
  notification: "إشعارات",
  selectAll: "تحديد الكل",
  passwordResetLinkInactive:
    "رابط إعادة تعيين كلمة المرور هذا غير صالح بعد الآن",
  requestNewLink: "طلب رابط إعادة تعيين جديد",
  testQuestionnaire: "استبيان الاختبار",
  testQuestionnaireDetail: "إدارة استبيانات الاختبار للأحداث",
  newQuestionnaire: "استبيان جديد",
  newTestQuestionnaire: "استبيان اختبار جديد",
  addQuestion: "إضافة سؤال",
  editTestQuestionnaire: "تحرير استبيان الاختبار",

  requiredPoints: "النقاط المطلوبة",
  questions: "أسئلة",
  question: "سؤال",
  answer: "الجواب",
  responseDocumentation: "وثائق الاستجابة",
  ko: "كو",
  failedPassed: "فشل / اجتاز",
  failedPassedComment: "فشل / تم تمريره + تعليق",
  assignedCourses: "الدورات المخصصة",
  questionnaire: "استبيان",
  questionnaireLocked: "الاستبيان مقفل",
  born: "ولد",
  examinee: "الممتحن",
  totalResult: "النتيجة الإجمالية",
  comment: "تعليق",
  done: "تم",
  pointsRequired: "النقاط المطلوبة",
  correct: "صحيح",
  locked: "مقفل",
  locks: "أقفال",
  lockedAtCest: "مغلق في (CEST)",
  unlockCourse: "فتح بالطبع",
  unlockCourseMessage:
    "هل أنت متأكد من أنك تريد إلغاء حظر {0} الدورة التدريبية للمستخدم {1}؟",
  allowedNumberOfAttempts: "عدد المحاولات المسموح به",
  noDates: "لا توجد تواريخ حاليا",
  testResults: "نتائج الاختبار",
  pointScore: "{نقاط} نقاط",
  markScore: "علامة",
  registrationLocked: "التسجيل مغلق",
  registrationLockedMessage:
    "يتم حاليا تقييم نتائج الحدث سيكون تغيير التسجيل ممكنا عند الانتهاء من التقييم ",
  propertiesDetail: "إدارة خصائص المستخدم والمقرر الدراسي",
  types: "أنواع",
  addTagType: "إضافة نوع",
  editType: "تحرير النوع",
  create: "إنشاء",
  deleteTagType: "حذف نوع العلامة",
  confirmDeleteTagTypeName: "هل أنت متأكد من أنه يجب حذف النوع {name}؟",
  relatedProperties: "خصائص ذات صلة",
  addTagValue: "إضافة عقار",
  deleteTagValue: "حذف الموقع",
  confirmDeleteTagValueName: "هل أنت متأكد من أنه يجب حذف الخاصية {name}؟",
  editTagValue: "تعديل الموقع",
  purpose: "الغرض",
  uploadLabel: "تحميل التصنيف",
  tagLabelSizeError:
    "حجم ملف الصورة المحدد كبير جدا يتم دعم الصور التي يصل حجمها إلى 20 كيلوبايت فقط ",
  failed: "فشل",
  emergencyContact: "الاتصال في حالات الطوارئ",
  emergencyContactPhone: "رقم هاتف الاتصال في حالات الطوارئ",
  userHasNoAccount: "هذا المستخدم ليس لديه حساب",
  driveInPermit: "تصريح القيادة",
  downloadRegistrationForm: "استمارة التسجيل",
  accessStartDate: "تاريخ بدء الوصول",
  today: "اليوم",
  tomorrow: "غدا",
  dayAfterTomorrow: "يوم بعد غد",
  tests: "الاختبارات",
  createTest: "إنشاء اختبار",
  planned: "مخطط",

  myTests: "اختباراتي",
  houseNumber: "هاوسنو",
  actionResult: "العمل",
  actionResultKpi: "KPI",
  missedAfter: "تعتبر غاب بعد",
  testPerson: "شخص اختبار",
  createAndDocument: "إنشاء ووثيقة",
  eob: "EOB",
  testDate: "تاريخ الاختبار",
  test: "اختبار",
  testManagement: "إدارة الاختبار",
  testManagementDetail: "إدارة أنواع الاختبار",
  addTestType: "إضافة نوع الاختبار",
  testType: "نوع الاختبار",
  testTeam: "فريق الاختبار",
  storage: "التخزين",
  validity: "Validity",
  editTestType: "تعديل نوع الاختبار",
  testValidityInDays: "صلاحية الاختبار (أيام)",
  retentionPeriod: "فترة الاحتفاظ",
  createTestType: "إنشاء نوع اختبار",
  authorizedTesters: "المختبرون المعتمدين",
  testAlertReceivers:
    "تنبيه أجهزة الاستقبال على نتيجة إيجابية (عناوين البريد الإلكتروني  مفصولة بفواصل)",
  documentTestResult: "نتيجة اختبار المستند",
  confirmCorrectnessTestResultData: "يرجى تأكيد صحة البيانات المدخلة ",
  testCreated: "اختبار إنشاء",
  testResultDocumented: "تم حفظ نتيجة الاختبار",
  result: "النتيجة",
  expiresIn: "تنتهي صلاحيته",
  deleteTestType: "حذف نوع الاختبار",
  deleteTestTypeMessage: "هل أنت متأكد من أنك تريد حذف نوع الاختبار {0}؟",
  positive: "إيجابي",
  negative: "سلبية",
  testResult: "نتيجة الاختبار",
  open: "مفتوح",
  testReportHeader: "جدول الاختبار والنتائج",
  testReportBody:
    "نظرة عامة على الاختبارات المجدولة ونتائج الاختبار حسب المستخدم والتاريخ",
  display: "العرض",
  testReportEnterData: "يرجى تحديد تاريخ البدء / الانتهاء ونوع الاختبار",
  startDate: "تاريخ البدء",
  endDate: "تاريخ الانتهاء",
  searchUser: "البحث عن مستخدم",
  completedAt: "مكتمل",
  enterAtLeastTwoLetters: "أدخل حرفين على الأقل",
  testsLimitExceeded:
    "يحتوي استعلامك على عدد كبير جدا من النتائج  ويقتصر العرض على 2000 نتيجة",
  missed: "غاب",
  scheduled: "المقرر",
  saveAndApply: "حفظ وتطبيق",
  errorApplyingRule: "خطأ في تطبيق القاعدة حاول مرة أخرى في غضون بضع دقائق",
  triggerAllSelected:
    "تشغيل التشغيل التلقائي للمستخدمين الحاليين في الحسابات المحددة",
  assignmentRuleApplied: "تم تطبيق قواعد التنازل",
  selectAccountWithAutomationToContinue:
    "حدد حسابا واحدا على الأقل مع قاعدة الأتمتة ليتم تشغيلها",

  automationCenter: "مركز الأتمتة",
  automationCenterDetail: "إنشاء وإدارة القواعد الآلية",
  newRule: "قاعدة جديدة",
  topic: "الموضوع",
  newTestCenterAutomationRule: "قاعدة أتمتة مركز الاختبار الجديدة",
  global: "عالمي",
  accountSpecific: "حساب محدد",
  selectTemplate: "حدد القالب",
  lastRun: "الجولة الأخيرة",
  rule: "القاعدة",
  reportIsBeingCreated: "يتم إنشاء التقرير",
  selectedAccounts: "الحسابات المختارة",
  mo: "الاثنين",
  tu: "الثلاثاء",
  we: "الأربعاء",
  th: "الخميس",
  fr: "جمعة",
  sa: "السبت",
  selectTopic: "اختر الموضوع",
  deleteAutomationRule: "حذف قاعدة الأتمتة",
  deleteAutomationRuleConfimration:
    "هل أنت متأكد من أنك تريد حذف القاعدة {rule}؟",
  editAutomation: "تحرير قاعدة الأتمتة",
  triggerAt: "الزناد في",
  addCoverPhoto: "إضافة صورة غلاف",
  confirmDelete: "تأكيد الحذف",
  confirmDeleteTest:
    "هل أنت متأكد من أنك تريد حذف الاختبار {type} في {date} ل {name}؟",
  miscellaneous: "متفرقات",
  triggeredBy: "Triggered by",
  criticality: "حرج",
  logDetails: "تفاصيل السجل",
  lastHour: "الساعة الأخيرة",
  last24Hours: "آخر 24 ساعة",
  last3Days: "آخر 3 أيام",
  lastWeek: "الأسبوع الماضي",
  accountsImported: "الحسابات المستوردة",
  importMoreAccounts: "استيراد المزيد من الحسابات",
  backToOrganization: "العودة إلى الهيكل التنظيمي",

  retention: "الاحتفاظ",
  maximumAllowed: "الحد الأقصى المسموح به هو {الحد الأقصى}",
  completionValidity: "مدة الصلاحية",
  completionValidityinCertificate: "عرض مدة الصلاحية في الشهادة",
  useTranslationsToEdit: "الرجاء استخدام محرر الترجمات",
  take_picture: "التقاط صورة",
  upload_picture: "تحميل الصورة",

  logout: "تسجيل الخروج",
  continue: "متابعة",
  courseFinishedDoYouWantToLogout:
    "تم الانتهاء من الدورة. هل ترغب في تسجيل الخروج أو المتابعة؟ سيتم تسجيل خروجك تلقائيًا في غضون {seconds}.",
  stateless: "عديم الجنسية",
};
