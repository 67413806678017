<template lang="pug">
.pdf-step
  .text-container.mb-1.ql-editor(v-html="data.text")
  .flex-center.flex-col.vertical-list(v-loading="data.loadingState.loading")
    object.pdf-content.mb-1(
      ref="pdfObj",
      :data="data.url",
      type="application/pdf"
    ) 
      .not-supported-view(ref="fallback")
        el-icon
          Document-delete.txt-larger.mb-1
        p {{ $t("browser_not_support_pdf") }}
    a(:href="data.url")
      el-button.medium-width.mb-2(
        target="_blank",
        type="primary",
        icon="Download"
      ) {{ $t("download_file") }}
    ConfirmationButton(
      v-if="data.requiresInteraction",
      :completed="data.completed",
      @confirmed="sendConfirmation"
    )
</template>

<script>
import stepMixin from "./stepMixin";
import ConfirmationButton from "./components/ConfirmButton.vue";

export default {
  components: { ConfirmationButton },
  mixins: [stepMixin],
  data: function () {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    sendConfirmation() {
      this.sendAnswer({ confirmation: true });
    },
  },
};
</script>

<style scoped lang="stylus">
@import '../../../settings.styl';

.text-container :deep(h1) {
  text-align: center;
}

.pdf-content {
  width: 100%;
  height: 70vh;
}

.not-supported-view {
  display: flex;
  max-width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid $border;
}
</style>
