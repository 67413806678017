// NOTE: privacy policy and tos may contain a string $langcode which will be replaced with the currently set language code of the user
export const termsAndConditionsLink =
  "/policies/Nutzungsbedingungen/210401_AGB_WhatzLife_User_Nutzungsbedingungen_Cloud-Loesung_$langcode.pdf";
export const privacyPolicyLink =
  "/policies/Datenschutz/210819_LYB_Informationsblatt_WhatzLearn Wesseling_$langcode.pdf";

export const headerlogo = "/img/logo.png";
export const loginImage = "/img/logo.png";

export const showDetailsForQuiz = false;
export const showDetailsForSurvey = false;
export const showSeal = false;
export const showCovidView = false;
export const showCovidCertificatesToAdmin = false;

export const showTestCenterView = true;
export const showHardwareRestrictions = true;

export const title = "Onboarding | LyondellBasell";

export const registrationRequiresKey = true;
// possible values: phone, address, birthday, company, lastname, firstname, birthyear
export const registrationRequiredDetails = {
  phone: { request: false, mandatory: false },
  address: { request: false, mandatory: false },
  firstname: { request: true, mandatory: true },
  lastname: { request: true, mandatory: true },
  birthday: { request: true, mandatory: true },
  company: { request: false, mandatory: false },
  emergencyContact: { request: true, mandatory: false },
};

export const customPublicRegistrations = {
  besucher: {
    email: { request: false, mandatory: false },
    username: { request: false, mandatory: false },
    firstname: { request: true, mandatory: true },
    lastname: { request: true, mandatory: true },
    birthday: { request: true, mandatory: true },
    emergencyContact: { request: false, mandatory: false },
  },
  kontraktor: {
    phone: { request: false, mandatory: false },
    address: { request: false, mandatory: false },
    firstname: { request: true, mandatory: true },
    lastname: { request: true, mandatory: true },
    birthday: { request: true, mandatory: true },
    company: { request: true, mandatory: true },
    emergencyContact: { request: true, mandatory: false },
  },
};

// NOTE: this is meant as a hotfix, in the future this should be user controlled
export const userStateTagOrder = [
  "e36d9ab6-0e79-11ed-8b12-cfe7e6694129",
  "565b2a58-0e79-11ed-be28-0bede1c36fda",
  "5ae2650a-1ce3-11ed-9816-23378afba8fa",
  "958a0a20-e7cb-11ec-b946-afcf7ebecef9",
  "4f8b1082-a20b-11ed-a2b2-fb5b17e3f939",
  "05d5a4c2-9737-11ed-bf60-9ba14cad9b20",
  "4097d156-9b0c-11ed-bf62-3f0cd172c1a6",
  "642c15b4-9b0c-11ed-91cb-47a4d93a7934",
  "07faa368-0e63-11ed-9608-a7220c2d3926",
  "13ad942c-0e63-11ed-ae7f-0bc24bc03151",
  "64c91020-0e77-11ed-b8a3-0f39e61fa6fd",
  "6feb67e6-0e77-11ed-984c-f360fb35bbda",
  "81923830-0e77-11ed-8b0e-ff79dcb333c6",
  "a19f5ca2-0e77-11ed-8b10-23a381d92be1",
  "aaaaf310-0e77-11ed-b8a7-2f5bfe0d91a4",
];

export const emailAsUsername = true;
export const supportedLanguages = [
  "de",
  "en",
  "hr",
  "hu",
  "ro",
  "cs",
  "sk",
  "bs",
  "pl",
  "tr",
  "bg",
  "ru",
  "sr",
  "et",
  "nl",
];
export const defaultLanguage = "de";
export const userStateShowDownload = true;

export const registrationKeyMinimumLength = 4;

export const qGateFields = [
  { id: "birthdate", type: "date", label: "birthday", mandatory: true },
  // {id: 'birthplace', type: 'string', label: 'birthplace', mandatory: true},
  // NOTE: nationalityWithNonEuVisa also adds the additional, optional fields socialSecurity and workPermit (if the user is not from the EU)
  {
    id: "nationality",
    type: "nationalityWithNonEuVisa",
    label: "nationality",
    mandatory: true,
  },
  // {id: 'address', type: 'string', label: 'address', mandatory: true},
  // {id: 'city', type: 'string', label: 'city', mandatory: true},
  // {id: 'zipCode', type: 'string', label: 'zipCode', mandatory: true},
  {
    id: "emergencyContact",
    type: "string",
    label: "emergencyContact",
    mandatory: false,
  },
  {
    id: "emergencyContactPhone",
    type: "string",
    label: "emergencyContactPhone",
    mandatory: false,
  },
  // {id: 'country', type: 'country', label: 'country', mandatory: true},
  {
    id: "car",
    type: "switchString",
    label: "driveInPermit",
    mandatory: false,
    settings: { subLabel: "carPlate", subId: "carPlate" },
  },
];

// Language overrides allow to override specific values for translation files, this is an example to change 'Zertifikat' to 'Teilnahmebescheinigung'
export const languageOverrides = {
  de: {
    birthdayHint: "Geburtstag (yyyy-mm-dd)",
    certificate: "Teilnahmebescheinigung",
    certificates: "Teilnahmebescheinigungen",
    printCertificate: "Teilnahmebescheinigung drucken",
    downloadCertificate:
      "Sie können jederzeit ihre Teilnahmebescheinigung unter Teilnahmebescheinigungen im Profil herunterladen und via E-Mail anfordern.",
  },
  en: {
    birthdayHint: "Birthday (yyyy-mm-dd)",
  },
  bg: {
    birthdayHint: "Рожден ден (yyyy-mm-dd)",
  },
  bs: {
    birthdayHint: "Datum rođenja (yyyy-mm-dd)",
  },
  cs: {
    birthdayHint: "Datum narození (yyyy-mm-dd)",
  },
  el: {
    birthdayHint: "Ημερομηνία γέννησης (yyyy-mm-dd)",
  },
  hr: {
    birthdayHint: "Datum rođenja (yyyy-mm-dd)",
  },
  hu: {
    birthdayHint: "Születési dátum (yyyy-mm-dd)",
  },
  it: {
    birthdayHint: "Data di nascita (yyyy-mm-dd)",
  },
  sk: {
    birthdayHint: "Dátum narodenia (yyyy-mm-dd)",
  },
  pl: {
    birthdayHint: "Data urodzenia (yyyy-mm-dd)",
  },
  pt: {
    birthdayHint: "Data de nascimento (yyyy-mm-dd)",
  },
  ru: {
    birthdayHint: "Дата рождения (yyyy-mm-dd)",
  },
  ro: {
    birthdayHint: "Data nașterii (yyyy-mm-dd)",
  },
  es: {
    birthdayHint: "Fecha de nacimiento (yyyy-mm-dd)",
  },
  tr: {
    birthdayHint: "Doğum tarihi (yyyy-mm-dd)",
  },
  sl: {
    birthdayHint: "Datum rojstva (yyyy-mm-dd)",
  },
  sr: {
    birthdayHint: "Datum rođenja (yyyy-mm-dd)",
  },
};
