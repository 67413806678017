<template lang="pug">
.policy-modal
  modal(:closable="false", @close="close")
    template(#title) {{ isPolicyAcceptance ? $t("policyAcceptance") : $t("additionalDetails") }}
    template(v-if="isPolicyAcceptance")
      h3.mb-2 {{ $t("acceptPolicies") }}
      template(
        v-for="(item, index) in requiredActions",
        :key="`policy-${index}`"
      )
        CheckboxInput.mb-1.mr-1(
          v-if="item.type === 'termsAndConditions'",
          v-model="isTerms"
        )
          span
            i18n-t(keypath="termsAccept", for="auth.tos")
              a(:href="termsAndConditionsLink", target="_blank") &nbsp; {{ $t("tos") }} &nbsp;
        CheckboxInput.mb-2.mr-1(
          v-if="item.type === 'privacyPolicy'",
          v-model="isDataPolicy"
        )
          span
            i18n-t(keypath="privacyAccept", for="auth.privacyPolicy")
              a(:href="privacyPolicyLink", target="_blank") &nbsp; {{ $t("privacyPolicy") }} &nbsp;
    template.mb-3(v-if="isUmapRequirement")
      h3.mb-2.mt-3 {{ $t("add_mandatory_user_property") }}
      template(
        v-for="(item, index) in requiredActions",
        :key="`action-${index}`"
      )
        .mb-1(v-if="item.type === 'umapRequirement'")
          label(:for="item.requirement.tagType") {{ item.tagType.name }}
          el-select(
            v-if="item.requirement.multiSelect",
            :id="item.requirement.tagType",
            v-model="selection[item.tagType.uuid]",
            filterable,
            multiple
          )
            el-option(
              v-for="item in tagValuesList(item.tagType.uuid)",
              :key="item.key",
              :label="item.value",
              :value="item.key"
            )
          el-select(
            v-else,
            :id="item.requirement.tagType",
            v-model="selection[item.tagType.uuid]",
            filterable
          )
            el-option(
              v-for="item in tagValuesList(item.tagType.uuid)",
              :key="item.key",
              :label="item.value",
              :value="item.key"
            )
      a.txt-center.txt-large(
        :href="`/propertyHelp/Rollen_und_Gewerke_${langCode}.pdf`",
        target="_blank"
      ) {{ $t("help") }}
    template(#footer)
      el-button(
        :disabled="!requiredActionsCompleted",
        type="primary",
        @click="save"
      )
        | {{ isPolicyAcceptance ? $t("policyAcceptanceSave") : $t("additionalDetailsSave") }}
</template>

<script>
import Modal from "@/components/elements/Modal";
import CheckboxInput from "@/components/elements/CheckboxInput";
import { privacyPolicyLink, termsAndConditionsLink } from "@/settings";
import { mapGetters } from "vuex";

export default {
  name: "PolicyModal",
  components: {
    Modal,
    CheckboxInput,
  },
  data() {
    return {
      selection: {},

      termsAndConditionsLink: termsAndConditionsLink,
      privacyPolicyLink: privacyPolicyLink,
      isDataPolicy: false,
      isTerms: false,
    };
  },
  computed: {
    ...mapGetters(["userInteractionCache"]),
    requiredActions() {
      return this.userInteractionCache.requiredActions;
    },
    requiredActionsCompleted() {
      return this.requiredActions.reduce(
        (agg, curr) => this.isRequirementCompleted(curr) && agg,
        true,
      );
    },
    isPolicyAcceptance() {
      return this.requiredActions.some(
        (item) =>
          item.type === "privacyPolicy" || item.type === "termsAndConditions",
      );
    },
    isUmapRequirement() {
      return this.requiredActions.some(
        (item) => item.type === "umapRequirement",
      );
    },
    langCode() {
      return this.$i18n.locale;
    },
    response() {
      const response = [];
      if (this.isTerms) {
        response.push({ type: "termsAndConditions" });
      }
      if (this.isDataPolicy) {
        response.push({ type: "privacyPolicy" });
      }
      Object.keys(this.selection).forEach((id) => {
        const value = this.selection[id];

        response.push({
          type: "umapRequirement",
          tagType: id,
          tagValue: Array.isArray(value) ? value : [value],
        });
      });
      return response;
    },
  },
  methods: {
    isExternalLink(link) {
      return link.startsWith("http");
    },
    tagValuesList(tagTypeId) {
      const item = this.requiredActions.find(
        (item) =>
          item.type === "umapRequirement" && item.tagType.uuid === tagTypeId,
      );
      return item.tagValues.map((tagValue) => ({
        value: tagValue.valueText,
        key: tagValue.uuid,
      }));
    },
    isRequirementCompleted(requirement) {
      switch (requirement.type) {
        case "termsAndConditions":
          return this.response.some(
            (item) => item.type === "termsAndConditions",
          );

        case "privacyPolicy":
          return this.response.some((item) => item.type === "privacyPolicy");

        case "umapRequirement":
          return this.response.some(
            (item) =>
              item.type === "umapRequirement" &&
              item.tagType === requirement.tagType.uuid,
          );
      }
      return false;
    },
    save() {
      if (!this.requiredActionsCompleted) {
        return;
      }
      const payload = {
        email: this.userInteractionCache.email,
        password: this.userInteractionCache.password,
        actionsRequired: this.response,
      };

      this.$store.dispatch("login", payload).then(
        () => {
          this.$store.commit("cancelUserInteractionRequired");
          this.$router.push({ path: "/" });
        },
        (error) => {},
      );
    },
    close() {
      this.$store.commit("cancelUserInteractionRequired");
    },
  },
};
</script>

<style scoped>
.policy-modal {
  z-index: 100;
}
</style>
