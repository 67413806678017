<template lang="pug">
.user-password
  h2.gdpr-title {{ $t("changePassword") }}
  p {{ $t("pleaseFillUpAllFields") }}
  .password-form-grid.mb-2
    label(for="current-password") {{ $t("currentPassword") }}
    el-input#current-password.m-0(
      v-model="currentPassword",
      :placeholder="$t('currentPassword')",
      type="password"
    )
    label(for="password1") {{ $t("password") }}
    password-input#password1(
      v-model="passwordOne",
      :placeholder="$t('password')",
      type="password"
    )
    label(for="password2") {{ $t("confirmPassword") }}
    el-popover(
      v-model:visible="showHelpPasswordRepeat",
      trigger="manual",
      placement="top",
      :width="400"
    )
      template(#reference)
        el-input#password2.m-0(
          v-model="passwordTwo",
          :placeholder="$t('confirmPassword')",
          type="password",
          show-password,
          @focus="onFocusPasswordRepeat",
          @blur="onHidePasswordRepeat"
        )
      ul.help-message
        li.require
          i.icon.icon--success.material-icons(
            v-if="passwordOne === passwordTwo"
          ) done
          i.icon.material-icons(v-else) clear
          | {{ $t("passwordsNotMatch") }}
  el-alert(v-if="passwordDontMatch", :closable="false", type="warning") {{ $t("passwordsNotMatch") }}
  el-alert(v-if="passwordsTooShort", :closable="false", type="warning") {{ $t("newPasswordShort") }}
  el-alert(
    v-if="passwordUppercaseLowercase",
    :closable="false",
    type="warning"
  ) {{ $t("passwordError") }}
  .flex-justify-center.mt-3
    //- .btn-light.cancel(@click="cancel") {{$t('cancel')}}
    el-button.fill-width(
      v-loading="loading",
      type="primary",
      @click="updatePassword"
    ) {{ $t("update") }}
</template>

<script>
import PasswordInput from "@/components/elements/PasswordInput";

export default {
  name: "UserPassword",
  components: {
    PasswordInput,
  },
  data() {
    return {
      isWrongOld: false,
      passwordDontMatch: false,
      passwordsTooShort: false,
      passwordUppercaseLowercase: false,
      currentPassword: "",
      passwordOne: "",
      passwordTwo: "",
      loading: false,
      showHelpPasswordRepeat: false,
    };
  },
  watch: {
    passwordOne() {
      this.resetPasswordCheck();
    },
    passwordTwo() {
      this.resetPasswordCheck();
    },
  },
  methods: {
    cancel() {
      this.currentPassword = "";
      this.passwordOne = "";
      this.passwordTwo = "";
    },
    onFocusPasswordRepeat() {
      this.showHelpPasswordRepeat = true;
    },
    onHidePasswordRepeat() {
      this.showHelpPasswordRepeat = false;
    },
    updatePassword() {
      this.isWrongOld = false;
      const isCorrect = this.checkPasswords();
      if (isCorrect) {
        this.loading = true;
        this.$store
          .dispatch("updatePassword", {
            oldPassword: this.currentPassword,
            newPassword: this.passwordOne,
            password: this.passwordOne, // @TODO: Workaround to be fixed: this is the property taken into account by Endpoint /change-password
          })
          .then(
            (data) => {
              this.$store.commit("addNewNotification", {
                title: "success",
                text: "passwordChanged",
                notificationType: "success",
              });
              this.$emit("closeModal");
            },
            (error) => {
              console.log(error.response);
              const response = error.response;
              if (response.data.error === "given password is wrong") {
                this.$store.commit("addNewNotification", {
                  title: "error",
                  text: "wrongCurrentPassword",
                  notificationType: "error",
                });
              }
            },
          )
          .finally(() => {
            this.loading = false;
          });
      }
    },
    checkPasswords() {
      const passwordOne = this.passwordOne;
      const passwordTwo = this.passwordTwo;
      this.passwordDontMatch = false;
      this.passwordsTooShort = false;
      if (passwordOne !== passwordTwo) {
        this.passwordDontMatch = true;
        return false;
      }
      if (passwordOne.length < 8) {
        this.passwordsTooShort = true;
        return false;
      }
      if (
        passwordOne.toUpperCase() === passwordOne ||
        passwordOne.toLowerCase() === passwordOne
      ) {
        this.passwordUppercaseLowercase = true;
        return false;
      }
      return true;
    },
    resetPasswordCheck() {
      this.passwordDontMatch = false;
      this.passwordsTooShort = false;
      this.passwordUppercaseLowercase = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import "../../settings.styl";

.password-form-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1.5rem;
    align-items: center;
  }




// NOTE: this is from elements/PasswordInput
  .require {
    display: flex;
    align-items: center;
    // margin: 5px 0;
  }

  .indicator {
    height: 4px;
  }

  .help-message {
    margin-bottom: 15px;
    padding-left: 8px;

    .icon {
      font-size: 16px;
      color: $incorrect;

      &--success {
        color: $correct;
      }
    }
  }
</style>
