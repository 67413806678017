<template lang="pug">
div
  h2 {{ $t("logs") }}
  el-alert(
    v-if="error",
    type="warning",
    :title="$t('genericError')",
    :closable="false"
  )
  template(v-else)
    el-table(:data="logs", style="width: 100%")
      el-table-column(:label="$t('created')", prop="created")
        template(#default="scope")
          | {{ formatDateTime(scope.row.created) }}
      el-table-column(:label="$t('name')", prop="name")
      el-table-column(:label="$t('description')", prop="description")
      el-table-column(:label="$t('type')", prop="type")
    .flex-row.flex-justify-center.mt-1
      el-pagination(
        :total="totalItems",
        :page-size="pageSize",
        layout="prev, pager, next",
        @current-change="setPage"
      )
</template>

<script>
import { mapGetters } from "vuex";
import { formatDateTime } from "@/utils/formatDate.js";

export default {
  name: "UserLogs",
  props: {},
  data() {
    return {
      page: 1,
      pageSize: 10,
      formatDateTime,
    };
  },
  computed: {
    ...mapGetters(["getLogsLoadingState", "getLogsPage", "getLogsOptions"]),
    logs() {
      return this.getLogsLoadingState.result;
    },
    error() {
      return this.getLogsLoadingState.error;
    },
    totalItems() {
      return this.getLogsPage !== null ? this.getLogsPage.total : 0;
    },
    userId() {
      return this.$store.getters.getUserData.id;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    setPage(page) {
      this.page = page;
      this.loadData();
    },
    loadData() {
      this.$store.dispatch("loadLogs", {
        page: this.page,
        pageSize: this.pageSize,
        affectedUsers: JSON.stringify([this.userId]),
      });
    },
  },
};
</script>

<style scoped></style>
