// This is a wrapper around el-input-date to ensure that date is either
undefined or date without timestamp // el-date-input includes the timestamp in
local timezone leading to timezone issues.
<template lang="pug">
el-date-picker(v-bind="$attrs", v-model="date", type="date")
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "DatePicker",
  props: {
    modelValue: String,
  },
  data: function () {
    return {
      date: "",
    };
  },
  watch: {
    date() {
      this.$emit(
        "update:modelValue",
        this.date ? dayjs(this.date).format("YYYY-MM-DD") : undefined,
      );
    },
    modelValue() {
      this.date = this.modelValue;
    },
  },
  mounted() {
    this.date = this.modelValue;
  },
};
</script>
<style lang="stylus"></style>
