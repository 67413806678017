import axios from "axios";
import api from "./config";

export default {
  getVaccinationSettings() {
    return axios.get(`${api.covidServices}settings`);
  },
  patchVaccinationSettings(settings) {
    return axios.patch(`${api.covidServices}settings`, settings);
  },

  getVaccinationCertificates(lang) {
    return axios.get(`${api.covidServices}certificates`);
  },
  postVaccinationCertificate(payload) {
    return axios.post(`${api.covidServices}certificates`, { payload });
  },
  deleteVaccinationCertificate(id) {
    return axios.delete(`${api.covidServices}certificates/${id}`);
  },

  getVaccinationCertificatesForUser(userId) {
    return axios.get(
      `${api.covidServices}certificates?${new URLSearchParams({ userId })}`,
    );
  },
  postVaccinationCertificateForUser(payload, userId) {
    return axios.post(
      `${api.covidServices}certificates?${new URLSearchParams({ userId })}`,
      { payload },
    );
  },

  getTranslations() {
    return axios.get(`${api.covidServices}translations`);
  },
  patchTranslations(patches) {
    return axios.patch(`${api.covidServices}translations`, {
      content: patches,
    });
  },

  setCovidRulesChecked(userId) {
    return axios.post(`${api.covidServices}manual-check/`, {
      userId: userId.toString(),
    });
  },
};
