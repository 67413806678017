<template lang="pug">
el-config-provider(:locale="locale")
  .fill-height
    policy-modal(v-if="userInteractionRequired")
    app-header
    .app-content
      notifications-container
      router-view(:key="$route.fullPath")
      app-footer
    .version.txt-smaller.txt-inactive v2.0
</template>

<script>
import AppHeader from "@/components/header/AppHeader";
import AppFooter from "@/components/footer/AppFooter";
import NotificationsContainer from "@/components/elements/notifications/NotificationsContainer";
import PolicyModal from "@/components/auth/PolicyModal";
import { title } from "./settings";
import { mapGetters } from "vuex";
import { unref } from "vue";

import de from "element-plus/dist/locale/de.mjs";
import en from "element-plus/dist/locale/en.mjs";
import fr from "element-plus/dist/locale/fr.mjs";
import bg from "element-plus/dist/locale/bg.mjs";
import cs from "element-plus/dist/locale/cs.mjs";
import el from "element-plus/dist/locale/el.mjs";
import hr from "element-plus/dist/locale/hr.mjs";
import hu from "element-plus/dist/locale/hu.mjs";
import it from "element-plus/dist/locale/it.mjs";
import lv from "element-plus/dist/locale/lv.mjs";
import pl from "element-plus/dist/locale/pl.mjs";
import pt from "element-plus/dist/locale/pt.mjs";
import ru from "element-plus/dist/locale/ru.mjs";
import ro from "element-plus/dist/locale/ro.mjs";
import es from "element-plus/dist/locale/es.mjs";
import tr from "element-plus/dist/locale/tr.mjs";
import sr from "element-plus/dist/locale/sr.mjs";
import et from "element-plus/dist/locale/et.mjs";
import nl from "element-plus/dist/locale/nl.mjs";
import ar from "element-plus/dist/locale/ar.mjs";

import "dayjs/locale/de";
import "dayjs/locale/en";
import dayjs from "dayjs";

export default {
  name: "App",
  components: {
    NotificationsContainer,
    AppHeader,
    AppFooter,
    PolicyModal,
  },
  computed: {
    ...mapGetters(["userInteractionRequired", "getCurrentUserData"]),
    locale() {
      switch (this.getCurrentUserData?.attributes?.langCode ?? "en") {
        case "de":
          return de;
        case "en":
          return en;
        case "fr":
          return fr;
        case "bg":
          return bg;
        case "cs":
          return cs;
        case "el":
          return el;
        case "hr":
          return hr;
        case "hu":
          return hu;
        case "it":
          return it;
        case "lv":
          return lv;
        case "pl":
          return pl;
        case "pt":
          return pt;
        case "ru":
          return ru;
        case "ro":
          return ro;
        case "es":
          return es;
        case "tr":
          return tr;
        case "sr":
          return sr;
        case "et":
          return et;
        case "nl":
          return nl;
        case "ar":
          return ar;
      }
      return en;
    },
  },
  watch: {
    locale() {
      this.setDayjsLocale();
    },
  },
  methods: {
    setDayjsLocale() {
      // NOTE: dayjs formatting is only being used in admin, thus english and german are sufficient
      if (this.locale.name === "de") {
        dayjs.locale("de");
        return;
      }
      dayjs.locale("en");
    },
  },
  created() {
    if (title) document.title = title;
    this.$store.dispatch("initUserData");
    this.setDayjsLocale();
  },
};
</script>

<style lang="stylus">
@import './global.styl';
@import './settings.styl';

#app {
  height: 100%;
}

.app-content {
  height: 'calc(100% - %s)' % $headerHeight;
  margin: auto;
  padding: 0 1rem;
}

.version {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: -1;
}

body {
  background-color: $background;
}
</style>
