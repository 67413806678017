<template lang="pug">
div
  h3 {{ $t("settings") }}
  .step-settings.mb-1
    label {{ $t("inputType") }}
    el-select(:model-value="inputType", @update:modelValue="setInputType")
      el-option(key="date", value="date", :label="$t('date')")
      el-option(key="week", value="week", :label="$t('week')")
      el-option(key="month", value="month", :label="$t('month')")
      el-option(key="year", value="year", :label="$t('year')")

    label {{ $t("minDate") }}
    el-date-picker(
      :type="inputType",
      :model-value="minDate",
      @update:modelValue="setMinDate"
    )

    label {{ $t("maxDate") }}
    el-date-picker(
      :type="inputType",
      :model-value="maxDate",
      @update:modelValue="setMaxDate"
    )

  CommonSettings(:step="step")
</template>

<script>
import { mapGetters } from "vuex";
import CommonSettings from "./CommonSettings";
import editStepMixin from "./mixins/editStepMixin";

export default {
  components: {
    CommonSettings,
  },
  mixins: [editStepMixin],
  computed: {
    label() {
      return this.step.label;
    },
    inputType() {
      return this.step.inputType ? this.step.inputType : "date";
    },
    minDate() {
      return this.step.minDate;
    },
    maxDate() {
      return this.step.maxDate;
    },
  },
  methods: {
    setLabel(label) {
      this.patchStep({ label });
    },
    setInputType(inputType) {
      this.patchStep({ inputType });
    },
    setMinDate(minDate) {
      this.patchStep({ minDate });
    },
    setMaxDate(maxDate) {
      this.patchStep({ maxDate });
    },
  },
};
</script>
