import accountService from "@/services/accounts";
import { signupKeys } from "@/services/shogun";
import {
  stateLoading,
  stateInitial,
  stateError,
  stateLoaded,
} from "./loadingStates";
import _ from "lodash";

export default {
  state: {
    list: [],
    loadingState: stateInitial,
    departments: [],
    departmentsLoadingState: stateInitial,
    signUpKeys: [],
    signUpKeysLoadingState: stateInitial,
    accountStatisticsLoadingState: stateInitial,
    globalAccountStatisticsLoadingState: stateInitial,
    subcontractors: [],
    subcontractorsLoadingState: stateInitial,
  },
  mutations: {
    loadingSignUpKeys(state, payload) {
      state.signUpKeysLoadingState = stateLoading;
    },
    loadedSignUpKeys(state, payload) {
      state.signUpKeysLoadingState = stateLoaded;
    },
    errorLoadingSignUpKeys(state, payload) {
      state.signUpKeysLoadingState = { ...stateError, errorMessage: payload };
    },
    initSignUpKeys(state, payload) {
      if (payload && payload.length && payload.length > 0) {
        state.signUpKeys = payload;
      }
      state.signUpKeysLoadingState = stateLoaded;
    },

    loadingAccountStatistic(state, payload) {
      state.accountStatisticsLoadingState = stateLoading;
    },
    loadingGlobalAccountStatistic(state, payload) {
      state.globalAccountStatisticsLoadingState = stateLoading;
    },
    loadedAccountStatistic(state, payload) {
      state.accountStatisticsLoadingState = {
        ...stateLoaded,
        result: payload.result,
      };
    },
    loadedGlobalAccountStatistic(state, payload) {
      state.globalAccountStatisticsLoadingState = {
        ...stateLoaded,
        result: payload.result,
      };
    },
    errorAccountStatistic(state, payload) {
      state.accountStatisticsLoadingState = {
        ...stateError,
        errorMessage: payload,
      };
    },
    errorGlobalAccountStatistic(state, payload) {
      state.globalAccountStatisticsLoadingState = {
        ...stateError,
        errorMessage: payload,
      };
    },

    loadingAccounts(state, payload) {
      state.loadingState = stateLoading;
    },
    loadedAccounts(state, payload) {
      state.loadingState = stateLoaded;
    },
    errorLoadingAccounts(state, payload) {
      state.loadingState = { ...stateError, errorMessage: payload };
    },
    initAccounts(state, payload) {
      state.list = payload;
      state.loadingState = stateLoaded;
    },

    loadingDepartments(state, payload) {
      state.departmentsLoadingState = stateLoading;
    },
    loadedDepartments(state, payload) {
      state.departmentsLoadingState = stateLoaded;
    },
    errorLoadingDepartments(state, payload) {
      state.departmentsLoadingState = { ...stateError, errorMessage: payload };
    },
    initDepartments(state, payload) {
      if (payload && payload.length && payload.length > 0) {
        state.departments = payload;
      }
      state.departmentsLoadingState = stateLoaded;
    },

    loadingSubcontractors(state) {
      state.subcontractorsLoadingState = stateLoading;
    },
    loadedSubcontractors(state, payload) {
      state.subcontractorsLoadingState = stateLoaded;
      state.subcontractors = payload;
    },
    errorLoadingSubcontractors(state, payload) {
      state.subcontractorsLoadingState = stateError;
    },

    addAccount(state, payload) {
      if (payload) {
        const result = {
          ...payload.attributes,
          ...payload,
        };
        state.list.push(result);
      }
    },
    updateAccount(state, payload) {
      const list = state.list;
      const foundItem = list.find((item) => item.id === +payload.id);

      // TODO: this should be removed
      // It looks like this is trying to merge payload and item, which has been corrected below
      // However this also has weird side effects like setting name and expiration to null,
      //  thus this is left for reference until all occurences are checked
      // if (foundItem) {
      //   foundItem.name = payload.name || null;
      //   foundItem.expiration = payload.expiration || null;
      //   const attributes = payload.attributes;
      //   Object.keys(attributes).forEach((key) => {
      //     foundItem[key] = attributes[key];
      //   });
      // }

      const itemIndex = list.findIndex((item) => item.id === payload.id);
      list.splice(itemIndex, 1, _.merge(list[itemIndex], payload));
    },
    deleteAccountById(state, payload) {
      const list = state.list;
      const foundItemIndex = list.findIndex((item) => item.id === +payload);
      if (foundItemIndex >= 0) {
        list.splice(foundItemIndex, 1);
      }
    },
  },
  actions: {
    initDepartments({ commit, dispatch }, id) {
      commit("loadingDepartments");
      return accountService.GET_DEPARTMENTS(id).then(
        (result) => {
          commit("initDepartments", result);
        },
        (error) => {
          commit("errorLoadingDepartments", error);
        },
      );
    },

    initSignUpKeys({ commit }) {
      commit("loadingSignUpKeys");
      return signupKeys.usersAndAccounts.searchSignupKeys().then(
        (result) => {
          commit("initSignUpKeys", result);
        },
        (error) => {
          commit("errorLoadingSignUpKeys", error);
        },
      );
    },
    addSignUpKey({ commit, dispatch }, payload) {
      commit("loadingSignUpKeys");
      return signupKeys.usersAndAccounts
        .createSignupKey({
          requestBody: payload,
        })
        .then(
          (result) => {
            dispatch("initSignUpKeys");
          },
          (error) => {
            dispatch("initSignUpKeys");
          },
        );
    },
    updateSignUpKey({ commit, dispatch }, payload) {
      commit("loadingSignUpKeys");
      return signupKeys.usersAndAccounts
        .mutateSignupKey({
          id: payload.id,
          requestBody: payload,
        })
        .then(
          (result) => {
            dispatch("initSignUpKeys");
          },
          (error) => {
            dispatch("initSignUpKeys");
          },
        );
    },
    deleteSignUpKey({ commit, dispatch }, id) {
      commit("loadingSignUpKeys");
      return signupKeys.usersAndAccounts.removeSignupKey({ id }).then(
        (result) => {
          dispatch("initSignUpKeys");
        },
        (error) => {
          dispatch("initSignUpKeys");
        },
      );
    },
    initAccountById({ commit, getters }, id) {
      // NOTE: this is used to edit the own account information, manipulating the list of accounts,
      // this might lead to problems
      // TODO: add a special property to save the own account information
      accountService.GET_BY_ID(id).then((data) => {
        if (data && !data.error) {
          const account = getters.getAccountById(id);
          if (account) {
            commit("updateAccount", data);
          } else {
            commit("addAccount", data);
          }
        }
      });
    },

    initAccountStatistics({ commit, getters }, options) {
      commit("loadingAccountStatistic");
      accountService
        .GET_ACCOUNT_STATISTICS(options)
        .then((data) => {
          commit("loadedAccountStatistic", data);
        })
        .catch((error) => {
          commit("errorAccountStatistic", error);
        });
    },

    initGlobalAccountStatistics({ commit, getters }, options) {
      commit("loadingGlobalAccountStatistic");
      accountService
        .GET_GLOBAL_ACCOUNT_STATISTICS(options)
        .then((data) => {
          commit("loadedGlobalAccountStatistic", data);
        })
        .catch((error) => {
          commit("errorGlobalAccountStatistic", error);
        });
    },

    initAccounts({ state, commit }, payload) {
      if (payload && payload.keepCache) {
        // we keep the data if it has already been loaded
        if (state.loadingState.loaded || state.loadingState.loading) {
          return;
        }
      }
      commit("loadingAccounts");
      accountService.GET().then(
        (data) => commit("initAccounts", data),
        (error) => commit("errorLoadingAccounts", error),
      );
    },
    importAccountStructure({ commit }, payload) {
      return accountService.IMPORT_ACCOUNT_STRUCTURE(payload).then((data) => {
        if (data.error) {
          commit("addNewNotification", {
            title: "error",
            text: data.error,
            textAsIs: true,
            notificationType: "error",
          });
          return false;
        }
        return true;
      });
    },
    addAccount({ commit }, payload) {
      commit("loadingAccounts");
      return accountService.POST(payload).then((data) => {
        if (data.error) {
          commit("errorLoadingAccounts", data.error);
          commit("addNewNotification", {
            title: "error",
            text: data.error,
            textAsIs: true,
            notificationType: "error",
          });
        } else {
          commit("addAccount", data);
          commit("addNewNotification", {
            title: "success",
            text: "accountAdded",
            notificationType: "success",
          });
          commit("loadedAccounts");
        }
      });
    },
    addDepartments({ commit, dispatch }, payload) {
      return accountService.BATCH_CREATE_ACCOUNTS(payload).then((success) => {
        dispatch("initDepartments", payload.account);
      });
    },
    deleteDepartment({ commit, dispatch }, payload) {
      return accountService
        .DELETE_DEPARTMENT(payload.account, payload.element)
        .then((success) => {
          dispatch("initDepartments", payload.account);
        });
    },

    updateAccount({ commit }, payload) {
      commit("loadingAccounts");
      return accountService.PATCH(payload).then(
        (data) => {
          commit("updateAccount", data);
          commit("addNewNotification", {
            title: "success",
            text: "accountUpdated",
            notificationType: "success",
          });
          commit("loadedAccounts");
        },
        (error) => {
          commit("errorLoadingAccounts", error);
          commit("addNewNotification", {
            title: "error",
            text: "genericError",
            notificationType: "error",
          });
        },
      );
    },
    deleteAccountById({ commit }, payload) {
      return new Promise((res) => {
        commit("loadingAccounts");
        accountService.DELETE(payload).then(
          (data) => {
            commit("deleteAccountById", payload);
            commit("loadedAccounts");
            commit("addNewNotification", {
              title: "warning",
              text: "accountDeleted",
              notificationType: "warning",
            });
            res();
          },
          (error) => {
            commit("loadedAccounts");
          },
        );
      });
    },

    loadSubcontractors({ commit }, payload) {
      commit("loadingSubcontractors");
      return accountService.getSubcontractors(payload.accountId).then(
        (result) => {
          commit("loadedSubcontractors", result);
        },
        (error) => {
          commit("errorLoadingSubcontractors", error);
        },
      );
    },

    deleteSubcontractor({ commit, dispatch }, payload) {
      commit("loadingSubcontractors");
      return accountService
        .deleteSubcontractor(payload.subcontractorId)
        .then((result) => {
          dispatch("loadSubcontractors", { accountId: payload.account });
        });
    },

    updateSubcontractor({ commit, dispatch }, payload) {
      commit("loadingSubcontractors");
      return accountService.patchSubcontractor(payload).then(
        (result) => {
          dispatch("loadSubcontractors", { accountId: payload.account });
        },
        (error) => {
          commit("errorLoadingSubcontractors", error);
        },
      );
    },

    addSubcontractor({ commit, dispatch }, payload) {
      commit("loadingSubcontractors");
      return accountService.addSubcontractor(payload).then(
        (result) => {
          dispatch("loadSubcontractors", { accountId: payload.account });
        },
        (error) => {
          commit("errorLoadingSubcontractors", error);
        },
      );
    },
  },

  getters: {
    isAccountsInited: (state) => state.list.length > 0,
    getAccounts: (state) => state.list,
    getAccountsLoadingState: (state) => state.loadingState,
    getAccountStatisticsLoadingState: (state) =>
      state.accountStatisticsLoadingState,
    getGlobalAccountStatisticsLoadingState: (state) =>
      state.globalAccountStatisticsLoadingState,
    getStatisticsDataLoaded: (state) => state.statisticsDataLoaded,
    getL1Accounts: (state) => state.list.filter((item) => item.parent === 0),
    getAccountById: (state) => (id) =>
      state.list.find((item) => item.id === id),
    getSignUpKeys: (state) => state.signUpKeys,
    getSignUpKeysLoadingState: (state) => state.signUpKeysLoadingState,
    departments: (state) => state.departments,
    departmentsLoadingState: (state) => state.departmentsLoadingState,

    subcontractors: (state) => state.subcontractors,
    subcontractorsLoadingState: (state) => state.subcontractorsLoadingState,

    // Returns a list of account names
    getAccountListByCapabilities: (state) => (caps) => {
      const list = state.list;
      const result = [];
      Object.keys(caps).forEach((item) => {
        if (caps[item].length > 0) {
          const account = list.find((itm) => itm.id === +item);
          if (account !== undefined && account.parent === 0) {
            result.push(account.name);
          }
        }
      });
      return result;
    },
    // Returns a list of account objects
    getAccountByCapabilities: (state) => (caps) => {
      const list = state.list;
      console.log(state.list);
      const result = [];
      Object.keys(caps).forEach((item) => {
        if (caps[item].length > 0) {
          const account = list.find((itm) => itm.id === +item);
          if (account !== undefined && account.parent === 0) {
            result.push(account);
          }
        }
      });
      return result;
    },
    getAccountStructureById: (state) => (id) =>
      state.list.filter((item) => item.parent === id),
  },
};
