import {
  stateInitial,
  stateLoading,
  stateLoaded,
  stateError,
} from "./loadingStates";
import flexService from "@/services/flex";
import { createStep } from "@/components/flex/steps/stepFactory";

export default {
  state: {
    metaDataContainer: {
      loadingState: stateInitial,
      metaData: null,
    },
    flexStepContainer: {
      loadingState: stateInitial,
      step: null,
    },
    /**
     * stepState holds changes to state of the current steps which are merged in the getter.
     * these states include the following:
     * {
     *    id: the id of the step
     *    loadingState: the current loading state of the step
     *    completed: whether the step is considered as complete, this is used in the UI to determine when to show the next button
     *    feedback: the feedback to the response, if this is a locally cached state, this will usually inlude the current state of the response. If this is e.g. a quiz, this will include whether the answer was correct
     *    isLocal: boolean, if set this indicates that the user response has not yet been sent to the server and should be sent after a click on next - this is the case for example in a survey
     * }
     */
    stepState: [],
  },
  mutations: {
    flexMetaDataLoading(state) {
      state.metaDataContainer.metaData = null;
      state.metaDataContainer.loadingState = stateLoading;
    },
    flexMetaDataLoaded(state, metaData) {
      state.metaDataContainer.metaData = metaData;
      state.metaDataContainer.loadingState = stateLoaded;
    },
    flexMetaDataError(state, error) {
      state.metaDataContainer.metaData = null;
      state.metaDataContainer.loadingState = { ...stateError };
      state.metaDataContainer.loadingState.errorMessage = error;
    },
    flexStepLoading(state) {
      state.flexStepContainer.step = null;
      state.stepState = [];
      state.flexStepContainer.loadingState = stateLoading;
    },
    flexViewLoaded(state, data) {
      state.flexStepContainer.step = data;
      state.flexStepContainer.loadingState = stateLoaded;
    },
    flexStepError(state, error) {
      state.flexStepContainer.loadingState = stateError;
      state.flexStepContainer.loadingState.errorMessage = error;
    },
    sendingAnswer(state, { stepId }) {
      state.stepState.push({
        id: stepId,
        loadingState: stateLoading,
      });
    },
    answerSent(state, { stepId, response }) {
      const stepStateIndex = state.stepState.findIndex((s) => s.id === stepId);
      state.stepState.splice(stepStateIndex, 1, {
        id: stepId,
        loadingState: stateLoaded,
        completed: true,
        feedback: response.feedback,
        isLocal: false,
      });
      // Update progress and user learning points
      state.flexStepContainer.step["course"] = response.course;
      state.flexStepContainer.step["user"] = response.user;
    },
    addAnswerLocally(state, { stepId, answer, completed }) {
      const stepStateIndex = state.stepState.findIndex((s) => s.id === stepId);
      const stepState = {
        id: stepId,
        loadingState: stateLoaded,
        completed: completed !== undefined ? completed : true,
        answer: answer,
        isLocal: true,
      };
      if (stepStateIndex > -1) {
        state.stepState.splice(stepStateIndex, 1, stepState);
      } else {
        state.stepState.push(stepState);
      }
    },
    answerError(state, { stepId, errorMessage }) {
      const stepStateIndex = state.stepState.findIndex((s) => s.id === stepId);
      state.stepState.splice(stepStateIndex, 1, {
        id: stepId,
        loadingState: { ...stateError, errorMessage },
      });
    },
  },
  actions: {
    loadFlexMetaData(context, { id, keepCache }) {
      if (
        keepCache &&
        (context.state.metaDataContainer.loadingState.loaded ||
          context.state.metaDataContainer.loadingState.loading)
      ) {
        return;
      }
      context.commit("flexMetaDataLoading");
      flexService.loadFlexMetaData(id).then(
        (data) => {
          context.commit("flexMetaDataLoaded", data);
        },
        (error) => {
          context.commit("flexMetaDataError", error);
        },
      );
    },
    loadFlexView(context, { flexId, stepId }) {
      context.commit("flexStepLoading");
      flexService.loadFlexView({ flexId, stepId }).then(
        (data) => {
          context.commit("flexViewLoaded", { ...data, flexId, stepId });
        },
        (error) => {
          context.commit("flexStepError", error);
        },
      );
    },
    /**
     * finish the current step, all non-interactive, or locally edited steps (sendOnNext) are being sent to the server,
     */
    finishStep(context) {
      const steps = context.getters.flexStep.steps;
      const flexId = context.getters.flexId;

      // TODO: this should only be non-interactive steps, steps that don't require completion but are completed should not be sent
      const nonInteractiveSteps = steps.filter((step) => !step.data.completed);
      const sendOnNextSteps = steps.filter(
        (step) => step.sendOnNext && step.data.completed,
      );

      return Promise.all([
        ...sendOnNextSteps.map((step) => {
          return flexService.postResponse({
            flexId: flexId,
            stepId: step.id,
            content: {
              type: step.data.type,
              ...step.data.answer,
            },
          });
        }),

        ...nonInteractiveSteps.map((step) =>
          flexService.postResponse({
            flexId: flexId,
            stepId: step.id,
            content: {
              type: step.data.type,
            },
          }),
        ),
      ]);
    },
    sendAnswer(context, { step, content, completed }) {
      const stepId = step.id;
      // If step does not require the answer to be sent right away, cache the answer and send on next
      if (step.sendOnNext) {
        context.commit("addAnswerLocally", {
          stepId,
          answer: content,
          completed,
        });
      } else {
        context.commit("sendingAnswer", { stepId });
        flexService
          .postResponse({ flexId: context.getters.flexId, stepId, content })
          .then(
            (response) => {
              context.commit("answerSent", { stepId, response });
            },
            (error) => {
              context.commit("answerError", { stepId, errorMessage: error });
            },
          );
      }
    },
  },
  getters: {
    flexMetaDataLoadingState: (state) => state.metaDataContainer.loadingState,
    flexMetaData: (state) => state.metaDataContainer.metaData,
    flexId: (state) => state.flexStepContainer.step.flexId,
    flexStepLoadingState: (state) => state.flexStepContainer.loadingState,
    // NOTE: on the one hand we add properties to the steps (i.e. the loading state), on the other hand we merge data about already completed steps
    // After that we use the factory to create the steps according to their type
    flexStep: (state) => {
      return {
        ...state.flexStepContainer.step,
        steps:
          state.flexStepContainer.step && state.flexStepContainer.step.steps
            ? state.flexStepContainer.step.steps
                // add the loading state
                .map((step) => ({ ...step, loadingState: stateLoaded }))
                .map((step) => {
                  const stepState = state.stepState.find(
                    (stepState) => stepState.id === step.id,
                  );
                  if (stepState) {
                    return { ...step, ...stepState };
                  }
                  return step;
                })
                .map((step) => createStep(step))
            : null,
      };
    },
  },
};
