<template lang="pug">
.input-step
  .ql-editor.text-container.mb-1(v-if="data.text", v-html="data.text")
  .flex-form-item
    label.ql-editor.flex-form-label(:for="step.id", v-html="data.label")
    .flex-form-input
      el-input(
        :id="step.id",
        v-model="text",
        :type="data.inputType",
        :maxlength="data.maxLength",
        :minlength="data.minLength",
        :show-word-limit="data.showWordLimit",
        :rows="data.rows"
      )
</template>

<script>
import stepMixin from "./stepMixin";

export default {
  components: {},
  mixins: [stepMixin],
  data: function () {
    return {
      text: "",
    };
  },
  computed: {},
  watch: {
    text(text) {
      let completed = true;
      if (this.data.minLength) {
        completed = text.length >= this.data.minLength;
      }
      if (this.data.maxLength) {
        completed = completed && text.length <= this.data.maxLength;
      }
      if (this.text !== "") {
        this.sendAnswer({ text }, completed);
      }
    },
  },
  mounted() {
    if (this.data.feedback && this.data.feedback.length > 0) {
      this.text = this.data.feedback[this.data.feedback.length - 1].text;
    }
  },
  methods: {},
};
</script>

<style></style>
