import frLocale from "element-plus/dist/locale/fr";

export default {
  ...frLocale,
  // TODO: translate
  termsAccept: "J'accepte les {0}.",
  tos: "conditions générales d'utilisation",
  privacyAccept: "J'accepte la {0}.",
  privacyPolicy: "déclaration de protection des données",
  resetPassword: "Réinitialiser le mot de passe",
  usernamePasswordRequired:
    "Veuillez saisir un nom d'utilisateur et un mot de passe.",
  passwordTooShort:
    "Le mot de passe doit être composé d'au moins 8 caractères.",
  wrongCredentials:
    "Les informations d'identification fournies ne correspondent à aucun compte.",
  accountStructureItem: "Département",
  addRole: "Ajouter un rôle",
  allUserRelatedDeleteText:
    "Toutes les données relatives à l'utilisateur seront irrémédiablement supprimées.",
  assignRoleModals: "Assigner un rôle",
  canBeDownloadedText: 'peut être téléchargée sous la rubrique "terminé"',
  changePassword: "Modifier le mot de passe",
  changingPasswordFor: "Changement de mot de passe pour l'utilisateur",
  confirmDeleteText: "Veuillez confirmer en tapant le nom d'utilisateur:",
  confirmDeteleBtnText: "Je comprends, supprimer cet utilisateur",
  confirmPassword: "Confirmer le mot de passe",
  createAccount: "Enregistrer l'utilisateur",
  createUser: "Créer un utilisateur",
  currentPassword: "Mot de passe actuel",
  customId: "Identifiant personnalisé",
  dataPrivacyHeader: "Protection des données",
  dataPrivacyText: "Texte de la section (le contenu suit)",
  dataPrivacyTitle: "Titre de la section",
  dataRelatedToMe: "données me concernant",
  dataRelatedToMeTitle: "Données me concernant",
  deleteText: "Êtes-vous sûr de vouloir supprimer le",
  downloadDataRelatedToMe:
    "télécharger les données me concernant au format .txt",
  fileWithUserInformation:
    "Fichier contenant des informations sur l'utilisateur",
  forgotPassword: "Mot de passe oublié ?",
  forgotPasswordText:
    "Veuillez saisir votre adresse électronique. Nous vous enverrons par courriel des instructions sur la manière de réinitialiser votre mot de passe.",
  importUsers: "Importer des utilisateurs",
  newHere: "Nouveau ici ?",
  newPassowrd: "Nouveau mot de passe",
  newPasswordShort: "Nouveau mot de passe trop court",
  password: "Mot de passe",
  passwordCantBeEmpty: "Le mot de passe ne peut pas être vide",
  passwordsNotMatch: "Les mots de passe ne correspondent pas",
  pickUser: "Choisissez un utilisateur",
  pleaseFillUpAllFields: "Veuillez remplir tous les champs",
  pleaseTypeNewPassword: "Veuillez saisir votre nouveau mot de passe",
  policyAcceptance: "Adoption des directives",
  policyAcceptanceSave: "accepter",
  acceptPolicies: "Veuillez accepter les directives",
  profile: "Profil",
  role: "Rôle",
  roleExpiresOn: "Le rôle expire le",
  saveDataAboutMe: "données enregistrées à mon sujet",
  signIn: "S'identifier",
  termsConditionsHeader: "Conditions générales d'utilisation",
  termsConditionsText: "Texte de la section (le contenu suivra)",
  termsConditionsTitle: "Titre de la section",
  typeInOne: "Tapez un pour tous",
  firstname: "Prénom",
  lastname: "Nom de famille",
  username: "Nom d'utilisateur",
  usernameMail: "Nom d'utilisateur ou adresse électronique",
  userNotes: "Notes",
  userProfile: "Profil de l'utilisateur",
  userStatus: "Statut de l'utilisateur",
  wrongCurrentPassword: "Mot de passe actuel incorrect",

  minimumLength: "longueur minimale 8",
  atLeastOneBigAndSmall: "au moins 1 lettre minuscule et 1 lettre majuscule",
  passwordNotEqualUsernameAndEmail:
    "le mot de passe ne peut pas être égal au nom d'utilisateur ou à l'adresse électronique",

  registrationKey: "Clé d'enregistrement",
  registration: "Enregistrement",
  notAnEmailAdress: "Veuillez saisir une adresse électronique valide.",

  company: "Entreprise",
  companyHint: "Entreprise",
  birthday: "Anniversaire",
  birthdayHint: "Anniversaire",
  birthyear: "Année de naissance",
  accountName: "Nom du compte",
  accountType: "Type de compte",
  accountAddress: "Adresse du compte",
  logo: "Logo du compte",
  address: "Adresse",
  contry: "Pays",
  lessThan: "moins de 1mb",
  defaultLanguage: "Langue par défaut",
  accountStatus: "Statut du compte",
  accountNotes: "Notes sur le compte",
  createAccount: "Créer un compte",
  fileToImport: "Notes sur le compte",
  importStructure: "Importer la structure",
  updateAccount: "Mise à jour du compte",
  addRootAccount: "Ajouter un compte de premier niveau",
  usersPerMonth: "Utilisateurs par mois",

  usersAdded: "Utilisateurs ajoutés",
  usersDeleted: "Utilisateurs supprimés",
  usersTotal: "Total des utilisateurs",
  city: "Ville",
  users: "Utilisateurs",
  coursesAvailable: "Cours disponibles",
  started: "Commencés",
  completed: "Terminés",
  openMandatoryAssignments: "Affectations obligatoires ouvertes",
  mandatoryAssignments: "Affectations obligatoires",
  dueMandatoryCompletions: "Affectations obligatoires à échéance",
  of: "de",
  accountOverview: "Aperçu du compte",
  selectAccount: "Sélectionner un compte",
  noAccountsAvailable: "Aucun compte disponible",
  completionRate: "Taux d'achèvement",
  averageResult: "Résultat moyen",
  courseOverview: "Aperçu des cours",
  reportCoursesSearchPlaceholder: "Rechercher un cours",
  completedLastWeeks: "Cours terminés au cours des {number} dernières semaines",
  completedLastMonths: "Cours terminés au cours des {number} derniers mois",
  executionTime: "Répartition par temps d'exécution (min)",
  completionLevel: "Répartition par points d'achèvement",
  knowledge: "VUE D'ENSEMBLE",
  toolbox: "BOÎTE À OUTILS",
  lessonCompleted: "Leçon terminée",
  lessonFailed: "Échec de la leçon",
  repeatLesson: "Veuillez répéter la leçon pour continuer",
  congratulations: "Félicitations",
  courseCompleted: "Cours terminé",
  downloadCertificate:
    'Vous pouvez télécharger votre certificat sous "Certificats" dans votre profil, ou demander votre certificat par courrier électronique.',
  trueFalse: "Vrai ou faux ?",
  seconds: "sec",
  noChoiceText: "Trop tard, quel dommage !",
  right: "Vrai !",
  wrong: "Faux",
  percentCompleted:
    "Vous avez répondu correctement à $successRate% de toutes les questions.",
  progress: "$courant de $total",

  repeat: "Répéter",
  confirmRead: "Je confirme avoir lu le texte.",
  confirmedRead: "Vous avez confirmé avoir lu le texte.",
  confirmVideo: "Je confirme que j'ai regardé la vidéo.",
  confirmedVideo: "Vous avez confirmé avoir regardé la vidéo.",
  confirmPdf: "Je confirme avoir lu le document pdf.",
  confirmedPdf: "Vous avez confirmé avoir lu le document pdf.",
  responsesMissing: "Veuillez compléter tous les points avant de continuer",
  addCourseContent: "Ajouter le contenu du cours",
  addSemicolon: "Ajouter des termes séparées par un point-virgule",
  addToProgram: "Ajouter au programme",
  allAccounts: "Tous les comptes",
  certificate: "Certificat",
  certificates: "Certificats",
  completed: "Terminé",
  contentIsLoaded: "Le contenu est chargé",
  contentManager: "Gestionnaire de contenu",
  courseCompleted: "Cours terminé",
  courseContent: "Contenu du cours",
  courseDocuments: "Documents du cours",
  courseLables: "Labels du cours",
  courseLanguage: "Langue du cours",
  courseNotes: "Notes de cours",
  courseStructure: "Structure du cours",
  courseTags: "Étiquettes du cours",
  courseTitle: "Titre du cours",
  createCourse: "Créer un cours",
  curator: "CURATEUR",
  description: "Description du cours",
  details: "Détails",
  dueDate: "Date d'échéance",
  duration: "Durée du cours",
  exportStatistics: "Statistiques d'exportation",
  learningStandard: "Norme d'apprentissage",
  licenses: "Licences",
  mandatory: "Obligatoire",
  noAssessment: "Pas d'évaluation à faire",
  noCoursesAssigned:
    "Aucun cours n'est assigné à votre programme. Veuillez consulter",
  noCoursesAvailable: "Aucun cours n'est actuellement disponible",
  noDueDate: "Aucune date d'échéance",
  overview: "VUE D'ENSEMBLE",
  page: "page",
  pickAnAccount: "Choisir un compte",
  printCertificate: "Imprimer",
  publishCourse: "Publier le cours",
  publishedToAccount: "Publié sur le compte",
  relatedCourses: "Cours apparentés",
  remove: "Retirer",
  removeFromProgram: "Supprimer du programme",
  singleAccount: "Compte unique",
  startCourse: "Démarrer",
  successMessageOne: "Félicitations, vous avez terminé le cours avec succès ",
  successMessageThree:
    "Vous pouvez maintenant télécharger ou imprimer le certificat d'achèvement du cours.",
  successMessageTwo: " !",
  tags: "Tags",
  unpublishCourse: "Dépublier le cours",
  updateCourseContent: "Télécharger le contenu du cours",
  updateCourse: "Mettre à jour le cours",
  youDidntfinishCourse: "Vous n'avez terminé aucun cours.",
  courseVisibleFor: "Cours visible pour",
  accountStructureItem: "Département",
  courseAssigmentOptions: "Options d'attribution du cours",
  assignCourse: "Attribuer un cours",
  pickACourse: "Choisir un cours",

  noDocumentsAvailable: "aucun document disponible",
  nothingRelated: "aucun cours ne s'y rapporte",
  nothingWritten: "rien d'indiqué",
  noTagsAvailable: "pas de labels disponibles",
  alreadyGlobal: "Le cours est déjà publié globalement.",
  questionsanswered: "questions",
  completedOn: "Complété le",
  locale: "fr-FR",
  quizAuthorizationContent: "Autorisations pour les quiz",

  courseNotPublished: "Le cours n'est actuellement pas publié",
  courseNotPublishedForAccount:
    "Le cours n'est actuellement pas publié pour le compte $account",
  clickToPublishCourse: "Publier le cours",
  visible: "Visible",
  userIsNotContentManager:
    "Vous n'êtes pas répertorié comme gestionnaire de contenu et ne pouvez donc pas modifier le contenu de ce cours.",

  assignmentOpen: "Ouvert",
  assignmentStarted: "Commencé",
  assignmentCompleted: "Terminé",
  dueDatePassed: "Date d'échéance dépassée",
  exitCourse: "Quitter le cours",
  markAsFinished: "Marquer comme terminé",

  assignToAllUsers: "Attribuer un cours à tous les utilisateurs du compte",
  learningPoints: "Points d'apprentissage",
  back: "Retour",
  next: "Suivant",
  courseOverview: "Aperçu du cours",

  system: "Système",
  configureLogging: "Configuration de la journalisation",
  createAccount: "Créer un compte",
  changeAccount: "Modifier un compte",
  deleteAccount: "Supprimer un compte",
  importAccountStructure: "Importer la structure d'un compte",
  createUser: "Créer un utilisateur",
  importUser: "Importer des utilisateurs",
  changeUser: "Modifier un utilisateur",
  deleteUser: "Supprimer un utilisateur",
  assignUserRole: "Attribuer un rôle à l'utilisateur",
  resetPassword: "Réinitialiser le mot de passe",

  createCourse: "Créer un cours",
  changeCourse: "Modifier le cours",
  addContent: "Ajouter un contenu",
  changeContent: "Modifier le contenu",
  addQuizContent: "Ajouter le contenu d'un quiz",
  changeQuizContent: "Modifier le contenu du quiz",
  deleteCourse: "Supprimer le cours",
  deleteContent: "Supprimer le contenu",
  publishCourse: "Publier le cours",
  unpublishCourse: "Dépublier le cours",
  assignCourse: "Attribuer un cours",
  unassignCourse: "Annuler le cours",

  systemMessages: "Messages système",

  learningManagement: "Gestion de l'apprentissage",
  courseManagement: "Gestion des cours",
  userManagement: "Gestion des utilisateurs",
  organizationStructure: "Structure de l'organisation",
  accountManagement: "Gestion des comptes",
  system: "Système d'information",

  learningManagementDetail: "Plans d'apprentissage et affectation des cours",
  courseManagementDetail: "Administration du contenu des cours",
  userManagementDetail:
    "Administration des utilisateurs et réinitialisation des mots de passe",
  organizationStructureDetail: "Définition de la structure organisationnelle",
  accountManagementDetail: "Administration des comptes",
  logsDetail: "Journaux et paramètres du système",
  noResults: "Pas de résultats",
  noData: "Pas de données disponibles",

  userCreateSuccess: "Utilisateur créé avec succès",
  createOtherUser: "Créer un autre utilisateur",
  backToUserOverview: "Retour à l'aperçu des utilisateurs",
  currentRoles: "Rôles actuels",

  numberOfRoles: "Nombre de rôles",
  numberOfUsers: "Utilisateurs",
  deleteAccountMessage:
    "Veuillez confirmer que vous souhaitez supprimer le compte  '$account' .",

  showStatistics: "Afficher les statistiques",
  appendAccount: "Ajouter le compte à $account.",

  courseAssignments: "Affectations",
  courseAssignmentsForCourse: "Affectations pour $cours",
  mandatoryAssignments: "Affectations obligatoires",
  dueDateAssignments: "Avec date d'échéance",
  dueDatePassed: "Date d'échéance dépassée",

  userOrAccount: "Utilisateur ou compte",
  expiryDate: "Date d'expiration",

  deletePublication: "Dépublier le cours",
  deletePublicationMessage:
    "Êtes-vous sûr de vouloir dépublier le cours de $account ?",
  orgStructure: "Département",
  type: "Type de cours",
  downloadTemplate: "Télécharger le modèle",
  published: "Publié",
  scorm: "SCORM",
  quiz: "Quiz",
  staticFile: "Pdf",
  editPublication: "Éditer la publication",
  pleaseDefineCourseContent:
    "veuillez définir le contenu du cours avant de le publier.",
  usernameMissing: "Nom d'utilisateur manquant",
  usernameError:
    "Le nom d'utilisateur ne peut contenir que des caractères alphanumériques.",
  emailMissing: "E-Mail manquant",
  emailError: "L'adresse e-mail n'est pas valide",
  passwordMissing: "Mot de passe manquant",
  passwordError:
    "Le mot de passe doit contenir au moins 8 caractères, une lettre majuscule et une lettre minuscule.",

  registrationKeyManagement: "Clés d'enregistrement",
  registrationKeyManagementDetail: "Gestion des clés d'enregistrement",

  addRegistrationKey: "Ajouter une clé d'enregistrement",
  registrationLink: "Clé d'enregistrement",
  createKey: "Créer une clé",
  user_upload_maximum: " Jusqu'à 1000 utilisateurs par téléchargement",

  userState: "État de l'utilisateur",
  userStateDetail: "État du permis d'accès et des cours obligatoires.",
  testsPassed: "Tests réussis",
  access: "Accès",
  downloadReport: "Télécharger le rapport",
  success: "Sauvegardé avec succès !",
  warning: "Attention !",
  error: "Erreur !",
  removedFromProgram: "Le cours a été supprimé de votre programme",
  addedToProgram: "Le cours a été ajouté à votre programme",
  courseCreated: "Le cours a été créé",
  courseNotCreated: "Le cours n'a pas été créé",
  fillUpFields: "Veuillez remplir les champs obligatoires",
  cookieNotAvailable:
    "Vos informations de connexion ne sont pas disponibles ou sont trop anciennes, veuillez vous connecter à nouveau",
  courseNotFinished:
    "Le cours n'a pas fourni d'informations sur son achèvement",
  courseAborted: "Le cours a été interrompu",
  correctAnswer: "Réponse correcte",
  wrongAnswer: "Mauvaise réponse",
  courseStarted: "Le cours a commencé",
  testsPassed: "Test réussi",
  testFailed: "Test non réussi",
  courseCompleted: "Le cours a été terminé avec succès",
  imageTooLarge: "L'image doit avoir une taille inférieure à 2mb",
  wrongFormat: "Mauvais format",
  passwordReset:
    "Un lien pour réinitialiser le mot de passe a été envoyé à l'adresse électronique.",
  passwordChanged: "Le mot de passe a été modifié",
  accountAdded: "Le compte a été ajouté",
  accountUpdated: "Le compte a été mis à jour",
  accountDeleted: "Le compte a été supprimé",
  userImported: "Les utilisateurs ont été importés",
  userNotImported: "Les utilisateurs n'ont pas été importés",
  loggingConfigSaved: "La configuration de l'enregistrement a été sauvegardée",
  userDeleted: "L'utilisateur a été supprimé",
  userAdded: "L'utilisateur a été ajouté",
  userUpdated: "L'utilisateur a été mis à jour",
  GDPRLoaded: "GDPR est chargé",
  cookiesDisabled: "Vous avez désactivé les cookies",
  wrongCredentials: "Mauvaises informations d'identification",
  fileUploaded: "Votre fichier a été téléchargé",
  courseContentUpdated: "Le contenu du cours a été mis à jour",
  coursePublished: "Le cours a été publié",
  courseUnPublished: "Le cours a été dépublié avec succès",
  serverUnreachable: "Le serveur est inaccessible",
  courseContentAdded: "Le contenu du cours a été ajouté.",
  user_upload_exceeded:
    "Trop d'utilisateurs : jusqu'à 1000 utilisateurs par téléchargement sont pris en charge.",

  selectAccounts: "Sélectionner un compte",
  selectAccountToContinue: "Sélectionnez un compte pour continuer",
  accountOverview: "Aperçu du compte",
  users: "Utilisateurs",
  courses: "Cours",
  coursesCompleted: "Cours terminés",
  completedCoursesPerUser: "Cours terminés par l'utilisateur",
  learningHours: "Heures d'apprentissage",
  learningHoursPerUser: "Heures d'apprentissage par utilisateur",
  courseOverview: "Aperçu des cours",
  completed: "Terminé",
  assigned: "Attribué",
  courseDetails: "Détails du cours",
  details: "Détails du cours",
  selectCourse: "Sélectionner un cours",
  type: "Type de cours",
  dueDate: "Date d'échéance",
  mandatory: "Obligatoire",
  completionRate: "Taux d'achèvement",
  averageScore: "Note moyenne",
  duration: "Durée de la formation",
  averageDuration: "Durée moyenne",
  account: "Compte",
  accountName: "Nom du compte",
  accounts: "Comptes",
  active: "Actif",
  admin: "Admin.",
  all: "Tous les comptes",
  not_found: "Page non trouvée",
  assessmentStatistics: "Statistiques d'évaluation",
  assignedUsersOnly: "Utilisateurs assignés uniquement",
  autoIndividual: "Auto, individuel",
  cancel: "Annuler",
  cardImage: "Image de la carte",
  close: "Fermer",
  completed: "Terminé",
  confirm: "Confirmer",
  country: "Pays",
  course: "Cours",
  courseDescription: "Description du cours",
  courseDocuments: "Documents du cours",
  courses: "Cours",
  courseStructure: "Structure du cours",
  dataProtection: "Protection des données",
  defaultLanguage: "Langue par défaut",
  deleteText: "Êtes-vous sûr de vouloir supprimer ",
  details: "Détails",
  dragAndDrop: "Glisser-déposer ou cliquer pour ajouter",
  dueDate: "Date d'échéance",
  email: "Courriel",
  empty: "Vide",
  exit: "Quitter",
  expiresOn: "Expire le",
  fileImport: "Fichier à importer",
  image: "Image",
  inactive: "Inactif",
  language: "Langue",
  logo: "Logo",
  mandatory: "Obligatoire",
  monthsLabels: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  myAccount: "Mon compte",
  myProfile: "Mon profil",
  myProgram: "Mon programme",
  myRoles: "Mes rôles",
  never: "Jamais",
  no: "jamais",
  none: "Aucun",
  notFound: "Pas trouvé",
  phoneNumber: "Numéro de téléphone",
  playImage: "Montrer l'image",
  publishingExpiresOn: "La publication expire le",
  publishTo: "Publier dans",
  reports: "Rapports",
  save: "Sauvegarder",
  search: "Rechercher",
  status: "Statut",
  structureItem: "Structure de l'article",
  termsAndConditions: "Conditions générales d'utilisation",
  unlimited: "Illimité",
  update: "Mise à jour",
  user: "Utilisateur",
  username: "Nom d'utilisateur",
  users: "Utilisateurs",
  visibility: "Visibilité du compte",
  yes: "oui",
  zipCode: "Code postal",

  info: "Info",
  error: "Erreur",
  alert: "Alerte",
  criticalAlert: "Alerte critique",
  navigation: "Navigation",
  system: "Système",
  note: "Note d'information",
  userProfile: "Profil de l'utilisateur",

  userId: "Identifiant de l'utilisateur",
  customId: "Identifiant personnalisé",
  organizationalStructure: "Structure organisationnelle",
  created: "Créé",
  new: "Nouveau",
  noResults: "Pas de résultats",
  noData: "Aucune donnée disponible",
  uploadFile: "Télécharger le fichier",

  id: "ID",
  name: "Nom",
  delete: "Supprimer",
  append: "Ajouter",
  edit: "Modifier",
  format: "Format",

  years: "Années",
  months: "Mois",
  weeks: "Semaines",
  days: "Jours",
  seconds: "Secondes",
  minutes: "Minutes",
  hours: "Hours",

  date: "Date",
  message: "Message",
  fax: "Fax",

  genericError:
    "Une erreur s'est produite, essayez de recharger la page. Si l'erreur persiste, contactez votre administrateur système.",
  action: "Action",
  // TODO
  roles: "Rôles",
  properties: "Propriétés",
  property: "Propriété",
  assignUserProperty: "Attribuer une propriété à un utilisateur",
  userProperties: "Propriétés de l'utilisateur",
  addTagToUser: "Ajouter une propriété à un utilisateur",
  showMoreFilters: "Afficher plus de filtres",

  assigned: "Attribué",
  unassigned: "Non attribué",
  assignedOrUnassigned: "Assigné / Non-assigné",
  emailAboutChange: "Envoyer un courriel de notification aux utilisateurs",
  showLessFilters: "Afficher moins de filtres",
  clearFilters: "Effacer les filtres",
  no_changes_assignment_button: "Pas de changement",
  assignment: "Affectation",

  upload_picture: "Télécharger l'image",
  take_picture: "Prendre une photo",
  access_card: "Carte d'accès",

  assign_course: "Assigner un cours",
  add_assignment_to_users: "assigner à $users",
  remove_assignments_from_users: "supprimer l'affectation de $users",
  update_assignments_for_users: "mettre à jour les affectations de $users",
  create_property: 'Créer une nouvelle propriété "$property"',
  non_mandatory: "Non obligatoire",
  department: "Département",
  user_details: "Détails de l'utilisateur",
  download_file: "Télécharger le fichier",
  your_browser_not_support_this_content:
    "Votre navigateur ne permet pas d'afficher ce contenu.",
  browser_not_support_pdf:
    "Votre navigateur ne peut pas afficher les fichiers pdf. Défilez vers le bas pour télécharger le pdf à la place.",
  upload_file: "Télécharger le fichier",
  deleteDocument: "Supprimer le document",
  deleteDocumentConfirmation: "Êtes-vous sûr de vouloir supprimer $document ?",
  video_unsupported: "Votre navigateur ne supporte pas la vidéo HTML5.",
  confirm: "Confirmer",
  confirmed: "Confirmé",
  multiselect: "Autoriser la sélection de plusieurs propriétés",
  profile_image: "Image du profil",
  account_details: "Détails du compte",
  mandatory_user_properties: "Propriétés obligatoires de l'utilisateur",
  published_courses_for_account: "Cours publiés",
  select_property_first: "Veuillez sélectionner une propriété",
  selected_property_already_mandatory:
    "La propriété sélectionnée est déjà obligatoire.",
  remove_assigned_properties: "Supprimer les propriétés attribuées",
  property_type: "Type de propriété",

  confirm_remove_assigned_properties:
    "Veuillez confirmer que vous souhaitez supprimer toutes les propriétés de type $type actuellement attribuées. Cette action ne peut pas être annulée.",
  tags_removed_from_users:
    "Toutes les propriétés attribuées du type $type ont été supprimées.",

  add_mandatory_user_property:
    "Ajouter une propriété obligatoire pour l'utilisateur",

  upload_certificate: "Télécharger le certificat",

  delete_certificate: "Supprimer le certificat",
  confirm_delete_certificate:
    "Êtes-vous sûr de vouloir supprimer le certificat téléchargé $certificateName ?",

  certificateName: "Nom du certificat",
  issuedFrom: "Délivré par",
  ectsPoints: "Points ECTS",
  ects: "ECTS",
  completedInPercent: "Complété en pourcentage",

  issuedAt: "Délivré à",
  receivedAt: "Reçu le",
  validUntil: "Valable jusqu'au",
  unlimitedValidity: "Validité illimitée",

  uploadedCertificates: "Certificats téléchargés",

  // TODO

  drag_drop_steps:
    "Glissez-déposez les étapes depuis la gauche pour les ajouter à une leçon.",
  add_lesson: "Ajouter une leçon",

  minCompletion: "Pourcentage minimum de points pour terminer la leçon",
  randomStepOrder: "Randomiser l'ordre des étapes",
  fixedViewExecutionOrder: "Randomiser l'ordre des pages",
  allowBackNavigation: "Permettre la navigation arrière",
  maxTimeForCompletions: "Temps maximum d'achèvement en minutes",
  maxRetries: "Nombre maximum de tentatives",
  maxNumberOfCompletions: "Nombre maximum d'essais pour améliorer le score",

  quiz: "Quiz",
  text: "Texte",
  multimedia: "Vidéo",
  pdf: "Pdf",
  inputText: "Saisie de texte",
  inputDate: "Saisie de la date",

  quiz_description:
    "Poser une question et permettre à l'utilisateur de choisir parmi plusieurs options de réponse.",
  text_description:
    "Montrer un texte à l'utilisateur et lui permettre de confirmer éventuellement son contenu.",
  multimedia_description:
    "Montrer une vidéo à l'utilisateur et lui permettre de confirmer éventuellement qu'il en a vu le contenu.",
  pdf_description:
    "Montrer une vidéo à l'utilisateur et lui laisser la possibilité de confirmer qu'il en a visionné le contenu.",
  inputText_description: "Laissez l'utilisateur saisir du texte.",
  inputDate_description: "Laissez l'utilisateur saisir une date.",

  lessons: "Leçons",
  new_lesson: "Nouvelle leçon",

  settings: "Paramètres",
  requiresCompletion: "Cette étape doit être complétée pour continuer",
  requiresInteraction:
    "L'utilisateur doit confirmer qu'il a consulté cette étape",
  isEditable: "L'utilisateur peut modifier cette étape",
  points: "Points",
  clickToEdit: "(cliquer pour éditer)",
  choiceSettings: "Paramètres de choix",
  choice: "Choix",
  choiceText: "Choix $index texte",
  choiceExplanation: "Choix $index explication",
  choiceIsCorrect: "Corriger le choix",
  addChoice: "Ajouter un choix",
  deleteChoice: "Supprimer le choix",
  uploadVideoFile: "Télécharger le fichier vidéo",
  discardChanges: "Rejeter les modifications",
  addInputLabel: "(ajouter une étiquette)",
  inputType: "Type d'entrée",
  week: "Semaine",
  month: "Mois",
  year: "Année",
  label: "Étiquette",
  minDate: "Date minimum",
  maxDate: "Date maximale",
  oneLine: "Ligne unique",
  multipleLine: "Lignes multiples",
  rows: "Lignes",
  minLength: "Longueur minimale",
  maxLength: "Longueur maximale",
  showWordLimit: "Afficher la limite de caractères",
  // TODO: translate

  lessonFailedNoMoreRetries:
    "Vous avez échoué à la leçon après $retries tentatives.",
  lessonFailedMoreRetries:
    "Il vous reste $retries tentatives pour terminer la leçon.",
  lessonFailedOneMoreRetries:
    "Il vous reste une tentative pour terminer la leçon.",

  // TODO:
  registrationKeyAlphanumeric:
    "Clé d'enregistrement (alphanumérique, min. {length} lettres)",
  registrationKeyMinimumLength:
    "La clé d'enregistrement doit comporter au moins {length} lettres",
  chooseAnAccountToContinue: "Sélectionner un compte pour continuer",

  help: "Aide",
  alreadyRegistered: "Déjà inscrit",
  additionalDetails: "Détails supplémentaires",
  additionalDetailsSave: "Confirmer",
  upload: "Télécharger",
  uploadVideo: "Télécharger une vidéo",
  uploadPdf: "Télécharger un pdf",
  step: "Etape",
  flexView: "Page",
  steps: "Etapes",
  flexViews: "Pages",
  lesson: "Leçon",
  dragInThisAreaToDelete:
    "Glisser-déposer des éléments dans cette zone pour les supprimer",
  editor: "Éditeur",
  baseLanguage: "Langue de base",
  translation: "Traduction",
  editCourse: "Modifier le cours",
  editTranslation: "Modifier les traductions",
  additionalDetailsSave: "Confirmer",
  dragAndDropCertificate:
    "Glissez-déposez le fichier PDF ici ou cliquez pour l'ajouter",
  userProfileV2: "Profil de l'utilisateur V2",
  certification: "Certification",
  issuedBy: "Délivrée par",
  issuedOn: "Délivrée le",
  fulfilledInPercent: "Réalisé en pourcentage",

  systemLogs: "Journal du système",
  systemConfigurationDetail: "Configuration des paramètres du système",
  systemConfiguration: "Configuration du système",
  views: "Vues",
  apis: "API",
  apisDetail: "Gérer les clés API",
  logs: "Journal",
  viewsDescription: "Visibilité de l'élément dans la vue correspondante",
  courseFormatDescription: "Formats de cours disponibles pour définir le cours",
  accessCard: "Carte d'accès",
  downloadCertification: "Télécharger la certification",

  pointTracking: "Points d'affichage",
  repeatable: "Répétable",
  completionShowScore: "Afficher le score après achèvement",

  plusNewTag: "+ Nouveau label",
  orSeparator: "ou",
  fileName: "Nom du fichier",
  fileSize: "Taille du fichier",
  contentManagerPicker: "Gestionnaire de contenu",
  errorSavingChanges: "Une erreur s'est produite",
  errorSavingChangesBody:
    "Une erreur s'est produite lors de l'enregistrement de vos modifications. Vérifiez votre connexion internet et réessayez dans quelques minutes.",
  retry: "Réessayer",
  editLesson: "Modifier la leçon",
  templates: "Modèles",
  courseStructure: "Structure du cours",
  statistics: "Statistiques",
  saveChangesTitle: "Sauvegarder les modifications",
  saveChangesBody:
    "Vous avez des modifications non enregistrées. Ces modifications doivent-elles être enregistrées ou supprimées ?",
  flexLessonLocked:
    "La leçon doit être terminée avant que l'utilisateur puisse continuer.",
  flexSubset:
    "Nombre de pages choisies au hasard parmi toutes les pages de la leçon.",
  curatorName: "Nom du curateur",
  curatorCompany: "Société du curateur",
  curatorDescription: "Description du curateur",
  courseCompletedTitle: "Titre du cours terminé",
  courseCompletedSubtitle: "Sous-titre du cours terminé",
  courseCompletedNote: "Texte du cours terminé",
  completionShowScore: "Afficher le score dans la vue de la fin du cours",
  failedCourseInformation:
    "Texte à afficher lorsque l'utilisateur échoue au cours",
  pinPage: "Toujours afficher cette page au début ou à la fin de la leçon",
  start: "Début",
  end: "Fin",
  courseDuration: "Durée du cours en minutes",
  randomizeAnswers: "Randomiser les réponses",
  closeEditor: "Fermer l'éditeur",
  courseNotEditable:
    "Ce cours est déjà utilisé et seules les traductions peuvent être éditées.",
  flex: "Flex",
  event: "Événement",
  instructions: "Instructions",
  isOnlineEvent: "Evénement en ligne",
  eventLocation: "Lieu de l'événement",
  eventLink: "Lien vers l'événement",
  selfRegistration: "Auto-inscription",
  attendanceConfirmation: "Présence",
  passedConfirmation: "Passer",
  mark: "Marque",
  trainer: "Formateur",
  attendanceAndGrades: "Présence et évaluation",
  onlyIfMandatoryCourse: "Si le cours est attribué",
  allUsersInAccount: "Tous les utilisateurs du compte",
  dateManagement: "Gestion des dates",
  dateManagementDetail: "Gérer les dates et les résultats des événements",
  courseFilter: "Filtre de cours",
  fromTo: "De - à",
  capacity: "Capacité",
  unlimitedCapacity: "Capacité illimitée",
  invalidatePreregistration:
    "Invalider les pré-inscriptions quelques heures avant l'événement",
  allowPreregistration: "Autoriser les pré-inscriptions",
  newDate: "Nouvelle date",
  editDate: "Modifier la date",
  preenrollments: "Pré-inscription",
  enrollments: "Inscription",
  preregistrationDate: "Préinscription $date",
  registered: "Enregistré",
  notRegistered: "Non enregistré",
  registeredAndNotRegistered: "Inscrit / Non inscrit",
  selectCourseToContinue: "Sélectionnez un cours pour continuer",
  changesSavedSuccessfully: "Changements sauvegardés avec succès",
  cancelRegistrationTitle: "Annuler l'inscription",
  cancelRegistrationBody:
    "Etes-vous sûr de vouloir annuler l'inscription de $nom pour la date suivante ?",
  date: "Date",
  dates: "Date d'inscription",
  unregister: "Annuler l'inscription",
  changeRegistrationTitle: "Modifier l'inscription",
  currentRegistration: "$name est déjà inscrit pour la date suivante",
  changeRegistrationBody:
    "Voulez-vous annuler cette inscription et créer une inscription pour la date suivante ?",
  preregistrationAvailableRegistrations:
    "Pré-inscriptions / places disponibles",
  seatsLeft: "places disponibles",
  registrationTitle: "Inscription",
  registrationBody:
    "En inscrivant l'utilisateur à $course, une affectation obligatoire à ce cours sera créée pour l'utilisateur.",
  register: "S'inscrire",
  myDates: "Mes rendez-vous",
  dateDetails: "Détails de la date",
  attendees: "Participants",
  fractionOfTotal: "fraction de $total",
  registrations: "Inscriptions",
  link: "Lien",
  notPassed: "Pas passé",
  passed: "Passé",
  attended: "Présents",
  notAttended: "Pas de participation",
  youAreRegisteredFor: "Vous êtes inscrit pour la date suivante : ",
  unlimitedSeats: "Capacité illimitée",
  otherDates: "Autres dates",
  managerWillAssignDate: "Votre gestionnaire vous attribuera une date.",

  courseCompletionsPerUser: "Cours terminés par utilisateur",
  userState: "État de l'utilisateur",
  accountStatistics: "Statistiques du compte",
  reportExcelDownloadBtn: "Télécharger un rapport Excel",
  reportDisplayBtn: "Afficher le rapport",
  covidReport: "Rapport Covid",
  reportExcelCourseCompletion:
    "Rapport sur l'affectation et l'achèvement des cours",
  reportExcelPracticalTests: "Rapport d'événements",
  listOfAccomplishedCourses:
    "Liste des réalisations de cours obligatoires et optionnels par utilisateur",
  reportUserState:
    "Liste de l'état d'accès de l'utilisateur et de l'état des cours obligatoires attribués",
  descriptionCovidReport: "Liste des certificats de vaccination COVID",
  descriptionCourseAssignmentsAndCompletion:
    "Liste des cours attribués et état actuel de leur achèvement.",
  descriptionEventReport:
    "Liste des inscriptions et des présences aux événements",
  descriptionAccountStatistics:
    "KPI spécifiques au compte et statistiques des cours publiés pour le compte sélectionné",
  userRoleAssignments: "Rôles attribués aux utilisateurs",
  listOfRolesPerUser: "Liste des rôles attribués à l'utilisateur par compte",

  copied: "Lien copié dans le presse-papiers",
  covidCertificates: "Certificats de vaccination",
  covidCertificatesDetail: "Gérer les certificats de vaccination et de test",
  covidInstructions: "Instructions COVID-19",
  covid: "COVID-19",
  addCertificate: "Ajouter un certificat",
  scanQrCode: "Scanner le QR-Code",
  scanQrCodeDescription:
    "Les QR-Codes suivants peuvent être scannés ou téléchargés :  Certificats de vaccination, tests PCR et antigènes, certificats de récupération.",
  openFile: "Ouvrir un fichier",
  openImage: "Ouvrir un fichier image",
  validFrom: "Valable depuis le $date",
  validFor: "Valable le $date",
  vaccinationCertificatesValid:
    "Vos certificats sont conformes aux règles en vigueur",
  vaccinationCertificatesInvalid:
    "Vos certificats ne sont pas conformes aux règles en vigueur",
  certificateInvalid: "Ce certificat n'est pas valide",
  certificateExpired: "Ce certificat est expiré",
  updatedOn: "Mis à jour le $date",
  uploadingCertificate: "Téléchargement du certificat",
  certificateUploadError:
    "Une erreur s'est produite lors du téléchargement du certificat.",
  certificateNameMismatch: "Le nom du certificat ne correspond pas",
  certificateBirthdayMismatch:
    "La date de naissance du certificat ne correspond pas",
  certificateExists: "Ce certificat existe déjà",
  certificateUploaded: "Le certificat a été téléchargé avec succès",
  instructions: "Instructions",
  accessRules: "Règles d'accès",
  qrCode: "QR-Code",
  certificateDeleted: "Certificat supprimé",
  deleteCertificateConfirmation:
    "Êtes-vous sûr de vouloir supprimer ce certificat ?",
  covidInstructionsLabel:
    "Instruction dans la langue par défaut du système $lang.",
  cameraNotAllowedError:
    "L'accès à la caméra a été refusé. Recharger la page pour autoriser l'accès ou autoriser l'accès dans les paramètres du navigateur.",
  cameraNotFoundError:
    "Aucune caméra n'a été trouvée, utilisez plutôt la fonctionnalité de téléchargement.",
  cameraNotReadableError:
    "Impossible d'accéder à la caméra - assurez-vous qu'aucune autre application n'utilise la caméra.",
  cameraNotAvailable:
    "Votre navigateur ne prend pas en charge l'accès à la caméra. Veuillez utiliser la fonctionnalité de téléchargement à la place.",
  qrCodeUploadError:
    "Le code QR n'a pas pu être lu. Vérifiez que l'image ne contient qu'un seul code QR et que celui-ci est lisible. Si le problème persiste, essayez une image de plus haute résolution.",
  confirmCovidRulesManuallyTitle:
    "COVID-19 - contrôle visuel de l'état des documents",
  confirmCovidRulesBody:
    "Je confirme que les documents fournis par $user sont conformes aux règles COVID-19 en vigueur et qu'ils sont valables pour la journée en cours.",
  activateApis: "Activer Apis",
  allowApiManagement: "Autoriser la gestion de l'api",
  allowedApis: "Apis autorisé",
  volume: "Volume en req/jour",
  throttle: "Vitesse en req/min",

  keys: "Clés",
  key: "Clé",
  keyName: "Nom de la touche",
  keyPurpose: "Objectif",
  createApiKey: "Ajouter une clé Api",
  keyCopiedToClipboard: "Copie réussie de la clé dans le presse-papiers.",
  deleteKey: "Supprimer la clé",
  deleteKeyMessage:
    "Êtes-vous sûr de vouloir supprimer la clé '$name' pour '$purpose' ?",
  changeKey: "Modifier la clé",
  keyCreatedSuccessfully: "La clé api a été créée avec succès",
  api: "Api",
  addApi: "Ajouter une api",
  editApi: "Modifier l'api",
  example: "Exemple",
  apiDetails: "Détails de l'api",
  notAvailable: "Non disponible",
  method: "Méthode",
  requestBody: "Corps de la requête",
  responses: "Réponses",
  parameters: "Paramètres",
  manualCheck: "Contrôle manuel",
  qGateTitle: "Contrôle de la qualité des données de l'utilisateur",
  qGateDescription:
    "Si le Q-Gate est activé, le contrôle de la porte est demandé pour vérifier la qualité des données de l'utilisateur et peut également ajouter des détails supplémentaires aux données de l'utilisateur.",
  subcontractorTitle: "Afficher l'onglet des sous-traitants",
  subcontractorDescription:
    "Permet d'ajouter des sous-traitants à un compte et d'affecter des utilisateurs à des sous-traitants.",
  subcontractors: "Sous-traitant",
  subcontractors: "Sous-traitants",
  addSubcontractor: "Ajouter un sous-traitant",
  editSubcontractor: "Modifier un sous-traitant",
  street: "Rue",
  deleteSubcontractor: "Supprimer un sous-traitant",
  deleteSubcontractorBody: "Société : $name",
  deleteSubcontractorHasReference:
    "Le sous-traitant a déjà des utilisateurs qui lui sont attribués et ne peut donc pas être supprimé.",
  accessRegistration: "Enregistrement de l'accès",
  accessFrom: "Accès à partir de",
  accessTo: "Fin probable de l'accès",
  birthplace: "Lieu de naissance",
  nationality: "Nationalité",
  car: "Voiture",
  carPlate: "Plaque d'immatriculation",
  socialSecurity: "Numéro de sécurité sociale",
  workPermit: "Permis de travail jusqu'au",
  confirmData:
    "Je confirme que les données saisies sont complètes et correctes.",
  accessRegistration: "Enregistrement de l'accès",
  registeredOn: "Enregistré le",
  registeredBy: "Enregistré par",
  isOnline: "En ligne",
  coursePreRegistrationUsedFrom:
    'Nombre total de préinscriptions pour "{nom du cours}" - {preRegTotal} sur le point d\'être annulées : {preRegAboutToCancel}',
  notEnoughCapacityReload:
    "Pas assez de capacité libre. Les capacités actuelles sont en train d'être rechargées.",
  editUserData: "Modifier les données de l'utilisateur",
  dateSpecificInstructions: "Instructions spécifiques à l'événement",
  preRegistrationClosed:
    "Les pré-enregistrements sont expirés pour cette date.",
  preRegistrationNotificationsTitle:
    "Envoyer des courriers électroniques concernant les préinscriptions arrivant à expiration",
  preRegistrationNotificationsDescription:
    "Le propriétaire du compte sera informé des enregistrements existants et des pré-enregistrements expirant dans les prochains jours.",
  automaticAssignments: "Automatisation",
  infoOnly: "Informations uniquement",
  automated: "Automatisé",
  assignAutomaticallyTo: "Assigner automatiquement à",
  allUsers: "Tous les utilisateurs",
  usersWithAssignedProperty: "Utilisateurs ayant une propriété attribuée",
  assignmentType: "Type d'affectation",
  optionally: "Facultatif",
  mandatory: "Obligatoire",
  exactDate: "Date exacte",
  relativeDateOffsetDays: "Relative (jours de décalage)",
  emailNotification: "Notification par courriel",
  selectPropertyMandatory: "Sélectionner un bien",
  setAllSelected: "Définir tous les sélectionnés",
  nSelected: "{count} sélectionné",
  publishedUnpublished: "Publié/non publié",
  published: "Publié",
  unpublished: "Non publié",
  noneInfoAutomated: "Aucun/info/automatisé",
  scope: "Champ d'application",
  tagged: "Étiqueté",
  notification: "Notification",
  selectAll: "Sélectionner tout",
  passwordResetLinkInactive:
    "Ce lien de réinitialisation du mot de passe n'est plus valide.",
  requestNewLink: "Demander un nouveau lien de réinitialisation",
  testQuestionnaire: "Questionnaire de test",
  testQuestionnaireDetail:
    "Gérer les questionnaires de test pour les événements",
  newQuestionnaire: "Nouveau questionnaire",
  newTestQuestionnaire: "Nouveau questionnaire de test",
  addQuestion: "Ajouter une question",
  editTestQuestionnaire: "Modifier le questionnaire de test",

  requiredPoints: "Points requis",
  questions: "Questions à l'ordre du jour",
  question: "Question",
  answer: "Réponse",
  responseDocumentation: "Réponse documentation",
  ko: "Ko",
  failedPassed: "Échec/réussite",
  failedPassedComment: "Echec/réussite + commentaire",
  assignedCourses: "Cours attribués",
  noCoursesAssigned: "Pas de cours attribués",
  questionnaire: "Questionnaire",
  questionnaireLocked: "Questionnaire verrouillé",
  born: "né",
  examinee: "Candidat à l'examen",
  totalResult: "Résultat total",
  comment: "Commentaire",
  done: "Terminé",
  pointsRequired: "Points requis",
  correct: "Correct",
  wrong: "Faux",
  locked: "Verrouillé",
  locks: "Verrouillé",
  lockedAtCest: "Verrouillé à (CEST)",
  unlockCourse: "Débloquer le cours",
  unlockCourseMessage:
    "Etes-vous sûr de vouloir débloquer le cours {0} pour l'utilisateur {1} ? ?",
  allowedNumberOfAttempts: "Nombre de tentatives autorisées",
  noDates: "Il n'y a actuellement aucune date",
  testResults: "Résultats du test",
  pointScore: "Points : {points} points",
  markScore: "Note : {marque}",
  registrationLocked: "Inscription verrouillée",
  registrationLockedMessage:
    "Les résultats de l'événement sont en cours d'évaluation. Il sera possible de modifier l'inscription lorsque l'évaluation sera terminée.",
  properties: "Propriétés",
  propertiesDetail: "Gérer les propriétés de l'utilisateur et du cours",
  types: "Types",
  addTagType: "Ajouter un type",
  editType: "Modifier le type",
  create: "Créer",
  deleteTagType: "Supprimer un type de balise",
  confirmDeleteTagTypeName:
    "Êtes-vous sûr que le type {nom} doit être supprimé ?",
  relatedProperties: "Propriétés associées",
  addTagValue: "Ajouter une propriété",
  deleteTagValue: "Supprimer une propriété",
  confirmDeleteTagValueName:
    "Êtes-vous sûr que la propriété {nom} doit être supprimée ?",
  editTagValue: "Modifier une propriété",
  purpose: "Objectif",
  uploadLabel: "Étiquette de téléchargement",
  tagLabelSizeError:
    "La taille du fichier image sélectionné est trop importante. Seules les images d'une taille maximale de 20 Ko sont prises en charge.",
  passed: "Accepté",
  failed: "Échec",
  emergencyContact: "Contact en cas d'urgence",
  emergencyContactPhone: "Numéro de téléphone du contact d'urgence",
  userHasNoAccount: "Cet utilisateur n'a pas de compte",
  driveInPermit: "Permis de conduire",
  downloadRegistrationForm: "Formulaire d'inscription",
  accessStartDate: "Date de début d'accès",
  today: "Aujourd'hui",
  tomorrow: "Demain",
  dayAfterTomorrow: "Après-demain",
  tests: "Test",
  createTest: "Créer un test",
  planned: "Prévu",
  myTests: "Mes tests",
  houseNumber: "Numéro",
  actionResult: "Action",
  actionResultKpi: "KPI",
  missedAfter: "Considéré comme manqué après",
  testPerson: "Personne chargée des tests",
  createAndDocument: "Créer et documenter les",
  eob: "fin de journée",
  testDate: "Date du test",
  test: "Test",
  testManagement: "Gestion des tests",
  testManagementDetail: "Gérer les types de tests",
  addTestType: "Ajouter un type de test",
  testType: "Type de test",
  testTeam: "Équipe de test",
  storage: "Stockage",
  validity: "Validité",
  editTestType: "Modifier le type de test",
  testValidityInDays: "Validité du test (jours)",
  retentionPeriod: "Période de rétention",
  createTestType: "Créer un type de test",
  authorizedTesters: "Testeurs autorisés",
  testAlertReceivers:
    "Alerter les destinataires en cas de résultat positif (adresses électroniques, séparées par des virgules)",
  documentTestResult: "Documenter le résultat du test",
  confirmCorrectnessTestResultData: `Veuillez confirmer l'exactitude des données saisies. 
  Après confirmation, aucune modification ne peut être apportée.`,
  testCreated: "Création du test",
  testResultDocumented: "Résultat du test sauvegardé",
  result: "Résultat",
  expiresIn: "Expire le",
  deleteTestType: "Supprimer un type de test",
  deleteTestTypeMessage:
    "Êtes-vous sûr de vouloir supprimer le type de test {0} ?",
  positive: "Positif",
  negative: "Négatif",
  testResult: "Résultat du test",
  open: "Ouvrir",
  testReportHeader: "Calendrier et résultats des tests",
  testReportBody:
    "Vue d'ensemble des tests programmés et des résultats par utilisateur et par date",
  display: "Afficher",
  testReportEnterData:
    "Veuillez sélectionner une date de début/fin et un type de test",
  startDate: "Date de début",
  endDate: "Date de fin",
  searchUser: "Rechercher un utilisateur",
  completedAt: "Terminé",
  enterAtLeastTwoLetters: "Saisissez au moins deux lettres",
  testsLimitExceeded:
    "Votre requête a trop de résultats, l'affichage est limité à 2000 résultats.",
  missed: "Manqué",
  scheduled: "Planifié",
  saveAndApply: "Sauvegarder et appliquer",
  errorApplyingRule:
    "Erreur dans l'application de la règle. Réessayez dans quelques minutes.",
  triggerAllSelected:
    "Déclencher l'automatisation pour les utilisateurs existants dans les comptes sélectionnés.",
  assignmentRuleApplied: "Règles d'affectation appliquées.",
  selectAccountWithAutomationToContinue:
    "Sélectionnez au moins un compte avec une règle d'automatisation à déclencher.",

  automationCenter: "Centre d'automatisation",
  automationCenterDetail: "Créer et gérer des règles automatisées.",
  newRule: "Nouvelle règle",
  topic: "Sujet",
  newTestCenterAutomationRule:
    "Nouvelle règle d'automatisation du centre de test",
  global: "Globale",
  accountSpecific: "Spécifique au compte",
  selectTemplate: "Sélectionner un modèle",
  lastRun: "Dernière exécution",
  rule: "Règle",
  reportIsBeingCreated: "Le rapport est en cours de création.",
  selectedAccounts: "Comptes sélectionnés",
  mo: "Lun",
  tu: "Mar",
  we: "Mer",
  th: "Jeu",
  fr: "Ven",
  sa: "Sam",
  su: "Dim",
  selectTopic: "Sélectionner un sujet",
  deleteAutomationRule: "Supprimer une règle d'automatisation",
  deleteAutomationRuleConfimration:
    "Êtes-vous sûr de vouloir supprimer la règle {règle} ?",
  editAutomation: "Modifier la règle d'automatisation",
  triggerAt: "Déclenchement à",
  reportIsBeingCreated: "Le rapport est en cours de création.",
  addCoverPhoto: "Ajouter une photo de couverture",
  confirmDelete: "Confirmer la suppression",
  confirmDeleteTest:
    "Etes-vous sûr de vouloir supprimer le test {type} le {date} pour {nom} ?",
  miscellaneous: "Divers",
  addCoverPhoto: "Ajouter une photo de couverture",
  triggeredBy: "Déclenché par",
  criticality: "Criticité",
  logDetails: "Détails du journal",
  lastHour: "Dernière heure",
  last24Hours: "Dernières 24 heures",
  last3Days: "Trois derniers jours",
  lastWeek: "Dernière semaine",
  accountsImported: "Comptes importés",
  importMoreAccounts: "Importer plus de comptes",
  backToOrganization: "Retour à l'organigramme",

  retention: "Rétention",
  maximumAllowed: "Le maximum autorisé est {maximum}.",
  completionValidity: "Durée de validité",
  completionValidityinCertificate: "Voir la durée de validité du certificat",
  useTranslationsToEdit: "Veuillez utiliser l'éditeur de traductions",
  hardwareRestrictions: "Restrictions sur les appareils",
  hardwareRestriction: "Restrictions relatives aux appareils",
  hardwareRestrictionsDetail:
    "Gérer sur quels appareils certains cours peuvent être suivis",
  installed: "Installé",
  notInstalled: "Non installé",
  installKeyOnThisDevice: "Installer la clé sur cet appareil",
  removeKeyOnThisDevice: "Retirer la clé de cet appareil",
  add: "Ajouter",
  createKey: "Créer une clé",
  editKey: "Modifier la clé",
  accessCourseAccount: "Accès (Cours : Comptes)",
  deselectAll: "Désélectionner tout",
  hardwareRestrictionKeyRequiredHeader: "Cours restreint",
  hardwareRestrictionKeyRequiredBody:
    "Ce cours ne peut être dispensé que sur des ordinateurs spécifiques. Si ce cours doit être exécuté sur cet appareil, veuillez contacter votre superviseur..",
  logout: "Déconnexion",
  continue: "Continuer",
  courseFinishedDoYouWantToLogout:
    "Le cours est terminé. Voulez-vous vous déconnecter ou continuer? Vous serez automatiquement déconnecté dans {seconds}.",
  stateless: "Apatride",
};
