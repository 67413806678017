import { toolbarOptions } from "@/utils/editorSettings";

export default {
  props: {
    step: { type: Object, required: true },
  },
  data: () => ({ toolbarOptions }),
  computed: {},
  methods: {
    patchStep(patch) {
      patch.id = this.step.id;
      patch.type = this.step.type;
      this.$store.commit("addStepPatch", patch);
    },
  },
};
