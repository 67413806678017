<template lang="pug">
.modal
  .modal__content.pl-2.pr-2.pb-1(
    :class="{ 'modal__content--auto': autoWidth, 'modal__content--wide': wide }"
  )
    .modal__header-wrapper(v-if="!clear")
      h3.header.fill-width
        slot(name="title")
      CloseIcon.ml-1(v-if="closable", :clickable="true", @click="close")
    .modal__lined
    slot
    .modal__footer
      slot(name="footer")
</template>

<script>
import CloseIcon from "@/components/icons/CloseIcon.vue";
export default {
  name: "ModalComponent",
  components: {
    CloseIcon,
  },
  props: {
    autoWidth: {
      type: Boolean,
      default: false,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="stylus">
@import '../../settings.styl';

.modal {
  position: fixed;
  z-index: 1050;
  padding: 3rem 1rem;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);


  &__content {
    margin-bottom: 100px;
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    background: $background;
    box-shadow: 0 21px 40px 0 rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    min-width: 640px;

    &--wide {
      width: 625px;
    }

    &--auto {
      max-width: 860px;
      width: 100%;
    }
  }

  &__header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .label {
    margin: 11px 0 0 0;
  }

  .inline-label {
    display: flex;
    align-items: center;
    margin: 25px 0;

    .label {
      margin: 0;
      padding: 0;
      font-size: 20px;
      flex: 0 0 170px;
    }

    .text {
      font-size: 20px;
      flex: 1 0;
    }
  }

  .input {
    height: 45px;
    flex: 1 0;
    margin: 5px 0;
    font-size: 18px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid;
    border-color: $border;
  }

  .textarea {
    margin: 5px 0;
    border-radius: 8px;
    font-size: 18px;
    padding: 4px 10px;
    border: 1px solid;
    border-color: $border;
  }

  .error {
    color: #f25750;
    font-size: 18px;
  }

  .star {
    color: #f25750;
  }

  &__footer {
    padding: 20px 0 6px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: center;

  }

  .header {
    font-weight: 600;
    letter-spacing: 0.29px;
    line-height: 41px;

    &__icon {
      cursor: pointer;
      margin-left: auto;
      font-weight: 300;
      color: $accent2;
      font-size: 32px;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

@media (max-width: 959px) and (min-width: 328px) {
  .modal {
    &__content {
      width: 100%;
    }
  }
}

@media (max-width: 328px) and (min-width: 0) {
  .modal {
    &__content {
      width: 100%;
    }
  }
}
</style>
