<template lang="pug">
.roles
  h2 {{ $t("roles") }}
  el-table(:data="tableData")
    el-table-column(prop="role", :label="$t('role')")
    el-table-column(prop="account", :label="$t('account')")
    el-table-column(prop="expiration", :label="$t('expiresOn')")
      template(#default="scope")
        span {{ scope.row.expiration ? formatDate(scope.row.expiration) : $t("never") }}
</template>

<script>
import { mapGetters } from "vuex";
import { roleNames } from "@/utils/roles";
import formatDate from "@/utils/formatDate";

export default {
  name: "UserRoles",
  components: {},
  data: function () {
    return {
      formatDate,
    };
  },
  computed: {
    ...mapGetters({
      userData: "getUserData",
      accounts: "getAccounts",
      accountsLoadingState: "getAccountsLoadingState",
      userState: "user/userState",
      userStateLoadingState: "user/userStateLoadingState",
    }),
    rolesList() {
      return this.$store.getters.getUsersRoles;
    },
    tableData() {
      if (this.accountsLoadingState.loaded) {
        return this.userState.accounts.map((item) => ({
          account: item.title,
          role: roleNames[item.role],
        }));
      }
      return [];
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$store.dispatch("user/loadUserState", {
        id: this.userData.id,
        keepCache: true,
      });
      this.$store.dispatch("initAccounts", { keepCache: true });
    },
  },
};
</script>

<style scoped></style>
