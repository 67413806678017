import axios from "axios";
import store from "@/store";
import authService from "./auth";

// TODO: exclude other base urls and registration endpoints, otherwise the token will leak
export const AuthInterceptor = (config) => {
  if (!store.getters.token) {
    return config;
  }
  const token = store.getters.token.token;
  if (token != null) {
    config.headers.Authorization = "Bearer " + token;
  }
  return config;
};

let isRefreshing = false;
let requestQueue = [];

export const AuthErrorInterceptor = (error) => {
  const originalRequest = error.config;
  const isLoginRequest = originalRequest.url.endsWith("auth/token/");
  const isRefreshRequest = originalRequest.url.endsWith("auth/token/refresh/");

  if (isRefreshRequest) {
    store.dispatch("logout");
  }

  if (
    error.response !== undefined &&
    error.response.status !== undefined &&
    error.response.status === 401 &&
    !originalRequest._retry &&
    !isLoginRequest
  ) {
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        requestQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers.Authorization = "Bearer " + token;
          return axios(originalRequest);
        })
        .catch((err) => {
          return err;
        });
    }

    if (store.getters.token === undefined) {
      store.dispatch("logout");
    }

    originalRequest._retry = true;
    isRefreshing = true;

    return new Promise(function (resolve, reject) {
      store
        .dispatch("refreshToken")
        .then((response) => {
          const data = response.data;
          originalRequest.headers.Authorization = "Bearer " + data.token;
          processQueue(null, data.token);
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
          console.log(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  }
  return Promise.reject(error);
};

const processQueue = (error, token) => {
  requestQueue.forEach((prom) => {
    if (error !== null || token === null) {
      prom.reject();
    } else {
      prom.resolve(token);
    }
  });
  requestQueue = [];
};
