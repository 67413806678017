import API from "./config";
import axios from "axios";
import { mapResponse, printError } from "../utils/mapResponse";

/**
 *  @module MyProgram service
 */

export default {
  /**
   * @typedef {Object} Metadata
   * @property {string} duration - duration of the course (ISO)
   * @property {Array<object>} chapters - chapters array
   * @property {string} schema - course schema
   * @property {string} schema - entry path (URL)
   * @property {string} title - course title
   * @property {string} version - coourse version
   */

  /**
   * @typedef {Object} Course
   * @property {boolean} active - is active flag
   * @property {string} cardImage - card image (small version)
   * @property {boolean} content - true if content is uploaded
   * @property {string} created - creation datetime (ISO)
   * @property {boolean} deleted - true if the course is unactive
   * @property {string} description - description
   * @property {number} id - id
   * @property {string} image - image
   * @property {string} language - language code
   * @property {Array<number>} managers - array of manager's ids
   * @property {Metadata} metadata - metadata
   * @property {string} modified - updation datetime (ISO)
   * @property {string} notes - notes
   * @property {(string|null)} override_duration -
   * @property {string} playImage - preview image
   * @property {Array<string>} tags - tags array
   * @property {string} title - title
   */

  /**
   * API call to get program courses
   * @returns {Array<Course>} courses array
   */
  GET() {
    return axios.get(API.userProgramURL).then(mapResponse).catch(printError);
  },
  /**
   * API call to get completed courses
   * @returns {Array<Course>} courses array
   */
  GET_COMPLETED() {
    return axios.get(API.userCompletedCourseURL).then(mapResponse);
  },
  GET_ASSESSMENTS() {
    return axios
      .get(API.userAssessmentsURL)
      .then(mapResponse)
      .catch(printError);
  },

  /**
   * API call to get certificate
   * @param {object} payload - payload object
   * @param {string} payload.lang - language of the ceritficate
   * @param {courseId} payload.courseId - course id
   * @param {userId} payload.userId - user id
   * @returns {Object} file blob
   */
  GET_CERTIFICATE(payload) {
    const { lang, courseId, userId } = payload;
    if (lang) {
      return axios
        .get(`${API.certificateURL}${courseId}/certificate`)
        .then(mapResponse);
    }
    return axios
      .get(`${API.certificateURL}${courseId}/${userId}/`)
      .then(mapResponse);
  },
  /**
   * API call to add a course at program
   * @param {object} payload - payload object
   * @param {number} payload.id - course id
   * @returns {Promise<Response>} Fetch response, status 204 if success
   */
  // PUT(payload) {
  //   const { id } = payload;
  //   return axios.put(`${API.programURL}${id}/programme`, payload).then(mapResponse).catch(printError);
  // },
  /**
   * API call to remove a course from program
   * @param {object} payload - payload object
   * @param {number} payload.id - course id
   * @returns {Promise<Response>} Fetch response, status 204 if success
   */
  // DELETE(payload) {
  //   const { id } = payload;
  //   return axios.delete(`${API.programURL}${id}/programme`).then(mapResponse).catch(printError);
  // },
};
