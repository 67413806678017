export default {
  termsAccept: "Es piekrītu {0}.",
  tos: "Vispārīgie darījumu noteikumi",
  privacyAccept: "Es piekrītu {0}.",
  privacyPolicy: "Datu aizsardzības paziņojums",
  resetPassword: "Atiestatīt paroli",
  usernamePasswordRequired: "Ievadiet lietotāja vārdu un paroli.",
  passwordTooShort: "Parolei jābūt vismaz 8 zīmes garai.",
  wrongCredentials: "Netika atrasts konts ar norādītajiem datiem.",
  accountStructureItem: "Nodaļa",
  addRole: "Pievienot lomu",
  allUserRelatedDeleteText:
    "Ar šo neatsaucami tiek dzēsti visi nozīmīgie lietotāja dati.",
  assignRoleModals: "Piešķirt lomu",
  canBeDownloadedText: "Var lejupielādēt pabeigtā statusā",
  changePassword: "Mainīt paroli",
  changingPasswordFor: "Mainīt lietotāja paroli",
  confirmDeleteText: "Ievadiet lietotāja vārdu apstiprināšanai:",
  confirmDeteleBtnText: "Esmu sapratis, dzēš lietotāju",
  confirmPassword: "Atkārtot paroli",
  createAccount: "Izveidot kontu",
  createUser: "Izveidot lietotāju",
  currentPassword: "Aktuālā parole",
  customId: "Lietotāja definētais ID",
  dataPrivacyHeader: "Datu privātums",
  dataPrivacyText: "Sadaļas teksts (saturs sekos)",
  dataPrivacyTitle: "Sadaļas nosaukums",
  dataRelatedToMe: "Skatīt par mani saglabātos datus",
  dataRelatedToMeTitle: "Saglabātie dati",
  deleteText: "Vai esat pārliecināts, ka vēlaties to dzēst",
  downloadDataRelatedToMe: "Lejupielādēt saglabātos datus",
  fileWithUserInformation: "Fails ar lietotāja informāciju",
  forgotPassword: "Aizmirsi paroli?",
  forgotPasswordText:
    "Ievadiet savu e-pasta adresi, lai saņemtu saiti savas paroles atiestatei.",
  importUsers: "Importēt lietotāju",
  newHere: "Jauns šeit?",
  newPassowrd: "Jaunā parole",
  newPasswordShort: "Jaunā parole pārāk īsa",
  password: "Parole",
  passwordCantBeEmpty: "Parole nedrīkst būt tukša",
  passwordsNotMatch: "Paroles nesakrīt",
  pickUser: "Izvēlieties lietotāju",
  pleaseFillUpAllFields: "Aizpildiet visus laukus",
  pleaseTypeNewPassword: "Ievadiet jauno paroli",
  policyAcceptance: "Piekrišana vadlīnijām",
  policyAcceptanceSave: "Piekrist",
  acceptPolicies: "Sniedziet piekrišanu vadlīnijām",
  profile: "Profils",
  role: "Loma",
  roleExpiresOn: "Lomas termiņš beidzas",
  saveDataAboutMe: "Par mani saglabātie dati",
  signIn: "Pieteikties",
  termsConditionsHeader: "Vispārīgie darījumu noteikumi",
  termsConditionsText: "Sadaļas teksts (saturs sekos)",
  termsConditionsTitle: "Sadaļas nosaukums",
  typeInOne: "Ievadiet vienu visiem",
  username: "Lietotāja vārds",
  firstname: "Vārds",
  lastname: "Uzvārds",
  usernameMail: "Lietotāja vārds vai e-pasts",
  userNotes: "Piezīmes",
  userProfile: "Lietotāja profils",
  userStatus: "Lietotāja statuss",
  wrongCurrentPassword: "Nepareiza aktuālā parole",
  fillAllFields: "Aizpildiet visus vajadzīgos laukus",
  wrongUsernameOrPassword: "Nepareizs lietotāja vārds vai parole",
  minimumLength: "Minimālais garums 8",
  atLeastOneBigAndSmall: "Vismaz 1 mazais un 1 lielais burts",
  passwordNotEqualUsernameAndEmail:
    "Parole nav idntiska lietotāja vārdam un e-pasta adresei",
  registrationKey: "Reģistrācijas kods",
  registration: "Reģistrācija",
  notAnEmailAdress: "Ievadiet derīgu e-pasta adresi.",
  company: "Firma",
  companyHint: "Firma",
  birthday: "Dzimšanas diena",
  birthdayHint: "Dzimšanas diena",

  accountName: "Konta nosaukums",
  accountType: "Konta veids",
  accountAddress: "Konta īpašnieka adrese",
  logo: "Logotips",
  address: "Adrese",
  contry: "Valsts",
  zipCode: "Pasta indekss",
  lessThan: "Mazāk nekā 1mb",
  defaultLanguage: "Noklusētā valoda",
  accountStatus: "Konta statuss",
  accountNotes: "Konta piezīmes",
  createAccount: "Izveidot kontu",
  updateAccount: "Atjaunināt kontu",
  fileToImport: "Konta piezīmes",
  importStructure: "Importēt struktūru",
  addRootAccount: "Pievienot bāzes līmeņa kontu",
  usersPerMonth: "Mēneša lietotāji",
  usersAdded: "Pievienotie lietotāji",
  usersDeleted: "Dzēstie lietotāji",
  usersTotal: "Lietotāji kopā",
  city: "Pilsēta",

  knowledge: "ZINĀŠANAS",
  toolbox: "RĪKLODZIŅŠ",
  lessonCompleted: "Lekcija pabeigta",
  lessonFailed: "Lekcija nav pabeigta",
  repeatLesson: "Lai turpinātu, atkārtojiet lekciju",
  congratulations: "Apsveicam!",
  courseCompleted: "Kurss pabeigts",
  percentCompleted: "Jūs atbildējāt pareizi uz $successRate% jautājumu.",
  downloadCertificate:
    "Savu sertifikātu varat lejupielādēt jebkurā laikā pie «Sertifikātiem» un pieprasīt pa e-pastu.",
  trueFalse: "Pareizi vai nepareizi?",
  seconds: "Sekundes",
  noChoiceText: "Žēl, pārāk vēlu.",
  right: "Pareizi!",
  wrong: "Nav pareizi",
  progress: "$current no $total",

  addCourseContent: "Pievienot kursa saturu",
  addSemicolon: "Pievienojiet nosaukumus ar semikolu",
  addToProgram: "Pievienot programmai",
  allAccounts: "Visi konti",
  certificate: "Sertifikāts",
  certificates: "Sertifikāti",
  completed: "Pabeigts",
  contentIsLoaded: "Saturs ir ielādēts",
  contentManager: "Satura pārvaldnieks",
  courseCompleted: "Kurss pabeigts",
  courseContent: "Kursa saturs",
  courseDocuments: "Dokumenti",
  courseLables: "Kursa etiķetes",
  courseLanguage: "Kursa valoda",
  courseNotes: "Kursa piezīmes",
  courseStructure: "Struktūra",
  courseTags: "Kursa tagi",
  courseTitle: "Kursa nosaukums",
  createCourse: "Izveidot kursu",
  curator: "KURATORS",
  description: "Apraksts",
  details: "Informācija",
  dueDate: "Termiņš beidzas",
  duration: "Ilgums",
  exportStatistics: "Eksportēt statistiku",
  learningStandard: "Mācību standarts",
  licenses: "Licences",
  mandatory: "Obligātais kurss",
  noAssessment: "Pašaik nav vērtējuma analīzei.",
  noCoursesAssigned: "Jūsu kontam nav piešķirtu kursu. Apmeklējiet",
  noCoursesAvailable: "Pašlaik kursi nav pieejami.",
  noDueDate: "Nav beigu termiņa",
  overview: "PĀRSKATS",
  page: "",
  pickAnAccount: "Izvēlies kontu",
  printCertificate: "Printēt sertifikātu",
  publishCourse: "Publicēt kursu",
  publishedToAccount: "Publicēts kontā",
  relatedCourses: "Līdzīgi kursi",
  remove: "Izņemt",
  removeFromProgram: "Izņemt no porgrammas",
  singleAccount: "Atsevišķs konts",
  startCourse: "Sākt",
  successMessageOne: "Apsveicam, Jūs esat ",
  successMessageThree: "Tagad varat lejupielādēt vai izprintēt sertifikātu. ",
  successMessageTwo: " veiksmīgi pabeidzis kursu!",
  tags: "Atslēgvārdi",
  unpublishCourse: "Atcelt publikāciju",
  updateCourseContent: "Augšupielādēt kursa saturu",
  updateCourse: "Aktualizēt kursu",
  youDidntfinishCourse: "Jūs vēl neesat pabeidzis nevienu kursu.",
  courseVisibleFor: "Kurss redzams",
  accountStructureItem: "Nodaļa",
  courseAssigmentOptions: "Piešķires opcijas",
  assignCourse: "Piešķirt kursu",
  pickACourse: "Izvēlieties kursu",

  noDocumentsAvailable: "Nav dokumentu",
  nothingRelated: "Nav nekā līdzīga",
  nothingWritten: "Nekas nav ierakstīts",
  noTagsAvailable: "Nav atslēgvārdu",
  alreadyGlobal: "Kurss jau ir publicēts visā pasaulē.",
  questionsanswered: "Jautājumi",
  completedOn: "Pabeigts",
  locale: "lva-LVA",
  quizAuthorizationContent: "Quiz autorizācija",

  courseNotPublished: "Kurss pašlaik nav publicēts",
  courseNotPublishedForAccount: "Kurss pašlaik nav publicēts kontam $account",
  clickToPublishCourse: "Publicēt kursu",
  visible: "Redzams",
  userIsNotContentManager:
    "Jūs neesat satura pārvaldnieks un tāpēc nevarat rediģēt kursa saturu.",
  assignmentOpen: "Atvērts",
  assignmentStarted: "Sākts",
  assignmentCompleted: "Pabeigts",
  dueDatePassed: "Pāsniegts beigu termiņš",

  exitCourse: "Beigt kursu",
  markAsFinished: "Atzīmēt kā rediģētu",

  system: "Sistēma",
  configureLogging: "Konfigurēt protokolēšanu",
  createAccount: "Izveidot kontu",
  changeAccount: "Mainīt kontu",
  deleteAccount: "Dzēst kontu",
  importAccountStructure: "Importēt konta struktūru",
  createUser: "Izveidot lietotāju",
  importUser: "Importēt lietotāju",
  changeUser: "Mainīt lietotāju",
  deleteUser: "Dzēst lietotāju",
  assignUserRole: "Piešķirt lomu",
  resetPassword: "Atiestatīt paroli",

  createCourse: "Izveidot kursu",
  changeCourse: "Mainīt kursu",
  addQuizContent: "Pievienot quiz saturu",
  changeQuizContent: "Mainīt quiz saturu",
  addContent: "Pievienot kursa saturu",
  changeContent: "Mainīt kursa saturu",
  deleteCourse: "Dzēst kursu",
  deleteContent: "Dzēst kursa saturu",
  publishCourse: "Publicēt kursu",
  unpublishCourse: "Nepublicē",
  assignCourse: "Piešķirt kursu",
  unassignCourse: "Atcelt kursa piešķiri",

  systemMessages: "Sistēmas ziņojumi",

  learningManagement: "Mācību pārvaldība",
  courseManagement: "Kursa pārvaldība",
  userManagement: "Lietotāju pārvaldība",
  organizationStructure: "Organizatoriskā struktūra",
  accountManagement: "Kontu pārvaldība",
  system: "Sistēma",

  learningManagementDetail: "Mācību plāni un kursa piešķire",
  courseManagementDetail: "Pārvaldīt kursa saturu",
  userManagementDetail: "Lietotāju pārvaldība un paroļu atiestate",
  organizationStructureDetail: "Definēt organizatorisko struktūru",
  accountManagementDetail: "Pārvaldīt kontus",
  logsDetail: "Sistēmas žurnālfaili un iestatījumi",

  userCreateSuccess: "Lietotājs veiksmīgi izveidots",
  createOtherUser: "Pievienot citu lietotāju",
  backToUserOverview: "Atpakaļ uz lietotāju pārskatu",
  importMoreUsers: "Importēt citus lietotājus",
  currentRoles: "Aktuālās lomas",
  numberOfRoles: "Lomu skaits",
  numberOfUsers: "Lietotājs",
  deleteAccountMessage: "Apstipriniet, ka vēlaties dzēst kontu '$account'.",
  showStatistics: "Skatīt statistiku",
  appendAccount: "Pievienot kontu pie $account.",

  courseAssignments: "Piešķirts",
  courseAssignmentsForCourse: "Kursa piešķires $course",
  mandatoryAssignments: "Obligātās kursa piešķires",
  dueDateAssignments: "Ar termiņu",
  dueDatePassed: "Beidzas termiņš",
  userOrAccount: "Lietotājs vai nodaļa",
  expiryDate: "Beigu datums",
  deletePublication: "Dzēst publikāciju",
  deletePublicationMessage:
    "Vai esat pārliecināts, ka vēlaties dzēst publikāciju kontam $account?",
  orgStructure: "Nodaļa",
  type: "Tips",
  downloadTemplate: "Lejupielāžu veidne",
  published: "Publicēts",
  scorm: "SCORM",
  quiz: "Quiz",
  staticFile: "Pdf",
  editPublication: "Rediģēt publikāciju",
  pleaseDefineCourseContent:
    "please define the course content before publishing it.",

  usernameMissing: "Trūkst lietotāja vārda",
  usernameError: "Lietotāja vārdā var būt tikai burti un cipari",
  emailMissing: "Trūkst e-pasta",
  emailError: "Nederīga e-pasta adrese",
  passwordMissing: "Trūkst paroles",
  passwordError:
    "Parolei jābūt vismaz 8 zīmes garai un jāietver lielais burts un mazais burts.",

  registrationKeyManagement: "Reģistrācijas kods",
  registrationKeyManagementDetail: "Reģistrācijas kodu pārvaldība",
  addRegistrationKey: "Pievienot reģistrācijas kodu",
  registrationLink: "Reģistrācijas saite",
  createKey: "Pievienot",

  userState: "Lietotāja valsts",
  userStateDetail: "Piekļuves valsts atļauja un obligātie kursi.",
  testsPassed: "Pārbaudes nokārtotas",
  access: "Piekļuve",
  downloadReport: "Lejupielādēt ziņojumu",

  success: "Veiksmīgi saglabāts!",
  warning: "Uzmanību!",
  error: "Kļūda!",
  removedFromProgram: "Šis kurss ir izņemts no jūsu programmas",
  addedToProgram: "Šis kurss ir pievienots jūsu programmai",
  courseCreated: "Kurss ir izveidots",
  courseNotCreated: "Kurss nav izveidots",
  fillUpFields: "Aizpildiet visus obligātos laukus",
  cookieNotAvailable:
    "Jūsu pieteikšanās informācija nav pieejama vai ir novecojusi, piesakieties vēlreiz",
  courseNotFinished: "Nav pieejama kursa pabeigšanas informācija",
  courseAborted: "Kurss ir atcelts",
  correctAnswer: "Pareiza atbilde",
  wrongAnswer: "Nepareiza atbilde",
  courseStarted: "Kurss ir sākts",
  testsPassed: "Tests izturēts",
  testFailed: "Tests nav izturēts",
  courseCompleted: "Kurss ir veiksmīgi pabeigts",
  imageTooLarge: "Faila izmēram jābūt mazākam par 2mb",
  wrongFormat: "Nepareizs formāts",
  passwordReset:
    "Saite paroles atiestatei ir nosūtīta uz norādīto e-pasta adresi",
  passwordChanged: "Parole ir nomainīta",
  accountAdded: "Konts ir pievienots",
  accountUpdated: "Konts ir aktualizēts",
  accountDeleted: "Konts ir izdzēsts",
  userImported: "Lietotāji ir importēti",
  userNotImported: "Lietotāji nav importēti",
  loggingConfigSaved: "Pieteikšanās konfigurācija ir saglabāta",
  userDeleted: "Lietotājs ir izdzēsts",
  userAdded: "Lietotājs ir izveidots",
  userUpdated: "Lietotājs ir aktualizēts",
  GDPRLoaded: "GDPR ir ielādēta",
  cookiesDisabled: "Jūs esat deaktivizējis sīkfailus",
  wrongCredentials: "Nepareizi pieteikšanās dati",
  fileUploaded: "Jūsu fails ir augšupielādēts",
  courseContentUpdated: "Kursa saturs ir aktualizēts",
  coursePublished: "Kurss ir publicēts",
  courseUnPublished: "Kursa publikācija ir atcelta.",
  serverUnreachable: "Serveris nav sasniedzams",
  selectAccounts: "Izvēlēties kontu",
  selectAccountToContinue: "Lai turpinātu, izvēlieties kontu",
  accountOverview: "Konta pārskats",
  users: "Lietotājs",
  courses: "Kursi",
  coursesCompleted: "Pabeigtie kursi",
  completedCoursesPerUser: "Pabeigtie kursi katram lietotājam",
  learningHours: "Mācību stundas",
  learningHoursPerUser: "Mācību stundas katram lietotājam",
  courseOverview: "Kursu pārskats",
  completed: "Pabeigts",
  assigned: "Piešķirts",
  courseDetails: "Informācija",
  selectCourse: "Izvēlēties kursu",
  details: "Izvēlēties",
  type: "Tips",
  dueDate: "Līdz",
  mandatory: "Obligātais kurss",
  completionRate: "Pabeigšanas līmenis",
  averageScore: "Testa rezultāts",
  duration: "Ilgums",
  averageDuration: "Vidējais ilgums",
  account: "Konts",
  accountName: "Konta nosaukums",
  accounts: "Konti",
  active: "Aktīvs",
  admin: "Administrators",
  all: "Visi",
  not_found: "Lapa neeksistē",
  assessmentStatistics: "Vērtējumu statistika",
  assignedUsersOnly: "Tikai piešķirtie lietotāji",
  autoIndividual: "Automātiski, individuāli",
  cancel: "Atcelt",
  cardImage: "Kartes skats",
  close: "Aizvērt",
  completed: "Pabeigts",
  confirm: "Apstiprināt",
  country: "Valsts",
  course: "Kursi",
  courseDescription: "Kursa apraksts",
  courseDocuments: "Kursa dokumenti",
  courses: "Bibliotēka",
  courseStructure: "Kursa struktūra",
  dataProtection: "Datu aizsardzība",
  defaultLanguage: "Noklusējuma valoda",
  deleteText: "Vai esat pārliecināts, ka vēlaties to dzēst",
  delete: "Dzēst",
  details: "Informācija",
  dragAndDrop: "Drag & Drop / Klikšķināt, lai pievienotu",
  dueDate: "Termiņš beidzas",
  email: "E-pasts",
  empty: "Tukšs",
  exit: "Iziet",
  expiresOn: "Termiņš beidzas",
  fileImport: "Importējamais fails",
  image: "Attēls",
  inactive: "Neaktīvs",
  language: "Valoda",
  logo: "Logotips",
  mandatory: "Obligātais kurss",
  monthsLabels: [
    "Janvāris",
    "Februāris",
    "Marts",
    "Aprīlis",
    "Maijs",
    "Jūnijs",
    "Jūlijs",
    "Augusts",
    "Septembris",
    "Oktobris",
    "Novembris",
    "Decembris",
  ],
  myAccount: "Mans konts",
  myProfile: "Mans profils",
  myProgram: "Mana programma",
  myRoles: "Manas lomas",
  never: "Nekad",
  no: "Nē",
  none: "Nav",
  notFound: "Nav atrasts",
  phoneNumber: "Tālruņa numurs",
  playImage: "Parādīt attēlu",
  publishingExpiresOn: "Publikācijas termiņš beidzas",
  publishTo: "Publicēt",
  reports: "Pārskats",
  save: "Saglabāt",
  search: "Meklēt...",
  status: "Statuss",
  structureItem: "Nodaļa",
  termsAndConditions: "Darījumu noteikumi",
  unlimited: "Neierobežots",
  update: "Aktualizēt",
  user: "Lietotājs",
  username: "lietotājvārds",
  users: "lietotājiem",
  yes: "Jā",
  zipCode: "Pasta indekss",

  info: "Informācija",
  error: "Kļūda",
  alert: "Trauksme",
  criticalAlert: "Kritiska trauksme",
  navigation: "Navigācija",
  system: "Sistēma",
  note: "Norāde",
  userProfile: "Lietotāja profils",

  userId: "Lietotāja ID",
  customId: "",
  organizationalStructure: "Organizatoriskā struktūra",
  created: "Izveidots",
  new: "Jauns",
  noResults: "Nav rezultātu",
  noData: "Nav datu",
  uploadFile: "Faila augšupielāde",
  id: "ID",
  name: "Vārds",
  append: "Pievienot",
  edit: "Rediģēt",
  format: "Formāts",
  minutes: "Minūtes",
  hours: "Stundas",
  date: "Datums",
  message: "Ziņojums",
  fax: "Fakss",

  genericError:
    "Radusies kļūda, mēģiniet ielādēt lapu vēlreiz. Ja kļūda saglabājas, sazinieties ar sistēmas administratoru.",
  action: "Akcija",

  uploadedCertificates: "Augšupielādētie sertifikāti",

  help: "Palīdzība",
  alreadyRegistered: "Jau ir reģistrēta",
  additionalDetails: "Papildu informācija",
  additionalDetailsSave: "Apstiprināt",
  dragAndDropCertificate:
    "Velciet un nometiet PDF failu šeit vai noklikšķiniet, lai pievienotu",
  coverPhoto: "Cover Photo",
  EditCoverPhoto: "Edit Cover Photo",
  AddCoverPhoto: "Add Cover Photo",
  additionalDocuments: "Additional Documents",
  editAdditionalDocuments: "Edit Additional Documents",
  addAdditionalDocuments: "Add Additional Documents",
  fileName: "File name",
  fileSize: "File size",
  userProfileV2: "User profile V2",
  certification: "Certification",
  issuedBy: "Issued by",
  issuedOn: "Issued on",
  fulfilledInPercent: "Fulfilled in percent",
  systemLogs: "System Logs",
  systemConfigurationDetail: "Configure system settings",
  systemConfiguration: "System configuration",
  courseFormat: "Course formats",
  views: "Views",
  apis: "APIs",
  logs: "Logs",
  viewsDescription: "Visibility of the element in the respective view",
  courseFormatDescription: "Available course formats for defining the course",
  accessCard: "Access card",
  downloadCertification: "Download Certification",
  logout: "Izrakstīties",
  continue: "Turpināt",
  courseFinishedDoYouWantToLogout:
    "Kurss ir pabeigts. Vai vēlaties izrakstīties vai turpināt? Jūs tiksiet automātiski izrakstīts pēc {seconds}.",
  stateless: "Bezvalstnieks",
};
