import dayjs from "dayjs";
import { useI18n } from "vue-i18n";
import { i18n } from "../main";

export function formatDuration(value) {
  if (!value) {
    return null;
  }

  const d = dayjs.duration(value);
  const years = d.years();
  const months = d.months();
  const weeks = d.weeks();
  const days = d.days();
  const hours = d.hours();
  const minutes = d.minutes();
  const seconds = d.seconds();
  let formatedDays = "";
  let formatedMinutes = "";
  let formatedSeconds = "";
  let formatedHours = "";
  let formatedWeeks = "";
  let formatedYears = "";
  let formatedMonths = "";
  let result = "";
  if (years > 0) {
    formatedYears = `${years} ${i18n.global.t("years")}`;
  }
  if (months > 0) {
    formatedMonths = `${months} ${i18n.global.t("months")}`;
  }
  if (weeks > 0) {
    formatedWeeks = `${weeks} ${i18n.global.t("weeks")}`;
  }
  if (days > 0) {
    formatedDays = `${days} ${i18n.global.t("days")}`;
  }
  if (hours > 0) {
    formatedHours = `${hours} ${i18n.global.t("hours")}`;
  }
  if (minutes > 0) {
    formatedMinutes = `${minutes} ${i18n.global.t("minutes")}`;
  }
  if (seconds > 0) {
    formatedSeconds = `${seconds} ${i18n.global.t("seconds")}`;
  }
  result = [
    formatedYears,
    formatedMonths,
    formatedWeeks,
    formatedDays,
    formatedHours,
    formatedMinutes,
    formatedSeconds,
  ]
    .filter((item) => item !== "")
    .join(", ");
  return result.trim();
}

export default function formatDate(date) {
  if (!date) {
    return "n/a";
  }
  const mdate = dayjs(date);
  return mdate.format("DD.MM.YYYY");
}

export function formatDateMonth(date) {
  if (!date) {
    return "n/a";
  }
  const mdate = dayjs(date);
  return mdate.format("MM.YYYY");
}

export function formatDateTime(date) {
  if (!date) {
    return "n/a";
  }
  const mdate = dayjs(date);
  return mdate.format("DD.MM.YYYY HH:mm");
}
