import _ from "lodash";
import {
  stateInitial,
  stateLoading,
  stateLoaded,
  stateError,
} from "./loadingStates";
import testcenterService from "@/services/testcenter";

export default {
  state: {
    testTypes: { ...stateInitial, result: [] },
    tests: { ...stateInitial, result: [] },
    testsPage: {},
    testsQuery: null,
    translations: {
      loadingState: stateInitial,
      translations: [],
      // patches are of type {id, type, key, lang, text}
      patches: [],
      defaultLanguage: null,
    },
  },
  mutations: {
    testTypesLoading(state) {
      state.testTypes = { ...stateLoading, result: state.testTypes.result };
    },
    testTypesLoaded(state, result) {
      state.testTypes = { ...stateLoaded, result };
    },
    testTypeAdded(state, result) {
      state.testTypes.result.push(result);
    },
    testTypeRemoved(state, testTypeId) {
      state.testTypes.result = state.testTypes.result.filter(
        (testType) => testType.id !== testTypeId,
      );
    },
    testTypeEdited(state, result) {
      state.testTypes.result = state.testTypes.result.map((testType) => {
        if (testType.id === result.id) {
          return result;
        }
        return testType;
      });
    },
    testTypesError(state, error) {
      state.testTypes = { ...stateError, result: state.testTypes.result };
    },
    testsLoading(state) {
      state.tests = { ...stateLoading, result: state.tests.result };
    },
    testsLoaded(state, { result, query, page }) {
      state.tests = { ...stateLoaded, result };
      state.testsPage = page;
      state.testsQuery = query;
    },
    testsError(state, { result, query }) {
      state.tests = { ...stateError, result };
      state.testsQuery = query;
    },
    testsUpdated(state, { result }) {
      state.tests.result = state.tests.result.map((test) => {
        if (test.id === result.id) {
          return result;
        }
        return test;
      });
    },
    loadTestTranslations(state) {
      state.translations.loadingState = stateLoading;
    },
    testTranslationsLoaded(state, response) {
      state.translations.translations = response.result;
      state.translations.defaultLanguage = response.defaultLanguage;
      state.translations.loadingState = stateLoaded;
    },
    testTranslationsSaved(state, updatedTranslations) {
      state.translations.translations = updatedTranslations;
      state.translations.patches = [];
      state.translations.loadingState = stateLoaded;
    },
    testTranslationsError(state) {
      state.translations.loadingState = stateError;
    },
    // NOTE: patch here is expected to be {id, type, key, lang, text},
    addTestTranslationPatch(state, patch) {
      const existingPatchIndex = state.translations.patches.findIndex(
        (item) =>
          item.type === patch.type &&
          item.id === patch.id &&
          (item.key === patch.key) & (item.lang === patch.lang),
      );
      if (existingPatchIndex > -1) {
        state.translations.patches.splice(existingPatchIndex, 1, patch);
      } else {
        state.translations.patches.push(patch);
      }
    },
    // directly patch the backend translation state
    patchTestTranslation(state, { stepId, patch }) {
      // NOTE: direct patches to translation only happen for file uploads,
      // i.e. we can safely assume here that the key for the patch is 'url'
      const stepItemIndex = state.translations.translations.findIndex(
        (item) => item.id === stepId,
      );
      if (stepItemIndex === -1) {
        return;
      }
      const translationItemIndex = state.translations.translations[
        stepItemIndex
      ].translations.findIndex((item) => item.key === patch.key);
      const translationItem =
        state.translations.translations[stepItemIndex].translations[
          translationItemIndex
        ];
      state.translations.translations[stepItemIndex].translations,
        translationItemIndex,
        _.merge({}, translationItem, patch);
    },
  },
  actions: {
    async getTestTypes({ commit, state }, settings) {
      if (settings && settings.keepCache && state.testTypes.loaded) {
        return;
      }
      commit("testTypesLoading");
      try {
        const response = await testcenterService.getTestTypes();
        commit("testTypesLoaded", response.data.result);
        return response;
      } catch (error) {
        commit("testTypesError", error);
        throw error;
      }
    },
    async addTestType({ commit }, testType) {
      try {
        const response = await testcenterService.postTestType(testType);
        commit("testTypeAdded", response.data);
      } catch (error) {
        commit("testTypesError", error);
        throw error;
      }
    },
    async editTestType({ commit }, testType) {
      try {
        const response = await testcenterService.patchTestType(testType);
        commit("testTypeEdited", response.data);
      } catch (error) {
        commit("testTypesError", error);
        throw error;
      }
    },
    async removeTestType({ commit }, testTypeId) {
      try {
        const response = await testcenterService.deleteTestType(testTypeId);
        commit("testTypeRemoved", testTypeId);
      } catch (error) {
        commit("testTypesError", error);
        throw error;
      }
    },
    async getTests({ commit }, { query }) {
      commit("testsLoading");
      try {
        const response = (await testcenterService.getTests(query)).data;
        commit("testsLoaded", {
          result: response.result,
          query,
          page: { total: response.total, pagesTotal: response.pagesTotal },
        });
        return response;
      } catch (error) {
        commit("testsError", error);
        throw error;
      }
    },
    async createTest({ commit, dispatch, state }, test) {
      commit("testsLoading");
      try {
        const result = await testcenterService.postTest(test);
        await dispatch("getTests", { query: state.testsQuery });
        return result;
      } catch (error) {
        commit("testsError", error);
        throw error;
      }
    },
    async editTest({ commit }, test) {
      commit("testsLoading");
      try {
        const result = await testcenterService.patchTest(test);
        commit("testUpdated", result);
      } catch (error) {
        commit("testsError", error);
        throw error;
      }
    },
    async removeTest({ commit, dispatch, state }, testId) {
      commit("testsLoading");
      try {
        const result = await testcenterService.deleteTest(testId);
        dispatch("getTests", { query: state.testsQuery });
      } catch (error) {
        commit("testsError", error);
        throw error;
      }
    },
    async addTestResult({ commit, dispatch, state }, result) {
      commit("testsLoading");
      try {
        await testcenterService.putTestResult(result);
        // Reload tests to reflect the change
        dispatch("getTests", { query: state.testsQuery });
      } catch (error) {
        commit("testsError", error);
        throw error;
      }
    },
    async loadTestTranslations(context) {
      context.commit("loadTestTranslations");
      const response = await testcenterService
        .getTranslations()
        .catch((error) => {
          context.commit("testTranslationsError");
          throw error;
        });
      context.commit("testTranslationsLoaded", response.data);
    },
    async saveTestTranslations(context, courseId) {
      context.commit("loadTestTranslations");
      const patches = context.state.translations.patches.reduce((agg, curr) => {
        const item = agg.find(
          (item) => item.type === curr.type && item.id === curr.id,
        );
        if (item) {
          const existingTranslation = item.translations.find(
            (item) => item.key === curr.key,
          );
          if (existingTranslation) {
            existingTranslation.values[curr.lang] = curr.text;
          } else {
            const values = {};
            values[curr.lang] = curr.text;
            item.translations.push({ key: curr.key, values });
          }
        } else {
          const values = {};
          values[curr.lang] = curr.text;
          agg.push({
            type: curr.type,
            id: curr.id,
            translations: [
              {
                key: curr.key,
                values,
              },
            ],
          });
        }
        return agg;
      }, []);
      await testCenterService.patchTranslations(patches).catch((error) => {
        context.commit("addNewNotification", {
          text: "genericError",
          title: "error",
          notificationType: "error",
        });
        context.commit("testTranslationsError");
      });
      context.commit(
        "testTranslationsSaved",
        context.getters.mergedTestTranslations,
      );
    },
  },
  getters: {
    testTypes: (state) => state.testTypes.result,
    activeTestTypes: (state) =>
      state.testTypes.result.filter((type) => type.active),
    testTypesLoadingState: (state) => state.testTypes,
    tests: (state) => state.tests.result,
    testsPage: (state) => state.testsPage,
    testsLoadingState: (state) => state.tests,
    testTranslationsLoadingState: (state) => state.translations.loadingState,
    testTranslations: (state) => state.translations.translations,
    testTranslationsDefaultLang: (state) => state.translations.defaultLanguage,
    mergedTestTranslations: (state) => {
      return state.translations.translations.map((item) => ({
        ...item,
        translations: item.translations.map((translation) => {
          const copy = { ...translation };
          state.translations.patches
            .filter(
              (patch) =>
                item.type === patch.type &&
                item.id === patch.id &&
                copy.key === patch.key,
            )
            .forEach((patch) => (copy.values[patch.lang] = patch.text));
          return copy;
        }),
      }));
    },
    testTranslationHasChanges: (state) => state.translations.patches.length > 0,
  },
};
