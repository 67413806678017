<template lang="pug">
.video-step
  .text-container.ql-editor(v-html="data.text")
  .flex-center.flex-col(v-loading="data.loadingState.loading")
    video.mb-3(ref="video", controls="true", preload="metadata") Your browser does not support HTML5 video.
      source(:src="data.url")
    ConfirmationButton(
      v-if="data.requiresInteraction",
      :completed="data.completed",
      @confirmed="sendConfirmation"
    )
</template>

<script>
import stepMixin from "./stepMixin";
import ConfirmationButton from "./components/ConfirmButton.vue";

export default {
  components: {
    ConfirmationButton,
  },
  mixins: [stepMixin],
  mounted() {},
  methods: {
    sendConfirmation() {
      this.sendAnswer({ confirmation: true });
    },
  },
};
</script>

<style scoped lang="stylus">
@import '../../../settings.styl';

.text-container {
  margin-bottom: 2rem;
}

video {
  max-width: 100%;
  max-height: 50vh;
}
</style>
