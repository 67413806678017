<template lang="pug">
.checkbox-wrapper
  .checkbox-input.mr-1(
    :class="{ active: val, disabled: disabled }",
    @click="toggleChecker"
  )
    .mark
  slot
</template>
<script>
export default {
  name: "CheckboxInput",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // NOTE: the checkbox is unfortunately made to represent its internal state, which might deviate from the value passed by :value
    // For backwards compatibility, it is optional to represent the external state.
    showExternalState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      val: this.modelValue,
    };
  },
  watch: {
    val(value) {
      // NOTE: input should only be emitted when it is being changed, though this might be used somew
      if (!this.showExternalState) {
        this.$emit("update:modelValue", value);
      }
    },
    modelValue(value) {
      this.val = value;
      if (!this.showExternalState) {
        this.$emit("update:modelValue", value);
      }
    },
  },
  methods: {
    toggleChecker() {
      if (this.showExternalState) {
        this.$emit("update:modelValue", !this.val);
        return;
      }
      if (this.disabled) {
        return;
      }
      this.val = !this.val;
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '../../settings.styl';

.checkbox-wrapper {
  display: flex;
  align-items: center;
}

.checkbox-input {
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
  border: 2px solid $buttonInactive;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $background;

  &.disabled {
    cursor: not-allowed;
    background-color: $buttonInactive;
  }

  &.active {
    border-color: $accent;

    .mark {
      background: $accent;
    }
  }

  .mark {
    width: 15px;
    height: 15px;
    border-radius: 3px;
  }
}
</style>
