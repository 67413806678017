<template lang="pug">
div
  EditPreview.mb-2(:text="text", :edit-on-start="false", @editText="editText")
  .pdf-upload-container(v-if="!url", v-loading="uploadLoading")
    .pdf-placeholder.mb-3 {{ $t("uploadPdf") }}
    input.file-upload(type="file", @change="uploadFile($event.target.files)")
  .pdf-container(v-else, v-loading="uploadLoading")
    object.pdf-content.mb-1(ref="pdfObj", :data="url", type="application/pdf") 
      .not-supported-view(ref="fallback")
        el-icon
          Document-delete.txt-larger.mb-1
        p {{ $t("browser_not_support_pdf") }}
    a(:href="url")
      el-button.medium-width.mb-2(
        target="_blank",
        type="primary",
        icon="Download"
      ) {{ $t("download_file") }}
</template>

<script>
import editStepMixin from "./mixins/editStepMixin";
import EditPreview from "./components/EditPreview.vue";

export default {
  components: {
    EditPreview,
  },
  mixins: [editStepMixin],
  data: function () {
    return {
      uploadLoading: false,
      isEditingText: false,
    };
  },
  computed: {
    text() {
      return this.step.text;
    },
    url() {
      return this.step.url;
    },
  },
  methods: {
    editText(text) {
      this.patchStep({ text });
    },
    uploadFile(files) {
      this.uploadLoading = true;
      this.$store.dispatch("uploadPdf", { file: files[0] }).then((_) => {
        this.uploadLoading = false;
      });
    },
    startEditText() {
      this.isEditingText = true;
    },
    stopEditText() {
      this.isEditingText = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.pdf-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 200px;
  border: 1px solid black;
  margin: auto;
}

.pdf-upload-container {
  position: relative;
  width: 400px;
  height: 200px;
  margin: auto;
}

.pdf-container {
  width: 400px;
  height: 200px;
  margin: auto;
}

.pdf-upload-container > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  width: 400px;
  height: 200px;
}

.pdf-upload-container input {
  opacity: 0;
}
</style>
