export default {
  state: {
    notificationsList: [],
    lastId: 0,
  },
  mutations: {
    /**
     * Delete a notification by id
     * @param {object} state - Vuex state
     * @param {number} id
     * @returns {boolean} Returns true if the notification was deleted
     */
    deleteNotification(state, id) {
      if (id) {
        state.notificationsList = state.notificationsList.filter(
          (item) => item.id !== +id,
        );
        return true;
      }
      return false;
    },
    /**
     * Add new notification to common stack of the notifications
     * @param {object} state - Vuex state
     * @param {object} payload - An object with norification information
     * @param {string} payload.title - The notification title  -- the key to i18n text
     * @param {string} payload.text - The notification text -- the key to i18n text
     * @param {string} payload.textAsIs - if true the text should not be translated
     * @param {string} payload.notificationType - The notification type. One
     * of ['warning', 'success', 'error', default]
     * @param {number} payload.timeout - The notification hiding delay (ms)
     */
    addNewNotification(state, payload) {
      const result = payload || {};
      state.lastId += 1;
      const id = state.lastId;
      state.notificationsList.push({
        id,
        title: result.title || "",
        text: result.text || "",
        textAsIs: result.textAsIs,
        timeout: result.timeout || 3500,
        isHiding: result.isHiding,
        notificationType: result.notificationType || "",
      });
    },
  },
  actions: {},
  getters: {
    getNotifications: (state) => state.notificationsList,
  },
};
