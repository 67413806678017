import {
  stateError,
  stateInitial,
  stateLoaded,
  stateLoading,
} from "./loadingStates";
import tagService from "@/services/tags";

export default {
  state: {
    tags: { ...stateInitial, result: [] },
    purposes: { ...stateInitial, result: [] },
    tagTypes: { ...stateInitial, result: [] },
    userTags: {
      ...stateInitial,
      result: [],
    },
    userTagId: null,
  },
  mutations: {
    tagsLoading(state) {
      state.tags = { ...stateLoading, result: state.tags.result };
    },
    tagsLoaded(state, result) {
      if (result) {
        state.tags = { ...stateLoaded, result };
      } else {
        state.tags = { ...stateLoaded, result: state.tags.result };
      }
    },
    tagAdded(state, tag) {
      state.tags.result.push(tag);
    },
    tagEdited(state, tag) {
      const index = state.tags.result.findIndex(
        (item) => item.uuid === tag.uuid,
      );
      if (index > -1) {
        state.tags.result.splice(index, 1, tag);
      }
    },
    tagDeleted(state, id) {
      const index = state.tags.result.findIndex((item) => item.uuid === id);
      if (index > -1) {
        state.tags.result.splice(index, 1);
      }
    },
    tagsError(state) {
      state.tags = { ...stateError, result: state.tags.result };
    },
    userTagsLoading(state, userId) {
      state.userTags = { ...stateLoading, result: state.tags.result };
      state.userId = userId;
    },
    userTagsLoaded(state, result) {
      state.userTags = { ...stateLoaded, result };
    },
    userTagsError(state) {
      state.userTags = stateError;
    },
    tagTypesLoading(state) {
      state.tagTypes = { ...stateLoading, result: state.tagTypes.result };
    },
    tagTypesLoaded(state, result) {
      if (result) {
        state.tagTypes = { ...stateLoaded, result };
      } else {
        state.tagTypes = { ...stateLoaded, result: state.tagTypes.result };
      }
    },
    tagTypesError(state) {
      state.tagTypes = { ...stateError, result: state.tagTypes.result };
    },
    addTagType(state, tag) {
      state.tagTypes.result.push(tag);
    },
    tagTypeEdited(state, tag) {
      const index = state.tagTypes.result.findIndex(
        (item) => item.uuid === tag.uuid,
      );
      if (index > -1) {
        state.tagTypes.result.splice(index, 1, tag);
      }
    },
    tagTypeDeleted(state, id) {
      const index = state.tagTypes.result.findIndex((item) => item.uuid === id);
      if (index > -1) {
        state.tagTypes.result.splice(index, 1);
      }
    },
    purposesLoading(state) {
      state.purposes = { ...stateLoading, result: state.purposes.result };
    },
    purposesLoaded(state, result) {
      state.purposes = { ...stateLoaded, result };
    },
    purposesError(state) {
      state.purposes = { ...stateError, result: state.purposes.result };
    },
  },
  actions: {
    loadTags(context) {
      context.commit("tagsLoading");
      tagService.getAvailableTags().then(
        (result) => {
          context.commit("tagsLoaded", result);
        },
        (error) => {
          context.commit("tagsError");
        },
      );
    },
    addTagValue(context, tag) {
      context.commit("tagsLoading");
      tagService.addTagTypeValue(tag.tagType, tag).then(
        (result) => {
          context.commit("tagAdded", result);
          context.commit("tagsLoaded");
        },
        (error) => {
          context.commit("tagsError");
        },
      );
    },
    editTagValue(context, tag) {
      context.commit("tagsLoading");
      return tagService.putTagTypeValue(tag.tagType, tag).then(
        (result) => {
          context.commit("tagEdited", result);
          context.commit("tagsLoaded");
        },
        (error) => {
          context.commit("tagsError");
          throw error;
        },
      );
    },
    deleteTagValue(context, tag) {
      context.commit("tagsLoading");
      tagService.deleteTagTypeValue(tag.tagType, tag.uuid).then(
        (result) => {
          context.commit("tagDeleted", tag.uuid);
          context.commit("tagsLoaded");
        },
        (error) => {
          context.commit("tagsError");
        },
      );
    },
    addTagType(context, tag) {
      context.commit("tagTypesLoading");
      return tagService.addTagType(tag).then(
        (result) => {
          context.commit("addTagType", result);
          context.commit("tagTypesLoaded");
        },
        (error) => {
          context.commit("tagTypesError");
        },
      );
    },
    editTagType(context, tag) {
      context.commit("tagTypesLoading");
      return tagService.patchTagType(tag).then(
        (result) => {
          context.commit("tagTypeEdited", result);
          context.commit("tagTypesLoaded");
        },
        (error) => {
          context.commit("tagTypesError");
        },
      );
    },
    deleteTagType(context, tag) {
      context.commit("tagTypesLoading");
      return tagService.deleteTagType(tag.uuid).then(
        (result) => {
          context.commit("tagTypeDeleted", tag.uuid);
          context.commit("tagTypesLoaded");
          // Deletion of a tag type might result in tag values being removed as well
          context.dispatch("loadTags");
        },
        (error) => {
          context.commit("tagTypesError");
        },
      );
    },
    loadTagTypes(context) {
      context.commit("tagTypesLoading");
      tagService.loadTagTypes().then(
        (result) => {
          context.commit("tagTypesLoaded", result);
        },
        (error) => {
          context.commit("tagTypesError");
        },
      );
    },
    loadTagsForUser(context, { userId }) {
      context.commit("userTagsLoading");
      tagService.getTagsForUser(userId).then(
        (result) => {
          context.commit("userTagsLoaded", result);
        },
        (error) => {
          context.commit("userTagsError");
        },
      );
    },
    loadPurposes(context) {
      context.commit("purposesLoading");
      tagService.getPurposes().then(
        (response) => {
          context.commit("purposesLoaded", response.result);
        },
        (error) => {
          context.commit("purposesError");
        },
      );
    },
  },
  getters: {
    tagsLoadingState: (state) => state.tags,
    userTagsLoadingState: (state) => state.userTags,
    tagTypesState: (state) => state.tagTypes,
    purposesState: (state) => state.purposes,
    userTagsWithTagTypeNames: (state) => {
      const result = state.userTags.result.map((tag) => {
        const tagType = state.tagTypes.result.find(
          (t) => t.uuid === tag.tagType,
        );
        return { ...tag, tagTypeName: tagType ? tagType.name : "" };
      });
      return result;
    },
  },
};
