<template lang="pug">
.header-container
  header.app-header
    .app-header__content
      header-logo.clickable(@click="redirect")
      .current-route.router-link-active {{ currentRouteName }}
      template(v-if="isAuthenticated")
        i.material-icons.navbar-menu-icon.clickable.mr-1(@click="toggleMenu") menu
        .navbar-wrapper(:class="{ 'navbar-wrapper--hided': !isShowed }")
          navbar
          .user-menu__title(@click="toggleMenu")
            avatar(:username="username", :size="32")
            .user-menu__icon.user-menu__icon--drop
              svg(
                width="8px",
                height="5px",
                viewbox="0 0 8 5",
                version="1.1",
                xmlns="http://www.w3.org/2000/svg",
                xmlns:xlink="http://www.w3.org/1999/xlink"
              )
                g#Page-1(
                  stroke="none",
                  stroke-width="1",
                  fill="none",
                  fill-rule="evenodd"
                )
                  g#Kurse---Bibliothek---V2(
                    transform="translate(-801.000000, -21.000000)",
                    fill="#4B4B4B"
                  )
                    path#Triangle(
                      d="M805.390434,21.488043 L808.350122,25.1876525 C808.522627,25.4032834 808.487666,25.7179296 808.272035,25.8904344 C808.183379,25.9613596 808.073223,26 807.959688,26 L802.040312,26 C801.76417,26 801.540312,25.7761424 801.540312,25.5 C801.540312,25.3864643 801.578953,25.276309 801.649878,25.1876525 L804.609566,21.488043 C804.78207,21.272412 805.096717,21.2374514 805.312348,21.4099561 C805.34118,21.433022 805.367369,21.4592107 805.390434,21.488043 Z",
                      transform="translate(805.000000, 23.500000) rotate(-180.000000) translate(-805.000000, -23.500000) "
                    )
          .seperator
          .user-menu__content(
            :class="{ 'user-menu__content--showed': isShowed }"
          )
            user-action-list(@openProfile="showUserMenu")
  user-menu(v-if="isProfileModalVisible", @closeModal="hideUserMenu")
  .overlay(v-if="isShowed")
</template>

<script>
import UserMenu from "@/components/usermenu/UserMenu";
import Navbar from "./Navbar";
import HeaderLogo from "./HeaderLogo";
import UserActionList from "@/components/usermenu/UserActionList";
import Avatar from "@/components/usermenu/Avatar";
import { mapGetters } from "vuex";

export default {
  name: "AppHeader",
  components: {
    Navbar,
    HeaderLogo,
    UserMenu,
    UserActionList,
    Avatar,
  },
  data() {
    return {
      isShowed: false,
      isProfileModalVisible: false,
      // A click listener to close the menu whenever an outside click occurs
      outsideClickListener: (event) => {
        this.isShowed = false;
      },
    };
  },
  watch: {
    $route() {
      this.isShowed = false;
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    currentRouteName() {
      switch (this.$route.name) {
        case "CompletedList":
          return this.$t("completed");
        case "MyCourseList":
          return this.$t("myProgram");
        case "CoursesList":
          return this.$t("courses");
        case "Vaccination":
          return this.$t("covid");
        case "TestCenter":
          return this.$t("tests");
      }

      const route = this.$route.path.split("/");
      if (route.length > 1) {
        switch (this.$route.path.split("/")[1]) {
          case "report":
            return this.$t("reports");
          case "assessmentStatistics":
            return this.$t("assessmentStatistics");
          case "admin":
            return this.$t("admin");
        }
      }
      return "";
    },
    getters() {
      return this.$store.getters;
    },
    isAuthenticated() {
      return this.getters.isAuthenticated;
    },
    userData() {
      return this.$store.getters.getUserData;
    },
    username() {
      return this.userData.username;
    },
  },
  created() {
    document.addEventListener("click", this.outsideClickListener);
  },
  methods: {
    redirect() {
      if (this.$route.fullPath !== "/" && this.isAuthenticated) {
        this.$router.push({ path: "/" });
      }
      return true;
    },
    toggleMenu(event) {
      this.isShowed = !this.isShowed;
      // stop the click propagation, since all clicks propagating to document will close the menu
      event.stopPropagation();
    },
    showUserMenu() {
      this.isProfileModalVisible = true;
    },
    hideUserMenu() {
      this.isProfileModalVisible = false;
    },
  },
};
</script>
<style lang="stylus">
@import '../../settings.styl';

.header-container {
  height: $headerHeight;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0.5;
  z-index: 1000;
}

.app-header {
  height: $headerHeight;
  display: flex;
  z-index: 1020;
  width: 100%;
  position: fixed;
  justify-content: center;
  top: 0;
  align-items: center;
  border-bottom: 1px solid $border;

  .user-menu {
    margin-left: auto;
    width: 120px;
    display: flex;
    justify-content: flex-end;

    &__title {
      width: 120px;
      display:flex;
      justify-content: end;
    }

  }

  &__title {
    flex: 1 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    text-transform: uppercase;
    margin-right: 170px;
  }

  &__content {
    flex: 1 0;
    max-width: $content-width;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;
  }

  .router-link-active {
    color: $accent;
  }

  .navbar-wrapper {
    position: relative;
    flex: 1 0;
    align-items: center;
    display: flex;
  }

  .navbar-menu-icon {
    display: none;
    margin-left: auto;
  }
}

.current-route {
  display: none;
  }

@media (max-width: 1040px) and (min-width: 0px) {
  .seperator {
    width: 100%;
    border-top: 1px solid $border;
  }

  .app-header {
    .navbar {
      width: 200px;
    }

  .current-route {
    display: flex;
    justify-content: center;
    width: 100%;

  }


    .user-menu {
      margin: 0;
      width: 100%;
      border-top: 1px solid;
      border-color: $border;
      display: flex;
      justify-content: center;
      padding-bottom: 0px;

      &__title {
        display: none;
      }

      &__content {
        width: 200px;
        display: block;
        position: relative;
        right: 0;
        top: 0%;
        border: none;

        .user-action-list {
          &__item {
            // text-transform: uppercase;
            cursor: pointer;
            padding: 0px;
            margin: 12px 0;
            font-size: 14px;
          }
        }
      }
    }


    .navbar-wrapper {
      // display: none;
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      flex-flow: column nowrap;
      background: $background;

      &--hided {
        display: none;
      }

      .navbar {
        flex-flow: column nowrap;
        margin: 0;

        &__item {
          margin: 10px 0;
        }
      }
    }

    .navbar-menu-icon {
      display: block;
    }
  }
}
</style>
