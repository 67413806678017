export default {
  termsAccept: "Sunt de acord cu {0}.",
  tos: "CGA",
  privacyAccept: "Sunt de acord cu {0}.",
  privacyPolicy: "Declarație privind protecția datelor",
  resetPassword: "Resetare parolă",
  usernamePasswordRequired:
    "Vă rugăm să introduceți un nume de utilizator și o parolă.",
  passwordTooShort: "Parola trebuie să aibă cel puțin 8 caractere.",
  wrongCredentials: "Nu a fost găsit niciun cont cu datele specificate.",
  accountStructureItem: "Departament",
  addRole: "Adăugare rol",
  allUserRelatedDeleteText:
    "Prin aceasta sunt șterse irevocabil toate datele relevante despre utilizator.",
  assignRoleModals: "Alocare rol",
  canBeDownloadedText: "se pot descărca de la finalizat",
  changePassword: "Modificare parolă",
  changingPasswordFor: "Modificare parolă pentru utilizator",
  confirmDeleteText:
    "Vă rugăm să atingeți numele de utilizator pentru confirmare:",
  confirmDeteleBtnText: "Am înțeles, șterge utilizatorul",
  confirmPassword: "Repetare parolă",
  createAccount: "Creare cont",
  createUser: "Creare utilizator",
  currentPassword: "Parolă actuală",
  customId: "ID definit de utilizator",
  dataPrivacyHeader: "Confidențialitatea datelor",
  dataPrivacyText: "Textul secțiunii (conținutul va urma)",
  dataPrivacyTitle: "Titlul secțiunii",
  dataRelatedToMe: "Vizualizare date stocate despre mine",
  dataRelatedToMeTitle: "Date stocate",
  deleteText: "Sunteți sigur că doriți să ștergeți aceasta",
  downloadDataRelatedToMe: "Descărcare date stocate",
  fileWithUserInformation: "Fișier cu informații utilizator",
  forgotPassword: "Parolă uitată?",
  forgotPasswordText:
    "Vă rugăm să introduceți adresa dumneavoastră de e-mail, pentru a obține linkul de resetare a parolei dumneavoastră.",
  importUsers: "Importare utilizator",
  newHere: "Nou aici?",
  newPassowrd: "Parolă nouă",
  newPasswordShort: "Parolă nouă prea scurtă",
  password: "Parolă",
  passwordCantBeEmpty: "Parola nu poate fi goală",
  passwordsNotMatch: "Parolele nu corespund",
  pickUser: "Selectați un utilizator",
  pleaseFillUpAllFields: "Vă rugăm să completați toate câmpurile",
  pleaseTypeNewPassword: "Vă rugăm să introduceți noua dumneavoastră parolă",
  policyAcceptance: "Adoptare directive",
  policyAcceptanceSave: "Acceptare",
  acceptPolicies: "Vă rugăm să acceptați directivele",
  profile: "Profil",
  role: "Rol",
  roleExpiresOn: "Rolul expiră pe",
  saveDataAboutMe: "date stocate despre mine",
  signIn: "Înregistrare",
  termsConditionsHeader: "Condiții generale de afaceri",
  termsConditionsText: "Textul secțiunii (conținutul va urma)",
  termsConditionsTitle: "Titlul secțiunii",
  typeInOne: "Introduceți unul pentru toate",
  username: "Nume utilizator",
  firstname: "Prenume",
  lastname: "Nume de familie",
  usernameMail: "Nume utilizator sau e-mail",
  userNotes: "Notițe",
  userProfile: "Profil utilizator",
  userStatus: "Stare utilizator",
  wrongCurrentPassword: "Parolă actuală greșită",
  fillAllFields: "Vă rugăm să completați toate câmpurile necesare",
  wrongUsernameOrPassword: "Nume utilizator sau parolă greșită",
  minimumLength: "lungime minimă 8",
  atLeastOneBigAndSmall: "minimum 1 literă mică și 1 majusculă",
  passwordNotEqualUsernameAndEmail:
    "parola nu este identică cu numele utilizatorului și adresa de e-mail",
  registrationKey: "Cheie înregistrare",
  registration: "Înregistrare",
  notAnEmailAdress: "Vă rugăm să introduceți o adresă de e-mail valabilă.",
  company: "Societate",
  companyHint: "Societate",
  birthday: "Zi de nastere",
  birthdayHint: "Zi de nastere",
  accountName: "Nume cont",
  accountType: "Tip de cont",
  accountAddress: "Adresa deținătorului de cont",
  logo: "Logo",
  address: "Adresă",
  contry: "Țară",
  zipCode: "Cod poștal",
  lessThan: "mai puțin de 1mb",
  defaultLanguage: "Limba implicita",
  accountStatus: "Starea contului",
  accountNotes: "Note cont",
  createAccount: "Creare cont",
  updateAccount: "Actualizare cont",
  fileToImport: "Note cont",
  importStructure: "Importare structură",
  addRootAccount: "Adăugare cont nivel de bază",
  usersPerMonth: "Utilizator lunar",
  usersAdded: "Utilizator adăugat",
  usersDeleted: "Utilizator șters",
  usersTotal: "Total utilizatori",
  city: "Oraș",

  knowledge: "CUNOȘTINȚE",
  toolbox: "SET DE INSTRUMENTE",
  lessonCompleted: "Lecție finalizată",
  lessonFailed: "Lecție nefinalizată",
  repeatLesson: "Vă rugăm să repetați lecția pentru a continua",
  congratulations: "Sincere felicitări!",
  courseCompleted: "Curs finalizat",
  percentCompleted: "Ați răspuns corect la $successRate% din întrebări.",
  downloadCertificate:
    "Puteți descărca oricând certificatul dumneavoastră de la „Certificate„ din profil și îl puteți solicita prin e-mail.",
  trueFalse: "Corect sau greșit?",
  seconds: "Secunde",
  noChoiceText: "Păcat, prea târziu.",
  right: "Corect!",
  wrong: "Nu este corect",
  progress: "$current din $total",

  addCourseContent: "Adăugare conținuturi curs",
  addSemicolon: "Adăugați etichete separate prin punct și virgulă",
  addToProgram: "Adăugare la program",
  allAccounts: "Toate conturile",
  certificate: "Certificat",
  certificates: "Certificate",
  completed: "Finalizat",
  contentIsLoaded: "Conținutul este încărcat",
  contentManager: "Manager conținut",
  courseCompleted: "Curs finalizat",
  courseContent: "Conținut curs",
  courseDocuments: "Documente",
  courseLables: "Etichete-curs",
  courseLanguage: "Limbă curs",
  courseNotes: "Notițe curs",
  courseStructure: "Structură",
  courseTags: "Taguri curs",
  courseTitle: "Nume curs",
  createCourse: "Creare curs",
  curator: "CURATOR",
  description: "Descriere",
  details: "Detalii",
  dueDate: "Scadent până la",
  duration: "Durată",
  exportStatistics: "Exportare statistici",
  learningStandard: "Standard învățare",
  licenses: "Licențe",
  mandatory: "Curs obligatoriu",
  noAssessment: "Momentan nu există nicio evaluare de examinat.",
  noCoursesAssigned:
    "Contului dumneavoastră nu îi sunt alocate cursuri. Vă rugăm să vizitați",
  noCoursesAvailable: "Momentan nu sunt cursuri disponibile.",
  noDueDate: "Nicio dată scadentă",
  overview: "VEDERE DE ANSAMBLU",
  page: "",
  pickAnAccount: "Alege un cont",
  printCertificate: "Tipărire certificat",
  publishCourse: "Publicare curs",
  publishedToAccount: "Publicat în cont",
  relatedCourses: "Cursuri similare",
  remove: "Eliminare",
  removeFromProgram: "Eliminare din program",
  singleAccount: "Cont individual",
  startCourse: "Pornire",
  successMessageOne: "Felicitări, cursul a fost ",
  successMessageThree:
    "Acum puteți descărca sau tipări certificatul dumneavoastră de absolvire. ",
  successMessageTwo: " absolvit cu succes!",
  tags: "Cuvinte cheie",
  unpublishCourse: "Anulare publicare",
  updateCourseContent: "Încărcare conținuturi curs",
  updateCourse: "Actualizare curs",
  youDidntfinishCourse: "Nu ai finalizat încă niciun curs.",
  courseVisibleFor: "Curs vizibil pentru",
  accountStructureItem: "Departament",
  courseAssigmentOptions: "Opțiuni de alocare",
  assignCourse: "Alocare curs",
  pickACourse: "Selectează un curs",

  noDocumentsAvailable: "niciun document disponibil",
  nothingRelated: "nimic similar disponibil",
  nothingWritten: "nimic înregistrat",
  noTagsAvailable: "niciun cuvânt cheie disponibil",
  alreadyGlobal: "Cursul a fost deja publicat în toată lumea.",
  questionsanswered: "Întrebări",
  completedOn: "Finalizat pe",
  locale: "ro-RO",
  quizAuthorizationContent: "Autorizare Quiz",

  courseNotPublished: "Cursul nu este publicat momentan",
  courseNotPublishedForAccount:
    "Cursul nu este publicat momentan pentru contul $account",
  clickToPublishCourse: "Publicare curs",
  visible: "Vizibil",
  userIsNotContentManager:
    "Dumneavoastră nu sunteți manager de conținut și de aceea nu puteți prelucra conținutul cursului.",
  assignmentOpen: "Deschis",
  assignmentStarted: "Pornit",
  assignmentCompleted: "Finalizat",
  dueDatePassed: "Data scadentă depășită",

  exitCourse: "Încheiere curs",
  markAsFinished: "Marcare ca prelucrat",

  system: "Sistem",
  configureLogging: "Configurare rapoarte",
  createAccount: "Creare cont",
  changeAccount: "Modificare cont",
  deleteAccount: "Ștergere cont",
  importAccountStructure: "Importare structură cont",
  createUser: "Creare utilizator",
  importUser: "Importare utilizator",
  changeUser: "Modificare utilizator",
  deleteUser: "Ștergere utilizator",
  assignUserRole: "Alocare rol",
  resetPassword: "Resetare parolă",

  createCourse: "Creare curs",
  changeCourse: "Modificare curs",
  addQuizContent: "Adăugare conținut Quiz",
  changeQuizContent: "Modificare conținut Quiz",
  addContent: "Adăugare conținut curs",
  changeContent: "Modificare conținut curs",
  deleteCourse: "Ștergere curs",
  deleteContent: "Ștergere conținut curs",
  publishCourse: "Publicare curs",
  unpublishCourse: "curs nepublicat",
  assignCourse: "Alocare curs",
  unassignCourse: "Anulare alocare curs",

  systemMessages: "Mesaje sistem",

  learningManagement: "Management învățare",
  courseManagement: "Management curs",
  userManagement: "Management utilizatori",
  organizationStructure: "Structură organizație",
  accountManagement: "Management conturi",
  system: "Sistem",

  learningManagementDetail: "Planuri de învățare și alocare curs",
  courseManagementDetail: "Administrare conținuturi curs",
  userManagementDetail: "Administrare utilizatori și resetare parole",
  organizationStructureDetail: "Definire structură organizație",
  accountManagementDetail: "Administrare conturi",
  logsDetail: "Logări sistem și setări",

  userCreateSuccess: "Utilizator creat cu succes",
  createOtherUser: "Adăugare un alt utilizator",
  backToUserOverview: "Înapoi la vedere de ansamblu utilizatori",
  importMoreUsers: "Importare alți utilizatori",
  currentRoles: "Roluri actuale",
  numberOfRoles: "Număr roluri",
  numberOfUsers: "Utilizator",
  deleteAccountMessage:
    "Vă rugăm să confirmați că doriți să ștergeți contul '$account'.",
  showStatistics: "Afișare statistici",
  appendAccount: "Atașare cont la $account.",

  courseAssignments: "Alocat",
  courseAssignmentsForCourse: "Alocări curs pentru $course",
  mandatoryAssignments: "Alocări curs obligatoriu",
  dueDateAssignments: "Cu perioadă",
  dueDatePassed: "Scadent",
  userOrAccount: "Utilizator sau departament",
  expiryDate: "Data expirării",
  deletePublication: "Ștergere publicare",
  deletePublicationMessage:
    "Sunteți sigur că doriți să ștergeți publicarea pentru contul $account?",
  orgStructure: "Departament",
  type: "Tip",
  downloadTemplate: "Descărcare șablon",
  published: "Publicat",
  scorm: "SCORM",
  quiz: "Quiz",
  staticFile: "Pdf",
  editPublication: "Prelucrare publicare",
  pleaseDefineCourseContent:
    "please define the course content before publishing it.",

  usernameMissing: "Numele utilizatorului lipsește",
  usernameError:
    "Numele utilizatorului poate consta numai din caractere alfanumerice",
  emailMissing: "E-mailul lipsește",
  emailError: "Nicio adresă de e-mail valabilă",
  passwordMissing: "Parola lipsește",
  passwordError:
    "Parola trebuie să aibă cel puțin 8 caractere și să conțină o majusculă și o literă mică.",

  registrationKeyManagement: "Cheie înregistrare",
  registrationKeyManagementDetail: "Administrare chei înregistrare",
  addRegistrationKey: "Adăugare cheie înregistrare",
  registrationLink: "Link de înregistrare",
  createKey: "Adăugare",

  userState: "Statul utilizator",
  userStateDetail: "Permis de acces și cursuri obligatorii.",
  testsPassed: "Au trecut testele",
  access: "Acces",
  downloadReport: "Descarcă raportul",
  success: "Salvat cu succes!",
  warning: "Atenție!",
  error: "Eroare!",
  removedFromProgram: "Acest curs a fost eliminat din programul dumneavoastră",
  addedToProgram: "Acest curs a fost adăugat în programul dumneavoastră",
  courseCreated: "Cursul a fost creat",
  courseNotCreated: "Cursul nu a fost creat",
  fillUpFields: "Vă rugăm să completați toate câmpurile obligatorii",
  cookieNotAvailable:
    "Informațiile dumneavoastră de logare nu sunt disponibile sau sunt prea vechi, vă rugăm să vă logați din nou",
  courseNotFinished: "Nicio informație de absolvire pentru curs disponibilă",
  courseAborted: "Cursul a fost întrerupt",
  correctAnswer: "Răspuns corect",
  wrongAnswer: "Răspuns greșit",
  courseStarted: "Cursul a fost pornit",
  testsPassed: "Test promovat",
  testFailed: "Test nepromovat",
  courseCompleted: "Cursul a fost absolvit cu succes",
  imageTooLarge: "Fișierul trebuie să fie mai mic de 2mb",
  wrongFormat: "Format greșit",
  passwordReset:
    "Un link pentru resetarea parolei a fost trimis pe adresa de e-mail indicată",
  passwordChanged: "Parola a fost modificată",
  accountAdded: "Contul a fost adăugat",
  accountUpdated: "Contul a fost actualizat",
  accountDeleted: "Contul a fost șterst",
  userImported: "Utilizatorii au fost importați",
  userNotImported: "Utilizatorii nu au fost importați",
  loggingConfigSaved: "Configurația de logare a fost salvată",
  userDeleted: "Utilizatorul a fost șters",
  userAdded: "Utilizatorul a fost creat",
  userUpdated: "Utilizatorul a fost actualizat",
  GDPRLoaded: "RGPD este încărcat",
  cookiesDisabled: "Ați dezactivat modulele cookie",
  wrongCredentials: "Date de logare greșite",
  fileUploaded: "Fișierul dumneavoastră a fost încărcat",
  courseContentUpdated: "Conținutul cursului a fost actualizat",
  coursePublished: "Cursul a fost publicat",
  courseUnPublished: "Publicarea cursului a fost anulată.",
  serverUnreachable: "Serverul nu poate fi accesat",
  selectAccounts: "Selectare cont",
  selectAccountToContinue: "Selectare cont pentru a continua",
  accountOverview: "Vedere de ansamblu cont",
  users: "Utilizator",
  courses: "Cursuri",
  coursesCompleted: "Cursuri absolvite",
  completedCoursesPerUser: "Cursuri absolvite pe utilizator",
  learningHours: "Ore de studiu",
  learningHoursPerUser: "Ore de studiu pe utilizator",
  courseOverview: "Vedere de ansamblu asupra cursului",
  completed: "Finalizat",
  assigned: "Alocat",
  courseDetails: "Detalii",
  selectCourse: "Selectare curs",
  details: "Selectare",
  type: "Tip",
  dueDate: "Până la",
  mandatory: "Curs obligatoriu",
  completionRate: "Rată de absolvire",
  averageScore: "Rezultat test",
  duration: "Durată",
  averageDuration: "Durată medie",
  account: "Cont",
  accountName: "Nume cont",
  accounts: "Conturi",
  active: "Activ",
  admin: "Administrator",
  all: "Toți",
  not_found: "Pagina nu există",
  assessmentStatistics: "Statistici evaluare",
  assignedUsersOnly: "Numai utilizatori alocați",
  autoIndividual: "Automat, individual",
  cancel: "Întrerupere",
  cardImage: "Imagine hartă",
  close: "Închidere",
  completed: "Finalizat",
  confirm: "Confirmare",
  country: "Țară",
  course: "Cursuri",
  courseDescription: "Descriere curs",
  courseDocuments: "Documente curs",
  courses: "Bibliotecă",
  courseStructure: "Structură curs",
  dataProtection: "Protecția datelor",
  defaultLanguage: "Limbă standard",
  deleteText: "Sunteți sigur că doriți să ștergeți aceasta",
  delete: "Ștergere",
  details: "Detalii",
  dragAndDrop: "Drag & Drop / Clic pentru adăugare",
  dueDate: "Scadent pe",
  email: "E-mail",
  empty: "Gol",
  exit: "Delogare",
  expiresOn: "Expiră pe",
  fileImport: "Fișier de importat",
  image: "Imagine",
  inactive: "Inactiv",
  language: "Limbă",
  logo: "Logo",
  mandatory: "Curs obligatoriu",
  monthsLabels: [
    "Ianuarie",
    "Februarie",
    "Martie",
    "Aprilie",
    "Mai",
    "Iunie",
    "Iulie",
    "August",
    "Septembrie",
    "Octombrie",
    "Noiembrie",
    "Decembrie",
  ],
  myAccount: "Contul meu",
  myProfile: "Profilul meu",
  myProgram: "Programul meu",
  myRoles: "Rolurile mele",
  never: "Niciodată",
  no: "Nu",
  none: "niciuna",
  notFound: "Nu a fost găsit",
  phoneNumber: "Număr de telefon",
  playImage: "Redare imagine",
  publishingExpiresOn: "Publicarea expiră pe",
  publishTo: "Publicare pe",
  reports: "Raport",
  save: "Salvare",
  search: "Căutare…",
  status: "Stare",
  structureItem: "Departament",
  termsAndConditions: "Condiții de afaceri",
  unlimited: "Nelimitat",
  update: "Actualizare",
  user: "Utilizator",
  username: "nume de utilizator",
  users: "utilizatori",
  yes: "Da",
  zipCode: "Cod poștal",

  info: "Informații",
  error: "Eroare",
  alert: "Alarmă",
  criticalAlert: "Alarmă critică",
  navigation: "Navigare",
  system: "Sistem",
  note: "Indicație",
  userProfile: "Profil utilizator",

  userId: "ID utilizator",
  customId: "",
  organizationalStructure: "Structură organizație",
  created: "Creat pe",
  new: "Nou",
  noResults: "Niciun rezultat",
  noData: "Nicio dată",
  uploadFile: "Încărcare fișier",
  id: "ID",
  name: "Nume",
  append: "Adăugare",
  edit: "Prelucrare",
  format: "Format",
  minutes: "Minute",
  hours: "Ore",
  date: "Dată",
  message: "Mesaj",
  fax: "Fax",

  genericError:
    "A apărut o eroare, încercați să reîncărcați pagina. În caz că eroarea persistă, contactați administratorul sistemului.",
  action: "Acțiune",

  uploadedCertificates: "Certificate încărcate",

  add_mandatory_user_property:
    "Vă rugăm să selectați următoarele caracteristici pentru jobul dvs.",
  help: "Ajutor",
  alreadyRegistered: "Deja înregistrat",
  additionalDetails: "Informatii suplimentare",
  additionalDetailsSave: "A confirma",
  dragAndDropCertificate:
    "Trageți și plasați fișierul PDF aici sau faceți clic pentru a adăuga",
  coverPhoto: "Cover Photo",
  EditCoverPhoto: "Edit Cover Photo",
  AddCoverPhoto: "Add Cover Photo",
  additionalDocuments: "Additional Documents",
  editAdditionalDocuments: "Edit Additional Documents",
  addAdditionalDocuments: "Add Additional Documents",
  fileName: "File name",
  fileSize: "File size",
  systemLogs: "System Logs",
  systemConfigurationDetail: "Configure system settings",
  systemConfiguration: "System configuration",
  courseFormat: "Course formats",
  views: "Views",
  apis: "APIs",
  logs: "Logs",
  viewsDescription: "Visibility of the element in the respective view",
  courseFormatDescription: "Available course formats for defining the course",
  accessCard: "Access card",
  downloadCertification: "Download Certification",
  userProfileV2: "User profile V2",
  certification: "Certification",
  issuedBy: "Issued by",
  issuedOn: "Issued on",
  fulfilledInPercent: "Fulfilled in percent",

  logout: "Deconectare",
  continue: "Continuă",
  courseFinishedDoYouWantToLogout:
    "Cursul s-a terminat. Vrei să te deconectezi sau să continui? Vei fi deconectat automat în {seconds}.",
  stateless: "Apatrid",
};
