export default {
  props: {
    data: { type: Object, required: true },
    step: { type: Object, required: true },
  },
  methods: {
    sendAnswer(content, completed) {
      this.$store.dispatch("sendAnswer", {
        step: this.step,
        content: {
          type: this.data.type,
          id: this.data.id,
          ...content,
        },
        completed,
      });
    },
  },
};
