<template>
  <div class="data-privacy-policy">
    <info-block @close="close">
      <template #header>
        {{ $t("dataPrivacyHeader") }}
      </template>
      <template #title>
        {{ $t("dataPrivacyTitle") }}
      </template>
      {{ $t("dataPrivacyText") }}
    </info-block>
  </div>
</template>

<script>
import InfoBlock from "./InfoBlock";

export default {
  name: "DataPrivacyPolicy",
  components: {
    InfoBlock,
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="stylus" scoped>
.data-privacy-policy {
  color: #000;
  font-weight: normal;
}
</style>
