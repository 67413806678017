import API from "./config";
import { mapResponse, printError } from "../utils/mapResponse";
import axios from "axios";

/**
 * TODO:
 * Refactor auth header: remove from requests and add in interceptor
 *
 */
/**
 *  @module Auth service
 */
export default {
  /**
   * @typedef {Object} Token
   * @property {string} token - jwt token
   */
  /**
   * @typedef {Object} GDPR
   * @property {array} certificates - certificates array  (array of objects)
   * @property {object} profile - profile information
   * @property {array} relatedLogs - logs array (array of objects)
   */
  /**
   * @typedef {Object} User
   * @property {number} id - id
   * @property {(number|null)} expiration - expiration date
   * @property {string} modified - datetime of modifing (ISO)
   * @property {string} created - datetime of creation (ISO)
   * @property {Array<object>} units - array of roles object
   * @property {number} units.id - account id
   * @property {number} units.roleId - role id
   * @property {UserAttributes} attributes - additional info
   */
  /**
   * @typedef {Object} UserAttributes
   * @property {string} username - username
   * @property {string} notes - addition info
   * @property {string} phone - phone number
   * @property {string} langCode - language code
   * @property {(string|null)} customId - email
   * @property {Array<number>} roles - array of ids
   */
  GET() {
    return axios.get(API.coursesURL).then(mapResponse);
  },

  /**
   * API call to login
   * @param {object} payload - payload object
   * @param {string} payload.email - username/email
   * @param {string} payload.password - user's password
   * @returns {Token} object with jwt token if success,
   */

  POST(payload) {
    return axios.post("/services/api/v2/general/login", payload);
  },

  /**
   * API call to update user information
   * @param {object} payload - payload object
   * @param {string} payload.email - email
   * @param {object} payload.attributes - additional info
   * @returns {User} updated user info
   */

  PATCH(payload) {
    return axios.patch(API.usersURL + result.id, result).then(mapResponse);
  },

  /**
   * API call to update user information
   * @param {object} payload - payload object
   * @param {string} payload.email - email
   * @param {object} payload.attributes - additional info
   * @returns {User} updated user info
   */

  PATCH_ME(payload) {
    return axios
      .patch(API.userProfileURL + payload.id, payload)
      .then(mapResponse);
  },

  refreshToken(refreshToken) {
    return axios.post("/services/api/v2/general/renew-token/", {
      refresh: refreshToken,
    });
  },

  /**
   * API call to set user's password
   * @param {object} payload - payload object
   * @param {number} payload.id - user id
   * @param {string} payload.password - new password
   * @returns {Promise<Response>} Fetch response, status 200 if success
   */

  SET_PASSWORD(payload) {
    return axios.post(API.resetPassowrdURL, payload).then(mapResponse);
  },

  /**
   * API call to reset user's password
   * @param {object} payload - payload object
   * @param {string} payload.email - user email
   * @param {string} payload.password - new password
   * @returns {Promise<Response>} Fetch response, status 200 if success
   */

  FORGOT_PASSWORD(payload) {
    return axios.post(API.forgotPasswordURL, payload).then(mapResponse);
  },

  /**
   * API call to update password
   * @param {object} payload - payload object
   * @param {string} payload.oldPassword - old password
   * @param {string} payload.newPassword - new password
   * @returns {Promise<Response>} Fetch response, status 200 if success
   */

  UPDATE_PASSWORD(payload) {
    const result = {
      ...payload,
    };
    delete result.userId;

    // return fetch(API.resetPassowrdURL, {
    return axios.post(API.changePasswordURL, result);
  },

  REGISTER(payload) {
    return axios
      .post(`${API.baseUrlV2}/access/sign-up`, payload)
      .then(mapResponse);
  },
};
