import getToken from "@/utils/getToken";
import API from "./config";
import { mapResponse, printError } from "../utils/mapResponse";
import axios from "axios";
/**
 *  @module Admin service
 */
export default {
  /**
   * @typedef {Object} UserAttributes
   * @property {string} username - username
   * @property {string} notes - addition info
   * @property {string} phone - phone number
   * @property {string} langCode - language code
   * @property {(string|null)} customId - email
   * @property {Array<number>} roles - array of ids
   */

  /**
   * @typedef {Object} AccountUser
   * @property {number} id - id
   * @property {(string|null)} email - email
   * @property {Array<number>} roles - array of ids
   * @property {UserAttributes} attributes - additional info
   */

  /**
   * @typedef {Object} User
   * @property {number} id - id
   * @property {(number|null)} expiration - expiration date
   * @property {string} modified - datetime of modifing (ISO)
   * @property {string} created - datetime of creation (ISO)
   * @property {Array<object>} units - array of roles object
   * @property {number} units.id - account id
   * @property {number} units.roleId - role id
   * @property {UserAttributes} attributes - additional info
   */

  /**
   * API call to get users that belong to an account by account id
   * @returns {Promise<Array<AccountUser>>} return account users array
   * @deprecated
   */
  GET_ACCOUNT_USERS(id) {
    return axios
      .get(`${API.baseUrl}accounts/${id}/users`)
      .then(mapResponse)
      .catch(printError);
  },

  /**
   * API call to get all users
   * @returns {Promise<Array<User>>} all users list
   * @deprecated Use getUsers() instead, which supports paging, search, filter
   */
  GET_USERS() {
    return axios.get(`${API.baseUrl}users`).then(mapResponse).catch(printError);
  },

  getUsers(query) {
    return axios.get(`${API.baseUrlV2}users?${new URLSearchParams(query)}`);
  },

  /**
   * API call to import users (.csv)
   * @param {object} payload - payload object
   * @param {string} payload.file - Base64 file (.csv)
   * @param {(string|null)} payload.password - user default password
   * @param {(number|null)} payload.account - default account id
   * @param {string} payload.notes - notes
   * @param {(string | null)} payload.expiring - default expiring time (ISO)
   * @param {boolean} payload.active - active user flag
   * @returns {Promise<Response>} Parsed response json
   */
  IMPORT_USERS(payload) {
    return axios
      .get(API.usersImportURL, payload)
      .then(mapResponse)
      .catch(printError);
  },
  /**
   * API call to delete user's account role
   * @param {object} payload - payload object
   * @param {string} payload.id - role id
   * @param {(string|null)} payload.userId - user id
   * @param {(number|null)} payload.accountId - account id
   * @returns {Promise<Response>} Fetch response, status 200 if success
   */
  DELETE_USER_ACCOUNT_ROLE(payload) {
    const { userId, id, accountId } = payload;

    return axios
      .delete(
        `${API.baseUrl}accounts/${accountId}/users/${userId}?${new URLSearchParams({ roleId: id })}`,
      )
      .then(mapResponse)
      .catch(printError);
  },
  /**
   * API call to add user's account role
   * @param {object} payload - payload object
   * @param {string} payload.userId - user id
   * @param {(number|null)} payload.accountId - account id
   * @param {(number|null)} payload.expiration - expiration
   * @param {(number|null)} payload.roleId - role id
   * @returns {boolean} true if the roles has been added
   */
  ADD_USER_ACCOUNT_ROLE(payload) {
    const { roleId, expiration } = payload;
    return axios
      .post(
        `${API.baseUrl}accounts/${payload.accountId}/users/${payload.userId}`,
        {
          roleId,
          expiration,
        },
      )
      .then(mapResponse)
      .catch(printError);
  },

  /**
   * API call to delete user by id
   * @param {number} id - user id
   * @returns {Promise<Response>} Fetch response, status 200 if success
   */
  DELETE_USER(id) {
    return axios
      .delete(API.baseUrl + "users/" + id)
      .then(mapResponse)
      .catch(printError);
  },

  /**
   * API call to reset password
   * @param {object} payload - payload object
   * @param {number} payload.id - user id
   * @param {string} payload.password - password
   * @returns {Promise<Response>} Fetch response, status 200 if success
   */

  RESET_PASSWORD(payload) {
    const { id, ...result } = payload;
    return axios.post(API.changePasswordURL + id, result);
  },

  /**
   * API call to add new user
   * @param {object} payload - payload object
   * @param {string} payload.email - user email
   * @param {number} payload.pickedAccount - account id
   * @param {object} payload.attributes - addition information
   * @param {(string | null)} payload.expiration - user expiration datetime
   * @returns {User} added user object
   */

  POST_USER(payload) {
    const result = payload;
    if (result.units) {
      delete result.units;
    }
    return axios
      .post(`${API.baseUrl}accounts/${payload.account}/users`, [result])
      .then(mapResponse)
      .catch(printError);
  },

  BATCH_ADD_USERS(payload) {
    const accountId = payload.account;
    return axios
      .post(`${API.baseUrl}accounts/${accountId}/users`, payload.users)
      .then(mapResponse)
      .catch(printError);
  },

  CHECK_BATCH_ADD_USERS(payload) {
    const accountId = payload.account;
    return axios
      .post(
        `${API.baseUrl}accounts/${accountId}/users?dryrun=true`,
        payload.users,
      )
      .then(mapResponse)
      .catch(printError);
  },

  /**
   * API call to update user
   * @param {object} payload - payload object
   * @param {number} payload.id - user id
   * @param {string} payload.email - user email
   * @param {number} payload.pickedAccount - account id
   * @param {object} payload.attributes - addition information
   * @param {(string | null)} payload.expiration - user expiration datetime
   * @returns {User} updated user object
   */

  UPDATE_USER(payload) {
    const result = {
      ...payload,
    };
    if (result.signupKey) {
      delete result.signupKey;
    }
    return axios
      .patch(API.baseUrl + "users/" + payload.id, result)
      .then(mapResponse)
      .catch(printError);
  },

  /**
   * API call to remove all roles a user currently holds.
   * This is useful if the user is added to a different Account, since users can only be part of one account.
   * TODO: rename
   * @param {object} payload - payload object
   * @param {number} payload.accountId - account id
   * @param {number} payload.userId - user id
   * @returns {Promise<Response>} Fetch response, status 200 if succes
   */

  REMOVE_ALL_USER_ACCOUNTS(payload) {
    const { accountId, userId } = payload;
    return axios
      .delete(`${API.baseUrl}accounts/${accountId}/users/${userId}`)
      .then(mapResponse)
      .catch(printError);
  },

  SET_USER_ACCESS(id, data) {
    return axios
      .patch(`${API.generalUsersServices}${id}/state`, data)
      .then(mapResponse)
      .catch(printError);
  },

  GET_USER_ACCESS_CARD_HISTORY(userId) {
    return axios
      .get(`${API.generalUsersServices}/cards/${userId}`)
      .then(mapResponse)
      .catch(printError);
  },

  SET_USER_ACCESS_CARD(id, hasCard) {
    return (
      axios
        // .post(`${API.baseUrl}users/${id}/state`, { hasCard })
        .post(`${API.generalUsersServices}${id}/state`, { hasCard })
        .then(mapResponse)
        .catch(printError)
    );
  },

  SET_PROFILE_PICTURE(id, content) {
    return axios.post(`${API.baseUrlV2}accounts/${id}/picture/`, content);
  },

  DELETE_PROFILE_PICTURE(id) {
    return axios.delete(`${API.baseUrlV2}accounts/${id}/picture/`);
  },

  /**
   * API call to get GDPR inforamtion
   * @param {boolean} isDownload - flag for download result
   * @returns {(object|GDPR)} returns BLob obj if flag is Download true,
   *  and GDPR object if the flag is false
   */

  GET_GDPR() {
    // TODO: fix download option
    return axios.get(`${API.getGDPRURL}`).then(mapResponse).catch(printError);
  },

  /**
   * API call to get settings of current user
   * @returns {Record<string, string | boolean | number>} object with jwt token if success,
   */

  GET_USER_SETTINGS() {
    return axios.get("/services/api/v2/users/usersettings");
  },

  /**
   * API call post an update to the users setting
   * @param {Record<string, string | boolean | number>} payload - payload object conatining the settings update.
   */

  PUT_USER_SETTINGS(payload) {
    return axios.put("/services/api/v2/users/usersettings", payload);
  },
};
