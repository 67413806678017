<template lang="pug">
modal.user-profile(auto-width, @close="close")
  template(#title) {{ $t("userProfile") }}
  //- Note: we want to have the tab header sticky and the content scrollable, thus we separate header and content 
  el-tabs(v-model="activeName")
    el-tab-pane(:label="$t('profile')", name="profile")
    el-tab-pane(:label="$t('roles')", name="roles")
    el-tab-pane(:label="$t('properties')", name="properties")
    el-tab-pane(:label="$t('logs')", name="logs")
    el-tab-pane(:label="$t('password')", name="password")
    el-tab-pane(:label="$t('miscellaneous')", name="miscellaneous")
  .content
    user-details(v-if="activeName === 'profile'")
    user-roles(v-if="activeName === 'roles'")
    user-profile-properties(v-if="activeName === 'properties'")
    user-logs(v-if="activeName === 'logs'")
    user-password(v-if="activeName === 'password'")
    miscellaneous(v-if="activeName === 'miscellaneous'", @close="close")
</template>

<script>
import UserDetails from "./UserDetails.vue";
import UserRoles from "./UserRoles.vue";
import Miscellaneous from "./Miscellaneous.vue";
import UserProfileProperties from "./UserProfileProperties.vue";
import UserLogs from "./UserLogs.vue";
import UserPassword from "./UserPassword.vue";
import userService from "@/services/users";

export default {
  name: "UserProfile",
  components: {
    Miscellaneous,
    UserDetails,
    UserRoles,
    UserProfileProperties,
    UserLogs,
    UserPassword,
  },
  props: {},
  data() {
    return {
      activeName: "profile",
    };
  },
  created() {},
  methods: {
    close() {
      this.$emit("close");
      return true;
    },
  },
};
</script>

<style scoped>
.user-profile > :deep(.modal__content) {
  max-height: calc(100vh - 6rem);
  height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  height: 0;
  overflow-y: scroll;
  width: 100%;
}
</style>
