import axios from "axios";
import { mapResponse, printError } from "../utils/mapResponse";
import api from "./config";

export default {
  addEventContent(courseId, content) {
    return axios
      .put(`${api.eventServices}?${new URLSearchParams({ courseId })}`, content)
      .then(mapResponse)
      .catch(printError);
  },
  patchEventContent(courseId, content) {
    // NOTE: backend supports patching through put
    return axios
      .put(`${api.eventServices}?${new URLSearchParams({ courseId })}`, content)
      .then(mapResponse)
      .catch(printError);
  },
  getEventContent(contentId) {
    return axios
      .get(`${api.eventServices}${contentId}`)
      .then(mapResponse)
      .catch(printError);
  },
  getAllEvents() {
    return axios.get(api.eventServices).then(mapResponse).catch(printError);
  },

  getAllDates(params) {
    var queryParams = "";
    if (params) {
      queryParams = new URLSearchParams({ ...params }).toString();
    }
    return axios
      .get(`${api.eventServices}dates?${queryParams}`)
      .then(mapResponse)
      .catch(printError);
  },
  getEventDates(eventId) {
    return axios
      .get(`${api.eventServices}dates?${new URLSearchParams({ eventId })}`)
      .then(mapResponse)
      .catch(printError);
  },
  createEventDate(date) {
    return axios
      .post(`${api.eventServices}dates`, date)
      .then(mapResponse)
      .catch(printError);
  },
  editEventDate(eventDate) {
    return axios
      .patch(`${api.eventServices}dates/${eventDate.id}`, eventDate)
      .then(mapResponse)
      .catch(printError);
  },

  editPreregistrations(data) {
    return axios.put(`${api.eventServices}pre-registration/${data.id}`, data);
  },
  searchRegistrations(eventId, searchBody, account) {
    let queryParams = "";
    if (account) {
      queryParams = `?${new URLSearchParams({ account })}`;
    }
    return axios.post(
      `${api.eventServices}${eventId}/registrations-search${queryParams}`,
      searchBody,
    );
  },
  getEventRegistration(eventId, userId) {
    return axios
      .get(`${api.eventServices}${eventId}/registrations/${userId}`)
      .then(mapResponse);
  },
  editRegistration(eventId, userId, dateId) {
    return axios.put(`${api.eventServices}${eventId}/registrations/${userId}`, {
      dateId,
    });
  },
  deleteRegistration(eventId, userId) {
    return axios.delete(
      `${api.eventServices}${eventId}/registrations/${userId}`,
    );
  },
  getExamResults(dateId) {
    return axios
      .get(`${api.eventServices}dates/${dateId}/exam`)
      .then(mapResponse)
      .catch(printError);
  },
  patchExamResults(dateId, results) {
    return axios
      .patch(`${api.eventServices}dates/${dateId}/exam`, { users: results })
      .then(mapResponse)
      .catch(printError);
  },
  getTranslations(eventId) {
    return axios
      .get(`${api.eventServices}${eventId}/translations`)
      .then(mapResponse)
      .catch(printError);
  },
  patchTranslations({ courseId, patches }) {
    return axios
      .patch(`${api.eventServices}${courseId}/translations`, {
        content: patches,
      })
      .then(mapResponse)
      .catch(printError);
  },

  // Questionnaire
  getQuestionnaires() {
    return axios
      .get(`${api.eventServices}questionnaire`)
      .then(mapResponse)
      .catch(printError);
  },
  createQuestionnaire(questionnaire) {
    return axios
      .post(`${api.eventServices}questionnaire`, questionnaire)
      .then(mapResponse)
      .catch(printError);
  },
  deleteQuestionnaire(questionnaireId) {
    return axios
      .delete(`${api.eventServices}questionnaire/${questionnaireId}`)
      .then(mapResponse)
      .catch(printError);
  },
  patchQuestionnaire(questionnaireId, questionnaire) {
    return axios
      .patch(
        `${api.eventServices}questionnaire/${questionnaireId}`,
        questionnaire,
      )
      .then(mapResponse)
      .catch(printError);
  },
  getQuestionnaireQuestions(questionnaireId) {
    return axios
      .get(`${api.eventServices}questionnaire/${questionnaireId}/questions`)
      .then(mapResponse)
      .catch(printError);
  },
  createQuestionnaireQuestion(questionnaireId, question) {
    return axios
      .post(
        `${api.eventServices}questionnaire/${questionnaireId}/questions`,
        question,
      )
      .then(mapResponse)
      .catch(printError);
  },
  orderQuestionnaireQuestions(questionnaireId, ordering) {
    return axios
      .put(`${api.eventServices}questionnaire/${questionnaireId}/order`, {
        ordering,
      })
      .then(mapResponse)
      .catch(printError);
  },
  deleteQuestionnaireQuestion(questionnaireId, questionId) {
    return axios
      .delete(
        `${api.eventServices}questionnaire/${questionnaireId}/questions/${questionId}`,
      )
      .then(mapResponse)
      .catch(printError);
  },
  patchQuestionnaireQuestion(questionnaireId, questionId, question) {
    return axios
      .patch(
        `${api.eventServices}questionnaire/${questionnaireId}/questions/${questionId}`,
        question,
      )
      .then(mapResponse)
      .catch(printError);
  },
  getQuestionnaireSections(questionnaireId) {
    return axios
      .get(`${api.eventServices}questionnaire/${questionnaireId}/sections`)
      .then(mapResponse)
      .catch(printError);
  },
  postQuestionnaireSection(questionnaireId, name, order) {
    return axios
      .post(`${api.eventServices}questionnaire/${questionnaireId}/sections`, {
        name,
        order,
      })
      .then(mapResponse)
      .catch(printError);
  },
  deleteQuestionnaireSection(questionnaireId, sectionId) {
    return axios
      .delete(
        `${api.eventServices}questionnaire/${questionnaireId}/sections/${sectionId}`,
      )
      .then(mapResponse)
      .catch(printError);
  },
  patchQuestionnaireSection(questionnaireId, sectionId, content) {
    return axios
      .patch(
        `${api.eventServices}questionnaire/${questionnaireId}/sections/${sectionId}`,
        content,
      )
      .then(mapResponse)
      .catch(printError);
  },
};

export type QuestionnaireSections = {
  name: string;
  order: number;
  id: string;
};
