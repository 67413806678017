<template lang="pug">
div
  EditPreview.mb-2(:text="text", :edit-on-start="false", @editText="editText")
  .flex-form-item
    EditPreview.mr-2(ref="labelEditor", :text="label", @editText="editLabel")
    .flex-form-input
      el-input(
        :type="data.inputType",
        :maxlength="data.maxlength",
        :minlength="data.minlength",
        :show-word-limit="data.showWordLimit",
        :rows="data.rows"
      )
</template>

<script>
import { mapGetters } from "vuex";
import editStepMixin from "./mixins/editStepMixin";
import EditPreview from "./components/EditPreview.vue";
import { nextTick } from "vue";

export default {
  components: {
    EditPreview,
  },
  mixins: [editStepMixin],
  data: function () {
    return {
      isEditingText: false,
      isEditingLabel: false,
    };
  },
  computed: {
    data() {
      return this.step;
    },
    text() {
      return this.step.text;
    },
    label() {
      return this.step.label;
    },
  },
  mounted() {
    nextTick(() => {
      this.$refs.labelEditor.focus();
    });
  },
  methods: {
    editText(text) {
      this.patchStep({ text });
    },
    startEditText() {
      this.isEditingText = true;
    },
    stopEditText() {
      this.isEditingText = false;
    },
    editLabel(label) {
      this.patchStep({ label });
    },
    startEditLabel() {
      this.isEditingLabel = true;
    },
    stopEditLabel() {
      this.isEditingLabel = false;
    },
  },
};
</script>
