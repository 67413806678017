<template>
  <svg
    :class="{ clickable: clickable }"
    width="26px"
    height="26px"
    viewBox="0 0 26 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Benutzerprofil"
        class="icon"
        :class="{ clickable: clickable }"
        transform="translate(-551.000000, -180.000000)"
        stroke="#0A457C"
        stroke-width="2"
      >
        <g id="Group" transform="translate(552.000000, 181.000000)">
          <circle id="Oval" cx="12" cy="12" r="12" />
          <path
            id="Line-3"
            d="M6.94903566,6.94903566 L17.0509643,17.0509643"
            stroke-linecap="round"
          />
          <path
            id="Line-3-Copy"
            d="M6.94903566,6.94903566 L17.0509643,17.0509643"
            stroke-linecap="round"
            transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "CloseIcon",
  props: {
    clickable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
