export default {
  termsAccept: "Acepto las {0}.",
  tos: "CGC",
  privacyAccept: "Acepto la {0}.",
  privacyPolicy: "Declaración de Protección de Datos",
  resetPassword: "Restablecer contraseña",
  usernamePasswordRequired:
    "Por favor, introduzca un nombre de usuario y una contraseña.",
  passwordTooShort:
    "La contraseña debe tener una longitud mínima de 8 caracteres.",
  wrongCredentials:
    "No se ha encontrado ninguna cuenta con los datos indicados.",
  accountStructureItem: "Departamento",
  addRole: "Añadir rol",
  allUserRelatedDeleteText:
    "Por la presente, se eliminarán de forma irrevocable todos los datos relevantes del usuario.",
  assignRoleModals: "Asignar rol",
  canBeDownloadedText: "pueden descargarse en finalizados",
  changePassword: "Cambiar la contraseña",
  changingPasswordFor: "Cambiar la contraseña para el usuario",
  confirmDeleteText: "Por favor, toque el nombre del usuario para confirmar:",
  confirmDeteleBtnText: "He comprendido, elimine el usuario",
  confirmPassword: "Repetir la contraseña",
  createAccount: "Crear cuenta",
  createUser: "Crear usuario",
  currentPassword: "Contraseña actual",
  customId: "ID definido por el usuario",
  dataPrivacyHeader: "Privacidad de los datos",
  dataPrivacyText: "Texto de la sección (el contenido se añadirá en breve)",
  dataPrivacyTitle: "Encabezado de la sección",
  dataRelatedToMe: "Visualizar los datos guardados por mí",
  dataRelatedToMeTitle: "Datos guardados",
  deleteText: "¿Está seguro de que desea eliminar eso?",
  downloadDataRelatedToMe: "Descargar los datos guardados",
  fileWithUserInformation: "Archivo con información del usuario",
  forgotPassword: "¿Olvidó su contraseña?",
  forgotPasswordText:
    "Por favor, introduzca su dirección de correo electrónico para recibir un enlace para restablecer su contraseña.",
  importUsers: "Importar usuario",
  newHere: "¿Es nuevo aquí?",
  newPassowrd: "Nueva contraseña",
  newPasswordShort: "La nueva contraseña es demasiado corta",
  password: "Contraseña",
  passwordCantBeEmpty: "La contraseña no puede estar vacía",
  passwordsNotMatch: "Las contraseñas no coinciden",
  pickUser: "Seleccione un usuario",
  pleaseFillUpAllFields: "Por favor, complete todos los campos",
  pleaseTypeNewPassword: "Por favor, introduzca su nueva contraseña",
  policyAcceptance: "Aceptación de las directrices",
  policyAcceptanceSave: "Aceptar",
  acceptPolicies: "Por favor, acepte las directrices",
  profile: "Perfil",
  role: "Roles",
  roleExpiresOn: "Los roles vencen el",
  saveDataAboutMe: "datos guardados por mí",
  signIn: "Iniciar sesión",
  termsConditionsHeader: "Condiciones Generales de Contratación",
  termsConditionsText: "Texto de la sección (el contenido se añadirá en breve)",
  termsConditionsTitle: "Encabezado de la sección",
  typeInOne: "Introduzca uno para todos",
  username: "Nombre de usuario",
  firstname: "Nombre",
  lastname: "Apellido(s)",
  usernameMail: "Nombre de usuario o dirección de correo electrónico",
  userNotes: "Notas",
  userProfile: "Perfil de usuario",
  userStatus: "Estado del usuario",
  wrongCurrentPassword: "Contraseña actual incorrecta",
  fillAllFields: "Por favor, rellene todos los campos necesarios",
  wrongUsernameOrPassword: "Nombre de usuario o contraseña incorrectos",
  minimumLength: "longitud mínima 8",
  atLeastOneBigAndSmall: "mínimo 1 minúscula y 1 mayúscula",
  passwordNotEqualUsernameAndEmail:
    "la contraseña no coincide con el nombre de usuario y el correo electrónico",
  registrationKey: "Clave de registro",
  registration: "Registro",
  notAnEmailAdress:
    "Por favor, introduzca una dirección de correo electrónico válida.",
  company: "Compañía",
  companyHint: "Compañía",
  birthday: "Cumpleaños",
  birthdayHint: "Cumpleaños",

  accountName: "Nombre de la cuenta",
  accountType: "Tipo de cuenta",
  accountAddress: "Dirección del titular",
  logo: "Logotipo",
  address: "Dirección",
  contry: "País",
  zipCode: "Código Postal",
  lessThan: "menos de 1 mb",
  defaultLanguage: "Idioma predeterminado",
  accountStatus: "Estado de la cuenta",
  accountNotes: "Notas de la cuenta",
  createAccount: "Crear cuenta",
  updateAccount: "Actualizar cuenta",
  fileToImport: "Notas de la cuenta",
  importStructure: "Importar estructura",
  addRootAccount: "Añadir cuenta de nivel básico",
  usersPerMonth: "Usuarios mensuales",
  usersAdded: "Usuarios añadidos",
  usersDeleted: "Usuarios eliminados",
  usersTotal: "Usuarios totales",
  city: "Ciudad",

  knowledge: "CONOCIMIENTO",
  toolbox: "CAJA DE HERRAMIENTAS",
  lessonCompleted: "Lección finalizada",
  lessonFailed: "Lección no finalizada",
  repeatLesson: "Por favor, repita la lección para continuar",
  congratulations: "¡Enhorabuena!",
  courseCompleted: "Curso finalizado",
  percentCompleted: "Ha respondido $successRate% preguntas correctamente.",
  downloadCertificate:
    'En la sección "Certificados" de su perfil, puede descargar su certificados en cualquier momento y solicitarlos por correo electrónico.',
  trueFalse: "¿Correcto o incorrecto?",
  seconds: "Segundos",
  noChoiceText: "Lástima, demasiado tarde.",
  right: "¡Correcto!",
  wrong: "¡Incorrecto!",
  progress: "$current de $total",

  addCourseContent: "Añadir el contenido del curso",
  addSemicolon: "Añada etiquetas separadas por un punto y coma",
  addToProgram: "Añadir al programa",
  allAccounts: "Todas las cuentas",
  certificate: "Certificado",
  certificates: "Certificados",
  completed: "Finalizado",
  contentIsLoaded: "Contenido cargado",
  contentManager: "Gestor de contenido",
  courseCompleted: "Curso finalizado",
  courseContent: "Contenido del curso",
  courseDocuments: "Documentación",
  courseLables: "Etiquetas del curso",
  courseLanguage: "Idioma del curso",
  courseNotes: "Notas del curso",
  courseStructure: "Estructura",
  courseTags: "Etiquetas del curso",
  courseTitle: "Nombre del curso",
  createCourse: "Crear curso",
  curator: "CURADOR",
  description: "Descripción",
  details: "Detalles",
  dueDate: "Vencedero hasta",
  duration: "Duración",
  exportStatistics: "Exportar estadísticas",
  learningStandard: "Estándar de aprendizaje",
  licenses: "Licencias",
  mandatory: "Curso obligatorio",
  noAssessment: "Actualmente no hay ninguna evaluación para examinar.",
  noCoursesAssigned:
    "En su cuenta no hay ningún curso asignado. Por favor, visite",
  noCoursesAvailable: "Actualmente no hay ningún curso disponible.",
  noDueDate: "No hay fecha de vencimiento",
  overview: "VISTA GENERAL",
  page: "",
  pickAnAccount: "Seleccione una cuenta",
  printCertificate: "Imprimir certificado",
  publishCourse: "Publicar curso",
  publishedToAccount: "Publicado en la cuenta",
  relatedCourses: "Cursos similares",
  remove: "Eliminar",
  removeFromProgram: "Eliminar del programa",
  singleAccount: "Cuenta individual",
  startCourse: "Iniciar",
  successMessageOne: "¡Enhorabuena, ha finalizado el curso correctamente ",
  successMessageThree:
    "Ahora puede descargar o imprimir su certificado de finalización. ",
  successMessageTwo: " completado con éxito!",
  tags: "Palabras clave",
  unpublishCourse: "Eliminar publicación",
  updateCourseContent: "Cargar contenido del curso",
  updateCourse: "Actualizar curso",
  youDidntfinishCourse: "Aún no ha finalizado ningún curso.",
  courseVisibleFor: "Curso visible para",
  accountStructureItem: "Departamento",
  courseAssigmentOptions: "Opciones de asignación",
  assignCourse: "Asignar curso",
  pickACourse: "Seleccione un curso",

  noDocumentsAvailable: "no hay documentación disponible",
  nothingRelated: "no hay nada similar disponible",
  nothingWritten: "no se ha introducido nada",
  noTagsAvailable: "no hay palabras clave disponibles",
  alreadyGlobal: "El curso ya está publicado a nivel mundial.",
  questionsanswered: "Preguntas",
  completedOn: "Finalizado el",
  locale: "es-ES",
  quizAuthorizationContent: "Autorización de la prueba",

  courseNotPublished: "Actualmente, el curso no está publicado",
  courseNotPublishedForAccount:
    "Actualmente, el curso no está publicado para la cuenta $account",
  clickToPublishCourse: "Publicar curso",
  visible: "Visible",
  userIsNotContentManager:
    "Usted no es un Gestor de Contenido y, por lo tanto, no puede editar el contenido del curso.",
  assignmentOpen: "Abrir",
  assignmentStarted: "Iniciado",
  assignmentCompleted: "Finalizado",
  dueDatePassed: "Fecha de vencimiento excedida",

  exitCourse: "Finalizar el curso",
  markAsFinished: "Marcar como editor",

  system: "Sistema",
  configureLogging: "Configurar registro",
  createAccount: "Crear cuenta",
  changeAccount: "Modificar cuenta",
  deleteAccount: "Eliminar cuenta",
  importAccountStructure: "Importar estructura de la cuenta",
  createUser: "Crear usuario",
  importUser: "Importar usuario",
  changeUser: "Modificar usuario",
  deleteUser: "Eliminar usuario",
  assignUserRole: "Asignar rol",
  resetPassword: "Restablecer contraseña",

  createCourse: "Crear curso",
  changeCourse: "Modificar curso",
  addQuizContent: "Añadir contenido de la prueba",
  changeQuizContent: "Modificar contenido de la prueba",
  addContent: "Añadir contenido del curso",
  changeContent: "Modificar contenido del curso",
  deleteCourse: "Eliminar curso",
  deleteContent: "Eliminar contenido del curso",
  publishCourse: "Publicar curso",
  unpublishCourse: "Não publique curso",
  assignCourse: "Asignar curso",
  unassignCourse: "Eliminar asignación del curso",

  systemMessages: "Mensajes del sistema",

  learningManagement: "Gestión de aprendizaje",
  courseManagement: "Gestión de cursos",
  userManagement: "Gestión de usuarios",
  organizationStructure: "Estructura de la organización",
  accountManagement: "Gestión de contenidos",
  system: "Sistema",

  learningManagementDetail: "Planes de aprendizaje y asignación de cursos",
  courseManagementDetail: "Administrar contenidos del curso",
  userManagementDetail: "Administrar usuarios y restablecer contraseñas",
  organizationStructureDetail: "Definir la estructura de la organización",
  accountManagementDetail: "Administrar cuentas",
  logsDetail: "Registros y configuración del sistema",

  userCreateSuccess: "Usuario creado correctamente",
  createOtherUser: "Añadir otro usuario",
  backToUserOverview: "Volver a la vista general del usuario",
  importMoreUsers: "Importar otro usuario",
  currentRoles: "Roles actuales",
  numberOfRoles: "Número de roles",
  numberOfUsers: "Usuario",
  deleteAccountMessage:
    "Por favor, confirme que desea eliminar la cuenta '$account'.",
  showStatistics: "Mostrar estadísticas",
  appendAccount: "Adjuntar cuenta en $account.",

  courseAssignments: "Asignado",
  courseAssignmentsForCourse: "Asignaciones del curso para $course",
  mandatoryAssignments: "Asignaciones para el curso obligatorio",
  dueDateAssignments: "Con plazo",
  dueDatePassed: "Vencedero",
  userOrAccount: "Usuario o departamento",
  expiryDate: "Fecha de vencimiento",
  deletePublication: "Eliminar publicación",
  deletePublicationMessage:
    "¿Está seguro de que desea eliminar la publicación para la cuenta $account?",
  orgStructure: "Departamento",
  type: "Tipo",
  downloadTemplate: "Descargar plantilla",
  published: "Publicado",
  scorm: "SCORM",
  quiz: "Prueba",
  staticFile: "Pdf",
  editPublication: "Editar publicación",
  pleaseDefineCourseContent:
    "please define the course content before publishing it.",

  usernameMissing: "Falta el nombre",
  usernameError:
    "El nombre de usuario solo puede contar con caracteres alfanuméricos",
  emailMissing: "Falta el correo electrónico",
  emailError: "No hay ninguna dirección de correo electrónico válida",
  passwordMissing: "Falta la contraseña",
  passwordError:
    "La contraseña debe tener una longitud mínima de 8 caracteres, así como contar con mayúsculas y minúsculas.",

  registrationKeyManagement: "Clave de registro",
  registrationKeyManagementDetail: "Administración de claves de registro",
  addRegistrationKey: "Añadir clave de registro",
  registrationLink: "Enlace de registro",
  createKey: "Añadir",

  userState: "Estado del usuario",
  userStateDetail: "Permiso de estado de acceso y cursos obligatorios.",
  testsPassed: "Pruebas aprobadas",
  access: "Acceso",
  downloadReport: "Descargar informe",
  success: "¡Guardado correctamente!",
  warning: "¡Precaución!",
  error: "¡Error!",
  removedFromProgram: "Este curso ha sido eliminado de su programa",
  addedToProgram: "Se ha añadido este curso a su programa",
  courseCreated: "Se ha creado el curso",
  courseNotCreated: "No se ha creado el curso",
  fillUpFields: "Por favor, rellene todos los campos obligatorios",
  cookieNotAvailable:
    "Sus credenciales de inicio de sesión no están disponibles o son demasiado antiguas, por favor, vuelva a iniciar sesión",
  courseNotFinished:
    "No hay información de finalización disponible para el curso",
  courseAborted: "Se ha cancelado el curso",
  correctAnswer: "Respuesta correcta",
  wrongAnswer: "Respuesta incorrecta",
  courseStarted: "Se ha iniciado el curso",
  testsPassed: "Examen aprobado",
  testFailed: "Examen suspendido",
  courseCompleted: "Se ha finalizado el curso correctamente",
  imageTooLarge: "El archivo no debe tener un tamaño superior a 2 mb",
  wrongFormat: "Formato incorrecto",
  passwordReset:
    "Se ha enviado un enlace para restablecer la contraseña a la dirección de correo electrónico indicada",
  passwordChanged: "Se ha modificado la contraseña",
  accountAdded: "Se ha añadido la cuenta",
  accountUpdated: "Se ha actualizado la cuenta",
  accountDeleted: "Se ha eliminado la cuenta",
  userImported: "Se ha importado el usuario",
  userNotImported: "No se ha importado el usuario",
  loggingConfigSaved: "Se ha guardado la configuración de registro",
  userDeleted: "Se ha eliminado el usuario",
  userAdded: "Se ha creado el usuario",
  userUpdated: "Se ha actualizado el usuario",
  GDPRLoaded: "RGPD cargado",
  cookiesDisabled: "Ha desactivado las cookies",
  wrongCredentials: "Credenciales de inicio de sesión incorrectas",
  fileUploaded: "Su archivo se ha cargado",
  courseContentUpdated: "Se ha actualizado el contenido del curso",
  coursePublished: "Se ha publicado el curso",
  courseUnPublished: "Se ha eliminado la publicación del curso.",
  serverUnreachable: "El servidor no está disponible",
  selectAccounts: "Seleccionar cuenta",
  selectAccountToContinue: "Para proseguir, seleccione una cuenta",
  accountOverview: "Vista general de la cuenta",
  users: "Usuario",
  courses: "Cursos",
  coursesCompleted: "Cursos finalizados",
  completedCoursesPerUser: "Cursos finalizados por usuario",
  learningHours: "Horas de aprendizaje",
  learningHoursPerUser: "Horas de aprendizaje por usuario",
  courseOverview: "Vista general del curso",
  completed: "Finalizado",
  assigned: "Asignado",
  courseDetails: "Detalles",
  selectCourse: "Seleccionar curso",
  details: "Seleccionar",
  type: "Tipo",
  dueDate: "Hasta",
  mandatory: "Curso obligatorio",
  completionRate: "Tasa de finalización",
  averageScore: "Resultados del examen",
  duration: "Duración",
  averageDuration: "Duración promedio",
  account: "Cuenta",
  accountName: "Nombre de la cuenta",
  accounts: "Cuentas",
  active: "Activa",
  admin: "Administrador",
  all: "Todo",
  not_found: "Esta página no existe",
  assessmentStatistics: "Estadísticas de evaluación",
  assignedUsersOnly: "Solo usuarios asignados",
  autoIndividual: "Automático, individual",
  cancel: "Cancelar",
  cardImage: "Imagen del mapa",
  close: "Cerrar",
  completed: "Finalizado",
  confirm: "Confirmar",
  country: "País",
  course: "Cursos",
  courseDescription: "Descripción del curso",
  courseDocuments: "Documentación del curso",
  courses: "Biblioteca",
  courseStructure: "Estructura del curso",
  dataProtection: "Protección de datos",
  defaultLanguage: "Idioma estándar",
  deleteText: "¿Está seguro de que desea eliminar eso?",
  delete: "Eliminar",
  details: "Detalles",
  dragAndDrop: "Arrastrar y soltar / Hacer clic para añadir",
  dueDate: "Vencedero el",
  email: "Correo electrónico",
  empty: "Vacío",
  exit: "Cerrar sesión",
  expiresOn: "Vence el",
  fileImport: "Ir al archivo que se desea importar",
  image: "Imagen",
  inactive: "Inactiva",
  language: "Idioma",
  logo: "Logotipo",
  mandatory: "Curso obligatorio",
  monthsLabels: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  myAccount: "Mi cuenta",
  myProfile: "Mi perfil",
  myProgram: "Mi programa",
  myRoles: "Mis roles",
  never: "Nunca",
  no: "No",
  none: "Ninguno",
  notFound: "No encontrado",
  phoneNumber: "Número de teléfono",
  playImage: "Reproducir imagen",
  publishingExpiresOn: "Publicación vencedera el",
  publishTo: "Publicar en",
  reports: "Informe",
  save: "Guardar",
  search: "Buscar…",
  status: "Estado",
  structureItem: "Departamento",
  termsAndConditions: "Condiciones Generales de Contratación",
  unlimited: "Ilimitado",
  update: "Actualizar",
  user: "Usuario",
  username: "nombre de usuario",
  users: "usuarios",
  yes: "Sí",
  zipCode: "Código Postal",

  info: "Información",
  error: "Error",
  alert: "Alarma",
  criticalAlert: "Alarma crítica",
  navigation: "Navegación",
  system: "Sistema",
  note: "Indicación",
  userProfile: "Perfil de usuario",

  userId: "ID del usuario",
  customId: "",
  organizationalStructure: "Estructura de la organización",
  created: "Creado el",
  new: "Nuevo",
  noResults: "Ningún resultado",
  noData: "Ningún dato",
  uploadFile: "Cargar archivo",
  id: "ID",
  name: "Nombre",
  append: "Añadir",
  edit: "Editar",
  format: "Formato",
  minutes: "Minutos",
  hours: "Horas",
  date: "Fecha",
  message: "Mensaje",
  fax: "Fax",

  genericError:
    "Se ha producido un error, por favor, intente cargar la página de nuevo. Si el error persiste, póngase en contacto en el administrador del sistema.",
  action: "Acción",

  uploadedCertificates: "Certificados cargados",

  add_mandatory_user_property:
    "Por favor seleccione las siguientes características para su trabajo",
  help: "Ayuda",
  alreadyRegistered: "Ya registrado",
  additionalDetails: "Información Adicional",
  additionalDetailsSave: "Para confirmar",
  dragAndDropCertificate:
    "Arrastre y suelte el archivo PDF aquí o haga clic para añadirlo",
  coverPhoto: "Cover Photo",
  EditCoverPhoto: "Edit Cover Photo",
  AddCoverPhoto: "Add Cover Photo",
  additionalDocuments: "Additional Documents",
  editAdditionalDocuments: "Edit Additional Documents",
  addAdditionalDocuments: "Add Additional Documents",
  fileName: "File name",
  fileSize: "File size",
  userProfileV2: "User profile V2",
  certification: "Certification",
  issuedBy: "Issued by",
  issuedOn: "Issued on",
  fulfilledInPercent: "Fulfilled in percent",
  systemLogs: "System Logs",
  systemConfigurationDetail: "Configure system settings",
  systemConfiguration: "System configuration",
  courseFormat: "Course formats",
  views: "Views",
  apis: "APIs",
  logs: "Logs",
  viewsDescription: "Visibility of the element in the respective view",
  courseFormatDescription: "Available course formats for defining the course",
  accessCard: "Access card",
  downloadCertification: "Download Certification",
  logout: "Cerrar sesión",
  continue: "Continuar",
  courseFinishedDoYouWantToLogout:
    "El curso ha terminado. ¿Quieres cerrar sesión o continuar? Se cerrará la sesión automáticamente en {seconds}.",
  stateless: "apátrida",
};
