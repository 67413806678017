<template>
  <div
    class="vue-avatar--wrapper"
    :style="[style, customStyle]"
    aria-hidden="true"
  >
    <img
      v-if="isImage"
      :src="imgSrc"
      :style="{ height: `${size}px`, width: `${size}px` }"
      class="avatar-image"
      aria-label="Profile Picture"
      @error="hideImage"
    />
    <span v-else class="avatar-text">{{ userInitial }}</span>
  </div>
</template>

<script>
import * as settings from "../../settings.styl.json";
import { mapGetters } from "vuex";

/**
 * From https://github.com/eliep/vue-avatar
// Props
// Name	Required	Default	Type	Description
// username	N	-	String	The user name that will be used to compute user initial.
// initials	N	-	String	Force the displayed initials by overriding the computed ones.
// inline	N	false	Boolean	Uses inline-flex instead of flex
// src	N	-	String	Path to the avatar image to display.
// customStyle	N	-	Object	A custom style object to override the base styles.
// backgroundColor	N	-	String	The avatar background color to use if no image is provided. If none is specified, a background color will be picked depending on the user name length.
// color	N	-	String	The font color used to render the user initials. If none is provided, the background color is used to compute the font color.
// lighten	N	80	Number	A factor by which the background color must be lightened to produce the font color. Number between [-100,100].
// size	N	50	Number	The avatar size in pixel.
// rounded	N	true	Boolean	True if the avatar must be rounded.
// Event
// Name	Arguments	Description
// @avatar-initials	username (the value of the username props), initials (the value of the computed initials or the initials props if any)	This event is trigger when the component is ready with component username and initial.
 */
export default {
  name: "AvatarImage",
  props: {
    username: {
      type: String,
    },
    initials: {
      type: String,
    },
    backgroundColor: {
      type: String,
    },
    customStyle: {
      type: Object,
    },
    inline: {
      type: Boolean,
    },
    size: {
      type: Number,
      default: 50,
    },

    rounded: {
      type: Boolean,
      default: true,
    },
    lighten: {
      type: Number,
      default: 80,
    },
  },

  data() {
    return {
      backgroundColors: settings.avatarColors,
      fontColors: settings.avatarFontColors,
      isImage: true,
      profileImageId: 0,
    };
  },

  mounted() {
    if (!this.isImage) {
      this.$emit("avatar-initials", this.username, this.userInitial);
    }
  },

  computed: {
    ...mapGetters(["getUserData", "profilePictureId"]),
    background() {
      if (!this.isImage) {
        return (
          this.backgroundColor ||
          this.randomBackgroundColor(
            this.username.length,
            this.backgroundColors,
          )
        );
      }
      return "#FFF";
    },
    imgSrc() {
      // NOTE: the id string at the end is to reload the element and force reload the cache, it is not a backend feature
      return `/services/api/v2/accounts/${this.getUserData.id}/picture?id=${this.profilePictureId}`;
    },

    fontColor() {
      if (!this.isImage) {
        return this.fontColors
          ? this.randomBackgroundColor(this.username.length, this.fontColors)
          : this.lightenColor(this.background, this.lighten);
      }
      return "#000";
    },

    style() {
      const style = {
        display: this.inline ? "inline-flex" : "flex",
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderRadius: this.rounded ? "50%" : 0,
        lineHeight: `${this.size + Math.floor(this.size / 20)}px`,
        fontWeight: "bold",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      };

      const imgBackgroundAndFontStyle = {
        background: `transparent url('${this.imgSrc}') no-repeat scroll 0% 0% / ${this.size}px ${this.size}px content-box border-box`,
      };

      const initialBackgroundAndFontStyle = {
        backgroundColor: this.background,
        font: `${Math.floor(this.size / 2.5)}px/${this.size}px $accent-font`,
        color: this.fontColor,
      };

      const backgroundAndFontStyle = this.isImage
        ? imgBackgroundAndFontStyle
        : initialBackgroundAndFontStyle;

      Object.assign(style, backgroundAndFontStyle);

      return style;
    },

    userInitial() {
      if (!this.isImage) {
        const initials = this.initials || this.initial(this.username);
        return initials;
      }
      return "";
    },
  },

  methods: {
    initial(username) {
      let parts = username.split(/[ -]/);
      let initials = "";

      for (var i = 0; i < parts.length; i++) {
        initials += parts[i].charAt(0);
      }

      if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, "");
      }

      initials = initials.substr(0, 3).toUpperCase();

      return initials;
    },

    hideImage() {
      this.isImage = false;
    },

    randomBackgroundColor(seed, colors) {
      return colors[seed % colors.length];
    },

    lightenColor(hex, amt) {
      // From https://css-tricks.com/snippets/javascript/lighten-darken-color/
      var usePound = true;

      if (hex[0] === "#") {
        hex = hex.slice(1);
        usePound = true;
      }

      var num = parseInt(hex, 16);
      var r = (num >> 16) + amt;

      if (r > 255) r = 255;
      else if (r < 0) r = 0;

      var b = ((num >> 8) & 0x00ff) + amt;

      if (b > 255) b = 255;
      else if (b < 0) b = 0;

      var g = (num & 0x0000ff) + amt;

      if (g > 255) g = 255;
      else if (g < 0) g = 0;

      return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    },
  },
  watch: {
    profilePictureId() {
      this.isImage = true;
    },
  },
};
</script>

<style>
.avatar-image {
  object-fit: cover;
  border-radius: 50%;
}
</style>
