<template lang="pug">
.navbar
  router-link.navbar__item.navbar__item--active(
    v-if="isProgrammeAvailable",
    to="/program/"
  ) {{ $t("myProgram") }}
  router-link.navbar__item(
    v-if="isCoursesAvailable",
    to="/courses-list/",
    :class="{ 'router-link-active': currentRouteName === 'Root' }"
  ) {{ $t("courses") }}
  router-link.navbar__item(v-if="isCompletedAvailable", to="/completed/") {{ $t("completed") }}
  router-link.navbar__item(v-if="isCovidAvailable", to="/vaccination/") {{ $t("covid") }}
  router-link.navbar__item(v-if="isReportAvailable", to="/report/") {{ $t("reports") }}
  router-link.navbar__item(v-if="isTestcenterAvailable", to="/test-center/") {{ $t("tests") }}
  router-link.navbar__item(
    v-if="isMonitoringAvailable",
    :to="{ name: 'monitoring' }"
  ) {{ $t("monitoring") }}
  router-link.navbar__item(v-if="isAdminAvailable", to="/admin/") {{ $t("admin") }}
  slot
</template>

<script>
import RolesScopesMixin from "../admin/admin-action/mixins/RolesScopesMixin";
import roles from "@/utils/roles";

export default {
  name: "NavbarComponent",
  mixins: [RolesScopesMixin],
  computed: {
    isCoursesAvailable() {
      const scope = this.scope;
      return scope("users_get_courses");
    },
    isProgrammeAvailable() {
      const scope = this.scope;
      return scope("users_get_courses_programme");
    },
    isCompletedAvailable() {
      const scope = this.scope;
      return scope("users_get_courses_completed");
    },
    isReportAvailable() {
      const scope = this.scope;
      return (
        scope("reports_get_accounts_id") && scope("reports_get_courses_id")
      );
    },
    isMonitoringAvailable() {
      return this.hasRole(roles.global_monitor);
    },
    currentRouteName() {
      return this.$route.name;
    },
    isCovidAvailable() {
      return this.$getSetting("showCovidView");
    },
    isAdminAvailable() {
      const scope = this.scope;
      return (
        this.hasRole(roles.admin) ||
        this.hasRole(roles.global_admin) ||
        this.hasRole(roles.account_owner) ||
        this.hasRole(roles.account_content_manager) ||
        this.hasRole(roles.global_content_admin) ||
        this.hasRole(roles.global_user_manager) ||
        this.hasRole(roles.global_user_state_manager) ||
        this.hasRole(roles.account_user_state_manager) ||
        this.hasRole(roles.trainer) ||
        this.hasRole(roles.event_planner) ||
        this.hasRole(roles.account_disponent)
      ); // || scope('accounts_post_id_units');
    },
    isAssessmentStatisticsAvailable() {
      return this.hasRole(roles.assessment_expert);
    },
    isTestcenterAvailable() {
      return this.$getSetting("showTestCenterView");
    },
    scope() {
      return this.$store.getters.checkCapability;
    },
  },
};
</script>

<style lang="stylus">
@import '../../settings.styl';

.navbar {
  display: flex;
  justify-content: center;
  flex: 1 0;
  margin-left: 0px;

  &__item {
    // text-transform: uppercase;
    font-size: $txt-small;
    font-weight: normal;
    margin: 0 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .router-link-active {
    color: $accent;
  }
}
</style>
