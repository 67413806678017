<template lang="pug">
div
  h3 {{ $t("settings") }}
  .step-settings.mb-1
    label {{ $t("inputType") }}
    el-select(:model-value="inputType", @update:modelValue="setInputType")
      el-option(key="text", value="text", :label="$t('oneLine')")
      el-option(key="textarea", value="textarea", :label="$t('multipleLine')")

    template(v-if="inputType === 'textarea'")
      label {{ $t("rows") }}
      el-input(
        type="number",
        :model-value="rows",
        @update:modelValue="setRows"
      )

    label {{ $t("minLength") }}
    el-input-number(
      :model-value="minLength",
      :max="maxLength - 1",
      :min="0",
      @change="setMinLength"
    )

    label {{ $t("maxLength") }}
    el-input-number(
      :model-value="maxLength",
      :min="minLength + 1",
      @change="setMaxLength"
    )

    template(v-if="maxLength")
      label {{ $t("showWordLimit") }}
      CheckboxInput(
        :model-value="showWordLimit",
        @update:modelValue="setShowWordLimit"
      )

  CommonSettings(:step="step")
</template>

<script>
import { mapGetters } from "vuex";
import CommonSettings from "./CommonSettings";
import CheckboxInput from "@/components/elements/CheckboxInput";
import editStepMixin from "./mixins/editStepMixin";

export default {
  components: {
    CommonSettings,
    CheckboxInput,
  },
  mixins: [editStepMixin],
  computed: {
    label() {
      return this.step.label;
    },
    inputType() {
      return this.step.inputType;
    },
    rows() {
      return this.step.rows;
    },
    minLength() {
      return this.step.minLength;
    },
    maxLength() {
      return this.step.maxLength;
    },
    showWordLimit() {
      return this.step.showWordLimit;
    },
  },
  methods: {
    setLabel(label) {
      this.patchStep({ label });
    },
    setInputType(inputType) {
      this.patchStep({ inputType });
    },
    setRows(rows) {
      this.patchStep({ rows: Number.parseInt(rows) });
    },
    setMinLength(minLength) {
      this.patchStep({ minLength: Number.parseInt(minLength) });
    },
    setMaxLength(maxLength) {
      this.patchStep({ maxLength: Number.parseInt(maxLength) });
    },
    setShowWordLimit(showWordLimit) {
      this.patchStep({ showWordLimit });
    },
  },
};
</script>
