export default {
  termsAccept: "Elfogadom az {0}-et.",
  tos: "ÁÜF",
  privacyAccept: "Elfogadom az {0}-et.",
  privacyPolicy: "Adatvédelmi nyilatkozat",
  resetPassword: "Jelszó visszaállítása",
  usernamePasswordRequired:
    "Kérjük, adjon meg egy felhasználónevet és egy jelszót.",
  passwordTooShort:
    "A jelszónak legalább 8 karakter hosszúságúnak kell lennie.",
  wrongCredentials: "A megadott névvel nem található fiók.",
  accountStructureItem: "Osztály",
  addRole: "Szerep hozzáadása",
  allUserRelatedDeleteText:
    "Ezzel a felhasználóval kapcsolatos minden adat visszavonhatatlanul törlésre kerül.",
  assignRoleModals: "Szerep hozzárendelése",
  canBeDownloadedText: "csak lezárás után tölthető le",
  changePassword: "Jelszó módosítása",
  changingPasswordFor: "Jelszó módosítása a felhasználóhoz",
  confirmDeleteText: "A megerősítéshez gépelje be a felhasználónevet:",
  confirmDeteleBtnText: "Megértettem, törölje a felhasználót",
  confirmPassword: "Jelszó ismétlése",
  createAccount: "Fiók létrehozása",
  createUser: "Felhasználó létrehozása",
  currentPassword: "Jelenlegi jelszó",
  customId: "Felhasználó által definiált azonosító",
  dataPrivacyHeader: "Magánjellegű adatok köre",
  dataPrivacyText: "Bekezdés szövege (tartalom következik)",
  dataPrivacyTitle: "Bekezdéscím",
  dataRelatedToMe: "A rólam tárolt adatok megtekintése",
  dataRelatedToMeTitle: "Tárolt adatok",
  deleteText: "Biztos, hogy törölni szeretné ezt",
  downloadDataRelatedToMe: "Tárolt adatok letöltése",
  fileWithUserInformation: "Fájl felhasználóadatokkal",
  forgotPassword: "Elfelejtette a jelszavát?",
  forgotPasswordText:
    "Adja meg e-mail címét, ahová a jelszavának visszaállításához szükséges hivatkozást megkapja.",
  importUsers: "Felhasználó importálása",
  newHere: "Először van itt?",
  newPassowrd: "Új jelszó",
  newPasswordShort: "Új jelszó túl rövid",
  password: "Jelszó",
  passwordCantBeEmpty: "A jelszó nem lehet üres",
  passwordsNotMatch: "A jelszavak nem egyeznek meg",
  pickUser: "Válasszon ki egy felhasználót",
  pleaseFillUpAllFields: "Az összes mezőt töltse ki",
  pleaseTypeNewPassword: "Adja meg új jelszavát",
  policyAcceptance: "Irányelvek elfogadása",
  policyAcceptanceSave: "Elfogadás",
  acceptPolicies: "Fogadja el az irányelveket",
  profile: "Profil",
  role: "Szerep",
  roleExpiresOn: "A szerep lejár ezen a napon:",
  saveDataAboutMe: "a rólam tárolt adatok",
  signIn: "Bejelentkezés",
  termsConditionsHeader: "Általános üzleti feltételek",
  termsConditionsText: "Bekezdés szövege (tartalom következik)",
  termsConditionsTitle: "Bekezdéscím",
  typeInOne: "Adjon meg egyet az összeshez",
  username: "Felhasználónév",
  firstname: "Keresztnév",
  lastname: "Vezetéknév",
  usernameMail: "Felhasználónév vagy e-mail cím",
  userNotes: "Megjegyzések",
  userProfile: "Felhasználóprofil",
  userStatus: "Felhasználó állapota",
  wrongCurrentPassword: "Jelenlegi jelszó hibás",
  fillAllFields: "Töltsön ki minden szükséges mezőt",
  wrongUsernameOrPassword: "Felhasználónév vagy jelszó hibás",
  minimumLength: "legkisebb hossz 8",
  atLeastOneBigAndSmall: "legalább 1 kisbetű és 1 nagybetű",
  passwordNotEqualUsernameAndEmail:
    "a jelszó nem azonos felhasználónévvel és az e-mail címmel",
  registrationKey: "Regisztrációs kulcs",
  registration: "Regisztráció",
  notAnEmailAdress: "Adjon meg érvényes e-mail címet.",
  company: "Vállalat",
  companyHint: "Vállalat",
  birthday: "Születésnap",
  birthdayHint: "Születésnap",
  accountName: "Fióknév",
  accountType: "Fiókfajta",
  accountAddress: "Fióktulajdonos címe",
  logo: "Logó",
  address: "Cím",
  contry: "Ország",
  zipCode: "Irányítószám",
  lessThan: "kevesebb mint 1 MB",
  defaultLanguage: "Alapértelmezett nyelv",
  accountStatus: "Fiókállapot",
  accountNotes: "Fiókjegyzetek",
  createAccount: "Fiók létrehozása",
  updateAccount: "Fiók frissítése",
  fileToImport: "Fiókjegyzetek",
  importStructure: "Struktúra importálása",
  addRootAccount: "Alapszintű fiók hozzáadása",
  usersPerMonth: "Havi felhasználók",
  usersAdded: "Hozzáadott felhasználók",
  usersDeleted: "Törölt felhasználók",
  usersTotal: "Felhasználók összesen",
  city: "Város",

  knowledge: "TUDÁS",
  toolbox: "ESZKÖZTÁR",
  lessonCompleted: "Lecke lezárva",
  lessonFailed: "Lecke nincs lezárva",
  repeatLesson: "A folytatáshoz ismételje meg a leckét",
  congratulations: "Szívből gratulálunk!",
  courseCompleted: "Tanfolyam lezárva",
  percentCompleted: "A kérdések $successRate%-át helyesen válaszolta meg.",
  downloadCertificate:
    "A tanúsítványát bármikor letöltheti a profiljában levő „Tanúsítványok” menüpontból vagy e-mailben igényelheti.",
  trueFalse: "Helyes vagy helytelen?",
  seconds: "Másodperc",
  noChoiceText: "Sajnálom, elkésett.",
  right: "Helyes!",
  wrong: "Helytelen",
  progress: "$total-ből $current",

  addCourseContent: "Tanfolyam-tartalmak hozzáadása",
  addSemicolon: "Pontosvesszővel elválasztott feliratokat adjon hozzá",
  addToProgram: "Hozzáadás a programhoz",
  allAccounts: "Összes fiók",
  certificate: "Tanúsítvány",
  certificates: "Tanúsítványok",
  completed: "Lezárva",
  contentIsLoaded: "Tartalom letöltve",
  contentManager: "Tartalomkezelő",
  courseCompleted: "Tanfolyam lezárva",
  courseContent: "Tanfolyam-tartalom",
  courseDocuments: "Mellékletek",
  courseLables: "Tanfolyam-címkék",
  courseLanguage: "Tanfolyam nyelve",
  courseNotes: "Tanfolyam jegyzetei",
  courseStructure: "Szerkezet",
  courseTags: "Tanfolyam-címkék",
  courseTitle: "Tanfolyamnév",
  createCourse: "Tanfolyam létrehozása",
  curator: "KURÁTOR",
  description: "Leírás",
  details: "Részletek",
  dueDate: "Esedékes eddig:",
  duration: "Időtartam",
  exportStatistics: "Statisztika exportálása",
  learningStandard: "Tanulási mérce",
  licenses: "Licencek",
  mandatory: "Kötelező tanfolyam",
  noAssessment: "Jelenleg nincsenek megvizsgálandó értékelések.",
  noCoursesAssigned: "Fiókjához nincs tanfolyam hozzárendelve. Keresse fel",
  noCoursesAvailable: "Jelenleg nem áll rendelkezésre tanfolyam.",
  noDueDate: "Nincs esedékességi dátum",
  overview: "ÁTTEKINTÉS",
  page: "",
  pickAnAccount: "Válasszon ki egy fiókot",
  printCertificate: "Tanúsítvány nyomtatása",
  publishCourse: "Tanfolyam közzététele",
  publishedToAccount: "Közzétéve a fiókban",
  relatedCourses: "Hasonló tanfolyamok",
  remove: "Eltávolítás",
  removeFromProgram: "Eltávolítás a programból",
  singleAccount: "Önálló fiók",
  startCourse: "Indítás",
  successMessageOne: "Gratulálunk, a kurzust ",
  successMessageThree:
    "Az elvégzésről szóló tanúsítványt letöltheti vagy kinyomtathatja. ",
  successMessageTwo: " sikeres csatlakozás!",
  tags: "Kulcsszavak",
  unpublishCourse: "Közzététel megszüntetése",
  updateCourseContent: "Tanfolyam-tartalmak feltöltése",
  updateCourse: "Tanfolyam frissítése",
  youDidntfinishCourse: "Még nem fejezett be egy tanfolyamot sem.",
  courseVisibleFor: "Tanfolyam alábbiak számára látható:",
  accountStructureItem: "Osztály",
  courseAssigmentOptions: "Hozzárendelési opciók",
  assignCourse: "Tanfolyam hozzárendelése",
  pickACourse: "Válasszon tanfolyamot",

  noDocumentsAvailable: "nincsenek mellékletek",
  nothingRelated: "nincsenek hasonlók",
  nothingWritten: "semmi nem került megadásra",
  noTagsAvailable: "nincsenek kulcsszavak",
  alreadyGlobal: "A tanfolyam már világszerte közzétéve.",
  questionsanswered: "Kérdések",
  completedOn: "Lezárva ezen a napon:",
  locale: "hu-HU",
  quizAuthorizationContent: "Kvíz jogosultság",

  courseNotPublished: "A tanfolyam jelenleg nincs közzétéve",
  courseNotPublishedForAccount:
    "A tanfolyam jelenleg nincs közzétéve a(z) $account fiók számára",
  clickToPublishCourse: "Tanfolyam közzététele",
  visible: "Látható",
  userIsNotContentManager:
    "Ön nem tartalomkezelő, ezért nem szerkesztheti a tartalmat.",
  assignmentOpen: "Nyitva",
  assignmentStarted: "Elkezdve",
  assignmentCompleted: "Lezárva",
  dueDatePassed: "Esedékességi dátum felülírása",

  exitCourse: "Tanfolyam befejezése",
  markAsFinished: "Megjelölés szerkesztettként",

  system: "Rendszer",
  configureLogging: "Naplózás konfigurálása",
  createAccount: "Fiók létrehozása",
  changeAccount: "Fiók módosítása",
  deleteAccount: "Fiók törlése",
  importAccountStructure: "Fiók-struktúra importálása",
  createUser: "Felhasználó létrehozása",
  importUser: "Felhasználó importálása",
  changeUser: "Felhasználó módosítása",
  deleteUser: "Felhasználó törlése",
  assignUserRole: "Szerep hozzárendelése",
  resetPassword: "Jelszó visszaállítása",

  createCourse: "Tanfolyam létrehozása",
  changeCourse: "Tanfolyam módosítása",
  addQuizContent: "Kvíz-tartalom hozzáadása",
  changeQuizContent: "Kvíz-tartalom módosítása",
  addContent: "Tanfolyam-tartalom hozzáadása",
  changeContent: "Tanfolyam-tartalom módosítása",
  deleteCourse: "Tanfolyam törlése",
  deleteContent: "Tanfolyam-tartalom törlése",
  publishCourse: "Tanfolyam közzététele",
  unpublishCourse: "A kurzus közzétételének visszavonása",
  assignCourse: "Tanfolyam hozzárendelése",
  unassignCourse: "Tanfolyam-hozzárendelés megszüntetése",

  systemMessages: "Rendszerüzenetek",

  learningManagement: "Tanuláskezelés",
  courseManagement: "Tanfolyamkezelés",
  userManagement: "Felhasználókezelés",
  organizationStructure: "Szervezeti felépítés",
  accountManagement: "Fiókok kezelése",
  system: "Rendszer",

  learningManagementDetail: "Tanulási tervek és tanfolyam-hozzárendelés",
  courseManagementDetail: "Tanfolyam-tartalom kezelése",
  userManagementDetail: "Felhasználók kezelése és jelszavak visszaállítása",
  organizationStructureDetail: "Szervezeti felépítés meghatározása",
  accountManagementDetail: "Fiókok kezelése",
  logsDetail: "Rendszernaplók és beállítások",

  userCreateSuccess: "Felhasználó sikeresen létrehozva",
  createOtherUser: "További felhasználó hozzáadása",
  backToUserOverview: "Vissza a felhasználó-áttekintéshez",
  importMoreUsers: "További felhasználók importálása",
  currentRoles: "Jelenlegi szerepek",
  numberOfRoles: "Szerepek száma",
  numberOfUsers: "Felhasználók",
  deleteAccountMessage:
    "Kérjük, nyugtázza, hogy a(z) '$account' fiókot törölni akarja.",
  showStatistics: "Statisztika megjelenítése",
  appendAccount: "Fiók hozzákapcsolása $account fiókhoz.",

  courseAssignments: "Kijelölve",
  courseAssignmentsForCourse: "Tanfolyam-hozzárendelés $course tanfolyamhoz",
  mandatoryAssignments: "Kötelező tanfolyam-hozzárendelések",
  dueDateAssignments: "Határidővel",
  dueDatePassed: "Esedékes",
  userOrAccount: "Felhasználó vagy osztály",
  expiryDate: "Lejárati dátum",
  deletePublication: "Közzététel törlése",
  deletePublicationMessage:
    "Biztos, hogy törölni akarja a(z) $account fiókhoz tartozó közzétételt?",
  orgStructure: "Osztály",
  type: "Típus",
  downloadTemplate: "Minta letöltése",
  published: "Közzétéve",
  scorm: "SCORM",
  quiz: "Kvíz",
  staticFile: "Pdf",
  editPublication: "Közzététel szerkesztése",
  pleaseDefineCourseContent:
    "please define the course content before publishing it.",

  usernameMissing: "Felhasználónév hiányzik",
  usernameError: "A felhasználónév csak alfanumerikus karakterekből állhat",
  emailMissing: "E-mail hiányzik",
  emailError: "Nincs érvényes e-mail cím",
  passwordMissing: "Jelszó hiányzik",
  passwordError:
    "A jelszónak legalább 8 karakter hosszúságúnak kell lennie, valamint egy nagy- és kisbetűt tartalmaznia kell.",

  registrationKeyManagement: "Regisztrációs kulcs",
  registrationKeyManagementDetail: "Regisztrációs kulcsok kezelése",
  addRegistrationKey: "Regisztrációs kulcs hozzáadása",
  registrationLink: "Regisztrációs hivatkozás",
  createKey: "Hozzáadás",

  userState: "Felhasználó állam",
  userStateDetail: "Belépési engedély és kötelező tanfolyamok.",
  testsPassed: "A tesztek letették",
  access: "Hozzáférés",
  downloadReport: "Töltse le a jelentést",

  success: "Sikeresen mentve!",
  warning: "Figyelem!",
  error: "Hiba!",
  removedFromProgram: "Ez a tanfolyam eltávolításra került az Ön programjából",
  addedToProgram: "Ez a tanfolyam hozzáadásra került az Ön programjához",
  courseCreated: "A tanfolyam létrehozásra került",
  courseNotCreated: "A tanfolyam nem került létrehozásra",
  fillUpFields: "Minden kötelező mezőt töltsön ki",
  cookieNotAvailable:
    "Bejelentkezési adatai nem állnak rendelkezésre vagy túl régiek, jelentkezzen be ismét",
  courseNotFinished: "Nincsenek befejezésre vonatkozó adatok a tanfolyamhoz",
  courseAborted: "A tanfolyam megszakításra került",
  correctAnswer: "Helyes válasz",
  wrongAnswer: "Helytelen válasz",
  courseStarted: "A tanfolyam elindításra került",
  testsPassed: "Teszt sikeres",
  testFailed: "Teszt sikertelen",
  courseCompleted: "A tanfolyam sikeresen lezárásra került",
  imageTooLarge: "A fájlnak 2 MB-nál kisebbnek kel lennie",
  wrongFormat: "Hibás formátum",
  passwordReset:
    "A jelszó visszaállításához szükséges hivatkozást elküldtük a megadott e-mail címre",
  passwordChanged: "A jelszó megváltoztatásra került",
  accountAdded: "A fiók hozzáadásra került",
  accountUpdated: "A fiók frissítésre került",
  accountDeleted: "A fiók törlésre került",
  userImported: "Felhasználók importálásra kerültek",
  userNotImported: "Felhasználók nem kerültek importálásra",
  loggingConfigSaved: "A naplózási konfiguráció mentésre került",
  userDeleted: "A felhasználó törlésre került",
  userAdded: "A felhasználó létrehozásra került",
  userUpdated: "A felhasználó frissítésre került",
  GDPRLoaded: "GDPR letöltve",
  cookiesDisabled: "Ön kikapcsolta a cookie-kat",
  wrongCredentials: "Hibás bejelentkezési adatok",
  fileUploaded: "A fájlja feltöltésre került",
  courseContentUpdated: "A tanfolyam-tartalom frissítésre került",
  coursePublished: "A tanfolyam közzétételre került",
  courseUnPublished: "A tanfolyam közzététele megszüntetésre került.",
  serverUnreachable: "A szerver nem érhető el",
  selectAccounts: "Fiók kiválasztása",
  selectAccountToContinue: "A folytatáshoz fiók kiválasztása",
  accountOverview: "Fiók áttekintése",
  users: "Felhasználó",
  courses: "Tanfolyamok",
  coursesCompleted: "Lezárt tanfolyamok",
  completedCoursesPerUser: "Lezárt tanfolyamok felhasználónként",
  learningHours: "Tanulási órák",
  learningHoursPerUser: "Tanulási órák felhasználónként",
  courseOverview: "Tanfolyam áttekintése",
  completed: "Lezárva",
  assigned: "Hozzárendelve",
  courseDetails: "Részletek",
  selectCourse: "Tanfolyam kiválasztása",
  details: "Kiválasztás",
  type: "Típus",
  dueDate: "Eddig",
  mandatory: "Kötelező tanfolyam",
  completionRate: "Befejezési arány",
  averageScore: "Teszteredmény",
  duration: "Időtartam",
  averageDuration: "Átlagos időtartam",
  account: "Fiók",
  accountName: "Fióknév",
  accounts: "Fiókok",
  active: "Aktív",
  admin: "Admin",
  all: "Összes",
  not_found: "Az oldal nem létezik",
  assessmentStatistics: "Értékelési statisztika",
  assignedUsersOnly: "Csak hozzárendelt felhasználók",
  autoIndividual: "Automatikus, egyéni",
  cancel: "Megszakítás",
  cardImage: "Térképes kép",
  close: "Bezárás",
  completed: "Lezárva",
  confirm: "Megerősítés",
  country: "Ország",
  course: "Tanfolyamok",
  courseDescription: "Tanfolyam leírása",
  courseDocuments: "Tanfolyami mellékletek",
  courses: "Könyvtár",
  courseStructure: "Tanfolyam szerkezete",
  dataProtection: "Adatvédelem",
  defaultLanguage: "Alapértelmezett nyelv",
  deleteText: "Biztos, hogy törölni szeretné ezt",
  delete: "Törlés",
  details: "Részletek",
  dragAndDrop: "Áthúzás / kattintás hozzáadáshoz",
  dueDate: "Esedékes ezen a napon:",
  email: "E-mail",
  empty: "Üres",
  exit: "Kijelentkezés",
  expiresOn: "Lejár ezen a napon:",
  fileImport: "Importálandó fájl",
  image: "Kép",
  inactive: "Inaktív",
  language: "Nyelv",
  logo: "Logó",
  mandatory: "Kötelező tanfolyam",
  monthsLabels: [
    "Január",
    "Február",
    "Március",
    "Április",
    "Május",
    "Június",
    "Július",
    "Augusztus",
    "Szeptember",
    "Október",
    "November",
    "December",
  ],
  myAccount: "Saját fiók",
  myProfile: "Saját profil",
  myProgram: "Saját program",
  myRoles: "Saját szerepek",
  never: "Soha",
  no: "Nem",
  none: "Nincs",
  notFound: "Nem található",
  phoneNumber: "Telefonszám",
  playImage: "Kép ábrázolása",
  publishingExpiresOn: "Közzététel lejár ezen a napon:",
  publishTo: "Közzétenni ebben:",
  reports: "Jelentés",
  save: "Mentés",
  search: "Keresés…",
  status: "Állapot",
  structureItem: "Osztály",
  termsAndConditions: "Üzleti feltételek",
  unlimited: "Korlátlan",
  update: "Frissítés",
  user: "Felhasználók",
  username: "Felhasználónév",
  users: "Felhasználók",
  yes: "Igen",
  zipCode: "Irányítószám",

  info: "Infó",
  error: "Hiba",
  alert: "Riasztás",
  criticalAlert: "Kritikus riasztás",
  navigation: "Navigáció",
  system: "Rendszer",
  note: "Megjegyzés",
  userProfile: "Felhasználóprofil",

  userId: "Felhasználó-azonosító",
  customId: "",
  organizationalStructure: "Szervezeti felépítés",
  created: "Létrehozva ezen a napon:",
  new: "Új",
  noResults: "Nincsenek eredmények",
  noData: "Nincsenek adatok",
  uploadFile: "Fájl feltöltése",
  id: "Azonosító",
  name: "Név",
  append: "Hozzáadás",
  edit: "Szerkesztés",
  format: "Formátum",
  minutes: "Percek",
  hours: "Órák",
  date: "Dátum",
  message: "Üzenet",
  fax: "Fax",

  genericError:
    "Hiba lépett fel, próbálja meg az oldal ismételt betöltését. Ha a hiba továbbra is fennáll, akkor forduljon a rendszergazdához.",
  action: "Művelet",

  uploadedCertificates: "Feltöltött tanúsítványok",

  add_mandatory_user_property:
    "Kérjük, válassza ki a következő jellemzőket a munkájához",
  help: "Segítség",
  alreadyRegistered: "Már regisztrált",
  additionalDetails: "További információ",
  additionalDetailsSave: "Megerősítéséhez",
  dragAndDropCertificate:
    "Húzza ide a PDF-fájlt, vagy kattintson a hozzáadáshoz",
  userProfileV2: "User profile V2",
  certification: "Certification",
  logs: "Logs",
  issuedBy: "Issued by",
  issuedOn: "Issued on",
  fulfilledInPercent: "Fulfilled in percent",
  coverPhoto: "Cover Photo",
  EditCoverPhoto: "Edit Cover Photo",
  AddCoverPhoto: "Add Cover Photo",
  additionalDocuments: "Additional Documents",
  editAdditionalDocuments: "Edit Additional Documents",
  addAdditionalDocuments: "Add Additional Documents",
  fileName: "File name",
  fileSize: "File size",
  systemLogs: "System Logs",
  systemConfigurationDetail: "Configure system settings",
  systemConfiguration: "System configuration",
  courseFormat: "Course formats",
  views: "Views",
  apis: "APIs",
  logs: "Logs",
  viewsDescription: "Visibility of the element in the respective view",
  courseFormatDescription: "Available course formats for defining the course",
  accessCard: "Access card",
  downloadCertification: "Download Certification",

  logout: "Kijelentkezés",
  continue: "Folytatás",
  courseFinishedDoYouWantToLogout:
    "A kurzus befejeződött. Ki szeretne jelentkezni vagy folytatja? Automatikusan kijelentkezik {seconds} másodpercen belül.",
  stateless: "hontalan",
};
