import services from "@/services/reports";
import { reports } from "@/services/shogun";
import coursesService from "@/services/courses";
import {
  stateInitial,
  stateLoading,
  stateLoaded,
  stateError,
} from "./loadingStates";

export default {
  state: {
    pickedAccountId: null,
    pickedCourse: null,
    courses: [],
    accountOverview: {},
    courseOverview: {},
    courseDetails: {},
    assignmentReport: [],
    assignmentReportLoadingState: stateInitial,
  },
  mutations: {
    assignmentReportLoadingState(state, payload) {
      state.assignmentReport = payload;
      state.assignmentReportLoadingState = stateLoaded;
    },
    assignmentReportLoading(state, payload) {
      state.assignmentReportLoadingState = stateLoading;
    },
    assignmentReportError(state, payload) {
      state.assignmentReportError = { ...stateError, error: payload };
    },
    setAccountId(state, payload) {
      if (payload || payload === 0) {
        state.pickedAccountId = +payload;
      }
    },
    initReportCourses(state, payload) {
      if (payload || payload === 0) {
        state.courses = payload;
      }
    },
    setCourseId(state, payload) {
      if (payload || payload === 0) {
        state.pickedCourse = payload;
      }
    },
    setAccountOverview(state, payload) {
      if (payload || payload === 0) {
        state.accountOverview = payload;
      }
    },
    setCourseOverview(state, payload) {
      if (payload || payload === 0) {
        state.courseOverview = payload;
      }
    },
    setCourseDetails(state, payload) {
      if (payload || payload === 0) {
        state.courseDetails = payload;
      }
    },
  },
  actions: {
    loadAssignmentReports({ commit }, { id }) {
      commit("assignmentReportLoading");
      services.GET_ASSIGNMENT_REPORT(id).then(
        (response) => {
          commit("assignmentReportLoadingState", response);
        },
        (error) => {
          commit("assignmentReportError", error);
        },
      );
    },
    loadAccountOverview({ commit }, id) {
      services.GET_ACCOUNT_OVERVIEW(id).then((data) => {
        commit("setAccountId", id);
        commit("setAccountOverview", data);
      });
    },
    loadCourseReport({ commit, getters }, courseId) {
      const accountId = getters.getReportPickedAccount;
      const payload = {
        id: accountId,
        courseId,
      };
      services.GET_COURSE_OVERVIEW(payload).then((data) => {
        commit("setCourseOverview", data);
      });
      services.GET_COURSE_DETAILS(payload).then((data) => {
        commit("setCourseDetails", data);
      });
    },
    initReportCourses({ commit }, id) {
      coursesService.INIT_PUBLICATIONS(id).then((data) => {
        commit("initReportCourses", data);
      });
    },

    searchUserStatesViews() {
      reports.userStatesView.searchUserstatesView();
      /*
      or
      reports.userStatesView.searchUserstatesView({ requestBody: { page, pageSize }})
      for real pagination
      */
    },
  },
  getters: {
    assignmentsReport: (state) => state.assignmentReport,
    assignmentsReportLoading: (state) =>
      state.assignmentReportLoadingState.loading,
    assignmentsReportLoaded: (state) =>
      state.assignmentReportLoadingState.loaded,
    assignmentsReportError: (state) => state.assignmentReportLoadingState.error,
    getAssignmentsReportLoadingState: (state) =>
      state.assignmentReportLoadingState,
    getAccountOverview: (state) => state.accountOverview,
    getReportPickedAccount: (state) => state.pickedAccountId,
    getCourseDetails: (state) => state.courseDetails,
    getCourseOverview: (state) => state.courseOverview,
  },
};
