// TODO: remove

import getToken from "@/utils/getToken";

export const requestSettings = {
  headers: {
    Authorization: getToken(),
    "Content-Type": "application/json",
  },
};

export const postSettings = {
  method: "POST",
  ...requestSettings,
};

export const deleteSettings = {
  method: "DELETE",
  ...requestSettings,
};
