import services from "@/services/courseLifecycle";

export default {
  namespaced: true,
  mutations: {},
  actions: {
    startCourse({}, { course }) {
      services.LIFECYCLE({
        state: "start",
        course,
      });
    },

    completeCourse({}, { course, score, duration }) {
      services.LIFECYCLE({
        state: "complete",
        course,

        score,
        duration,
      });
    },

    failCourse({}, { course }) {
      services.LIFECYCLE({
        state: "failed",
        course,
      });
    },
  },
};
