export default {
  termsAccept: "Съгласен съм с {0}.",
  tos: "ОТУ",
  privacyAccept: "Съгласен съм с {0}.",
  privacyPolicy: "Декларация за защита на лични данни",
  resetPassword: "Нулиране на парола",
  usernamePasswordRequired: "Моля, въведете потребителско име и парола.",
  passwordTooShort: "Паролата трябва да е минимум от 8 знака.",
  wrongCredentials: "Не е намерен акаунт с посочените данни.",
  accountStructureItem: "Отдел",
  addRole: "Добавяне на роля",
  allUserRelatedDeleteText:
    "С това се изтриват безвъзвратно всички данни, отнасящи се до потребителя.",
  assignRoleModals: "Разпределяне на роля",
  canBeDownloadedText: "могат да се изтеглят от завършен",
  changePassword: "Промяна на парола",
  changingPasswordFor: "Промяна на парола за потребителя",
  confirmDeleteText: "Моля, въведете потребителско име за потвърждение:",
  confirmDeteleBtnText: "Разбрах, изтривам потребителя",
  confirmPassword: "Повтаряне на парола",
  createAccount: "Създаване на акаунт",
  createUser: "Създаване на потребител",
  currentPassword: "Актуална парола",
  customId: "Идентификационен номер, определен за потребителя",
  dataPrivacyHeader: "Поверителност на данни",
  dataPrivacyText: "Отрязък от текст (съдържанието следва)",
  dataPrivacyTitle: "Заглавие на отрязъка",
  dataRelatedToMe: "Преглед на съхранени за мен данни",
  dataRelatedToMeTitle: "Съхранени данни",
  deleteText: "Сигурни ли сте, че искате да изтриете това",
  downloadDataRelatedToMe: "Изтегляне на съхранени данни",
  fileWithUserInformation: "Файл с потребителска информация",
  forgotPassword: "Забравена парола?",
  forgotPasswordText:
    "Моля въведете Вашия имейл адрес, за да получите линк за нулиране на Вашата парола.",
  importUsers: "Импортиране на потребител",
  newHere: "Вие сте нов потребител?",
  newPassowrd: "Нова парола",
  newPasswordShort: "Новата парола е твърде кратка",
  password: "Парола",
  passwordCantBeEmpty: "Паролата не бива да е незапълнена",
  passwordsNotMatch: "Паролите не съвпадат",
  pickUser: "Изберете потребител",
  pleaseFillUpAllFields: "Моля, попълнете всички полета",
  pleaseTypeNewPassword: "Моля, въведете Вашата нова парола",
  policyAcceptance: "Приемане на правилата",
  policyAcceptanceSave: "Приемане",
  acceptPolicies: "Моля, приемете правилата",
  profile: "Профил",
  role: "Роля",
  roleExpiresOn: "Ролята изтича на",
  saveDataAboutMe: "съхранени за мен данни",
  signIn: "Регистриране",
  termsConditionsHeader: "Общи търговски условия",
  termsConditionsText: "Отрязък от текст (съдържанието следва)",
  termsConditionsTitle: "Заглавие на отрязъка",
  typeInOne: "Въведете едно за всички",
  username: "Потребителско име",
  firstname: "Име",
  lastname: "Фамилия",
  usernameMail: "Потребителско име или имейл",
  userNotes: "Бележки",
  userProfile: "Профил на потребителя",
  userStatus: "Статус на потребителя",
  wrongCurrentPassword: "Грешна актуална парола",
  fillAllFields: "Моля, попълнете всички необходими полета",
  wrongUsernameOrPassword: "Потребителско име или парола грешно",
  minimumLength: "минимална дължина 8",
  atLeastOneBigAndSmall: "минимум 1 малка и 1 голяма буква",
  passwordNotEqualUsernameAndEmail:
    "паролата не е идентична с потребителското име и имейл адреса",
  registrationKey: "Ключ за регистрация",
  registration: "Регистрация",
  notAnEmailAdress: "Моля, въведете валиден имейл адрес.",
  company: "Компания",
  companyHint: "Компания",
  birthday: "Рожден ден",
  birthdayHint: "Рожден ден",
  accountName: "Име на акаунт",
  accountType: "Вид акаунт",
  accountAddress: "Адрес на титуляра на акаунта",
  logo: "Лого",
  address: "Адрес",
  contry: "Държава",
  zipCode: "Пощенски код",
  lessThan: "по-малко от 1mb",
  defaultLanguage: "Език по подразбиране",
  accountStatus: "Статус на акаунта",
  accountNotes: "Бележки към акаунта",
  createAccount: "Създаване на акаунт",
  updateAccount: "Обновяване на акаунт",
  fileToImport: "Бележки към акаунта",
  importStructure: "Импортиране на структура",
  addRootAccount: "Добавяне на акаунт с базово ниво",
  usersPerMonth: "Месечни потребители",
  usersAdded: "Добавени потребители",
  usersDeleted: "Изтрити потребители",
  usersTotal: "Потребители общо",
  city: "Град",

  knowledge: "ЗНАНИЯ",
  toolbox: "ИНСТРУМЕНТАРИУМ",
  lessonCompleted: "Урокът е приключен",
  lessonFailed: "Урокът не е приключен",
  repeatLesson: "Моля, повторете урока, за да продължите",
  congratulations: "Искрени поздравления!",
  courseCompleted: "Завършен курс",
  percentCompleted: "Вие отговорихте правилно на $successRate% от въпросите.",
  downloadCertificate:
    "Вие може да изтеглите по всяко време Вашия сертификат в профила от поле „Сертификати“ и да го изискате по имейл.",
  trueFalse: "Грешно или правилно?",
  seconds: "Секунди",
  noChoiceText: "Жалко, твърде късно.",
  right: "Правилно!",
  wrong: "Неправилно",
  progress: "$current от $total",

  addCourseContent: "Добавяне съдържания на курс",
  addSemicolon: "Добавете надписи чрез точка и запетая разделно",
  addToProgram: "Добавяне към програмата",
  allAccounts: "Всички акаунти",
  certificate: "Сертификат",
  certificates: "Сертификати",
  completed: "Завършен",
  contentIsLoaded: "Съдържанието е заредено",
  contentManager: "Управление на съдържанието",
  courseCompleted: "Завършен курс",
  courseContent: "Съдържание на курс",
  courseDocuments: "Документи",
  courseLables: "Етикети за курс",
  courseLanguage: "Език за курса",
  courseNotes: "Бележки за курса",
  courseStructure: "Структура",
  courseTags: "Дни на курса",
  courseTitle: "Име на курса",
  createCourse: "Създаване на курс",
  curator: "КУРАТОР",
  description: "Описание",
  details: "Детайли",
  dueDate: "Дължим до",
  duration: "Продължителност",
  exportStatistics: "Експортиране на статистики",
  learningStandard: "Учебен стандарт",
  licenses: "Лицензи",
  mandatory: "Задължителен курс",
  noAssessment: "В момента няма оценка за преглеждане.",
  noCoursesAssigned:
    "За Вашия акаунт няма разпределени курсове. Моля, посетете",
  noCoursesAvailable: "В момента няма на разположение курсове.",
  noDueDate: "Няма крайна дата",
  overview: "ПРЕГЛЕД",
  page: "",
  pickAnAccount: "Избери акаунт",
  printCertificate: "Разпечатване на сертификат",
  publishCourse: "Публикуване на курс",
  publishedToAccount: "Публикуван в акаунта",
  relatedCourses: "Подобни курсове",
  remove: "Премахване",
  removeFromProgram: "Премахване от програмата",
  singleAccount: "Отделен акаунт",
  startCourse: "Стартиране",
  successMessageOne: "Поздравления, Вие завършихте ",
  successMessageThree:
    "Можете да изтеглите сега Вашия сертификат за завършване или да го разпечатате. ",
  successMessageTwo: " успешно курса!",
  tags: "Ключови думи",
  unpublishCourse: "Отмяна на публикация",
  updateCourseContent: "Качване на съдържания на курс",
  updateCourse: "Актуализиране на курс",
  youDidntfinishCourse: "Още не си завършил нито един курс.",
  courseVisibleFor: "Курс, видим за",
  accountStructureItem: "Отдел",
  courseAssigmentOptions: "Опции за разпределяне",
  assignCourse: "Разпределяне на курс",
  pickACourse: "Избери курс",
  noDocumentsAvailable: "не са налични документи",
  nothingRelated: "не е налично друго подобно",
  nothingWritten: "не е записано",
  noTagsAvailable: "не са налични ключови думи",
  alreadyGlobal: "Курсът е вече глобално публикуван.",
  questionsanswered: "Въпроси",
  completedOn: "Завършен на",
  locale: "bg-BG",
  quizAuthorizationContent: "Оторизиране за викторина",
  courseNotPublished: "Понастоящем курсът не е публикуван",
  courseNotPublishedForAccount:
    "Понастоящем курсът не е публикуван за акаунта $account",
  clickToPublishCourse: "Публикуване на курс",
  visible: "Видим",
  userIsNotContentManager:
    "Вие не сте мениджър, управляващ съдържанието, и затова не може да обработвате съдържанието на курса.",
  assignmentOpen: "Отворен",
  assignmentStarted: "Стартиран",
  assignmentCompleted: "Завършен",
  dueDatePassed: "Крайната дата е надвишена",
  exitCourse: "Завършване на курс",
  markAsFinished: "Отбелязване като обработено",
  system: "Система",
  configureLogging: "Конфигуриране на протоколи",
  createAccount: "Създаване на акаунт",
  changeAccount: "Промяна на акаунт",
  deleteAccount: "Изтриване на акаунт",
  importAccountStructure: "Импортиране на структура на акаунт",
  createUser: "Създаване на акаунт",
  importUser: "Импортиране на потребители",
  changeUser: "Промяна на потребители",
  deleteUser: "Изтриване на потребители",
  assignUserRole: "Разпределяне на роля",
  resetPassword: "Нулиране на парола",

  createCourse: "Създаване на курс",
  changeCourse: "Промяна на курс",
  addQuizContent: "Добавяне на съдържание на викторина",
  changeQuizContent: "Промяна на съдържание на викторина",
  addContent: "Добавяне на съдържание на курс",
  changeContent: "Промяна на съдържание на курс",
  deleteCourse: "Изтриване на курс",
  deleteContent: "Изтриване на съдържание на курс",
  publishCourse: "Публикуване на курс",
  unpublishCourse: "Непубликуван",
  assignCourse: "Разпределяне на курс",
  unassignCourse: "Отмяна на разпределянето на курс",

  systemMessages: "Системни съобщения",

  learningManagement: "Управление на обучението",
  courseManagement: "Управление на курс",
  userManagement: "Управление на потребителите",
  organizationStructure: "Организационна структура",
  accountManagement: "Управление на акаунти",
  system: "Система",

  learningManagementDetail: "Учебни планове и разпределяне на курс",
  courseManagementDetail: "Управление на съдържанието на курс",
  userManagementDetail: "Управление на потребители и нулиране на пароли",
  organizationStructureDetail: "Дефиниране на организационна структура",
  accountManagementDetail: "Управление на акаунти",
  logsDetail: "Системни логове и настройки",

  userCreateSuccess: "Успешно създаване на потребител",
  createOtherUser: "Добавяне на друг потребител",
  backToUserOverview: "Назад към преглед на потребители",
  importMoreUsers: "Импортиране на други потребители",
  currentRoles: "Актуални роли",
  numberOfRoles: "Брой роли",
  numberOfUsers: "Потребители",
  deleteAccountMessage:
    "Моля потвърдете, че искате да изтриете акаунта '$account'.",
  showStatistics: "Показване на статистики",
  appendAccount: "Добавяне на акаунт към $account.",

  courseAssignments: "Разпределен",
  courseAssignmentsForCourse: "Разпределяне на курс за $course",
  mandatoryAssignments: "Разпределяне на задължителен курс",
  dueDateAssignments: "Със срок",
  dueDatePassed: "Дължим",
  userOrAccount: "Потребител или отдел",
  expiryDate: "Изтичане на срок",
  deletePublication: "Изтриване на публикация",
  deletePublicationMessage:
    "Сигурни ли сте, че искате да изтриете публикацията за акаунта $account?",
  orgStructure: "Отдел",
  type: "Тип",
  downloadTemplate: "Изтегляне на образец",
  published: "Публикуван",
  scorm: "SCORM",
  quiz: "Викторина",
  staticFile: "Pdf",
  editPublication: "Обработване на публикация",
  pleaseDefineCourseContent:
    "please define the course content before publishing it.",

  usernameMissing: "Липсва потребителско име",
  usernameError:
    "Потребителското име може да се състои само от буквено-цифрови знаци",
  emailMissing: "Липсва имейл",
  emailError: "Няма валиден имейл адрес",
  passwordMissing: "Липсва парола",
  passwordError:
    "Паролата трябва да е минимум от 8 знака, както и да съдържа една голяма и една малка буква.",

  registrationKeyManagement: "Ключ за регистрация",
  registrationKeyManagementDetail: "Управление на ключове за регистрация",
  addRegistrationKey: "Добавяне на ключ за регистрация",
  registrationLink: "Линк за регистрация",
  createKey: "Добавяне",

  userState: "Състояние на потребителя",
  userStateDetail: "Разрешително за достъп и задължителни курсове.",
  testsPassed: "Тестовете преминаха",
  access: "Достъп",
  downloadReport: "Доклад за изтегляне",
  success: "Съхранен успешно!",
  warning: "Внимание!",
  error: "Грешка!",
  removedFromProgram: "Този курс е премахнат от Вашата програма",
  addedToProgram: "Този курс е добавен във Вашата програма",
  courseCreated: "Курсът беше създаден",
  courseNotCreated: "Курсът не беше създаден",
  fillUpFields: "Моля, попълнете всички задължителни полета",
  cookieNotAvailable:
    "Вашата информация за влизане не е налична или твърде стара, моля влезте отново",
  courseNotFinished: "Не е налична информация за завършване на курса",
  courseAborted: "Курсът беше прекъснат",
  correctAnswer: "Правилен отговор",
  wrongAnswer: "Грешен отговор",
  courseStarted: "Курсът беше стартиран",
  testsPassed: "Издържан тест",
  testFailed: "Неиздържан тест",
  courseCompleted: "Курсът беше завършен успешно",
  imageTooLarge: "Файлът трябва да е с размер по-малко от 2mb",
  wrongFormat: "Грешен формат",
  passwordReset:
    "Изпратен е линк за нулиране на паролата на посочения имейл адрес",
  passwordChanged: "Паролата беше променена",
  accountAdded: "Акаунтът беше добавен",
  accountUpdated: "Акаунтът беше актуализиран",
  accountDeleted: "Акаунтът беше изтрит",
  userImported: "Потребителите бяха импортирани",
  userNotImported: "Потребителите не бяха импортирани",
  loggingConfigSaved: "Конфигурацията за влизане беше съхранена",
  userDeleted: "Потребителят беше изтрит",
  userAdded: "Потребителят беше добавен",
  userUpdated: "Потребителят беше актуализиран",
  GDPRLoaded: "ОРЗД е зареден",
  cookiesDisabled: "Вие деактивирахте бисквитите",
  wrongCredentials: "Грешни данни за влизане",
  fileUploaded: "Вашият файл беше качен",
  courseContentUpdated: "Съдържанието на курса беше актуализирано",
  coursePublished: "Курсът беше публикуван",
  courseUnPublished: "Публикуването на курса беше отменено.",
  serverUnreachable: "Сървърът не е достъпен",
  selectAccounts: "Избиране на акаунт",
  selectAccountToContinue: "Избиране на акаунт, за да продължите напред",
  accountOverview: "Преглед на акаунт",
  users: "Потребител",
  courses: "Курсове",
  coursesCompleted: "Завършени курсове",
  completedCoursesPerUser: "Завършени курсове за потребител",
  learningHours: "Учебни часове",
  learningHoursPerUser: "Учебни часове за потребител",
  courseOverview: "Преглед на курс",
  completed: "Завършен",
  assigned: "Разпределен",
  courseDetails: "Детайли",
  selectCourse: "Избиране на курс",
  details: "Избиране",
  type: "Тип",
  dueDate: "До",
  mandatory: "Задължителен курс",
  completionRate: "Процент завършващи",
  averageScore: "Резултати от тест",
  duration: "Продължителност",
  averageDuration: "Средна продължителност",
  account: "Акаунт",
  accountName: "Име на акаунта",
  accounts: "Акаунти",
  active: "Активен",
  admin: "Администратор",
  all: "Всички",
  not_found: "Страницата не съществува",
  assessmentStatistics: "Статистики на оценки",
  assignedUsersOnly: "Само разпределени потребители",
  autoIndividual: "Автоматично, индивидуално",
  cancel: "Прекъсване",
  cardImage: "Изображение на карта",
  close: "Затваряне",
  completed: "Завършен",
  confirm: "Потвърждаване",
  country: "Държава",
  course: "Курсове",
  courseDescription: "Описание на курс",
  courseDocuments: "Документи за курс",
  courses: "Библиотека",
  courseStructure: "Структура на курс",
  dataProtection: "Защита на данни",
  defaultLanguage: "Стандартен език",
  deleteText: "Сигурни ли сте, че искате да изтриете това",
  delete: "Изтриване",
  details: "Детайли",
  dragAndDrop: "Влачете и пуснете / Кликнете за добавяне",
  dueDate: "Дължим на",
  email: "Имейл",
  empty: "Празен",
  exit: "Отписване",
  expiresOn: "Падеж на",
  fileImport: "Файл за импортиране",
  image: "Изображение",
  inactive: "Неактивен",
  language: "Език",
  logo: "Лого",
  mandatory: "Задължителен курс",
  monthsLabels: [
    "Януари",
    "Февруари",
    "Март",
    "Април",
    "Май",
    "Юни",
    "Юли",
    "Август",
    "Септември",
    "Октомври",
    "Ноември",
    "Декември",
  ],
  myAccount: "Моят акаунт",
  myProfile: "Моят профил",
  myProgram: "Моята програма",
  myRoles: "Моите роли",
  never: "Никога",
  no: "Не",
  none: "Никой",
  notFound: "Не е намерен",
  phoneNumber: "Телефонен номер",
  playImage: "Възпроизвеждане на изображение",
  publishingExpiresOn: "Публикацията изтича на",
  publishTo: "Публикуван в",
  reports: "Доклад",
  save: "Съхраняване",
  search: "Търсене…",
  status: "Статус",
  structureItem: "Отдел",
  termsAndConditions: "Търговски условия",
  unlimited: "Неограничено",
  update: "Актуализиране",
  user: "Потребители",
  username: "потребителско име",
  users: "Потребители",
  yes: "Да",
  zipCode: "Пощенски код",

  info: "Информация",
  error: "Грешка",
  alert: "Аларма",
  criticalAlert: "Критична аларма",
  navigation: "Навигация",
  system: "Система",
  note: "Указание",
  userProfile: "Профил на потребителя",

  userId: "Потребител Идентификационен номер",
  customId: "",
  organizationalStructure: "Организационна структура",
  created: "Създаден на",
  new: "Ново",
  noResults: "Няма резултати",
  noData: "Няма данни",
  uploadFile: "Качване на файл",
  id: "Идентификационен номер",
  name: "Име",
  append: "Добавяне",
  edit: "Обработване",
  format: "Формат",
  minutes: "Минути",
  hours: "Часове",
  date: "Дата",
  message: "Съобщение",
  fax: "Факс",

  genericError:
    "Възникна грешка, опитайте да заредите отново страницата. Ако грешката все още е налице, свържете се със системния администратор.",
  action: "Акция",

  uploadedCertificates: "Качени сертификати",

  add_mandatory_user_property: "Добавете задължително свойство на потребителя",
  help: "Помогне",
  alreadyRegistered: "Вече е регистриран",
  additionalDetails: "Допълнителни детайли",
  additionalDetailsSave: "Потвърдете",
  dragAndDropCertificate:
    "Влачете и пуснете PDF файла тук или кликнете, за да добавите",
  coverPhoto: "Cover Photo",
  EditCoverPhoto: "Edit Cover Photo",
  AddCoverPhoto: "Add Cover Photo",
  additionalDocuments: "Additional Documents",
  editAdditionalDocuments: "Edit Additional Documents",
  addAdditionalDocuments: "Add Additional Documents",
  fileName: "File name",
  fileSize: "File size",
  userProfileV2: "User profile V2",
  certification: "Certification",
  issuedBy: "Issued by",
  issuedOn: "Issued on",
  fulfilledInPercent: "Fulfilled in percent",
  systemLogs: "System Logs",
  systemConfigurationDetail: "Configure system settings",
  systemConfiguration: "System configuration",
  courseFormat: "Course formats",
  views: "Views",
  apis: "APIs",
  logs: "Logs",
  viewsDescription: "Visibility of the element in the respective view",
  courseFormatDescription: "Available course formats for defining the course",
  accessCard: "Access card",
  downloadCertification: "Download Certification",

  logout: "Изход",
  continue: "Продължи",
  courseFinishedDoYouWantToLogout:
    "Курсът е завършен. Искате ли да излезете или да продължите? Ще бъдете автоматично излезли след {seconds}.",
  stateless: "без гражданство",
};
