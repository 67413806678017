<template lang="pug">
.editor-container
  QuillEditor(
    ref="editor",
    v-model:content="content",
    content-type="html",
    :toolbar="toolbarOptions",
    theme="snow",
    :modules="modules"
  )
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import BlotFormatter from "quill-blot-formatter/dist/BlotFormatter";

export default {
  components: {
    QuillEditor,
  },
  props: {
    modelValue: String,
    allowImages: Boolean,
  },
  data: function () {
    return {
      toolbarOptions: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction

        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        // [{ 'font': [] }],
        [{ align: [] }],

        ["clean"], // remove formatting button
        this.allowImages ? ["image"] : undefined,
      ],
      modules: this.allowImages
        ? {
            name: "blotFormatter",
            module: BlotFormatter,
            options: {
              /* options */
            },
          }
        : undefined,
    };
  },

  computed: {
    content: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },

  methods: {
    focus() {
      this.$refs.editor.focus();
    },
  },
};
</script>
