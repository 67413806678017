import axios from "axios";
import { mapResponse, printError } from "../utils/mapResponse";
import api from "./config";

export default {
  getApiSystemSettings() {
    return axios.get(`${api.systemConfigurationServices}apis/`);
  },
  setApiSystemSettings(content) {
    return axios.put(`${api.systemConfigurationServices}apis/`, content);
  },
  getAvailableApis() {
    return axios.get(`${api.apiAdmin}`);
  },
  getApiKeys() {
    return axios.get(`${api.apiAdmin}keys/`);
  },
  createApiKey(key) {
    return axios.post(`${api.apiAdmin}keys/`, key);
  },
  updateApiKey(id, key) {
    return axios.put(`${api.apiAdmin}keys/${id}`, key);
  },
  deleteApiKey(id) {
    return axios.delete(`${api.apiAdmin}keys/${id}`);
  },
};
