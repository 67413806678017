// A list of existing roles: see https://github.com/inctec/elearning-portal/wiki/Roles for clarification
// NOTE: When adding a role, also add a role description in de/en with name <>
export default {
  admin: 1,
  global_admin: 2,
  global_content_admin: 3,
  user: 4,
  account_owner: 5,
  account_content_manager: 6,
  account_reporting_user: 7,
  assessment_expert: 8,
  global_user_state_manager: 9,
  account_user_state_manager: 10,
  // account_user_manager: 11,
  global_user_manager: 12,
  event_planner: 13,
  trainer: 14,
  tester: 15,
  account_disponent: 16,
  global_monitor: 17,
};

export const roleNames = {
  1: "Admin",
  2: "Global admin",
  3: "Global content admin",
  4: "User",
  5: "Account owner",
  6: "Account content manager",
  7: "Account reporting",
  8: "Assessment Expert",
  9: "User state manager (global)",
  10: "User state manager (account)",
  // account_user_manager: 11,
  12: "Global user manager",
  13: "Event planner",
  14: "Trainer",
  15: "Tester",
  16: "Account Disponent",
  17: "Global Monitoring",
};
