import find from "lodash.find";
import flatten from "lodash.flatten";

import services from "@/services/quizzes";
import { mapResponse, printError } from "../utils/mapResponse";

export default {
  state: {
    quizId: null,
    userQuizId: null,
    quiz: {},
    lessons: [],
    chapters: [],
    quizTitle: null,
    quizCompany: null,
    quizDescription: null,
    quizCompleted: false,
    curatorName: null,
    curatorCompany: null,
    curatorText: null,
    curatorPhoto: null,
    numberOfLessonsInChapter: null,
  },
  mutations: {
    initQuiz(state, quiz) {
      state.quiz = quiz;
      state.quizId = quiz.id;
      state.userQuizId = quiz.id;
      state.quizTitle = quiz.title;
      state.quizCompany = quiz.company;
      state.quizDescription = quiz.description;
      state.quizCompleted = quiz.completed;
      state.curatorName = quiz.curator ? quiz.curator.name : "";
      state.curatorCompany = quiz.curator ? quiz.curator.company : "";
      state.curatorText = quiz.curator ? quiz.curator.description : "";
      state.curatorPhoto =
        quiz.curator && quiz.curator.photo ? quiz.curator.photo.documentId : "";
      const chapters = quiz.chapters;
      if (chapters && chapters.list) {
        state.chapters = chapters.list;
        const ls = flatten(
          chapters.list
            .map((c) => {
              if (c.lessons && c.lessons.list) {
                return c.lessons.list;
              }
              return null;
            })
            .filter((e) => e),
        );
        state.lessons = ls;
      } else {
        state.chapters = [];
        state.lessons = [];
      }
    },
    initLesson(state, lesson) {
      const ls = state.lessons.filter((l) => l.id !== lesson.id);
      ls.push(lesson);
      state.lessons = ls;
      // // also update chapters
      // state.chapters
    },
    initNumberOfLessonsInChapter(state, numberOfLessonsInChapter) {
      state.numberOfLessonsInChapter = numberOfLessonsInChapter;
    },
  },
  actions: {
    initQuizById({ commit }, quizId) {
      return services.getUserQuizById(quizId).then((quiz) => {
        commit("initQuiz", quiz);
        return quiz;
      });
    },
    initLessonById({ commit }, { lessonId, stepId, joker }) {
      const fiftyFiftyJoker = joker === "fifty-fifty-joker";
      const audienceJoker = joker === "audience-joker";
      return services
        .getUserLessonById({ lessonId, stepId, fiftyFiftyJoker, audienceJoker })
        .then(({ lesson, meta }) => {
          commit("initLesson", lesson);
          if (meta && meta.numberOfLessonsInChapter) {
            commit(
              "initNumberOfLessonsInChapter",
              meta.numberOfLessonsInChapter,
            );
          }
          return lesson;
        });
    },
    answerQuestion({ commit }, { lessonId, stepId, choiceId, optionId }) {
      const action = { choiceId, optionId };
      return services.saveSelection(lessonId, stepId, action).then((data) => {
        // data contains the lesson information
        commit("initLesson", data);
        return data;
      });
    },
  },
  getters: {
    getQuiz: (state) => state.quiz,
    getChapters: (state) => state.chapters,
    getLessons: (state) => state.lessons,
    getNumberOfLessonsInChapter: (state) => state.numberOfLessonsInChapter,
    getLessonByUuId: (state) => (id) => {
      const lessons = state.lessons;
      return find(lessons, (o) => o.uuid === id);
    },
    getQuizId: (state) => state.quizId,
    getUserQuizId: (state) => state.userQuizId,
    getQuizTitle: (state) => state.quizTitle,
    getQuizCompany: (state) => state.quizCompany,
    getQuizDescription: (state) => state.quizDescription,
    getQuizCompleted: (state) => state.quizCompleted,
    getCuratorName: (state) => state.curatorName,
    getCuratorCompany: (state) => state.curatorCompany,
    getCuratorText: (state) => state.curatorText,
    getCuratorPhoto: (state) => state.curatorPhoto,
  },
};
