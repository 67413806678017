<template lang="pug">
.user-details(v-loading="loading")
  h2 {{ $t("profile") }}
  .profile-input-grid
    label.user-profile-field__label.mr-1(for="avatar") {{ $t("profile_image") }}
    .profile-picture-settings
      avatar#avatar(:size="32", :username="profile ? profile.username : ''")
      .image-upload-container
        el-button(icon="Upload")
          //- upload-icon.mr-1
          span {{ $t("upload_picture") }}
        input.file-upload(
          type="file",
          @change="uploadUserProfile($event.target.files)"
        )
      el-button.fill-width(@click="cameraVisible = true") {{ $t("take_picture") }}
      el-button.fill-width(
        v-loading="deletePictureLoading",
        type="danger",
        :disabled="deletePictureLoading",
        @click="deleteProfilePicture"
      ) {{ $t("delete") }}
    label(for="language")
      | {{ $t("defaultLanguage") }}
    language-select#language.mr-1(v-model="currentLang")

  el-table(:data="filteredList", style="width: 100%")
    el-table-column(prop="key")
      template(#default="scope")
        span(v-if="scope.row.key") {{ $t(`${scope.row.key}`) }}

    el-table-column(prop="value")
      template(#default="scope")
        CountryView(
          v-if="scope.row.key === 'country'",
          :code="scope.row.value"
        )
        span(v-else) {{ scope.row.value }}

  modal(v-if="cameraVisible", @close="cameraVisible = false")
    camera.mb-1(ref="camera")
    template(#footer)
      el-button(type="primary", @click="takePicture()") {{ $t("take_picture") }}
</template>

<script>
import userApi from "@/services/users";
import Camera from "./Camera";
import LanguageSelect from "@/components/elements/LanguageSelect";
import userService from "@/services/users";
import Avatar from "@/components/usermenu/Avatar";
import {
  stateError,
  stateInitial,
  stateLoading,
  stateLoaded,
} from "../../store/loadingStates";
import { mapGetters } from "vuex";
import formatDate from "@/utils/formatDate";
import CountryView from "../elements/CountryPicker/CountryView.vue";

export default {
  name: "UserDetails",
  components: {
    Camera,
    LanguageSelect,
    Avatar,
    CountryView,
  },
  props: {},
  data() {
    return {
      cameraVisible: false,
      deletePictureLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      userData: "getUserData",
      userState: "user/userState",
      userStateLoadingState: "user/userStateLoadingState",
    }),
    loading() {
      return this.userStateLoadingState.loading;
    },
    profile() {
      return this.userStateLoadingState.result;
    },
    attributes() {
      return this.userStateLoadingState.result.attributes;
    },
    filteredList() {
      if (!this.userStateLoadingState.loaded) {
        return [];
      }
      // NOTE: if qGate data exists, it should override the profile data
      const profile = this.profile.qGate
        ? { ...this.profile, ...this.profile.qGate }
        : this.profile;
      const list = [
        {
          key: "username",
          value: profile.username,
        },

        {
          key: "firstname",
          value: profile.extras.attributes.firstname,
        },
        {
          key: "lastname",
          value: profile.extras.attributes.lastname,
        },
        {
          key: "email",
          value: profile.email,
        },
        {
          key: "phoneNumber",
          value: profile.phone,
        },
        {
          key: "address",
          value:
            profile.extras.attributes.address ??
            profile.extras.attributes.address1,
        },
        {
          key: "city",
          value:
            profile.extras.attributes.city ??
            profile.extras.attributes.address2,
        },
        {
          key: "zipCode",
          value: profile.extras.attributes.zipCode,
        },
        {
          key: "country",
          value: profile.extras.attributes.country,
        },
        {
          key: "birthday",
          value: formatDate(
            profile.extras.attributes.birthdate ??
              profile.extras.attributes.birthdate,
          ),
        },
        {
          key: "company",
          value: profile.extras.attributes.company,
        },
        {
          key: "department",
          value: profile.extras.attributes.department,
        },
        {
          key: "ID",
          value: profile.extras.attributes.customId,
        },
        {
          key: "emergencyContact",
          value: profile.extras.attributes.emergencyContact,
        },
        {
          key: "emergencyContactPhone",
          value: profile.extras.attributes.emergencyContactPhone,
        },
      ];
      return list.filter(
        (item) =>
          item.value !== null && item.value !== undefined && item.value !== "",
      );
    },
    currentLang: {
      get() {
        if (this.userStateLoadingState.loaded) {
          console.log(this.userState);
          return this.userState.extras.attributes.langCode;
        }
        return "de";
      },
      set(value) {
        if (!this.userStateLoadingState.loaded) {
          return;
        }
        if (value !== this.userState.extras.attributes.langCode) {
          const langCode = value.toLowerCase();
          this.$i18n.locale = langCode;
          document.documentElement.setAttribute("lang", langCode);

          this.$store
            .dispatch("updateUserInformation", {
              // NOTE: For unknown reasons, the backend requires the email to be set
              email: this.profile.email,
              attributes: {
                ...this.profile.extras.attributes,
                langCode,
              },
            })
            .then(() =>
              this.$store.dispatch("user/loadUserState", {
                id: this.userData.id,
                keepCache: false,
              }),
            );
        }
      },
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
  methods: {
    uploadUserProfile(files) {
      const file = files[0];
      const formData = new FormData();
      formData.append("files", file, file.name);
      userService.SET_PROFILE_PICTURE(this.userData.id, formData).then(() => {
        this.$store.commit("reloadProfilePicture");
        this.$store.commit("addNewNotification", {
          title: "success",
          text: "fileUploaded",
          notificationType: "success",
        });
      });
    },
    deleteProfilePicture() {
      this.deletePictureLoading = true;
      this.$store
        .dispatch("deleteProfilePicture")
        .then(() => {
          this.$store.commit("addNewNotification", {
            title: "success",
            notificationType: "success",
          });
        })
        .finally(() => {
          this.deletePictureLoading = false;
        });
    },
    takePicture() {
      const camera = this.$refs.camera;
      if (camera) {
        const snapshot = camera.capture();

        // Encode the base64 string as byte array
        const splitDataURI = snapshot.split(",");
        const byteString =
          splitDataURI[0].indexOf("base64") >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ia], { type: mimeString });

        const formData = new FormData();
        formData.append("files", blob);

        userService.SET_PROFILE_PICTURE(this.userData.id, formData).then(() => {
          this.$store.commit("reloadProfilePicture");
          this.cameraVisible = false;
          this.$store.commit("addNewNotification", {
            title: "success",
            text: "fileUploaded",
            notificationType: "success",
          });
        });
      }
    },
    // NOTE: currently the user can't update their data
    updateProfile() {
      this.$store
        .dispatch("updateUserInformation", {
          email: this.profile.email,
          attributes: {
            ...this.userData.attributes,
            phone: this.currentPhoneNumber,
          },
        })
        .then(this.close)
        .catch((error) => {});
      // this.$store.commit('setUserRole', this.role);
    },
    loadData() {
      this.$store.dispatch("user/loadUserState", {
        id: this.userData.id,
        keepCache: false,
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../settings.styl';


.profile-input-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 1.5rem;
  }

.user-details {
  position: relative;
  }


#avatar {
    min-width: 32px;
}

.profile-picture-settings {
    display: flex;
    align-items: center;
}

.profile-picture-settings > * + * {
    margin-left: 1rem;
    align-items: center;
}

.image-upload-container {
    position: relative;
    width: 100%;
    height: 40px;
    min-width: 150px;
}

.image-upload-container > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    width: 100%;
}

.image-upload-container input {
    opacity: 0;
}


@media (max-width: 959px) {
    .profile-picture-settings {
        display: flex;
        flex-direction column;
    }

    .profile-picture-settings > * + * {
        margin-left: 0;
        margin-top: 1rem;
    }
}
</style>
