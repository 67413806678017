<template lang="pug">
.quiz-step-container(v-loading="data.loadingState.loading")
  .quiz-content
    //- NOTE: for backwards compatibility we check the first character, if it is '<' we assume that this is HTML
    //- and should be displayed as is, if not we assume this is a legacy course which requires styling on the question  
    h1.quiz-step-header.txt-center.txt-accent.mb-3.ql-editor(
      v-if="!data.question.startsWith('<')",
      v-html="data.question"
    ) 
    .ql-editor.mb-3(v-else, v-html="data.question") 
    .quiz-step-answers(v-if="!data.completed")
      .quiz-step-answer.clickable(
        v-for="(choice, index) in data.choices",
        :key="choice.id",
        @click="dispatchChoice(choice.id)"
      ) 
        //- Go through the alphabet
        span.txt-accent.txt-large.mr-2 {{ String.fromCharCode(65 + index) }}
        span.ql-editor(v-html="choice.text")
        span
    .quiz-step-completed(v-else)
      .quiz-response-header(
        :class="{ 'background-success': data.feedback.wasCorrect, 'background-error': !data.feedback.wasCorrect }"
      )
        el-icon(v-if="data.feedback.wasCorrect")
          Check.completed-icon
        el-icon(v-else)
          Close.completed-icon
        span.ql-editor(v-html="data.feedback.selectedOption")
      p.ql-editor(v-html="data.feedback.description")
</template>

<script>
import stepMixin from "./stepMixin";

export default {
  mixins: [stepMixin],
  methods: {
    dispatchChoice(choice) {
      if (this.data.completed) {
        return;
      }
      this.sendAnswer({ choice });
    },
  },
};
</script>

<style lang="stylus">
@import '../../../settings.styl';

.quiz-step-answers {
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 4rem;
  grid-auto-rows: 1fr;
  max-width: 100%;
}

.quiz-step-answer {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: $background;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
  margin: 12px;
  border-radius: 10px 10px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  box-shadow: 0 8px 31px 0 rgba(0, 0, 0, 0.08);
}

.quiz-step-completed {
  margin: auto;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  width: 400px;
  box-shadow: 0 8px 31px 0 rgba(0, 0, 0, 0.08);
}

.quiz-step-completed > p {
  padding: 2rem;
}

.quiz-response-header {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
}

.quiz-response-header i {
  font-size: 32px;
  margin-right: 1rem;
}

.quiz-step-container {
  position: relative;
}

@media screen and (max-width: 500px) {
  .quiz-step-header {
    font-size: 1.5em;
    margin-bottom: 1rem !important;
  }

  .quiz-step-answers {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
  }

  .quiz-step-answer {
    width: 100%;
    padding: 2rem;
    margin: 0;
    justify-content: start;
  }

  .quiz-step-completed {
    width: 100%;
  }
}
</style>
