const DataQuality = () => import("./data-quality/DataQuality.vue");
const MonitoringOverview = () => import("./MonitoringOverview.vue");
const FraudDetectionOverview = () =>
  import("./fraud-detection/FraudDetectionOverview.vue");

export default [
  {
    path: "/monitoring",
    name: "monitoring",
    component: MonitoringOverview,
  },
  {
    path: "/monitoring/fraud-detection",
    name: "fraudDetection",
    component: FraudDetectionOverview,
  },
  {
    path: "/monitoring/data-quality",
    name: "dataQuality",
    component: DataQuality,
  },
];
