import service from "@/services/capabilities";
import roles from "@/utils/roles";
import { mapResponse, printError } from "../utils/mapResponse";
export default {
  state: {
    list: [],
    contentAdminIds: [],
    contentManageIds: [],
    accountManagerId: null,
    currentUsersRolesIds: [],
    isGlobalContentAdmin: false,
    globals: [],
    accountOwnerId: null,
    roleId: null,
    roles: {},
  },
  mutations: {
    initCapabilities(state, payload) {
      state.list = [];
      if (payload && payload.length) {
        state.list = payload;
        state.contentManageIds = [];
        state.globals = [];
        state.accountOwnerId = null;
        // TODO: remove duplicate data, access directly
        payload.forEach((item) => {
          const name = item.name;
          const id = item.id;
          if (id === roles.account_content_manager) {
            // Account Learning Manager (formerly Account Content Manager)
            state.accountManagerId = id;
            state.contentManageIds.push(id);
          }
          if (id === roles.global_content_admin) {
            // Global Content Admin
            state.globalContentAdminId = id;
            state.contentManageIds.push(id);
          }
          if (id === roles.admin || id === roles.global_admin) {
            // Global Admin and Admin
            state.globals.push(id);
          }
          if (id === roles.account_owner) {
            // Account Owner
            state.accountOwnerId = id;
          }
        });
      }
    },
    initUsersRoles(state, payload) {
      const roles = {};
      if (!payload || !payload.length) {
        return;
      }
      payload.forEach((item) => {
        roles[item.roleId] = item.ids;
      });
      state.roles = roles;
    },
    flushRoles(state) {
      state.roles = {};
    },
    setUserRoles(state, ids) {
      if (ids) {
        state.currentUsersRolesIds = ids;
        localStorage.setItem("roleIds", JSON.stringify(ids));
      }
    },
  },
  actions: {
    initUsersRoles({ commit, dispatch }, payload) {
      let capabilities = [];
      if (payload.capabilities && payload.capabilities.length) {
        capabilities = payload.capabilities;
      }
      dispatch("initUserCurrentRole", capabilities);
      commit("initUsersRoles", capabilities);
    },
    initUserCurrentRole({ commit }, payload) {
      let saved = localStorage.getItem("roleIds");
      const roleIds = payload.map((item) => item.roleId);
      saved = saved ? JSON.parse(saved) : false;
      if (!saved || !saved.length) {
        saved = [...roleIds];
      }
      commit("setUserRoles", saved);
    },
    initCapabilities({ commit }) {
      service
        .INIT()
        .then(mapResponse)
        .catch(printError)
        .then((data) => {
          commit("initCapabilities", data);
        });
    },
  },
  getters: {
    globalContentAdminId: (state) => state.globalContentAdminId,
    getAccountManagerId: (state) => state.accountManagerId,
    getRoles: (state) => state.roles,
    getGlobalAdmins: (state) => state.globals,
    getCurrentUsersRolesIds: (state) => {
      const roleIds = state.currentUsersRolesIds;
      const currentRoles = state.roles;
      return roleIds.filter((item) => currentRoles[item]);
    },
    getCurrentRoleId: (state) => state.roleId,
    getContentManagersIds: (state) => state.contentManageIds,
    isAccountOwner: (state) => {
      const id = state.accountOwnerId;
      const currentRoles = state.currentUsersRolesIds;
      return currentRoles.indexOf(id) !== -1;
    },
    isGlobalAdmin: (state) => state.globals.indexOf(state.roleId) !== -1,
    getUsersRoles: (state, getters) => {
      const list = getters.getRolesList;
      return list.filter((item) => {
        const current = state.roles[item.key];
        if (current) {
          return item;
        }
        return false;
      });
    },
    getCurrentCapabilities: (state, getters) => {
      const list = state.list;
      const roleIds = getters.getCurrentUsersRolesIds;
      const result = [];
      list.forEach((item) => {
        if (roleIds.indexOf(item.id) !== -1) {
          item.capabilities.forEach((cap) => {
            if (result.indexOf(cap) === -1) {
              result.push(cap);
            }
          });
        }
      });
      return result;
    },
    getRolesList: (state) =>
      state.list.map((item) => ({
        key: item.id,
        value: item.name,
      })),
    getRoleById: (state, getters) => (id) => {
      const list = getters.getRolesList;
      return list.find((item) => item.key === +id);
    },
    checkCapability: (state, getters) => (title) => {
      const caps = getters.getCurrentCapabilities;

      if (caps && caps.indexOf(title) !== -1) {
        return true;
      }
      return false;
    },
    // NOTE: This is trying to get the account(s) of the user but will return false if the user is not an account owner, for reasons unknown
    getOwnAccountId: (state, getters) => {
      const caps = getters.getCapabilites;
      const accountOwnerId = state.accountOwnerId;
      if (!accountOwnerId) {
        return false;
      }
      let accountId = false;
      Object.keys(caps).some((key) => {
        if (caps[key].indexOf(accountOwnerId) !== -1) {
          accountId = +key;
          return key;
        }
        return false;
      });
      return accountId;
    },
  },
};
