// These states indicate the loading states of requests: initial, loading, loaded and error

export const stateInitial = {
  loading: false,
  loaded: false,
  error: false,
  errorMessage: "",
};

export const stateLoading = {
  loading: true,
  loaded: false,
  error: false,
  errorMessage: "",
};

export const stateLoaded = {
  loading: false,
  loaded: true,
  error: false,
  errorMessage: "",
};

export const stateError = {
  loading: false,
  loaded: false,
  error: true,
  errorMessage: "",
};

export interface LoadState<T> {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  loaded: boolean;
  result?: T;
}

export interface PaginatedLoadState<T> {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  loaded: boolean;
  result?: T;
  query?: object;
  page?: number;
  pagesTotal?: number;
  total?: number;
}

export type Pagination = {
  total: number;
  pagesTotal: number;
};

export type Paginated<T> = Pagination & {
  page: number;
  pageSize: number;
  result: T;
};
