import { createRouter, createWebHistory } from "vue-router";
const Login = () => import("@/components/auth/AuthPage.vue");
const Home = () => import("@/Home.vue");
const Registration = () => import("@/components/auth/Registration.vue");
const CoursesList = () => import("@/components/courses-list/CoursesList.vue");
const MyCourseList = () => import("@/components/my-course/MyCourseList.vue");
const CoursePage = () => import("@/components/course/CoursePage.vue");
const CompletedList = () => import("@/components/my-course/CompletedList.vue");
const Admin = () => import("@/components/admin/Admin.vue");
const ForgotPassword = () => import("@/components/auth/ForgotPassword.vue");
const NewPassword = () => import("@/components/auth/NewPassword.vue");
const QuizElements = () => import("@/components/quiz/QuizElements.vue");
const QuizStart = () => import("@/components/quiz/QuizStart.vue");
const QuizLesson = () => import("@/components/quiz/QuizLesson.vue");
const QuizQuestion = () => import("@/components/quiz/QuizQuestion.vue");
const QuizChallenge = () => import("@/components/quiz/QuizChallenge.vue");
const QuizQuestionExplanation = () =>
  import("@/components/quiz/QuizQuestionExplanation.vue");
const QuizLessonCompleted = () =>
  import("@/components/quiz/QuizLessonCompleted.vue");
const QuizCompleted = () => import("@/components/quiz/QuizCompleted.vue");
const QuizSurvey = () => import("@/components/quiz/QuizSurvey.vue");
const QuizSurveyAnswer = () => import("@/components/quiz/QuizSurveyAnswer.vue");
const QuizSurveyBody = () => import("@/components/quiz/QuizSurveyBody.vue");

const FlexStart = () => import("@/components/flex/FlexStart.vue");
const FlexView = () => import("@/components/flex/FlexView.vue");
const FlexLessonCompleted = () =>
  import("@/components/flex/LessonCompleted.vue");
const FlexCourseCompleted = () =>
  import("@/components/flex/CourseCompleted.vue");

const FlexEditor = () => import("@/components/flex/FlexEditor.vue");
const FlexTranslations = () => import("@/components/flex/FlexTranslations.vue");
const EventTranslations = () =>
  import("@/components/admin/admin-action/EventTranslations.vue");
const EventCourse = () => import("@/components/course/EventCourse.vue");

const AssessmentList = () =>
  import("@/components/my-course/AssessmentList.vue");

const CovidView = () => import("@/components/covid/CovidView.vue");

const TestCenterView = () =>
  import("@/components/test-center/TestCenterView.vue");

const PrivacyPolicy = () => import("@/components/auth/DataPrivacyPolicy.vue");
const TermsAndConditions = () =>
  import("@/components/auth/TermsConditions.vue");
const NotFound = () => import("@/components/NotFound.vue");

const AutomationCenter = () =>
  import("@/components/admin/admin-action/AutomationCenter.vue");
const Users = () => import("@/components/admin/admin-action/Users.vue");
const SignUpKeys = () =>
  import("@/components/admin/admin-action/SignUpKeys.vue");
const LoginRedirects = () =>
  import("@/components/admin/admin-action/LoginRedirects.vue");
const Accounts = () => import("@/components/admin/admin-action/Accounts.vue");
const Courses = () => import("@/components/admin/admin-action/Courses.vue");
const SystemConfiguration = () =>
  import("@/components/admin/admin-action/SystemConfiguration.vue");
const Organization = () =>
  import("@/components/admin/admin-action/Organization.vue");
const Learning = () => import("@/components/admin/admin-action/Learning.vue");
const AdminProperties = () =>
  import("@/components/admin/admin-action/Properties.vue");
const UserAddEdit = () =>
  import("@/components/admin/admin-action/UserAddEdit.vue");
const UserImport = () =>
  import("@/components/admin/admin-action/UserImport.vue");
const AdminAccountAddEdit = () =>
  import("@/components/admin/admin-action/AccountAddEdit.vue");
const AdminAccountImport = () =>
  import("@/components/admin/admin-action/AccountImport.vue");
const AdminAccountStatistic = () =>
  import("@/components/admin/admin-action/AccountStatistics.vue");
const AdminCoursesAddEdit = () =>
  import("@/components/admin/admin-action/CourseAddEdit.vue");
const AdminCovid = () =>
  import("@/components/admin/admin-action/CovidAdmin.vue");
const AdminQuestionnaire = () =>
  import("../components/admin/admin-action/AdminQuestionnaire.vue");
const AdminQuestionnaireEdit = () =>
  import("@/components/admin/admin-action/AdminQuestionnaireEdit.vue");
const EventDateManagement = () =>
  import("@/components/admin/admin-action/EventDateManagement.vue");
const EventDateExamMarks = () =>
  import("@/components/admin/admin-action/EventDateExamMarks.vue");
const CourseLocks = () =>
  import("@/components/admin/admin-action/CourseLocks.vue");
const AdminTest = () => import("@/components/admin/admin-action/TestAdmin.vue");
const TestcenterTranslations = () =>
  import("@/components/admin/admin-action/TestcenterTranslations.vue");

const AdminUserState = () =>
  import("@/components/admin/admin-action/AdminUserState.vue");
const AdminUserStateDetail = () =>
  import("@/components/admin/admin-action/UserStateDetail.vue");
const AdminApis = () => import("@/components/admin/admin-action/AdminApis.vue");
const ApiKeyAddEdit = () =>
  import("@/components/admin/admin-action/ApiKeyAddEdit.vue");
const CourseAssignmentsAutomation = () =>
  import("../components/admin/admin-action/CourseAssignmentsAutomation.vue");
const AdminCourseAssignmentEdit = () =>
  import("@/components/admin/admin-action/CourseAssignmentEdit.vue");

const SealIcon = () => import("@/components/icons/SealIcon.vue");
const ReportsTest = () => import("@/components/reports/ReportsTests.vue");

const ApiDetails = () =>
  import("@/components/admin/admin-action/ApiDetails.vue");
const Logs = () => import("@/components/admin/admin-action/Logs.vue");
const LogSettings = () =>
  import("@/components/admin/admin-action/LogSettings.vue");

const Report = () => import("@/components/reports/Report.vue");
const ReportOverview = () => import("@/components/reports/ReportOverview.vue");

const HardwareRestrictions = () =>
  import("@/components/admin/admin-action/HardwareRestriction.vue");
const CovidTranslations = () =>
  import("../components/admin/admin-action/CovidTranslations.vue");

const UserStateViewManagement = () =>
  import("../components/admin/admin-action/UserStateViewManagement.vue");

const UserStateViewDetail = () =>
  import("../components/admin/admin-action/UserStateViewDetail.vue");


import store from "@/store";

import monitoringRoutes from "../components/monitoring/router"

export const router = createRouter({
  routes: [
    ...monitoringRoutes,
    {
      path: "/",
      name: "Root",
      component: Home,
    },
    {
      path: "/report",
      name: "ReportOverview",
      component: ReportOverview,
    },
    {
      path: "/report-detail",
      name: "Report",
      component: Report,
    },
    {
      path: "/report/test",
      name: "ReportTest",
      component: ReportsTest,
    },
    {
      path: "/assessmentStatistics",
      name: "AssessmentList",
      component: AssessmentList,
    },
    {
      path: "/tos",
      name: "TermsAndConditions",
      component: TermsAndConditions,
      meta: { public: true },
    },
    {
      path: "/privacy",
      name: "PrivacyPolicy",
      component: PrivacyPolicy,
      meta: { public: true },
    },
    {
      path: "/forgot",
      name: "NewPassword",
      component: NewPassword,
      meta: { public: true },
    },
    {
      path: "/forgot-pass",
      name: "ForgotPassword",
      component: ForgotPassword,
      meta: { public: true },
    },
    {
      path: "/admin/user/edit/:id?",
      name: "AdminUserAddEdit",
      component: UserAddEdit,
      props: true,
    },
    {
      path: "/admin/user/import",
      name: "AdminUserImport",
      component: UserImport,
    },
    {
      path: "/admin/user",
      name: "AdminUsers",
      component: Users,
    },
    {
      path: "/admin/learning",
      name: "AdminLearning",
      component: Learning,
    },
    {
      path: "/admin/properties",
      name: "AdminProperties",
      component: AdminProperties,
    },
    {
      path: "/admin/user-state",
      name: "AdminUserState",
      component: AdminUserState,
    },
    {
      path: "/admin/event",
      name: "AdminEvent",
      component: EventDateManagement,
    },
    {
      path: "/admin/event/exam",
      props: true,
      name: "AdminEventExam",
      component: EventDateExamMarks,
    },
    {
      path: "/admin/apis",
      name: "AdminApis",
      component: AdminApis,
    },
    {
      path: "/admin/automation",
      name: "AutomationCenter",
      component: AutomationCenter,
    },
    {
      path: "/admin/apis/edit/:id?",
      name: "ApiKeyAddEdit",
      component: ApiKeyAddEdit,
      props: true,
    },
    {
      path: "/admin/apis/details/:id?",
      name: "ApiDetails",
      component: ApiDetails,
      props: true,
    },
    {
      path: "/admin/user-state/:id",
      name: "AdminUserStateDetail",
      component: AdminUserStateDetail,
      props: true,
    },
    {
      path: "/admin/user-state-views/edit/:id?",
      name: "UserStateViewDetail",
      component: UserStateViewDetail,
      props: true,
    },
    {
      path: "/admin/user-state-views",
      name: "UserStateViewManagement",
      component: UserStateViewManagement,
    },
    {
      path: "/admin/learning/assign-course/:id/:accountId?",
      name: "AdminCourseAssignmentEdit",
      component: AdminCourseAssignmentEdit,
      props: true,
    },
    {
      path: "/admin/learning/automated-assignments",
      name: "CourseAssignmentsAutomation",
      component: CourseAssignmentsAutomation,
      props: true,
    },
    {
      path: "/admin/learning/course-locks",
      name: "AdminCourseLocks",
      component: CourseLocks,
    },
    {
      path: "/admin/courses/edit/:id?",
      name: "AdminCoursesAddEdit",
      component: AdminCoursesAddEdit,
      props: true,
    },
    {
      path: "/admin/courses",
      name: "AdminCourses",
      component: Courses,
    },
    {
      path: "/admin/organization",
      name: "AdminOrganization",
      component: Organization,
    },
    {
      path: "/admin/account/import",
      name: "AdminAccountImport",
      component: AdminAccountImport,
    },
    {
      path: "/admin/account/edit/:id?",
      name: "AdminAccountAddEdit",
      component: AdminAccountAddEdit,
      props: true,
    },
    {
      path: "/admin/account/statistics",
      name: "AdminAccountStatistic",
      component: AdminAccountStatistic,
    },
    {
      path: "/admin/account",
      name: "AdminAccounts",
      component: Accounts,
    },
    {
      path: "/admin/system",
      name: "AdminSystem",
      component: Logs,
    },
    {
      path: "/admin/hardware",
      name: "HardwareRestrictions",
      component: HardwareRestrictions,
    },
    {
      path: "/admin/system/settings",
      name: "AdminSystemSettings",
      component: LogSettings,
    },
    {
      path: "/admin/questionnaire",
      name: "AdminQuestionnaire",
      component: AdminQuestionnaire,
    },
    {
      path: "/admin/questionnaire/:id",
      name: "AdminQuestionnaireEdit",
      component: AdminQuestionnaireEdit,
      props: true,
    },
    {
      path: "/admin/system-configuration",
      name: "AdminSystemConfiguration",
      component: SystemConfiguration,
    },
    {
      path: "/admin/signup-keys",
      name: "AdminSignUpKeys",
      component: SignUpKeys,
    },
    {
      path: "/admin/signup-keys/login-redirects",
      name: "AdminLoginRedirects",
      component: LoginRedirects,
    },
    {
      path: "/admin/vaccination",
      name: "AdminCovid",
      component: AdminCovid,
    },
    {
      path: "/admin/test",
      name: "AdminTest",
      component: AdminTest,
    },
    {
      path: "/admin",
      name: "Admin",
      component: Admin,
    },
    {
      path: "/courses-list",
      name: "CoursesList",
      component: CoursesList,
    },
    {
      path: "/test-center",
      name: "TestCenter",
      component: TestCenterView,
    },
    {
      path: "/vaccination",
      name: "Vaccination",
      component: CovidView,
    },
    {
      path: "/program",
      name: "MyCourseList",
      component: MyCourseList,
    },
    {
      path: "/completed",
      name: "CompletedList",
      component: CompletedList,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { public: true },
    },
    {
      path: "/registration",
      name: "Registration",
      meta: { public: true },
      component: Registration,
      props: (route) => ({ registrationKey: route.query.key }),
    },
    {
      path: "/registration/:registrationKey",
      name: "RegistrationWithKey",
      meta: { public: true },
      component: Registration,
      props: true,
    },
    {
      path: "/course/:id/:page?",
      name: "CoursePage",
      component: CoursePage,
    },
    {
      path: "/quizelements",
      name: "QuizElements",
      component: QuizElements,
    },
    {
      path: "/quizstart/:quizId",
      name: "QuizStart",
      component: QuizStart,
    },
    {
      path: "/events/:eventId",
      name: "EventCourse",
      component: EventCourse,
      props: true,
    },
    {
      path: "/flex/:id",
      name: "FlexStart",
      component: FlexStart,
      props: true,
    },
    {
      path: "/flex/:flexId/step/:stepId",
      name: "FlexView",
      component: FlexView,
      props: true,
    },
    {
      path: "/flex/:flexId/lesson-completed/:lessonId",
      name: "LessonCompleted",
      component: FlexLessonCompleted,
      props: true,
    },
    {
      path: "/flex/:flexId/course-completed",
      name: "CourseCompleted",
      component: FlexCourseCompleted,
      props: true,
    },
    {
      path: "/flex-editor/:id/",
      name: "FlexEditor",
      component: FlexEditor,
      props: true,
    },
    {
      path: "/flex-translations/:courseId/",
      name: "FlexTranslations",
      component: FlexTranslations,
      props: true,
    },
    {
      path: "/admin/test/translations/",
      name: "TestcenterTranslations",
      component: TestcenterTranslations,
    },
    {
      path: "/event-translations/:eventId/",
      name: "EventTranslations",
      component: EventTranslations,
      props: true,
    },
    {
      path: "/vaccination-translations/",
      name: "covidTranslations",
      component: CovidTranslations,
    },
    {
      path: "/quizlesson/:lessonId",
      name: "QuizLesson",
      component: QuizLesson,
    },
    {
      path: "/quizquestion/:lessonId/:stepId",
      name: "QuizQuestion",
      component: QuizQuestion,
    },
    {
      path: "/quizchallenge",
      name: "QuizChallenge",
      component: QuizChallenge,
    },
    {
      path: "/quizsurvey/:lessonId/:stepId",
      name: "QuizSurvey",
      component: QuizSurvey,
    },
    {
      path: "/quizsurveybody",
      name: "QuizSurveyBody",
      component: QuizSurveyBody,
    },
    {
      path: "/quizsurveyanswer",
      name: "QuizSurveyAnswer",
      component: QuizSurveyAnswer,
    },
    // {
    //   path: '/quizquestionexplanation/:lessonId/:stepId',
    //   name: 'QuizQuestionExplanation',
    //   component: QuizQuestionExplanation,
    // },
    {
      path: "/quizlessoncompleted/:lessonId",
      name: "QuizLessonCompleted",
      component: QuizLessonCompleted,
    },
    {
      path: "/quizcompleted/:quizId",
      name: "QuizCompleted",
      component: QuizCompleted,
    },
    {
      path: "/seal/:quizSuccessRate",
      name: "Seal",
      component: SealIcon,
      props: true,
    },
    {
      path: "/:pathMatch(.*)*",
      name: "404",
      meta: { public: true },
      component: NotFound,
    },
  ],
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  if (!to.meta?.public) {
    const isLoggedIn =
      store.getters.token !== undefined && store.getters.token !== null;
    if (!isLoggedIn) {
      next("/login");
    } else {
      const userSettingsLoadingState =
        store.getters["user/userSettingsLoadingState"];
      if (!userSettingsLoadingState.loaded) {
        await store.dispatch("user/loadUserSettings");
      }
      next();
    }
  } else {
    next();
  }
});
export default router;
