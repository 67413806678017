import getToken from "@/utils/getToken";
import axios from "axios";
import { mapResponse, printError } from "../utils/mapResponse";
import api from "./config";

export default {
  loadFlexMetaData(id) {
    return axios.get(`${api.flexServices}${id}`).then(mapResponse);
  },

  loadFlexView({ flexId, stepId }) {
    return axios
      .get(`${api.flexServices}${flexId}/views/${stepId}`)
      .then(mapResponse)
      .catch(printError);
  },

  postResponse({ flexId, stepId, content }) {
    return axios
      .post(`${api.flexServices}${flexId}/steps/${stepId}`, content)
      .then(mapResponse)
      .catch(printError);
  },

  loadLessonCompleted({ flexId, lessonId }) {
    return axios
      .get(`${api.flexServices}${flexId}/lesson-completed/${lessonId}`)
      .then(mapResponse)
      .catch(printError);
  },

  loadCourseCompleted({ flexId }) {
    return axios
      .get(`${api.flexServices}${flexId}/course-completed`)
      .then(mapResponse)
      .catch(printError);
  },

  loadFullCourse(courseId) {
    return axios
      .get(`${api.flexServices}editor/${courseId}`)
      .then(mapResponse)
      .catch(printError);
  },

  patchCourse(courseId, patch) {
    return axios
      .patch(`${api.flexServices}editor/${courseId}`, patch)
      .then(mapResponse)
      .catch(printError);
  },

  addLesson(courseId, lesson) {
    return axios
      .post(`${api.flexServices}editor/${courseId}/lessons`, lesson)
      .then(mapResponse)
      .catch(printError);
  },

  patchLesson(courseId, lessonId, patch) {
    return axios
      .patch(`${api.flexServices}editor/${courseId}/lessons/${lessonId}`, patch)
      .then(mapResponse)
      .catch(printError);
  },

  addStep(courseId, viewId, step) {
    return axios.post(
      `${api.flexServices}editor/${courseId}/views/${viewId}/steps`,
      step,
    );
  },

  patchStep(courseId, stepId, patch) {
    return axios.patch(
      `${api.flexServices}editor/${courseId}/steps/${stepId}`,
      patch,
    );
  },

  orderSteps(courseId, viewId, stepOrdering) {
    return axios.put(
      `${api.flexServices}editor/${courseId}/views/${viewId}/steps/order`,
      stepOrdering,
    );
  },

  addView(courseId, lessonId, view) {
    return axios.post(
      `${api.flexServices}editor/${courseId}/lessons/${lessonId}/views`,
      view,
    );
  },

  deleteView(courseId, viewId) {
    return axios.delete(
      `${api.flexServices}editor/${courseId}/views/${viewId}`,
    );
  },

  deleteStep(courseId, stepId) {
    return axios.delete(
      `${api.flexServices}editor/${courseId}/steps/${stepId}`,
    );
  },

  deleteLesson(courseId, lessonId) {
    return axios.delete(
      `${api.flexServices}editor/${courseId}/lessons/${lessonId}`,
    );
  },

  patchView(courseId, viewId, patch) {
    return axios.patch(
      `${api.flexServices}editor/${courseId}/views/${viewId}`,
      patch,
    );
  },

  orderViews(courseId, lessonId, order) {
    return axios.put(
      `${api.flexServices}editor/${courseId}/lessons/${lessonId}/views/order`,
      order,
    );
  },

  orderLessons(courseId, order) {
    return axios.put(
      `${api.flexServices}editor/${courseId}/lessonOrder/`,
      order,
    );
  },

  uploadFlexVideo({ courseId, stepId, formData, langCode }) {
    let url = `${api.flexServices}editor/${courseId}/steps/${stepId}/multimedia`;
    if (langCode) {
      const params = new URLSearchParams();
      params.append("language", langCode);
      url = url + "?" + params.toString();
    }
    return axios
      .put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(mapResponse)
      .catch(printError);
  },

  uploadFlexPdf({ courseId, stepId, formData, langCode }) {
    let url = `${api.flexServices}editor/${courseId}/steps/${stepId}/pdf`;
    return axios
      .put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(mapResponse)
      .catch(printError);
  },

  getTranslations(courseId) {
    return axios.get(`${api.flexServices}editor/${courseId}/translation`);
  },

  patchTranslations({ courseId, patches }) {
    return axios.patch(`${api.flexServices}editor/${courseId}/translation`, {
      content: patches,
    });
  },

  UPLOAD_FLEX({ formData, courseId }) {
    return axios
      .post(`${api.flexServices}?courseId=${courseId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(mapResponse)
      .catch(printError);
  },

  getSessionByUserId({ userId }) {
    return axios.get(`${api.flexServices}session/user/${userId}`);
  },

  getSessionByCourseId({ courseId }) {
    return axios.get(`${api.flexServices}session/course/${courseId}`);
  },

  deleteUserSession({ sessionUuid }) {
    return axios.delete(`${api.flexServices}session/${sessionUuid}`);
  },
};
