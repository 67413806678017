const reserveURL = "http://localhost:3000/api/v1/";
const reserveURLv2 = "http://localhost:3000/api/v2/";

const selectedURLs = {
  authURL: "/accounts/api/v1/",
  accountsURL: "/accounts/api/v1/",

  loggingURL: "/elearning/api/v1/",
  coursesURL: "/elearning/api/v1/",
  reportURL: "/elearning/api/v1/",
  elearningURL: "/elearning/api/v1/",
  quizzesURL: "/elearning/api/v1/",

  newReporting: "/services/api/v2/",
  flex: "/services/api/v2/",
  personalData: "/services/api/v2/",
};

// Override the URL configurations if they are available through environment variables
// (used especially in Docker-based deployments)
// The expected environment variables are:
// EQ_ACCOUNT_SERVICE_BASE : the URL to the account service base
// EQ_QUESTION_SERVICE_BASE : the URL to the EQ backend services base
// if (process.env.EQ_ACCOUNT_SERVICE_BASE) {
//   selectedURLs.accountsURL = process.env.EQ_ACCOUNT_SERVICE_BASE;
// }
// if (process.env.EQ_QUESTION_SERVICE_BASE) {
//   selectedURLs.datasourcesURL = process.env.EQ_QUESTION_SERVICE_BASE;
//   selectedURLs.questionsURL = process.env.EQ_QUESTION_SERVICE_BASE;
//   selectedURLs.answersURL = process.env.EQ_QUESTION_SERVICE_BASE;
// }

const coursesLink = selectedURLs.coursesURL || reserveURL;
const accountsLink = selectedURLs.accountsURL || reserveURL;
const authLink = selectedURLs.authURL || reserveURL;
const loggingLink = selectedURLs.loggingURL || reserveURL;
const elearningLink = selectedURLs.elearningURL || reserveURL;
const quizzesLink = selectedURLs.quizzesURL || reserveURL;
const reportLink = selectedURLs.reportURL || reserveURL;
const newReportingLink = selectedURLs.newReporting || reserveURLv2;
const flexLink = selectedURLs.flex || reserveURLv2;
const personalDataLink = selectedURLs.personalData || reserveURLv2;

export default {
  baseUrl: "/elearning/api/v1/",
  baseUrlV2: "/services/api/v2/",
  servicesBaseUrl: "/services/",
  authBaseUrl: "/accounts/api/v1/",

  testCenterUrl: `/services/api/v2/testcenter`,
  automationUrl: `/services/api/v2/automation`,

  loginURL: `${authLink}login/`,
  renewTokenURL: `${authLink}renew-token/`,
  userProfileURL: `${accountsLink}accounts/profiles/`,
  signupURL: `${authLink}sign-up/`,
  roleCapsURL: `${authLink}rolesCaps/`,
  forgotPasswordURL: `${authLink}forgot-password/`,
  resetPassowrdURL: `${authLink}reset-password/`,
  changePasswordURL: `${authLink}change-password/`,
  registerURL: `${authLink}sign-up/`,

  usersURL: `${accountsLink}accounts/`,

  courseAssigmentsURL: `${elearningLink}accounts/`,
  publicatedCoursesURL: `${elearningLink}accounts/`,

  reportsAccountURL: `${reportLink}reports/accounts/`,
  reportingServices: `${newReportingLink}reports/`,

  apiAdmin: `${newReportingLink}apis/`,
  flexServices: `${newReportingLink}flex/`,
  covidServices: `${newReportingLink}vaccination/`,
  eventServices: `${newReportingLink}event/`,
  systemConfigurationServices: `${newReportingLink}system/`,
  generalUsersServices: `${newReportingLink}users/`,

  tagServices: `${newReportingLink}umap/`,

  coursesURL: `${coursesLink}courses/`,
  userCoursesURL: `${coursesLink}users/courses/`,
  passwordURL: `${coursesLink}users/password/`,
  documentsURL: `${coursesLink}courses/`,

  scormURL: `${coursesLink}users/`,

  userProgramURL: `${coursesLink}users/courses/programme`,
  userCompletedCourseURL: `${coursesLink}users/courses/completed`,
  userAssessmentsURL: `${coursesLink}users/courses/surveys`,
  assessmentExportURL: `${elearningLink}bi/courses/`,

  programURL: `${coursesLink}users/courses/`,
  certificateURL: `${loggingLink}users/courses/`,
  getGDPRURL: `${personalDataLink}users/personaldata`,
  loggingURL: `${loggingLink}logs`,
  coursesLoggingURL: `${loggingLink}logs/courses`,

  quizzesURL: `${quizzesLink}quizzes/`,

  publishUrl: `${selectedURLs.elearningURL}publications/`,

  subcontractorUrl: `${selectedURLs.newReporting}subcontractor/`,
};
