// TODO: check if used, otherwise remove

<template>
  <footer v-if="isFooterShowed" class="app-footer">
    <data-privacy-policy v-if="isDataPolicyShowed" @close="togglePolicy" />
    <terms-conditions v-if="isTemrsShowed" @close="toggleTerms" />
    <div class="app-footer__content">
      <span class="app-footer__cp">© incontext.technology</span>
      <footer-item @click="toggleTerms">
        {{ $t("termsAndConditions") }}
      </footer-item>
      <footer-item @click="togglePolicy">
        {{ $t("dataProtection") }}
      </footer-item>
    </div>
  </footer>
</template>

<script>
import DataPrivacyPolicy from "@/components/auth/DataPrivacyPolicy";
import TermsConditions from "@/components/auth/TermsConditions";
import FooterItem from "./AppFooterItem";

export default {
  name: "AppFooter",
  components: {
    FooterItem,
    DataPrivacyPolicy,
    TermsConditions,
  },
  data() {
    return {
      isDataPolicyShowed: false,
      isTemrsShowed: false,
      isFooterShowed: false,
    };
  },
  methods: {
    togglePolicy() {
      this.isDataPolicyShowed = !this.isDataPolicyShowed;
    },
    toggleTerms() {
      this.isTemrsShowed = !this.isTemrsShowed;
    },
  },
};
</script>
<style lang="stylus">
.app-footer {
  position: fixed;
  z-index: 999;
  bottom: 0;
  background: $footer-color;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: #929292;

  &__content {
    max-width: 1200px;
    flex: 1 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__cp {
    margin: 0 5px;
    font-weight: bold;
    margin-right: auto;
  }

  .footer-item {
    font-weight: bold;
  }
}

@media (max-width: 485px) and (min-width: 0px) {
  .app-footer {
    display: none;
    // position: relative;
  }
}
</style>
