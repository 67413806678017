import ScormService from "@/services/scorm";

/**
 * state carries the value of all scorm 1.2 model variables
 * TODO clarify:
 * - set 'cmi.core.credit' to be initialized to 'credit' or 'no-credit' depending on context (user's purpose)
 * - set 'cmi.core.lesson_status' at the end of the course to 'passed' or 'failed' if cmi.core.credit value is 'credit'
 * - set 'cmi.core.lesson_status' at the end of the course to 'browsed' if cmi.core.lesson_mode is value 'browse'
 * - set the cmi.core.lesson_mode to the mode required (depending on course context): browse, normal, review (currently only normal)
 */
export default {
  namespaced: true,
  state: {
    "cmi._version": 3.4,
    "cmi.core._children":
      "student_id, student_name, lesson_location, credit, lesson_status, entry, score, total_time, lesson_mode, exit, session_time",
    "cmi.core.student_id": "",
    "cmi.core.student_name": "",
    "cmi.core.location": "",
    "cmi.core.credit": "credit",
    "cmi.core.lesson_status": "",
    "cmi.core.entry": "ab-initio",
    "cmi.core.score._children": "raw, min, max",
    "cmi.core.score.raw": "",
    "cmi.core.score.min": "",
    "cmi.core.score.max": "",
    "cmi.core.total_time": "0000:00:00", // on purpuse not use the optional subseconds from format HHHH:MM:SS.SS
    "cmi.core.lesson_mode": "normal",
    "cmi.core.exit": "",
    "cmi.core.session_time": "0000:00:00",
    "cmi.suspend_data": "",
    "cmi.launch_data": "",
    "cmi.comments": "",
    "cmi.comments_from_lms": "",
    "cmi.objectives._children": "id, score, status",
    "cmi.objectives._count": 0,
    objectives: {},
    "cmi.student_data._children":
      "mastery_score, max_time_allowed, time_limit_action",
    "cmi.student_data.mastery_score": "",
    "cmi.student_data.max_time_allowed": "",
    "cmi.student_data.time_limit_action": "",
    "cmi.student_preference._children": "audio, language, speed, text",
    "cmi.student_preference.audio": "",
    "cmi.student_preference.language": "",
    "cmi.student_preference.speed": "",
    "cmi.student_preference.text": "",
    "cmi.interactions._children":
      "id, objectives, time, type, correct_responses, weighting, student_response, result, latency",
    "cmi.interactions._count": 0,
    interactions: {},
  },
  mutations: {
    initScorm(state, scormState) {
      // reset current scorm state
      resetState(state);
      // set new scorm state
      Object.keys(scormState).forEach((key) => {
        // console.log('initializing value', {key, value: scormState[key]});
        state[key] = scormState[key];
      });
      return;
    },
    setScormValue(state, { key, value }) {
      state[key] = value;
      return;
    },
    setScormSubValue(state, { property, key, value }) {
      state[property][key] = value;
      return;
    },
  },
  actions: {
    async setScormValue(
      { commit, state },
      { userId, accountId, courseId, key, value },
    ) {
      const val = `${value}`;

      // In case of objectives, calculate the new counts
      if (/^cmi.objectives\.[0-9]*\./.test(key)) {
        commit("setScormSubValue", { property: "objectives", key, value: val });
        await ScormService.POST({
          userId,
          accountId,
          courseId,
          key,
          value: val,
        });

        const objectiveCount = parseInt(state["cmi.objectives._count"]);
        const n = parseInt(
          key
            .match(/(?:cmi.objectives\.)([0-9]*)(?:\.)/)
            .filter((k) => !k.startsWith("cmi"))[0],
        );
        if (n + 1 > objectiveCount) {
          commit("setScormValue", {
            key: "cmi.objectives._count",
            value: n + 1,
          });
          await ScormService.POST({
            userId,
            accountId,
            courseId,
            key: "cmi.objectives._count",
            value: objectiveCount,
          });
        }
        return;
      } else if (/^cmi.interactions\.[0-9]*\./.test(key)) {
        // In case of interactions, calculate the new counts
        commit("setScormSubValue", {
          property: "interactions",
          key,
          value: val,
        });
        await ScormService.POST({
          userId,
          accountId,
          courseId,
          key,
          value: val,
        });

        let interactionCount = state["cmi.interactions._count"];
        const n = parseInt(
          key
            .match(/(?:cmi.interactions\.)([0-9]*)(?:\.)/)
            .filter((k) => !k.startsWith("cmi"))[0],
        );
        if (n + 1 > interactionCount) {
          commit("setScormValue", {
            key: "cmi.interactions._count",
            value: n + 1,
          });
          await ScormService.POST({
            userId,
            accountId,
            courseId,
            key: "cmi.interactions._count",
            value: n + 1,
          });
        }
        const interactionObjectiveCount = parseInt(
          state[`cmi.interactions.${n}.objectives._count`],
        );
        const interactionResponseCount = parseInt(
          state[`cmi.interactions.${n}.correct_responses._count`],
        );
        if (/^cmi.interactions\.[0-9]*\.objectives\.[0-9]*\./.test(key)) {
          const n1 = parseInt(
            key
              .match(
                /(?:^cmi.interactions\.[0-9]*\.objectives\.)([0-9]*)(?:\.)/,
              )
              .filter((k) => !k.startsWith("cmi"))[0],
          );
          if (n1 + 1 > interactionObjectiveCount) {
            const keyObjectives = `cmi.interactions.${n}.objectives._count`;
            commit("setScormSubValue", {
              property: "interactions",
              key: keyObjectives,
              value: n1 + 1,
            });
            await ScormService.POST({
              userId,
              accountId,
              courseId,
              key: keyObjectives,
              value: n1 + 1,
            });
          }
        }
        if (
          /^cmi.interactions\.[0-9]*\.correct_responses\.[0-9]*\./.test(key)
        ) {
          const n1 = parseInt(
            key
              .match(
                /(?:^cmi.interactions\.[0-9]*\.correct_responses\.)([0-9]*)(?:\.)/,
              )
              .filter((k) => !k.startsWith("cmi"))[0],
          );
          if (n1 + 1 > interactionResponseCount) {
            const keyResponses = `cmi.interactions.${n}.correct_responses._count`;
            commit("setScormSubValue", {
              property: "interactions",
              key: keyResponses,
              value: n1 + 1,
            });
            await ScormService.POST({
              userId,
              accountId,
              courseId,
              key: keyResponses,
              value: n1 + 1,
            });
          }
        }
        return;
      } else {
        // standard case
        commit("setScormValue", { key, value: val });
        await ScormService.POST({
          userId,
          accountId,
          courseId,
          key,
          value: val,
        });
      }
    },
    async initScorm({ commit }, { userId, accountId, courseId, userName }) {
      const scormState = await ScormService.GET_ALL_VALUES({
        userId,
        accountId,
        courseId,
      });
      // if no value yet, initialize the course according to context
      if (!scormState || scormState.length < 1) {
        console.log("no scorm state found. Initializing the course");
        commit("setScormValue", { key: "cmi.core.student_id", value: userId });
        commit("setScormValue", {
          key: "cmi.core.student_name",
          value: userName,
        });
        commit("setScormValue", { key: "cmi.core.location", value: "" });
        commit("setScormValue", { key: "cmi.core.credit", value: "credit" });
        commit("setScormValue", { key: "cmi.core.lesson_status", value: "" });
        commit("setScormValue", {
          key: "cmi.core.total_time",
          value: "0000:00:00",
        });
        commit("setScormValue", {
          key: "cmi.core.lesson_mode",
          value: "normal",
        });
        commit("setScormValue", {
          key: "cmi.student_data.mastery_score",
          value: "",
        });
        commit("setScormValue", {
          key: "cmi.student_data.max_time_allowed",
          value: "",
        });
        commit("setScormValue", {
          key: "cmi.student_data.time_limit_action",
          value: "",
        });
        commit("setScormValue", { key: "cmi.objectives._count", value: 0 });
        commit("setScormValue", { key: "cmi.interactions._count", value: 0 });

        await ScormService.POST({
          userId,
          accountId,
          courseId,
          key: "cmi.objectives._count",
          value: 0,
        });

        await ScormService.POST({
          userId,
          accountId,
          courseId,
          key: "cmi.interactions._count",
          value: 0,
        });

        return true;
      }

      // extracts the objectives and interactions lists from the retrieved scorm state
      // and calculate the corresponding counts for:
      // cmi.objectives.n.
      // cmi.interactions.n.
      // cmi.interactions.n.correct_responses.n.
      // cmi.interactions.n.objectives.n
      const objectives = {};
      const interactions = {};
      let objectiveCount = 0;
      let interactionCount = 0;
      let interactionSubCounts = {};
      // Object.keys(scormState).forEach(key => {
      //   if (/^cmi.objectives\.[0-9]*\./.test(key)) {
      //     objectives[key] = scormState[key];
      //     const n = parseInt(key.match(/(?:cmi.objectives\.)([0-9]*)(?:\.)/).filter(k => ! k.startsWith('cmi'))[0]);
      //     // (?:characters) finds but does not capture characters.
      //     objectiveCount = (objectiveCount < (n+1)) ? n+1 : objectiveCount;
      //   }
      //   if (/^cmi.interactions\.[0-9]*\./.test(key)) {
      //     interactions[key] = interactions[key];
      //     const n = parseInt(key.match(/(?:cmi.interactions\.)([0-9]*)(?:\.)/).filter(k => ! k.startsWith('cmi'))[0]);
      //     interactionCount = (interactionCount < (n + 1)) ? n + 1 : interactionCount; // numbering starts with 0
      //     if (!interactionSubCounts[n]) {
      //       interactionSubCounts[n] = {
      //         objectives: 0,
      //         correctResponses: 0,
      //       }
      //     }
      //     if (/^cmi.interactions\.[0-9]*\.objectives\.[0-9]*\./.test(key)) {
      //       const n1 = parseInt(key.match(/(?:^cmi.interactions\.[0-9]*\.objectives\.)([0-9]*)(?:\.)/).filter(k => ! k.startsWith('cmi'))[0]);
      //       interactionSubCounts[n].objectives = (interactionSubCounts[n].objectives < (n1 + 1)) ?
      //         n1 + 1 :
      //         interactionSubCounts[n].objectives; // numbering starts with 0
      //     }
      //     if (/^cmi.interactions\.[0-9]*\.correct_responses\.[0-9]*\./.test(key)) {
      //       const n1 = parseInt(key.match(/(?:^cmi.interactions\.[0-9]*\.correct_responses\.)([0-9]*)(?:\.)/).filter(k => ! k.startsWith('cmi'))[0]);
      //       interactionSubCounts[n].correctResponses = (interactionSubCounts[n].correctResponses < (n1 + 1)) ?
      //         n1 + 1 :
      //         interactionSubCounts[n].correctResponses; // numbering starts with 0
      //     }
      //   }
      // });
      // Object.keys(interactionSubCounts).forEach(n => {
      //   const keyResponses = `cmi.interactions.${n}.correct_responses._count`;
      //   const keyObjectives = `cmi.interactions.${n}.objectives._count`;
      //   interactions[keyResponses] = interactionSubCounts[n].correctResponses;
      //   interactions[keyObjectives] = interactionSubCounts[n].objectives;
      // });

      /**
       * base initialization of session data
       * set retrieved data, objectives and interaction data and
       * other session values depending on dependencies to current scorm model values and rules
       * see http://xml.coverpages.org/SCORM-12-RunTimeEnv.pdf
       */
      const newScormState = extractScormState({
        ...scormState,
        objectives,
        interactions,
        "cmi.objectives._count": objectiveCount,
        "cmi.interactions._count": interactionCount,
        "cmi.core.student_id": userId,
        "cmi.core.student_name": userName,
        "cmi.core.credit": "credit",
        "cmi.core.lesson_mode": "normal",
        "cmi.core.session_time": "",
      });

      commit("initScorm", newScormState);
      return true;
    },
  },
  getters: {
    getLogMeassages: (state) => state.messages,
    getLogMeassages: (state) => state.messages,
  },
};

/**** Helper functions ****/
const resetState = (state) => {
  state["cmi.core.student_id"] = "";
  state["cmi.core.student_name"] = "";
  state["cmi.core.location"] = "";
  state["cmi.core.credit"] = "";
  state["cmi.core.lesson_status"] = "";
  state["cmi.core.entry"] = "";
  state["cmi.core.score.raw"] = "";
  state["cmi.core.score.min"] = "";
  state["cmi.core.score.max"] = "";
  state["cmi.core.total_time"] = "0000:00:00"; // on purpuse not use the optional subseconds from format HHHH:MM:SS.SS
  state["cmi.core.lesson_mode"] = "normal";
  state["cmi.core.exit"] = "";
  state["cmi.core.session_time"] = "";
  state["cmi.suspend_data"] = "";
  state["cmi.launch_data"] = "";
  state["cmi.comments"] = "";
  state["cmi.comments_from_lms"] = "";
  state["cmi.objectives._count"] = 0;
  state["objectives"] = {};
  state["cmi.student_data.mastery_score"] = "";
  state["cmi.student_data.max_time_allowed"] = "";
  state["cmi.student_data.time_limit_action"] = "";
  state["cmi.student_preference.audio"] = "";
  state["cmi.student_preference.language"] = "";
  state["cmi.student_preference.speed"] = "";
  state["cmi.student_preference.text"] = "";
  state["cmi.interactions._count"] = 0;
  state["interactions"] = {};
};

const extractScormState = (values) => {
  const scormState = {};
  scormState["cmi.core.student_id"] = values["cmi.core.student_id"] || "";
  scormState["cmi.core.student_name"] = values["cmi.core.student_name"] || "";
  scormState["cmi.core.location"] = values["cmi.core.location"] || "";
  scormState["cmi.core.credit"] = values["cmi.core.credit"] || "";
  scormState["cmi.core.lesson_status"] = values["cmi.core.lesson_status"] || "";
  scormState["cmi.core.entry"] = values["cmi.core.entry"] || "";
  scormState["cmi.core.score.raw"] = values["cmi.core.score.raw"] || "";
  scormState["cmi.core.score.min"] = values["cmi.core.score.min"] || "";
  scormState["cmi.core.score.max"] = values["cmi.core.score.max"] || "";
  scormState["cmi.core.total_time"] =
    values["cmi.core.total_time"] || "0000:00:00";
  scormState["cmi.core.lesson_mode"] = values["cmi.core.lesson_mode"] || "";
  scormState["cmi.core.exit"] = values["cmi.core.exit"] || "";
  scormState["cmi.core.session_time"] = values["cmi.core.session_time"] || "";
  scormState["cmi.suspend_data"] = values["cmi.suspend_data"] || "";
  scormState["cmi.launch_data"] = values["cmi.launch_data"] || "";
  scormState["cmi.comments"] = values["cmi.comments"] || "";
  scormState["cmi.comments_from_lms"] = values["cmi.comments_from_lms"] || "";
  scormState["cmi.objectives._count"] = values["cmi.objectives._count"] || 0;
  scormState["objectives"] = values["objectives"] || {};
  scormState["cmi.student_data.mastery_score"] =
    values["cmi.student_data.mastery_score"] || "";
  scormState["cmi.student_data.max_time_allowed"] =
    values["cmi.student_data.max_time_allowed"] || "";
  scormState["cmi.student_data.time_limit_action"] =
    values["cmi.student_data.time_limit_action"] || "";
  scormState["cmi.student_preference.audio"] =
    values["cmi.student_preference.audio"] || "";
  scormState["cmi.student_preference.language"] =
    values["cmi.student_preference.language"] || "";
  scormState["cmi.student_preference.speed"] =
    values["cmi.student_preference.speed"] || "";
  scormState["cmi.student_preference.text"] =
    values["cmi.student_preference.text"] || "";
  scormState["cmi.interactions._count"] =
    values["cmi.interactions._count"] || 0;
  scormState["interactions"] = values["interactions"] || {};
  return scormState;
};
