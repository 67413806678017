<template lang="pug">
div
  EditPreview(ref="editor", :text="text", @editText="editText")
  .center
    ConfirmationButton(v-if="requiresInteraction")
</template>

<script>
import ConfirmationButton from "./components/ConfirmButton.vue";
import editStepMixin from "./mixins/editStepMixin";
import EditPreview from "./components/EditPreview.vue";
import { nextTick } from "vue";

export default {
  components: {
    EditPreview,
    ConfirmationButton,
  },
  mixins: [editStepMixin],
  computed: {
    text() {
      return this.step.text;
    },
    requiresInteraction() {
      return this.step.requiresInteraction;
    },
  },
  mounted() {
    nextTick(() => {
      this.$refs.editor.focus();
    });
  },
  methods: {
    editText(text) {
      this.patchStep({ text, type: "text" });
    },
    startEditText() {
      this.isEditingText = true;
    },
    stopEditText() {
      this.isEditingText = false;
    },
  },
};
</script>
