export default {
  termsAccept: "Acconsento alle {0}.",
  tos: "CGC",
  privacyAccept: "Acconsento all’{0}.",
  privacyPolicy: "Informativa sulla privacy",
  resetPassword: "Ripristina password",
  usernamePasswordRequired: "Inserire un nome utente e una password.",
  passwordTooShort: "La password deve contenere almeno 8 caratteri.",
  wrongCredentials: "Nessun account con i dati forniti è stato trovato.",
  accountStructureItem: "Sezione",
  addRole: "Aggiungei ruolo",
  allUserRelatedDeleteText:
    "In tal modo tutti i dati rilevanti per l’utente vengono eliminati irrevocabilmente.",
  assignRoleModals: "Assegna ruolo",
  canBeDownloadedText: "possono essere scaricati sotto completati",
  changePassword: "Modifica password",
  changingPasswordFor: "Modifica password per l’utente",
  confirmDeleteText: "Inserisci nome utente per confermare:",
  confirmDeteleBtnText: "Ho capito, elimina l’utente",
  confirmPassword: "Ripeti password",
  createAccount: "Crea conto",
  createUser: "Crea utente",
  currentPassword: "Password attuale",
  customId: "ID personalizzata",
  dataPrivacyHeader: "Privacy dei dati",
  dataPrivacyText: "Testo della sezione (il contenuto seguirà)",
  dataPrivacyTitle: "Titolo della sezione",
  dataRelatedToMe: "Visualizza i dati memorizzati su di me",
  dataRelatedToMeTitle: "Dati salvati",
  deleteText: "Si desidera davvero procedere all’eliminazione",
  downloadDataRelatedToMe: "Scarica i dati salvati",
  fileWithUserInformation: "File con le informazioni dell’utente",
  forgotPassword: "Password dimenticata?",
  forgotPasswordText:
    "Inserire il proprio indirizzo e-mail per ricevere un link per reimpostare la password.",
  importUsers: "Importa utente",
  newHere: "Nuovo qui?",
  newPassowrd: "Nuova password",
  newPasswordShort: "Nuova password troppo corta",
  password: "Password",
  passwordCantBeEmpty: "La password non può essere vuota",
  passwordsNotMatch: "Le password non corrispondono",
  pickUser: "Scegliere un utente",
  pleaseFillUpAllFields: "Compilare tutti i campi",
  pleaseTypeNewPassword: "Inserire una nuova password",
  policyAcceptance: "Accettazione delle linee guida",
  policyAcceptanceSave: "Accetta",
  acceptPolicies: "Accettare le linee guida",
  profile: "Profilo",
  role: "Ruolo",
  roleExpiresOn: "Il ruolo scade il",
  saveDataAboutMe: "dati memorizzati su di me",
  signIn: "Registrazione",
  termsConditionsHeader: "Condizioni generali di contratto",
  termsConditionsText: "Testo della sezione (il contenuto seguirà)",
  termsConditionsTitle: "Titolo della sezione",
  typeInOne: "Inserire uno per tutti",
  username: "Nome dell’utente",
  firstname: "Nome",
  lastname: "Cognome",
  usernameMail: "Nome dell’utente o e-mail",
  userNotes: "Note",
  userProfile: "Profilo dell’utente",
  userStatus: "Stato dell’utente",
  wrongCurrentPassword: "Password attuale errata",
  fillAllFields: "Compilare tutti i campi richiesti",
  wrongUsernameOrPassword: "Nome utente o password errati",
  minimumLength: "lunghezza minima 8",
  atLeastOneBigAndSmall: "almeno 1 lettera minuscola e 1 maiuscola",
  passwordNotEqualUsernameAndEmail:
    "la password non corrisponde al nome utente e all’indirizzo e-mail",
  registrationKey: "Chiave di registrazione",
  registration: "Registrazione",
  notAnEmailAdress: "Inserire un indirizzo e-mail valido.",
  company: "Azienda",
  companyHint: "Azienda",
  birthday: "Compleanno",
  birthdayHint: "Compleanno",

  accountName: "Nome del conto",
  accountType: "Tipo di conto",
  accountAddress: "Indirizzo del titolare del conto",
  logo: "Logo",
  address: "Indirizzo",
  contry: "Paese",
  zipCode: "Codice postale",
  lessThan: "meno di 1mb",
  defaultLanguage: "Lingua di default",
  accountStatus: "Stato del conto",
  accountNotes: "Note del conto",
  createAccount: "Crea conto",
  updateAccount: "Aggiorna conto",
  fileToImport: "Note dell’account",
  importStructure: "Importa struttura",
  addRootAccount: "Aggiungi account livello base",
  usersPerMonth: "Utenti mensili",
  usersAdded: "Utenti aggiunti",
  usersDeleted: "Utenti eliminati",
  usersTotal: "Utenti totali",
  city: "Città",

  knowledge: "SAPERE",
  toolbox: "TOOLBOX",
  lessonCompleted: "Lezione completata",
  lessonFailed: "Lezione non completata",
  repeatLesson: "Ripetere la lezione per continuare",
  congratulations: "Congratulazioni!",
  courseCompleted: "Corso completato",
  percentCompleted:
    "Hai risposto correttamente al $ successRate% delle domande.",
  downloadCertificate:
    "Puoi scaricare il tuo certificato in qualsiasi momento sotto “Certificati” nel profilo e richiederlo via e-mail.",
  trueFalse: "Vero o falso?",
  seconds: "Secondi",
  noChoiceText: "Peccato, troppo tardi.",
  right: "Vero!",
  wrong: "Falso",
  progress: "$current di $total",

  addCourseContent: "Aggiungi contenuti del corso",
  addSemicolon: "Aggiungi etichette separate da un punto e virgola",
  addToProgram: "Aggiungi al programma",
  allAccounts: "Tutti i conti",
  certificate: "Certificato",
  certificates: "Certificati",
  completed: "Completato",
  contentIsLoaded: "Contenuto caricato",
  contentManager: "Gestore del contenuto",
  courseCompleted: "Corso completato",
  courseContent: "Contenuto del corso",
  courseDocuments: "Documenti",
  courseLables: "Etichette del corso",
  courseLanguage: "Lingua del corso",
  courseNotes: "Note del corso",
  courseStructure: "Struttura",
  courseTags: "Tag del corso",
  courseTitle: "Nome del corso",
  createCourse: "Crea corso",
  curator: "CURATORE",
  description: "Descrizione",
  details: "Dettagli",
  dueDate: "Scaduto fino",
  duration: "Durata",
  exportStatistics: "Esporta statistiche",
  learningStandard: "Standard di apprendimento",
  licenses: "Licenze",
  mandatory: "Corso obbligatorio",
  noAssessment: "Al momento non ci sono valutazioni da esaminare.",
  noCoursesAssigned: "Nessun corso assegnato al proprio account. Visitare",
  noCoursesAvailable: "Al momento non ci sono corsi disponibili.",
  noDueDate: "Nessuna data di scadenza",
  overview: "PANORAMICA",
  page: "",
  pickAnAccount: "Selezionare un conto",
  printCertificate: "Stampa certificati",
  publishCourse: "Pubblica corso",
  publishedToAccount: "Pubblicato nel conto",
  relatedCourses: "Corsi simili",
  remove: "Rimuovi",
  removeFromProgram: "Rimuovi dal programma",
  singleAccount: "Conto singolo",
  startCourse: "Avvia",
  successMessageOne: "Congratulazioni, hai ......... il corso ",
  successMessageThree:
    "Ora puoi scaricare o stampare il tuo certificato di completamento. ",
  successMessageTwo: " completato con successo",
  tags: "Parole chiave",
  unpublishCourse: "Annulla pubblicazione",
  updateCourseContent: "Carica contenuti del corso",
  updateCourse: "Aggiorna corso",
  youDidntfinishCourse: "Non hai ancora terminato un corso.",
  courseVisibleFor: "Corso visibile per",
  accountStructureItem: "Sezione",
  courseAssigmentOptions: "Opzioni di assegnazione",
  assignCourse: "Assegna corso",
  pickACourse: "Scegli un corso",

  noDocumentsAvailable: "nessun documento disponibile",
  nothingRelated: "niente di simile disponibile",
  nothingWritten: "non è inserito nulla",
  noTagsAvailable: "nessuna parola chiave disponibile",
  alreadyGlobal: "Il corso è già stato pubblicato in tutto il mondo.",
  questionsanswered: "Domande",
  completedOn: "Completato il",
  locale: "it-IT",
  quizAuthorizationContent: "Autorizzazione test",

  courseNotPublished: "Il corso non è attualmente pubblicato",
  courseNotPublishedForAccount:
    "Il corso non è attualmente pubblicato per il conto $account",
  clickToPublishCourse: "Pubblica corso",
  visible: "Visibile",
  userIsNotContentManager:
    "Non sei un content manager e quindi non puoi modificare il contenuto del corso.",
  assignmentOpen: "Aperto",
  assignmentStarted: "Avviato",
  assignmentCompleted: "Completato",
  dueDatePassed: "Scadenza superata",

  exitCourse: "Termina corso",
  markAsFinished: "Contrassegna come elaborato",

  system: "Sistema",
  configureLogging: "Configura verbalizzazione",
  createAccount: "Crea account",
  changeAccount: "Modifica account",
  deleteAccount: "Elimina account",
  importAccountStructure: "Importa struttura dell’account",
  createUser: "Crea utente",
  importUser: "Importa utente",
  changeUser: "Modifica utente",
  deleteUser: "Elimina utente",
  assignUserRole: "Assegna ruolo",
  resetPassword: "Ripristina password",

  createCourse: "Crea corso",
  changeCourse: "Modifica corso",
  addQuizContent: "Aggiungi contenuto del quiz",
  changeQuizContent: "Modifica contenuto del quiz",
  addContent: "Aggiungi contenuto del corso",
  changeContent: "Modifica contenuto del corso",
  deleteCourse: "Elimina corso",
  deleteContent: "Elimina contenuto del corso",
  publishCourse: "Pubblica corso",
  unpublishCourse: "corso non pubblicato",
  assignCourse: "Assegna corso",
  unassignCourse: "Annulla assegnazione del corso",

  systemMessages: "Messaggi di sistema",

  learningManagement: "Gestione dell’apprendimento",
  courseManagement: "Gestione del corso",
  userManagement: "Gestione dell’utente",
  organizationStructure: "Struttura organizzativa",
  accountManagement: "Gestione dei conti",
  system: "Sistema",

  learningManagementDetail: "Piani di apprendimento e assegnazione del corso",
  courseManagementDetail: "Gestisci i contenuti del corso",
  userManagementDetail: "Gestisci utente e ripristina password",
  organizationStructureDetail: "Definisci struttura organizzativa",
  accountManagementDetail: "Gestisci conti",
  logsDetail: "Log di sistema e impostazioni",

  userCreateSuccess: "Utente creato con successo",
  createOtherUser: "Aggiungi un ulteriore utente",
  backToUserOverview: "Torna alla panoramica utente",
  importMoreUsers: "Importa ulteriori utenti",
  currentRoles: "Ruoli attuali",
  numberOfRoles: "Numero ruoli",
  numberOfUsers: "Utente",
  deleteAccountMessage:
    "Confermare che si desidera eliminare l’account '$account'.",
  showStatistics: "Mostra statistiche",
  appendAccount: "Aggiungi l’account a $account.",

  courseAssignments: "Assegnato",
  courseAssignmentsForCourse: "Assegnazioni dei corsi per $course",
  mandatoryAssignments: "Assegnazioni corsi obbligatorie",
  dueDateAssignments: "Con scadenza",
  dueDatePassed: "Scaduto",
  userOrAccount: "Utente o sezione",
  expiryDate: "Data di scadenza",
  deletePublication: "Elimina pubblicazione",
  deletePublicationMessage:
    "Si desidera davvero eliminare la pubblicazione per l’account $ account?",
  orgStructure: "Sezione",
  type: "Tipo",
  downloadTemplate: "Scarica modello",
  published: "Pubblicato",
  scorm: "SCORM",
  quiz: "Quiz",
  staticFile: "Pdf",
  editPublication: "Elabora pubblicazione",
  pleaseDefineCourseContent:
    "please define the course content before publishing it.",

  usernameMissing: "Nome dell’utente mancante",
  usernameError: "Il nome utente può contenere solo caratteri alfanumerici",
  emailMissing: "E-mail mancante",
  emailError: "Nessun indirizzo e-mail valido",
  passwordMissing: "Password mancante",
  passwordError:
    "La password deve contenere almeno 8 caratteri e contenere una lettera maiuscola e una lettera minuscola.",

  registrationKeyManagement: "Chiave di registrazione",
  registrationKeyManagementDetail: "Gestione delle chiavi di registrazione",
  addRegistrationKey: "Aggiungi chiave di registrazione",
  registrationLink: "Link di registrazione",
  createKey: "Aggiungi",

  userState: "Stato dell’utente",
  userStateDetail: "Permesso di stato di accesso e corsi obbligatori.",
  testsPassed: "Test passati",
  access: "Accesso",
  downloadReport: "Scarica il rapporto",
  success: "Salvato con successo!",
  warning: "Attenzione!",
  error: "Errore!",
  removedFromProgram: "Questo corso è stato rimosso dal tuo programma",
  addedToProgram: "Questo corso è stato aggiunto al tuo programma",
  courseCreated: "Il corso è stato creato",
  courseNotCreated: "Il corso non è stato creato",
  fillUpFields: "Compilare tutti i campi richiesti",
  cookieNotAvailable:
    "Le tuo informazioni di accesso non sono disponibili oppure sono obosolete. Effettuare nuovamente l’accesso",
  courseNotFinished:
    "Nessuna informazione sul completamento del corso disponibile",
  courseAborted: "Il corso è stato interrotto",
  correctAnswer: "Risposta corretta",
  wrongAnswer: "Risposta errata",
  courseStarted: "Il corso è iniziato",
  testsPassed: "Test superato",
  testFailed: "Test non superato",
  courseCompleted: "Il corso è stato completato con successo",
  imageTooLarge: "Il file deve essere più piccolo di 2mb",
  wrongFormat: "Formato errato",
  passwordReset:
    "È stato inviato un link per reimpostare la password all’indirizzo e-mail specificato",
  passwordChanged: "La password è stata modificata",
  accountAdded: "L’account è stato aggiunto",
  accountUpdated: "L’account è stato aggiornato",
  accountDeleted: "L’account è stato eliminato",
  userImported: "L’utente è stato importato",
  userNotImported: "L’utente non è stato importato",
  loggingConfigSaved: "La configurazione di log in è stata salvata",
  userDeleted: "L’utente è stato eliminato",
  userAdded: "L’utente è stato creato",
  userUpdated: "L’utente è stato aggiornato",
  GDPRLoaded: "Il RGPD è caricato",
  cookiesDisabled: "Hai disattivato i cookie",
  wrongCredentials: "Dati di login errati",
  fileUploaded: "Il tuo file è stato caricato",
  courseContentUpdated: "Il contenuto del corso è stato aggiornato",
  coursePublished: "Il corso è stato pubblicato",
  courseUnPublished: "La pubblicazione del corso è stata annullata.",
  serverUnreachable: "Il server non è raggiungibile",
  selectAccounts: "Scegli account",
  selectAccountToContinue: "Per continuare, scegliere account",
  accountOverview: "Panoramica account",
  users: "Utente",
  courses: "Corsi",
  coursesCompleted: "Corsi completati",
  completedCoursesPerUser: "Corsi completati per utente",
  learningHours: "Ore di apprendimento",
  learningHoursPerUser: "Ore di apprendimento per utente",
  courseOverview: "Panoramica del corso",
  completed: "Completato",
  assigned: "Assegnato",
  courseDetails: "Dettagli",
  selectCourse: "Scegli corso",
  details: "Scegli",
  type: "Tipo",
  dueDate: "Fino a",
  mandatory: "Corso obbligatorio",
  completionRate: "Tasso di completamento",
  averageScore: "Risultato del test",
  duration: "Durata",
  averageDuration: "Durata media",
  account: "Conto",
  accountName: "Nome dell’account",
  accounts: "Account",
  active: "Attivo",
  admin: "Admin",
  all: "Tutti",
  not_found: "La pagina non esiste",
  assessmentStatistics: "Statistiche di valutazione",
  assignedUsersOnly: "Solo utenti assegnati",
  autoIndividual: "Automatico, individuale",
  cancel: "Annulla",
  cardImage: "Immagine della carta",
  close: "Chiudi",
  completed: "Completato",
  confirm: "Confermato",
  country: "Paese",
  course: "Corsi",
  courseDescription: "Descrizione del corso",
  courseDocuments: "Documenti del corso",
  courses: "Biblioteca",
  courseStructure: "Struttura del corso",
  dataProtection: "Protezione dei dati",
  defaultLanguage: "Lingua standard",
  deleteText: "Si desidera davvero procedere all’eliminazione",
  delete: "Elimina",
  details: "Dettagli",
  dragAndDrop: "Drag & Drop / clicca per aggiungere",
  dueDate: "Scaduto il",
  email: "E-mail",
  empty: "Vuoto",
  exit: "Annulla",
  expiresOn: "Scaduto il",
  fileImport: "Al file importato",
  image: "Immagine",
  inactive: "Inattivo",
  language: "Lingua",
  logo: "Logo",
  mandatory: "Corso obbligatorio",
  monthsLabels: [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ],
  myAccount: "Il mio conto",
  myProfile: "Il mio profilo",
  myProgram: "Il mio programma",
  myRoles: "I miei ruoli",
  never: "Mai",
  no: "No",
  none: "Nessuno",
  notFound: "Non trovato",
  phoneNumber: "Numero di telefono",
  playImage: "Riproduci immagine",
  publishingExpiresOn: "Pubblicazione scaduta il",
  publishTo: "Pubblica in",
  reports: "Relazione",
  save: "Salva",
  search: "Ricerca in corso...",
  status: "Stato",
  structureItem: "Sezione",
  termsAndConditions: "Condizioni di contratto",
  unlimited: "Illimitato",
  update: "Aggiorna",
  user: "Utente",
  username: "nome utente",
  users: "utenti",
  yes: "Sì",
  zipCode: "Codice postale",

  info: "Info",
  error: "Errore",
  alert: "Allarme",
  criticalAlert: "Allarme critico",
  navigation: "Navigazione",
  system: "Sistema",
  note: "Indicazione",
  userProfile: "Profilo dell’utente",

  userId: "ID utente",
  customId: "",
  organizationalStructure: "Struttura organizzativa",
  created: "Creato il",
  new: "Nuovo",
  noResults: "Nessun risultato",
  noData: "Nessun dato",
  uploadFile: "Carica file",
  id: "ID",
  name: "Nome",
  append: "Aggiungi",
  edit: "Elabora",
  format: "Formato",
  minutes: "Minuti",
  hours: "Ore",
  date: "Data",
  message: "Messaggio",
  fax: "Fax",

  genericError:
    "Si è verificato un errore. Provare a ricaricare la pagina. Se l’errore persiste, contattare l’amministratore del sistema.",
  action: "Azione",

  uploadCertificates: "Certificati caricati",

  add_mandatory_user_property:
    "Seleziona le seguenti caratteristiche per il tuo lavoro",
  help: "Aiuto",
  alreadyRegistered: "Già registrato",
  additionalDetails: "Informazioni aggiuntive",
  additionalDetailsSave: "Confermare",
  dragAndDropCertificate: "Trascina il file PDF qui o clicca per aggiungere",
  coverPhoto: "Cover Photo",
  EditCoverPhoto: "Edit Cover Photo",
  AddCoverPhoto: "Add Cover Photo",
  additionalDocuments: "Additional Documents",
  editAdditionalDocuments: "Edit Additional Documents",
  addAdditionalDocuments: "Add Additional Documents",
  fileName: "File name",
  fileSize: "File size",
  systemLogs: "System Logs",
  systemConfigurationDetail: "Configure system settings",
  systemConfiguration: "System configuration",
  courseFormat: "Course formats",
  views: "Views",
  apis: "APIs",
  viewsDescription: "Visibility of the element in the respective view",
  courseFormatDescription: "Available course formats for defining the course",
  accessCard: "Access card",
  downloadCertification: "Download Certification",
  userProfileV2: "User profile V2",
  certification: "Certification",
  logs: "Logs",
  issuedBy: "Issued by",
  issuedOn: "Issued on",
  fulfilledInPercent: "Fulfilled in percent",

  logout: "Disconnetti",
  continue: "Continua",
  courseFinishedDoYouWantToLogout:
    "Il corso è terminato. Vuoi disconnetterti o continuare? Verrai disconnesso automaticamente in {seconds}.",
  stateless: "Apolide",
};
