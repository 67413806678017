<template>
  <div class="info-block">
    <modal wide clear @close="close">
      <div class="header-field">
        <i class="material-icons icon" @click="close">play_circle_outline</i>
        <p class="header">
          <slot name="header" />
        </p>
        <div class="lang-toggler">
          <div
            class="lang"
            :class="{ 'lang--active': currentLang === 'en' }"
            @click="pickLang('en')"
          >
            EN
          </div>
          |
          <div
            class="lang"
            :class="{ 'lang--active': currentLang === 'de' }"
            @click="pickLang('de')"
          >
            DE
          </div>
        </div>
      </div>
      <div class="title">
        <slot name="title" />
      </div>
      <div class="text">
        <slot />
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/elements/Modal";
import { useI18n } from "vue-i18n";

export default {
  name: "InfoBlock",
  components: {
    Modal,
  },
  emits: ["close"],
  computed: {
    currentLang() {
      const i18n = useI18n();
      return i18n.locale;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    pickLang(code) {
      const current = this.currentLang;
      if (current !== code) {
        const i18n = useI18n();
        i18n.locale = code;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.info-block {
  .header-field {
    display: flex;
    align-items: center;
    margin-bottom: 65px;

    .header {
      text-transform: uppercase;
      padding-top: 4px;
      margin-left: 12px;
      color: #fea500;
      font-size: 18px;
    }
  }

  .title {
    font-weight: bold;
    padding: 0 15px;
    font-size: 19px;
  }

  .text {
    font-size: 18px;
    margin-top: 20px;
    padding: 0 15px;
  }

  .icon {
    color: #d2d2d2;
    font-size: 26px;
    transform: rotate(180deg);
    cursor: pointer;

    &:hover {
      color: #000;
    }
  }

  .lang-toggler {
    margin-left: auto;
    display: flex;
    align-items: center;

    .lang {
      cursor: pointer;
      margin: 0 5px;

      &--active {
        color: #fea500;
      }
    }
  }
}
</style>
