import nlLocale from "element-plus/dist/locale/nl";

export default {
  ...nlLocale,
  termsAccept: "Ik ga akkoord met {0} .",
  tos: "AV",
  privacyAccept: "Ik ga akkoord met {0} .",
  privacyPolicy: "Privacyverklaring",
  resetPassword: "Wachtwoord resetten",
  usernamePasswordRequired: "Voer een gebruikersnaam en een wachtwoord in.",
  passwordTooShort: "Het wachtwoord moet minimaal 8 tekens bevatten.",
  wrongCredentials: "Er is geen account gevonden met de ingevoerde gegevens.",
  accountStructureItem: "Afdeling",
  addRole: "Rol toevoegen",
  allUserRelatedDeleteText:
    "Hiermee worden alle voor de gebruiker relevante gegevens van definitief verwijderd.",
  assignRoleModals: "Rol toewijzen",
  canBeDownloadedText: "kunnen bij afgesloten worden gedownload",
  changePassword: "Wachtwoord wijzigen",
  changingPasswordFor: "Wachtwoord voor de gebruiker wijzigen",
  confirmDeleteText: "Voer de gebruikersnaam in om te bevestigen:",
  confirmDeteleBtnText: "Ik heb het begrepen, verwijder de gebruiker",
  confirmPassword: "Wachtwoord herhalen",
  createAccount: "Account aanmaken",
  createUser: "Gebruiker aanmaken",
  currentPassword: "Huidig wachtwoord",
  customId: "Gebruikersbepaalde ID",
  dataPrivacyHeader: "Gegevensprivesfeer",
  dataPrivacyText: "Paragraaftekst (inhoud volgt nog)",
  dataPrivacyTitle: "Paragraaftitel",
  dataRelatedToMe: "Over mij opgeslagen gegevens inzien",
  dataRelatedToMeTitle: "Opgeslagen gegevens",
  deleteText: "Weet u zeker dat u dit wilt verwijderen",
  downloadDataRelatedToMe: "Opgeslagen gegevens downloaden",
  fileWithUserInformation: "Bestand met gebruikersinformatie",
  forgotPassword: "Wachtwoord vergeten?",
  forgotPasswordText:
    "Voer uw e-mailadres in, wij sturen u dan een link voor het resetten van uw wachtwoord.",
  importUsers: "Gebruiker importeren",
  newHere: "Nieuw hier?",
  newPassowrd: "Nieuw wachtwoord",
  newPasswordShort: "Nieuwe wachtwoord te kort",
  password: "Wachtwoord",
  passwordCantBeEmpty: "Het wachtwoord mag niet leeg zijn",
  passwordsNotMatch: "Wachtwoorden komen niet overeen",
  pickUser: "Kies een gebruiker",
  pleaseFillUpAllFields: "Vul alle velden in",
  pleaseTypeNewPassword: "Voer uw nieuwe wachtwoord in",
  policyAcceptance: "Aanvaarding van de richtlijnen",
  policyAcceptanceSave: "Aanvaarden",
  acceptPolicies: "Aanvaard alle richtlijnen",
  profile: "Profiel",
  role: "Rol",
  roleExpiresOn: "De rol vervalt op",
  saveDataAboutMe: "over mij opgeslagen gegevens",
  signIn: "Aanmelden",
  termsConditionsHeader: "Algemene voorwaarwaarden",
  termsConditionsText: "Paragraaftekst (inhoud volgt nog)",
  termsConditionsTitle: "Paragraaftitel",
  typeInOne: "Voer één in voor alle",
  username: "Gebruikersnaam",
  firstname: "Voornaam",
  lastname: "Achternaam",
  usernameMail: "Gebruikersnaam of e-mailadres",
  userNotes: "Notities",
  userProfile: "Gebruikersprofiel",
  userStatus: "Gebruiker status",
  wrongCurrentPassword: "Onjuist huidig wachtwoord",
  fillAllFields: "Vul alle vereiste velden in",
  wrongUsernameOrPassword: "Gebruikersnaam of wachtwoord zijn onjuist",
  minimumLength: "Minimumlengte 8",
  atLeastOneBigAndSmall: "ten minste 1 kleine letter en 1 hoofdletter",
  passwordNotEqualUsernameAndEmail:
    "Wachtwoord is niet gelijk aan de gebruikersnaam en het e-mailadres",
  registrationKey: "Registratiesleutel",
  registration: "Registratie",
  notAnEmailAdress: "Voer een geldig e-mailadres in.",
  company: "Bedrijf",
  birthday: "Geboortedatum",
  birthdayHint: "Geboortedatum",
  birthyear: "Geboortejaar",
  accountName: "Accountnaam",
  accountType: "Accounttype",
  accountAddress: "Adres van de accounteigenaar",
  logo: "Logo",
  address: "Adres",
  contry: "Land",
  zipCode: "Postcode",
  lessThan: "minder dan 1 mb",
  defaultLanguage: "Standaardtaal",
  accountStatus: "Accountstatus",
  accountNotes: "Accountnotities",
  createAccount: "Account aanmaken",
  updateAccount: "Account bijwerken",
  fileToImport: "Account notities",
  importStructure: "Structuur importeren",
  addRootAccount: "Basisniveau account toevoegen",
  usersPerMonth: "Maandelijkse gebruikers",
  usersAdded: "Toegevoegde gebruikers",
  usersDeleted: "Verwijderde gebruikers",
  usersTotal: "Gebruikers totaal",
  city: "Stad",
  users: "Gebruikers",
  coursesAvailable: "Beschikbare cursussen",
  started: "Gestart",
  completed: "Voltooid",
  openMandatoryAssignments: "Open verplichte opdrachten",
  mandatoryAssignments: "Verplichte opdrachten",
  dueMandatoryCompletions: "Aankomende verplichte voltooiingen",
  of: "van",
  accountOverview: "Accountoverzicht",
  selectAccount: "Kies een account",
  noAccountsAvailable: "Geen accounts beschikbaar",
  completionRate: "Percentage afgerond",
  averageResult: "Gemiddeld resultaat",
  courseOverview: "Overzicht van de cursussen",
  reportCoursesSearchPlaceholder: "Zoek een cursus",
  completedLastWeeks: "Voltooide cursussen in de afgelopen {number} weken",
  completedLastMonths: "Voltooide cursussen in de afgelopen {number} maanden",
  executionTime: "Verdeling volgens uitvoeringstijd (min)",
  completionLevel: "Verdeling volgens afsluitende punten",
  knowledge: "KENNIS",
  toolbox: "TOOLBOX",
  lessonCompleted: "Les afgerond",
  lessonFailed: "Cursus niet behaald",
  repeatLesson: "Herhaal de cursus, om verder te kunnen gaan",
  congratulations: "Van harte gefeliciteerd!",
  courseCompleted: "Cursus afgerond",
  percentCompleted: "U heeft $successRate% van de vragen goed beantwoord.",
  downloadCertificate:
    'U kunt op elk moment uw certificaat bij "Certificaten" in uw profiel downloaden.',
  trueFalse: "Correct of onjuist?",
  seconds: "Sec.",
  noChoiceText: "Jammer, te laat.",
  right: "Correct!",
  wrong: "Onjuist",
  progress: "$current van $total",
  addCourseContent: "Inhoud cursus toevoegen",
  startCourseEditor: "Cursuseditor starten",
  addSemicolon: "Voeg opschriften door een halve kolom gescheiden toe",
  addToProgram: "Aan het programma toevoegen",
  allAccounts: "Alle accounts",
  certificate: "Certificaat",
  certificates: "Certificaten",
  completed: "Voltooid",
  contentIsLoaded: "Inhoud is geladen",
  contentManager: "Inhoudmanager",
  courseCompleted: "Cursus afgerond",
  courseContent: "Inhoud cursus",
  courseFormat: "Format cursus",
  coverPhoto: "Omslagfoto",
  editCoverPhoto: "Omslagfoto wijzigen",
  addCoverPhoto: "Omslagfoto toevoegen",
  additionalDocuments: "Aanvullende documenten",
  editAdditionalDocuments: "Aanvullende documenten wijzigen",
  addAdditionalDocuments: "Aanvullende documenten toevoegen",
  documents: "Documenten",
  courseLables: "Cursusetiketten",
  courseLanguage: "Taal van cursus",
  courseNotes: "Cursusnotities",
  courseStructure: "Structuur",
  courseTags: "Cursustags",
  courseTitle: "Naam cursus",
  createCourse: "Cursus aanmaken",
  curator: "CURATOR",
  description: "Beschrijving",
  details: "Details",
  dueDate: "Vervaldatum",
  duration: "Duur",
  exportStatistics: "Statistieken exporteren",
  learningStandard: "Leerstandaard",
  licenses: "Licenties",
  mandatory: "Verplichte cursus",
  noAssessment: "Momenteel zijn er geen beoordelingen beschikbaar.",
  noCoursesAssigned: "Aan uw account zijn geen cursussen toegewezen. Bezoek ",
  noCoursesAvailable: "Momenteel zijn er geen cursussen beschikbaar.",
  noDueDate: "Geen vervaldatum",
  overview: "Overzicht",
  pagina: "",
  pickAnAccount: "Kies een account",
  printCertificate: "Druk een certificaat af",
  publishCourse: "Cursus publiceren",
  publishedToAccount: "Gepubliceerd in account",
  relatedCourses: "Vergelijkbare cursussen",
  remove: "Verwijderen",
  removeFromProgram: "Uit het programma verwijderen",
  singleAccount: "Enkel account",
  startCourse: "Starten",
  successMessageOne: "Gefeliciteerd, u heeft de cursus ",
  successMessageThree: "U kunt nu uw eindcertificaat downloaden of afdrukken. ",
  successMessageTwo: " met succes afgerond!",
  tags: "Trefwoorden",
  unpublishCourse: "Publicatie opheffen",
  updateCourseContent: "Inhoud cursus uploaden",
  updateCourse: "Cursus bijwerken",
  youDidntfinishCourse: "U heeft nog geen cursus afgerond.",
  courseVisibleFor: "Cursus is zichtbaar voor",
  accountStructureItem: "Afdeling",
  courseAssigmentOptions: "Toewijzingsopties",
  assignCourse: "Cursus toewijzen",
  pickACourse: "Kies een cursus",
  noDocumentsAvailable: "Geen documenten aanwezig",
  nothingRelated: "Geen vergelijkbare cursussen aanwezig",
  nothingWritten: "niets ingevoerd",
  noTagsAvailable: "Geen trefwoorden aanwezig",
  alreadyGlobal: "De cursus is al wereldwijd gepubliceerd.",
  questionsanswered: "Vragen",
  completedOn: "Voltooid op",
  locale: "nl-NL",
  quizAuthorizationContent: "Quiz toestemming",
  courseNotPublished: "De cursus is op dit moment niet gepubliceerd",
  courseNotPublishedForAccount:
    "De cursus is op dit moment niet gepubliceerd voor het account $account",
  clickToPublishCourse: "Cursus publiceren",
  visible: "Zichtbaar",
  userIsNotContentManager:
    "U bent geen Content Manager en kunt daarom de inhoud van de cursus niet bewerken.",
  assignmentOpen: "Open",
  assignmentStarted: "Gestart",
  assignmentCompleted: "Voltooid",
  dueDatePassed: "Vervaldatum overschreden",
  exitCourse: "Cursus annuleren",
  markAsFinished: "Cursus afgerond",
  assignToAllUsers: "Cursus toewijzen aan alle gebruikers in het account",

  learningPoints: "Leerpunten",
  learningPointsAbbreviation: "LP",
  back: "Terug",
  next: "Verder",
  responsesMissing: "Sluit alle punten af, voordat u verdergaat",

  configureLogging: "Logging configureren",
  createAccount: "Account aanmaken",
  changeAccount: "Account wijzigen",
  deleteAccount: "Account verwijderen",
  importAccountStructure: "Accountstructuur importeren",
  createUser: "Gebruiker aanmaken",
  importUser: "Gebruiker importeren",
  changeUser: "Gebruiker wijzigen",
  deleteUser: "Gebruiker verwijderen",
  assignUserRole: "Rol toewijzen",
  resetPassword: "Wachtwoord resetten",
  createCourse: "Cursus aanmaken",
  changeCourse: "Cursus wijzigen",
  addQuizContent: "Inhoud quiz toevoegen",
  changeQuizContent: "Inhoud quiz wijzigen",
  addContent: "Inhoud cursus toevoegen",
  changeContent: "Inhoud cursus wijzigen",
  deleteCourse: "Cursus verwijderen",
  deleteContent: "Inhoud cursus verwijderen",
  publishCourse: "Cursus publiceren",
  unpublishCourse: "Publicatie opheffen",
  assignCourse: "Cursus toewijzen",
  unassignCourse: "Cursustoewijzing opheffen",
  systemMessages: "Systeemberichten",
  learningManagement: "Leermanagement",
  courseManagement: "Cursusmanagement",
  userManagement: "Gebruikersmanagement",
  organizationStructure: "Organisatiestructuur",
  accountManagement: "Accountmanagement",
  system: "Systeem",
  learningManagementDetail: "Leerschema's en cursustoewijzing",
  courseManagementDetail: "Inhoud cursus beheren",
  userManagementDetail: "Gebruikers beheren en wachtwoorden resetten",
  organizationStructureDetail: "Organisatiestructuur bepalen",
  accountManagementDetail: "Accounts beheren",
  logsDetail: "Systeemlogs en instellingen",
  userCreateSuccess: "Gebruiker succesvol aangemaakt",
  createOtherUser: "Nog een gebruiker toevoegen",
  backToUserOverview: "Terug naar gebruikersoverzicht",
  importMoreUsers: "Meer gebruikers importeren",
  currentRoles: "Huidige rollen",
  numberOfRoles: "Aantal rollen",
  numberOfUsers: "Gebruikers",
  deleteAccountMessage:
    "Gaarne bevestigen dat u het account '$account' wilt verwijderen.",
  showStatistics: "Statistieken tonen",
  appendAccount: "Account bij $account vastmaken.",
  courseAssignments: "Toegewezen",
  courseAssignmentsForCourse: "Cursustoewijzing voor $course",
  mandatoryAssignments: "Verplichte cursusopdrachten",
  dueDateAssignments: "Met termijn",
  dueDatePassed: "Vervaldatum voorbij",
  userOrAccount: "Gebruiker of afdeling",
  expiryDate: "Afloopdatum",
  deletePublication: "Publicatie verwijderen",
  deletePublicationMessage:
    "Weet u zeker dat u de publicatie voor het account $account wilt verwijderen?",
  orgStructure: "Afdeling",
  type: "Type",
  downloadTemplate: "Voorbeeld downloaden",
  published: "Gepubliceerd",
  scorm: "SCORM",
  quiz: "Quiz",
  staticFile: "pdf",
  editPublication: "Publicatie bewerken",
  pleaseDefineCourseContent:
    "Bepaal de inhoud van de cursus alvorens u deze publiceert.",
  usernameMissing: "Gebruikersnaam ontbreekt",
  usernameError: "Gebruikersnaam kan alleen uit alfanumerieke tekens bestaan",
  emailMissing: "E-mailadres ontbreekt",
  emailError: "Geen geldig e-mailadres",
  passwordMissing: "Wachtwoord ontbreekt",
  passwordError:
    "Het wachtwoord moet minimaal 8 tekens lang zijn en zowel een hoofletter als een kleine letter bevatten.",
  registrationKeyManagement: "Registratiesleutel",
  registrationKeyManagementDetail: "Beheren van registratiesleutels",
  addRegistrationKey: "Registratiesleutel toevoegen",
  registrationLink: "Registratielink",
  createKey: "Toevoegen",
  user_upload_maximum: "Max. 1000 users per upload",
  userState: "Gebruikersstatus",
  userStateDetail: "Zugangsberechtigung und Pflichtkurse.",
  testsPassed: "Test afgerond",
  access: "Toegang",
  downloadReport: "Bericht downloaden",
  success: "Succesvol opgeslagen!",
  warning: "Voorzichtig!",
  error: "Fout!",
  removedFromProgram: "Cursus is uit uw programma verwijderd",
  addedToProgram: "Cursus is aan uw programma toegevoegd",
  courseCreated: "Cursus is gemaakt",
  courseNotCreated: "Cursus is niet gemaakt",
  fillUpFields: "Vul alle verplichte velden in",
  cookieNotAvailable: "Uw sessie is verlopen, meld u opnieuw aan",
  courseNotFinished: "Geen afsluitingsinformatie voor de cursus aanwezig",
  courseAborted: "Cursus is afgebroken",
  correctAnswer: "Correcte antwoord",
  wrongAnswer: "Verkeerd antwoord",
  courseStarted: "Cursus is gestart",
  testFailed: "Test niet behaald",
  courseCompleted: "Cursus is succesvol afgerond",
  imageTooLarge: "Bestand mag niet groter dan 2 MB zijn",
  wrongFormat: "Onjuist format",
  passwordReset:
    "Er is een link voor het resetten van het wachtwoord verstuurd naar het aangegeven e-mailadres",
  passwordChanged: "Wachtwoord is gewijzigd",
  accountAdded: "Account is toegevoegd",
  accountUpdated: "Account is bijgewerkt",
  accountDeleted: "Account is verwijderd",
  userImported: "Gebruikers zijn geïmporteerd",
  userNotImported: "Gebruikers zijn niet geïmporteerd",
  loggingConfigSaved: "De log-configuratie is opgeslagen",
  userDeleted: "Gebruiker is verwijderd",
  userAdded: "Gebruiker is aangemaakt",
  userUpdated: "Gebruiker is bijgewerkt",
  GDPRLoaded: "GDPR is geladen",
  cookiesDisabled: "U heeft de cookies uitgeschakeld",
  wrongCredentials: "Onjuiste aanmeldgegevens",
  fileUploaded: "Uw bestand is geüpload",
  courseContentUpdated: "Cursusinhoud is bijgewerkt",
  coursePublished: "Cursus is gepubliceerd",
  courseUnPublished: "Het publiceren van de cursus is ongedaan gemaakt",
  serverUnreachable: "Server is niet bereikbaar",
  courseContentAdded: "Cursusinhoud is succesvol toegevoegd.",
  user_upload_exceeded:
    "Te veel gebruikers. Er worden maximaal 1000 gebruikers per upload ondersteund.",
  selectAccounts: "Account kiezen",
  selectAccountToContinue: "Kies account om verder te gaan",
  accountOverview: "Accountoverzicht",
  users: "Gebruikers",
  courses: "Cursussen",
  coursesCompleted: "Afgerond op",
  completedCoursesPerUser: "Afgeronde cursussen per gebruiker",
  learningHours: "Leeruren",
  learningHoursPerUser: "Leeruren per gebruiker",
  courseOverview: "Cursusoverzicht",
  completed: "Voltooid",
  assigned: "Toegewezen",
  courseDetails: "Details",
  selectCourse: "Cursus selecteren",
  details: "Selecteren",
  type: "Type",
  dueDate: "Tot",
  mandatory: "Verplichte cursus",
  completionRate: "Percentage afgerond",
  averageScore: "Testresultaat",
  duration: "Duur",
  averageDuration: "Gemiddelde duur",
  account: "Account",
  accountName: "Accountnaam",
  accounts: "Accounts",
  active: "Actief",
  admin: "Admin",
  all: "Alle",
  not_found: "De pagina bestaat niet",
  assessmentStatistics: "Beoordelingsstatistieken",
  assignedUsersOnly: "Alleen toegewezen gebruikers",
  autoIndividual: "Automatisch, individueel",
  cancel: "Annuleren",
  cardImage: "Kaartafbeelding",
  close: "Sluiten",
  completed: "Voltooid",
  confirm: "Bevestigen",
  country: "Land",
  course: "Cursussen",
  courseDescription: "Cursusbeschrijving",
  courseDocuments: "Cursusdocumenten",
  courses: "Bibliotheek",
  courseStructure: "Cursusopbouw",
  dataProtection: "Gegevensbescherming",
  defaultLanguage: "Standaardtaal",
  deleteText: "Weet u zeker dat u dit wilt verwijderen",
  delete: "Verwijderen",
  details: "Details",
  dragAndDrop: "Drag & Drop / klikken om toe te voegen",
  dueDate: "Vervaldatum op",
  email: "E-mail",
  empty: "Leeg",
  exit: "Afmelden",
  expiresOn: "Vervalt op",
  fileImport: "Te importeren bestand",
  image: "Afbeelding",
  inactive: "Inactief",
  language: "Taal",
  logo: "Logo",
  mandatory: "Verplichte cursus",
  monthsLabels: [
    "januari",
    "februari",
    "maart",
    "april",
    "mei",
    "juni",
    "juli",
    "augustus",
    "september",
    "oktober",
    "november",
    "dezember",
  ],
  myAccount: "Mijn account",
  myProfile: "Mijn profiel",
  myProgram: "Mijn programma",
  myRoles: "Mijn rollen",
  never: "Nooit",
  no: "Nee",
  none: "Geen",
  notFound: "Niet gevonden",
  phoneNumber: "Telefoonnummer",
  playImage: "Afbeelding weergeven",
  publishingExpiresOn: "Publicatie vervalt op",
  publishTo: "Gepubliceerd in",
  reports: "Bericht",
  save: "Opslaan",
  search: "Zoeken...",
  status: "Status",
  structureItem: "Afdeling",
  termsAndConditions: "Algemene voorwaarwaarden",
  unlimited: "Onbegrensd",
  update: "Bijwerken",
  user: "Gebruikers",
  username: "Gebruikersnaam",
  users: "Gebruikers",
  visibility: "Zichtbaarheid account",
  yes: "Ja",
  zipCode: "Postcode",
  info: "Info",
  error: "Fout",
  alert: "Alarm",
  criticalAlert: "Kritisch alarm",
  navigation: "Navigatie",
  note: "Aanwijzing",
  userProfile: "Gebruikersprofiel",
  userId: "Gebruikers-ID",
  customId: "ID",
  organizationalStructure: "Organisatiestructuur",
  created: "Gemaakt op",
  new: "Nieuw",
  noResults: "Geen resultaten",
  noData: "Geen gegevens",
  uploadFile: "Bestand uploaden",
  id: "ID",
  name: "Naam",
  append: "Toevoegen",
  edit: "Bewerken",
  format: "Format",
  years: "Jaren",
  months: "Maanden",
  weeks: "Weken",
  days: "Dagen",
  seconds: "Seconden",
  minutes: "Minuten",
  hours: "Uren",
  date: "Datum",
  message: "Bericht",
  fax: "Fax",
  genericError:
    "Er heeft zich een fout voorgedaan, probeer de pagina opnieuw te laden. Neem contact op met de systeembeheerder indien de fout blijft bestaan.",
  action: "Actie",

  // TODO
  roles: "Rollen",
  properties: "Eigenschappen",
  property: "Eigenschap",
  assignUserProperty: "Eigenschap toewijzen",
  userProperties: "Eigenschappen van de gebruiker",
  addTagToUser: "Eigenschap toewijzen",
  showMoreFilters: "Meer filters tonen",
  assigned: "Toegewezen",
  unassigned: "Niet toegewezen",
  assignedOrUnassigned: "Toegewezen/Niet toegewezen",
  emailAboutChange: "Bericht per e-mail verzenden",
  showLessFilters: "Minder filters tonen",
  clearFilters: "Filters verwijderen",
  no_changes_assignment_button: "Geen wijzigingen",
  assignment: "Toewijzingen",

  upload_picture: "Uploaden",
  take_picture: "Opnemen",
  access_card: "Toegangskaart",

  assign_course: "Cursus toewijzen",
  add_assignment_to_users: "aan $users toewijzen",
  remove_assignments_from_users: "van $users toewijzing verwijderen",
  update_assignments_for_users: "voor $users toewijziging updaten",
  create_property: 'Eigenschap "$property" aanmaken',
  non_mandatory: "Geen verplichte cursus",
  department: "Afdeling",
  user_details: "Gebruikersgegevens",
  download_file: "Bestand downloaden",
  your_browser_not_support_this_content:
    "Uw browser ondersteunt dit bestandsformaat niet.",
  browser_not_support_pdf:
    "Uw browser kan geen pdf-bestanden weergeven.' Scroll down to download the pdf instead.",
  upload_file: "Bestand uploaden",
  deleteDocument: "Document verwijderen",
  deleteDocumentConfirmation: "Weet u zeker, dat u $document wilt verwijderen?",
  video_unsupported: "Deze browser ondersteunt geen HTML5-video.",
  confirm: "Bevestigen",
  confirmed: "Bevestigd",
  multiselect: "Keuze meerdere eigenschappen toestaan",
  profile_image: "Profilbild",
  property_type: "Eigenschaften Typ",
  account_details: "Account details",
  remove_assigned_properties: "Zugewiesene Eigenschaften entfernen",
  mandatory_user_properties: "Obligatorische Benutzereigenschaften",

  select_property_first: "Kies een eigenschap om verder te gaan.",
  selected_property_already_mandatory:
    "De geselecteerde eigenschap is al verplicht.",
  confirm_remove_assigned_properties:
    "Bitte bestätigen sie dass sie alle zugewiesenen Eigenschaften des typs $type entfernen wollen, dieser Schritt kann nicht rückgangig gemacht werden.  .",
  tags_removed_from_users:
    "Alle zugewiesenen Eigenschaften des Typs $type wurden entfernt.",
  add_mandatory_user_property:
    "Bitte wählen sie folgende Merkmale für ihre Tätigkeit aus:",

  upload_certificate: "Zertifikat hochladen",
  delete_certificate: "Zertifikat löschen",
  confirm_delete_certificate:
    "Sind sie sicher, dass sie das Zertifikat $certificateName löschen wollen?",

  certificateName: "Certificaatnaam",
  issuedFrom: "Uitgegeven door",
  ectsPoints: "ECTS-punten",
  ects: "ECTS",

  completedInPercent: "Afgerond in procent",

  issuedAt: "Uitgegeven op",
  receivedAt: "Verkregen op",
  validUntil: "Geldig tot",
  unlimitedValidity: "Onbegrensde geldigheid",

  uploadedCertificates: "Geüploade certificaten",

  drag_drop_steps:
    "Gebruik Drag and Drop om elementen uit de linkerkolom in te voegen.",
  add_lesson: "Les toevoegen",

  minCompletion: "Percentage punten om de les af te ronden",
  randomStepOrder: "Willekeurige volgorde van de afzonderlijke stappen.",
  fixedViewExecutionOrder:
    "Willekeurige volgorde van de afzonderlijke weergaven.",
  allowBackNavigation: "Terugbladeren toestaan",
  maxTimeForCompletions: "Maximale tijd om de les af te sluiten in minuten",
  maxRetries: "Maximaal aantal herhalingen",
  maxNumberOfCompletions:
    "Maximaal aantal herhalingen om het puntenaantal te verbeteren",

  quiz: "Quiz",
  text: "Tekst",
  multimedia: "Video",
  pdf: "Pdf",
  inputText: "Tekstinvoer",
  inputDate: "Datuminvoer",

  quiz_description:
    "Stel de gebruiker een vraag en laat hem een antwoord uit meerdere mogelijkheden kiezen",
  text_description:
    "Laat de gebruiker een tekst zien en laat hem als optie de inhoud van de tekst bevestigen.",
  multimedia_description:
    "Laat de gebruiker een video zien en laat hem als optie de inhoud van de video bevestigen.",
  pdf_description:
    "Laat de gebruiker een pdf zien en laat hem als optie de inhoud van de pdf bevestigen.",
  inputText_description: "Laat de gebruiker een tekst invoeren.",
  inputDate_description: "Laat de gebruiker een datum invoeren.",

  lessons: "Lessen",
  new_lesson: "Nieuw les",

  settings: "Instellingen",
  requiresCompletion:
    "De stap moet vervuld worden om de cursus verder te bewerken",
  requiresInteraction: "De gebruiker moet deze stap bevestigen",
  isEditable: "De gebruiker kan deze stap achteraf bewerken",
  points: "Punten",
  clickToEdit: "(klikken om te bewerken)",
  choiceSettings: "Antwoordinstellingen",
  choice: "Antwort",
  choiceText: "Antwoord $index tekst",
  choiceExplanation: "Antwoord $index uitleg",
  choiceIsCorrect: "Correcte antwoord",
  addChoice: "Antwoord toevoegen",
  deleteChoice: "Antwoord verwijderen",
  uploadVideoFile: "Videobestand uploaden",
  discardChanges: "Wijzigingen afkeuren",
  addInputLabel: "(label toevoegen)",
  inputType: "Invoerwijze",
  week: "week",
  month: "maand",
  year: "jaar",
  label: "Label",
  minDate: "Minimale datum",
  maxDate: "Maximale datum",
  oneLine: "Enkele regel",
  rows: "Regels",
  multipleLine: "Meerdere regels",
  minLength: "Minimale lengte",
  maxLength: "Maximale lengte",
  showWordLimit: "Toon maximaal aantal tekens",

  lessonFailedNoMoreRetries:
    "U heeft na $retries pogingen de cursus niet met succes afgesloten.",
  lessonFailedMoreRetries:
    "U heeft nog $retries pogingen over om deze les en de cursus te halen.",
  lessonFailedOneMoreRetries:
    "U heeft nog één poging over om deze les en de cursus te halen.",
  registrationKeyAlphanumeric:
    "Registratiesleutel (alfanumeriek, min. 3 tekens)",
  registrationKeyMinimumLength:
    "De registratiesleutel moet minimaal {length} tekens bevatten.",
  chooseAnAccountToContinue: "Selecteer een account om verder te gaan.",

  help: "Help",
  alreadyRegistered: "Reeds geregistreerd",
  additionalDetails: "Aanvullende details",
  additionalDetailsSave: "Bevestigen",
  upload: "Upload",
  uploadVideo: "Video uploaden",
  uploadPdf: "Pdf uploaden",
  step: "Stap",
  flexView: "Scherm",
  flexViews: "Schermen",
  lesson: "Lessen",
  dragInThisAreaToDelete: "Sleep elementen in dit gebied om ze te verwijderen",
  editor: "Editor",
  baseLanguage: "Oorspronkelijke taal",
  translation: "Vertaling",
  editCourse: "Cursus bewerken",
  editTranslation: "Vertaling bewerken",

  pointTracking: "Punten tonen",
  repeatable: "Herhaalbaar",
  completionShowScore: "Totaal aantal punten tonen",
  dragAndDropCertificate:
    "Sleep het pdf-bestand met Drag & Drop of klik om toe te voegen",
  fileName: "Bestandsnaam",
  fileSize: "Bestandsformaat",
  userProfileV2: "Gebruikersprofiel v2",
  certification: "Certificaten",
  logs: "Logs",
  issuedBy: "Uitgegeven door",
  issuedOn: "Uitgegeven op",
  fulfilledInPercent: "Voltooid in procent",
  plusNewTag: "+ nieuwe tag",
  orSeparator: "Of",
  contentManagerPicker: "Inhoudmanager",
  saveChangesTitle: "Wijzigingen opslaan",
  saveChangesBody:
    "U heeft niet-opgeslagen wijzigingen. Moeten deze worden opgeslagen of worden afgekeurd?",
  flexLessonLocked: "De gebruiker moet deze les afsluiten om verder te gaan.",
  flexSubset: "Aantal schermen uit deze les die de gebruiker moet bewerken.",

  curatorName: "Naam van de beheerder",
  curatorCompany: "Bedrijf van de beheerder",
  curatorDescription: "Beschrijving van de beheerder",
  courseCompletedTitle: "Cursus afgerond titel",
  courseCompletedSubtitle: "Cursus afgerond subtitel",
  courseCompletedNote: "Cursus afgerond tekst",
  completionShowScore: "Punten tonen na afronding cursus",
  failedCourseInformation: "Tekstweergave bij niet-bestaan van de cursus",
  pinPage: "Scherm altijd bij begin of einde van een les tonen",
  start: "Begin",
  end: "Einde",
  courseDuration: "Duur van de cursus in minuten",
  randomizeAnswers: "Antwoorden mengen",
  closeEditor: "Editor sluiten",
  courseNotEditable:
    "Deze cursus is al in gebruik en kan daarom niet gewijzigd worden.",
  flex: "Flex",
  copied: "Naar het klembord gekopieerd",
  covidCertificates: "Vaccinatiecertificaten",
  covidCertificatesDetail: "Beheren van vaccinatie- en testcertificaten",
  covidInstructions: "COVID-19-relateerde instructies",
  covid: "COVID-19",
  addCertificate: "Zertifikat hinzufügen",
  scanQrCode: "QR-code inscannen",
  scanQrCodeDescription:
    "De volgende QR-codes kunnen ingeschand of geupload worden: vaccinatiecertificaten, PCR- en antigeen-testcertificaten, genezingscertificaten",
  openFile: "Datei öffnen",
  openImage: "Bilddatei öffnen",
  validFrom: "Gueltig seit $date",
  validFor: "Gueltig am $date",
  vaccinationCertificatesValid:
    "Uw certificaten voldoen aan de aangegeven regels",
  vaccinationCertificatesInvalid:
    "Uw certificaten voldoen niet aan de aangegeven regels",
  certificateInvalid: "Dit certificaat is ongeldig",
  certificateExpired: "Dit certificaat is verlopen",
  updatedOn: "Bijgewerkt op $date",
  uploadinCertificate: "Certificaat uploaden",
  certificateUploadError: "Fout bij het uploaden van het certificaat",
  certificateNameMismatch:
    "Het certificaat is uitgeschreven op een andere naam.",
  certificateBirthdayMismatch:
    "Het certificaat is uitgeschreven op een andere geboortedatum.",
  certificateExists: "Het certificaat bestaat al.",
  certificateUploaded: "Certificaat succesvol geüpload",
  instructions: "Instructies",
  accessRules: "Toegangsregels",
  selectAccessRule: "Toegangsregels selecteren",
  qrCode: "QR-code",
  certificateDeleted: "Certificaat verwijderd",
  deleteCertificateConfirmation:
    "Weet u zeker dat u dit certificaat wilt verwijderen",
  event: "Event",

  instructions: "Instructies",
  isOnlineEvent: "Online gebeurtenis",
  eventLink: "Event link",
  eventLocation: "Locatie",
  selfRegistration: "Zelfregistratie",
  attendanceConfirmation: "Aanwezigheid",
  passedConfirmation: "Bevestigd",
  mark: "Gewaardeerd",
  trainer: "Trainer",
  attendanceAndGrades: "Aanwezigheid en waardering",
  onlyIfMandatoryCourse: "Bij verplichte opdrachten",
  allUsersInAccount: "Alle gebruikers van het account",
  dateManagement: "Databeheer",
  dateManagementDetail: "Beheer van data en deelnemerresultaten",
  courseFilter: "Cursusfilter",

  fromTo: "Van - tot",
  capacity: "Capaciteit",
  unlimitedCapacity: "Onbegrensde capaciteit",
  invalidatePreregistration: "Vervaldatum vooraanmeldingen",
  allowPreregistration: "Vooraanmeldingen toestaan",
  newDate: "Nieuw datum",
  editDate: "Datum bewerken",
  preenrollments: "Vooraanmeldingen",
  enrollments: "Aanmeldingen",
  preregistrationDate: "Vooraanmelding $date",
  registered: "Aangemeld",
  notRegistered: "Niet aangemeld",
  registeredAndNotRegistered: "Aangemeld/Niet aangemeld",
  selectCourseToContinue: "Cursus selecteren om verder te gaan",
  changesSavedSuccessfully: "Wijzigingen succesvol opgeslagen",
  cancelRegistrationTitle: "Afmelding",
  cancelRegistrationBody: "Wilt u echt $name afmelden van de volgende datum?",
  date: "Datum",
  dates: "Data",
  unregister: "Afmelden",
  changeRegistrationTitle: "Wijziging",
  currentRegistration: "$name is reeds voor de volgende datum aangemeld",
  changeRegistrationBody:
    "Wilt u de bestaande aanmelding opheffen en een nieuwe voor de volgende datum maken?",
  preregistrationAvailableRegistrations: "Vooraanmeldingen/vrije plaatsen",
  seatsLeft: "Vrije plaatsen",
  registrationTitle: "Aanmelding",
  registrationBody:
    "Door de aanmelding voor de volgende datum wordt de cursus $course als verplichte cursus toegewezen aan de gebruiker.",
  register: "Aanmelden",
  myDates: "Mijn data",
  dateDetails: "Datumdetails",
  attendees: "Deelnemers",
  fractionOfTotal: "$fraction van $total",
  registrations: "Registraties",
  link: "Link",
  notPassed: "Niet behaald",
  passed: "Behaald",
  attended: "Deelgenomen",
  notAttended: "Niet deelgenomen",
  youAreRegisteredFor: "U bent voor de volgende datum aangemeld:",
  unlimitedSeats: "Geen plaatsbeperking",
  otherDates: "Overige data",
  managerWillAssignDate: "U wordt door uw manager voor een datum aangemeld.",
  covidInstructionsLabel: "Instructies in de systeemtaal $lang.",
  cameraNotAllowedError:
    "Het gebruik van de camera is niet toegestaan. Laad de pagina opnieuw of wijzig de cameratoegang in de browserinstellingen.",
  cameraNotFoundError:
    "Er is geen camera gevonden. Gebruik in plaats hiervan de upload.",
  cameraNotReadableError:
    "Er kon geen toegang tot de camera worden gekregen. Zorg ervoor, dat de camera niet door een ander programma wordt gebruikt.",
  cameraNotAvailable:
    "Uw browser ondersteunt de cameratoegang niet. Gebruik in plaats hiervan de upload.",
  qrCodeUploadError:
    "De QR-code kon niet worden gelezen. Zorg ervoor dat er slechts een QR-code op de afbeelding aanwezig is en verhoog eventueel de resolutie.",
  confirmCovidRulesManuallyTitle: "COVID-19 - visuele statuscontrole",
  confirmCovidRulesBody:
    "Hiermee bevestig is, dat de door $user getoonde bewijzen voldoen aan de actuele COVID-19-regels en voor vandaag geldig zijn.",

  activateApis: "API-bereik activeren",
  allowApiManagement: "API-beheer toestaan",
  allowedApis: "Toegestane api's",
  volume: "Volume in ver/dag",
  throttle: "Throttle in ver/min",
  apis: "API's",
  apisDetail: "Beheren van de API-sleutel",

  keys: "Sleutels",
  key: "Sleutels",
  keyName: "Naam",
  keyPurpose: "Gebruiksdoel",
  createApiKey: "Sleutel toevoegen",
  keyCopiedToClipboard: "De sleutel is naar het klembord gekopieerd",
  deleteKey: "Sleutel verwijderen",
  deleteKeyMessage:
    "Weet u zeker dat u de sleutel '$name' voor '$purpose' wilt verwijderen?",
  changeKey: "Schlüssel bearbeiten",
  keyCreatedSuccessfully: "De Api-sleutel is toegevoegd",
  keyUpdatedSuccessfully: "De wijzigingen zijn succesvol opgeslagen",
  api: "Api",
  addApi: "Api toevoegen",
  editApi: "Api bewerken",
  example: "Voorbeeld",
  apiDetails: "Api-details",
  flex: "Flex",
  notAvailable: "Niet beschikbaar",
  method: "Methode",
  requestBody: "Aanvraag",
  responses: "Antwoorden",
  parameters: "Parameters",
  manualCheck: "Bewijscontrole",
  isOnline: "Online",

  logout: "Uitloggen",
  continue: "Doorgaan",
  courseFinishedDoYouWantToLogout:
    "De cursus is afgelopen. Wil je uitloggen of doorgaan? Je wordt automatisch uitgelogd over {seconds}.",
  stateless: "Staatloos",
};
